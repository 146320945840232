/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Papa from "papaparse/papaparse.min";
import { fetchAllBsa, fetchBsa } from "../../actions/address";
import {
  getBsaKey,
  retrieveBsa,
  retrieveBsaCount,
  retrieveNumberToShow,
} from "../../selectors/address";
import "../../../css/styles.css";
import { retrieveMutualTransactionInfo } from "../../selectors/walletMutualTransactions";

class AddressBSA extends Component {
  getDownloadCSVLink() {
    const data = this.props.bsa;
    if (data === null) {
      return null;
    }
    const finalData = [];
    data.map(item => finalData.push({ bsaAddress: item }));
    let csv = Papa.unparse(finalData);

    // The encodeURIComponent wrapper around the csv object was necessary for firefox compatibility
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    }
    return csv;
  }

  loadMore() {
    this.props.fetchBsa(this.props.address, false);
  }

  disableBsa() {
    const x = this.props.bsa.length;
    return x >= this.props.bsaCount;
  }

  render() {
    const bsaMentions = this.props.bsa.map(id => (
      <li
        key={id}
        className="list-group-item"
        style={{ fontFamily: "Monospace", fontSize: "11px" }}
      >
        {id}
      </li>
    ));

    const csvButton =
      this.props.bsaKey === null ? (
        <a
          href={this.getDownloadCSVLink()}
          download={`${this.props.address}bsa-output.csv`}
          className="btn btn-success"
        >
          Download CSV
        </a>
      ) : (
        <Button
          className="blueButton"
          onClick={() => this.props.fetchAllBsa(this.props.address, false)}
        >
          Get Download Link
        </Button>
      );

    return (
      <div>
        <div className="verticalDivider" />
        <Button className="blueButton" onClick={() => this.loadMore()} disabled={this.disableBsa()}>
          {" "}
          Load More
        </Button>{" "}
        {csvButton}
        <div>
          <div className="verticalDivider" />
          <ul className="list-group">{bsaMentions}</ul>
        </div>
      </div>
    );
  }
}

AddressBSA.propTypes = {
  address: PropTypes.string.isRequired,
  bsa: PropTypes.arrayOf(PropTypes.number).isRequired,
  fetchAllBsa: PropTypes.func.isRequired,
  fetchBsa: PropTypes.func.isRequired,
  bsaKey: PropTypes.number,
  bsaCount: PropTypes.number.isRequired,
};

AddressBSA.defaultProps = {
  bsaKey: 0,
};

const mapStateToProps = (state, { address }) => {
  return {
    retrieveMutualTrans: retrieveMutualTransactionInfo(state),
    bsa: retrieveBsa(state, address),
    bsaCount: retrieveBsaCount(state, address),
    numberToShow: retrieveNumberToShow(state, address),
    bsaKey: getBsaKey(state, address),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchBsa: (address, initial) => {
    dispatch(fetchBsa(address, initial));
  },
  fetchAllBsa: address => {
    dispatch(fetchAllBsa(address, false));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressBSA);
