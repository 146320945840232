import {
  faPlusCircle,
  faShare,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField
} from "@material-ui/core";
import { Button } from "react-bootstrap";

interface ShareButtonProps {
  callback: (email: string) => Promise<void>;
  getSharedUsers: () => Promise<[any]>;
  removeUser: (email: string) => Promise<void>;
  color?: string;
  fontSize?: string;
  marginLeft?: string;
  marginRight?: string;
  hoverText?: string;
}
export const ShareButton = ({
  callback,
  getSharedUsers,
  removeUser,
  color = "var(--secondary-color)",
  fontSize = "1.5rem",
  marginLeft = "10px",
  marginRight = "10px"
}: ShareButtonProps) => {
  const [shareShow, setShareShow] = useState<boolean>(false);
  const [shareText, setShareText] = useState<string>("");
  const [sharedUsers, setSharedUsers] = useState<any[]>();
  useEffect(() => {
    const getUsersLists = async () => {
      if (shareShow) {
        const users = await getSharedUsers();
        setSharedUsers(users);
      }
    };
    getUsersLists().catch(console.error);
  }, [shareShow]);

  // @ts-ignore
  const useStyles = makeStyles({
    listItemText: {
      fontSize: fontSize
    },
    dialogRoot: {
      zIndex: "10003 !important" // needed to show dialog over the navbar
    }
  });
  const classes = useStyles();

  return (
    <>
      <FontAwesomeIcon
        className="copyText"
        style={{ fontSize, color, marginLeft, marginRight }}
        icon={faShare}
        onClick={() => setShareShow(true)}
      />
      <Dialog
        fullWidth
        open={shareShow}
        onClose={() => {
          setShareShow(false);
          setShareText("");
        }}
        classes={{ root: classes.dialogRoot }}
      >
        <DialogTitle classes={{ root: classes.listItemText }}>
          Share with
        </DialogTitle>
        <DialogContent>
          <TextField
            id="share-text"
            label="Email"
            variant="filled"
            value={shareText}
            fullWidth
            inputProps={{ maxLength: 50, style: { fontSize } }}
            InputLabelProps={{ style: { fontSize } }}
            onChange={e => setShareText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!shareText || shareText.trim().length <= 0}
            onClick={async () => {
              await callback(shareText);
              setShareShow(false);
              setShareText("");
            }}
            className="greenButton"
          >
            Add
          </Button>
          <Button
            onClick={() => {
              setShareShow(false);
              setShareText("");
            }}
            className="issueButton"
          >
            Cancel
          </Button>
        </DialogActions>
        {sharedUsers && sharedUsers.length > 0 && (
          <DialogContent>
            <DialogContentText classes={{ root: classes.listItemText }}>
              Shared Users
            </DialogContentText>
            <List>
              {sharedUsers.map(item => (
                <ListItem key={item.email}>
                  <ListItemText
                    primary={item.email}
                    secondary={item.permission}
                    classes={{
                      primary: classes.listItemText,
                      secondary: classes.listItemText
                    }}
                  />
                  <ListItemIcon>
                    <IconButton
                      size="small"
                      onClick={async () => {
                        await removeUser(item.email);
                        setSharedUsers(
                          sharedUsers.filter(x => x.email !== item.email)
                        );
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="deleteButton"
                        style={{ fontSize: "20px" }}
                      />
                    </IconButton>
                  </ListItemIcon>
                </ListItem>
              ))}
            </List>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};
