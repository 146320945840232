/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import { fromJS, List, Set } from "immutable";

import App from "./components/App";
import axios, {
  autoLoggedOut,
  deleteSessionCookie,
  LOGIN_API,
  logoutIfInactive,
  setLastAction
} from "./api";
import { logError, stringifyStack } from "./helpers";
import configureStore from "./configureStore";
import { Currency } from "./reducers/currency";
import { ApplicationCustomizations } from "./reducers/colorScheme";
import { SnackbarProvider } from "notistack";
import * as React from "react";
import * as ReactDOM from "react-dom";
// @ts-ignore
import * as StackTrace from "stacktrace-js";
import SnackbarCloseButton from "./components/Utils/SnackbarCloseButton";
import { makeMessageList, Messages } from "./reducers/messages";

/**
 * Calculates stacktrace and calls logError to record error
 * @param error
 * @param message
 */
export async function windowError(error: Error, message: Event | string) {
  try {
    const value = await StackTrace.fromError(error).then(stringifyStack);
    await logError(message, value, null);
    return true;
  } catch (e) {
    throw e;
  }
}
// IIFE used to check if user is logged in and use that information to initialize
// the redux store. There's probably a better way to do this, but I couldn't figure
// one out.
(async () => {
  try {
    const {
      data: {
        role,
        features,
        coins,
        colorScheme,
        clientMode,
        registrationTimestamp,
        twoConfigured,
        twoSuccess,
        phone,
        email,
        messages
      }
    } = await axios.get(LOGIN_API);
    const currency = localStorage.getItem("currency");
    document.body.setAttribute("data-theme", colorScheme);
    return {
      loggedIn: true,
      role,
      features,
      coins,
      currency,
      colorScheme,
      clientMode,
      registrationTimestamp,
      twoConfigured: twoConfigured === "true",
      twoSuccess,
      phone,
      email,
      messages
    };
  } catch (err) {
    console.error(err);
    if (err.response && err.response.status !== 401) {
      throw err;
    }

    let colorScheme = null;
    let clientMode = null;
    if (err.response.data.colorScheme !== undefined) {
      colorScheme = err.response.data.colorScheme;
    }
    if (err.response.data.clientMode !== undefined) {
      clientMode = err.response.data.clientMode;
    }

    let registrationTimestamp = null;
    // @ts-ignore
    return {
      loggedIn: false,
      role: "user",
      features: [],
      coins: [],
      currency: null,
      colorScheme,
      clientMode,
      registrationTimestamp,
      phone: null,
      email: "",
      messages: []
    };
  }
})().then(
  async ({
    loggedIn,
    role,
    features,
    coins,
    currency,
    colorScheme,
    clientMode,
    twoSuccess,
    registrationTimestamp,
    phone,
    email,
    messages
  }) => {
    const store = configureStore(
      fromJS({
        messages: new Messages({
          messages: makeMessageList(messages)
        }),
        authentication: {
          loggedIn,
          role,
          registrationTimestamp,
          error: null,
          twoSuccess,
          phone,
          email
        },
        features: Set(fromJS(features)),
        coins: Set(fromJS(coins)),
        currency:
          currency === null ? new Currency({ currency: "bitcoin" }) : new Currency({ currency }),
        applicationCustomizations: new ApplicationCustomizations({
          colorScheme,
          clientMode
        })
      })
    );
    document.body.setAttribute("data-theme", colorScheme);
    setInterval(() => logoutIfInactive(store.dispatch), 5000);

    axios.interceptors.response.use(
      function(response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function(error) {
        if (error.response && error.response.status === 401) {
          autoLoggedOut(store.dispatch);
          deleteSessionCookie(); // auto logout if 401
          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );

    if (loggedIn) {
      localStorage.setItem("loggedIn", "true");
    } else {
      localStorage.setItem("loggedIn", "false");
    }

    if (currency === null) {
      localStorage.setItem("currency", "bitcoin");
    } else {
      localStorage.setItem("currency", currency);
    }
    // Do a check on the initial loading of the page.
    // This takes care of the case when the user leaves the page and comes back after
    // the timeout.
    await logoutIfInactive(store.dispatch);

    // We do not want this to be set until the above check happens.
    window.onclick = () => setLastAction();

    window.onerror = async (message, source, lineno, colno, error) => {
      return await windowError(error, message);
    };

    // List to unhandledrejection since onerror does not catch it
    window.addEventListener("unhandledrejection", function(e) {
      if (e.reason instanceof Error) {
        return windowError(e.reason, e.reason.message);
      }
      return false; // if not error just return
    });

    ReactDOM.render(
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
      >
        <App store={store} />
      </SnackbarProvider>,
      document.getElementById("content")
    );
  }
);
