// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import axios, { deleteSessionCookie, LOGIN_API, LOGOUT_API } from "../api";
import {
  DISMISS_LOGIN_FAILURE_NOTIFICATION,
  LOGGING_IN,
  LOGGING_OUT,
  LOGGING_OUT_AUTOMATICALLY,
  LOGIN_CSRF_ERROR,
  LOGIN_DELETED,
  LOGIN_DISABLED,
  LOGIN_FAILURE,
  LOGIN_OVERLOAD,
  LOGIN_SUCCESS,
  LOGIN_TRIAL_ENDED,
  LOGOUT_SUCCESS,
  TWO_FACTOR_AUTHENTICATION_FAILURE,
  TWO_FACTOR_AUTHENTICATION_SUCCESS,
  TWO_FACTOR_PHONE_SET_SUCCESSFUL,
  TWO_FACTOR_REGISTRATION_SUCCESS
} from "./actionNames";

export const login = (user_email, password) => async dispatch => {
  dispatch({ type: LOGGING_IN });

  try {
    const {
      data: {
        role,
        features,
        colorScheme,
        clientMode,
        coins,
        ctAccess,
        registrationTimestamp,
        twoConfigured,
        phone,
        messages,
        email
      }
    } = await axios.post(LOGIN_API, {
      email: user_email,
      password
    });
    dispatch({
      type: LOGIN_SUCCESS,
      role,
      features,
      coins,
      colorScheme,
      clientMode,
      ctAccess,
      registrationTimestamp,
      twoConfigured,
      phone,
      messages,
      email
    });
  } catch (err) {
    if (err.response.status === 401) {
      dispatch({ type: LOGIN_FAILURE });
    } else if (err.response.status === 403) {
      if (err.response.data.reason === "disabled") {
        dispatch({ type: LOGIN_DISABLED });
      } else if (err.response.data.reason === "trial_ended") {
        dispatch({ type: LOGIN_TRIAL_ENDED });
      } else if (err.response.data.reason === "deleted") {
        dispatch({ type: LOGIN_DELETED });
      } else if (err.response.data.reason === "overload") {
        dispatch({ type: LOGIN_OVERLOAD });
      } else {
        // Probably csrf error.
        dispatch({ type: LOGIN_CSRF_ERROR });
      }
    } else {
      throw err;
    }
  }
};

export const logout = () => async dispatch => {
  dispatch({ type: LOGGING_OUT });

  try {
    await axios.post(LOGOUT_API, {
      reason: "manual logout"
    });
    dispatch({ type: LOGOUT_SUCCESS });
  } catch (err) {
    deleteSessionCookie();
    dispatch({ type: LOGGING_OUT_AUTOMATICALLY });
  }
};

export const dismissFailureNotification = () => ({
  type: DISMISS_LOGIN_FAILURE_NOTIFICATION
});

export const registerTwoFactorCode = () => async dispatch => {
  try {
    await axios.put("/api/v2/2fa");
    dispatch({ type: TWO_FACTOR_REGISTRATION_SUCCESS });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const registerPhoneNumber = phone => async dispatch => {
  try {
    await axios.post("/api/v2/2fa-phone", { phone: phone });
    dispatch({ type: TWO_FACTOR_PHONE_SET_SUCCESSFUL, phone: phone });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const changePhoneNumber = (phone, token) => async dispatch => {
  try {
    await axios.post(`/api/v2/change-phone`, { token: token, phone: phone });
    dispatch({ type: TWO_FACTOR_PHONE_SET_SUCCESSFUL, phone: phone });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const submitTwoFactor = code => async dispatch => {
  try {
    const { data } = await axios.post("/api/v2/2fa", { code: code });
    if (data === true) {
      dispatch({ type: TWO_FACTOR_AUTHENTICATION_SUCCESS });
      return true;
    } else {
      dispatch({ type: TWO_FACTOR_AUTHENTICATION_FAILURE });
      return false;
    }
  } catch (err) {}
};

export const submitTwoFactorSms = code => async dispatch => {
  try {
    const { data } = await axios.post("/api/v2/2fa-text", { code: code });
    if (data === true) {
      dispatch({ type: TWO_FACTOR_AUTHENTICATION_SUCCESS });
      return true;
    } else {
      dispatch({ type: TWO_FACTOR_AUTHENTICATION_FAILURE });
      return false;
    }
  } catch (err) {}
};
