// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import reducer from "./reducers";
import { Iterable } from "immutable";
import { createLogger } from "./customLoggerMiddleware";
import { reduxLog } from "./helpers";
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (initialState = {}) => {
  // redux-logger to modify immutable to JSON
  const stateTransformer = state => {
    let newState = {};

    for (let i of Object.keys(state)) {
      if (Iterable.isIterable(state[i])) {
        newState[i] = state[i].toJS();
      } else {
        newState[i] = state[i];
      }
    }
    return newState;
  };
  // Creating middleware custom logger to log redux actions
  const customLogger = createLogger({
    predicate: (getState, action) => {
      // only matching on FAILURE actions change if want to catch others
      return action.type && action.type.includes("FAILURE");
    },
    stateTransformer,
    onFinish: logEntry => {
      // After catching redux call reduxLog to store
      reduxLog(logEntry).catch(e => console.log(e));
    }
  });
  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(...[thunk, customLogger]))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./reducers", () => {
      // eslint-disable-next-line global-require
      const nextReducer = require("./reducers").default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
};

export default configureStore;
