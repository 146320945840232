// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { List } from "immutable";
import { getCurrency } from "./currency";

export const getMention = (state, onlineAccountId) => {
  const currency = getCurrency(state);
  return state.getIn([currency, "mention", onlineAccountId]);
};

export const getAddressPosts = (state, onlineAccountId, address) => {
  const mention = getMention(state, onlineAccountId);
  if (mention) {
    return mention.getIn(["addressToPosts", address], List());
  }
  return List();
};

export const getAddressPostsConverted = (state, onlineAccountId, address) =>
  getAddressPosts(state, onlineAccountId, address).toJS();

export const getWalletPosts = (state, onlineAccountId, wallet) => {
  const mention = getMention(state, onlineAccountId);
  if (mention) {
    return mention.getIn(["walletToPosts", wallet], List());
  }
  return List();
};

export const getWalletPostsConverted = (state, onlineAccountId, wallet) =>
  getWalletPosts(state, onlineAccountId, wallet).toJS();
