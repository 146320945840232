import React, { useState } from "react";
import { Button, ControlLabel, Form, FormControl, FormGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import Modal from "react-bootstrap/lib/Modal";
import Datetime from "react-datetime";
import { ButtonProps } from "react-bootstrap/lib/Button";

interface GraphDateFilterProps extends ButtonProps {
  startDate: Date | null;
  endDate: Date | null;
  setDate: (startDate: Date, endDate: Date) => void;
}

/**
 * Button to set date range for graph
 * @param startDate
 * @param endDate
 * @param setDate function to call when setting date
 * @param props
 * @constructor
 */
export const GraphDateFilter: React.FC<GraphDateFilterProps> = ({
  startDate,
  endDate,
  setDate,
  ...props
}) => {
  const [showDateSelectModal, setShowDateSelect] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);
  const dateFilter = selectedStartDate || selectedEndDate;

  const clearDates = () => {
    console.log("here");
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };
  return (
    <>
      <Button
        onClick={() => {
          setShowDateSelect(true);
        }}
        className="graphToggle"
        {...props}
      >
        <FontAwesomeIcon icon={faCalendarAlt} />
        {(startDate || endDate) && (
          <span
            style={{
              borderRadius: "7px",
              fontWeight: "600",
              fontSize: "10px",
              marginLeft: "5px"
            }}
          >
            {`${startDate === null ? "Origin" : startDate.toLocaleDateString()} - ${
              endDate === null ? "Present" : endDate.toLocaleDateString()
            }`}
          </span>
        )}
      </Button>

      <Modal show={showDateSelectModal} onHide={() => setShowDateSelect(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select dates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={e => {
              e.preventDefault();
              setDate(selectedStartDate, selectedEndDate);
              setShowDateSelect(false);
            }}
          >
            <FormGroup controlId="startDate">
              <ControlLabel>Start date</ControlLabel>
              <Datetime
                initialValue={startDate}
                input
                onChange={e => setSelectedStartDate(new Date(e.valueOf()))}
              />
            </FormGroup>
            <FormGroup controlId="endDate">
              <ControlLabel>End date</ControlLabel>
              <Datetime
                initialValue={endDate}
                input
                onChange={e => setSelectedEndDate(new Date(e.valueOf()))}
              />
            </FormGroup>
            {
              <p style={{ color: "red" }}>
                Large date ranges can result in certain edges not loading for very large relations
              </p>
            }
            <Button type="submit">Set date</Button>
            {dateFilter && (
              <Button
                onClick={clearDates}
                style={{
                  marginLeft: 10
                }}
              >
                Remove Date Selection
              </Button>
            )}
            <Button
              style={{
                marginLeft: 10
              }}
              className="redButton"
              onClick={() => setShowDateSelect(false)}
            >
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
