import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import { faDownload } from "@fortawesome/free-solid-svg-icons/index";
import { CSVDownloader } from "react-papaparse";
import axios, { DOWNLOAD_API } from "../api";
import { connect } from "react-redux";
import { getCurrency } from "../selectors/currency";

const DataDownload = ({ data, entity_type, currency, filename = null }) => {
  const rows = data.length;

  const send_download_info = () => {
    let address_count = 0;
    let trx_count = 0;
    if (entity_type === "address") {
      address_count = rows;
    } else {
      trx_count = rows;
    }
    axios.post(DOWNLOAD_API(currency), {
      trx_count,
      address_count
    });
  };

  return (
    <CSVDownloader
      data={() => {
        send_download_info();
        return data;
      }}
      filename={filename || `whitesail_${entity_type}.csv`}
      className="whiteButton btn btn-default"
      style={{ marginRight: "10px", color: "green !important" }}
    >
      <FontAwesomeIcon icon={faDownload} style={{ marginRight: "5px" }} />{" "}
      Download
    </CSVDownloader>
  );
};

const mapStateToProps = state => {
  return {
    currency: getCurrency(state)
  };
};

export default connect(
  mapStateToProps,
  null
)(DataDownload);
