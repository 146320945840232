// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { Map, Record } from "immutable";
import { LOGGING_OUT, SEARCH_SUCCESS } from "../actions/actionNames";

/*
Search State
Search: List<SearchResult>

SearchResult: {
  results: dictionaries with list of results for every entity type,
  amount: amount of returned results,
}
 */

export const SearchResult = Record({
  results: null,
  amount: 0,
});

const addSearchResult = (state, { query, data }) => {
  let amount = 0;
  for (let [_, value] of Object.entries(data)) {
    amount += value.length;
  }
  return state.set(
    query,
    new SearchResult({
      results: data,
      amount: amount,
    })
  );
};

const makeSearchReducer = coin => {
  return (state = Map(), action) => {
    // If the coin doesn't match the reducer, just return the state.
    if (action === undefined || action.name !== coin) {
      return state;
    }

    switch (action.type) {
      case SEARCH_SUCCESS:
        return addSearchResult(state, action);
      case LOGGING_OUT:
        return Map();
      default:
        return state;
    }
  };
};

export default makeSearchReducer;
