/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Transaction from "../Transaction";
import {
  getModalAddressLink,
  getModalTransactionLink,
  getModalWalletLink
} from "../Transaction/linkHelpers";
import { openAddressModal, openTransactionModal, openWalletModal } from "../../actions/graph";

const TransactionModal = ({
  transaction,
  handleOpenAddressModal,
  handleOpenTransactionModal,
  handleOpenWalletModal,
  addToGraph,
  cy,
  graphId
}) => {
  console.log("second mention: ", cy);
  return (
    <Transaction
      transaction={transaction}
      getAddressLink={(address, addressCell) =>
        getModalAddressLink(address, addressCell, handleOpenAddressModal)
      }
      getWalletLink={(wallet, primaryTag) =>
        getModalWalletLink(wallet, primaryTag, handleOpenWalletModal)
      }
      getTransactionLink={(transaction_, direction) =>
        getModalTransactionLink(transaction_, direction, handleOpenTransactionModal)
      }
      getCreateGraphModal={() => addToGraph}
      graphModal={true}
      cy={cy}
      graphId={graphId}
    />
  );
};

TransactionModal.propTypes = {
  transaction: PropTypes.string.isRequired,
  handleOpenAddressModal: PropTypes.func.isRequired,
  handleOpenTransactionModal: PropTypes.func.isRequired,
  handleOpenWalletModal: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  handleOpenAddressModal: address => dispatch(openAddressModal(address)),
  handleOpenWalletModal: wallet => dispatch(openWalletModal(wallet)),
  handleOpenTransactionModal: transaction => dispatch(openTransactionModal(transaction))
});

export default connect(
  null,
  mapDispatchToProps
)(TransactionModal);
