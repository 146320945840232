// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { List, Map, Record } from "immutable";
import {
  addRiskReducer,
  addWalletMentionsFactory,
  addWalletSummaryFactory,
  addWalletTransactionsBySatoshiFactory,
  addWalletTransactionsFactory,
  setDateRangeFactory
} from "./reducerFactories";
import {
  CUSTOM_WALLET_ADD_ADDRESSES,
  CUSTOM_WALLET_ADDRESSES_FETCH_SUCCESS,
  CUSTOM_WALLET_DELETE_ADDRESS,
  CUSTOM_WALLET_DELETE_SUCCESS,
  CUSTOM_WALLET_MENTIONS_FETCH_SUCCESS,
  CUSTOM_WALLET_SAVE_SUCCESS,
  CUSTOM_WALLET_SET_NAME,
  CUSTOM_WALLET_SUMMARY_FETCH_SUCCESS,
  CUSTOM_WALLET_TRANSACTIONS_FETCH_SUCCESS,
  CUSTOM_WALLET_TRANSACTIONS_SET_DATE_RANGE,
  CUSTOM_WALLET_TRANSACTIONS_SWAP_ORDER,
  LOGGING_OUT,
  WALLET_ADDRESSES_FETCH_SUCCESS,
  WALLET_ADDRESSES_SCROLL_TOP,
  WALLET_ASSOCIATIONS_FETCH_SUCCESS,
  WALLET_MENTIONS_FETCH_SUCCESS,
  WALLET_MENTIONS_PURGE,
  WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS,
  WALLET_MUTUAL_TRANSACTIONS_SWAP_ORDER,
  WALLET_MUTUAL_WALLETS_FETCH_FAILURE,
  WALLET_MUTUAL_WALLETS_FETCH_SUCCESS,
  WALLET_MUTUAL_WALLETS_TRANSACTIONS_SET_DATE_RANGE,
  WALLET_MUTUAL_WALLETS_TRANSACTIONS_SET_LARGEST_FIRST,
  WALLET_PEEL_CHAIN_FETCH_SUCCESS,
  WALLET_RECEIVED_WALLETS_FETCH_SUCCESS,
  WALLET_SENT_WALLETS_FETCH_SUCCESS,
  WALLET_SET_CUSTOM_TAG,
  WALLET_SET_PROCESS,
  WALLET_SUMMARY_FETCH_SUCCESS,
  WALLET_SUMMARY_PURGE,
  WALLET_TOGGLE_RECEIVED_FILTER,
  WALLET_TOGGLE_SENT_FILTER,
  WALLET_TRANSACTIONS_BY_SATOSHI_FETCH_SUCCESS,
  WALLET_TRANSACTIONS_FETCH_SUCCESS,
  WALLET_TRANSACTIONS_PURGE,
  WALLET_TRANSACTIONS_SCROLL_TOP,
  WALLET_TRANSACTIONS_SET_DATE_RANGE,
  WALLET_TRANSACTIONS_SWAP_ORDER,
  WALLET_UNCLUSTERED_FETCH_SUCCESS,
  WALLET_WALLET_MUTUAL_TRANSACTIONS_FETCH_FAILURE,
  WALLET_WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS,
  WALLET_TRANSACTIONS_SET_ORDER,
  MORE_WALLET_SUMMARY_FETCH_SUCCESS,
  WALLET_REMOVE_CUSTOM_TAG,
  WALLET_SET_NAMES,
  SET_WALLET_MAIN_NAME,
  WALLET_RISK_SCORE,
  RISK_SUCCESS
} from "../actions/actionNames";

import {
  CompoundKey,
  makeGetWalletRecord,
  Mentions,
  Transactions,
  WalletAddress,
  WalletMention,
  WalletMutualWallet,
  WalletName,
  WalletSummary,
  WalletTransaction
} from "./sharedRecords";
import { getAddressRecord, MutualWallets } from "./address";
import {
  addAddresses,
  clearWalletData,
  deleteAddress,
  deleteWalletInfo,
  setCustomWalletName,
  setProcessed
} from "./customWallet";
import { getCustomTagInfo, getWallet } from "../selectors/wallet";
import { getEmail } from "../selectors/authentication";

/*
Wallet State

// **NOTE** walletIds are **ALWAYS** strings.
// This is mostly cause I can't keep track of when a walletId may be implicitly
// converted to a string, which leads to a ton of confusion when debugging.
Wallet: Map<string, WalletRecord>,

WalletRecord: {
  summary: WalletSummary: {
    addressCount: number,
    inputCount: number,
    inputSatoshi: number,
    outputCount: number,
    outputSatoshi: number,
    firstTransaction: number,
    lastTransaction: number,
    transactionCount: number,
    balance: number,
    anchorAddress: string,
    primaryTag: string | null,
    originalTag: string | null,
    mentionsCount: number,
  },
  transactions: Transactions: {
    transactionMap: Map<string, Transaction>,
    newestFirst: List<number>,
    newestFirstNextKey: number,
    oldestFirst: List<number>,
    oldestFirstNextKey: number,
    largestFirst: List<number>,
    largestNextKey: CompoundKey,
    order: -1 | 1,
    startDate: number | null,
    endDate: number | null,
  },
  mentions: Mentions: {
    mentions: List<AddressMention>,
  },
  addresses: WalletAddresses: {
    addressRecords: List<WalletAddress>,
    primaryKey: number | null,
    secondaryKey: number | null,
  },
  associations: WalletAssociations: {
    associations: List<WalletAssociation>,
    nextKey: number | null,
  },
  sentWallets: AssociatedWallets: {
    wallets: List<AssociatedWallet>,
    filtered: bool,
    primaryKey: number | null,
    secondaryKey: number | null,
  },
  receivedWallets: AssociatedWallets: {
    wallets: List<AssociatedWallet>,
    filtered: bool,
    primaryKey: number | null,
    secondaryKey: number | null,
  },
  // string is the outputWalletId
  mutualTransactions: Map<string, MutualTransactions>,
  transactionsScrollTop: number | null,
  addressesScrollTop: number | null,
}

WalletAddress: {
  address: string,
  addressId: number,
  transactionCount: number,
}

WalletAssociation: {
  walletId: string,
  tag: string | null,
}

AssociatedWallet: {
  walletId: string,
  satoshi: number,
  tag: string | null,
  category: string | null,
}

CompoundKey: {
  primary: number,
  secondary: number,
}

// This is essentially the same as how we handle multiple sorts in addresses.
MutualTransactions: {
  transactionMap: Map<number, MutualTransaction>,
  newestFirst: List<number>,
  newestFirstNextKey: number | null,
  oldestFirst: List<number>,
  oldestFirstNextKey: number | null,
  largestFirst: List<number>,
  largestFirstNextKey: CompoundKey | null,
  smallestFirst: List<number>,
  smallestFirstNextKey: CompoundKey | null,
}
 */

export const WalletAssociations = Record({
  associations: List(),
  nextKey: 1
});

// Used in default view
export const WalletAssociation = Record({
  walletId: "",
  tag: ""
});

export const WalletAddresses = Record({
  addressRecords: List(),
  primaryKey: 0,
  secondaryKey: 0
});

// Used in graph view
export const AssociatedWallet = Record({
  walletId: "",
  satoshi: 0,
  tag: "",
  category: ""
});

// Associated wallets are similar to WalletAssociations,
// but only used in graph view
export const AssociatedWallets = Record({
  wallets: List(), // list of AssociatedWallets
  filtered: false,
  nextPrimaryKey: 0,
  nextSecondaryKey: 0,
  nextKey: 1
});

export const MutualTransactions = Record({
  transactionMap: Map(),
  newestFirst: List(),
  newestFirstNextKey: 0,
  oldestFirst: List(),
  oldestFirstNextKey: 0,
  largestFirst: List(),
  largestFirstNextKey: new CompoundKey(),
  smallestFirst: List(),
  smallestFirstNextKey: new CompoundKey()
});

export const MutualTransaction = Record({
  transactionHash: "",
  transactionId: 0,
  satoshi: 0,
  timestamp: 0,
  price: 0,
  priceAvailable: true
});

export const WalletUnclusteredAmounts = Record({
  unclustered_recv: List(),
  unclustered_sent: List()
});

export const WalletRecord = Record({
  summary: new WalletSummary(),
  name: new WalletName(),
  transactions: new Transactions(),
  mentions: new Mentions(),
  admentions: new Mentions(),
  addresses: new WalletAddresses(),
  associations: new WalletAssociations(),
  sentWallets: new AssociatedWallets(),
  receivedWallets: new AssociatedWallets(),
  mutual: Map(),
  mutualTransactions: new Map(),
  mutualWallets: new MutualWallets(),
  transactionsScrollTop: null,
  addressesScrollTop: null,
  unclustered_amounts: new WalletUnclusteredAmounts(),
  processed: true,
  peel: false,
  peel_chain_data: null,
  risk: null
});

export const getWalletRecord = makeGetWalletRecord(WalletRecord);

// doesn't clear the actual values, the action will handle it
export const swapOrder = (state, { walletId }) => {
  const order = state.getIn([walletId, "transactions", "order"]);
  return state.setIn([walletId, "transactions", "order"], order > 0 ? -1 : 1);
};

/**
 * Sets the order for wallet transactions. Used for sort by newest/oldest
 * @param state redux state
 * @param walletId wallet to change order for
 * @param order the new value of order
 * @returns {*}
 */
export const setOrder = (state, { walletId, order }) => {
  return state.setIn([walletId, "transactions", "order"], order);
};

// direction ['receivedWallets', 'sentWallets']
const addAssociatedWallets = direction => (state, { walletId, data }) => {
  let walletRecord = getWalletRecord(state, walletId);
  let wallets = walletRecord.getIn([direction, "wallets"], List());
  data[direction].forEach(associatedWallet => {
    const record = new AssociatedWallet(associatedWallet);
    wallets = wallets.push(record);
  });

  walletRecord = walletRecord
    .setIn([direction, "wallets"], wallets)
    .setIn([direction, "nextPrimaryKey"], data.primaryKey)
    .setIn([direction, "nextSecondaryKey"], data.secondaryKey);

  const tempState = state.set(walletId, walletRecord);
  return setWalletNames(tempState, { walletIds: data.names });
};

export const addReceivedWallets = addAssociatedWallets("receivedWallets");
export const addSentWallets = addAssociatedWallets("sentWallets");
export const addWalletTransactions = addWalletTransactionsFactory(WalletRecord);
export const addWalletMentions = addWalletMentionsFactory(WalletRecord, WalletMention);
export const addWalletTransactionsBySatoshi = addWalletTransactionsBySatoshiFactory(WalletRecord);

const toggleAssociatedFilter = direction => (state, { walletId }) => {
  const associatedWallets = state
    .getIn([walletId, direction])
    .set("wallets", List())
    .set("nextPrimaryKey", 0)
    .set("nextSecondaryKey", 0)
    .update("filtered", filtered => !filtered);
  return state.setIn([walletId, direction], associatedWallets);
};

export const toggleSentFilter = toggleAssociatedFilter("sentWallets");

export const toggleReceivedFilter = toggleAssociatedFilter("receivedWallets");

/**
 * Sets the date range of transactions to search and clears old transactions
 * @param state
 * @param action
 */
export const setDateRange = setDateRangeFactory(WalletRecord);

export const addWalletSummary = (state, action) => {
  const { item: walletId, data } = action;
  let walletRecord = state.get(walletId) || new WalletRecord();
  const walletSummary = new WalletSummary(data).set("fetched", true);
  walletRecord = walletRecord.set("summary", walletSummary);
  walletRecord = addWalletName(walletRecord, { walletId, name: data["name"] });
  return state.set(walletId, walletRecord);
};

/**
 * Takes the walletRecord state and sets the name to the provide name
 * @param walletRecord walletRecord in redux
 * @param name WalletName Object
 * @returns WalletRecord updated walletRecord state
 */
export const addWalletName = (walletRecord, { name }) => {
  //TODO expand on overwrite to not set state if name already loaded
  return walletRecord.set("name", new WalletName(name));
};

/**
 * Takes the state and dictionary of walletId -> WalletName and maps the wallet's name to the provided WalletName
 * @param state
 * @param walletIds
 * @returns {State}
 */
export const setWalletNames = (state, { walletIds }) => {
  let curState = state;
  Object.keys(walletIds).forEach(walletId => {
    // gets walletrecord if it exists if not make new one
    let walletRecord = state.get(walletId) || new WalletRecord();
    walletRecord = addWalletName(walletRecord, {
      walletId,
      name: walletIds[walletId]
    });
    //Sets wallet to modified walletRecord
    curState = curState.set(walletId, walletRecord);
  });
  return curState;
};

/**
 * Sets the main name for a wallet in the redux store. Goes through WalletName and adds {main: true} to the name that
 * matches the main name and makes sure that main is not true for the other names so only 1 name can be the main.
 * @param state
 * @param walletId wallet to change
 * @param mainName new main name
 * @returns {*}
 */
export const setMainName = (state, { walletId, mainName }) => {
  let walletMap = state.get(walletId);
  if (walletMap) {
    // names object which will be modified and replace the old names
    let names = walletMap.get("name").toJS();
    if (names["name"]) {
      // custom wallet name so the main name is always the custom wallet name
      return state;
    }

    // customTag is a list of names so we have to check all the names
    if (names["customTag"]) {
      const editIndex = names["customTag"].findIndex(elem => elem.name === mainName);
      names = {
        ...names,
        customTag: names["customTag"].map((elem, idx) => ({
          ...elem,
          ...(idx === editIndex ? { main: true } : { main: false })
        }))
      };
    }
    if (names["tag"]) {
      names = {
        ...names,
        tag: {
          ...names["tag"],
          ...(names["tag"].name === mainName ? { main: true } : { main: false })
        }
      };
    }
    if (names["wisteria_attribution"] && names["wisteria_attribution"]["score"] > 0.7) {
      names = {
        ...names,
        wisteria_attribution: {
          ...names["wisteria_attribution"],
          ...(names["wisteria_attribution"].name === mainName ? { main: true } : { main: false })
        }
      };
    }
    walletMap = walletMap.set("name", new WalletName({ ...names }));
    return state.set(walletId, walletMap);
  }
  return state;
};

export const addWalletAddresses = (state, { walletId, data }) => {
  let walletRecord = getWalletRecord(state, walletId);
  let addresses = walletRecord.getIn(["addresses", "addressRecords"]);
  data.addresses.forEach(address => {
    const addressRecord = new WalletAddress(address);
    addresses = addresses.push(addressRecord);
  });

  walletRecord = walletRecord
    .setIn(["addresses", "addressRecords"], addresses)
    .setIn(["addresses", "primaryKey"], data.primaryKey)
    .setIn(["addresses", "secondaryKey"], data.secondaryKey);
  return state.set(walletId, walletRecord);
};

const addWalletAssociations = (state, { walletId, data }) => {
  const { hasMoreItems } = data;
  let walletRecord = getWalletRecord(state, walletId);
  let associations = walletRecord.getIn(["associations", "associations"]);
  data.associations.forEach(association => {
    const associationRecord = new WalletAssociation(association);
    associations = associations.push(associationRecord);
  });

  walletRecord = walletRecord
    .setIn(["associations", "associations"], associations)
    .updateIn(["associations", "nextKey"], nextKey => (hasMoreItems ? nextKey + 1 : null));
  return state.set(walletId, walletRecord);
};

const setCustomTag = (state, { walletId, primaryTag, email, id, permission }) => {
  let walletRecord = getWalletRecord(state, walletId);
  const curName = walletRecord.get("name") && walletRecord.get("name").toJS();
  const curCustomTags = walletRecord.getIn(["name", "customTag"])
    ? walletRecord.getIn(["name", "customTag"])
    : [];
  const editIndex = curCustomTags.findIndex(el => el.id === id);
  // make what the new custom tags should be either change existing or append new row for a new one
  let newCustomTags;
  if (editIndex === -1) {
    newCustomTags = [...curCustomTags, { name: primaryTag, email, id, permission }];
  } else {
    newCustomTags = curCustomTags.map(customTag => ({
      ...customTag,
      name: customTag.id === id ? primaryTag : customTag.name
    }));
  }
  walletRecord = addWalletName(walletRecord, {
    walletId,
    name: {
      ...(curName && curName),
      customTag: newCustomTags
    }
  });
  return state.set(walletId, walletRecord);
};

const removeCustomTag = (state, { walletId, id }) => {
  let walletRecord = state.get(walletId);
  if (!walletRecord) {
    return state;
  }
  const curName = walletRecord.get("name") && walletRecord.get("name").toJS();
  let curCustomTags = curName ? walletRecord.getIn(["name", "customTag"]) : [];
  curCustomTags = curCustomTags.filter(item => item.id !== id);
  walletRecord = walletRecord.setIn(["name", "customTag"], curCustomTags);
  return state.set(walletId, walletRecord);
};

const addUnclusteredAmounts = (state, { walletId, data }) => {
  let walletRecord = state.get(walletId) || new WalletRecord();
  walletRecord = walletRecord.set(
    "unclustered_amounts",
    WalletUnclusteredAmounts({
      unclustered_recv: new List(data.unclustered_recv),
      unclustered_sent: new List(data.unclustered_sent)
    })
  );

  return state.set(walletId, walletRecord);
};

const addPeelChainData = (state, { walletId, data }) => {
  let walletRecord = state.get(walletId) || new WalletRecord();
  walletRecord = walletRecord.set("peel", data.peel);
  walletRecord = walletRecord.set("peel_chain_data", data.peel_chain_data);

  return state.set(walletId, walletRecord);
};

const addMutualTransactions = (state, { data, inputWalletId, outputWalletId, order }) => {
  let walletRecord = state.get(inputWalletId) || new WalletRecord();

  if (walletRecord.getIn(["mutualTransactions", outputWalletId]) == null) {
    walletRecord = walletRecord.setIn(
      ["mutualTransactions", outputWalletId],
      new MutualTransactions()
    );
  }

  // Update the transactionMap for every value (might be some duplicates, but map
  // will take care of it.
  walletRecord = walletRecord.updateIn(
    ["mutualTransactions", outputWalletId, "transactionMap"],
    transactionMap => {
      data.transactions.forEach(transaction => {
        const { transactionId } = transaction;
        const mutualTransaction = new MutualTransaction(transaction);
        transactionMap = transactionMap.set(transactionId, mutualTransaction);
      });
      return transactionMap;
    }
  );

  walletRecord = walletRecord.updateIn(
    ["mutualTransactions", outputWalletId, order],
    largestFirst => {
      data.transactions.forEach(transaction => {
        const { transactionId } = transaction;
        largestFirst = largestFirst.push(transactionId);
      });
      return largestFirst;
    }
  );

  if (order === "largestFirst" || order === "smallestFirst") {
    const { primaryKey, secondaryKey } = data;
    const compoundKey = new CompoundKey({
      primary: primaryKey,
      secondary: secondaryKey
    });
    walletRecord = walletRecord.setIn(
      ["mutualTransactions", outputWalletId, `${order}NextKey`],
      compoundKey
    );
  } else {
    const { key } = data;
    walletRecord = walletRecord.setIn(
      ["mutualTransactions", outputWalletId, `${order}NextKey`],
      key
    );
  }

  return state.set(inputWalletId, walletRecord);
};

const makeScrollTop = key => (state, action) => {
  const { walletId, scrollTop } = action;

  let walletRecord = getWalletRecord(state, walletId);
  walletRecord = walletRecord.set(key, scrollTop);

  return state.set(walletId, walletRecord);
};

const setTransactionsScrollTop = makeScrollTop("transactionsScrollTop");

const setAddressesScrollTop = makeScrollTop("addressesScrollTop");

const addWalletMutualWallets = (state, action) => {
  const { item: address_, data } = action;
  let walletRecord = getWalletRecord(state, address_);
  let mutualWallets = walletRecord.getIn(["mutualWallets", "mutualWallets"]);

  // Create an AddressMutualWallet object for each mutualWallet and add them to the map
  data.forEach(mutualWallet => {
    mutualWallets = mutualWallets.set(
      mutualWallet.walletId,
      new WalletMutualWallet({
        walletId: mutualWallet.walletId,
        tag: mutualWallet.tag,
        category: mutualWallet.category,
        totalTrx: mutualWallet.transactionCount,
        firstTrx: mutualWallet.firstTransaction,
        lastTrx: mutualWallet.lastTransaction,
        sent: mutualWallet.sent,
        recv: mutualWallet.recv,
        sentCount: mutualWallet.sentCount,
        recvCount: mutualWallet.recvCount
      })
    );
  });

  // Set the mutualWallets portion of the store to the list of mutualWallets
  walletRecord = walletRecord
    .setIn(["mutualWallets", "mutualWallets"], mutualWallets)
    .setIn(["mutualWallets", "mostRecentCallSuccess"], true)
    .setIn(["mutualWallets", "walletsFetched"], true);
  return state.set(address_, walletRecord);
};

const addWalletMutualWalletsFailure = (state, action) => {
  const { item: address_ } = action;
  let walletRecord = getWalletRecord(state, address_);

  // Set the mutualWallets portion of the store to the list of mutualWallets
  walletRecord = walletRecord.setIn(["mutualWallets", "mostRecentCallSuccess"], false);
  return state.set(address_, walletRecord);
};

const addWalletMutualTransactions = (state, action) => {
  const { item: address_, walletId, order, data } = action;
  let walletRecord = getWalletRecord(state, address_);
  let transactions = walletRecord.getIn([
    "mutualWallets",
    "mutualWallets",
    walletId,
    "transactions"
  ]);
  let transactionMap = transactions.get("transactionMap");

  // determine whether the fetched data is largest first, newest first or oldest first
  const key = order;
  let transactionList = transactions.get(key);

  // Create an Transaction object for each mutualWallet and add them to the map
  data.transactions.forEach(transaction => {
    const {
      transactionId,
      transactionHash,
      satoshi,
      timestamp,
      price,
      priceAvailable
    } = transaction;
    transactionMap = transactionMap.set(
      transactionId,
      new WalletTransaction({
        transactionId,
        transactionHash,
        timestamp,
        satoshi,
        price,
        priceAvailable
      })
    );
    transactionList = transactionList.push(transactionId);
  });

  // Set the transactionmap and transactionlist in the transactions object
  transactions = transactions
    .set("transactionMap", transactionMap)
    .set(key, transactionList)
    .set(`${key}NextKey`, data.key)
    .set("mostRecentCallSuccess", true);

  walletRecord = walletRecord
    .setIn(["mutualWallets", "mutualWallets", walletId, "transactions"], transactions)
    .setIn(["mutualWallets", "mutualWallets", walletId, "mostRecentCallSuccess"], true);
  return state.set(address_, walletRecord);
};

const addWalletMutualTransactionsFailure = (state, action) => {
  const { item: address_, walletId } = action;
  let walletRecord = getWalletRecord(state, address_);
  let record = walletRecord.getIn(["mutualWallets", "mutualWallets", walletId]);
  record = record.set("mostRecentCallSuccess", false);

  walletRecord = walletRecord.setIn(["mutualWallets", "mutualWallets", walletId], record);
  return state.set(address_, walletRecord);
};

const mutualTransactionSwapOrder = (state, action) => {
  const { address: address_, walletId } = action;

  let walletRecord = getWalletRecord(state, address_);
  let mutualWallets = walletRecord.getIn(["mutualWallets", "mutualWallets", walletId]);

  let transactions = mutualWallets.get("transactions");
  const order = transactions.get("order");
  const newOrder =
    order === "largestFirst" || order === "oldestFirst" ? "newestFirst" : "oldestFirst";

  if (transactions.get("startDate") !== null || transactions.get("endDate") !== null) {
    transactions = transactions
      .set("order", newOrder)
      .set("newestFirst", List())
      .set("oldestFirst", List())
      .set("newestFirstNextKey", 0)
      .set("oldestFirstNextKey", 0)
      .set("startDate", null)
      .set("endDate", null);
  } else {
    transactions = transactions
      .set("order", newOrder)
      .set("startDate", null)
      .set("endDate", null);
  }

  walletRecord = walletRecord.setIn(
    ["mutualWallets", "mutualWallets", walletId, "transactions"],
    transactions
  );
  return state.set(address_, walletRecord);
};

const walletMutualTransactionSetLargestFirst = (state, action) => {
  const { address: address_, walletId, startDate, endDate } = action;

  let walletRecord = getWalletRecord(state, address_);
  let mutualWallets = walletRecord.getIn(["mutualWallets", "mutualWallets", walletId]);

  // If a date range was set before, clear the previous order's array
  let transactions;
  if (
    mutualWallets.getIn(["transactions", "startDate"]) !== null ||
    mutualWallets.getIn(["transactions", "endDate"]) !== null
  ) {
    transactions = mutualWallets
      .get("transactions")
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("order", "largestFirst")
      .set("newestFirst", List())
      .set("oldestFirst", List());
  } else {
    transactions = mutualWallets
      .get("transactions")
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("order", "largestFirst");
  }

  walletRecord = walletRecord.setIn(
    ["mutualWallets", "mutualWallets", walletId, "transactions"],
    transactions
  );
  return state.set(address_, walletRecord);
};

/**
 * Sets the date range of transactions for the selected mutual wallet to search and clears old transactions
 * @param state
 * @param action
 */
const setDateRangeMutualWallets = (state, action) => {
  const { address: address_, walletId, tag, startDate, endDate } = action;

  let walletRecord = getWalletRecord(state, address_);
  let mutualWallets = walletRecord.getIn(["mutualWallets", "mutualWallets", walletId]);
  // Set the start and end dates, as well as resetting the trx's for the current sorting order
  const transactions = mutualWallets
    .get("transactions")
    .set("newestFirst", List())
    .set(`newestFirstNextKey`, 0)
    .set("order", "newestFirst")
    .set("startDate", startDate)
    .set("endDate", endDate);
  walletRecord = walletRecord.setIn(
    ["mutualWallets", "mutualWallets", walletId, "transactions"],
    transactions
  );
  return state.set(address_, walletRecord);
};

const purgeTransactions = (state, action) => {
  const { walletId } = action;
  let walletRecord = getWalletRecord(state, walletId);

  walletRecord = walletRecord.delete("transactions");
  return state.set(walletId, walletRecord);
};

const purgeSummary = (state, action) => {
  const { walletId } = action;
  let walletRecord = getWalletRecord(state, walletId);

  walletRecord = walletRecord.delete("summary");
  return state.set(walletId, walletRecord);
};

const setRiskScore = (state, { walletId, risk }) => {
  let walletRecord = state.get(walletId);
  let walletSummary = walletRecord.get("summary");
  walletSummary = walletSummary.set("risk", risk);
  walletRecord = walletRecord.set("summary", walletSummary);
  return state.set(walletId, walletRecord);
};

const addMoreWalletSummary = (state, action) => {
  const { item: walletId, data } = action;
  let walletRecord = state.get(walletId);
  let walletSummary = walletRecord.get("summary");
  for (const [key, value] of Object.entries(data)) {
    walletSummary = walletSummary.set(key, value);
  }
  walletRecord = walletRecord.set("summary", walletSummary);
  return state.set(walletId, walletRecord);
};

const purgeMentions = (state, action) => {
  const { walletId } = action;
  let walletRecord = getWalletRecord(state, walletId);

  walletRecord = walletRecord.delete("mentions");
  return state.set(walletId, walletRecord);
};

const makeWalletReducer = coin => {
  return (state = Map(), action) => {
    // If the coin doesn't match the reducer, just return the state.
    if (action === undefined || action.name !== coin) {
      return state;
    }

    if (action && action.entityType && action.entityType !== "wallet") {
      return state;
    }

    switch (action.type) {
      case CUSTOM_WALLET_SET_NAME:
        return setCustomWalletName(state, action);
      case WALLET_SUMMARY_FETCH_SUCCESS:
        return addWalletSummary(state, action);
      case MORE_WALLET_SUMMARY_FETCH_SUCCESS:
        return addMoreWalletSummary(state, action);
      case WALLET_SUMMARY_PURGE:
        return purgeSummary(state, action);
      case WALLET_TRANSACTIONS_FETCH_SUCCESS:
        return addWalletTransactions(state, action);
      case WALLET_TRANSACTIONS_PURGE:
        return purgeTransactions(state, action);
      case WALLET_TRANSACTIONS_SET_ORDER:
        return setOrder(state, action);
      case WALLET_TRANSACTIONS_SWAP_ORDER:
        return swapOrder(state, action);
      case WALLET_TRANSACTIONS_SET_DATE_RANGE:
        return setDateRange(state, action);
      case WALLET_RISK_SCORE:
        return setRiskScore(state, action);
      case WALLET_MENTIONS_PURGE:
        return purgeMentions(state, action);
      case WALLET_MENTIONS_FETCH_SUCCESS:
        return addWalletMentions(state, action);
      case WALLET_ADDRESSES_FETCH_SUCCESS:
        return addWalletAddresses(state, action);
      case WALLET_ASSOCIATIONS_FETCH_SUCCESS:
        return addWalletAssociations(state, action);
      case WALLET_SENT_WALLETS_FETCH_SUCCESS:
        return addSentWallets(state, action);
      case WALLET_RECEIVED_WALLETS_FETCH_SUCCESS:
        return addReceivedWallets(state, action);
      case WALLET_TOGGLE_RECEIVED_FILTER:
        return toggleReceivedFilter(state, action);
      case WALLET_TOGGLE_SENT_FILTER:
        return toggleSentFilter(state, action);
      case WALLET_SET_CUSTOM_TAG:
        return setCustomTag(state, action);
      case WALLET_REMOVE_CUSTOM_TAG:
        return removeCustomTag(state, action);
      case WALLET_TRANSACTIONS_BY_SATOSHI_FETCH_SUCCESS:
        return addWalletTransactionsBySatoshi(state, action);
      case WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS:
        return addMutualTransactions(state, action);
      case WALLET_TRANSACTIONS_SCROLL_TOP:
        return setTransactionsScrollTop(state, action);
      case WALLET_ADDRESSES_SCROLL_TOP:
        return setAddressesScrollTop(state, action);
      case WALLET_MUTUAL_WALLETS_FETCH_SUCCESS:
        return addWalletMutualWallets(state, action);
      case WALLET_MUTUAL_WALLETS_FETCH_FAILURE:
        return addWalletMutualWalletsFailure(state, action);
      case WALLET_WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS:
        return addWalletMutualTransactions(state, action);
      case WALLET_WALLET_MUTUAL_TRANSACTIONS_FETCH_FAILURE:
        return addWalletMutualTransactionsFailure(state, action);
      case WALLET_MUTUAL_TRANSACTIONS_SWAP_ORDER:
        return mutualTransactionSwapOrder(state, action);
      case WALLET_MUTUAL_WALLETS_TRANSACTIONS_SET_LARGEST_FIRST:
        return walletMutualTransactionSetLargestFirst(state, action);
      case WALLET_MUTUAL_WALLETS_TRANSACTIONS_SET_DATE_RANGE:
        return setDateRangeMutualWallets(state, action);
      case WALLET_UNCLUSTERED_FETCH_SUCCESS:
        return addUnclusteredAmounts(state, action);
      case WALLET_PEEL_CHAIN_FETCH_SUCCESS:
        return addPeelChainData(state, action);
      case CUSTOM_WALLET_SUMMARY_FETCH_SUCCESS:
        return addWalletSummary(state, action);
      case CUSTOM_WALLET_TRANSACTIONS_FETCH_SUCCESS:
        return addWalletTransactions(state, action);
      case CUSTOM_WALLET_MENTIONS_FETCH_SUCCESS:
        return addWalletMentions(state, action);
      case CUSTOM_WALLET_TRANSACTIONS_SWAP_ORDER:
        return swapOrder(state, action);
      case CUSTOM_WALLET_TRANSACTIONS_SET_DATE_RANGE:
        return setDateRange(state, action);
      case CUSTOM_WALLET_ADD_ADDRESSES:
        return addAddresses(state, action);
      case CUSTOM_WALLET_DELETE_ADDRESS:
        return deleteAddress(state, action);
      case CUSTOM_WALLET_ADDRESSES_FETCH_SUCCESS:
        return addAddresses(state, action);
      case CUSTOM_WALLET_SAVE_SUCCESS:
        return clearWalletData(state, action);
      case CUSTOM_WALLET_DELETE_SUCCESS:
        return deleteWalletInfo(state, action);
      case WALLET_SET_PROCESS:
        return setProcessed(state, action);
      case WALLET_SET_NAMES:
        return setWalletNames(state, action);
      case SET_WALLET_MAIN_NAME:
        return setMainName(state, action);
      case RISK_SUCCESS:
        return addRiskReducer(getWalletRecord, action.entityType)(state, action);
      case LOGGING_OUT:
        return Map();
      default:
        return state;
    }
  };
};

export default makeWalletReducer;
