/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, FormControl, FormGroup, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../css/RichEditor.css";
import "../../css/styles.css";

import { faSearch } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import { fetchSearchResult } from "../actions/search";
import { getClientMode } from "../selectors/applicationConfig";
import ClientModeConfig from "./ClientModeConfig";
import { Searchbar } from "./Searchbar";
import axios, { SEARCH_API } from "../api";
import { SEARCH_SUCCESS } from "../actions/actionNames";
import { getCurrency } from "../selectors/currency";
import history from "./history";
import { getWalletNameHelper } from "../selectors/wallet";
import { getEmail } from "../selectors/authentication";

/**
 * SearchBar form that searches tags, wallets, transactions, and addresses
 * with partial search as applicable.
 */
export const Search = ({ wide = "30%", fromNav = false }) => {
  let clientMode = useSelector(state => getClientMode(state));
  const name = useSelector(state => getCurrency(state));
  const email = useSelector(state => getEmail(state));
  let dispatch = useDispatch();
  let clientModeConfigObject = {
    word_for_collection: "",
    cap_word_for_collection: "",
    plural_for_collection: "",
    cap_plural_for_collection: "",
    nav_logo: null,
    login_logo: null,
    login_top_logo: null,
    login_top_icon: null
  };
  if (clientMode in ClientModeConfig) {
    clientModeConfigObject = ClientModeConfig[clientMode];
  }

  /**
   * Searches the value. If there is only one possible result,
   * a redirect to that result is made. If there are multiple,
   * a redirect to the partial search page is made. Otherwise,
   * redirect to 404? (why?) Probably old behavior before
   * partial search is made.
   * @returns {Promise<*[]|[]>}
   * @param value
   */
  const handleSearch = async (value, currency) => {
    let searchResults;
    const api = `${SEARCH_API(currency)}`;

    try {
      ({ data: searchResults } = await axios.get(api, {
        params: { query: value, category: "all", limit: 5, no_stats: true }
      }));
    } catch (err) {
      throw err;
    }
    const queryResults = [];
    searchResults &&
      searchResults.addresses &&
      searchResults.addresses.forEach(({ address, url, tag, contract }) => {
        const addHash = address.split(":").pop();
        queryResults.push({
          type: "Address",
          label: contract || tag || addHash,
          ...(contract && { contract: contract }),
          url: url
        });
      });
    searchResults &&
      searchResults.transactions &&
      searchResults.transactions.forEach(transaction => {
        queryResults.push({
          type: "Transaction",
          label: transaction["transaction_hash"],
          url: transaction["url"]
        });
      });
    searchResults &&
      searchResults.custom_wallets &&
      searchResults.custom_wallets.forEach(({ name, url }) => {
        queryResults.push({
          type: "Custom Wallet",
          label: getWalletNameHelper(name, email)[0]["name"],
          url: url
        });
      });
    // TODO make custom  a list of custom tags need helper function to extract it
    searchResults &&
      searchResults.wallets &&
      searchResults.wallets.forEach(({ name, url, custom }) => {
        const names = getWalletNameHelper(name, email);
        const custom_tags = names.reduce((filtered, { name, tagType }) => {
          tagType === "customTag" && filtered.push(name);
          return filtered;
        }, []);
        queryResults.push({
          type: "Wallet",
          label: getWalletNameHelper(name, email, true)[0]["name"],
          ...(custom_tags && { custom: custom_tags }),
          url: url
        });
      });
    return queryResults;
  };

  const handleSelectOption = option => {
    // Search url doesn't have the currency name in it
    option.type === "Search"
      ? history.push(`${option.url}`)
      : history.push(`/${name}${option.url}`);
  };

  const manualSearch = query => {
    dispatch(fetchSearchResult(query));
  };

  return (
    <Navbar.Form style={{ width: wide }}>
      <Searchbar
        label={"Address/Transaction/Wallet"}
        query={handleSearch}
        handleSelect={handleSelectOption}
        manualSearch={manualSearch}
      />
    </Navbar.Form>
  );
};

Search.propTypes = {
  wide: PropTypes.string,
  fromNav: PropTypes.bool
};
