/*
 * Copyright 2018-2021  DecisionQ Information Operations, Inc. All Rights Reserved.
 */

import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { useHistory, withRouter } from "react-router-dom";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import ClientModeConfig from "../../ClientModeConfig";
import { getClientMode } from "../../../selectors/applicationConfig";
import axios, { CUSTOM_WALLETS_API } from "../../../api";
import { getCurrency } from "../../../selectors/currency";
import { customWalletAddAddresses } from "../../../actions/customWallet";
import { getFeature } from "../../../selectors/features";

const filter = createFilterOptions();

/**
 * AddToCustomWallet is the 'add to custom wallet' button on address pages to quickly
 * add addresses to custom wallets. The button opens a Material UI Dialog with a text box
 * the text box is an autocomplete with free solo from Material UI.
 *
 * The text box shows all the options the user has to add the address to custom wallets and if they
 * type something that doesn't exist, it presents the option to add a new custom wallet
 * @param entityId: the id of the entity we are adding (addressId)
 * @param clientMode  has overarching website styling and word choice
 * @param currency The cryptocurrency we are selected on
 * @param fontSize Fontsize of the button
 * @param addAddress Dispatch prop function for handling adding the address id
 * @param customWalletsEnabled Whether the custom wallet features is on to show the component
 * @returns {JSX.Element}
 * @constructor
 */
const AddToCustomWallet = ({
  entityId,
  clientMode,
  currency,
  fontSize,
  addAddress,
  customWalletsEnabled = true,
}) => {
  const history = useHistory(); // history object to change page to custom wallet
  const [show, setShow] = useState(false); // To show the add new custom wallet Dialog
  const [loading, setLoading] = useState(true); // Loading results for autocomplete
  const [customWallets, setCustomWallets] = useState([]); // List of found custom wallets for user
  const [value, setValue] = useState(null); // Value selected by user to use for custom wallet
  const [add, setAdd] = useState(false); // Whether the add button was pressed
  const { enqueueSnackbar, closeSnackbar } = useSnackbar(); // Notification snackbar

  // Getting the right word to display for wallets
  let clientModeConfigObject = {
    word_for_collection: "",
    cap_word_for_collection: "",
    plural_for_collection: "",
    cap_plural_for_collection: "",
    nav_logo: null,
    login_logo: null,
  };
  if (clientMode in ClientModeConfig) {
    clientModeConfigObject = ClientModeConfig[clientMode];
  }

  /**
   * Handles the case when the 'add to custom wallet' button is pressed and the dialog pops up.
   * Show represents the state of showing the Dialog, first we must request for the wallets
   * that belong to this user and display them
   */
  useEffect(() => {
    (async () => {
      if (show) {
        // Addresses changed need to refetch all the transactions.
        setLoading(true); // Loading for the autocomplete
        const { data: walletsData } = await axios.get(CUSTOM_WALLETS_API(currency));
        setCustomWallets(walletsData);
        setLoading(false);
      }
    })();
    if (!show) {
      //if closing set value for textbox to nothing
      setValue(null);
    }
  }, [show]);

  /**
   * Handles the cases when the user presses the add button in the Dialog.
   * We could either have to create a new custom wallet when they add a new one
   * or add an address to an existing custom wallet. We also reroute them to
   * the custom wallet page
   */
  useEffect(() => {
    // value represents the value of the autocomplete
    if (!value) {
      // if undefined ignore
      return;
    }
    (async () => {
      // Whether the value matches another of the users clusters
      const existing = customWallets.filter(cluster => cluster.name === value.name)[0];

      // Custom wallet already exists so add address to the existing one
      if (existing) {
        if (existing.clusterId) {
          addAddress(existing.clusterId, [entityId], enqueueSnackbar).catch(err => {
            // notify user of errors trying to add addresses
            if (err.response.status === 406) {
              enqueueSnackbar(`Exceeded Address Limit (${err.response.data})`, {
                variant: "error",
              });
            } else {
              enqueueSnackbar("Error adding address", { variant: "error" });
            }
          }); // Add address

          // direct them to custom wallet page
          history.replace(`/${currency}/custom-wallet/${existing.clusterId}`);
        }
        setShow(false);
      } else {
        // Wallet didn't exists we have to create a new one and add the address to it
        // API call to create new custom wallet with name, if error put notification
        const { data } = await axios
          .post(CUSTOM_WALLETS_API(currency), {
            name: value.name,
          })
          .catch(() =>
            enqueueSnackbar(
              `Error making custom ${clientModeConfigObject.cap_word_for_collection}`,
              { variant: "error" }
            )
          );

        // get clusterId from api call add address and reroute them to the wallet's page
        const { clusterId } = data;
        if (clusterId) {
          addAddress(clusterId, [entityId]).catch(err => {
            // notify user of errors trying to add addresses
            if (err.response.status === 406) {
              enqueueSnackbar(`Exceeded Address Limit (${err.response.data})`, {
                variant: "error",
              });
            } else {
              enqueueSnackbar("Error adding address", { variant: "error" });
            }
          });
          history.push(`/${currency}/custom-wallet/${clusterId}`);
        }
      }
      setAdd(false);
    })();
  }, [add]);

  return (
    <>
      {/* Show div only if custom wallets is enabled */}
      {customWalletsEnabled && (
        <>
          <Button onClick={() => setShow(true)} className="greenButton">
            <span>
              <FontAwesomeIcon icon={faWallet} style={{ fontSize, marginRight: "7px" }} />
              Add to Custom {clientModeConfigObject.cap_word_for_collection}
            </span>
          </Button>
          {/* Dialog with Autocomplete that opens when button pressed */}
          <Dialog fullWidth open={show} onClose={() => setShow(false)}>
            <DialogTitle>Add Custom {clientModeConfigObject.cap_word_for_collection}</DialogTitle>
            <DialogContent>
              <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setValue({
                      name: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setValue({
                      name: newValue.inputValue,
                    });
                  } else {
                    setValue(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params); // filter custom wallets

                  // Suggest the creation of a new value when not empty and not an existing name
                  if (
                    params.inputValue.trim() !== "" &&
                    !filtered.some(names => names.name === params.inputValue)
                  ) {
                    filtered.push({
                      inputValue: params.inputValue,
                      name: `Add "${params.inputValue}" as a custom ${clientModeConfigObject.cap_word_for_collection}`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={customWallets}
                loading={loading}
                size="medium"
                getOptionLabel={option => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name;
                }}
                renderOption={option => <Typography style={{ fontSize }}>{option.name}</Typography>}
                freeSolo
                renderInput={params => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, maxLength: 50, style: { fontSize } }}
                    InputLabelProps={{ style: { fontSize } }}
                    label="Name"
                  />
                )}
              />
            </DialogContent>
            {/* Add button or cancel that closes dialog */}
            <DialogActions>
              <Button
                disabled={!value || value.name.trim().length <= 0}
                onClick={() => setAdd(true)}
                className="greenButton"
              >
                Add
              </Button>
              <Button onClick={() => setShow(false)} className="issueButton">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

AddToCustomWallet.propTypes = {
  currency: PropTypes.string.isRequired,
  addAddress: PropTypes.func.isRequired,
  customWalletsEnabled: PropTypes.bool.isRequired,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fontSize: PropTypes.string,
  clientMode: PropTypes.string.isRequired,
};

AddToCustomWallet.defaultProps = {
  fontSize: "1.5rem",
};

function mapStateToProps(state) {
  return {
    clientMode: getClientMode(state),
    currency: getCurrency(state),
    customWalletsEnabled: getFeature(state, "custom_wallets"),
  };
}

const mapDispatchToProps = dispatch => ({
  addAddress: (walletId, address, snackbar) =>
    dispatch(customWalletAddAddresses(walletId, address, snackbar)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddToCustomWallet)
);
