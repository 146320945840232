// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import moment from "moment";
import axios, { USER_API } from "./api";
import { OverlayTrigger } from "react-bootstrap";
import React from "react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import { FullWidthPopover } from "./components/styled";

const _satoshiToBitcoin = (satoshi, precision) => {
  // Take satoshi int and convert it to bitcoin string representation
  // with 8 decimal places and commas
  const bitcoin = (satoshi / 100000000).toFixed(precision);
  const index = bitcoin.indexOf(".");
  // decimal portion
  let result = bitcoin.substring(index);
  const endIndex = bitcoin[0] === "-" ? 1 : 0;

  // Add commas to number
  for (let i = bitcoin.indexOf(".") - 1, counter = 0; i >= endIndex; i -= 1) {
    result = bitcoin[i] + result;
    counter += 1;
    if (counter === 3 && i !== endIndex) {
      result = `,${result}`;
      counter = 0;
    }
  }

  if (endIndex) {
    result = `-${result}`;
  }
  return result;
};

/**
 * Convert integral satoshi to bitcoin (8 decimal places)
 * 8 decimal places are always displayed and commas are inserted
 * as needed.
 * @param {number} satoshi - Bitcoin measured in satoshi
 * @return {string} Bitcoin measured in bitcoins
 */
export const satoshiToBitcoin = satoshi => _satoshiToBitcoin(satoshi, 8);

export const satoshiToBitcoinNoCommas = satoshi => {
  // Take satoshi int and convert it to bitcoin string representation
  // with 8 decimal places and commas
  return (satoshi / 100000000).toFixed(8);
};

/**
 * This converts satoshi to a bitcoin value with 3 decimal places.
 *
 * Satoshi less than 100,000 (would need more than 3 decimal places), will be converted
 * to '<0.001'.
 */
export const satoshiToBitcoin3 = satoshi => {
  if (satoshi < 100000) {
    return "<0.001";
  }

  return _satoshiToBitcoin(satoshi, 3);
};

export const bitcoinToSatoshi = bitcoin => {
  // Take satoshi int and convert it to bitcoin string representation
  // with 8 decimal places and commas
  const satoshi = (bitcoin * 100000000).toFixed(8); // decimal portion
  return satoshi;
};

/**
 * Converts a bitcoin string like 79,000.23221111 (output of satoshiToBitcoin) back
 * into a satoshi (7900023221111)
 */
export const bitcoinStringToSatoshi = bitcoin => {
  // parseFloat("66,839.96090018") returns 66...
  return parseFloat(bitcoin.replace(/,/g, "")) * 100000000;
};

/**
 * Shorten transaction hash depending on what the viewport width is.
 * @param {string} transactionHash
 * @return {string}
 */
export const shortenTransactionHash = transactionHash => {
  let beginning;
  let end;
  if (window.outerWidth < 1400) {
    beginning = transactionHash.substr(0, 15);
    end = transactionHash.substr(49, 15);
  } else if (window.outerWidth < 1500) {
    beginning = transactionHash.substr(0, 17);
    end = transactionHash.substr(47, 17);
  } else if (window.outerWidth < 1700) {
    beginning = transactionHash.substr(0, 23);
    end = transactionHash.substr(41, 23);
  } else {
    return transactionHash;
  }
  return `${beginning}..${end}`;
};

/**
 * Make long bech32 addresses shorter.
 * @param {string} address
 */
export const shortenAddress = address => {
  if (address.length > 35) {
    return `${address.substr(0, 17)}..${address.substr(address.length - 17, 17)}`;
  }
  return address;
};

// react-bootstrap complains if onToggle isn't passed to the Panel component
// The component doesn't seem to use it, so a noop is just passed
export const noop = () => {};

/**
 * Changes input values for forms in component
 * @param {event} e onChange event with forms
 */
export function onInputChange(e) {
  const { fields } = this.state;
  if (e.target.type !== "checkbox") {
    fields[e.target.id] = e.target.value;
  } else {
    fields[e.target.id] = e.target.checked;
  }
  this.setState({ fields });
}

/**
 * Get unix timestamp from form date string. Returns null
 * if null is passed as date.
 * @param date
 * @param offset the value to offset by. e.g. 1
 * @param offsetUnit the unit of measurement to offset by e.g. 'days'
 * @returns {number|null}
 */
export const getUnixTimestampFromDate = (date, offset = null, offsetUnit = null) => {
  if (!date) {
    return null;
  }

  const time = moment.utc(date);
  if (offset != null && offsetUnit != null) {
    time.add(offset, offsetUnit);
  }

  return time.unix();
};

/**
 * Calls redux api call to log redux call
 * @param data data from LogEntry in redux-logger
 * @returns {Promise<boolean>}
 */
export async function reduxLog(data) {
  try {
    await axios.post(`${USER_API}/redux`, {
      ...data
    });
    return true;
  } catch (e) {
    throw e;
  }
}

/**
 * Helper function to call error api call to log Javascript errors in api
 * @param message error message
 * @param stack stacktrace
 * @param componentStack If react error the componentStack
 * @param extra Any extra information
 * @returns {Promise<void>}
 */
export const logError = async (message, stack, componentStack, extra = {}) => {
  try {
    extra = {
      ...extra,
      userAgent: navigator.userAgent
    }; // taking note of broswer version since that might be useful
    await axios.post(`${USER_API}/error`, {
      message,
      stack,
      componentStack,
      extra
    });
  } catch (e) {
    //Don't throw error for logError or the error catcher will catch it and start infinite loop of errors
    console.error(e);
  }
};

// This should take care of un-minifying stacktraces.
export const stringifyStack = stackframes => {
  const stringifiedStack = stackframes
    .map(sf => {
      return sf.toString();
    })
    .join("\n");
  return stringifiedStack;
};

export const handleCheckMatch = (setState, newPassword1, newPassword2) => {
  // If either are blank just return
  if (newPassword1.length === 0 || newPassword2.length === 0) {
    setState({
      pwMatch: true
    });
    return;
  }

  // Check for all of the requirements and set component state accordingly
  setState({
    pwMatch: newPassword1 !== newPassword2
  });
};

export const handleCheckRequirements = (setState, newPassword1, newPassword2) => {
  // Check for all of the requirements and set component state accordingly
  setState({
    number: !/\d/.test(newPassword1),
    letter: !/[a-z]/i.test(newPassword1),
    specialCharacter: !/[~`!#$%\^&@*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(newPassword1),
    pwMatch: newPassword1 !== newPassword2 || newPassword1.length <= 0,
    pwLength: newPassword1.length < 13
  });
};

export const numberWithCommas = x => {
  if (x !== undefined && x !== null) {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  } else {
    return "NaN";
  }
};

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

function replaceAll(str, match, replacement) {
  return str.replace(new RegExp(escapeRegExp(match), "g"), () => replacement);
}

export const convertPriceToUsd = (coinValue, usd) => {
  return coinValue !== null
    ? significantDecimals(usd * parseFloat(replaceAll(coinValue.toString(), ",", "")), 2)
    : null;
};

const tooltipNotAvailable = (
  <FullWidthPopover style={{ fontFamily: "Quicksand", fontWeight: "600" }}>
    Historical USD prices not available for this Transaction. The transaction is likely too old.
  </FullWidthPopover>
);

const tooltipNoHist = (
  <FullWidthPopover style={{ fontFamily: "Quicksand", fontWeight: "600" }}>
    Historical USD summary statistics not available for this entity, too many transactions.
  </FullWidthPopover>
);

const noPriceAvailable = () => (
  <OverlayTrigger overlay={tooltipNotAvailable} placement="left" delayShow={300} delayHide={150}>
    <p className="disabled" style={{ fontFamily: "Quicksand" }}>
      ?
    </p>
  </OverlayTrigger>
);

export const chooseBetweenConversions = (
  [crypto, cBool],
  [usd, uBool],
  [histUsd, hBool],
  priceAvailable = true,
  noHist = false
) => {
  if (uBool) {
    return usd || 0;
  }
  if (hBool) {
    if (noHist) {
      return (
        <OverlayTrigger overlay={tooltipNoHist} placement="top" delayShow={300} delayHide={150}>
          <span>
            <FontAwesomeIcon icon={faExclamationTriangle} /> {usd}
          </span>
        </OverlayTrigger>
      );
    }
    if (priceAvailable) {
      return histUsd;
    }
    return noPriceAvailable();
  }
  if (cBool) {
    return crypto;
  }
};

//.01
export function significantDecimals(n, d = 2) {
  const log10 = n ? Math.floor(Math.log10(n)) : 0,
    div = log10 < 0 ? Math.pow(10, 1 - log10 + (d - 2)) : Math.pow(10, d);
  return Math.round(n * div) / div;
}

// For generating ids for components when you don't care about the name
export function makeid(length) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
