import React, { useEffect, useState } from "react";
import cytoscape from "cytoscape";
import CytoscapeComponent from "react-cytoscapejs";
import { Alert, Button, DropdownButton, MenuItem, Modal } from "react-bootstrap";
import popper from "cytoscape-popper";
import { addWalletToGraphInExplorer, createGraphInNewTabWithPreload } from "../../actions/graph";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TransactionChainGraphDetailed from "./TransactionChainGraphDetailed";
import CreateGraphModal from "../Home/CreateGraphModal";
import PreloadGraphModal from "../PreloadGraphModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import TransactionChainHelpImg from "../../../img/transaction-chain-help-image.png";
import TransactionChainDetailedHelpImg from "../../../img/transaction-chain-detailed-help.png";

cytoscape.use(popper);

let walletIdsForGraph = [];
function TransactionChainGraph(props) {
  const {
    addWalletToGraphCallback,
    transactionChain,
    graphModal,
    graphId,
    cy,
    createGraph
  } = props;

  const [width, setWith] = useState("100%");
  const [height, setHeight] = useState(550);
  const [graphData, setGraphData] = useState({
    nodes: [],
    edges: []
  });
  const [nodeSelected, setNodeSelected] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(0);
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [newGraphOpen, setNewGraphOpen] = useState(false);
  const [existingGraphOpen, setExistingGraphOpen] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showDetailedHelpModal, setShowDetailedHelpModal] = useState(false);

  useEffect(() => {
    if (nodeSelected) {
      let currentNode = myCyRef.nodes(":selected").data();
      let hopNum = currentNode["id"].split("_")[0];
      let walletName = currentNode["id"].split("_")[1];
      // TODO need to check to make sure that it is actually only the 0 index that we need
      if (hopNum !== "0") {
        let lstWalletIds = transactionChain[hopNum][walletName]["walletPath"][0];
        // Makes sure to grab just the unique wallet ids. Don't want to spend time adding wallets that have already been
        // added
        walletIdsForGraph = [...new Set(lstWalletIds)];
      } else {
        walletIdsForGraph = [];
      }
    } else {
      walletIdsForGraph = [];
    }
  }, [nodeSelected]);

  if (graphData.nodes.length === 0) {
    let current_x_position_node = 400;
    let current_y_position_node = 66;

    // Order the hop keys in transactionChain
    let orderKeys = Object.keys(transactionChain).sort(function(a, b) {
      return parseInt(b) < parseInt(a);
    });

    // Create the very first node which is the current transaction
    let newNodes = [
      {
        data: { id: 0, label: "Current", type: "majFunds" },
        position: { x: current_x_position_node, y: current_y_position_node - 100 }
      }
    ];

    let newEdges = []; // List of edges
    let newGraphData = graphData;
    let lastIndex = -1; // Set to -1 so that we can account for current

    // Goes through the transactionChain dictionary
    orderKeys.map((numHops, index) => {
      Object.keys(transactionChain[numHops]).map((entityName, entityIndex) => {
        // Goes through all the entities' isMajority list
        transactionChain[numHops][entityName]["isMajority"].map((isMajorityEntity, hopIndex) => {
          // If this particular node is not the majority of funds nodes, then wee need to make sure the small node
          // to indicate hops is there and the edges are all there
          if (!isMajorityEntity) {
            // Creates the hop node
            newNodes = newNodes.concat({
              data: { id: numHops + "_intersect", label: "", type: "timePoint" },
              position: { x: current_x_position_node, y: current_y_position_node }
            });

            // Creates the entity node
            newNodes = newNodes.concat({
              data: { id: numHops + "_" + entityName, label: entityName },
              position: { x: current_x_position_node - 150, y: current_y_position_node }
            });

            // If the index is 0, then that means we need to have an edge that connects the current node to the hop node
            if (index === 0) {
              if (lastIndex === -1) {
                newEdges = newEdges.concat({
                  data: { source: 0, target: numHops + "_intersect", label: numHops }
                });
                lastIndex = null;
              }
            } else {
              if (hopIndex === 0 && lastIndex !== index) {
                // If this is the first time that we have seen the hop, then we need to create an edge between the
                // hop nodes
                newEdges = newEdges.concat({
                  data: {
                    source: orderKeys[index - 1] + "_intersect",
                    target: numHops + "_intersect",
                    label: numHops
                  }
                });
              }
            }
            // Creates an edge from the hop node to the entity
            newEdges = newEdges.concat({
              data: {
                source: numHops + "_intersect",
                target: numHops + "_" + entityName,
                label: numHops + "_" + entityName,
                type: "toNonMaj"
              }
            });
          } else {
            // If the entity that we are looking at is the majority, we don't need to add another hop node
            newNodes = newNodes.concat({
              data: { id: numHops + "_" + entityName, label: entityName, type: "majFunds" },
              position: { x: current_x_position_node, y: current_y_position_node + 60 }
            });
            if (index === 0) {
              newEdges = newEdges.concat({
                data: { source: 0, target: numHops + "_" + entityName, label: numHops }
              });
            } else {
              newEdges = newEdges.concat({
                data: {
                  source: orderKeys[index - 1] + "_intersect",
                  target: numHops + "_" + entityName,
                  label: numHops
                }
              });
            }
          }
        });
        // Need to update the index that indicates what hop we are currently on. This is the variable that makes
        // sure that we only have one edge per hop
        lastIndex = index;
        // Updates the y position for the next node
        current_y_position_node += 50;
      });
    });

    // Updates the graph to have the nodes and edges that we have created
    newGraphData.nodes = newNodes;
    newGraphData.edges = newEdges;
    setGraphData(newGraphData);
  }

  // Layout for the cytoscape object
  const layout = {
    name: "preset", // preset means that we are using defined positions
    fit: true,
    // circle: true,
    directed: true,
    padding: 50,
    // spacingFactor: 1.5,
    animate: true,
    animationDuration: 1000,
    avoidOverlap: true,
    nodeDimensionsIncludeLabels: false
  };

  // This is the style sheet for the nodes and edges in the cytoscape graph
  const styleSheet = [
    {
      selector: "node",
      style: {
        backgroundColor: "#608ca9",
        width: 30,
        height: 30,
        label: "data(label)",
        "overlay-padding": "6px",
        "z-index": "10",
        color: "gray",
        fontSize: 20
      }
    },
    {
      selector: "node:selected",
      style: {
        "border-width": "4px",
        "border-color": "#AAD8FF",
        "background-color": "#77828C",
        color: "black",
        width: 30,
        height: 30
      }
    },
    {
      selector: "node[type='majFunds']",
      style: {
        backgroundColor: "#28b463"
      }
    },
    {
      selector: "node[type='timePoint']",
      style: {
        width: 6,
        height: 6
      }
    },
    {
      selector: "edge",
      style: {
        width: 3,
        label: "data(label)",
        // "line-color": "#6774cb",
        "line-color": "#AAD8FF",
        "target-arrow-color": "#6774cb",
        "target-arrow-shape": "triangle",
        "curve-style": "bezier"
      }
    },
    {
      selector: "edge[type='toNonMaj']",
      style: {
        width: 3,
        // "line-color": "#6774cb",
        label: "",
        "line-color": "#AAD8FF",
        "target-arrow-color": "#6774cb",
        "target-arrow-shape": "triangle",
        "curve-style": "bezier"
      }
    },
    {
      selector: "edge:selected",
      style: {
        width: 3,
        // "line-color": "#6774cb",
        "line-color": "#7693ad",
        "target-arrow-color": "#6774cb",
        "target-arrow-shape": "triangle",
        "curve-style": "bezier"
      }
    }
  ];

  // This function adds the wallets to the graph
  const addWalletToGraph_ = graphId => {
    // The line below unselects all elements in the graph when a new one is added
    let currentNode = myCyRef.nodes(":selected").data();
    let hopNum = currentNode["id"].split("_")[0];
    let walletName = currentNode["id"].split("_")[1];

    // TODO need to check to make sure that it is actually only the 0 index that we need
    let lstWalletIds = transactionChain[hopNum][walletName]["walletPath"][0];
    // Makes sure to grab just the unique wallet ids. Don't want to spend time adding wallets that have already been
    // added
    let uniqueWalletIds = [...new Set(lstWalletIds)];
    cy.elements().unselect();
    try {
      uniqueWalletIds.map(walletId => {
        addWalletToGraphCallback(walletId, graphId, cy);
      });
      setShowSuccessMessage(1);
    } catch (err) {
      console.log(err);
      setShowSuccessMessage(-1);
    }
  };

  const onClickOpenDetailedHelp = () => {
    setShowDetailedHelpModal(true);
  };

  const onClickCloseDetailedHelp = () => {
    console.log("I am actually trying to close");
    setShowDetailedHelpModal(false);
  };

  let myCyRef;

  return (
    <>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <div style={{ display: "flex" }}>
              <h1>Transaction Chain</h1>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                style={{
                  fontSize: "18px",
                  marginTop: "23px",
                  marginLeft: "5px",
                  cursor: "pointer"
                }}
                onClick={() => setShowHelpModal(true)}
              />{" "}
            </div>
            <p>Showing transactions to a named entity on the blockchain</p>
          </div>
          {!graphModal ? (
            <div style={{ marginLeft: "auto" }}>
              <DropdownButton
                className="graphToggle"
                title={"Add Selected Nodes to Graph"}
                id="dropdown-basic"
                disabled={!nodeSelected || nodeSelected === "0"}
              >
                <MenuItem eventKey="new" onSelect={() => setNewGraphOpen(true)}>
                  New graph
                </MenuItem>
                <MenuItem eventKey="existing" onSelect={() => setExistingGraphOpen(true)}>
                  Existing graph
                </MenuItem>
              </DropdownButton>
              <CreateGraphModal
                createGraph={createGraph}
                show={newGraphOpen}
                close={() => setNewGraphOpen(false)}
              />
              <PreloadGraphModal
                type={"wallet"}
                close={() => setExistingGraphOpen(false)}
                show={existingGraphOpen}
                item={walletIdsForGraph}
                curGraph={graphId}
              />

              <Button
                onClick={() => {
                  setShowTransactionDetails(true);
                }}
                disabled={!nodeSelected || nodeSelected === "0"}
              >
                Get Chain Details
              </Button>
            </div>
          ) : (
            <>
              <Button
                onClick={() => {
                  addWalletToGraph_(graphId);
                }}
                disabled={!nodeSelected}
                style={{ marginLeft: "auto" }}
              >
                Add Chain To Graph
              </Button>
              <Button
                onClick={() => {
                  setShowTransactionDetails(true);
                }}
                disabled={!nodeSelected || nodeSelected === "0"}
                style={{ marginLeft: 5 }}
              >
                Get Chain Details
              </Button>
            </>
          )}
        </div>
        <div
          style={{
            border: "1px solid",
            backgroundColor: "#f5f6fe",
            height: height
          }}
        >
          <CytoscapeComponent
            elements={CytoscapeComponent.normalizeElements(graphData)}
            // pan={{ x: 200, y: 200 }}
            style={{ width: width, height: height }}
            zoomingEnabled={true}
            maxZoom={3}
            minZoom={0.1}
            autounselectify={false}
            boxSelectionEnabled={true}
            layout={layout}
            stylesheet={styleSheet}
            wheelSensitivity={0.3}
            cy={cy2 => {
              myCyRef = cy2;

              cy2.on("select", "node", evt => {
                // cy.nodes(":selected").unselect();

                cy2.edges(":selected").unselect();
                let node = evt.target;
                setNodeSelected(node.data()["id"]);
                let edge_label = parseInt(node.data()["id"].split("_")[0]);
                let edges_to_node = cy2.edges("[type!='toNonMaj']");
                edges_to_node.map(item => {
                  if (parseInt(item.data()["label"]) <= edge_label) {
                    item.select();
                  }
                });
                let last_edge_to_node = cy2.edges("[label='" + node.data()["id"] + "']");
                last_edge_to_node.select();
              });

              cy2.on("unselect", "node", evt => {
                setNodeSelected(null);
              });
            }}
          />
        </div>
        <p style={{ marginTop: 5 }}>
          {showSuccessMessage === 1 ? (
            <Alert bsStyle="success" onDismiss={() => setShowSuccessMessage(0)}>
              <strong>Successfully Added Wallets to Graph</strong>
            </Alert>
          ) : (
            showSuccessMessage === -1 && (
              <Alert bsStyle="danger">
                <strong>Failed to Add Wallets to Graph</strong>
              </Alert>
            )
          )}
        </p>
      </div>
      <Modal
        show={showTransactionDetails}
        onHide={() => setShowTransactionDetails(false)}
        className="transactionChainDetailsModal"
        bsSize="large"
      >
        <Modal.Body>
          <TransactionChainGraphDetailed
            transactionChain={transactionChain}
            nodeSelected={nodeSelected}
            onClickOpenDetailedHelp={onClickOpenDetailedHelp}
            onClickCloseDetailedHelp={onClickCloseDetailedHelp}
            showDetailedHelpModal={showDetailedHelpModal}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showHelpModal}
        onHide={() => setShowHelpModal(false)}
        className="helpModal"
        bsSize="large"
      >
        <Modal.Body>
          <h1>Transaction Chain Help</h1>
          <p>
            Transaction Chain is a tool to track where majority of funds travel through the
            blockchain. The graph displayed shows the path taken to track majority of funds. Nodes
            indicate tagged entities that were found along the path and the edges represent the
            number hops it took from the current transaction to reach the tagged entity. If the node
            is green, then that indicates that majority of funds were tracked to the green node
            entity. If the node is gray, that indicates that the majority of funds did not go to the
            entity, but that the tagged entity was found in the transaction.{" "}
          </p>
          <p>Upon selecting a node, there are two actions that can be taken:</p>
          <ol style={{ fontFamily: "Quicksand, sansSerif", color: "#333333" }}>
            <li>
              <p>
                Add all data in the path from the current transaction to the selected entity to one
                of your graphs
              </p>
            </li>
            <li>
              <p>
                Take a closer look at the transactions from the current transaction to the selected
                entity. To interpret the detailed transaction chain graph, see:{" "}
                <a onClick={onClickOpenDetailedHelp}>Detailed Transaction Chain Help</a>
              </p>
            </li>
          </ol>
          <h3>Example:</h3>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={TransactionChainHelpImg} style={{ alignSelf: "center" }} />
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDetailedHelpModal}
        onHide={onClickCloseDetailedHelp}
        className="helpModal"
        bsSize="large"
      >
        <Modal.Body>
          <h1>Detailed Transaction Chain Help</h1>
          <p>
            The detailed transaction chain view illustrates the path chosen when following the
            majority of funds. The large gray box represents a transaction, the rectangle boxes
            inside the transaction box represents either the input or the output side of the
            transaction, and the nodes represents an entity/address or a grouping of
            entities/addresses (see example below for more information). Upon pressing each of the
            boxes and nodes, more details will be displayed above the graph.
          </p>
          <h3>Example:</h3>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={TransactionChainDetailedHelpImg} style={{ alignSelf: "center" }} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  addWalletToGraphCallback: (walletId, graphId, cy2) =>
    dispatch(addWalletToGraphInExplorer(walletId, graphId, cy2)),
  createGraph: (caseNumber, description, isPublic) => {
    dispatch(
      createGraphInNewTabWithPreload(caseNumber, description, isPublic, {
        preloadAddresses: [],
        preloadWallets: walletIdsForGraph,
        preloadTransactions: [],
        preloadUnclusteredTransactions: []
      })
    );
  }
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(TransactionChainGraph)
);
