/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { Grid, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentGraph, getWalletViewData } from "../../../selectors/graph";
import { changeDataTabsKey } from "../../../actions/graph";
import WrappedLink from "../../Utils/WrappedLink";
import AddressStats from "./AddressStats";
import {
  getAddressSummary,
  getAddressTokens,
  getAddressTransactions,
  getInternalTransactions
} from "../../../selectors/ethereum/address";
import {
  fetchAddressSummaryEthereum,
  fetchAddressTokens,
  fetchAddressTransactions,
  fetchInternalTransactions
} from "../../../actions/ethereum/address";
import { TokenStatsTable } from "../SmartContracts/TokenStatsTable";
import CopyText from "../../CopyText";
import { TransactionList } from "../TransactionList";
import { InternalTransactionList } from "../InternalTransactionList";
import { getAddressSet } from "../../../selectors/ethereum/graph";

export const AddressView = ({
  address,
  name,
  stateBased = false,
  addToGraph
}) => {
  const { dataTabsKey } = useSelector(state => getWalletViewData(state));
  const { token, ...addressSummary } = useSelector(state =>
    getAddressSummary(state, address, true)
  );
  const dispatch = useDispatch();
  const fetchAddress = useCallback(async () => {
    await dispatch(fetchAddressSummaryEthereum(address));
  });
  const addresses = useSelector(state =>
    getAddressSet(state, getCurrentGraph(state))
  );

  useEffect(() => {
    fetchAddress().catch(console.error);
  }, [address]);

  return (
    <div className="activeOptionMainDiv">
      <p style={{ fontSize: "24px", marginLeft: "10px" }}>
        {address < 0 && "Custom Entity"}
      </p>
      <p style={{ fontSize: "15px", marginLeft: "10px" }}>
        <CopyText text={address} color={"white"} />
        <WrappedLink
          entity={address}
          entityType={"address"}
          stateBased
          style={{ color: "white", fontSize: "18px", fontWeight: "500" }}
        >
          {name}
        </WrappedLink>
      </p>
      <div
        className="tabWrapper walletGraphData"
        style={{
          borderLeft: "1px solid white",
          borderRadius: "0",
          marginLeft: "0"
        }}
      >
        <Tabs
          style={{ width: "100%" }}
          activeKey={dataTabsKey}
          id="transaction-tab"
          onSelect={key => dispatch(changeDataTabsKey(key))}
          animation={false}
          mountOnEnter
        >
          <Tab
            eventKey="AccountStats"
            title="Stats"
            style={{ marginLeft: "0", paddingBottom: "0" }}
          >
            <AddressStats {...addressSummary} stateBased={stateBased} />
          </Tab>
          {token && (
            <Tab
              eventKey="TokenStats"
              title="Token Stats"
              style={{ marginLeft: "0", paddingBottom: "0" }}
            >
              <TokenStatsTable
                address={address}
                {...token}
                stateBased={stateBased}
              />
            </Tab>
          )}
          <Tab
            eventKey="Transactions"
            title="Transactions"
            style={{ marginLeft: "0", paddingBottom: "0" }}
          >
            <TransactionList
              graphView={true}
              fetchTransactions={(token_account_id, filter) =>
                fetchAddressTransactions(address, token_account_id, filter)
              }
              fetchTokens={() => fetchAddressTokens(address)}
              stateBased={stateBased}
              bold={address}
              key={address}
              getTokens={state => getAddressTokens(state, address, true)}
              getTransactions={(state, token_account_id, filter = null) =>
                getAddressTransactions(
                  state,
                  address,
                  token_account_id,
                  filter,
                  true
                )
              }
              enabledFilters={new Set(["side"])}
              addToGraph={addToGraph}
              addresses={addresses}
            />
          </Tab>
          <Tab
            eventKey="Internal-Transactions"
            title="Internal Transactions"
            style={{ marginLeft: "0", paddingBottom: "0" }}
          >
            <InternalTransactionList
              graphView={true}
              fetchTransactions={filter =>
                fetchInternalTransactions(address, filter)
              }
              stateBased={stateBased}
              bold={address}
              getTransactions={(state, filter) =>
                getInternalTransactions(state, address, filter, true)
              }
              key={address}
              enabledFilters={new Set(["side"])}
              addToGraph={addToGraph}
              addresses={addresses}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

AddressView.propTypes = {
  address: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};
