/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Login from "../Login";
import PasswordResetRequest from "../PasswordResetRequest";
import PasswordReset from "../PasswordReset";
import Register from "../Register";
import {ChangePhone} from "../TwoFA/ChangePhone";

const UnauthenticatedRoutes = () => (
  <Switch>
    <Route exact path="/" render={() => <Redirect to="/login" />} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/register/token/:token" component={Register} />
    <Route exact path="/reset-request" component={PasswordResetRequest} />
    <Route exact path="/reset/token/:token" component={PasswordReset} />
    <Route exact path="/change-phone/token/:token" component={ChangePhone} />
    <Redirect to="/login" />
  </Switch>
);

export default UnauthenticatedRoutes;
