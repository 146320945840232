/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { LinkContainer } from "react-router-bootstrap";
import { DropdownButton, MenuItem, NavItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Search } from "./Search";
import CurrencyChooserConfig from "./CurrencyChooserConfig";

import { logout } from "../actions/authentication";
import { fetchCurrencyUsdValues } from "../actions/currency";
import { getCtAccess, getLoggedIn, getRole, getTwoFASuccess } from "../selectors/authentication";

import WhitesailHeader from "../../img/logo-small-2.png";
import "../../css/styles.css";
import UserGuide from "../../other/TheWhitesailGuide.pdf";
import { getCurrency, getUsd } from "../selectors/currency";
import { SWAP_CURRENCY } from "../actions/actionNames";
import { getFeature } from "../selectors/features";
import { getClientMode } from "../selectors/applicationConfig";
import ClientModeConfig from "../components/ClientModeConfig";
import CurrencyChooser from "./CurrencyChooser";
import { numberWithCommas } from "../helpers";
import { getCoins } from "../selectors/coins";
import { Link } from "@material-ui/core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { MessageHandler } from "./Messages/MessageHandler";
// This is used to prevent menu items from remaining active
const alwaysFalse = () => false;

class WhitesailNavbar extends Component {
  state = {
    coins: {}
  };

  setCoins = () => {
    const data = this.props.usdPrices;
    if (data === null) {
      let data = {};
      Object.keys(CurrencyChooserConfig).map(item => {
        data[item] = null;
      });
    } else {
      this.setState({
        coins: data
      });
    }
  };

  logout = async () => {
    try {
      await this.props.onLogout();
    } catch (err) {
      throw err;
    }
  };

  renderMenu = () => {
    let currencyObject = CurrencyChooserConfig[this.props.currency];

    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null
    };

    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }
    let { coins } = this.state;
    let {
      loggedIn,
      fetchCurrencyUsdValues,
      clientMode,
      altCoinsEnabled,
      swapCurrency,
      usdPrices,
      currency,
      role,
      coinsEnabled,
      ctAccess,
      twoSuccess
    } = this.props;
    if (loggedIn && twoSuccess) {
      return (
        <ul className="whitesail-navbar">
          <LinkContainer to="/" isActive={alwaysFalse}>
            <NavItem>
              <li className="navbar-image" key={"logo"}>
                <img
                  alt=""
                  src={clientModeConfigObject.nav_logo}
                  style={{
                    maxHeight:
                      clientMode === "cryptotru" || clientMode === "cryptovoyant" ? "25px" : "45px",
                    minHeight:
                      clientMode === "cryptotru" || clientMode === "cryptovoyant" ? "25px" : "45px"
                  }}
                />
              </li>
            </NavItem>
          </LinkContainer>
          <CurrencyChooser
            setCoins={this.setCoins}
            fetchCurrencyUsdValues={fetchCurrencyUsdValues}
            usdPrices={usdPrices}
          />
          {clientMode === "vci" && (
            <LinkContainer to="/" isActive={alwaysFalse}>
              <NavItem>
                <li className="nav-item" key={"whitesail_logo"}>
                  <img
                    style={{ paddingLeft: "0", paddingRight: "0" }}
                    src={WhitesailHeader}
                    alt=""
                  />
                </li>
              </NavItem>
            </LinkContainer>
          )}
          <Search wide="35%" fromNav={true} />
          {altCoinsEnabled ? (
            <NavItem style={{ maxWidth: "400px" }}>
              <DropdownButton
                style={{
                  fontFamily: "Quicksand",
                  display: "inline-block",
                  zIndex: "10001",
                  height: "40px",
                  paddingBottom: "3px",
                  borderRadius: "20px",
                  color: "#555",
                  backgroundColor: "white",
                  border: "2px solid white",
                  fontWeight: "500",
                  fontSize: "15px"
                }}
                title={
                  <>
                    <img
                      style={{
                        height: "25px",
                        width: "25px",
                        marginRight: "5px"
                      }}
                      src={currencyObject.logo}
                      alt=""
                    />{" "}
                    <p style={{ marginRight: "15px", height: "auto" }}>{currencyObject.label}</p>
                    {Object.keys(coins).includes(currency) && (
                      <p
                        style={{
                          height: "auto",
                          color: "green"
                        }}
                      >
                        {" "}
                        ${numberWithCommas(coins[currency].toFixed(2))}{" "}
                      </p>
                    )}
                    <div className="divider" />
                  </>
                }
                id="currency-dropdown"
              >
                {Object.keys(CurrencyChooserConfig).map(function(item) {
                  if (coinsEnabled.has(item)) {
                    return (
                      <LinkContainer to="/" isActive={alwaysFalse} style={{ width: "300px" }}>
                        <MenuItem
                          key={item}
                          onClick={() => {
                            swapCurrency(item);
                          }}
                          style={{ fontSize: "8px" }}
                          className="coinDropdown"
                        >
                          <>
                            <img
                              style={{
                                height: "20px",
                                width: "20px",
                                marginRight: "10px"
                              }}
                              src={CurrencyChooserConfig[item].logo}
                              alt=""
                            />{" "}
                            {CurrencyChooserConfig[item].label}{" "}
                            {Object.keys(coins).includes(item) && (
                              <p>${numberWithCommas(coins[item].toFixed(2))}</p>
                            )}
                          </>
                        </MenuItem>
                      </LinkContainer>
                    );
                    // }
                  }
                })}
              </DropdownButton>
            </NavItem>
          ) : (
            ""
          )}
          <NavItem>
            <DropdownButton
              style={{
                fontFamily: "Quicksand",
                display: "inline-block",
                zIndex: "10001",
                height: "65px",
                paddingBottom: "3px"
              }}
              title={<> Tools/Settings </>}
              id="account-dropdown"
            >
              <MenuItem
                header
                style={{
                  color: "black",
                  fontSize: "16px",
                  fontWeight: "700",
                  fontFamily: "Quicksand"
                }}
              >
                {" "}
                Tools{" "}
              </MenuItem>
              <LinkContainer to="/subpoena-support" isActive={alwaysFalse}>
                <MenuItem style={{ fontSize: "8px" }}>Subpoena Support</MenuItem>
              </LinkContainer>
              <LinkContainer to="/tutorial-videos" isActive={alwaysFalse}>
                <MenuItem style={{ fontSize: "8px" }}>Tutorial Videos</MenuItem>
              </LinkContainer>
              {role === "researcher" && (
                <LinkContainer to={`/${currency}/researcher`} isActive={alwaysFalse}>
                  <MenuItem style={{ fontSize: "8px" }}>Researcher</MenuItem>
                </LinkContainer>
              )}
              <MenuItem divider style={{ color: "black !important" }} />
              <MenuItem
                header
                style={{
                  color: "black",
                  fontSize: "16px",
                  fontWeight: "700",
                  fontFamily: "Quicksand"
                }}
              >
                {" "}
                Settings{" "}
              </MenuItem>
              <LinkContainer to="/change-email" isActive={alwaysFalse}>
                <MenuItem style={{ fontSize: "8px" }}>Change Email</MenuItem>
              </LinkContainer>
              <LinkContainer to="/change-password" isActive={alwaysFalse}>
                <MenuItem style={{ fontSize: "8px" }}>Change Password</MenuItem>
              </LinkContainer>
              {ctAccess && <MenuItem onClick={() => window.open(UserGuide)}>User Guide</MenuItem>}
              {["admin", "supervisor", "researcher", "user5"].includes(role) ? (
                <LinkContainer to="/user-management" isActive={alwaysFalse}>
                  <MenuItem style={{ fontSize: "8px" }}>User Management</MenuItem>
                </LinkContainer>
              ) : null}
              <MenuItem onClick={this.logout}>Logout</MenuItem>
            </DropdownButton>
          </NavItem>
          <NavItem>
            <MessageHandler />
          </NavItem>
        </ul>
      );
    }
    return (
      <ul className="whitesail-navbar">
        <li style={{ height: "65px" }} key={"default"} />
      </ul>
    );
  };

  render() {
    return (
      <div className="navbar-menu" style={{ zIndex: "10001", position: "relative" }}>
        {this.renderMenu()}
      </div>
    );
  }
}

WhitesailNavbar.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  loggedIn: getLoggedIn(state),
  role: getRole(state),
  currency: getCurrency(state),
  altCoinsEnabled: getFeature(state, "alt_coins"),
  coinsEnabled: getCoins(state),
  clientMode: getClientMode(state),
  usdPrices: getUsd(state),
  CtAccess: getCtAccess(state),
  twoSuccess: getTwoFASuccess(state)
});

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(logout()),
  swapCurrency: currency => dispatch({ type: SWAP_CURRENCY, currency }),
  fetchCurrencyUsdValues: () => dispatch(fetchCurrencyUsdValues())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WhitesailNavbar);
