import React, { Component } from "react";
import { Button, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

class WatchlistNote extends Component {
  state = {
    showNotification: false,
    fields: {
      primaryTag: "",
    },
  };

  onInputChange = e => {
    const { fields } = this.state;
    fields[e.target.id] = e.target.value;
    this.setState({ fields, showNotification: false });
  };

  componentDidMount() {
    this.setState({ fields: { primaryTag: this.props.note } });
  }

  submit = async (id, title, type) => {
    const status = await this.props.updateNotes(id, title, type);

    if (status === true) {
      this.setState({
        showNotification: true,
      });
    }
  };

  render() {
    const { note, id, type } = this.props;
    return (
      <div style={{ marginTop: "30px", paddingLeft: "15px", paddingRight: "15px" }}>
        <p
          style={{
            marginBottom: "10px",
            fontFamily: "Quicksand",
            color: "var(--secondary-color)",
            fontSize: "20px",
            paddingBottom: "5px",
            borderBottom: "2px solid var(--secondary-color)",
          }}
        >
          {" "}
          Notes{" "}
        </p>
        <FormControl
          componentClass="textarea"
          type="textarea"
          id="primaryTag"
          value={this.state.fields.primaryTag}
          onChange={e => this.onInputChange(e)}
        />
        <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
          <Button
            className="greenButton"
            style={{ marginTop: "10px", width: "fitContent", display: "inline" }}
            onClick={() => this.submit(id, this.state.fields.primaryTag, type)}
            disabled={this.state.fields.primaryTag === note}
          >
            <FontAwesomeIcon icon={faSave} style={{ marginRight: "5px" }} /> Save
          </Button>
          {this.state.showNotification && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                className="addToWatchlistNotificationSuccess"
                style={{ display: "inline", marginBottom: "0", marginLeft: "25px" }}
              >
                Note Saved!
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default WatchlistNote;
