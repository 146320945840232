import { List, Map, Record, Set } from "immutable";
import {
  GRAPH_ADD_WALLET_TO_GRAPH,
  GRAPH_DELETE_WALLET_FROM_GRAPH,
  GRAPH_FETCH_SUCCESS,
  GRAPH_SEARCH_LOADING,
  GRAPH_SEARCH_SUCCESS,
  SET_GRAPH_CATEGORY_COLORS
} from "../../actions/actionNames";
import { convertFromRaw, EditorState } from "draft-js";
import { Modals, sharedGraphReducer } from "../graph";

export const GraphView = Record({
  modals: new Modals(),
  mainTabsKey: "editor", // one of 'data', 'editor', 'control', 'search', 'console'
  dataTabsKey: "AddressStats",
  outputWalletId: null,
  mutualTransactionsOrder: "largestFirst",
  showNotes: false
});

export const Graph = Record({
  view: new GraphView(), // view state for current graph being shown
  graphIdToGraphData: Map(), // map of int -> GraphRecord
  colors: Map()
});

export const EthereumGraphRecord = Record({
  caseNumber: "", // supposed to be something like 123-567-9413, no check is done though
  description: "",
  originalEditorState: "",
  editorState: EditorState.createEmpty(),
  graph: {},
  addresses: Set(),
  lastSaved: "",
  attributedEdgeToggleState: true,
  currentQuery: null,
  search: Map(),
  searchLoading: false,
  view: new GraphView()
});

export const EthereumAddressSearchRecord = Record({
  address: "",
  url: "",
  tag: null,
  contract: null
});
/**
 * Adds a graph to the store.
 * Called when Graph component mounts or changes its graphId prop
 * @param state
 * @param action
 */
const addGraphData = (
  state,
  { graphId, addresses, data: { caseNumber, description, editorContent, graph: graph_ } }
) => {
  const editorState = editorContent
    ? EditorState.createWithContent(convertFromRaw(editorContent))
    : EditorState.createEmpty();
  const graphRecord = EthereumGraphRecord({
    caseNumber,
    description,
    editorState,
    graph: graph_,
    addresses: Set(addresses),
    lastSaved: ""
  });
  const stateModals = state.setIn(["view", "modals"], new Modals());
  return stateModals.setIn(["graphIdToGraphData", graphId], graphRecord);
};

export const updateGraphSearch = (state, { graphId, data, query }) => {
  const { addresses } = data;

  const addressResults = List.of(...addresses);

  let graphData = state.getIn(["graphIdToGraphData", graphId]);
  graphData = graphData.setIn(["search", query], addressResults).set("currentQuery", query);
  return state.setIn(["graphIdToGraphData", graphId], graphData);
};

export const setGraphSearchLoading = (state, { graphId }) => {
  return state.setIn(["graphIdToGraphData", graphId, "searchLoading"], true);
};

const deleteAddressFromGraph = (state, { address, graphId }) => {
  return state.updateIn(["graphIdToGraphData", graphId, "addresses"], addresses => {
    return addresses.delete(address);
  });
};

const addAddressToGraph = (state, { address, graphId }) => {
  return state.updateIn(["graphIdToGraphData", graphId, "addresses"], addresses =>
    addresses.add(address)
  );
};

/**
 * Sets graph category colors in redux store, takes object mapping category names -> color
 * @param state
 * @param colors
 * @returns {*}
 */
const setGraphCategoryColors = (state, { colors }) => {
  return state.set("colors", new Map(colors));
};

const makeGraphReducerEthereum = coin => {
  return (state = new Graph(), action) => {
    // If the coin doesn't match the reducer, just return the state.
    if (action === undefined || action.name !== coin) {
      return state;
    }

    switch (action.type) {
      case GRAPH_FETCH_SUCCESS:
        return addGraphData(state, action);
      case GRAPH_SEARCH_SUCCESS:
        return updateGraphSearch(state, action);
      case GRAPH_SEARCH_LOADING:
        return setGraphSearchLoading(state, action);
      case GRAPH_DELETE_WALLET_FROM_GRAPH:
        return deleteAddressFromGraph(state, action);
      case GRAPH_ADD_WALLET_TO_GRAPH:
        return addAddressToGraph(state, action);
      case SET_GRAPH_CATEGORY_COLORS:
        return setGraphCategoryColors(state, action);
      default:
        return sharedGraphReducer(state, action, coin);
    }
  };
};

export default makeGraphReducerEthereum;
