// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { connect } from "react-redux";

import WalletSummaryBase from "../WalletSummaryBase";
import { getWalletSummary } from "../../../selectors/wallet";
import {
  deleteCustomWallet,
  fetchCustomWalletSummary,
  removeCustomWalletSharedUser
} from "../../../actions/customWallet";
import {
  fetchMoreWalletSummary,
  purgeWalletSummary
} from "../../../actions/wallet";
import axios, { CUSTOM_WALLETS_API } from "../../../api";

/**
 * Maping redux state to component's props
 * @param state redux
 * @param walletId current walletId
 */
const makeMapStateToProps = (state, { walletId }) => ({
  ...getWalletSummary(state, walletId, true),
  custom: true
});

/**
 * Handles when user shares the tag with another user
 * @param email email of the user to share to
 * @param clusterId
 */
const handleShare = async (email, clusterId, enqueueSnackbar, currency) => {
  try {
    await axios.put(`${CUSTOM_WALLETS_API(currency)}/${clusterId}/share`, {
      email,
      permission: "view" // by default always view permission atm
    });
  } catch (err) {
    if (err && err.response && err.response.status === 403) {
      enqueueSnackbar(`Not authorized to share custom wallet`, {
        variant: "error"
      });
    } else {
      enqueueSnackbar("Error sharing custom wallet", {
        variant: "error"
      });
    }
    throw err;
  }
};

/**
 * Gets list of users with share access to this custom tag
 */
const getSharedUsers = async (clusterId, enqueueSnackbar, currency) => {
  try {
    const { data } = await axios.get(
      `${CUSTOM_WALLETS_API(currency)}/${clusterId}/share`
    );
    return data;
  } catch (err) {
    console.error(err);
    if (err && err.response && err.response.status === 403) {
      enqueueSnackbar(`Not authorized to access shared custom wallet`, {
        variant: "error"
      });
    } else {
      enqueueSnackbar("Error getting shared users custom wallet", {
        variant: "error"
      });
    }
    return [];
  }
};

/**
 * Mapping functions for custom wallet summary api calls to the props
 * @param dispatch
 * @returns {{fetch: (function(*=): *), onDeleteFunc: (function(*=, *=, *=): *), purge: (function(*=): *)}}
 */
const mapDispatchToProps = (dispatch, ownProps) => ({
  onDeleteFunc: (walletId, name, enqueueSnackbar) =>
    dispatch(deleteCustomWallet(walletId, name, enqueueSnackbar)),
  fetch: walletId =>
    dispatch(fetchCustomWalletSummary(walletId, ownProps.graphId || 0)),
  purge: walletId => dispatch(purgeWalletSummary(walletId)),
  fetchMore: walletId =>
    dispatch(fetchMoreWalletSummary(walletId, ownProps.graphId || 0)),
  getSharedUsers: (walletId, enqueueSnackbar, currency) =>
    getSharedUsers(walletId, enqueueSnackbar, currency),
  removeSharedUser: (walletId, email, enqueueSnackbar) =>
    dispatch(removeCustomWalletSharedUser(walletId, email, enqueueSnackbar)),
  shareCustomWallet: (walletId, email, enqueueSnackbar, currency) =>
    handleShare(email, walletId, enqueueSnackbar, currency)
});

/*
 * Mapping props to WalletSummaryBase
 */
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(WalletSummaryBase);
