// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

export const getCurrency = state => {
  return state.getIn(["currency", "currency"]);
};

export const getUsd = state => {
  const x = state.getIn(["currency", "prices"]);
  return x;
};

export const getUsdForCoin = state => {
  const coin = getCurrency(state);
  try {
    const x = state.getIn(["currency", "prices", coin]);
    return x;
  } catch (e) {
    return 0.0;
  }
};
