/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";

import "bootstrap/dist/css/bootstrap.css";

import WhitesailNavbar from "./WhitesailNavbar";
import Routes from "./Routes";
import BottomHelpPopup from "./BottomHelpPopup";
import Notification from "./Notification";
import TrialCountdown from "./TrialCountdown";
import { connect } from "react-redux";
import { getRole } from "../selectors/authentication";
import { MessageHandler } from "./Messages/MessageHandler";
// import Notification from './Notification';

/* Navbar with routing */
const Main = ({ role }) => (
  <main>
    <div id="stickyTop">
      <WhitesailNavbar />
    </div>
    <div style={{ marginTop: "10px" }}>
      <Routes />
    </div>
    <BottomHelpPopup />
    {role === "trial" && <TrialCountdown />}
    <Notification />
  </main>
);

const mapStateToProps = state => {
  return {
    role: getRole(state)
  };
};

export default connect(
  mapStateToProps,
  null
)(Main);
