import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInternalTransactions,
  fetchTransactionSummaryEthereum
} from "../../actions/ethereum/transaction";
import {
  getInternalTransactions,
  getTransactionSummary
} from "../../selectors/ethereum/transaction";
import ReportIssue from "../ReportIssue";
import { Col, Grid, Row, Tab, Tabs } from "react-bootstrap";
import { getCurrency, getUsdForCoin } from "../../selectors/currency";
import StatCard from "../StatCard";
import moment from "moment";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  chooseBetweenConversions,
  convertPriceToUsd,
  numberWithCommas,
  significantDecimals
} from "../../helpers";
import WrappedLink from "../Utils/WrappedLink";
import withRouteBasedTabs from "../Transaction/withRouteBasedProps";
import withStateBasedTabs from "../Address/withStateBasedTabs";
import { CoinSwap } from "../CoinSwap";
import { TransactionTokenTransfers } from "./TransactionTokenTransfers";
import { Helmet } from "react-helmet";
import { InternalTransactionList } from "./InternalTransactionList";
import AddToNote from "../NoteEditor/AddToNote";
import BtcToUsdChooser from "../Utils/BtcToUsdChooser";
import CurrencyChooserConfig from "../CurrencyChooserConfig";

const TransactionEthereum = ({ transaction, stateBased = false, ...props }) => {
  const transactionSummary = useSelector(state => getTransactionSummary(state, transaction, true));
  const currency = useSelector(state => getCurrency(state));
  const usdPrice = useSelector(state => getUsdForCoin(state));
  const dispatch = useDispatch();
  const [usd, setUsd] = useState(false);
  const [histUsd, setHistUsd] = useState(false);

  const fetchAddress = useCallback(async () => {
    await dispatch(fetchTransactionSummaryEthereum(transaction));
  });
  useEffect(() => {
    fetchAddress().catch(console.error);
  }, [transaction]);

  const setUsdDisplay = value => {
    setUsd(value);
    setHistUsd(false);
  };

  const setHistDisplay = value => {
    setUsd(false);
    setHistUsd(value);
  };

  const {
    toAddress,
    fromAddress,
    addresses,
    price,
    coin_swap,
    coin_swap_data
  } = transactionSummary;

  let currencyObject = CurrencyChooserConfig[currency];

  let priceConversion = histUsd ? price : usdPrice;
  const addressLink = address => (
    <WrappedLink
      entity={address}
      entityType={"address"}
      stateBased={stateBased || false}
      style={{ color: "var(--link-colors)" }}
    >
      {address}
    </WrappedLink>
  );
  // <Link to={`/ethereum/address/${address}`}>{address}</Link>;

  const wallet = address => {
    return Object.keys(addresses).includes(address) ? (
      Object.keys(addresses[address]["wallet"]["owner"]).includes("name") ? (
        <WrappedLink
          entity={addresses[address]["wallet"]["walletId"]}
          entityType={"address"}
          stateBased={stateBased || false}
          style={{ color: "var(--link-colors)" }}
        >
          {addresses[address]["wallet"]["owner"]["name"]}
        </WrappedLink>
      ) : (
        <WrappedLink
          entity={addresses[address]["wallet"]["walletId"]}
          entityType={"address"}
          stateBased={stateBased || false}
          style={{ color: "var(--link-colors)" }}
        >
          {addresses[address]["wallet"]["walletId"]}
        </WrappedLink>
      )
    ) : (
      <p> </p>
    );
  };

  const fee = (transactionSummary.gasUsed * transactionSummary.gasPrice) / Math.pow(10, 9);

  return (
    <Grid>
      <Helmet>
        <title>{`Trx ${transaction}`}</title>
      </Helmet>
      <div
        className="container"
        style={{
          borderWidth: "0 0 0 2px",
          borderColor: "var(--secondary-color)",
          borderStyle: "solid",
          paddingBottom: "0"
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 className="entityHeader">Transaction</h4>
          <div style={{ marginLeft: "auto" }}>
            {/*{addToGraph()}{" "}*/}
            {/*<Button className="blueButton">Add to Graph</Button>*/}
            <AddToNote page="transaction" itemId={transaction} />
            <ReportIssue entityName={transaction} entityType={"transaction"} currency={currency}>
              {" "}
            </ReportIssue>
          </div>
        </div>
        <div className="row" style={{ marginLeft: "0", alignItems: "center" }}>
          <div className="col txnSummaryTxnId">
            <p style={{ marginTop: "10px", marginBottom: "10px" }}>{transaction}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <p
              style={{
                color: "var(--secondary-color)",
                fontFamily: "Quicksand",
                fontWeight: "700",
                fontSize: "20px"
              }}
            >
              {" "}
              Summary{" "}
            </p>
          </div>
          <div className="pull-right" style={{ display: "inline", paddingTop: "8px" }}>
            <BtcToUsdChooser
              showAsUsd={usd}
              toggleUsdDisplay={setUsdDisplay}
              historicalToUsd={histUsd}
              toggleHistoricalUsd={setHistDisplay}
              currencyAbbreviation={currencyObject["abb"]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-6 infoCol">
            <div className="infoCards">
              <p
                style={{
                  color: "var(--secondary-color)",
                  fontFamily: "Quicksand",
                  fontWeight: "700"
                }}
              >
                {" "}
                Info{" "}
              </p>
              <StatCard
                stat="Timestamp"
                value={moment
                  .unix(transactionSummary.date)
                  .utc()
                  .format("LLL")}
              />
              <StatCard stat="Nonce" value={transactionSummary.nonce} />
              <StatCard stat="Block Height" value={transactionSummary.blockHeight} />
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 infoCol">
            <div className="infoCards">
              <p
                style={{
                  color: "var(--secondary-color)",
                  fontFamily: "Quicksand",
                  fontWeight: "700"
                }}
              >
                {" "}
                Gas Information{" "}
              </p>
              <StatCard
                stat="Gas Price (Gwei)"
                value={numberWithCommas(significantDecimals(transactionSummary.gasPrice, 5))}
              />
              <StatCard stat="Gas Used" value={numberWithCommas(transactionSummary.gasUsed)} />
              <StatCard
                stat={`Trx Fee (${usd || histUsd ? "USD" : "ETH"})`}
                value={chooseBetweenConversions(
                  [numberWithCommas(significantDecimals(fee, 5)), !usd],
                  [numberWithCommas(convertPriceToUsd(fee, usdPrice)), usd],
                  [numberWithCommas(convertPriceToUsd(fee, price)), histUsd],
                  price !== -1
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <Row style={{ paddingRight: "0", paddingTop: "0", marginTop: "10px" }}>
        <div
          className="container"
          style={{ paddingRight: "0", paddingTop: "0", marginTop: "10px" }}
        >
          <Col lg={5}>
            <p style={{ fontFamily: "Quicksand", fontSize: "15px" }}>
              <b
                style={{
                  color: "var(--secondary-color)",
                  fontFamily: "Quicksand",
                  fontWeight: "700",
                  fontSize: "18px",
                  borderBottom: "2px solid var(--secondary-color)"
                }}
              >
                From
              </b>
            </p>
            <Row>
              <Col lg={12}>
                <StatCard stat={"Address"} value={addressLink(transactionSummary.fromAddress)} />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <StatCard stat={"Wallet"} value={wallet(fromAddress)} />
              </Col>
            </Row>
          </Col>
          <Col lg={2}>
            <div>
              <p
                style={{
                  marginLeft: "15px",
                  backgroundColor: "#d1f0db",
                  padding: "5px",
                  borderRadius: "3px",
                  color: "#00875a",
                  display: "inline-block",
                  fontFamily: "Quicksand",
                  fontWeight: "600",
                  whiteSpace: "pre"
                }}
              >
                {`${transactionSummary.value} ETH ($${chooseBetweenConversions(
                  [0, false],
                  [
                    numberWithCommas(convertPriceToUsd(transactionSummary.value, usdPrice)),
                    !histUsd
                  ],
                  [numberWithCommas(convertPriceToUsd(transactionSummary.value, price)), histUsd],
                  price !== -1
                )}) `}
                <FontAwesomeIcon icon={faArrowRight} style={{ float: "right", marginTop: "2px" }} />
              </p>
            </div>
          </Col>
          <Col lg={5}>
            <p
              style={{
                fontFamily: "Quicksand",
                fontSize: "15px",
                height: "100%"
              }}
            >
              <b
                style={{
                  color: "var(--secondary-color)",
                  fontFamily: "Quicksand",
                  fontWeight: "700",
                  fontSize: "18px",
                  borderBottom: "2px solid var(--secondary-color)"
                }}
              >
                To
              </b>
            </p>
            <Row>
              <Col lg={12}>
                <StatCard stat={"Address"} value={addressLink(transactionSummary.toAddress)} />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <StatCard stat={"Wallet"} value={wallet(toAddress)} />
              </Col>
            </Row>
          </Col>
        </div>
      </Row>
      <Row
        style={{
          paddingRight: "0",
          paddingTop: "0",
          marginTop: "10px"
        }}
      >
        <div className={"col-sm-12 col-md-12 col-lg-12 infoCol"}>
          <Tabs className="inviteTabs" id="address-tab" animation={false}>
            <Tab title="Token Transfers" eventKey="token-transfers">
              <TransactionTokenTransfers
                transaction={transaction}
                stateBased={stateBased || false}
              />
            </Tab>
            <Tab title="Internal Transactions" eventKey="internal">
              <InternalTransactionList
                graphView={props.graphView || false}
                fetchTransactions={() => fetchInternalTransactions(transaction)}
                getTransactions={state => getInternalTransactions(state, transaction, true)}
                stateBased={stateBased}
                searchKey={transaction}
                showUsd={usd || histUsd}
                overridePriceConversion={priceConversion}
              />
            </Tab>
            {coin_swap && (
              <Tab title="Coin Swap" eventKey="swaps">
                <CoinSwap coinSwapData={coin_swap_data} />
              </Tab>
            )}
          </Tabs>
        </div>
      </Row>
    </Grid>
  );
};

export default TransactionEthereum;

export const EtherTransactionWithRouteBasedTabs = withRouteBasedTabs(TransactionEthereum);
export const EtherTransactionWithStateBasedTabs = withStateBasedTabs(TransactionEthereum);
