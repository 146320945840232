import {
  TokenTransfer,
  TransactionSummary
} from "../../reducers/ethereum/transaction";
import {
  InternalTransaction,
  Transactions
} from "../../reducers/ethereum/address";

export const getTransaction = (state, transaction) => {
  return state.getIn(["ethereum", "transaction", transaction]);
};

export const getTransactionSummary = (state, transaction, noNull = false) => {
  const transactionMap = getTransaction(state, transaction);
  if (transactionMap) {
    return transactionMap.get("summary").toJS();
  }
  if (noNull) {
    return new TransactionSummary().toJS();
  }
  return null;
};

export const getInternalTransactions = (state, transaction, noNull = false) => {
  const transactionMap = getTransaction(state, transaction);
  if (transactionMap) {
    return transactionMap.get("internalTransactions").toJS();
  }
  if (noNull) {
    return new Transactions().toJS();
  }
  return null;
};

export const getTransactionTokenTransfers = (
  state,
  transaction,
  noNull = false
) => {
  const transactionMap = getTransaction(state, transaction);
  if (transactionMap) {
    return transactionMap.get("tokens").toJS();
  }
  if (noNull) {
    return new TokenTransfer().toJS();
  }
  return null;
};
