/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import { connect } from "react-redux";
import { makeGetWalletTransactions } from "../../../selectors/wallet";
import WalletTransactionsBase from "../WalletTransactionsBase";
import { getProcessed } from "../../../selectors/customWallet";
import {
  fetchWalletMutualWallets,
  fetchWalletTransactions,
  fetchWalletTransactionsBySatoshi,
  purgeWalletTransactions,
  setTransactionsScrollTop,
  walletTransactionsSetDateRange,
  walletTransactionsSetLargestFirst,
  walletTransactionsSetOrder
} from "../../../actions/wallet";
import { CUSTOM_WALLETS_API } from "../../../api";

/**
 * Makes WalletTransactions' state using redux state
 * @returns {function(*=, {walletId?: *}): unknown}
 */
const makeMapStateToProps = () => {
  const getWalletTransactions = makeGetWalletTransactions();
  return (state, { walletId }) => ({
    ...getWalletTransactions(state, walletId),
    custom: true,
    processed: getProcessed(state, walletId) // map processed to redux
  });
};

/**
 * Mapping functions to dispatch calls customize to custom wallets
 *
 * TODO if WalletTransaction has custom why do we need this middle man component and not WalletTransaction deal with it
 * @param dispatch
 * @returns {{onSwapOrder: (function(*=): *), fetch: (function(*=, *=, *=, *=, *=): *), purge: purge, onSetDate: (function(*=, *=, *=): *)}}
 */
const mapDispatchToProps = (dispatch, ownProps) => ({
  __fetchByDate: (
    walletId,
    order,
    startDate = null,
    endDate = null,
    initial = false
  ) =>
    dispatch(
      fetchWalletTransactions(
        CUSTOM_WALLETS_API,
        walletId,
        order,
        startDate,
        endDate,
        initial,
        ownProps.graphId || 0
      )
    ),
  __fetchBySatoshi: (walletId, startDate = null, endDate = null) =>
    dispatch(
      fetchWalletTransactionsBySatoshi(
        CUSTOM_WALLETS_API,
        walletId,
        startDate,
        endDate,
        ownProps.graphId || 0
      )
    ),
  onSetLargestFirst: (walletId, startDate = null, endDate = null, initial) =>
    dispatch(
      walletTransactionsSetLargestFirst(
        CUSTOM_WALLETS_API,
        walletId,
        startDate,
        endDate,
        initial,
        ownProps.graphId || 0
      )
    ),
  onSetDate: (walletId, startDate, endDate) =>
    dispatch(
      walletTransactionsSetDateRange(
        CUSTOM_WALLETS_API,
        walletId,
        startDate,
        endDate,
        ownProps.graphId || 0
      )
    ),
  setTransactionsScrollTop: (wallet, scrollTop) =>
    dispatch(setTransactionsScrollTop(wallet, scrollTop)),
  fetchMutualWallets: walletId =>
    dispatch(
      fetchWalletMutualWallets(
        CUSTOM_WALLETS_API,
        walletId,
        ownProps.graphId || 0
      )
    ),
  onSetOrder: (walletId, order) =>
    dispatch(walletTransactionsSetOrder(walletId, order))
});

// TODO this is confusing still don't understand what is happening here
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { order } = stateProps;
  const { __fetchByDate, __fetchBySatoshi } = dispatchProps;

  const fetch = order === 2 ? __fetchBySatoshi : __fetchByDate;

  return {
    fetch,
    ...ownProps,
    ...stateProps,
    ...dispatchProps
  };
};

/**
 * Make WalletTransactionsBase using the props we defined above
 */
export default connect(
  makeMapStateToProps,
  mapDispatchToProps,
  mergeProps
)(WalletTransactionsBase);
