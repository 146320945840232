export const getShowMessages = state => {
  return state.getIn(["messages", "showMessages"]);
};

export const getMessages = state => {
  return state.getIn(["messages", "messages"]).toJS();
};

export const getMessagesNotViewed = state => {
  return state
    .getIn(["messages", "messages"])
    .toJS()
    .filter(({ viewed, ignored }) => !viewed && !ignored);
};
