/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types/index";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Table
} from "react-bootstrap";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { ScrollDiv } from "../../styled";
import {
  customWalletAddAddresses,
  customWalletCheckBulkAddress,
  customWalletDeleteAddress,
  deleteCustomWallet,
  fetchCustomWalletAddresses
} from "../../../actions/customWallet";
import { makeGetCustomWalletAddresses } from "../../../selectors/wallet";
import AlertsQueue from "../../Utils/Alerts/AlertsQueue";
import history from "../../history";
import CopyText from "../../CopyText";

/**
 * Represents the addresses tab on a custom wallet page, includes logic for adding
 * and deleting addresses for custom wallet.
 *
 */
class CustomWalletAddresses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressValue: "", // Value from add address text
      showAddAddressModal: false, // Whether to show add address modal
      badAddressesAlerts: [], // Stores erroneous address typed into add address for alerts
      loading: false // Whether add address submit is waiting
    };
  }

  /*
    On mount fetch addresses from api to display
   */
  componentDidMount() {
    this.props.fetch(this.props.walletId).catch(e => {
      this.props.enqueueSnackbar("Error loading custom wallet", {
        variant: "error"
      });
      history.replace("/custom-wallet");
    });
  }

  /**
    On update, refetch addresses
   */
  componentDidUpdate(prevProps) {
    const { walletId: oldWalletId } = prevProps;
    const { walletId: newWalletId } = this.props;
    if (oldWalletId !== newWalletId) {
      this.props.fetch(this.props.walletId).catch(e => {
        this.props.enqueueSnackbar("Error loading custom wallet", {
          variant: "error"
        });
        history.replace("/custom-wallet");
      });
    }
  }

  /**
   * Adds address(s) to custom wallet, used when the add address button is pressed.
   * First checks if the address is valid and checks, reports on errors if needed, then
   * adds the address
   *
   * @param e
   * @returns {Promise<void>}
   */
  addAddressIfExists = async e => {
    e.preventDefault();
    const { walletId } = this.props;
    this.setState({
      // Set loading for modal to true
      loading: true
    });

    // Trim textbox results to split it into the multiple addresses
    const addresses = this.state.addressValue.trim().split(/[\s,\n]+/);

    // Calls check address to get addresses that don't exist or conflict with other addresses
    const badAddresses = await this.props.checkAddress(walletId, addresses);

    // Makes alerts with badAddresses with the style danger used by AlertsQueue
    const alerts = badAddresses.reduce(
      (acc, curEl) => [...acc, { text: curEl, style: "danger" }],
      []
    );

    /*
      sets state to include alerts for the errors and still shows the modal if  bad addresses,
      also keeps the address value if bad addresses.
      Else, close the modal and continue to properly add address
    */
    const { addressValue } = this.state;
    this.setState({
      badAddressesAlerts: alerts,
      showAddAddressModal: badAddresses.length > 0,
      loading: false,
      addressValue: badAddresses.length === 0 ? "" : addressValue
    });

    // Call addAddress since there were no badAddresses
    if (badAddresses.length === 0) {
      this.props
        .addAddress(walletId, addresses, this.props.enqueueSnackbar)
        .then(() => {
          // notify user addresses were added at the end
          this.props.enqueueSnackbar("Added address(s)", {
            variant: "success"
          });
        })
        .catch(err => {
          // notify user of errors trying to add addresses
          if (err.response.status === 406) {
            this.props.enqueueSnackbar(
              `Exceeded Address Limit (${err.response.data})`,
              {
                variant: "error"
              }
            );
          } else {
            this.props.enqueueSnackbar("Error adding address", {
              variant: "error"
            });
          }
        });
    }
  };

  /**
   * Makes calls to handle the deletion of an address from a custom wallet
   * @param addressId address to delete
   */
  handleDeleteAddress = addressId => {
    const { walletId } = this.props;
    this.props
      .deleteAddress(walletId, addressId)
      .then(() => {
        // notify of deletion
        this.props.enqueueSnackbar(`Deleted address ${addressId}`, {
          variant: "success"
        });
      })
      .catch(() => {
        // notify of error
        this.props.enqueueSnackbar(`Error deleting address ${addressId}`, {
          variant: "error"
        });
      });
  };

  /**
   * If modal is closed, reset state variable related to the add address modal
   */
  closeAddressModal = () => {
    this.setState({
      addressValue: "",
      showAddAddressModal: false,
      badAddressesAlerts: []
    });
  };

  /**
   * Helper function for Add Address modal to grow the text box for every new line until line 25
   * @returns {number} The number of new lines in address value capped at 25
   */
  getRowSize = () => {
    const { length } = this.state.addressValue.split(/\r\n|\r|\n/);
    if (length > 25) return 25;
    return length;
  };

  render() {
    const {
      maxHeight,
      addresses,
      processed,
      currency,
      fromModal,
      getAddressLink,
      owner
    } = this.props;

    return (
      <div style={{ margin: 0, textAlign: "center" }}>
        <ScrollDiv
          maxHeight={maxHeight}
          style={{ margin: "0", borderRadius: "0" }}
        >
          <Table
            className={
              fromModal ? "walletStatsTable purple" : "walletStatsTable"
            }
            striped
            style={{
              fontFamily: "Monospace",
              position: "relative"
            }}
          >
            {/*<LimitedBackdrop open={!processed}>*/}
            {/*  <CircularProgress color="inherit" s />*/}
            {/*</LimitedBackdrop>*/}
            <thead>
              <tr>
                <th>
                  {owner && (
                    <Tooltip
                      title={
                        processed ? (
                          <h6>Add Address</h6>
                        ) : (
                          <h6>Disabled until Processed</h6>
                        )
                      }
                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          if (processed) {
                            this.setState({ showAddAddressModal: true });
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          className="addButton"
                        />
                      </IconButton>
                    </Tooltip>
                  )}{" "}
                  Address
                </th>
                <th>Transaction Count</th>
              </tr>
            </thead>
            <tbody>
              {addresses == null ? (
                <></>
              ) : (
                addresses.map(({ address, addressId, transactionCount }) => (
                  <tr key={addressId}>
                    <td>
                      {owner && (
                        <Tooltip
                          title={
                            processed ? (
                              <h6>Deletes Address</h6>
                            ) : (
                              <h6>Disabled until Processed</h6>
                            )
                          }
                        >
                          <IconButton
                            className={"customDeleteIconButton"}
                            size="small"
                            onClick={() => {
                              if (processed) {
                                this.handleDeleteAddress(address);
                              }
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              className="deleteButton"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {getAddressLink(address, currency)}{" "}
                      <CopyText
                        text={address.split(":").pop()}
                        marginLeft="0"
                      />
                    </td>
                    <td>{transactionCount}</td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
          <Modal
            show={this.state.showAddAddressModal}
            onHide={() => this.closeAddressModal()}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Address(s)</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.addAddressIfExists}>
                <FormGroup controlId="formBasicText">
                  <ControlLabel>
                    <p>Put address or list of addresses on new lines</p>
                    <p style={{ color: "red" }}>
                      Large addresses or large lists might take a while to
                      process
                    </p>
                  </ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    rows={this.getRowSize()}
                    type="text"
                    placeholder="Address"
                    value={this.state.addressValue}
                    onChange={e => {
                      this.setState({ addressValue: e.target.value });
                    }}
                    maxLength={1000}
                  />
                </FormGroup>
                {this.state.loading ? (
                  <div>
                    <CircularProgress
                      style={{ color: "var(--secondary-color)" }}
                    />
                  </div>
                ) : (
                  this.state.badAddressesAlerts.length > 0 && (
                    <AlertsQueue alerts={this.state.badAddressesAlerts} />
                  )
                )}
                <Button type="submit">Add</Button>
              </Form>
            </Modal.Body>
          </Modal>
        </ScrollDiv>
      </div>
    );
  }
}

CustomWalletAddresses.propTypes = {
  currency: PropTypes.string.isRequired,
  walletId: PropTypes.string.isRequired,
  maxHeight: PropTypes.number.isRequired,
  fetch: PropTypes.func.isRequired,
  getAddressLink: PropTypes.func.isRequired,
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      addressId: PropTypes.number.isRequired,
      transactionCount: PropTypes.number.isRequired
    })
  ).isRequired,
  addAddress: PropTypes.func.isRequired,
  checkAddress: PropTypes.func.isRequired,
  deleteWallet: PropTypes.func.isRequired,
  deleteAddress: PropTypes.func.isRequired,
  processed: PropTypes.bool.isRequired,
  owner: PropTypes.bool.isRequired,
  fromModal: PropTypes.bool, // TODO figure out the implications of this
  name: PropTypes.string,
  enqueueSnackbar: PropTypes.func
};

CustomWalletAddresses.defaultProps = {
  fromModal: false,
  name: ""
};

const makeMapStateToProps = () => {
  const getAddressInfo = makeGetCustomWalletAddresses();

  return (state, { walletId }) => getAddressInfo(state, walletId, true, true);
};

const mapDispatchToProps = (dispatch, { graphId }) => ({
  fetch: walletId =>
    dispatch(fetchCustomWalletAddresses(walletId, graphId || 0)),
  addAddress: (walletId, address, enqueueSnackbar) =>
    dispatch(customWalletAddAddresses(walletId, address, enqueueSnackbar)),
  checkAddress: (walletId, address) =>
    dispatch(customWalletCheckBulkAddress(walletId, address)),
  deleteWallet: (walletId, name, enqueueSnackbar) =>
    dispatch(deleteCustomWallet(walletId, name, enqueueSnackbar)),
  deleteAddress: (walletId, addressId) =>
    dispatch(customWalletDeleteAddress(walletId, addressId))
});

export default withRouter(
  withSnackbar(
    connect(
      makeMapStateToProps,
      mapDispatchToProps
    )(CustomWalletAddresses)
  )
);
