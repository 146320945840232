import { useEffect, useState } from "react";
import { Alert, Button, Table } from "react-bootstrap";
import { ScrollDiv } from "../styled";
import CopyText from "../CopyText";
import WrappedLink from "../Utils/WrappedLink";
import { Skeleton } from "@material-ui/lab";

import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Filters, FilterType } from "./interfaces";
import { SideFilterButton } from "./SideFilterButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { AddButton } from "../Utils/AddButton";
import {
  chooseBetweenConversions,
  convertPriceToUsd,
  numberWithCommas,
  significantDecimals
} from "../../helpers";
import { getUsdForCoin } from "../../selectors/currency";
import { Tooltip, TextField } from "@material-ui/core";

interface InternalTransactionListProps {
  graphView: boolean;
  fetchTransactions: (filter: FilterType) => any;
  getTransactions: (state: any, filter: FilterType) => any;
  loadingRows?: number;
  stateBased: boolean;
  bold?: string;
  searchKey: string;
  enabledFilters?: Set<Filters>;
  addToGraph?: (addressId: string) => void;
  addresses?: Set<string>;
  showUsd?: boolean;
  showHist?: boolean;
  overridePriceConversion?: number;
}
export const InternalTransactionList = ({
  graphView = false,
  getTransactions,
  fetchTransactions,
  loadingRows = 5,
  stateBased = false,
  bold,
  searchKey,
  enabledFilters = new Set<Filters>(),
  addToGraph,
  addresses,
  showUsd = false,
  showHist = false,
  overridePriceConversion
}: InternalTransactionListProps) => {
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState<FilterType>({
    side: "everything"
  });
  const { transactions, offset } = useSelector((state: any) => getTransactions(state, filter));
  const usdPrice = useSelector(state => getUsdForCoin(state));

  const dispatch = useDispatch();
  const loadTransactions = async () => {
    setLoading(true);
    await dispatch(fetchTransactions(filter));
    setLoading(false);
  };

  useEffect(() => {
    if (transactions && transactions.length > 0) {
      setLoading(false);
    } else {
      loadTransactions().catch(console.error);
    }
  }, [dispatch, searchKey, filter]);

  const maxHeight = window.innerHeight > 400 ? window.innerHeight - 400 : 100;

  const buttonDisabled = offset === -1;
  const buttonText = !buttonDisabled ? "Load More ..." : "No More";
  // Number of columns in table changes depending on if on the graph sidebar
  const numTableColumns = graphView ? 7 : 9;

  const getValue = (value: number, price: number, priceAvailable: boolean) => {
    const tokenAmount = value / Math.pow(10, 18);

    return chooseBetweenConversions(
      [numberWithCommas(significantDecimals(tokenAmount, 5)), !showUsd],
      [
        "$" + numberWithCommas(convertPriceToUsd(tokenAmount, overridePriceConversion || usdPrice)),
        showUsd
      ],
      [
        "$" + numberWithCommas(convertPriceToUsd(tokenAmount, overridePriceConversion || price)),
        showHist
      ],
      priceAvailable,
      false
    );
  };
  return (
    <div>
      <div className="row" style={{ marginLeft: "0", marginBottom: "15px", marginTop: "15px" }}>
        <div className={graphView ? "purpleButtonFilter" : "blueButtonFilter"}>
          <Button
            className={"whiteButton"}
            onClick={_ => {
              loadTransactions().catch(console.error);
            }}
            disabled={buttonDisabled || loading}
            style={{ float: "right" }}
          >
            {buttonText}
          </Button>
          <div style={{ display: "flex", fontSize: "15px" }}>
            {enabledFilters.has("side") && (
              <SideFilterButton
                side={filter.side}
                setSide={side => setFilter({ side })}
                graphView={graphView}
              />
            )}
          </div>
        </div>
      </div>
      {loading || transactions.length > 0 ? (
        <ScrollDiv
          maxHeight={maxHeight}
          id="walletTransactionsTable"
          // onScroll={this.setTableScrollTop}
        >
          <Table
            className="inviteTable"
            style={{
              width: "100%",
              tableLayout: "fixed",
              overflow: "auto",
              wordWrap: "break-word"
            }}
          >
            <thead>
              <tr>
                {/*Not showing block and trx fee on graph sidebar to save space*/}
                <th style={{ width: "15%" }}>Time (UTC)</th>
                <th style={{ width: "15%" }}>Trx Hash</th>
                <th style={{ width: "7%" }}>Index</th>
                <th style={{ width: "13%" }}>Function</th>
                {!graphView && <th style={{ width: "10%" }}>Block</th>}
                <th style={{ width: "15%" }}>Sender</th>
                <th style={{ width: "15%" }}>Receiver</th>
                <th style={{ width: "10%" }}>
                  {!graphView ? `Value` : ""} ({showUsd ? "USD" : "ETH"})
                </th>
                {!graphView && (
                  <th style={{ width: "10%" }}>Trx Fee ({showUsd ? "USD" : "ETH"})</th>
                )}
              </tr>
            </thead>
            <tbody style={{ position: "relative" }}>
              {}
              {}
              {transactions &&
                transactions.map(
                  ({
                    transaction,
                    sender_id,
                    receiver_id,
                    block_id,
                    value,
                    trx_fee,
                    call_index,
                    function_signature,
                    price,
                    priceAvailable,
                    timestamp
                  }: any) => (
                    <tr key={transaction + call_index} className="inviteRowThin staticTableRow">
                      <td>{timestamp}</td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap"
                        }}
                      >
                        <CopyText text={transaction} marginRight="4px" marginLeft="0" />

                        <WrappedLink
                          entity={transaction}
                          entityType={"transaction"}
                          stateBased={stateBased || false}
                          style={{ fontWeight: "500" }}
                        >
                          {transaction}
                        </WrappedLink>
                      </td>
                      <td>{call_index}</td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap"
                        }}
                      >
                        <Tooltip title={function_signature}>
                          <div style={{ cursor: "pointer" }}>{function_signature}</div>
                        </Tooltip>
                      </td>
                      {!graphView && <td> {block_id} </td>}
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {addToGraph &&
                          (addresses.has(sender_id.account_id) ? (
                            <FontAwesomeIcon
                              className="copyText"
                              style={{
                                color: "green",
                                fontSize: "14px",
                                marginLeft: "0px",
                                marginRight: "10px"
                              }}
                              icon={faCheck}
                            />
                          ) : (
                            <AddButton
                              callback={() => addToGraph(sender_id.account_id)}
                              marginRight="10px"
                              marginLeft="0"
                              color={"green"}
                            />
                          ))}
                        <CopyText text={sender_id.account_id} marginRight="4px" marginLeft="0" />
                        <WrappedLink
                          entity={sender_id.account_id}
                          entityType={"address"}
                          stateBased={stateBased || false}
                          style={{ fontWeight: "500" }}
                        >
                          {sender_id.account_id === bold ? (
                            <strong>
                              {sender_id["name_attribution"] ||
                                sender_id["name_token"] ||
                                sender_id.account_id}
                            </strong>
                          ) : (
                            sender_id["name_attribution"] ||
                            sender_id["name_token"] ||
                            sender_id.account_id
                          )}
                        </WrappedLink>
                      </td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {addToGraph &&
                          (addresses.has(receiver_id.account_id) ? (
                            <FontAwesomeIcon
                              className="copyText"
                              style={{
                                color: "green",
                                fontSize: "14px",
                                marginLeft: "0px",
                                marginRight: "10px"
                              }}
                              icon={faCheck}
                            />
                          ) : (
                            <AddButton
                              callback={() => addToGraph(receiver_id.account_id)}
                              marginRight="10px"
                              marginLeft="0"
                              color={"green"}
                            />
                          ))}
                        <CopyText text={receiver_id.account_id} marginRight="4px" marginLeft="0" />
                        <WrappedLink
                          entity={receiver_id.account_id}
                          entityType={"address"}
                          stateBased={stateBased || false}
                          style={{ fontWeight: "500" }}
                        >
                          {receiver_id.account_id === bold ? (
                            <strong>
                              {receiver_id["name_attribution"] ||
                                receiver_id["name_token"] ||
                                receiver_id.account_id}
                            </strong>
                          ) : (
                            receiver_id["name_attribution"] ||
                            receiver_id["name_token"] ||
                            receiver_id.account_id
                          )}
                        </WrappedLink>
                      </td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {getValue(value, price, priceAvailable)}
                      </td>
                      {!graphView && (
                        <td
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap"
                          }}
                        >
                          {getValue(trx_fee, price, priceAvailable)}
                        </td>
                      )}
                    </tr>
                  )
                )}
              {loading &&
                [...Array(loadingRows)].map(_ => (
                  <tr className="inviteRowThin staticTableRow">
                    {[...Array(numTableColumns)].map(_ => (
                      <td>
                        <Skeleton />
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </Table>
        </ScrollDiv>
      ) : (
        <Alert style={{ boxShadow: "0px 2px 2px 0px #666" }} bsStyle="warning">
          No transactions found
        </Alert>
      )}
    </div>
  );
};
