/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button } from "react-bootstrap";

import WalletTag from "./Mention";
import { ScrollDiv } from "../styled";
import { getWalletMentions } from "../../selectors/wallet";
import { fetchWalletMentions, purgeWalletMentions } from "../../actions/wallet";
import { WALLET_API } from "../../api";
import { connect } from "react-redux";
import { getProcessed } from "../../selectors/customWallet";

class WalletMentionsBase extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    this.getOrFetchWalletMentions(); // Fetch wallet transactions if not in the redux store
  }

  async componentDidUpdate(prevProps) {
    const { walletId: oldWalletId, processed: lastProcessed } = prevProps;
    const {
      walletId: newWalletId,
      processed: curProcessed,
      purge
    } = this.props;
    if (newWalletId !== oldWalletId) {
      this.fetchWalletMentions(); // fetch transactions for in wallet
    }

    // if lastProcessed is false and curProcessed is true that means the wallet has changed
    // Need to purge old transaction data and refetch from beginning
    if (!lastProcessed && curProcessed) {
      // Addresses changed need to refetch all the transactions.
      await purge(newWalletId);
      this.fetchWalletMentions();
    }
  }

  fetchWalletMentions = () => {
    const { fetch, apiBase, walletId } = this.props;
    this.setState({ loading: true });
    fetch(apiBase, walletId).finally(() => this.setState({ loading: false }));
  };

  /**
    get if it already exists in state if not fetch
   */
  getOrFetchWalletMentions = () => {
    if (!this.props.mentions || this.props.mentions.length === 0) {
      this.fetchWalletMentions();
    } else {
      this.setState({ loading: false });
    }
  };

  render() {
    const { maxHeight, walletId, mentions, nextKey } = this.props;
    let buttonText;
    if (mentions.length === 0) {
      buttonText = "None     ";
    } else if (nextKey) {
      buttonText = "Load More ...";
    } else {
      buttonText = "No more  ";
    }

    const mentionRows = mentions.map(tag_ => {
      const { username, onlineAccountId, sourceName, count } = tag_;
      return (
        <WalletTag
          key={onlineAccountId}
          onlineAccountId={onlineAccountId}
          username={username}
          sourceName={sourceName}
          walletId={walletId}
          count={count}
        />
      );
    });

    return (
      <div
        style={{
          width: "100%",
          padding: "0",
          marginLeft: "0",
          textAlign: "center"
        }}
      >
        <ScrollDiv
          maxHeight={maxHeight}
          style={{
            margin: "0",
            marginBottom: "20px",
            textAlign: "center",
            width: "100%"
          }}
        >
          <div
            className="row"
            style={{
              padding: "0",
              margin: "0",
              textAlign: "center",
              width: "100%",
              marginTop: "15px"
            }}
          >
            {mentionRows}
          </div>
        </ScrollDiv>
        <Button
          className="whiteButton"
          onClick={this.fetchWalletMentions}
          disabled={this.state.loading || this.dis}
        >
          {buttonText}
        </Button>
      </div>
    );
  }
}

WalletMentionsBase.propTypes = {
  walletId: PropTypes.string.isRequired,
  fetch: PropTypes.func.isRequired,
  maxHeight: PropTypes.number.isRequired,
  nextKey: PropTypes.number,
  mentions: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      onlineAccountId: PropTypes.number.isRequired,
      sourceName: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired
    })
  ).isRequired,
  apiBase: PropTypes.func
};

WalletMentionsBase.defaultProps = {
  nextKey: null,
  mentions: [],
  apiBase: WALLET_API
};

const mapStateToProps = (state, props) => {
  const { walletId } = props;
  return {
    processed: getProcessed(state, walletId),
    ...getWalletMentions(state, walletId, true)
  };
};

const mapDispatchToProps = dispatch => ({
  fetch: (apiBase, walletId) =>
    dispatch(fetchWalletMentions(apiBase, walletId, false)),
  purge: walletId => dispatch(purgeWalletMentions(walletId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletMentionsBase);
