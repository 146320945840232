/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import PropTypes from "prop-types";

const withStateBasedTabs = Component => {
  class WrappedComponent extends React.Component {
    state = {
      key: "transactions"
    };

    handleRouteChange = key => {
      switch (key) {
        case "transactions":
          this.setState({ key: "transactions" });
          break;
        case "mentions":
          this.setState({ key: "mentions" });
          break;
        case "bsa":
          this.setState({ key: "bsa" });
          break;
        case "ip-addresses":
          this.setState({ key: "ip-addresses" });
          break;
        case "notes":
          this.setState({ key: "notes" });
          break;
        case "risk":
          this.setState({ key: "risk" });
          break;
        default:
          break;
      }
    };

    render() {
      const { address, ...passThroughProps } = this.props;

      return (
        <Component
          address={address}
          tabKey={this.state.key}
          onTabSelect={this.handleRouteChange}
          stateBased={true}
          customWalletEnabled={false}
          {...passThroughProps}
        />
      );
    }
  }

  WrappedComponent.propTypes = {
    address: PropTypes.string.isRequired
  };

  WrappedComponent.displayName = `withRouteBasedTabs(${Component.displayName || Component.name})`;

  return WrappedComponent;
};

withStateBasedTabs.propTypes = {
  address: PropTypes.string.isRequired
};

export default withStateBasedTabs;
