import React, { useState } from "react";
import { connect } from "react-redux";
import { getFeature } from "../../selectors/features";
import { getCurrency } from "../../selectors/currency";
import { getRole, getTwoFASuccess } from "../../selectors/authentication";
import { getCoins } from "../../selectors/coins";
import { SWAP_CURRENCY } from "../../actions/actionNames";
import {Col, Form, Grid, Row} from "react-bootstrap";
import { Helmet } from "react-helmet";
import ReactCodeInput from "react-code-input";
import { Button } from "@material-ui/core";
import { registerPhoneNumber, submitTwoFactor } from "../../actions/authentication";
import { Redirect } from "react-router-dom";
import axios from "../../api";
import {isPossiblePhoneNumber, isValidPhoneNumber} from "libphonenumber-js";
import PhoneInput from "react-phone-number-input";

const EnterPhoneNumber = ({ setCodeSent, registerPhone }) => {
  const [error, setError] = useState(null);
  const [phone, setPhone] = useState("");

  // This method registers the phone number the user entered and then sends a 2FA code to it
  const sendCode = async () => {
    // Dispatch the action to register the phone number
    let numberStatus = await registerPhone(phone);

    // This if statement checks if the register phone step was successful
    if (numberStatus) {
      // API call to send the message
      let { data } = await axios.put("/api/v2/2fa-text", {
        phone: phone,
      });

      // Check if there was an error
      if (!data) {
        setError(true);
      } else {
        setCodeSent(true);
      }
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Grid>
        <Helmet>
          <title>2FA Enter</title>
        </Helmet>

        <Row style={{ paddingTop: "50px" }}>
          <Col lg={12}>
            <Row style={{ textAlign: "center", marginBottom: "20px" }}>
              <p style={{ fontSize: "20px" }}> Enter your Phone Number </p>
            </Row>
            <Row style={{ textAlign: "center", marginBottom: "20px", justifyContent: 'center'}}>
               <PhoneInput
                   style={{fontSize: 30, maxWidth: "50%", margin: "auto"}}
                   defaultCountry={"US"}
                   countries={["US", "RO"]}
                   addInternationalOption={false}
                  // international
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={setPhone}
                  error={phone ? (isPossiblePhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                />
            </Row>
            <Row style={{ textAlign: "center", marginBottom: "20px", justifyContent: 'center' }}>
              {phone && isPossiblePhoneNumber(phone) && (
                <Button
                  style={{
                    backgroundColor: "var(--base-color)",
                    color: "white",
                    fontFamily: "Quicksand",
                    fontSize: "12px",
                  }}
                  onClick={() => sendCode()}
                >
                  Submit
                </Button>
              )}
            </Row>
            {error && (
              <Row style={{ textAlign: "center" }}>
                <p style={{ color: "red", fontSize: "14px" }}>
                  {" "}
                  There was an error, please confirm your phone number and try again.
                </p>
              </Row>
            )}
          </Col>
        </Row>
      </Grid>
    </>
  );
};

const mapStateToProps = state => {
  return {
    throwExceptionComponentEnabled: getFeature(state, "throw_exception_test"),
    issuesEnabled: getFeature(state, "issues"),
    currency: getCurrency(state),
    isResearcher: getRole(state) === "researcher",
    success: getTwoFASuccess(state),
  };
};

const mapDispatchToProps = dispatch => ({
  swapCurrency: currency => dispatch({ type: SWAP_CURRENCY, currency }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnterPhoneNumber);
