/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Skeleton } from "@material-ui/lab";
import { Row } from "reactstrap";
import Col from "react-bootstrap/lib/Col";

/**
 * A line of text that displays a 'stat' and a 'value'. This
 * is used in Address/WalletSummary.
 *
 * loading prop determines whether to display a skeleton
 * @param {object} props
 * @returns {React.Component}
 * @constructor
 */
const StatCard = ({
  stat,
  value,
  clickableValue,
  clickFunction = () => {},
  loading,
  pullRight
}) => (
  <Row style={{ paddingBottom: "0", paddingTop: 0 }}>
    <Col md={5} sm={5} lg={5}>
      <b
        style={{
          marginRight: "5px",
          color: "#666",
          fontFamily: "Quicksand",
          fontWeight: "700"
        }}
      >
        {stat}
      </b>
    </Col>
    <Col md={7} sm={7} lg={7}>
      {loading ? (
        <Skeleton s />
      ) : (
        <p
          className={` ${clickableValue ? "hoverableStatCard" : ""}`}
          style={{
            fontFamily: "Quicksand",
            fontWeight: "700",
            ...(clickableValue && { cursor: "pointer" }),
            padding: 0,
            marginBottom: 0,
            marginLeft: 5,
            textAlign: pullRight ? "right" : "left"
          }}
          onClick={clickFunction}
        >
          {value}
        </p>
      )}
    </Col>
  </Row>
);

const StyledStatCard = styled(StatCard)`
  font-family: Monospace;
`;

export default StyledStatCard;

StatCard.defaultProps = {
  clickableValue: false,
  loading: false,
  pullRight: true
};

StatCard.propTypes = {
  stat: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
  clickableValue: PropTypes.bool,
  clickFunction: PropTypes.func,
  loading: PropTypes.bool
};
