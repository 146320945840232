import * as React from "react";
import CopyText from "../CopyText";

export const SupportText = () => {
  return (
    <>
      <br />
      <br />
      <p style={{ textAlign: "center" }}>
        Contact
        <a href={"mailto:" + "support@sievakocorp.com"}> support@sievakocorp.com</a>{" "}
        <CopyText marginLeft={"5px"} text={"support@sievakocorp.com"} />
        for support
      </p>
    </>
  );
};
