/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Tab, Table, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { cytoscapeNodeShape } from "../../prop-types";
import { FakeLinkSpan, ScrollDiv } from "../styled";
import { addWalletToGraph, openTransactionModal } from "../../actions/graph";
import { fetchTransaction } from "../../actions/transaction";
import { getTransactionInfo } from "../../selectors/transaction";
import TransactionNodeStats from "./TransactionNodeStats";
import { satoshiToBitcoin3 } from "../../helpers";
import { getWalletSetMemo } from "../../selectors/graph";
import CurrencyChooserConfig from "../CurrencyChooserConfig";
import { getCurrency } from "../../selectors/currency";

class TransactionNodeView extends Component {
  componentDidMount() {
    // When the component mounts, we want to fetch the transaction information
    const transaction = this.props.trx.data("id");
    this.props.fetch(transaction);
  }

  render() {
    const {
      trx,
      handleOpenTransactionModal,
      cy,
      dataTabsKey,
      transactionInfo,
      wallets,
      addWalletToGraph
    } = this.props;

    const receivedWalletRows = transactionInfo.outputWallets.map(wallet => {
      const { walletId, satoshi, primaryTag } = wallet;
      return (
        <tr>
          <td> {primaryTag || walletId} </td>
          <td> {satoshiToBitcoin3(satoshi)} </td>
          <td>
            {" "}
            {wallets.has(walletId.toString()) ? (
              <Button style={{ float: "right" }} className="greenButtonGraph" bsSize="xsmall">
                {" "}
                <FontAwesomeIcon icon={faCheck} /> Added{" "}
              </Button>
            ) : (
              <Button
                style={{ float: "right" }}
                className="purpleButton"
                bsSize="xsmall"
                onClick={() => addWalletToGraph(walletId, cy)}
              >
                Add To Graph
              </Button>
            )}
          </td>
        </tr>
      );
    });

    const sentWalletRows = transactionInfo.inputWallets.map(wallet => {
      const { walletId, satoshi, primaryTag } = wallet;
      return (
        <tr>
          <td> {primaryTag || walletId} </td>
          <td> {satoshiToBitcoin3(satoshi)} </td>
          <td>
            {" "}
            {wallets.has(walletId.toString()) ? (
              <Button style={{ float: "right" }} className="greenButtonGraph" bsSize="xsmall">
                {" "}
                <FontAwesomeIcon icon={faCheck} /> Added{" "}
              </Button>
            ) : (
              <Button
                style={{ float: "right" }}
                className="purpleButton"
                bsSize="xsmall"
                onClick={() => addWalletToGraph(walletId, cy)}
              >
                Add To Graph
              </Button>
            )}
          </td>
        </tr>
      );
    });

    let currencyObject = CurrencyChooserConfig[this.props.currency];

    return (
      <div className="activeOptionMainDiv">
        <p style={{ fontSize: "24px", marginLeft: "10px" }}>Transaction</p>
        <p
          style={{
            fontSize: "15px",
            marginLeft: "10px",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          <FakeLinkSpan
            style={{ color: "white", fontSize: "18px", fontWeight: "500" }}
            onClick={() => handleOpenTransactionModal(trx.data("id"))}
          >
            {trx.data("id")}
          </FakeLinkSpan>
        </p>
        <div
          className="tabWrapper walletGraphData"
          style={{
            borderLeft: "1px solid white",
            borderRadius: "0",
            marginLeft: "0"
          }}
        >
          <Tabs
            style={{ width: "100%" }}
            activeKey={dataTabsKey}
            id="transaction-tab"
            onSelect={this.handleSelect}
            animation={false}
            mountOnEnter
          >
            <Tab
              eventKey="WalletStats"
              title="Stats"
              style={{ marginLeft: "0", paddingBottom: "0" }}
            >
              <br />
              <TransactionNodeStats trxInfo={transactionInfo} />
            </Tab>
            <Tab eventKey="Sent" title="Sent" style={{ marginLeft: "0", paddingBottom: "0" }}>
              <br />
              <ScrollDiv style={{ margin: "0", borderRadius: "0", paddingBottom: "0" }}>
                <Table className="walletStatsTable purple">
                  <thead>
                    <tr>
                      <th>Entity</th>
                      <th className="text-left">{currencyObject.abb}</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>{sentWalletRows}</tbody>
                </Table>
              </ScrollDiv>{" "}
            </Tab>
            <Tab
              eventKey="Received"
              title="Received"
              style={{ marginLeft: "0", paddingBottom: "0" }}
            >
              <br />
              <ScrollDiv style={{ margin: "0", borderRadius: "0", paddingBottom: "0" }}>
                <Table className="walletStatsTable purple">
                  <thead>
                    <tr>
                      <th>Entity</th>
                      <th className="text-left">Bitcoins</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>{receivedWalletRows}</tbody>
                </Table>
              </ScrollDiv>
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

TransactionNodeView.propTypes = {
  trx: cytoscapeNodeShape.isRequired,
  cy: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};

const mapStateToProps = (state, ownProps) => {
  return {
    transactionInfo: getTransactionInfo(state, ownProps.trx.data("id")),
    wallets: getWalletSetMemo(state, ownProps.graphId),
    currency: getCurrency(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleOpenTransactionModal: transaction => dispatch(openTransactionModal(transaction)),
  fetch: transaction => dispatch(fetchTransaction(transaction)),
  addWalletToGraph: (walletId, cy) =>
    dispatch(
      addWalletToGraph(walletId, null, null, ownProps.graphId, cy, null, true, "", "transaction")
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionNodeView);
