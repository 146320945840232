/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { chooseBetweenConversions, numberWithCommas, satoshiToBitcoin } from "../../helpers";
import CategoryColorsConfig from "../Graph/CategoryColorsConfig";
import { Skeleton } from "@material-ui/lab";
import WalletName from "./CustomWallet/WalletName";
import ReportIssue from "../ReportIssue";
import CustomWalletNameEdit from "./CustomWallet/CustomWalletNameEdit";
import { CreateCustomTag } from "../CreateCustomTag";
import AddToWatchlist from "../Home/Watchlist/AddToWatchlist";
import StatCard from "../StatCard";
import { getCurrency } from "../../selectors/currency";
import { getClientMode } from "../../selectors/applicationConfig";
import { getProcessed } from "../../selectors/customWallet";
import { getEmail } from "../../selectors/authentication";
import DeleteEntity from "./CustomWallet/DeleteEntity";
import ClientModeConfig from "../ClientModeConfig";
import { withSnackbar } from "notistack";
import { connect, useSelector } from "react-redux";
import CurrencyChooserConfig from "../CurrencyChooserConfig";
import BtcToUsdChooser from "../Utils/BtcToUsdChooser";
import ChangeSwitch from "../Utils/ChangeSwitch";
import CategoryName from "./CategoryName";
import { ShareButton } from "../Utils/ShareButton";
import { IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import AddToNote from "../NoteEditor/AddToNote";
import { RiskButton } from "../Risk/RiskButton";

/**
 * WalletSummary represents the top part with basic wallet stats.
 * It also contains logic for adding to graph, custom wallet, and issues
 * @param inputSatoshi
 * @param outputSatoshi
 * @param balance
 * @param addToGraph
 * @param walletId
 * @param primaryTag
 * @param name name of wallet
 * @param custom If it is a custom wallet
 * @param onDeleteFunc
 * @param addressCount
 * @param inputCount
 * @param outputCount
 * @param transactionCount
 * @param firstTransaction
 * @param lastTransaction
 * @param category category of wallet if tagged
 * @param purge function to purge information in redux
 * @param fetch function to fetch stats from api
 * @param showAsUsd
 * @param convertPriceToUsd
 * @param toggleUsdDisplay
 * @returns {JSX.Element}
 * @constructor
 */
const WalletSummary = ({
  inputSatoshi,
  outputSatoshi,
  balance,
  addToGraph,
  walletId,
  name,
  custom,
  onDeleteFunc,
  addressCount,
  inputCount,
  outputCount,
  transactionCount,
  firstTransaction,
  lastTransaction,
  category,
  purge,
  fetch,
  fetchMore,
  // These columns are ones calculated on the spot or large_wallet_stats.
  sent_hist_usd,
  recv_hist_usd,
  sent_no_self,
  recv_no_self,
  sent_usd_no_self,
  recv_usd_no_self,
  showChange,
  toggleChange,
  currentUsdPrice,
  showAsUsd,
  convertPriceToUsd,
  toggleUsdDisplay,
  historicalToUsd,
  toggleHistoricalUsd,
  email,
  wisteria_category,
  wisteria_category_confidence_score,
  showTagChanges = true,
  ...props
}) => {
  // TODO simplify using useReducer
  const [loading, setLoading] = useState(true); // Manages whether we are waiting for data to load
  const [moreLoading, setMoreLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true); //First time loading component so not double loading in the start.

  const currency = useSelector(state => getCurrency(state));
  const clientMode = useSelector(state => getClientMode(state));
  const processed = useSelector(state => getProcessed(state, walletId));

  /**
   * If the processed changes that means the custom wallet has changed so we have to
   * purge the old data and update it
   */
  useEffect(() => {
    (async () => {
      if (processed && !firstLoad) {
        // Addresses changed need to refetch all the transactions.
        setLoading(true);
        await purge(walletId);
        await fetch(walletId);
        setLoading(false);
        await fetchMore(walletId);
        setMoreLoading(false);
      }
    })();
  }, [processed]);

  /**
   * On start up fetch the wallet's stats
   */
  useEffect(() => {
    (async () => {
      setLoading(true);
      setMoreLoading(true);
      await fetch(walletId);
      setLoading(false);
      await fetchMore(walletId);
      setMoreLoading(false);
      setFirstLoad(false);
    })();
  }, []);

  const displayLoading = loading; // to show loading skeleton

  // converting props to strings
  const addressCountString = Number(addressCount).toLocaleString();
  const inputCountString = Number(inputCount).toLocaleString();
  const outputCountString = Number(outputCount).toLocaleString();
  const transactionCountString = Number(transactionCount).toLocaleString();

  // calculating bitcoin amounts
  let balanceBitcoin = satoshiToBitcoin(balance);
  if (balanceBitcoin < 0.0) {
    balanceBitcoin = 0.0;
  }
  const showChangeWithDefault = showChange || sent_hist_usd === -1;
  const outputBitcoin = satoshiToBitcoin(showChangeWithDefault ? outputSatoshi : recv_no_self);
  const inputBitcoin = satoshiToBitcoin(showChangeWithDefault ? inputSatoshi : sent_no_self);
  const inputHistUsd = showChangeWithDefault ? sent_hist_usd : sent_usd_no_self;
  const outputHistUsd = showChangeWithDefault ? recv_hist_usd : recv_usd_no_self;
  const showUsdText = showAsUsd || historicalToUsd;

  // Get dates in utc time
  const firstTransactionDate = moment
    .unix(parseInt(firstTransaction, 10))
    .utc()
    .format("LLL");
  const lastTransactionDate = moment
    .unix(parseInt(lastTransaction, 10))
    .utc()
    .format("LLL");

  let currencyObject = CurrencyChooserConfig[currency];

  let clientModeConfigObject = {
    word_for_collection: "",
    cap_word_for_collection: "",
    plural_for_collection: "",
    cap_plural_for_collection: "",
    nav_logo: null,
    login_logo: null,
    login_top_logo: null,
    login_top_icon: null
  };
  if (clientMode in ClientModeConfig) {
    clientModeConfigObject = ClientModeConfig[clientMode];
  }

  let categoryColor = "white";
  let categoryFontColor = "black";
  let categoryLabel = category;
  let wisteriaCategoryColor = "white";
  let wisteriaCategoryFontColor = "black";
  let wisteriaCategoryLabel = category;
  CategoryColorsConfig(clientModeConfigObject).forEach(_category => {
    if (_category.category === category) {
      categoryColor = _category.hex;
      categoryFontColor = ["Pools", "Faucet", "Gambling"].includes(category) ? "#333" : "white";
      categoryLabel = _category.label;
    }

    if (_category.category === wisteria_category) {
      wisteriaCategoryColor = _category.hex;
      wisteriaCategoryFontColor = ["Pools", "Faucet", "Gambling"].includes(wisteria_category)
        ? "#333"
        : "white";
      wisteriaCategoryLabel = _category.label;
    }
  });

  // For displaying the wallet of the wallet.
  const renderWalletHeader = currencyObject => {
    return (
      <>
        <div className="row" style={{ marginBottom: "20px" }}>
          <div className="col-md-12 col-lg-12" style={{ display: "flex", alignItems: "center" }}>
            {loading ? (
              <Skeleton width={300} height={45} />
            ) : (
              <WalletName
                wallet_id={walletId}
                loading={custom && !processed}
                variant="h2"
                custom={custom || walletId < 0}
              />
            )}
          </div>
          <div className="col-md-12 col-lg-12" style={{ display: "flex", alignItems: "center" }}>
            {wisteria_category && (
              <CategoryName
                categoryLabel={wisteriaCategoryLabel}
                categoryColor={wisteriaCategoryColor}
                categoryFontColor={wisteriaCategoryFontColor}
                confidence_score={wisteria_category_confidence_score}
                category={wisteria_category}
              />
            )}
            {!wisteria_category && category !== "Other" && (
              <CategoryName
                categoryLabel={categoryLabel}
                categoryColor={categoryColor}
                categoryFontColor={categoryFontColor}
                category={category}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      className="container-fluid"
      style={{
        borderWidth: "0 0 0 2px",
        borderColor: "var(--secondary-color)",
        borderStyle: "solid",
        paddingBottom: "0"
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <h4 className="entityHeader">
          {(custom ? "Custom " : "") + `${clientModeConfigObject.cap_word_for_collection}`}
        </h4>
        <div style={{ marginLeft: "auto" }}>
          {addToGraph === null ? "" : addToGraph()}{" "}
          <AddToNote page={`${clientModeConfigObject.cap_word_for_collection}`} itemId={walletId} />
          <ReportIssue
            style={{ marginLeft: "15px" }}
            entityName={walletId}
            entityType={"wallet"}
            currency={currency}
          />{" "}
          {showTagChanges &&
            (custom ? (
              email &&
              props.user_email === email && (
                <CustomWalletNameEdit walletId={walletId} primaryTag={name || walletId} />
              )
            ) : (
              <CreateCustomTag wallet_id={walletId} />
            ))}
          {!custom && <AddToWatchlist wallet_id={walletId} entityType={"Wallet"} />}
          {/* Add delete button and share button for owner, else show owner and delete share button */}
          {custom &&
            email &&
            (props.user_email === email ? (
              <>
                <DeleteEntity onDelete={onDeleteFunc} entityId={walletId} name={name || walletId} />
                <ShareButton
                  callback={email =>
                    props.shareCustomWallet(walletId, email, props.enqueueSnackbar, currency)
                  }
                  getSharedUsers={() =>
                    props.getSharedUsers(walletId, props.enqueueSnackbar, currency)
                  }
                  removeUser={email =>
                    props.removeSharedUser(walletId, email, props.enqueueSnackbar)
                  }
                />
              </>
            ) : (
              // Not the owner of the tag so just show who owns it.
              <>
                <>{`Owned by ${email}`}</>
                <IconButton
                  size="small"
                  onClick={async () => {
                    await props.removeSharedUser(walletId, props.user_email, props.enqueueSnackbar);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="deleteButton"
                    style={{ fontSize: "20px" }}
                  />
                </IconButton>
              </>
            ))}
        </div>
      </div>
      {renderWalletHeader(currencyObject)}
      <div className="row" style={{ padding: 0 }}>
        <div className="col-lg-4 col-md-4 col-sm-4">
          <p
            style={{
              color: "var(--secondary-color)",
              fontFamily: "Quicksand",
              fontWeight: "700",
              fontSize: "20px",
              display: "inline"
            }}
          >
            Summary
          </p>
          <ChangeSwitch
            showChange={showChangeWithDefault}
            toggleChange={toggleChange}
            noChange={sent_hist_usd === -1}
            loading={moreLoading}
          />
        </div>
        {!custom && props.fetchRiskScore && (
          <div className={"col-lg-4 col-md-4 col-sm-4"} style={{ paddingTop: 7 }}>
            <RiskButton
              getRisk={() => props.fetchRiskScore(walletId)}
              risk={props.risk && props.risk.level}
            />
          </div>
        )}
        <div className="pull-right" style={{ display: "inline", paddingTop: "8px" }}>
          <BtcToUsdChooser
            showAsUsd={showAsUsd}
            toggleUsdDisplay={toggleUsdDisplay}
            historicalToUsd={historicalToUsd}
            toggleHistoricalUsd={toggleHistoricalUsd}
            currencyAbbreviation={currencyObject["abb"]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-4 col-lg-4 infoCol">
          <div className="infoCards">
            <p
              style={{
                color: "var(--secondary-color)",
                fontFamily: "Quicksand",
                fontWeight: "700"
              }}
            >
              {" "}
              Volume{" "}
            </p>
            <StatCard
              stat={`Sent ${showUsdText ? "USD" : currencyObject.abb}`}
              value={chooseBetweenConversions(
                [inputBitcoin, !showAsUsd],
                [
                  "$" + numberWithCommas(convertPriceToUsd(inputBitcoin, currentUsdPrice)),
                  showAsUsd
                ],
                ["$" + numberWithCommas(inputHistUsd), historicalToUsd],
                true,
                sent_hist_usd === -1
              )}
              loading={!showChangeWithDefault || historicalToUsd ? moreLoading : displayLoading}
            />
            <StatCard
              stat={`Recv ${showUsdText ? "USD" : currencyObject.abb}`}
              value={chooseBetweenConversions(
                [outputBitcoin, !showAsUsd],
                [
                  "$" + numberWithCommas(convertPriceToUsd(outputBitcoin, currentUsdPrice)),
                  showAsUsd
                ],
                ["$" + numberWithCommas(outputHistUsd), historicalToUsd],
                true,
                sent_hist_usd === -1
              )}
              loading={!showChangeWithDefault || historicalToUsd ? moreLoading : displayLoading}
            />
            <StatCard
              stat="Balance"
              value={
                showUsdText
                  ? "$" + numberWithCommas(convertPriceToUsd(balanceBitcoin, currentUsdPrice))
                  : balanceBitcoin
              }
              loading={displayLoading}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 infoCol">
          <div className="infoCards">
            <p
              style={{
                color: "var(--secondary-color)",
                fontFamily: "Quicksand",
                fontWeight: "700"
              }}
            >
              {" "}
              Transactions{" "}
            </p>
            <StatCard stat="Sent Trxs" value={inputCountString} loading={displayLoading} />
            <StatCard stat="Recv Trxs" value={outputCountString} loading={displayLoading} />
            <StatCard stat="Total Trxs" value={transactionCountString} loading={displayLoading} />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 infoCol">
          <div className="infoCards">
            <p
              style={{
                color: "var(--secondary-color)",
                fontFamily: "Quicksand",
                fontWeight: "700"
              }}
            >
              {" "}
              Activity{" "}
            </p>
            <StatCard stat="Addresses" value={addressCountString} loading={displayLoading} />
            <StatCard stat="First Trx" value={firstTransactionDate} loading={displayLoading} />
            <StatCard stat="Latest Trx" value={lastTransactionDate} loading={displayLoading} />
          </div>
        </div>
      </div>
    </div>
  );
};

WalletSummary.propTypes = {
  balance: PropTypes.number.isRequired,
  firstTransaction: PropTypes.number.isRequired,
  lastTransaction: PropTypes.number.isRequired,
  inputCount: PropTypes.number.isRequired,
  inputSatoshi: PropTypes.number.isRequired,
  outputCount: PropTypes.number.isRequired,
  outputSatoshi: PropTypes.number.isRequired,
  transactionCount: PropTypes.number.isRequired,
  addressCount: PropTypes.number.isRequired,
  addToGraph: PropTypes.func,
  primaryTag: PropTypes.string,
  walletId: PropTypes.string.isRequired,
  name: PropTypes.string,
  custom: PropTypes.bool,
  onDeleteFunc: PropTypes.func,
  processed: PropTypes.bool,
  currentUsdPrice: PropTypes.number,

  fetch: PropTypes.func.isRequired,
  fetchMore: PropTypes.func.isRequired,
  purge: PropTypes.func
};

WalletSummary.defaultProps = {
  addToGraph: null,
  name: null,
  onDeleteFunc: () => {},
  processed: true,
  custom: false,
  primaryTag: null,
  purge: walletId => {},
  getSharedUsers: (walletId, enqueueSnackbar, currency) => {},
  removeSharedUser: (walletId, email, enqueueSnackbar) => {},
  shareCustomWallet: (walletId, email, enqueueSnackbar, currency) => {},
  fetchRiskScore: walletId => {},
  currentUsdPrice: 0
};

const mapStateToProps = (state, { walletId }) => {
  return {
    currency: getCurrency(state),
    clientMode: getClientMode(state),
    processed: getProcessed(state, walletId),
    user_email: getEmail(state)
  };
};

export default withSnackbar(
  connect(
    mapStateToProps,
    null
  )(WalletSummary)
);
