/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types/index";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ScrollDiv } from "../../styled";
import { makeGetWalletAssociations } from "../../../selectors/wallet";
import { fetchWalletAssociations } from "../../../actions/wallet";

class WalletAssociations extends Component {
  getWalletLink = (walletId, tag) => {
    const { handleOpenWalletModal } = this.props;
    if (handleOpenWalletModal) {
      return (
        <div>
          {tag}{" "}
          <Button bsSize="xsmall" onClick={() => handleOpenWalletModal(walletId)}>
            Open
          </Button>
        </div>
      );
    }

    return <Link to={{ pathname: `/wallet/${walletId}` }}>{tag}</Link>;
  };

  fetchAssociations = async () => {
    const { walletId } = this.props;
    this.props.fetch(walletId);
  };

  render() {
    const { associations, maxHeight, nextKey } = this.props;

    const buttonText = nextKey ? "Load More ..." : "No more   ";

    const associationRows = associations.map(association => {
      const { walletId: associatedWalletId, tag } = association;

      return (
        <tr key={associatedWalletId}>
          <td>{this.getWalletLink(associatedWalletId, tag)}</td>
        </tr>
      );
    });

    return (
      <div>
        <ScrollDiv maxHeight={maxHeight}>
          <Table striped hover>
            <thead>
              <tr>
                <th>Tag</th>
              </tr>
            </thead>
            <tbody>{associationRows}</tbody>
          </Table>
        </ScrollDiv>
        <Button onClick={this.fetchAssociations} disabled={!nextKey}>
          {buttonText}
        </Button>
      </div>
    );
  }
}

WalletAssociations.propTypes = {
  walletId: PropTypes.string.isRequired,
  handleOpenWalletModal: PropTypes.func,
  associations: PropTypes.arrayOf(
    PropTypes.shape({
      walletId: PropTypes.number.isRequired,
      tag: PropTypes.string.isRequired,
    })
  ).isRequired,
  fetch: PropTypes.func.isRequired,
  nextKey: PropTypes.number,
  maxHeight: PropTypes.number.isRequired,
};

WalletAssociations.defaultProps = {
  handleOpenWalletModal: null,
  nextKey: null,
};

const makeMapStateToProps = () => {
  const getWalletAssociations = makeGetWalletAssociations();
  return (state, { walletId }) => getWalletAssociations(state, walletId);
};

const mapDispatchToProps = dispatch => ({
  fetch: walletId => dispatch(fetchWalletAssociations(walletId)),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(WalletAssociations);
