/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";

import { OverlayTrigger } from "react-bootstrap";

import PropTypes from "prop-types";

import StatCard from "../StatCard";
import ReportIssue from "../ReportIssue";
import CurrencyChooserConfig from "../CurrencyChooserConfig";
import { numberWithCommas } from "../../helpers";
import { FullWidthPopover } from "../styled";
import CopyText from "../CopyText";
import { CoinSwap } from "../CoinSwap";
import AddToNote from "../NoteEditor/AddToNote";
import { useDispatch } from "react-redux";
import { createRiskFetch } from "../../actions/actionCreatorFactories";
import { getAddressRisk } from "../../selectors/address";
import { RiskButton } from "../Risk/RiskButton";
import { getTransactionRisk } from "../../selectors/transaction";

const TransactionSummary = ({
  block,
  timestamp,
  inputBitcoin,
  outputBitcoin,
  fee,
  transaction,
  addToGraph,
  currency,
  unclustered_input,
  risk_score,
  convertPriceToUsd,
  toggleUsdDisplay,
  showAsUsd,
  priceAvailable,
  clientMode,
  coinSwap,
  coinSwapData,
  isMempool,
  risk
}) => {
  let currencyObject = CurrencyChooserConfig[currency];
  const dispatch = useDispatch();
  const tooltip = (
    <FullWidthPopover style={{ fontFamily: "Quicksand", fontWeight: "600" }}>
      USD Prices on this Page are Reflective of the {currencyObject["label"]} Price{" "}
      <b>on the Day of the Transaction</b>
    </FullWidthPopover>
  );

  const tooltipNotAvailable = (
    <FullWidthPopover style={{ fontFamily: "Quicksand", fontWeight: "600" }}>
      Historical USD prices not available for this Transaction. The transaction is likely too old.
    </FullWidthPopover>
  );
  return (
    <div
      className="container-fluid"
      style={{
        borderWidth: "0 0 0 2px",
        borderColor: "var(--secondary-color)",
        borderStyle: "solid",
        paddingBottom: "0"
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <h4 className="entityHeader">Transaction</h4>
        <div style={{ marginLeft: "auto" }}>
          <AddToNote page="transaction" itemId={transaction} />
          {addToGraph()}{" "}
          <ReportIssue entityName={transaction} entityType={"transaction"} currency={currency}>
            {" "}
          </ReportIssue>
        </div>
      </div>
      <div className="row" style={{ marginLeft: "0", alignItems: "center" }}>
        <div className="col txnSummaryTxnId" style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              marginRight: "10px"
            }}
          >
            {transaction}
          </p>
          <CopyText text={transaction} fontSize="18px" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <p
            style={{
              color: "var(--secondary-color)",
              fontFamily: "Quicksand",
              fontWeight: "700",
              fontSize: "20px",
              display: "inline"
            }}
          >
            Summary
          </p>
        </div>
        <div className="col-lg-4">
          <RiskButton
            getRisk={async () => {
              return await dispatch(
                createRiskFetch("transaction", getTransactionRisk)(transaction)
              );
            }}
            risk={risk && risk.level}
          />
        </div>
        <div className="pull-right" style={{ display: "inline" }}>
          {showAsUsd ? (
            <>
              <p
                className="usdToggleInactive"
                style={{ marginRight: "10px" }}
                onClick={() => toggleUsdDisplay()}
              >
                {currencyObject["abb"]}
              </p>
              <p className="usdToggle"> | </p>
              <OverlayTrigger overlay={tooltip} placement="left" delayShow={300} delayHide={150}>
                <p className="usdToggle" style={{ marginLeft: "10px", fontFamily: "Quicksand" }}>
                  USD
                </p>
              </OverlayTrigger>{" "}
            </>
          ) : priceAvailable ? (
            <>
              <p className="usdToggle" style={{ marginRight: "10px" }}>
                {currencyObject["abb"]}
              </p>
              <p className="usdToggle"> | </p>

              <OverlayTrigger overlay={tooltip} placement="left" delayShow={300} delayHide={150}>
                <p
                  className="usdToggleInactive"
                  style={{ marginLeft: "10px", fontFamily: "Quicksand" }}
                  onClick={() => toggleUsdDisplay()}
                >
                  USD
                </p>
              </OverlayTrigger>
            </>
          ) : (
            <>
              <p className="usdToggle" style={{ marginRight: "10px" }}>
                {currencyObject["abb"]}
              </p>
              <p className="usdToggle"> | </p>

              <OverlayTrigger
                overlay={tooltipNotAvailable}
                placement="left"
                delayShow={300}
                delayHide={150}
              >
                <p
                  className="usdToggleInactiveDisabled disabled"
                  style={{ marginLeft: "10px", fontFamily: "Quicksand" }}
                >
                  USD
                </p>
              </OverlayTrigger>
            </>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-6 col-lg-6 infoCol">
          <div className="infoCards">
            <p
              style={{
                color: "var(--secondary-color)",
                fontFamily: "Quicksand",
                fontWeight: "700"
              }}
            >
              {" "}
              Info{" "}
            </p>
            {isMempool ? (
              <StatCard stat={"Unconfirmed Transaction"} />
            ) : (
              <StatCard stat="Block" value={block} />
            )}
            {!isMempool && <StatCard stat="Timestamp" value={timestamp} />}
            {/*{clientMode !== "vci" && (*/}
            {/*  <StatCard stat="Risk Score" value={risk_score.toFixed(4)} />*/}
            {/*)} TODO TEMP DISABLE*/}
          </div>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 infoCol">
          <div className="infoCards">
            <p
              style={{
                color: "var(--secondary-color)",
                fontFamily: "Quicksand",
                fontWeight: "700"
              }}
            >
              {" "}
              Volume{" "}
            </p>
            <StatCard
              stat={`Input ${showAsUsd ? "USD" : currencyObject.abb}`}
              value={
                showAsUsd ? "$" + numberWithCommas(convertPriceToUsd(inputBitcoin)) : inputBitcoin
              }
            />
            <StatCard
              stat={`Output ${showAsUsd ? "USD" : currencyObject.abb}`}
              value={
                showAsUsd ? "$" + numberWithCommas(convertPriceToUsd(outputBitcoin)) : outputBitcoin
              }
            />
            <StatCard
              stat={`Fee (${showAsUsd ? "USD" : currencyObject.abb})`}
              value={showAsUsd ? "$" + numberWithCommas(convertPriceToUsd(fee)) : fee}
            />
          </div>
        </div>
      </div>
      <div className="row">
        {coinSwap && (
          <div className="col-sm-12 col-md-12 col-lg-12 infoCol">
            <CoinSwap coinSwapData={coinSwapData} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionSummary;

TransactionSummary.propTypes = {
  block: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  inputBitcoin: PropTypes.string.isRequired,
  outputBitcoin: PropTypes.string.isRequired,
  fee: PropTypes.string.isRequired,
  transaction: PropTypes.string.isRequired,
  addToGraph: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  unclustered_input: PropTypes.bool.isRequired,
  risk_score: PropTypes.number.isRequired,
  clientMode: PropTypes.string.isRequired,
  coinSwap: PropTypes.bool.isRequired,
  coinSwapData: PropTypes.object.isRequired
};
