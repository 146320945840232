// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { Record } from "immutable";
import {
  DISMISS_LOGIN_FAILURE_NOTIFICATION,
  LOGGING_IN,
  LOGGING_OUT,
  LOGGING_OUT_AUTOMATICALLY,
  LOGIN_CSRF_ERROR,
  LOGIN_DELETED,
  LOGIN_DISABLED,
  LOGIN_FAILURE,
  LOGIN_OVERLOAD,
  LOGIN_SUCCESS,
  LOGIN_TRIAL_ENDED,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  TWO_FACTOR_AUTHENTICATION_FAILURE,
  TWO_FACTOR_AUTHENTICATION_SUCCESS,
  TWO_FACTOR_PHONE_SET_SUCCESSFUL,
  TWO_FACTOR_REGISTRATION_SUCCESS
} from "../actions/actionNames";

/*
Authentication State
AuthenticationInfo: {
  loggedIn: bool,
  processing: bool,
  role: string,
  error: null | 'autoLogout' | 'loginFailure' | 'loginDisabled' | 'csrf' | 'loginDeleted',
}
 */

export const AuthenticationInfo = Record({
  loggedIn: false,
  twoConfigured: false,
  twoSuccess: false,
  processing: false,
  role: "",
  error: "", // Displayed at login page
  ctAccess: false,
  registrationTimestamp: null,
  phone: null
});

const authentication = (state = new AuthenticationInfo(), action) => {
  switch (action.type) {
    case LOGGING_IN:
      return state.set("processing", true).set("error", null);
    case LOGGING_OUT:
      return state.set("processing", true).set("error", null);
    case LOGIN_SUCCESS:
      localStorage.setItem("loggedIn", "true");
      localStorage.setItem("twoConfigured", action.twoConfigured);
      return state
        .set("loggedIn", true)
        .set("processing", false)
        .set("error", null)
        .set("role", action.role)
        .set("ctAccess", action.ctAccess)
        .set("twoConfigured", action.twoConfigured)
        .set("phone", action.phone)
        .set("registrationTimestamp", action.registrationTimestamp)
        .set("email", action.email);
    case TWO_FACTOR_REGISTRATION_SUCCESS:
      localStorage.setItem("twoConfigured", "true");
      return state.set("twoConfigured", true);
    case TWO_FACTOR_AUTHENTICATION_SUCCESS:
      localStorage.setItem("twoSuccess", "true");
      return state.set("twoSuccess", true);
    case TWO_FACTOR_AUTHENTICATION_FAILURE:
      localStorage.setItem("twoSuccess", "false");
      return state.set("twoSuccess", false);
    case TWO_FACTOR_PHONE_SET_SUCCESSFUL:
      return state.set("phone", action.phone);
    case LOGIN_DISABLED:
      return state.set("processing", false).set("error", "loginDisabled");
    case LOGIN_OVERLOAD:
      return state.set("processing", false).set("error", "loginOverload");
    case LOGIN_TRIAL_ENDED:
      return state.set("processing", false).set("error", "trialEnded");
    case LOGIN_DELETED:
      return state.set("processing", false).set("error", "loginDeleted");
    case LOGOUT_SUCCESS:
      localStorage.setItem("loggedIn", "false");
      localStorage.setItem("twoConfigured", "false");
      localStorage.setItem("twoSuccess", "false");
      return state
        .set("loggedIn", false)
        .set("processing", false)
        .set("role", null)
        .set("twoConfigured", false)
        .set("twoSuccess", false)
        .set("phone", null)
        .set("registrationTimestamp", null)
        .set("email", null);
    case LOGIN_FAILURE:
    case LOGOUT_FAILURE:
      return state.set("processing", false).set("error", "loginFailure");
    case LOGIN_CSRF_ERROR:
      return state.set("error", "csrf");
    case DISMISS_LOGIN_FAILURE_NOTIFICATION:
      return state.set("error", null);
    case LOGGING_OUT_AUTOMATICALLY:
      if (state.get("loggedIn") === true) {
        localStorage.setItem("loggedIn", "false");
        localStorage.setItem("twoConfigured", "false");
        localStorage.setItem("twoSuccess", "false");
        return state
          .set("error", "autoLogout")
          .set("loggedIn", false)
          .set("role", null)
          .set("twoConfigured", false)
          .set("phone", null)
          .set("twoSuccess", false)
          .set("registrationTimestamp", null);
      }
      return state;
    default:
      return state;
  }
};

export default authentication;
