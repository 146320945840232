/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";

import { ScrollDiv } from "../../styled";
import { cytoscapeNodeShape } from "../../../prop-types";
import { fetchWalletMentions } from "../../../actions/wallet";
import { makeGetWalletMentions } from "../../../selectors/wallet";
import Mention from "../../Wallet/Mention";
import "../../../../css/styles.css";
import { CircularProgress } from "@material-ui/core";
import { getWalletPostsConverted } from "../../../selectors/mention";
import { fetchMentionWalletPosts } from "../../../actions/mention";
import { WALLET_API } from "../../../api";

class WalletMentions extends Component {
  fetchWalletTags = async () => {
    const { walletId, apiBase } = this.props;
    console.log(walletId);
    this.props.fetch(walletId);
  };

  render() {
    const { maxHeight, walletId, nextKey } = this.props;
    const buttonText = !(nextKey == null) ? "Load More ..." : "No more   ";
    const col1 = [];
    const col2 = [];
    this.props.mentions.forEach((mention, index) => {
      const { username, onlineAccountId, sourceName, count } = mention;
      if (index % 2 === 0) {
        col1.push(
          <Mention
            key={onlineAccountId}
            onlineAccountId={onlineAccountId}
            username={username}
            sourceName={sourceName}
            id={walletId}
            count={count}
            getPostConverted={getWalletPostsConverted}
            fetchMentionPosts={fetchMentionWalletPosts}
          />
        );
      } else {
        col2.push(
          <Mention
            key={onlineAccountId}
            onlineAccountId={onlineAccountId}
            username={username}
            sourceName={sourceName}
            id={walletId}
            count={count}
            getPostConverted={getWalletPostsConverted}
            fetchMentionPosts={fetchMentionWalletPosts}
          />
        );
      }
    });

    return (
      <>
        {nextKey === 0 ? (
          <div style={{ margin: 0, textAlign: "center" }}>
            <CircularProgress size={80} style={{ color: "white" }} />
          </div>
        ) : (
          <div
            className="container"
            style={{
              width: "100%",
              padding: "0",
              marginLeft: "0",
              textAlign: "center"
            }}
          >
            <ScrollDiv
              maxHeight={maxHeight}
              style={{ margin: "0", marginBottom: "15px" }}
            >
              <div className="row" style={{ padding: "0", margin: "0" }}>
                <div
                  className="col-sm-6 col-md-6 col-lg-6"
                  style={{ paddingLeft: "60px", paddingRight: "30px" }}
                >
                  {col1}
                </div>
                <div
                  className="col-sm-6 col-md-6 col-lg-6"
                  style={{ paddingRight: "60px", paddingLeft: "30px" }}
                >
                  {col2}
                </div>
                {/*{mentionRows}*/}
              </div>
            </ScrollDiv>
            <Button
              className="purpleButton"
              onClick={this.fetchWalletTags}
              disabled={!nextKey}
            >
              {buttonText}
            </Button>
          </div>
        )}
      </>
    );
  }
}

WalletMentions.propTypes = {
  fetch: PropTypes.func.isRequired,
  nextKey: PropTypes.number,
  wallet: cytoscapeNodeShape.isRequired,
  maxHeight: PropTypes.number.isRequired,
  mentions: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      onlineAccountId: PropTypes.number.isRequired,
      sourceName: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired
    })
  ).isRequired,
  apiBase: PropTypes.func.isRequired
};

WalletMentions.defaultProps = {
  mentions: [],
  nextKey: null,
  apiBase: WALLET_API
};

const makeMapStateToProps = () => {
  const getWalletMentions = makeGetWalletMentions();
  return (state, { walletId }) => getWalletMentions(state, walletId, false);
};

const mapDispatchToProps = (dispatch, { graphId }) => ({
  fetch: walletId =>
    dispatch(fetchWalletMentions(walletId, false, graphId || 0))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(WalletMentions);
