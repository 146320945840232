// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { connect } from "react-redux";

import { getWalletMentions } from "../../../selectors/wallet";
import { fetchWalletMentions } from "../../../actions/wallet";
import WalletMentionsBase from "../WalletMentionsBase";
import { WALLET_API } from "../../../api";

const makeMapStateToProps = () => {
  return (state, { walletId }) => getWalletMentions(state, walletId);
};

const mapDispatchToProps = dispatch => ({
  fetch: walletId => dispatch(fetchWalletMentions(WALLET_API, walletId, false)),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(WalletMentionsBase);
