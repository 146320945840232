/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import { ScrollDiv } from "../styled";
import "../../../css/styles.css";
import { getWalletPeelChainData } from "../../selectors/wallet";
import {
  addPeelChainToGraph,
  addWalletToGraph,
} from "../../actions/graph";
import { faCheck, faNetworkWired } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import { getClientMode } from "../../selectors/applicationConfig";
import { getCurrency } from "../../selectors/currency";
import { CircularProgress } from "@material-ui/core";
import { getWalletSetMemo } from "../../selectors/graph";

class WalletPeelChain extends Component {
  render() {
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null,
    };

    const { peel, peel_chain_data, graphId, walletId, cy, wallets } = this.props;

    let ids_with_rows = [];
    const walletRows =
      peel_chain_data === null
        ? null
        : peel_chain_data.map(entry => {
            if (ids_with_rows.includes(entry.wallet_id)) {
              return;
            } else {
              ids_with_rows.push(entry.wallet_id);
            }
            const rows = (
              <>
                <tr>
                  <td> {entry.tag || entry.wallet_id} </td>
                  <td>
                    {wallets.has(entry.wallet_id.toString()) ? (
                      <Button
                        style={{ float: "right" }}
                        className="greenButtonGraph"
                        bsSize="xsmall"
                      >
                        <FontAwesomeIcon icon={faCheck} /> Added
                      </Button>
                    ) : (
                      <Button
                        style={{ float: "right" }}
                        className="purpleButton"
                        bsSize="xsmall"
                        onClick={() =>
                          this.props.addWalletToGraph(
                            entry.wallet_id,
                            graphId,
                            cy
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faCheck} /> Add to Graph
                      </Button>
                    )}
                  </td>
                </tr>
                {entry.peel_wallet_id !== null && !ids_with_rows.includes(entry.peel_wallet_id) && (
                  <tr>
                    <td> {entry.peel_tag || entry.peel_wallet_id} </td>
                    <td>
                      {wallets.has(entry.peel_wallet_id.toString()) ? (
                        <Button
                          style={{ float: "right" }}
                          className="greenButtonGraph"
                          bsSize="xsmall"
                        >
                          <FontAwesomeIcon icon={faCheck} /> Added
                        </Button>
                      ) : (
                        <Button
                          style={{ float: "right" }}
                          className="purpleButton"
                          bsSize="xsmall"
                          onClick={() =>
                            this.props.addWalletToGraph(
                              entry.peel_wallet_id,
                              entry.peel_tag || entry.peel_wallet_id,
                              entry.peel_category,
                              graphId,
                              cy
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faCheck} /> Add to Graph
                        </Button>
                      )}
                    </td>
                  </tr>
                )}
              </>
            );

            if (!ids_with_rows.includes(entry.peel_wallet_id) && entry.peel_wallet_id !== null) {
              ids_with_rows.push(entry.peel_wallet_id);
            }

            return rows;
          });

    let chain_all_in_graph = true;
    ids_with_rows.forEach(x => {
      if (!wallets.has(x.toString())) {
        chain_all_in_graph = false;
      }
    });

    return (
      <div style={{ textAlign: "center", margin: "0" }}>
        {peel_chain_data === null ? (
          <CircularProgress size={80} style={{ color: "white", marginTop: "50px" }} />
        ) : peel ? (
          <>
            {!chain_all_in_graph && (
              <div style={{ display: "flex" }}>
                <Button
                  className="allPurpleButton"
                  bsSize="xsmall"
                  onClick={() => this.props.addPeelChainToGraph(walletId, cy, graphId)}
                  style={{
                    display: "inline",
                    marginRight: "5px",
                    float: "left",
                    marginTop: "10px",
                    marginBottom: "5px",
                  }}
                >
                  <FontAwesomeIcon icon={faNetworkWired} style={{ marginRight: "3px" }} />
                  Add Peel Chain
                </Button>
              </div>
            )}
            <ScrollDiv
              maxHeight="600px"
              style={{ margin: "0", borderRadius: "0", paddingBottom: "0" }}
            >
              <Table className="walletStatsTable purple">
                <thead>
                  <tr>
                    <th>Entity</th>
                    <th className="text-left"></th>
                  </tr>
                </thead>
                <tbody>{walletRows}</tbody>
              </Table>
            </ScrollDiv>
          </>
        ) : (
          <p> This wallet is not in a peel chain.</p>
        )}
      </div>
    );
  }
}

WalletPeelChain.propTypes = {
  // We need to add outputWalletId and then find it from cytoscape.
  cy: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  graphId: PropTypes.number.isRequired,
  walletId: PropTypes.number.isRequired,
};

// need to get next keys as well.
const mapStateToProps = (state, { walletId, graphId }) => {
  return {
    ...getWalletPeelChainData(state, walletId),
    clientMode: getClientMode(state),
    currency: getCurrency(state),
    wallets: getWalletSetMemo(state, graphId),
  };
};

const mapDispatchToProps = dispatch => ({
  addWalletToGraph: (walletId, graphId, cy) =>
    dispatch(addWalletToGraph(walletId,null, null, graphId, cy, null, true)),
  addPeelChainToGraph: (wallet_id, cy, graphId) =>
    dispatch(addPeelChainToGraph(wallet_id, cy, graphId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletPeelChain);
