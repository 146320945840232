// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { convertFromRaw, convertToRaw, Editor, EditorState, RichUtils } from "draft-js";
import tippy, { sticky } from "tippy.js";
import moment from "moment";
import { isImmutable } from "immutable";
import axios, { GRAPH_API, NOTES_API, SEARCH_API, TRANSACTION_API } from "../api";
import { addEdgeToGraph, getAndAddEdges, setUpCytoscape, updateEdges } from "./graphHelpers";

import {
  FETCHING_GRAPH,
  FETCHING_GRAPH_SEARCH_RESULTS,
  GRAPH_ADD_WALLET_TO_GRAPH,
  GRAPH_CHANGE_CASE_NUMBER,
  GRAPH_CHANGE_DATA_TABS_KEY,
  GRAPH_CHANGE_DATES,
  GRAPH_CHANGE_DESCRIPTION,
  GRAPH_CHANGE_MAIN_TABS_KEY,
  GRAPH_CHANGE_TOGGLE_STATE,
  GRAPH_CLOSE_EXPLORER_MODAL,
  GRAPH_DELETE_SUCCESS,
  GRAPH_DELETE_WALLET_FROM_GRAPH,
  GRAPH_EXPLORER_MODAL_GO_BACK,
  GRAPH_EXPLORER_MODAL_GO_FORWARD,
  GRAPH_FETCH_FAILURE,
  GRAPH_FETCH_SUCCESS,
  GRAPH_HANDLE_DELETE,
  GRAPH_IMPORT_GRAPH,
  GRAPH_MUTUAL_TRANSACTION_CHANGE_ORDER,
  GRAPH_OPEN_EXPLORER_MODAL,
  GRAPH_PEEL_CHAIN_STATUS_SUCCESS,
  GRAPH_SAVE_SUCCESS,
  GRAPH_SEARCH_ASSOCIATIONS_SUCCESS,
  GRAPH_SEARCH_FAILURE,
  GRAPH_SEARCH_SUCCESS,
  GRAPH_SELECT_SINGLE_NODE,
  GRAPH_SET_OUTPUT_WALLET_ID,
  GRAPH_SHOW_NOTES_SET,
  GRAPH_UNDO_STACK_ADD,
  GRAPH_UNDO_STACK_GO_BACK,
  GRAPH_UNDO_STACK_GO_FORWARD,
  GRAPH_UPDATE_EDITOR_STATE,
  GRAPH_UPDATE_GRAPH_IN_REDUX,
  SETTING_CURRENT_GRAPH,
  WALLET_SET_NAMES
} from "./actionNames";
import {
  getCurrentGraph,
  getGraph,
  getGraphJson,
  getGraphSaveInfo,
  getGraphSelectedDates,
  getGraphWallets
} from "../selectors/graph";
import { getAddress } from "../selectors/address";
import {
  getWallet,
  getWalletName,
  getWalletNameHelper,
  getWalletPeelChainData
} from "../selectors/wallet";
import history from "../components/history";
import { satoshiToBitcoin3 } from "../helpers";
import { addNotification } from "./notification";
import { fetchWalletSummary, setOriginalWalletTagInner, setWalletTagInner } from "./wallet";
import { fetchAddressSummary } from "./address";
import CategoryColorsConfig from "../components/Graph/CategoryColorsConfig";
// Used for mocking within a module
import { getCurrency } from "../selectors/currency";
import { fetchTransaction } from "./transaction";
import { getEmail } from "../selectors/authentication";

export const goBack = () => (dispatch, getState) => {
  dispatch({
    type: GRAPH_EXPLORER_MODAL_GO_BACK,
    name: getCurrency(getState())
  });
};

export const goForward = () => (dispatch, getState) => {
  dispatch({
    type: GRAPH_EXPLORER_MODAL_GO_FORWARD,
    name: getCurrency(getState())
  });
};

// Opening explorer is wrapped by address/wallet/transaction variants
export const openExplorerModal = ({ entityType, entity }) => (dispatch, getState) => {
  dispatch({
    type: GRAPH_OPEN_EXPLORER_MODAL,
    entityData: { entityType, entity },
    name: getCurrency(getState())
  });
};

export const openAddressModal = address =>
  openExplorerModal({
    entityType: "address",
    entity: address
  });

export const openWalletModal = wallet =>
  openExplorerModal({
    entityType: "wallet",
    entity: wallet
  });

export const openTransactionModal = transaction =>
  openExplorerModal({
    entityType: "transaction",
    entity: transaction
  });

// This variation is just used to open up the explorer, nothing is added to the history.
export const openModal = () =>
  openExplorerModal({
    entityType: null,
    entity: null
  });

// When a node is clicked on, all selected nodes and the one clicked on have their
// current position saved within Graph.jsx's state. This map is passed in walletIdToPosition.
// After the user moves the nodes, the nodes current position is saved along with the
// previous position in walletIdToPosition. The mapping might be stale if React decides
// to not update state immediately. (Not sure if this is an issue in practice or how
// we would deal with it.)
//
// shouldRefit is used when performing layout operations. Although you can grab the extent
// before the layout operation is done, cytoscape don't seen to provide a method to actually set
// it. It's probably just bad documentation. For now, cy.fit() is used so that undo gives
// a reasonable effect. (Items would potentially be out of range since the extent changes when
// layout is called.)
export const addNodeMovementToUndoStack = (
  cy,
  walletIds,
  walletIdToPosition,
  shouldRefit = false
) => async (dispatch, getState) => {
  const wallets = walletIds.map(walletId => {
    const wallet = cy.elements(`#${walletId}`);
    return {
      walletId: wallet.data("id"),
      x: wallet.position("x"),
      prevX: walletIdToPosition.get(walletId).x,
      y: wallet.position("y"),
      prevY: walletIdToPosition.get(walletId).y
    };
  });

  const name = getCurrency(getState());
  dispatch({
    type: GRAPH_UNDO_STACK_ADD,
    opType: "move",
    wallets,
    shouldRefit,
    name
  });

  // Save the graph
  const graphId = getCurrentGraph(getState());
  await dispatch(saveGraph(graphId, cy));
};

export const makeNodeNote = (ele, showAfter = false) => {
  if (ele.note) {
    // destroy the tippy and remake it (dynamic content not really supported in this version, this is easier)
    ele.note.destroy();
  }

  const title = ele.data("id").includes("+")
    ? `${ele
        .cy()
        .nodes(node => node.data("id") === ele.data("source"))
        .data("label")} to ${ele
        .cy()
        .nodes(node => node.data("id") === ele.data("target"))
        .data("label")}`
    : ele.data("label");

  // reset the tippy
  let ref = ele.popperRef();
  const blocks = ele.data("notes")["blocks"];
  const noteValue = blocks.map(block => (!block.text.trim() && "\n") || block.text).join("\n");
  ele.note = new tippy(document.createElement("div"), {
    content: () => {
      return `
        <div class="nodeNote" style="vertical-align: top">
          <div style="border-bottom: 1px solid #67cdef; padding-bottom: 5px; margin-bottom: 5px; text-align: left;">
              <span class="edgeTippyHeader">${title} Notes</span>
          </div> 
           <p style="white-space: pre-wrap; word-break: break-word;"> ${noteValue} </p> 
        </div>
        `;
    },
    getReferenceClientRect: ref.getBoundingClientRect, // https://atomiks.github.io/tippyjs/v6/all-props/#getreferenceclientrect
    theme: "light",
    trigger: "manual",
    // interactive: true,
    arrow: true,
    allowHTML: true,
    hideOnClick: false,
    sticky: true,
    plugins: [sticky]
  });
  if (showAfter) {
    ele.note.show();
  }
};

export const remakeNotes = (cy, showNotes) => {
  cy.elements().forEach(node => {
    // Only need to make note tippys for node that have notes.
    if (node.data("notes")) {
      // We remake the tippy each time it is shown in case the note has changed
      makeNodeNote(node, showNotes);
    } else if (node.note && (node.data("notes") === "" || node.data("notes") === null)) {
      // If an existing note has been cleared from a node we want to delete it.
      node.note.destroy();
    }

    // if (node.note) {
    //   showNotes ? node.note.show() : node.note.hide();
    // }
  });
};

export const addColorChangeToUndoStack = (item, style, prevColor, color, cy) => async (
  dispatch,
  getState
) => {
  item.data({ manuallySetColor: color });
  item.style({ [style]: color });
  const wallets = [
    {
      walletId: item.id(),
      colorStyle: style,
      prevColor,
      color
    }
  ];
  dispatch({
    type: GRAPH_UNDO_STACK_ADD,
    opType: "changeColor",
    wallets,
    name: getCurrency(getState())
  });

  const graphId = getCurrentGraph(getState());
  await dispatch(saveGraph(graphId, cy));
};

export const addNodeNoteChangeToUndoStack = (wallet, notes, cy) => async (dispatch, getState) => {
  const prevNotes = wallet.data("notes");
  wallet.data({ notes });
  if (wallet.note) {
    wallet.note.destroy();
  }

  const wallets = [
    {
      walletId: wallet.id(),
      prevNotes,
      notes
    }
  ];

  dispatch({
    type: GRAPH_UNDO_STACK_ADD,
    opType: "setNotes",
    wallets,
    name: getCurrency(getState())
  });

  const graphId = getCurrentGraph(getState());
  if (wallet.group() === "nodes") {
    await dispatch(saveGraph(graphId, cy, null, wallet.id(), null));
  } else if (wallet.group() === "edges") {
    await dispatch(saveGraph(graphId, cy, null, null, wallet.id()));
  }
};

// Grabs the current change pointed at and applies the reverse the the cytoscape object.
export const undoGraphChange = (graphId, cy) => async (dispatch, getState) => {
  const currency = getCurrency(getState());
  const undoStackRecord = getState().getIn([currency, "graph", "view", "modals", "graphUndoStack"]);
  const showNotes = getState().getIn([currency, "graph", "view", "showNotes"]);
  const index = undoStackRecord.get("index");
  if (index === -1) {
    return;
  }
  const item = undoStackRecord.getIn(["stack", index]);
  // operations are **reversed** here!
  // wallets that were 'add'ed should be removed, while wallets that were 'delete'd
  // should be added back.
  const opType = item.get("opType");
  const wallets = item.get("wallets").toJS();

  const name = getCurrency(getState());
  if (opType === "add") {
    wallets.forEach(wallet => {
      // using getElementById because elements(:selector) would always truncate the id after the + on edges
      const element = cy.getElementById(`${wallet.walletId}`);
      if (element.note) {
        element.note.hide();
      }
      element.remove();
      dispatch({
        type: GRAPH_DELETE_WALLET_FROM_GRAPH,
        graphId: graphId.toString(),
        walletId: wallet.walletId,
        name
      });
    });
  } else if (opType === "delete") {
    // Need to add all wallets and then all edges. Was getting exception before where it was trying to add an edge
    // before one of its nodes was added
    const nodes = [];
    const trxNodes = [];
    const edges = [];
    wallets.forEach(wallet =>
      wallet.walletId.includes("+")
        ? edges.push(wallet)
        : wallet.type === "default"
        ? nodes.push(wallet)
        : trxNodes.push(wallet)
    );
    await Promise.all(
      nodes.map(wallet => {
        const { walletId, x, y, manuallySetColor, notes, address } = wallet;
        return addWalletToGraph(walletId, x, y, graphId, cy, manuallySetColor, false, notes)(
          dispatch,
          getState
        );
      })
    );

    // Add the transaction nodes if any exist
    await trxNodes.map(async trxNode => {
      await addUnclusteredTransactionNodeToGraph(
        trxNode.walletId,
        trxNode.label,
        trxNode.x,
        trxNode.y,
        graphId,
        cy,
        trxNode.manuallySetColor,
        trxNode.notes
      )(dispatch, getState);
    });

    // Add the edges to the graph
    edges.map(wallet => {
      const ids = wallet.walletId.split("+");
      const { satoshi, bitcoin, manuallySetColor, notes, type, attributed, hist_usd } = wallet;
      addEdgeToGraph(
        ids[0],
        ids[1],
        satoshi,
        bitcoin,
        cy,
        notes,
        manuallySetColor,
        type,
        attributed,
        hist_usd
      );
    });
  } else if (opType === "move") {
    wallets.forEach(wallet => {
      cy.elements(`#${wallet.walletId}`).position({
        x: wallet.prevX,
        y: wallet.prevY
      });
    });

    if (item.get("shouldRefit")) {
      cy.fit();
    }
  } else if (opType === "setTag") {
    wallets.forEach(wallet => {
      const { oldTag } = wallet.tagOperation;
      // Undo Operation is the same if the tag was deleted/changed.
      setWalletTagInner(cy.elements(`#${wallet.walletId}`), oldTag)(dispatch);
    });
  } else if (opType === "changeColor") {
    wallets.forEach(wallet => {
      const node = cy.getElementById(wallet.walletId);
      const { prevColor } = wallet;
      node.data({ manuallySetColor: prevColor });
      node.style({ [wallet.colorStyle]: prevColor });
    });
  } else if (opType === "setNotes") {
    wallets.forEach(wallet => {
      const node = cy.getElementById(wallet.walletId);
      const { prevNotes } = wallet;
      node.data({ notes: prevNotes });
    });
    remakeNotes(cy, showNotes);
  } else {
    throw new Error(`Unknown opType: ${opType}`);
  }

  dispatch({
    type: GRAPH_UNDO_STACK_GO_BACK,
    name
  });

  const id = getCurrentGraph(getState());
  await dispatch(saveGraph(id, cy));
};

// Grabs the next change pointed to in the undo stack (cursor doesn't need to point
// to last item) and applies it to the cytoscape object.
export const redoGraphChange = (graphId, cy) => async (dispatch, getState) => {
  const currency = getCurrency(getState());
  const undoStackRecord = getState().getIn([currency, "graph", "view", "modals", "graphUndoStack"]);
  const showNotes = getState().getIn([currency, "graph", "view", "showNotes"]);
  const index = undoStackRecord.get("index");
  if (index === undoStackRecord.get("stack").size - 1) {
    return;
  }

  const item = undoStackRecord.getIn(["stack", index + 1]);
  const opType = item.get("opType");
  const wallets = item.get("wallets").toJS();

  const name = getCurrency(getState());
  if (opType === "add") {
    // Need to add all wallets and then all edges. Was getting exception before where it was trying to add an edge
    // before one of its nodes was added
    const nodes = [];
    const trxNodes = [];
    const edges = [];
    wallets.forEach(wallet =>
      wallet.walletId.includes("+")
        ? edges.push(wallet)
        : wallet.type === "default"
        ? nodes.push(wallet)
        : trxNodes.push(wallet)
    );
    await Promise.all(
      nodes.map(wallet => {
        const { walletId, x, y, manuallySetColor, notes } = wallet;
        return addWalletToGraph(walletId, x, y, graphId, cy, manuallySetColor, false, notes)(
          dispatch,
          getState
        );
      })
    );

    // Add the transaction nodes if any exist
    await trxNodes.map(async trxNode => {
      await addUnclusteredTransactionNodeToGraph(
        trxNode.walletId,
        trxNode.label,
        trxNode.x,
        trxNode.y,
        graphId,
        cy,
        trxNode.manuallySetColor,
        trxNode.notes
      )(dispatch, getState);
    });

    // Add the edges if any exist
    edges.map(wallet => {
      const ids = wallet.walletId.split("+");
      const { satoshi, bitcoin, manuallySetColor, notes, type, attributed, hist_usd } = wallet;
      addEdgeToGraph(
        ids[0],
        ids[1],
        satoshi,
        bitcoin,
        cy,
        notes,
        manuallySetColor,
        type,
        attributed,
        hist_usd
      );
    });
  } else if (opType === "delete") {
    wallets.forEach(wallet => {
      // using getElementById because elements(:selector) would always truncate the id after the + on edges
      const element = cy.getElementById(`${wallet.walletId}`);
      if (element.note) {
        element.note.hide();
      }
      element.remove();
      dispatch({
        type: GRAPH_DELETE_WALLET_FROM_GRAPH,
        graphId: graphId.toString(),
        walletId: wallet.walletId,
        name
      });
    });
  } else if (opType === "move") {
    wallets.forEach(wallet => {
      cy.elements(`#${wallet.walletId}`).position({
        x: wallet.x,
        y: wallet.y
      });
    });

    if (item.get("shouldRefit")) {
      cy.fit();
    }
  } else if (opType === "setTag") {
    wallets.forEach(wallet => {
      const { opType: tagOpType, newTag } = wallet.tagOperation;
      if (tagOpType === "change") {
        setWalletTagInner(cy.elements(`#${wallet.walletId}`), newTag)(dispatch);
      } else if (tagOpType === "delete") {
        // This redeletes the tag.
        setOriginalWalletTagInner(cy.elements(`#${wallet.walletId}`), newTag)(dispatch);
      }
    });
  } else if (opType === "changeColor") {
    wallets.forEach(wallet => {
      const node = cy.getElementById(wallet.walletId);
      const { color } = wallet;
      node.data({ manuallySetColor: color });
      node.style({ [wallet.colorStyle]: color });
    });
  } else if (opType === "setNotes") {
    wallets.forEach(wallet => {
      const node = cy.getElementById(wallet.walletId);
      const { notes } = wallet;
      node.data({ notes });
      remakeNotes(cy, showNotes);
    });
  } else {
    throw new Error(`Unknown opType: ${opType}`);
  }

  dispatch({
    type: GRAPH_UNDO_STACK_GO_FORWARD,
    name
  });

  const id = getCurrentGraph(getState());
  await dispatch(saveGraph(id, cy));
};

export const getGraphObject = (cy, state, graphId, last_transaction_id = 0) => {
  // FIXME: Notify when saving graph that does not belong to you
  const graph = cy.json(); // cy is uncontrolled so must be passed
  delete graph.style; // we don't want to keep old styles

  const dates = getGraphSelectedDates(state, graphId); // getting date range if exists
  // Styles for individual nodes/edges are not included in the graph's
  // JSON representation for some reason. Anything that needs to
  // be kept will need to be manually extracted from the nodes
  // style
  // const styles = {};
  // cy.nodes("*").forEach(node => {
  //   styles[node.id()] = {
  //     // Only grab values we need, as you can't actually apply
  //     // every style in the object
  //     "background-color": node.style()["background-color"],
  //   };
  // });

  // Commented this out because we don't care about retrieving previous edge color since this isn't customizable
  /* cy.edges("*").forEach(edge => {
        styles[edge.id()] = {
          "line-color": edge.style()["line-color"],
        };
      }); */

  return {
    graph,
    version: 12,
    last_transaction_id,
    ...(dates["startDate"] && { startDate: dates["startDate"] }),
    ...(dates["endDate"] && { endDate: dates["endDate"] })
  };
};

export const saveGraph = (
  graphId,
  cy,
  graphInfo = null,
  graphNodeIdSave = null,
  graphEdgeIdSave = null
) => async (dispatch, getState) => {
  // If the graph does not exist in redux yet, we can't be certain it was actually fetched, so we do not want to save it
  // COULD THIS BE WHERE MAYBE THE GRAPHID DOESN'T LINE UP AND IT SAVES ONE OVER THE OTHER?
  const graphExistsInRedux = getGraph(getState(), graphId);
  if (graphExistsInRedux !== undefined) {
    const graphState = JSON.stringify(
      getGraphObject(
        cy,
        getState(),
        graphId,
        graphExistsInRedux.getIn(["graph", "last_transaction_id"])
      )
    );
    let description;
    let caseNumber;
    let attributedEdgeToggleState;
    let editorState;
    let info;
    if (!graphInfo) {
      info = getGraphSaveInfo(getState(), graphId.toString());

      description = info.description;
      caseNumber = info.caseNumber;
      editorState = info.editorState;
      attributedEdgeToggleState = info.attributedEdgeToggleState;
    } else {
      description = graphInfo.description;
      caseNumber = graphInfo.caseNumber;
      editorState = graphInfo.editorState;
      attributedEdgeToggleState = graphInfo.attributedEdgeToggleState;
    }
    const name = getCurrency(getState());
    const editorContent = editorState
      ? JSON.stringify(convertToRaw(editorState.getCurrentContent()))
      : null;

    try {
      await axios.put(`${GRAPH_API(name)}/${graphId}`, {
        graphId,
        graph: graphState,
        editorContent,
        description,
        caseNumber,
        attributedEdgeToggleState
      });
      if (graphNodeIdSave) {
        let graphData = JSON.parse(graphState).graph.elements.nodes;
        graphData.map(async g => {
          if (g.data.id === graphNodeIdSave) {
            const nodeNotes = g.data.notes;
            if (nodeNotes === "") {
              if (g.data.type === "default") {
                await axios.post(`${NOTES_API(name)}/wallet/${graphNodeIdSave}`, {
                  mainLocation: "graph",
                  noteId: graphId
                });
              } else if (g.data.type === "transaction") {
                await axios.post(`${NOTES_API(name)}/transaction/${graphNodeIdSave}`, {
                  noteId: graphId,
                  mainLocation: "graph"
                });
              }
            } else {
              const walletNote = nodeNotes;
              const walletLocation = "graph";
              const walletLocationId = graphId;
              try {
                if (g.data.type === "default") {
                  await axios.put(`${NOTES_API(name)}/wallet/${graphNodeIdSave}`, {
                    walletLocation,
                    walletLocationId,
                    walletNote
                  });
                } else if (g.data.type === "transaction") {
                  await axios.put(`${NOTES_API(name)}/transaction/${graphNodeIdSave}`, {
                    transactionLocation: walletLocation,
                    transactionLocationId: walletLocationId,
                    transactionNote: walletNote
                  });
                }
              } catch (err) {
                console.log(err);
              }
            }
          }
        });
      } else if (graphEdgeIdSave) {
        let graphData = JSON.parse(graphState).graph.elements.edges;
        graphData.map(async g => {
          if (g.data.id === graphEdgeIdSave) {
            const [fromWalletId, toWalletId] = graphEdgeIdSave.split("+");
            let sourceType = null;
            let dstType = null;
            JSON.parse(graphState).graph.elements.nodes.map(node1 => {
              if (node1.data.id === fromWalletId) {
                sourceType = node1.data.type;
              }
              if (node1.data.id === toWalletId) {
                dstType = node1.data.type;
              }
            });
            const edgeNotes = g.data.notes;
            if (edgeNotes === "") {
              await axios.post(
                `${NOTES_API(
                  name
                )}/graphTransaction/${fromWalletId.toString()}/${toWalletId.toString()}`,
                {
                  mainLocation: "graph",
                  noteId: graphId,
                  sourceType: sourceType,
                  dstType: dstType
                }
              );
            } else {
              const graphTransactionNote = edgeNotes;
              const graphTransactionLocation = "graph";
              const graphTransactionLocationId = graphId;
              try {
                await axios.put(
                  `${NOTES_API(
                    name
                  )}/graphTransaction/${fromWalletId.toString()}/${toWalletId.toString()}`,
                  {
                    graphTransactionLocation,
                    graphTransactionLocationId,
                    graphTransactionNote,
                    sourceType,
                    dstType
                  }
                );
              } catch (err) {
                console.log(err);
              }
            }
          }
        });
      }

      // Save the current timestamp of the saved graph
      dispatch({
        type: GRAPH_SAVE_SUCCESS,
        graphId: graphId.toString(),
        name,
        timestamp: moment().format("MMMM Do YYYY, h:mm:ss a")
      });

      // Disable until better notification system is added.
      // dispatch(addNotification('Graph saved', 'success'));
    } catch (err) {
      console.log(err);
      dispatch(addNotification("Graph failed to save", "danger"));
    }
  }
};

export const deleteGraph = graphId => async (dispatch, getState) => {
  const name = getCurrency(getState());
  dispatch({ type: GRAPH_HANDLE_DELETE, name });
  try {
    await axios.delete(`${GRAPH_API(name)}/${graphId}`);
    dispatch({ type: GRAPH_DELETE_SUCCESS, graphId: graphId.toString(), name });
    history.replace("/graph");
    history.push("/graphs");
    dispatch(addNotification("Graph successfully deleted.", "success"));
  } catch (err) {
    dispatch(addNotification("Unable to delete graph.", "danger"));
  }
};

/**
 * This is a variant of createGraph which opens up the graph in a new tab.
 * This is done when creating a graph from the address/wallet/transaction views.
 * The preload values are just arrays of the items desired.
 */
export const createGraphInNewTabWithPreload = (
  caseNumber,
  description,
  isPublic,
  { preloadAddresses, preloadWallets, preloadTransactions, preloadUnclusteredTransactions }
) => async (dispatch, getState) => {
  const name = getCurrency(getState());
  try {
    const {
      data: { graphId }
    } = await axios.post(GRAPH_API(name), {
      caseNumber,
      description,
      isPublic,
      preloadAddresses,
      preloadWallets,
      preloadTransactions,
      preloadUnclusteredTransactions
    });

    if (graphId) {
      window.open(`/${name}/graph/${graphId}`, "_blank");
    }
  } catch (err) {
    throw err;
  }
};

// Adds items to preload the next time the graph is fetched.
export const addPreloadToGraph = (
  graphId,
  { preloadAddresses, preloadWallets, preloadTransactions, preloadUnclusteredTransactions }
) => async (dispatch, getState) => {
  const name = getCurrency(getState());

  // Make the request to add the preloads to the appropriate rows
  try {
    await axios.put(`${GRAPH_API(name)}/${graphId}/add-preload`, {
      preloadAddresses,
      preloadWallets,
      preloadTransactions,
      preloadUnclusteredTransactions
    });

    // Graph is cleared to force reload if user navigates to it within the
    // same tab. (Otherwise, it would show the old version before preloaded
    // items were added.)
    dispatch({
      type: GRAPH_DELETE_SUCCESS,
      graphId: graphId.toString(),
      name
    });

    window.open(`/${name}/graph/${graphId}`, "_blank");
  } catch (err) {
    throw err;
  }
};

export const importGraph = (graphId, data, cy) => async (dispatch, getState) => {
  let json;
  try {
    const name = getCurrency(getState());
    json = JSON.parse(data);
    if (json.graph == null) {
      throw new Error();
    }
    const wallets = await setUpCytoscape(json.graph, cy, name).then(result => result);

    await dispatch({
      type: GRAPH_IMPORT_GRAPH,
      graphId: graphId.toString(),
      graph: json.graph,
      editorContent: json.editorContent,
      caseNumber: json.caseNumber,
      description: json.description,
      wallets,
      name
    });

    await dispatch(saveGraph(graphId, cy));
  } catch (err) {
    dispatch(addNotification("Invalid import", "danger"));
  }
};

export const getWalletColorFromWallet = (category, oldColor, manuallySetColor, balance) => {
  // Find the color mapping for a wallet based on its category
  const categoryConfig = CategoryColorsConfig().find(e => e.category === category);

  // If the manuallySetColor isnt null then they manually set it so that should be the node color.
  if (manuallySetColor) {
    return manuallySetColor;
  }

  if (oldColor && !categoryConfig) {
    return oldColor;
  }

  // Check if the wallet category is known
  if (!categoryConfig) {
    // If the balance is zero, make it white. Else, make it grey
    if (balance > 0) {
      return "#c7c5c5";
    }
    return "#ffffff";
  }
  // If there has been no color set manually and there is a matching category, color accordingly
  return categoryConfig.hex;
};

// Get the color for a transaction node
export const getTrxNodeColorFromWallet = (oldColor, manuallySetColor) => {
  // If the manuallySetColor isnt null then they manually set it so that should be the node color.
  if (manuallySetColor !== null) {
    return manuallySetColor;
  }
  return oldColor || "#ffffff";
};

// export const isPeelChainInGraph = (wallet_id, wallets) => async (dispatch, getState) => {
//   // First get the peel chain data for the graph
//   const name = getCurrency(getState());
//   const { data } = axios.get(`${GRAPH_API(name)}/wallet/${wallet_id}/peel-chain`);
// };

export const addPeelChainToGraph = (wallet_id, cy, graphId) => async (dispatch, getState) => {
  // First get the peel chain data for the graph
  const name = getCurrency(getState());

  // First check if the peel chain is already in redux
  const chain = getWalletPeelChainData(getState(), wallet_id);
  let peel_data = null;
  if (chain["peel_chain_data"] === null) {
    const { data } = await axios.get(`${GRAPH_API(name)}/wallet/${wallet_id}/peel-chain`);
    peel_data = data["peel_chain_data"];
  } else {
    peel_data = chain["peel_chain_data"];
  }

  // First we need to delete all nodes from the graph that are in the chain.
  let node_data = {};
  let delete_nodes = [];

  peel_data.forEach(peel_item => {
    const element = cy.getElementById(peel_item["wallet_id"]);
    if (element.length === 1) {
      // Remember the elements data
      const manually_set_color = cy
        .getElementById(peel_item["wallet_id"])[0]
        .data("manuallySetColor");
      const notes = cy.getElementById(peel_item["wallet_id"])[0].data("notes");

      // Add the node data to the dict
      node_data[peel_item["wallet_id"]] = {
        manually_set_color,
        notes
      };
      delete_nodes.push(element);
    }

    // Now check if it has a peel item and do the same
    if (peel_item["peel_wallet_id"] !== null) {
      const element = cy.getElementById(peel_item["peel_wallet_id"]);
      if (element.length === 1) {
        // Remember the elements data
        const manually_set_color = cy
          .getElementById(peel_item["peel_wallet_id"])[0]
          .data("manuallySetColor");
        const notes = cy.getElementById(peel_item["peel_wallet_id"])[0].data("notes");

        // Add the node data to the dict
        node_data[peel_item["peel_wallet_id"]] = {
          manually_set_color,
          notes
        };
        delete_nodes.push(element);
      }
    }
  });

  await dispatch(deleteWalletsFromGraph(graphId, cy, delete_nodes, false));

  // Get the initial position for the first node in the peel chain
  const { x1, x2, y1, y2 } = cy.extent();
  // The latter half of the following expression determines whether to multiply by pos or neg
  const x = (x1 + x2) / 2 + Math.random() * (Math.random() > 0.5 ? 150 : -150);
  const y = (y1 + y2) / 2 + Math.random() * (Math.random() > 0.5 ? 150 : -150);

  let newWallets = cy.collection();

  // You can't just do a forEach as that just fires the functions
  // and immediately returns, which makes await ineffective.
  await Promise.all(
    peel_data.map(async chain_item => {
      const { wallet_id, peel_wallet_id, tag, peel_tag, chain_order } = chain_item;

      // Determine positioning based on chain order
      const x_position = x + 175 * chain_order;
      const peel_x = peel_wallet_id === null ? null : x_position;
      const peel_y = peel_wallet_id === null ? null : y - 175;

      // If the wallet is in the graph already move it to where it should be. Otherwise add it

      let manually_set_color = null;
      let notes = null;

      if (delete_nodes.includes(wallet_id)) {
        manually_set_color = node_data[wallet_id]["manually_set_color"];
        notes = node_data[wallet_id]["notes"];
      }
      const newWallet = await addWalletToGraph(
        wallet_id,
        x_position,
        y,
        graphId.toString(),
        cy,
        manually_set_color,
        false, // These need to be all added together as a single stack item.
        notes
      )(dispatch, getState);

      if (newWallet) {
        newWallets = newWallets.union(newWallet);
      }

      if (peel_wallet_id) {
        let peel_manually_set_color = null;
        let peel_notes = null;

        if (delete_nodes.includes(peel_wallet_id)) {
          peel_manually_set_color = node_data[wallet_id]["manually_set_color"];
          peel_notes = node_data[wallet_id]["notes"];
        }

        const newPeelWallet = await addWalletToGraph(
          peel_wallet_id,
          peel_x,
          peel_y,
          graphId.toString(),
          cy,
          peel_manually_set_color,
          false, // These need to be all added together as a single stack item.
          peel_notes
        )(dispatch, getState);
        if (newPeelWallet) {
          newWallets = newWallets.union(newPeelWallet);
        }
      }
    })
  );
  //
  // // Hopefully this is sync...
  // newWallets.layout({ name: "circle", fit: false }).run();
  const newWalletData = newWallets.map(wallet => {
    return {
      walletId: wallet.data("id"),
      label: wallet.data("label"),
      address: wallet.data("address"),
      category: wallet.data("category"),
      x: wallet.position("x"),
      y: wallet.position("y"),
      manuallySetColor: wallet.data("manuallySetColor")
    };
  });

  dispatch({
    type: GRAPH_UNDO_STACK_ADD,
    opType: "add",
    wallets: newWalletData,
    name
  });

  // Save the graph
  const id = getCurrentGraph(getState());
  await dispatch(saveGraph(id, cy));
};

/**
 * This is the base function for adding a wallet to the cytoscape instance
 *
 * This function requires all the information to be known when making the call which
 * might not always be the case. There are two layers of wrappers that are used when
 * you have a walletId, but do not know what the label/category is, and when you have
 * an address and do not know what it's walletId is and by extension its tag/category.
 *
 * The wrappers are addWalletToGraphInExplorer and addAddressToGraphInExplorer.
 *
 * This function is used when adding a wallet from a search result and while in the
 * explorer. When you are adding a wallet from the sent/recv menu, addWalletToGraphFromWalletView
 * is used.
 *
 */
export const addWalletToGraph = (
  walletId,
  x_,
  y_,
  graphId,
  cy,
  manuallySetColor = null,
  modifyUndoStack,
  notes = "",
  typeForEdges = "default"
) => async (dispatch, getState) => {
  if (cy.elements(`#${walletId}`).size() !== 0) {
    return null;
  }
  // If no coordinates are given, default to +- 50px away from the center of the current viewport
  // in both the x and y directions.
  let x;
  let y;
  if (x_ == null || y_ == null) {
    const { x1, x2, y1, y2 } = cy.extent();
    // The latter half of the following expression determines whether to multiply by pos or neg
    x = (x1 + x2) / 2 + Math.random() * (Math.random() > 0.5 ? 150 : -150);
    y = (y1 + y2) / 2 + Math.random() * (Math.random() > 0.5 ? 150 : -150);
  } else {
    x = x_;
    y = y_;
  }

  const name = getCurrency(getState());
  const email = getEmail(getState());
  const details =
    walletId > 0
      ? await axios.post(`/api/v2/${name}/graph/wallet-tags`, {
          walletIds: [walletId]
        })
      : await axios.get(`/api/v2/${name}/custom-wallet/${walletId}/stats`);

  const dateRange = getGraphSelectedDates(getState(), graphId); // date range
  const color = getWalletColorFromWallet(
    walletId > 0 ? details.data[walletId].category : "custom",
    "#c7c5c5",
    manuallySetColor,
    walletId > 0 ? details.data[walletId].balance : details.data.balance
  );
  const address = walletId > 0 ? details.data[walletId].address : "";
  const label =
    walletId > 0
      ? getWalletNameHelper(details.data[walletId], email)[0].name
      : details.data.name.name;
  const category = walletId > 0 ? details.data[walletId].category : "custom";
  try {
    const newWallet = cy.add({
      group: "nodes",
      data: {
        id: walletId.toString(),
        label: label,
        address: address,
        category: category,
        oldColor: color,
        manuallySetColor,
        balance: walletId > 0 ? details.data[walletId].balance : details.data.balance,
        inputCount: walletId > 0 ? details.data[walletId].inputCount : details.data.inputCount,
        notes,
        type: "default"
      },
      position: {
        x,
        y
      },
      selected: true
      // style: {
      //   "background-color": color
      // }
    });
    const walletIdStr = walletId.toString();

    // this needs to get all the edges and push new
    await getAndAddEdges(
      walletIdStr,
      cy,
      name,
      typeForEdges,
      dateRange.startDate,
      dateRange.endDate
    );

    // return value is used in addTransactionToGraph
    // SearchRow calls this function but ignores the return value
    dispatch({
      type: GRAPH_ADD_WALLET_TO_GRAPH,
      walletId: walletIdStr,
      graphId: graphId.toString(),
      name
    });

    if (modifyUndoStack === true) {
      dispatch({
        type: GRAPH_UNDO_STACK_ADD,
        opType: "add",
        wallets: [
          {
            walletId: walletIdStr,
            address,
            label,
            category,
            x,
            y,
            manuallySetColor,
            type: walletId > 0 ? "default" : "custom"
          }
        ],
        name
      });

      // Save the graph only if it's modifying undo stack. Else it's a trx, where we save it already
      const id = getCurrentGraph(getState());
      await dispatch(saveGraph(id, cy));
    }
    return newWallet;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const addUnclusteredTransactionNodeToGraph = (
  transaction_hash,
  label,
  x_,
  y_,
  graphId,
  cy,
  manuallySetColor,
  notes = ""
) => async (dispatch, getState) => {
  // Make sure the trx isn't already a node on the graph
  if (cy.elements(`#${transaction_hash}`).size() !== 0) {
    return null;
  }

  // get the details for the inputs and outputs of the transaction
  let data = await fetchTransaction(transaction_hash)(dispatch, getState);

  // If the transaction is pulled from redux, we need to convert it to a vanilla javascript object
  if (isImmutable(data)) {
    data = data.toJS();
  }
  console.log(data);

  //TODO wtf is this, the call returns stats if transaction fetched not from redux store but transactionStats if from redux???
  const { inputs, mentions, outputs, transactionStats, stats } = data;

  // Next we want to aggregate addresses that map to the same wallet, since the graph operates at the wallet level
  const inputsAndOutputs = {
    inputs: {},
    outputs: {}
  };

  inputs.forEach(ele => {
    if (ele.walletId in inputsAndOutputs.inputs) {
      inputsAndOutputs.inputs[ele.walletId] += ele.satoshi;
    } else {
      inputsAndOutputs.inputs[ele.walletId] = ele.satoshi;
    }
  });

  outputs.forEach(ele => {
    if (ele.walletId in inputsAndOutputs.outputs) {
      inputsAndOutputs.outputs[ele.walletId] += ele.satoshi;
    } else {
      inputsAndOutputs.outputs[ele.walletId] = ele.satoshi;
    }
  });

  // If no coordinates are given, default to +- 50px away from the center of the current viewport
  // in both the x and y directions.
  let x;
  let y;
  if (x_ == null || y_ == null) {
    const { x1, x2, y1, y2 } = cy.extent();
    // The latter half of the following expression determines whether to multiply by pos or neg
    x = (x1 + x2) / 2 + Math.random() * (Math.random() > 0.5 ? 150 : -150);
    y = (y1 + y2) / 2 + Math.random() * (Math.random() > 0.5 ? 150 : -150);
  } else {
    x = x_;
    y = y_;
  }

  // Add the transaction node to the graph
  const transactionNode = cy.add({
    group: "nodes",
    data: {
      type: "transaction",
      id: transaction_hash.toString(),
      label: `${label.substring(0, 6)}...${label.substring(label.length - 3, label.length)}`,
      category: null,
      oldColor: "red",
      manuallySetColor,
      inputs,
      outputs,
      inputCount: transactionStats ? transactionStats.inputCount : stats.inputCount,
      outputCount: transactionStats ? transactionStats.outputCount : stats.outputCount,
      timestamp: transactionStats ? transactionStats.timestamp : stats.timestamp,
      notes
    },
    position: {
      x,
      y
    },
    selected: true
  });

  // Add the input and output edges
  Object.keys(inputsAndOutputs.inputs).forEach(walletId => {
    const satoshi = inputsAndOutputs.inputs[walletId];

    // Add the edge to the graph
    addEdgeToGraph(
      walletId,
      transaction_hash,
      satoshi,
      satoshiToBitcoin3(satoshi),
      cy,
      "",
      null,
      "transaction",
      false,
      null
    );
  });

  Object.keys(inputsAndOutputs.outputs).forEach(walletId => {
    const satoshi = inputsAndOutputs.outputs[walletId];
    if (walletId === "0") {
      return; // Dont make edges for nonstandard wallets
    }
    // Add the edge to the graph
    addEdgeToGraph(
      transaction_hash,
      walletId,
      satoshi,
      satoshiToBitcoin3(satoshi),
      cy,
      "",
      null,
      "transaction",
      false,
      null
    );
  });

  return transactionNode;
};

// This is a wrapper around addWalletToGraph that fetches the primary tag
// if it is not known at the time of dispatching the action.
// Use DI to make this testable, since mocking within the same module is overly complicated/
// just straight up doesn't work in jest
export const __addWalletToGraphInExplorer = addWalletToGraphCallback => (
  walletId,
  graphId,
  cy
) => async (dispatch, getState) => {
  // The line below unselects all elements in the graph when a new one is added
  cy.elements().unselect();

  await dispatch(
    addWalletToGraphCallback(walletId.toString(), null, null, graphId.toString(), cy, null, true)
  );
};

export const addWalletToGraphInExplorer = __addWalletToGraphInExplorer(addWalletToGraph);

// This is a wrapper of addWalletToGraphInExplorer that takes in an address and fetches the
// address summary to get the corresponding wallet. This is then forwarded to
// addWalletToGraphInExplorer in order to get the wallets primaryTag and category, which
// is then forwarded to addWalletToGraph.
export const __addAddressToGraphInExplorer = addWalletToGraphInExplorerCallback => (
  address,
  graphId,
  cy
) => async (dispatch, getState) => {
  // The line below unselects all elements in the graph when a new one is added
  cy.elements().unselect();

  await dispatch(fetchAddressSummary(address));
  const walletId = getAddress(getState(), address).getIn(["summary", "walletId"]);
  if (typeof walletId !== "string") {
    throw new Error("walletId was a number!");
  }
  await dispatch(addWalletToGraphInExplorerCallback(walletId.toString(), graphId.toString(), cy));
};

export const addAddressToGraphInExplorer = __addAddressToGraphInExplorer(
  addWalletToGraphInExplorer
);

/**
 * addWalletToGraphFromWalletView() adds a wallet to the cytoscape graph
 *
 * This is called within wallet view items (sent/recv items) and notifies child
 * components of nodes that are added. When searching and using the explorer,
 * addWalletToGraph is used.
 */
export const addWalletToGraphFromWalletView = (
  newWalletId,
  satoshi,
  address,
  mainWallet,
  tag,
  category,
  direction,
  graphId,
  cy,
  manuallySetColor = null,
  notes = ""
) => async (dispatch, getState) => {
  // we know what these values are because sent and received wallets have this data already
  // thus we can call with values
  cy.elements().unselect();

  const mainWalletId = mainWallet.id();
  const { x, y } = mainWallet.position();
  const name = getCurrency(getState());

  const details = await axios.post(`/api/v2/${name}/graph/wallet-tags`, {
    walletIds: [newWalletId]
  });
  const color = getWalletColorFromWallet(
    details.data[newWalletId].category,
    "#c7c5c5",
    manuallySetColor,
    details.data[newWalletId].balance
  );

  const label = tag || address || newWalletId;

  const node = {
    group: "nodes",
    data: {
      id: newWalletId,
      label: label,
      address,
      category,
      oldColor: color,
      manuallySetColor: null,
      balance: details.data[newWalletId].balance,
      inputCount: details.data[newWalletId].inputCount,
      notes,
      type: "default"
    },
    position: {
      x: direction === "sent" ? x + 150 : x - 150,
      y: direction === "sent" ? y + 150 : y - 150
    },
    selected: true,
    style: {
      "background-color": color
    }
  };

  cy.nodes().lock();
  cy.add([node]);

  const connectedNodes = mainWallet.connectedEdges().connectedNodes();
  const boundingBox = connectedNodes.boundingBox();
  connectedNodes
    .layout({
      name: "breadthfirst",
      fit: false,
      boundingBox
    })
    .run();
  cy.nodes().unlock();

  const dateRange = getGraphSelectedDates(getState(), graphId);
  const newWalletIdStr = newWalletId.toString();
  await getAndAddEdges(newWalletIdStr, cy, name, "default", dateRange.startDate, dateRange.endDate);

  const newWallet = cy.nodes(node => node.data("id") === newWalletIdStr);
  // Position of new wallet gets changed since we call layout on the connected
  // nodes. We want to add *these* values to the undo stack.
  const newWalletX = newWallet.position("x");
  const newWalletY = newWallet.position("y");

  // cytoscape is not controlled by react, so we must ensure child components
  // know what elements are in cy with forceUpdate()

  dispatch({
    type: GRAPH_ADD_WALLET_TO_GRAPH,
    walletId: newWalletIdStr,
    graphId: graphId.toString(),
    name
  });
  dispatch({
    type: GRAPH_UNDO_STACK_ADD,
    opType: "add",
    wallets: [
      {
        walletId: newWalletIdStr,
        address,
        label,
        category,
        x: newWalletX,
        y: newWalletY,
        manuallySetColor: null,
        type: "default"
      }
    ],
    name
  });

  // Save the graph
  const id = getCurrentGraph(getState());
  await dispatch(saveGraph(id, cy));
};

// TODO might have to change addTransactionToGraphCallback as was in commit bb3eb08b
export const addTransactionToGraph = (transactionWallets, cy, graphId) => async (
  dispatch,
  getState
) => {
  let newWallets = cy.collection();
  // You can't just do a forEach as that just fires the functions
  // and immediately returns, which makes await ineffective.
  await Promise.all(
    Array.from(transactionWallets).map(async wallet => {
      const newWallet = await addWalletToGraph(
        wallet,
        null,
        null,
        graphId.toString(),
        cy,
        null,
        false, // These need to be all added together as a single stack item.
        ""
      )(dispatch, getState);
      if (newWallet) {
        newWallets = newWallets.union(newWallet);
      }
    })
  );

  // Hopefully this is sync...
  newWallets.layout({ name: "circle", fit: false }).run();
  const newWalletData = newWallets.map(wallet => {
    return {
      walletId: wallet.data("id"),
      label: wallet.data("label"),
      address: wallet.data("address"),
      category: wallet.data("category"),
      x: wallet.position("x"),
      y: wallet.position("y"),
      manuallySetColor: wallet.data("manuallySetColor")
    };
  });

  const name = getCurrency(getState());
  dispatch({
    type: GRAPH_UNDO_STACK_ADD,
    opType: "add",
    wallets: newWalletData,
    name
  });

  // Save the graph
  const id = getCurrentGraph(getState());
  await dispatch(saveGraph(id, cy));
};

export const addUnclusteredTransactionToGraph = (
  transactionHash,
  transactionWallets,
  cy,
  graphId
) => async (dispatch, getState) => {
  let newWallets = cy.collection();

  await Promise.all(
    Array.from(transactionWallets).map(async walletId => {
      const newWallet = await addWalletToGraph(
        walletId,
        null,
        null,
        graphId.toString(),
        cy,
        null,
        false, // These need to be all added together as a single stack item.
        ""
      )(dispatch, getState);
      if (newWallet) {
        newWallets = newWallets.union(newWallet);
      }
    })
  );

  // Add the necessary nodes to the graph, including the square node that represents the trx
  const transactionNode = await addUnclusteredTransactionNodeToGraph(
    transactionHash,
    transactionHash,
    null,
    null,
    graphId,
    cy,
    null,
    ""
  )(dispatch, getState);

  newWallets = newWallets.union(transactionNode);
  newWallets.layout({ name: "circle", fit: false }).run();

  // Hopefully this is sync...
  // newWallets.layout({ name: "circle", fit: false }).run();
  const newWalletData = newWallets.map(wallet => {
    if (wallet.data("type") === "default") {
      return {
        walletId: wallet.data("id"),
        label: wallet.data("label"),
        category: wallet.data("category"),
        x: wallet.position("x"),
        y: wallet.position("y"),
        type: "default",
        manuallySetColor: wallet.data("manuallySetColor")
      };
    }
    return {
      walletId: wallet.data("id"),
      label: wallet.data("label"),
      x: wallet.position("x"),
      y: wallet.position("y"),
      type: "transaction",
      manuallySetColor: wallet.data("manuallySetColor")
    };
  });

  const name = getCurrency(getState());
  dispatch({
    type: GRAPH_UNDO_STACK_ADD,
    opType: "add",
    wallets: newWalletData,
    name
  });

  // Save the graph
  const id = getCurrentGraph(getState());
  await dispatch(saveGraph(id, cy));
};

// This is a wrapper around addTransactionToGraph that is used when we know the
// transaction hash, but don't know the wallets that belong in it.
// The argument order should be made consistent, but refactoring tools suck...
export const __addTransactionToGraphInExplorer = (
  addTransactionToGraphCallback,
  addUnclusteredTransactionToGraphCallback
) => (transaction, cy, graphId) => async (dispatch, getState) => {
  // The line below unselects all elements in the graph when a new one is added
  cy.elements().unselect();

  const currency = getCurrency(getState());
  try {
    const {
      data: { wallets, input_count }
    } = await axios.get(`${TRANSACTION_API(currency)}/${transaction}/wallets`);
    // TODO might have to change addTransactionToGraphCallback as was in commit bb3eb08b
    if (input_count && input_count > 1) {
      await dispatch(addUnclusteredTransactionToGraphCallback(transaction, wallets, cy, graphId));
    } else {
      await dispatch(addTransactionToGraphCallback(wallets, cy, graphId.toString()));
    }
    return 1;
  } catch (err) {
    throw err;
  }
};

export const __addUnclusteredTransactionToGraphInExplorer = addUnclusteredTransactionToGraphCallback => (
  transaction,
  cy,
  graphId
) => async (dispatch, getState) => {
  // The line below unselects all elements in the graph when a new one is added
  cy.elements().unselect();

  const currency = getCurrency(getState());

  try {
    // Get the wallets in the trx
    const {
      data: { wallets }
    } = await axios.get(`${TRANSACTION_API(currency)}/${transaction}/wallets`);

    // Dispatch the action to add the unclustered trx to the graph
    await dispatch(addUnclusteredTransactionToGraphCallback(transaction, wallets, cy, graphId));
  } catch (err) {
    throw err;
  }
};

export const addTransactionToGraphInExplorer = __addTransactionToGraphInExplorer(
  addTransactionToGraph,
  addUnclusteredTransactionToGraph
);

export const addUnclusteredTransactionToGraphInExplorer = __addUnclusteredTransactionToGraphInExplorer(
  addUnclusteredTransactionToGraph
);

/**
 * Fetches the graph and sets it in cy as side effect
 */
export const fetchGraph = (graphId, cy, fetchGraphComplete) => async (dispatch, getState) => {
  const graph = getGraph(getState(), graphId.toString());
  const name = getCurrency(getState());
  if (graph) {
    const graphJson = getGraphJson(getState(), graphId.toString());
    await setUpCytoscape(graphJson, cy, name);
    dispatch({
      type: SETTING_CURRENT_GRAPH,
      graphId: graphId.toString(),
      name
    });
    return null;
  }

  dispatch({
    type: FETCHING_GRAPH,
    graphId: graphId.toString(),
    name
  });

  try {
    const { data } = await axios.get(`${GRAPH_API(name)}/${graphId}`);
    // Set up cytoscape using fetched json
    let walletSet = [];
    if (data.graph) {
      const { graph: graphJson } = data;

      if (graphJson.graph.elements.edges) {
        graphJson.graph.elements.edges.map(edge => {
          if (edge.data.notes !== "") {
            try {
              edge.data.notes = JSON.parse(edge.data.notes);
            } catch (err) {}
          }
        });
      }

      walletSet = await setUpCytoscape(graphJson, cy, name).then(result => result); // this is actually an array...
    }

    // preload items into cytoscape
    const {
      preloadAddresses,
      preloadWallets,
      preloadTransactions,
      preloadUnclusteredTransactions
    } = data;

    // The following runs without waiting for things to get preloaded. I couldn't
    // figure out why this was happening, so it's commented out for now.
    // if (preloadAddresses.length !== 0 ||
    //   preloadWallets.length !== 0 ||
    //   preloadTransactions.length !== 0) {
    //   const { description, caseNumber, editorState } = data;
    //   await dispatch(saveGraph(graphId, cy, { description, caseNumber, editorState }));
    //   // Only clear preload values if graph successfully saves
    //   await axios.put(`${GRAPH_API}/${graphId}/clear-preload`);
    // }

    dispatch({
      type: GRAPH_FETCH_SUCCESS,
      graphId: graphId.toString(),
      data,
      wallets: walletSet, // This will not include preload items
      name
    });

    dispatch({
      type: SETTING_CURRENT_GRAPH,
      graphId: graphId.toString(),
      name
    });

    // Preload items will be added to redux state here.
    preloadAddresses.map(async address => {
      await dispatch(addAddressToGraphInExplorer(address, graphId, cy));
    });

    preloadWallets.map(async walletId => {
      await dispatch(addWalletToGraphInExplorer(walletId.toString(), graphId, cy));
    });

    preloadTransactions.map(async transaction => {
      await dispatch(addTransactionToGraphInExplorer(transaction, cy, graphId));
    });

    await preloadUnclusteredTransactions.map(async transaction => {
      await dispatch(addUnclusteredTransactionToGraphInExplorer(transaction, cy, graphId));
    });
  } catch (err) {
    history.replace("/graph");
    dispatch({
      type: GRAPH_FETCH_FAILURE,
      graphId: graphId.toString(),
      name
    });
    throw err;
  }
};

// This is called through a graphRef in cxtMenuSettings.js.
export const deleteWalletsFromGraph = (graphId, cy, elements, modifyUndoStack) => async (
  dispatch,
  getState
) => {
  const name = getCurrency(getState());
  if (modifyUndoStack === true) {
    const wallets = elements.map(wallet => {
      if (wallet.isNode()) {
        if (wallet.data("type") === "default") {
          return {
            walletId: wallet.data("id"),
            address: wallet.data("address"),
            label: wallet.data("label"),
            category: wallet.data("category"),
            x: wallet.position("x"),
            y: wallet.position("y"),
            type: "default",
            manuallySetColor: wallet.data("manuallySetColor"),
            notes: wallet.data("notes")
          };
        }
        return {
          walletId: wallet.data("id"),
          label: wallet.data("label"),
          x: wallet.position("x"),
          y: wallet.position("y"),
          type: "transaction",
          manuallySetColor: wallet.data("manuallySetColor"),
          notes: wallet.data("notes")
        };
      }

      // These conditions only apply to edges
      if (wallet.data("type") === "default") {
        return {
          walletId: wallet.data("id"),
          bitcoin: wallet.data("bitcoin"),
          satoshi: wallet.data("satoshi"),
          manuallySetColor: wallet.data("manuallySetColor"),
          notes: wallet.data("notes"),
          type: "default",
          attributed: wallet.data("attributed"),
          hist_usd: wallet.data("hist_usd")
        };
      }
      return {
        walletId: wallet.data("id"),
        bitcoin: wallet.data("bitcoin"),
        satoshi: wallet.data("satoshi"),
        manuallySetColor: wallet.data("manuallySetColor"),
        notes: wallet.data("notes"),
        type: "transaction",
        attributed: false,
        hist_usd: wallet.data("hist_usd")
      };
    });
    dispatch({
      type: GRAPH_UNDO_STACK_ADD,
      opType: "delete",
      wallets,
      name
    });
  }

  elements.forEach(wallet => {
    // wallet has backreference to graph that owns it.
    wallet.remove();

    if (wallet.note) {
      wallet.note.hide();
    }

    if (wallet.isNode()) {
      dispatch({
        type: GRAPH_DELETE_WALLET_FROM_GRAPH,
        graphId: graphId.toString(),
        walletId: wallet.data("id"),
        name
      });
    }
  });

  // Save the graph
  await dispatch(saveGraph(graphId, cy));
};

export const closeExplorerModal = () => (dispatch, getState) => {
  dispatch({
    type: GRAPH_CLOSE_EXPLORER_MODAL,
    name: getCurrency(getState())
  });
};

export const graphSearch = (query, cy) => async (dispatch, getState) => {
  // Do not cache these until a way of handling updated user_tags is added
  // They are currently still placed into the redux store, but always overwritten.
  const name = getCurrency(getState());
  dispatch({
    type: FETCHING_GRAPH_SEARCH_RESULTS,
    query,
    name
  });

  const api = `${SEARCH_API(name)}`;

  try {
    const { data } = await axios.get(api, {
      params: { query: query, category: "all", no_stats: true, limit: 5, returnWallets: true }
    });

    const walletIds = {};
    const { wallets } = data;
    wallets &&
      wallets.forEach(({ walletId, name }) => !walletIds[walletId] && (walletIds[walletId] = name));
    const { custom_wallets } = data;
    custom_wallets &&
      custom_wallets.forEach(
        ({ walletId, name }) => !walletIds[walletId] && (walletIds[walletId] = name)
      );
    Object.keys(walletIds).length > 0 &&
      dispatch({
        type: WALLET_SET_NAMES,
        walletIds,
        name
      });
    dispatch({
      type: GRAPH_SEARCH_SUCCESS,
      query,
      data,
      name
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: GRAPH_SEARCH_FAILURE,
      query,
      name
    });
    dispatch({
      type: GRAPH_SEARCH_SUCCESS,
      query,
      data: {
        results: []
      },
      name
    });
    dispatch(addNotification(`No matching wallet found for ${query}. `, "danger"));
  }
};

export const graphAssociationSearch = (walletId, query, custom = false) => async (
  dispatch,
  getState
) => {
  const name = getCurrency(getState());
  const {
    data: { results }
  } = await axios.get(`${GRAPH_API(name)}/wallet/${walletId}/associations`, {
    params: {
      query
    }
  });

  dispatch({
    type: GRAPH_SEARCH_ASSOCIATIONS_SUCCESS,
    query,
    walletId,
    results,
    name
  });
};

export const changeCaseNumber = (graphId, caseNumber) => (dispatch, getState) => {
  const name = getCurrency(getState());
  dispatch({
    type: GRAPH_CHANGE_CASE_NUMBER,
    graphId: graphId.toString(),
    caseNumber,
    name
  });
};

export const changeAttributedEdgeToggle = (graphId, attributedEdgeToggleState) => (
  dispatch,
  getState
) => {
  const name = getCurrency(getState());
  dispatch({
    type: GRAPH_CHANGE_TOGGLE_STATE,
    graphId: graphId.toString(),
    attributedEdgeToggleState,
    name
  });
};

export const changeDates = (cy, graphId, startDate, endDate) => async (dispatch, getState) => {
  const name = getCurrency(getState());
  dispatch({
    type: GRAPH_CHANGE_DATES,
    graphId: graphId.toString(),
    startDate,
    endDate,
    name
  });
  const graph = getGraph(getState(), graphId.toString());
  if (graph) {
    const graph = getGraphJson(getState(), graphId.toString());
    // TODO filter out transaction nodes
    let wallets = getGraphWallets(getState(), graphId).toJS();
    wallets = wallets.filter(Number);
    const { graph: graphJson } = graph;
    await updateEdges(graphJson, wallets, name, wallets, startDate, endDate, true);
    console.log(graphJson);
    cy.json(graphJson);
    await dispatch(saveGraph(graphId, cy));
  }
};

export const changeDescription = (graphId, description) => (dispatch, getState) => {
  dispatch({
    type: GRAPH_CHANGE_DESCRIPTION,
    graphId: graphId.toString(),
    description,
    name: getCurrency(getState())
  });
};

export const changeMainTabsKey = mainTabsKey => (dispatch, getState) => {
  dispatch({
    type: GRAPH_CHANGE_MAIN_TABS_KEY,
    mainTabsKey,
    name: getCurrency(getState())
  });
};

export const changeDataTabsKey = dataTabsKey => (dispatch, getState) => {
  dispatch({
    type: GRAPH_CHANGE_DATA_TABS_KEY,
    dataTabsKey,
    name: getCurrency(getState())
  });
};

export const setOutputWalletId = outputWalletId => (dispatch, getState) => {
  dispatch({
    type: GRAPH_SET_OUTPUT_WALLET_ID,
    outputWalletId: outputWalletId == null ? null : outputWalletId.toString(),
    name: getCurrency(getState())
  });
};

export const graphShowNotesSet = (cy, showNotes) => (dispatch, getState) => {
  remakeNotes(cy, showNotes);
  dispatch({
    type: GRAPH_SHOW_NOTES_SET,
    showNotes,
    name: getCurrency(getState())
  });
};

export const updateGraphInRedux = (graphId, cy) => (dispatch, getState) => {
  // First check if the graph exists in redux from the initial fetch. If it does not, we don't want to 'save' it,
  // as that creates a redux entry for a graph that was never fetched. This seems to happen for graphs that take a long
  // time to fetch (10+ seconds)
  const curState = getState();
  const graphExistsInRedux = getGraph(curState, graphId);
  if (graphExistsInRedux !== undefined) {
    const graph = getGraphObject(
      cy,
      curState,
      graphId,
      graphExistsInRedux.getIn(["graph", "last_transaction_id"])
    );
    dispatch({
      type: GRAPH_UPDATE_GRAPH_IN_REDUX,
      graphId: graphId.toString(),
      graph,
      name: getCurrency(getState())
    });
  }
};

export const updateEditorState = (graphId, editorState) => (dispatch, getState) => {
  dispatch({
    type: GRAPH_UPDATE_EDITOR_STATE,
    graphId: graphId.toString(),
    editorState,
    name: getCurrency(getState())
  });
};

export const setMutualTransactionsOrder = order => (dispatch, getState) => {
  dispatch({
    type: GRAPH_MUTUAL_TRANSACTION_CHANGE_ORDER,
    order,
    name: getCurrency(getState())
  });
};

export const selectSingleNode = (graphId, walletId) => (dispatch, getState) => {
  dispatch({
    type: GRAPH_SELECT_SINGLE_NODE,
    graphId: graphId.toString(),
    walletId: walletId.toString(),
    name: getCurrency(getState())
  });
};

export const fetchPeelChainStatus = walletId => async (dispatch, getState) => {
  const name = getCurrency(getState());
  const { data } = await axios.put(`${GRAPH_API(name)}/wallet/${walletId}/peel-chain`);
  dispatch({
    type: GRAPH_PEEL_CHAIN_STATUS_SUCCESS,
    walletId: walletId.toString(),
    name: getCurrency(getState()),
    data: data["peel"]
  });
};
