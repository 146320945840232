// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import axios, { GRAPH_API, TRANSACTION_API } from "../api";
import { bitcoinStringToSatoshi, satoshiToBitcoin3 } from "../helpers";
import { getWalletColorFromWallet } from "./graph";
import { ethereumSetUpCytoscape } from "./ethereum/graphHelpers";
import { getWalletNameHelper } from "../selectors/wallet";

/**
 * Changes the bitcoin value so that it only has the only 3 decimal places are shown.
 * In addition, the original satoshi value is stored in `data.satoshi` just in case
 * we need to change the number of decimal places in the future.
 */
const update1To2 = graph => {
  const { graph: graphJson } = graph;
  graphJson.elements.edges.forEach(edge => {
    // bitcoin will be a string like "11.71054795"
    const { bitcoin } = edge.data;
    edge.data.satoshi = bitcoinStringToSatoshi(bitcoin);
    edge.data.bitcoin = satoshiToBitcoin3(edge.data.satoshi);
  });
  graph.version = 2;
};

/**
 * Adds a field for wallet category into the node data field. This is used for updating the node
 * colors in older graphs.
 * @param graph
 * @param currency
 */
const update2To3 = async (graph, currency) => {
  const { graph: graphJson } = graph;
  // Make a list of the ids and fetch their categories
  const ids = [];
  if (graphJson.elements.nodes) {
    graphJson.elements.nodes.forEach(ele => ids.push(parseInt(ele.data.id)));
    const details = await axios.post(`/api/v2/${currency}/graph/wallet-tags`, {
      walletIds: ids
    });
    // Set the category and oldColor fields for each node in the graphJson
    graphJson.elements.nodes.forEach(node => {
      if (details.data[node.data.id] !== null) {
        node.data.category = details.data[node.data.id].category;
        node.data.oldColor = graph.styles[node.data.id]["background-color"];
      }
    });
  }

  // update the graph version
  graph.version = 3;
};

/**
 * Adds a field for wallet category into the node data field. This is used for updating the node
 * colors in older graphs.
 * @param graph
 * @param currency
 */
const update3To4 = async (graph, currency) => {
  const { graph: graphJson } = graph;
  // Make a list of the ids and fetch their categories
  const ids = [];
  if (graphJson.elements.nodes) {
    graphJson.elements.nodes.forEach(ele => ids.push(parseInt(ele.data.id)));
    const details = await axios.post(`/api/v2/${currency}/graph/wallet-tags`, {
      walletIds: ids
    });
    // Set the balance and inputCount fields for each node in the graphJson
    graphJson.elements.nodes.forEach(node => {
      if (details.data[node.data.id] !== null) {
        node.data.balance = details.data[node.data.id].balance;
        node.data.inputCount = details.data[node.data.id].inputCount;
        // manuallySetColor now serves the purpose of determining whether or not a node had its color changed.
        node.data.manuallySetColor = null;
      }
    });
  }

  // update the graph version
  graph.version = 4;
};

/**
 * Adds a field for notes into the node data field.
 * @param graph
 */
const update4To5 = graph => {
  const { graph: graphJson } = graph;

  if (graphJson.elements.nodes) {
    graphJson.elements.nodes.forEach(node => {
      // Add an empty string into the notes field.
      node.data.notes = "";
    });
  }

  // update the graph version
  graph.version = 5;
};

/**
 * Adds a field for notes into the node data field.
 * @param graph
 */
const update5To6 = graph => {
  const { graph: graphJson } = graph;

  if (graphJson.elements.edges) {
    graphJson.elements.edges.forEach(edge => {
      // Add manuallySetColor and notes fields to edges.
      edge.data.manuallySetColor = "white";
      edge.data.notes = "";
    });
  }

  // update the graph version
  graph.version = 6;
};

/**
 * Adds a field for notes into the node data field.
 * @param graph
 */
const update6To7 = graph => {
  const { graph: graphJson } = graph;

  // Regular expression for all letters (case-insensitive)
  const regExp = /[a-zA-Z]/g;

  if (graphJson.elements.nodes) {
    graphJson.elements.nodes.forEach(node => {
      // If the id contains letters, we know it represents a trx, otherwise, it must be a wallet.
      node.data.type = regExp.test(node.data.id) ? "transaction" : "default";
    });
  }

  // Add the same type variable to the edges so that we know if they're unclustered trx edges
  if (graphJson.elements.edges) {
    graphJson.elements.edges.forEach(edge => {
      // If the id contains letters, we know it represents a trx, otherwise, it must be a wallet.
      edge.data.type = regExp.test(edge.data.id) ? "transaction" : "default";
    });
  }

  // update the graph version
  graph.version = 7;
};

const update7to8 = graph => {
  const { graph: graphJson } = graph;

  if (graphJson.elements.edges) {
    graphJson.elements.edges.forEach(edge => {
      // If the manually set color is the stock white, make it null. Otherwise, keep it because someone set it.
      edge.data.manuallySetColor =
        edge.data.manuallySetColor === "#FFFFFF" ||
        edge.data.manuallySetColor === "#ffffff" ||
        edge.data.manuallySetColor === "white"
          ? null
          : edge.data.manuallySetColor;
    });
  }

  graph.version = 8;
};

/**
 * Adds the attributed label to an edge so that we can easily toggle edges between known entities.
 * Just adds the field, as the value is correctly set each time the graph loads.
 *
 * I'm also redoing the default/transaction denotions because I found a graph where the data was for some reason
 * incomplete.
 * @param graph
 * @param currency
 */
const update8to9 = async (graph, currency) => {
  const { graph: graphJson } = graph;

  // Regular expression for all letters (case-insensitive)
  const regExp = /[a-zA-Z]/g;

  if (graphJson.elements.nodes) {
    graphJson.elements.nodes.forEach(node => {
      // If the id contains letters, we know it represents a trx, otherwise, it must be a wallet.
      node.data.type = regExp.test(node.data.id) ? "transaction" : "default";
    });
  }

  let ids = [];
  graphJson.elements.nodes.forEach(ele => {
    // We only want wallets, so filter type by default. Trx hashes wont change
    if (ele.data.type === "default") {
      ids.push(parseInt(ele.data.id));
    }
  });

  const details = await axios.post(`/api/v2/${currency}/graph/wallet-tags`, {
    walletIds: ids
  });

  // Add the attributed ids to a list
  let attributed_ids = [];
  for (const [key, value] of Object.entries(details.data)) {
    const { tag } = value;

    if (tag !== null) {
      attributed_ids.push(key);
    }
  }

  if (graphJson.elements.edges) {
    graphJson.elements.edges.forEach(edge => {
      // If both ids in an edge are attributed, we mark the edge as being attributed
      edge.data.attributed =
        attributed_ids.includes(edge.data.source) && attributed_ids.includes(edge.data.target);

      // If the id contains letters, we know it represents a trx, otherwise, it must be a wallet.
      edge.data.type = regExp.test(edge.data.id) ? "transaction" : "default";
    });
  }

  graph.version = 9;
};

const update9to10 = graph => {
  graph.showUnattributedEdges = true;
  graph.version = 10;
};

const update10to11 = graph => {
  const { graph: graphJson } = graph;
  if (graphJson.elements.edges) {
    graphJson.elements.edges.forEach(edge => {
      edge.data.hist_usd = null;
    });
  }

  graph.version = 11;
};

// Adding graph last_transaction_id
const update11to12 = graph => {
  graph.last_transaction_id = 0;
  graph.version = 12;
};

/**
 * Updates the Graph JSON to the latest version.
 *
 * I think this is probably going to just sequentially update, so if the latest version
 * is 3 and we get a graph JSON that is version 1, we update 1 -> 2 and then 2 -> 3.
 */
const updateGraphVersion = async (graph, currency) => {
  // TODO refactor this
  if (graph.version === 1 || graph.version === null) {
    update1To2(graph);
    await update2To3(graph, currency);
    await update3To4(graph, currency);
    update4To5(graph);
    update5To6(graph);
    update6To7(graph);
    update7to8(graph);
    await update8to9(graph, currency);
    update9to10(graph, currency);
    update10to11(graph, currency);
  }
  if (graph.version === 2) {
    await update2To3(graph, currency);
    await update3To4(graph, currency);
    update4To5(graph);
    update5To6(graph);
    update6To7(graph);
    update7to8(graph);
    await update8to9(graph, currency);
    update9to10(graph, currency);
    update10to11(graph, currency);
  }
  if (graph.version === 3) {
    await update3To4(graph, currency);
    update4To5(graph);
    update5To6(graph);
    update6To7(graph);
    update7to8(graph);
    await update8to9(graph, currency);
    update9to10(graph, currency);
    update10to11(graph, currency);
  }
  if (graph.version === 4) {
    update4To5(graph);
    update5To6(graph);
    update6To7(graph);
    update7to8(graph);
    await update8to9(graph, currency);
    update9to10(graph, currency);
    update10to11(graph, currency);
  }
  if (graph.version === 5) {
    update5To6(graph);
    update6To7(graph);
    update7to8(graph);
    await update8to9(graph, currency);
    update9to10(graph, currency);
    update10to11(graph, currency);
  }
  if (graph.version === 6) {
    update6To7(graph);
    update7to8(graph);
    await update8to9(graph, currency);
    update9to10(graph, currency);
    update10to11(graph, currency);
  }
  if (graph.version === 7) {
    update7to8(graph);
    await update8to9(graph, currency);
    update9to10(graph, currency);
    update10to11(graph, currency);
  }
  if (graph.version === 8) {
    await update8to9(graph, currency);
    update9to10(graph, currency);
    update10to11(graph, currency);
  }
  if (graph.version === 9) {
    update9to10(graph, currency);
    update10to11(graph, currency);
  }
  if (graph.version === 10) {
    update10to11(graph, currency);
  }
  if (graph.version === 11) {
    update11to12(graph, currency);
  }
  if (graph.version !== 12) {
    throw new Error("Unknown graph version");
  }
};

/**
 * Goes through all wallets and updates their graph with optional date range
 * @param graphJson
 * @param wallets
 * @param currency
 * @param allWallets
 * @param startDate
 * @param endDate
 * @param removeNotFound
 * @returns {Promise<void>}
 */
export async function updateEdges(
  graphJson,
  wallets,
  currency,
  allWallets,
  startDate = null,
  endDate = null,
  removeNotFound = false
) {
  // Object to hold all edges found through the api
  const newEdgeJson = {};
  if (graphJson.elements.edges === undefined) {
    graphJson.elements.edges = [];
  }
  for (const id of wallets) {
    // Fetch the edges for each id
    const {
      data: { edges, timeout }
    } = await axios.post(`${GRAPH_API(currency)}/edges`, {
      newWalletId: id,
      walletIds: allWallets,
      startDate: startDate && startDate.getTime() / 1000,
      endDate: endDate && endDate.getTime() / 1000
    });

    // Add the data to a dict for quick lookup later
    // inputWallet, outputWallet, satoshi, attributed, hist_usd elements of edge
    edges.forEach(edge => {
      const { inputWallet, outputWallet } = edge;
      const key = `${inputWallet}+${outputWallet}`;
      // if getting same edge take the one that didn't timeout if it exists
      if (newEdgeJson[key]) {
        if (newEdgeJson[key].timeout && !timeout) {
          newEdgeJson[key] = { ...edge, timeout };
        }
      } else {
        newEdgeJson[key] = { ...edge, timeout };
      }
    });
  }

  // Removes edges if the edge was never found in returned from api
  let edgesToRemove = [];
  graphJson.elements.edges.forEach(ele => {
    if (ele.data.type === "default") {
      const values = newEdgeJson[ele.data.id];
      if (values !== undefined) {
        ele.data.satoshi = values["satoshi"];
        ele.data.bitcoin = satoshiToBitcoin3(values["satoshi"]);
        ele.data.hist_usd = values["hist_usd"];
        ele.data.timeout = values["timeout"];
        delete newEdgeJson[ele.data.id];
      } else {
        edgesToRemove.push(ele.data.id);
      }
    }
  });
  if (removeNotFound) {
    // if removeNotFound set remove the edges
    graphJson.elements.edges = graphJson.elements.edges.filter(element => {
      return !edgesToRemove.includes(element.data.id);
    });
  }

  // Add edges that weren't in the graph to begin with
  Object.keys(newEdgeJson).forEach(ele => {
    addEdgeToGraphFromJsonView(
      newEdgeJson[ele].inputWallet.toString(),
      newEdgeJson[ele].outputWallet.toString(),
      newEdgeJson[ele].satoshi,
      satoshiToBitcoin3(newEdgeJson[ele].satoshi),
      graphJson,
      null,
      "",
      "default",
      newEdgeJson[ele].attributed,
      newEdgeJson[ele].hist_usd,
      newEdgeJson[ele].timeout
    );
  });
}

/**
 * This function checks all cold and zero balance wallets to ensure that they have not had any
 * outgoing trx or balance changes since being added to the graph.
 * @param graph
 * @param currency
 * @returns {Promise<void>}
 */
const updateNodeDetails = async (graph, currency, email) => {
  const { graph: graphJson } = graph;

  // If there are no nodes then the nodes array will not exist, so we just return
  if (graphJson.elements.nodes === undefined) {
    return;
  }

  // Create a mapping of wallet ids
  const oldWalletToNewWallet = {};
  const newWalletToOldWallet = {};

  // Get an array of the wallets in the graph
  const ids = [];
  graphJson.elements.nodes.forEach(ele => {
    // We only want wallets, so filter type by default. Trx hashes wont change
    if (ele.data.type === "default") {
      ids.push(parseInt(ele.data.id));
    } else {
      // If the data type is not default, it's a trx node so we want to map its hash back to itself since those dont change
      oldWalletToNewWallet[ele.data.id] = ele.data.id;
      newWalletToOldWallet[ele.data.id] = ele.data.id;
    }
  });

  // Keep track of custom colors for nodes and edges that may get replaced
  const customColorMappings = {
    edges: {},
    nodes: {}
  };

  // retrieve the updated details for all wallets
  const details = await axios.post(`/api/v2/${currency}/graph/wallet-tags`, {
    walletIds: ids
  });

  const idsToRemove = [];
  // iterate through data from request and see which wallets have changed id
  for (const [key, value] of Object.entries(details.data)) {
    if (!value) {
      // wallet doesn't exist anymore so delete it
      idsToRemove.push(key);
      oldWalletToNewWallet[key.toString()] = key;
    } else {
      const { currentWalletId } = value;
      if (key === currentWalletId) {
        oldWalletToNewWallet[key.toString()] = currentWalletId;
        newWalletToOldWallet[currentWalletId] = key.toString();
      }
    }
  }

  // Iterate through the nodes. If their wallet id has changed in the map
  const idsToChange = {};
  const finalWalletIds = [];
  const unchangedWalletIds = [];

  graphJson.elements.nodes.forEach(ele => {
    // First, make sure the node represents a wallet and not a transaction
    if (ele.data.type === "default") {
      // Then, check if the wallet id changed
      if (oldWalletToNewWallet[ele.data.id] && ele.data.id !== oldWalletToNewWallet[ele.data.id]) {
        // Next, check if the new wallet id is already a node in the graph. If so, delete this wallet and its edges
        // since the node we want already exists
        if (ids.includes(parseInt(oldWalletToNewWallet[ele.data.id]))) {
          idsToRemove.push(ele.data.id);
        } else {
          // If the new wallet id doesn't already exist in the graph, we can just swap ids. Here we want to delete
          // all edges for this wallet id and recalculate them using a variant of getAndAddEdges
          idsToChange[ele.data.id] = { x: ele.position.x, y: ele.position.y };
          finalWalletIds.push(oldWalletToNewWallet[ele.data.id]);

          // Add the custom color to the mapping. If the wallet it is being moved to has a category, we don't want to
          // carry over the custom color since tagged entities have specific colors
          if (details.data[ele.data.id].category !== null) {
            customColorMappings.nodes[oldWalletToNewWallet[ele.data.id]] =
              ele.data.manuallySetColor;
          } else {
            customColorMappings.nodes[oldWalletToNewWallet[ele.data.id]] = null;
          }
        }
      } else {
        // If it didn't change, add it to our final array
        finalWalletIds.push(ele.data.id);
        unchangedWalletIds.push(ele.data.id);
      }
    }
  });

  // Filter makes removing items from an array much simpler so we do that here instead of above
  // For nodes, we remove all elements that need to be removed AND those we want to change. We remove the changed ones
  // because we want to recalculate the edges anyways.
  graphJson.elements.nodes = graphJson.elements.nodes.filter(
    element => !idsToRemove.includes(element.data.id) && !(element.data.id in idsToChange)
  );

  // Before adding any edges back, we need to add back the changed nodes or else we get an exception.
  for (const id of Object.keys(idsToChange)) {
    const {
      balance,
      category,
      currentWalletId,
      customTag,
      inputCount,
      tag,
      anchorAddress
    } = details.data[id];
    const { x, y } = idsToChange[id];
    await addNodeToGraphFromJsonView(
      graphJson,
      balance,
      category,
      currentWalletId,
      tag,
      inputCount,
      customTag,
      "",
      customColorMappings.nodes[currentWalletId] || null,
      x,
      y,
      anchorAddress
    );
  }

  // A dict that keeps track of edge colors between nodes. We want this so that in recalculating the edges, we don't
  // lose edge colors that were set by the user.
  // Key is new source wallet id, value is a dict mapping new target wallet id to manuallysetcolor.
  const manuallySetColors = {};
  const edgeNotes = {};

  if (graphJson.elements.edges !== undefined) {
    // For edges we want to remove any edge that touches a removed OR a changed node. Those touching the changed nodes will be added back below.
    graphJson.elements.edges = graphJson.elements.edges.filter(element => {
      // If there is no manuallySetColor, default to white. Some older graph edges don't have this which we need here.
      if (element.data.manuallySetColor === undefined) {
        element.data.manuallySetColor = null;
      }

      // Same thing for notes. Move this to a new graph version at some point
      if (element.data.notes === undefined) {
        element.data.notes = "";
      }

      // Get the converted source and target wallet ids
      const source = oldWalletToNewWallet[element.data.source];
      const target = oldWalletToNewWallet[element.data.target];

      // If the key isn't in the colors dict yet, we need to initialize it
      if (!Object.keys(manuallySetColors).includes(source)) {
        manuallySetColors[source] = {};
      }
      // Add the source+target edge color. We will need this when going back through and adding the edges
      if (!Object.keys(manuallySetColors[source]).includes(target)) {
        manuallySetColors[source][target] = [element.data.manuallySetColor];
      } else {
        manuallySetColors[source][target].push(element.data.manuallySetColor);
      }

      // If the key isn't in the notes yet, we need to initialize it
      if (!Object.keys(edgeNotes).includes(source)) {
        edgeNotes[source] = {};
      }
      // Add the source+target note. We will need this when going back through and adding the edges
      if (!Object.keys(edgeNotes[source]).includes(target)) {
        edgeNotes[source][target] = [element.data.notes];
      } else {
        edgeNotes[source][target].push(element.data.notes);
      }

      // If neither the source nor target are touching an updated node, we keep it. If the edge involves a wallet
      // that was just updated, we want to scrap it
      return (
        !idsToRemove.includes(element.data.target) &&
        !idsToRemove.includes(element.data.source) &&
        !(element.data.target in idsToChange) &&
        !(element.data.source in idsToChange) &&
        element.data.type === "default"
      );
    });
  }

  const {
    data: { wallet_ids, last_transaction_id }
  } = await axios.post(`${GRAPH_API(currency)}/changed-wallets/${graph.last_transaction_id || 0}`, {
    wallet_ids: unchangedWalletIds
  });
  graph.last_transaction_id = last_transaction_id;

  const startDate = graph.startDate ? new Date(graph.startDate) : null;
  const endDate = graph.endDate ? new Date(graph.endDate) : null;
  // update the details of the edges that already exist. We don't need to do this for transaction nodes/edges
  await updateEdges(graphJson, wallet_ids, currency, finalWalletIds, startDate, endDate, false);

  // Here we add back the edges of all of the changed nodes.
  // Note that in order to get async/await to work here, I needed to use a for of loop instead of foreach
  for (const id of Object.keys(idsToChange)) {
    await getAndAddEdgesToGraphFromJson(
      oldWalletToNewWallet[id],
      graphJson,
      finalWalletIds,
      currency,
      manuallySetColors,
      edgeNotes
    );
  }

  // Reset the node's input count and balance to what was just retrieved
  for (const ele of graphJson.elements.nodes) {
    if (details.data[ele.data.id] !== undefined && details.data[ele.data.id] !== null) {
      // Update input count, balance, and category
      ele.data.inputCount = details.data[ele.data.id].inputCount;
      ele.data.balance = details.data[ele.data.id].balance;
      ele.data.category = details.data[ele.data.id].category;

      ele.data.label = getWalletNameHelper(details.data[ele.data.id], email)[0]["name"];
      ele.data.address = details.data[ele.data.id]["address"];
    }

    if (ele.data.type === "transaction") {
      // If the node represents a trx, readd the edges
      await getAndAddUnclusteredTrxEdgesFromJsonView(
        ele.data.id,
        graphJson,
        currency,
        manuallySetColors,
        edgeNotes,
        finalWalletIds
      );
    }
  }
};

// Returns node ids of all wallets in graph as ints
// They are stored as strings within cytoscape
export const setUpCytoscape = async (graph, cy, currency) => {
  if (currency === "ethereum") {
    return ethereumSetUpCytoscape(graph, cy);
  }
  if (!graph || graph.version > 12) {
    return null;
  }

  // Adding this in for the rare weird case where graphJson is null
  if (graph.graph === null || !graph.graph) {
    return [];
  }

  // Run the update functions on the graph
  await updateGraphVersion(graph, currency);
  await updateNodeDetails(graph, currency);

  const { graph: graphJson } = graph;

  // Check if the cytoscape instance has been destroyed before attempting to call a method on it
  if (cy.destroyed()) {
    return [];
  }
  cy.json(graphJson);

  return cy
    .nodes()
    .toArray()
    .map(node => node.id());
};

const addNodeToGraphFromJsonView = (
  graphJson,
  balance,
  category,
  id,
  tag,
  inputCount,
  customTag,
  notes,
  manuallySetColor,
  x,
  y,
  address
) => {
  // Get the color depending on the wallet category and balance
  const color = getWalletColorFromWallet(category, "#c7c5c5", null, balance);

  // Create the new node object
  const node = {
    data: {
      id,
      label: customTag || tag || address || id,
      address,
      category,
      oldColor: color,
      manuallySetColor,
      balance,
      inputCount,
      notes
    },
    grabbable: true,
    group: "nodes",
    locked: false,
    classes: "",
    removed: false,
    pannable: false,
    position: {
      x,
      y
    },
    selectable: true,
    selected: false
  };

  // Add the node to the list of nodes in the graph json
  graphJson.elements.nodes.push(node);
};

const addEdgeToGraphFromJsonView = (
  inputWallet,
  outputWallet,
  satoshi,
  bitcoin,
  graphJson,
  manuallySetColor = null,
  notes = "",
  type = "default",
  attributed = false,
  hist_usd = null,
  timeout = false
) => {
  const id = `${inputWallet}+${outputWallet}`;
  const edge = {
    group: "edges",
    data: {
      id,
      source: inputWallet,
      target: outputWallet,
      satoshi,
      bitcoin,
      manuallySetColor,
      notes,
      type,
      attributed,
      hist_usd,
      timeout
    },
    position: { x: 0, y: 0 },
    classes: "",
    grabbable: true,
    locked: false,
    pannable: true,
    removed: false,
    selectable: true,
    selected: false
  };

  graphJson.elements.edges.push(edge);
};

const getAndAddEdgesToGraphFromJson = async (
  walletIdToAdd,
  graphJson,
  finalWalletIds,
  currency,
  manuallySetColors,
  edgeNotes
) => {
  // We should consider modifying this to go in one request. No sense in doing a new one for every node.
  const {
    data: { edges }
  } = await axios.post(`${GRAPH_API(currency)}/edges`, {
    newWalletId: walletIdToAdd,
    walletIds: finalWalletIds
  });

  edges.forEach(edge => {
    const { inputWallet, outputWallet, satoshi, attributed, hist_usd } = edge;
    const bitcoin = satoshiToBitcoin3(satoshi);

    const bodyStyles = window.getComputedStyle(document.body);
    const cssGraphBackground = bodyStyles.getPropertyValue("--graph-background-color");
    const cssGraphArrow = bodyStyles.getPropertyValue("--graph-arrow-color");

    let color = cssGraphArrow;
    let note = "";

    // First check if this edge should have a manually set color based on its equivalent having existed
    if (manuallySetColors[inputWallet] !== undefined) {
      if (manuallySetColors[inputWallet][outputWallet] !== undefined) {
        // If we only find one manually set color for this edge we will use it. If there are multiple, we default to white.
        if (manuallySetColors[inputWallet][outputWallet].length <= 1) {
          color = manuallySetColors[inputWallet][outputWallet][0];
        }
      }
    }

    // Then check if this edge should have a note based on its equivalent having existed
    if (edgeNotes[inputWallet] !== undefined) {
      if (edgeNotes[inputWallet][outputWallet] !== undefined) {
        // If we only find one manually set color for this edge we will use it. If there are multiple, we default to white.
        if (edgeNotes[inputWallet][outputWallet].length <= 1) {
          note = edgeNotes[inputWallet][outputWallet][0];
        }
      }
    }
    addEdgeToGraphFromJsonView(
      inputWallet.toString(),
      outputWallet.toString(),
      satoshi,
      bitcoin,
      graphJson,
      color,
      note,
      "default",
      attributed,
      hist_usd
    );
  });
};

const getAndAddUnclusteredTrxEdgesFromJsonView = async (
  transactionHash,
  graphJson,
  currency,
  manuallySetColors,
  edgeNotes,
  idsInGraph
) => {
  // We should consider modifying this to go in one request. No sense in doing a new one for every node.
  const { data } = await axios.get(`${TRANSACTION_API(currency)}/${transactionHash}`);

  const { inputs, outputs } = data;

  // Next we want to aggregate addresses that map to the same wallet, since the graph operates at the wallet level
  const inputsAndOutputs = {
    inputs: {},
    outputs: {}
  };

  // Set the satoshi amounts for the inputs and outputs
  inputs.forEach(ele => {
    if (idsInGraph.includes(ele.walletId.toString())) {
      if (ele.walletId in inputsAndOutputs.inputs) {
        inputsAndOutputs.inputs[ele.walletId] += ele.satoshi;
      } else {
        inputsAndOutputs.inputs[ele.walletId] = ele.satoshi;
      }
    }
  });
  outputs.forEach(ele => {
    if (idsInGraph.includes(ele.walletId.toString())) {
      if (ele.walletId in inputsAndOutputs.outputs) {
        inputsAndOutputs.outputs[ele.walletId] += ele.satoshi;
      } else {
        inputsAndOutputs.outputs[ele.walletId] = ele.satoshi;
      }
    }
  });

  // Add the input and output edges
  Object.keys(inputsAndOutputs.inputs).forEach(walletId => {
    const satoshi = inputsAndOutputs.inputs[walletId];
    const bitcoin = satoshiToBitcoin3(satoshi);

    const bodyStyles = window.getComputedStyle(document.body);
    const cssGraphArrow = bodyStyles.getPropertyValue("--graph-arrow-color");
    let color = cssGraphArrow;

    // First check if this edge should have a manually set color based on its equivalent having existed
    if (manuallySetColors[walletId] !== undefined) {
      if (manuallySetColors[walletId][transactionHash] !== undefined) {
        // If we only find one manually set color for this edge we will use it. If there are multiple, we default to white.
        if (manuallySetColors[walletId][transactionHash].length <= 1) {
          color = manuallySetColors[walletId][transactionHash][0];
        }
      }
    }

    // Check if there was a note set for the edge
    let notes = "";
    // First check if this edge should have a manually set color based on its equivalent having existed
    if (edgeNotes[walletId] !== undefined) {
      if (edgeNotes[walletId][transactionHash] !== undefined) {
        // If we only find one manually set color for this edge we will use it. If there are multiple, we default to white.
        if (edgeNotes[walletId][transactionHash].length <= 1) {
          notes = edgeNotes[walletId][transactionHash][0];
        }
      }
    }
    // Add the edge to the graph
    addEdgeToGraphFromJsonView(
      walletId,
      transactionHash,
      satoshi,
      bitcoin,
      graphJson,
      color,
      notes,
      "transaction",
      false
    );
  });

  Object.keys(inputsAndOutputs.outputs).forEach(walletId => {
    const satoshi = inputsAndOutputs.outputs[walletId];
    const bitcoin = satoshiToBitcoin3(satoshi);
    const bodyStyles = window.getComputedStyle(document.body);
    const cssGraphArrow = bodyStyles.getPropertyValue("--graph-arrow-color");

    let color = cssGraphArrow;
    // First check if this edge should have a manually set color based on its equivalent having existed
    if (manuallySetColors[transactionHash] !== undefined) {
      if (manuallySetColors[transactionHash][walletId] !== undefined) {
        // If we only find one manually set color for this edge we will use it. If there are multiple, we default to white.
        if (manuallySetColors[transactionHash][walletId].length <= 1) {
          color = manuallySetColors[transactionHash][walletId][0];
        }
      }
    }

    // Check if there was a note set for the edge
    let notes = "";
    // First check if this edge should have a manually set color based on its equivalent having existed
    if (edgeNotes[transactionHash] !== undefined) {
      if (edgeNotes[transactionHash][walletId] !== undefined) {
        // If we only find one manually set color for this edge we will use it. If there are multiple, we default to white.
        if (edgeNotes[transactionHash][walletId].length <= 1) {
          notes = edgeNotes[transactionHash][walletId][0];
        }
      }
    }

    // Add the edge to the graph
    addEdgeToGraphFromJsonView(
      transactionHash,
      walletId,
      satoshi,
      bitcoin,
      graphJson,
      color,
      notes,
      "transaction",
      false
    );
  });
};

export const addEdgeToGraph = (
  inputWallet,
  outputWallet,
  satoshi,
  bitcoin,
  cy,
  notes = "",
  manuallySetColor = null,
  type = "default",
  attributed = false,
  hist_usd = null,
  timeout = false
) => {
  // doesn't need an 'add to undo stack' specification because all edges added to the graph are either added with nodes
  // or are on the undo stack from being deleted.
  const id = `${inputWallet}+${outputWallet}`;
  const edge = {
    group: "edges",
    data: {
      id,
      source: inputWallet.toString(),
      target: outputWallet.toString(),
      satoshi,
      bitcoin,
      manuallySetColor,
      notes,
      type,
      attributed,
      hist_usd,
      timeout
    }
  };
  cy.add([edge]);
};

/**
 * Adds edges for wallet in graph, if date range adds edges for that timerange
 * @param walletId
 * @param cy
 * @param currency
 * @param type
 * @param startDate
 * @param endDate
 * @returns {Promise<void>}
 */
export const getAndAddEdges = async (
  walletId,
  cy,
  currency,
  type = "default",
  startDate = null,
  endDate = null
) => {
  if (cy.nodes() === undefined) {
    return;
  }
  // Only look for the edges with the normal nodes, not trx ones
  const walletIds = [];
  const trxWalletIds = [];

  cy.nodes()
    .toArray()
    .forEach(node => {
      if (node.data("type") === "transaction") {
        trxWalletIds.push(node.data("id"));
      } else {
        walletIds.push(node.data("id"));
      }
    });
  // walletIds.push(walletId.toString());
  const {
    data: { edges, timeout }
  } = await axios.post(`${GRAPH_API(currency)}/edges`, {
    newWalletId: walletId,
    walletIds,
    startDate: startDate && startDate.getTime() / 1000,
    endDate: endDate && endDate.getTime() / 1000
  });

  edges.forEach(edge => {
    const { inputWallet, outputWallet, satoshi, attributed, hist_usd } = edge;
    const bitcoin = satoshiToBitcoin3(satoshi);
    addEdgeToGraph(
      inputWallet,
      outputWallet,
      satoshi,
      bitcoin,
      cy,
      "",
      null,
      type,
      attributed,
      hist_usd,
      timeout
    );
  });

  // Now get the edges for the transactions
  for (const trx of trxWalletIds) {
    // We should consider modifying this to go in one request. No sense in doing a new one for every node.
    const { data } = await axios.get(`${TRANSACTION_API(currency)}/${trx}`);

    const { inputs, outputs } = data;

    // Next we want to aggregate addresses that map to the same wallet, since the graph operates at the wallet level
    const inputsAndOutputs = {
      inputs: {},
      outputs: {}
    };

    // Set the satoshi amounts for the inputs and outputs
    inputs.forEach(ele => {
      if (ele.walletId.toString() === walletId) {
        if (ele.walletId in inputsAndOutputs.inputs) {
          inputsAndOutputs.inputs[ele.walletId] += ele.satoshi;
        } else {
          inputsAndOutputs.inputs[ele.walletId] = ele.satoshi;
        }
      }
    });

    outputs.forEach(ele => {
      if (ele.walletId.toString() === walletId) {
        if (ele.walletId in inputsAndOutputs.outputs) {
          inputsAndOutputs.outputs[ele.walletId] += ele.satoshi;
        } else {
          inputsAndOutputs.outputs[ele.walletId] = ele.satoshi;
        }
      }
    });

    // Add the input edge if necessary
    Object.keys(inputsAndOutputs.inputs).forEach(ele => {
      const satoshi = inputsAndOutputs.inputs[ele];
      const bitcoin = satoshiToBitcoin3(satoshi);
      addEdgeToGraph(walletId, trx, satoshi, bitcoin, cy, "", null, "transaction", false);
    });

    // Add the output edge if necessary
    Object.keys(inputsAndOutputs.outputs).forEach(ele => {
      const satoshi = inputsAndOutputs.outputs[ele];
      const bitcoin = satoshiToBitcoin3(satoshi);
      addEdgeToGraph(trx, walletId, satoshi, bitcoin, cy, "", null, "transaction", false);
    });
  }
};
