import axios, { ETHEREUM_API } from "../../api";
import {
  getAddressMutualTokens,
  getAddressMutualTransactions,
  getAddressSummary,
  getAddressTokens,
  getAddressTransactions,
  getInternalTransactions
} from "../../selectors/ethereum/address";
import {
  ADDRESS_GRAPH_TRANSACTIONS_FETCH_SUCCESS,
  ADDRESS_HISTORY_STATS_FAILURE,
  ADDRESS_HISTORY_STATS_SUCCESS,
  ADDRESS_INTERNAL_TRANSACTIONS_FAILURE,
  ADDRESS_INTERNAL_TRANSACTIONS_FETCH_SUCCESS,
  ADDRESS_INTERNAL_TRANSACTIONS_FETCHING,
  ADDRESS_SUMMARY_FETCH_FAILURE,
  ADDRESS_SUMMARY_FETCH_SUCCESS,
  ADDRESS_TOKEN_FAILURE,
  ADDRESS_TOKEN_FETCH_SUCCESS,
  ADDRESS_TOKEN_FETCHING,
  ADDRESS_TRANSACTION_TOKEN_FAILURE,
  ADDRESS_TRANSACTION_TOKEN_FETCH_SUCCESS,
  ADDRESS_TRANSACTION_TOKEN_FETCHING,
  FETCHING_ADDRESS_HISTORY_STATS,
  FETCHING_ADDRESS_SUMMARY,
  FETCHING_GRAPH_ADDRESS_TRANSACTIONS,
  FETCHING_MUTUAL_ADDRESS_TOKENS,
  FETCHING_MUTUAL_ADDRESS_TRANSACTIONS,
  MUTUAL_ADDRESS_TOKENS_FETCH_FAILURE,
  MUTUAL_ADDRESS_TOKENS_FETCH_SUCCESS,
  MUTUAL_ADDRESS_TRANSACTIONS_FETCH_FAILURE,
  MUTUAL_ADDRESS_TRANSACTIONS_FETCH_SUCCESS
} from "../actionNames";

export const fetchAddressSummaryEthereum = address => async (dispatch, getState) => {
  // First grab the address summary from redux
  const summary = getAddressSummary(getState(), address);

  // If the summary is already filled out, we don't need to fetch it again
  if (summary && summary["account_id"] === address) {
    return;
  }
  // If the data wasn't in the store, fetch it using the api
  dispatch({ type: FETCHING_ADDRESS_SUMMARY });
  try {
    const { data } = await axios.get(`${ETHEREUM_API}/address/${address}`);
    dispatch({
      type: ADDRESS_SUMMARY_FETCH_SUCCESS,
      address,
      data,
      name: "ethereum"
    });
  } catch (err) {
    dispatch({
      type: ADDRESS_SUMMARY_FETCH_FAILURE,
      address,
      name: "ethereum"
    });
    throw err;
  }
};

export const fetchAddressHistoricalEthereum = address => async (dispatch, getState) => {
  // First grab the address summary from redux
  const summary = getAddressSummary(getState(), address);

  // If the summary is already filled out, we don't need to fetch it again
  if (summary && (summary["histRecv"] !== null && summary["histSent"] !== null)) {
    return;
  }
  // If the data wasn't in the store, fetch it using the api
  dispatch({ type: FETCHING_ADDRESS_HISTORY_STATS });
  try {
    const { data } = await axios.get(`${ETHEREUM_API}/address/${address}/histprice`);
    dispatch({
      type: ADDRESS_HISTORY_STATS_SUCCESS,
      address,
      data,
      name: "ethereum"
    });
  } catch (err) {
    dispatch({
      type: ADDRESS_HISTORY_STATS_FAILURE,
      address,
      name: "ethereum"
    });
    throw err;
  }
};

export const fetchAddressTransactions = (address, token_account, filter = null) => async (
  dispatch,
  getState
) => {
  const transactions = getAddressTransactions(getState(), address, token_account, filter, true);
  // Setting key null interferes with URL building, instead use -1 as default
  // for when address is not currently in store and will be created in the reducer.
  let offset = 0;
  if (transactions) {
    offset = transactions["offset"];
  }
  // No need to fetch if offset is already negative so no more transactions
  if (offset < 0) {
    return;
  }

  // Get the current currency
  dispatch({ type: ADDRESS_TRANSACTION_TOKEN_FETCHING });
  let API = `${ETHEREUM_API}/address/${address}/transactions/token/${token_account}`;
  return new Promise((resolve, reject) => {
    axios
      .get(API, {
        params: {
          offset,
          ...(filter && filter.side && { side: filter.side })
        }
      })
      .then(({ data }) => {
        dispatch({
          type: ADDRESS_TRANSACTION_TOKEN_FETCH_SUCCESS,
          address,
          token_account: token_account,
          data,
          filter,
          name: "ethereum"
        });
        resolve();
      })
      .catch(e => {
        console.log(e);
        dispatch({
          type: ADDRESS_TRANSACTION_TOKEN_FAILURE,
          address,
          name: "ethereum"
        });
        reject(e);
      });
  });
};

export const fetchInternalTransactions = (address, filter) => async (dispatch, getState) => {
  const transactions = getInternalTransactions(getState(), address, filter, true);
  // Setting key null interferes with URL building, instead use -1 as default
  // for when address is not currently in store and will be created in the reducer.
  let offset = 0;
  if (transactions) {
    offset = transactions["offset"];
  }

  // No need to fetch if offset is already negative so no more transactions
  if (offset < 0) {
    return;
  }

  // Get the current currency
  dispatch({ type: ADDRESS_INTERNAL_TRANSACTIONS_FETCHING });
  let API = `${ETHEREUM_API}/address/${address}/internal-transactions`;
  return new Promise((resolve, reject) => {
    axios
      .get(API, {
        params: {
          offset,
          ...(filter && filter.side && { side: filter.side })
        }
      })
      .then(({ data }) => {
        dispatch({
          type: ADDRESS_INTERNAL_TRANSACTIONS_FETCH_SUCCESS,
          address,
          data,
          filter,
          name: "ethereum"
        });
        resolve();
      })
      .catch(e => {
        dispatch({
          type: ADDRESS_INTERNAL_TRANSACTIONS_FAILURE,
          address,
          name: "ethereum"
        });
        reject(e);
      });
  });
};

export const fetchMutualAddressTransactions = (source, target, token_account) => async (
  dispatch,
  getState
) => {
  const transactions = getAddressMutualTransactions(
    getState(),
    source,
    target,
    token_account,
    true
  );
  // Setting key null interferes with URL building, instead use -1 as default
  // for when address is not currently in store and will be created in the reducer.
  let offset = 0;
  if (transactions) {
    offset = transactions["offset"];
  }

  // Get the current currency
  dispatch({ type: FETCHING_MUTUAL_ADDRESS_TRANSACTIONS });
  let API = `${ETHEREUM_API}/graph/edge/token/${token_account}/${source}/${target}`;
  return new Promise((resolve, reject) => {
    axios
      .get(API, {
        params: {
          offset
        }
      })
      .then(({ data }) => {
        dispatch({
          type: MUTUAL_ADDRESS_TRANSACTIONS_FETCH_SUCCESS,
          source,
          target,
          token_account: token_account,
          data,
          name: "ethereum"
        });
        resolve();
      })
      .catch(e => {
        dispatch({
          type: MUTUAL_ADDRESS_TRANSACTIONS_FETCH_FAILURE,
          source,
          target,
          name: "ethereum"
        });
        reject(e);
      });
  });
};

export const fetchAddressTokens = address => async (dispatch, getState) => {
  const tokens = getAddressTokens(getState(), address, true);
  let offset = 0;
  if (tokens) {
    offset = tokens["offset"];
  }

  // Get the current currency
  dispatch({ type: ADDRESS_TOKEN_FETCHING });
  let API = `${ETHEREUM_API}/address/${address}/tokens`;
  return new Promise((resolve, reject) => {
    axios
      .get(API, {
        params: {
          offset
        }
      })
      .then(({ data }) => {
        dispatch({
          type: ADDRESS_TOKEN_FETCH_SUCCESS,
          address,
          data,
          name: "ethereum"
        });
        resolve();
      })
      .catch(e => {
        dispatch({
          type: ADDRESS_TOKEN_FAILURE,
          address,
          name: "ethereum"
        });
        reject(e);
      });
  });
};

export const fetchMutualAddressTokens = (source, target) => async (dispatch, getState) => {
  const tokens = getAddressMutualTokens(getState(), source, target, true);
  let offset = 0;
  if (tokens) {
    offset = tokens["offset"];
  }

  // Get the current currency
  dispatch({ type: FETCHING_MUTUAL_ADDRESS_TOKENS });
  let API = `${ETHEREUM_API}/graph/edge/tokens/${source}/${target}`;
  return new Promise((resolve, reject) => {
    axios
      .get(API)
      .then(({ data }) => {
        dispatch({
          type: MUTUAL_ADDRESS_TOKENS_FETCH_SUCCESS,
          source,
          target,
          data,
          name: "ethereum"
        });
        resolve();
      })
      .catch(e => {
        dispatch({
          type: MUTUAL_ADDRESS_TOKENS_FETCH_FAILURE,
          source,
          target,
          name: "ethereum"
        });
        reject(e);
      });
  });
};

export const fetchGraphAddressTransactionsEthereum = address => async (dispatch, getState) => {
  // Fetch the transactions
  dispatch({ type: FETCHING_GRAPH_ADDRESS_TRANSACTIONS });

  const { data } = await axios.put(`${ETHEREUM_API}/graph/address-transactions`, {
    address: address,
    ether_sent_offset: 0,
    ether_received_offset: 0,
    erc20_sent_offset: 0,
    erc20_received_offset: 0,
    erc721_sent_offset: 0,
    erc721_received_offset: 0
  });

  dispatch({
    type: ADDRESS_GRAPH_TRANSACTIONS_FETCH_SUCCESS,
    address,
    data,
    name: "ethereum"
  });
};
