/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { debounce } from "lodash";

import { getUnixTimestampFromDate } from "../../helpers";
import { FakeLinkSpan } from "../styled";
import { getAddressMutualWallets2, makeGetWalletMutualTransactions } from "../../selectors/address";
import {
  addressMutualTransactionsSetLargestFirst,
  addressMutualTransactionsSwapOrder,
  addressMutualWalletTransactionsSetDateRange,
  addressTransactionsSetDateRange,
  addressTransactionsSetLargestFirst,
  addressTransactionsSwapOrder,
  fetchAddressMutualWallets,
  fetchAddressTransactions,
  fetchAddressTransactionsBySatoshi,
  fetchMutualWalletTransactions,
  setScrollTop,
} from "../../actions/address";
import { ADDRESS_API } from "../../api";
import AddressTransactionsAll from "./AddressTransactionsAll";
import MutualWallets from "./MutualWallets";

/**
 * Table of transactions associated with an address.
 * Transaction rows displayed have date, link to detailed transactions,
 * and bitcoin.
 */
class AddressTransactions extends Component {
  state = {
    currentView: "transactions",
  };

  setTableScrollTop = debounce(() => {
    // const container = document.getElementById("addressTransactionsTable");
    // if (container == null) {
    //   return;
    // }
    // const { scrollTop } = container;
    // this.props.setScrollTop(this.props.address, scrollTop);
  }, 100);

  handleOpenDateSelectModal = () => this.setState({ showDateSelectModal: true });

  handleCloseDateSelectModal = () => this.setState({ showDateSelectModal: false });

  getDatesAsUnix = () => {
    const { startDate, endDate } = this.state.fields;
    return {
      startDateUnix: getUnixTimestampFromDate(startDate),
      endDateUnix: getUnixTimestampFromDate(endDate, 1, "days"),
    };
  };

  getTransactionLink = transactionHash => {
    const { handleOpenTransactionModal, currency } = this.props;
    if (handleOpenTransactionModal) {
      return (
        <FakeLinkSpan onClick={() => handleOpenTransactionModal(transactionHash)}>
          {transactionHash}
        </FakeLinkSpan>
      );
    }

    return (
      <Link to={{ pathname: `/${currency}/transaction/${transactionHash}` }}>
        {transactionHash}
      </Link>
    );
  };

  fetchAddressTransactions = () => {
    const { address, order } = this.props;
    const { startDateUnix, endDateUnix } = this.getDatesAsUnix();
    this.props.fetch(address, order, startDateUnix, endDateUnix);
  };

  handleSetWalletFilter = () => {
    if (this.state.currentView === "transactions") {
      this.setState({
        currentView: "mutualWallets",
        dateFilter: false,
        submittedStart: "Origin",
        submittedEnd: "Present",
      });
    } else {
      this.setState({
        currentView: "transactions",
        dateFilter: false,
        submittedStart: "Origin",
        submittedEnd: "Present",
      });
    }
  };

  render() {
    const { address, currency } = this.props;

    if (this.state.currentView === "transactions") {
      return (
        <AddressTransactionsAll
          address={address}
          apiBase={ADDRESS_API}
          maxHeight={700}
          getTransactionLink={this.props.getTransactionLink}
          handleOpenDateSelectModal={this.handleOpenDateSelectModal}
          handleCloseDateSelectModal={this.handleCloseDateSelectModal}
          handleSetWalletFilter={this.handleSetWalletFilter}
          currentView={this.state.currentView}
          showDateSelectModal={this.state.showDateSelectModal}
          currency={currency}
          convertPriceToUsd={this.props.convertPriceToUsd}
          currentUsdPrice={this.props.currentUsdPrice}
          toggleUsdDisplay={this.props.toggleUsdDisplay}
          showAsUsd={this.props.showAsUsd}
          historicalToUsd={this.props.historicalToUsd}
          toggleHistoricalUsd={this.props.toggleHistoricalUsd}
          showChange={this.props.showChange}
          toggleChange={this.props.toggleChange}
        />
      );
    }
    return (
      <MutualWallets
        sourceId={address}
        entityType={"address"}
        sourceTag={null}
        apiBase={ADDRESS_API}
        maxHeight={700}
        getTransactionLink={this.props.getTransactionLink}
        handleOpenDateSelectModal={this.handleOpenDateSelectModal}
        handleCloseDateSelectModal={this.handleCloseDateSelectModal}
        handleSetWalletFilter={this.handleSetWalletFilter}
        currentView={this.state.currentView}
        fetchMoreWallets={fetchAddressMutualWallets}
        fetchTransactions={fetchMutualWalletTransactions}
        getWallets={getAddressMutualWallets2}
        getMutualTransactions={makeGetWalletMutualTransactions}
        setDateRange={addressMutualWalletTransactionsSetDateRange}
        setLargest={addressMutualTransactionsSetLargestFirst}
        fetchMoreTransactions={fetchMutualWalletTransactions}
        swapOrder={addressMutualTransactionsSwapOrder}
        fromAddressView
        currency={currency}
        convertPriceToUsd={this.props.convertPriceToUsd}
        currentUsdPrice={this.props.currentUsdPrice}
        toggleUsdDisplay={this.props.toggleUsdDisplay}
        showAsUsd={this.props.showAsUsd}
        historicalToUsd={this.props.historicalToUsd}
        toggleHistoricalUsd={this.props.toggleHistoricalUsd}
        showChange={this.props.showChange}
        toggleChange={this.props.toggleChange}
      />
    );
  }
}

AddressTransactions.propTypes = {
  address: PropTypes.string.isRequired,
  fetch: PropTypes.func.isRequired,
  newestFirstNextKey: PropTypes.number,
  oldestFirstNextKey: PropTypes.number,
  order: PropTypes.number.isRequired,
  onSwapOrder: PropTypes.func.isRequired,
  onSetDate: PropTypes.func.isRequired,
  onSetLargestFirst: PropTypes.func.isRequired,
  // sets the max height of transaction table
  maxHeight: PropTypes.number.isRequired,
  // handles modal transitions in graph mode
  handleOpenTransactionModal: PropTypes.func,
  getTransactionLink: PropTypes.func.isRequired,
  byLargest: PropTypes.bool.isRequired,
  hasNextLargest: PropTypes.bool,
  setScrollTop: PropTypes.func.isRequired,
  convertPriceToUsd: PropTypes.func.isRequired,
  currentUsdPrice: PropTypes.number.isRequired,
  toggleUsdDisplay: PropTypes.func.isRequired,
  showAsUsd: PropTypes.bool.isRequired,
  historicalToUsd: PropTypes.bool.isRequired,
  toggleHistoricalUsd: PropTypes.func.isRequired,
  showChange: PropTypes.bool.isRequired,
  toggleChange: PropTypes.func.isRequired,
};

AddressTransactions.defaultProps = {
  newestFirstNextKey: null,
  oldestFirstNextKey: null,
  handleOpenTransactionModal: null,
  hasNextLargest: true,
};

const mapDispatchToProps = dispatch => ({
  __fetchByDate: (address, order, startDate = null, endDate = null, initial = false) => {
    dispatch(fetchAddressTransactions(address, order, startDate, endDate, initial));
  },
  __fetchBySatoshi: address => {
    dispatch(fetchAddressTransactionsBySatoshi(address, false));
  },
  onSetLargestFirst: address => {
    dispatch(addressTransactionsSetLargestFirst(address));
  },
  onSwapOrder: address => dispatch(addressTransactionsSwapOrder(address)),
  onSetDate: (address, startDate, endDate) =>
    dispatch(addressTransactionsSetDateRange(address, startDate, endDate)),
  setScrollTop: (address, scrollTop) => dispatch(setScrollTop(address, scrollTop)),
});

export default connect(mapDispatchToProps)(AddressTransactions);
