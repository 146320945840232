/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types/index";
import React, { Component } from "react";
import { Grid, Row, Tab, Tabs } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import WalletAddresses from "./WalletAddresses";
import WalletSummary from "./WalletSummary";
import WalletMentions from "../../Graph/WalletTags/WalletMentions.jsx";
import WalletTransactions from "./WalletTransactions";
import { WALLET_API } from "../../../api";
import {
  fetchWalletAddresses,
  fetchWalletMentions,
  fetchWalletSummary,
  fetchWalletTransactions
} from "../../../actions/wallet";
import {
  getTransactionsScrollTop,
  getWalletName,
  getWalletRisk,
  getWalletSummary
} from "../../../selectors/wallet";
import { createGraphInNewTabWithPreload } from "../../../actions/graph";
import withAddPreloadModals from "../../withAddPreloadModals";
import { getCurrency, getUsdForCoin } from "../../../selectors/currency";
import ClientModeConfig from "../../ClientModeConfig";
import { getClientMode } from "../../../selectors/applicationConfig";
import { convertPriceToUsd } from "../../../helpers";
import WalletNoteEditor from "../../NoteEditor/WalletNoteEditor";
import { RiskScoreBase } from "../../Risk/RiskScoreBase";

class Wallet extends Component {
  state = {
    usd: false,
    change: true,
    histUsd: false
  };

  componentDidMount() {
    const { walletId } = this.props;
    this.props.fetchWalletTags(walletId);
    this.props.fetchWalletAddresses(walletId);

    this.setTableScrollTop();
    // Disabled since this tends to lock up the database
    // this.props.fetchWalletAssociations(walletId);
  }

  componentDidUpdate(prevProps) {
    const { walletId: oldWalletId } = prevProps;
    const { walletId: newWalletId } = this.props;
    if (newWalletId !== oldWalletId) {
      this.props.fetchWalletAddresses(newWalletId);
      this.setTableScrollTop();
    }
  }

  setTableScrollTop = () => {
    // const container = document.getElementById("walletTransactionsTable");
    // if (this.props.transactionsScrollTop != null) {
    //   container.scrollTop = this.props.transactionsScrollTop;
    // }
  };

  getHelmet = key => {
    const { name } = this.props;
    const keys = {
      transactions: "Transactions",
      mentions: "Tags",
      notes: "Notes",
      risk: "Risk",
      addresses: "Addresses"
    };
    const tab = keys[key] ?? keys.addresses;

    return (
      <Helmet>
        <title>{`Wal ${name} ${tab}`}</title>
      </Helmet>
    );
  };

  getMentionsBadge = mentionsCount => {
    if (mentionsCount === 0) {
      return (
        <div>
          <p style={{ display: "inline", paddingRight: "4px" }}>Mentions</p>
          <span className="badge progress-bar-danger">{mentionsCount}</span>
        </div>
      );
    }
    return (
      <div>
        <p style={{ display: "inline", paddingRight: "4px" }}>Mentions</p>
        <span className="badge progress-bar-info">{mentionsCount}</span>
      </div>
    );
  };

  getRiskBadge = () => {
    const count = (this.props.risk && this.props.risk.level) ?? 0;
    return (
      <div>
        <p style={{ display: "inline", paddingRight: "4px" }}>Risk</p>
        <span className="badge progress-bar-danger">{count}</span>
      </div>
    );
  };

  disableMentions() {
    return this.props.mentionsCount === 0;
  }

  setUsdDisplay = value => {
    this.setState({
      usd: value,
      histUsd: false
    });
  };

  setChange = value => {
    this.setState({
      change: value
    });
  };

  setHistUsd = value => {
    this.setState({
      histUsd: value,
      usd: false
    });
  };

  render() {
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    const {
      walletId,
      tabKey,
      onTabSelect,
      getTransactionLink,
      getDateCell,
      getAddressLink,
      primaryTag,
      category
    } = this.props;

    return (
      <Grid>
        <Row>
          <WalletSummary
            walletId={walletId}
            primaryTag={primaryTag}
            addToGraph={() => this.props.getCreateGraphModal()}
            currency={this.props.currency}
            convertPriceToUsd={convertPriceToUsd}
            currentUsdPrice={this.props.usd}
            toggleUsdDisplay={this.setUsdDisplay}
            showAsUsd={this.state.usd}
            historicalToUsd={this.state.histUsd}
            toggleHistoricalUsd={this.setHistUsd}
            showChange={this.state.change}
            toggleChange={this.setChange}
            showTagChanges={this.props.showTagChanges === undefined || !!this.props.showTagChanges}
          />
        </Row>
        <Row style={{ paddingRight: "0", paddingTop: "0" }}>
          {this.getHelmet(tabKey)}
          <div className="col-md-12 col-lg-12">
            <Tabs
              className="inviteTabs"
              activeKey={tabKey}
              id="address-tab"
              onSelect={onTabSelect}
              animation={false}
              mountOnEnter
              unmountOnExit
            >
              <Tab
                eventKey="transactions"
                title={`${clientModeConfigObject.cap_word_for_collection} Transactions`}
              >
                <WalletTransactions
                  walletId={walletId}
                  apiBase={WALLET_API}
                  maxHeight={700}
                  getTransactionLink={getTransactionLink}
                  getDateCell={getDateCell}
                  graphView={false}
                  currency={this.props.currency}
                  convertPriceToUsd={convertPriceToUsd}
                  currentUsdPrice={this.props.usd}
                  showAsUsd={this.state.usd}
                  historicalToUsd={this.state.histUsd}
                />
              </Tab>

              <Tab eventKey="addresses" title="Addresses">
                <WalletAddresses
                  apiBase={WALLET_API}
                  walletId={walletId}
                  maxHeight={700}
                  getAddressLink={getAddressLink}
                  currency={this.props.currency}
                />
              </Tab>

              <Tab
                eventKey="mentions"
                title={this.getMentionsBadge(this.props.mentionsCount)}
                disabled={this.disableMentions()}
                style={{
                  backgroundColor: "var(--secondary-color)",
                  paddingBottom: "15px",
                  marginTop: "10px",
                  marginBottom: "50px"
                }}
              >
                <WalletMentions walletId={walletId} apiBase={WALLET_API} maxHeight={700} />
              </Tab>

              <Tab eventKey="notes" title="Notes">
                <WalletNoteEditor
                  wallet={primaryTag}
                  walletId={walletId}
                  mainLocationId={walletId}
                  mainLocation={"wallet"}
                ></WalletNoteEditor>
              </Tab>
              {this.props.risk && (
                <Tab eventKey="risk" title={this.getRiskBadge()}>
                  <RiskScoreBase type={"wallet"} results={this.props.risk} />
                </Tab>
              )}
            </Tabs>
          </div>
        </Row>
      </Grid>
    );
  }
}

Wallet.propTypes = {
  walletId: PropTypes.string.isRequired,
  primaryTag: PropTypes.string,
  tabKey: PropTypes.string.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  fetchWalletSummary: PropTypes.func.isRequired,
  fetchWalletTransactions: PropTypes.func.isRequired,
  fetchWalletTags: PropTypes.func.isRequired,
  fetchWalletAddresses: PropTypes.func.isRequired,
  fetchWalletMutualWallets: PropTypes.func.isRequired,
  // fetchWalletAssociations: PropTypes.func.isRequired,
  getTransactionLink: PropTypes.func.isRequired,
  getDateCell: PropTypes.func.isRequired,
  getAddressLink: PropTypes.func.isRequired,
  getCreateGraphModal: PropTypes.func.isRequired,
  mentionsCount: PropTypes.number.isRequired
};

Wallet.defaultProps = {
  primaryTag: null
};

const mapStateToProps = (state, { walletId }) => {
  const scrollTop = {
    transactionsScrollTop: getTransactionsScrollTop(state, walletId)
  };
  return {
    ...Object.assign(getWalletSummary(state, walletId, true), scrollTop),
    currency: getCurrency(state),
    clientMode: getClientMode(state),
    usd: getUsdForCoin(state),
    risk: getWalletRisk(state, walletId),
    ...getWalletName(state, walletId)
  };
};

const mapDispatchToProps = (dispatch, { walletId: wal }) => ({
  fetchWalletSummary: walletId => dispatch(fetchWalletSummary(walletId)),
  fetchWalletTags: walletId => dispatch(fetchWalletMentions(walletId, true)),
  fetchWalletAddresses: walletId => dispatch(fetchWalletAddresses(walletId, true)),
  // TODO wtf daniel this component is never used in the file but magically passed to withAddPreloadModals and used so confusing
  createGraph: (caseNumber, description, isPublic) => {
    dispatch(
      createGraphInNewTabWithPreload(caseNumber, description, isPublic, {
        preloadAddresses: [],
        preloadWallets: [wal],
        preloadTransactions: [],
        preloadUnclusteredTransactions: []
      })
    );
  }
});

const getPreloadInfo = ({ walletId }) => ({
  type: "wallet",
  item: walletId,
  processed: true
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAddPreloadModals(Wallet, getPreloadInfo));
