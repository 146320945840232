/*
 * Copyright 2018-2022  DecisionQ Information Operations, Inc. All Rights Reserved.
 */

import React from "react";
import { getTrxNodeColorFromWallet, getWalletColorFromWallet } from "../../../actions/graph";
import { numberWithCommas } from "../../../helpers";

export const style = (
  loopVisibility = "hidden",
  labelOpacity = "1",
  showAttributed = "visible",
  showAsUsd = false
) => {
  // Need to use this to pull the css style properties, for whatever reason cytoscape won't read from the css file
  const bodyStyles = window.getComputedStyle(document.body);
  const cssGraphBackground = bodyStyles.getPropertyValue("--graph-background-color");
  const cssGraphArrow = bodyStyles.getPropertyValue("--graph-arrow-color");
  const cssGraphSelect = bodyStyles.getPropertyValue("--graph-node-select");
  return [
    {
      selector: "node",
      style: {
        content: ele => ele.data("label") || ele.data("id"),
        "text-valign": "bottom",
        "text-halign": "center",
        label: "data(label)",
        color: "#333333",
        shape: "ellipse",
        "font-family": "Quicksand",
        "font-size": "18px",
        "font-weight": "bold",
        "background-color": ele =>
          getWalletColorFromWallet(
            ele.data("category"),
            ele.data("oldColor"),
            ele.data("manuallySetColor"),
            ele.data("balance")
          ),
        "background-image": ele => {
          if (ele.data("category") === "custom") {
            return `data:image/svg+xml;utf8,${encodeURIComponent(
              '<svg height="30px" width="30px" aria-hidden="true" focusable="false" data-prefix="far" data-icon="wallet" class="svg-inline--fa fa-wallet fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path stroke="black" stroke-width="9" stroke-opacity="1.0" fill="white" d="M461.2 128H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h384c8.84 0 16-7.16 16-16 0-26.51-21.49-48-48-48H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h397.2c28.02 0 50.8-21.53 50.8-48V176c0-26.47-22.78-48-50.8-48zM416 336c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path></svg>'
            )}`;
          }
          if (ele.data("inputCount") === 0) {
            return `data:image/svg+xml;utf8,${encodeURIComponent(
              '<svg height="30px" width="30px" aria-hidden="true" focusable="false" data-prefix="far" data-icon="snowflake" class="svg-inline--fa fa-snowflake fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path stroke="black" stroke-width="9" stroke-opacity="1.0" fill="white" d="M440.1 355.2l-39.2-23 34.1-9.3c8.4-2.3 13.4-11.1 11.1-19.6l-4.1-15.5c-2.2-8.5-10.9-13.6-19.3-11.3L343 298.2 271.2 256l71.9-42.2 79.7 21.7c8.4 2.3 17-2.8 19.3-11.3l4.1-15.5c2.2-8.5-2.7-17.3-11.1-19.6l-34.1-9.3 39.2-23c7.5-4.4 10.1-14.2 5.8-21.9l-7.9-13.9c-4.3-7.7-14-10.3-21.5-5.9l-39.2 23 9.1-34.7c2.2-8.5-2.7-17.3-11.1-19.6l-15.2-4.1c-8.4-2.3-17 2.8-19.3 11.3l-21.3 81-71.9 42.2v-84.5L306 70.4c6.1-6.2 6.1-16.4 0-22.6l-11.1-11.3c-6.1-6.2-16.1-6.2-22.2 0l-24.9 25.4V16c0-8.8-7-16-15.7-16h-15.7c-8.7 0-15.7 7.2-15.7 16v46.1l-24.9-25.4c-6.1-6.2-16.1-6.2-22.2 0L142.1 48c-6.1 6.2-6.1 16.4 0 22.6l58.3 59.3v84.5l-71.9-42.2-21.3-81c-2.2-8.5-10.9-13.6-19.3-11.3L72.7 84c-8.4 2.3-13.4 11.1-11.1 19.6l9.1 34.7-39.2-23c-7.5-4.4-17.1-1.8-21.5 5.9l-7.9 13.9c-4.3 7.7-1.8 17.4 5.8 21.9l39.2 23-34.1 9.1c-8.4 2.3-13.4 11.1-11.1 19.6L6 224.2c2.2 8.5 10.9 13.6 19.3 11.3l79.7-21.7 71.9 42.2-71.9 42.2-79.7-21.7c-8.4-2.3-17 2.8-19.3 11.3l-4.1 15.5c-2.2 8.5 2.7 17.3 11.1 19.6l34.1 9.3-39.2 23c-7.5 4.4-10.1 14.2-5.8 21.9L10 391c4.3 7.7 14 10.3 21.5 5.9l39.2-23-9.1 34.7c-2.2 8.5 2.7 17.3 11.1 19.6l15.2 4.1c8.4 2.3 17-2.8 19.3-11.3l21.3-81 71.9-42.2v84.5l-58.3 59.3c-6.1 6.2-6.1 16.4 0 22.6l11.1 11.3c6.1 6.2 16.1 6.2 22.2 0l24.9-25.4V496c0 8.8 7 16 15.7 16h15.7c8.7 0 15.7-7.2 15.7-16v-46.1l24.9 25.4c6.1 6.2 16.1 6.2 22.2 0l11.1-11.3c6.1-6.2 6.1-16.4 0-22.6l-58.3-59.3v-84.5l71.9 42.2 21.3 81c2.2 8.5 10.9 13.6 19.3 11.3L375 428c8.4-2.3 13.4-11.1 11.1-19.6l-9.1-34.7 39.2 23c7.5 4.4 17.1 1.8 21.5-5.9l7.9-13.9c4.6-7.5 2.1-17.3-5.5-21.7z"></path></svg>'
            )}`;
          } else {
            return `data:image/svg+xml;utf8,${encodeURIComponent(
              '<svg height="30px" width="30px" aria-hidden="true"  opacity="0.0" focusable="false" data-prefix="far" data-icon="snowflake" class="svg-inline--fa fa-snowflake fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path stroke="black" stroke-width="9" stroke-opacity="1.0" fill="white" d="M440.1 355.2l-39.2-23 34.1-9.3c8.4-2.3 13.4-11.1 11.1-19.6l-4.1-15.5c-2.2-8.5-10.9-13.6-19.3-11.3L343 298.2 271.2 256l71.9-42.2 79.7 21.7c8.4 2.3 17-2.8 19.3-11.3l4.1-15.5c2.2-8.5-2.7-17.3-11.1-19.6l-34.1-9.3 39.2-23c7.5-4.4 10.1-14.2 5.8-21.9l-7.9-13.9c-4.3-7.7-14-10.3-21.5-5.9l-39.2 23 9.1-34.7c2.2-8.5-2.7-17.3-11.1-19.6l-15.2-4.1c-8.4-2.3-17 2.8-19.3 11.3l-21.3 81-71.9 42.2v-84.5L306 70.4c6.1-6.2 6.1-16.4 0-22.6l-11.1-11.3c-6.1-6.2-16.1-6.2-22.2 0l-24.9 25.4V16c0-8.8-7-16-15.7-16h-15.7c-8.7 0-15.7 7.2-15.7 16v46.1l-24.9-25.4c-6.1-6.2-16.1-6.2-22.2 0L142.1 48c-6.1 6.2-6.1 16.4 0 22.6l58.3 59.3v84.5l-71.9-42.2-21.3-81c-2.2-8.5-10.9-13.6-19.3-11.3L72.7 84c-8.4 2.3-13.4 11.1-11.1 19.6l9.1 34.7-39.2-23c-7.5-4.4-17.1-1.8-21.5 5.9l-7.9 13.9c-4.3 7.7-1.8 17.4 5.8 21.9l39.2 23-34.1 9.1c-8.4 2.3-13.4 11.1-11.1 19.6L6 224.2c2.2 8.5 10.9 13.6 19.3 11.3l79.7-21.7 71.9 42.2-71.9 42.2-79.7-21.7c-8.4-2.3-17 2.8-19.3 11.3l-4.1 15.5c-2.2 8.5 2.7 17.3 11.1 19.6l34.1 9.3-39.2 23c-7.5 4.4-10.1 14.2-5.8 21.9L10 391c4.3 7.7 14 10.3 21.5 5.9l39.2-23-9.1 34.7c-2.2 8.5 2.7 17.3 11.1 19.6l15.2 4.1c8.4 2.3 17-2.8 19.3-11.3l21.3-81 71.9-42.2v84.5l-58.3 59.3c-6.1 6.2-6.1 16.4 0 22.6l11.1 11.3c6.1 6.2 16.1 6.2 22.2 0l24.9-25.4V496c0 8.8 7 16 15.7 16h15.7c8.7 0 15.7-7.2 15.7-16v-46.1l24.9 25.4c6.1 6.2 16.1 6.2 22.2 0l11.1-11.3c6.1-6.2 6.1-16.4 0-22.6l-58.3-59.3v-84.5l71.9 42.2 21.3 81c2.2 8.5 10.9 13.6 19.3 11.3L375 428c8.4-2.3 13.4-11.1 11.1-19.6l-9.1-34.7 39.2 23c7.5 4.4 17.1 1.8 21.5-5.9l7.9-13.9c4.6-7.5 2.1-17.3-5.5-21.7z"></path></svg>'
            )}`;
          }
        },
        "text-background-opacity": "1",
        "text-background-color": cssGraphBackground,
        "text-margin-y": "5px",
        "border-color": cssGraphArrow,
        "border-style": "solid",
        "border-width": 4,
        width: "50px",
        height: "50px"
      }
    },
    {
      selector: "node[type='transaction']",
      style: {
        content: ele => ele.data("label") || ele.data("id"),
        "text-valign": "bottom",
        "text-halign": "center",
        label: "data(label)",
        color: "#333333",
        shape: "rectangle",
        "font-family": "Quicksand",
        "font-size": "18px",
        "font-weight": "bold",
        "background-color": ele =>
          getTrxNodeColorFromWallet(ele.data("oldColor"), ele.data("manuallySetColor")),
        "background-image": ele => {
          if (ele.data("inputCount") === 0) {
            return `data:image/svg+xml;utf8,${encodeURIComponent(
              '<svg height="30px" width="30px" aria-hidden="true" focusable="false" data-prefix="far" data-icon="snowflake" class="svg-inline--fa fa-snowflake fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path stroke="black" stroke-width="9" stroke-opacity="1.0" fill="white" d="M440.1 355.2l-39.2-23 34.1-9.3c8.4-2.3 13.4-11.1 11.1-19.6l-4.1-15.5c-2.2-8.5-10.9-13.6-19.3-11.3L343 298.2 271.2 256l71.9-42.2 79.7 21.7c8.4 2.3 17-2.8 19.3-11.3l4.1-15.5c2.2-8.5-2.7-17.3-11.1-19.6l-34.1-9.3 39.2-23c7.5-4.4 10.1-14.2 5.8-21.9l-7.9-13.9c-4.3-7.7-14-10.3-21.5-5.9l-39.2 23 9.1-34.7c2.2-8.5-2.7-17.3-11.1-19.6l-15.2-4.1c-8.4-2.3-17 2.8-19.3 11.3l-21.3 81-71.9 42.2v-84.5L306 70.4c6.1-6.2 6.1-16.4 0-22.6l-11.1-11.3c-6.1-6.2-16.1-6.2-22.2 0l-24.9 25.4V16c0-8.8-7-16-15.7-16h-15.7c-8.7 0-15.7 7.2-15.7 16v46.1l-24.9-25.4c-6.1-6.2-16.1-6.2-22.2 0L142.1 48c-6.1 6.2-6.1 16.4 0 22.6l58.3 59.3v84.5l-71.9-42.2-21.3-81c-2.2-8.5-10.9-13.6-19.3-11.3L72.7 84c-8.4 2.3-13.4 11.1-11.1 19.6l9.1 34.7-39.2-23c-7.5-4.4-17.1-1.8-21.5 5.9l-7.9 13.9c-4.3 7.7-1.8 17.4 5.8 21.9l39.2 23-34.1 9.1c-8.4 2.3-13.4 11.1-11.1 19.6L6 224.2c2.2 8.5 10.9 13.6 19.3 11.3l79.7-21.7 71.9 42.2-71.9 42.2-79.7-21.7c-8.4-2.3-17 2.8-19.3 11.3l-4.1 15.5c-2.2 8.5 2.7 17.3 11.1 19.6l34.1 9.3-39.2 23c-7.5 4.4-10.1 14.2-5.8 21.9L10 391c4.3 7.7 14 10.3 21.5 5.9l39.2-23-9.1 34.7c-2.2 8.5 2.7 17.3 11.1 19.6l15.2 4.1c8.4 2.3 17-2.8 19.3-11.3l21.3-81 71.9-42.2v84.5l-58.3 59.3c-6.1 6.2-6.1 16.4 0 22.6l11.1 11.3c6.1 6.2 16.1 6.2 22.2 0l24.9-25.4V496c0 8.8 7 16 15.7 16h15.7c8.7 0 15.7-7.2 15.7-16v-46.1l24.9 25.4c6.1 6.2 16.1 6.2 22.2 0l11.1-11.3c6.1-6.2 6.1-16.4 0-22.6l-58.3-59.3v-84.5l71.9 42.2 21.3 81c2.2 8.5 10.9 13.6 19.3 11.3L375 428c8.4-2.3 13.4-11.1 11.1-19.6l-9.1-34.7 39.2 23c7.5 4.4 17.1 1.8 21.5-5.9l7.9-13.9c4.6-7.5 2.1-17.3-5.5-21.7z"></path></svg>'
            )}`;
          }
        },
        "text-background-opacity": "1",
        "text-background-color": cssGraphBackground,
        "text-margin-y": "5px",
        "border-color": cssGraphArrow,
        "border-style": "solid",
        "border-width": 4,
        width: "100px",
        height: "50px"
      }
    },
    {
      selector: "edge[type='default']",
      css: {
        "mid-target-arrow-shape": "triangle",
        "arrow-scale": 2,
        "target-arrow-shape": "triangle",
        "curve-style": "unbundled-bezier",
        label: ele =>
          showAsUsd
            ? ele.data("hist_usd") === null
              ? "N/A"
              : "$" + numberWithCommas(ele.data("hist_usd").toFixed(2))
            : ele.data("transactionCount"),
        color: "#333333",
        "font-size": "13px",
        "text-rotation": "autorotate",
        "text-opacity": labelOpacity,
        "text-background-opacity": "1",
        "text-background-color": cssGraphBackground,
        "text-margin-y": "-20px",
        "text-margin-x": "-20px",
        "font-family": "Quicksand",
        "font-weight": "bold",
        "line-color": ele => {
          return ele.data("manuallySetColor") !== undefined && ele.data("manuallySetColor") !== null
            ? ele.data("manuallySetColor")
            : cssGraphArrow;
        },
        "target-arrow-color": cssGraphArrow,
        "mid-target-arrow-color": cssGraphArrow,
        visibility: ele => (ele.data("attributed") === true ? showAttributed : "visible")
      }
    },
    {
      selector: "edge[type='transaction']",
      css: {
        "mid-target-arrow-shape": "triangle",
        "arrow-scale": 2,
        "target-arrow-shape": "triangle",
        "curve-style": "unbundled-bezier",
        label: "data(transactionCount)",
        color: "#333333",
        "font-size": "13px",
        "text-rotation": "autorotate",
        "text-opacity": labelOpacity,
        "text-background-opacity": "1",
        "text-background-color": cssGraphBackground,
        "text-margin-y": "-20px",
        "text-margin-x": "-20px",
        "font-family": "Quicksand",
        "font-weight": "bold",
        "line-color": ele =>
          ele.data("manuallySetColor") !== undefined && ele.data("manuallySetColor") !== null
            ? ele.data("manuallySetColor")
            : cssGraphArrow,
        "line-style": "dashed",
        "target-arrow-color": cssGraphArrow,
        "mid-target-arrow-color": cssGraphArrow
      }
    },
    {
      selector: ":loop",
      css: {
        visibility: loopVisibility,
        "curve-style": "unbundled-bezier",
        "text-opacity": labelOpacity,
        "text-valign": "top",
        "text-halign": "center",
        "font-size": "13px",
        label: "data(transactionCount)",
        "text-rotation": "autorotate",
        "text-background-opacity": "1",
        "text-background-color": cssGraphBackground
      }
    },
    {
      selector: "node:selected",
      style: {
        "border-color": cssGraphSelect,
        "border-style": "solid",
        "border-width": 4
      }
    },
    {
      selector: "edge:selected",
      style: {
        "line-color": cssGraphSelect,
        "target-arrow-color": cssGraphSelect,
        "mid-target-arrow-color": cssGraphSelect,
        "border-color": cssGraphSelect,
        "border-style": "solid",
        "border-width": 2
      }
    }
  ];
};
