/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";

import { numberWithCommas, satoshiToBitcoin } from "../../../helpers";
import { useSelector } from "react-redux";
import { getWalletName } from "../../../selectors/wallet";

const InputWalletRow = ({
  keyProp,
  walletId,
  satoshi,
  primaryTag,
  handleOpenWalletModal,
  getWalletLink,
  currency,
  convertPriceToUsd,
  toggleUsdDisplay,
  showAsUsd
}) => {
  const bitcoin = walletId !== null ? satoshiToBitcoin(satoshi) : null;
  const showWallet = walletId !== 0;

  const { name } = useSelector(state => {
    if (showWallet && walletId) {
      return getWalletName(state, walletId);
    } else {
      return {
        tagType: "coinbase",
        name: null
      };
    }
  });
  const walletTag = name || "Coinbase";

  // We don't want to exclude wallets that have a primaryTag of Coinbase (the exchange)
  let walletLink;
  if (walletTag === "Coinbase" && primaryTag == null) {
    walletLink = <p>Coinbase</p>;
  } else {
    walletLink = getWalletLink(walletId, walletTag, handleOpenWalletModal, currency);
  }

  return (
    <tr key={keyProp} className="inviteRowThin staticTableRow">
      <td>{showWallet ? walletLink : <span>Unclustered</span>}</td>
      <td>
        <div className="text-right">
          {showAsUsd ? "$" + numberWithCommas(convertPriceToUsd(bitcoin)) : bitcoin}
        </div>
      </td>
    </tr>
  );
};

export default InputWalletRow;

InputWalletRow.propTypes = {
  keyProp: PropTypes.string.isRequired,
  walletId: PropTypes.number,
  satoshi: PropTypes.number,
  primaryTag: PropTypes.string,
  handleOpenWalletModal: PropTypes.func,
  getWalletLink: PropTypes.func.isRequired
};

InputWalletRow.defaultProps = {
  primaryTag: null,
  handleOpenWalletModal: null,
  walletId: null,
  satoshi: null
};
