/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import {
  Alert,
  Button,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Row,
} from "react-bootstrap";
import validator from "validator";

import axios, { CSRF_ERROR_MESSAGE, PASSWORD_RESET_REQUEST_API } from "../api";
import LogoHeader from "./LogoHeader";

export default class PasswordResetRequest extends Component {
  state = {
    fields: {
      email: "",
    },
    notification: {
      type: null,
      message: "",
    },
  };

  onInputChange = e => {
    const { fields } = this.state;
    fields[e.target.id] = e.target.value;
    this.setState({ fields });
  };

  handleSubmit = async e => {
    e.preventDefault();
    if (!this.validateForm()) {
      this.setState({
        notification: {
          type: "danger",
          message: "Please enter a valid email",
        },
      });
      return;
    }
    await this.sendPasswordReset();
  };

  validateForm = () => {
    const { email } = this.state.fields;
    return validator.isEmail(email);
  };

  sendPasswordReset = async () => {
    const { email } = this.state.fields;

    try {
      await axios.post(PASSWORD_RESET_REQUEST_API, {
        email,
      });
      this.setState({
        notification: {
          type: "success",
          message: "Password reset link sent to email",
        },
      });
    } catch (err) {
      if (err.response.status === 403) {
        this.setState({
          notification: {
            type: "danger",
            message: CSRF_ERROR_MESSAGE,
          },
        });
        return;
      }

      throw err;
    }
  };

  handleDismiss = () =>
    this.setState({
      notification: {
        type: null,
        message: "",
      },
    });

  renderNotification = () => {
    const { type, message } = this.state.notification;
    if (type) {
      return (
        <Row>
          <Col lg={6} lgOffset={3}>
            <Alert bsStyle={type} onDismiss={this.handleDismiss}>
              {message}
            </Alert>
          </Col>
        </Row>
      );
    }
    return null;
  };

  render() {
    const { email } = this.state.fields;

    return (
      <Grid>
        {this.renderNotification()}
        <LogoHeader />
        <br />
        <Row>
          <Col lg={6} lgOffset={3}>
            <div className="page-header">
              <h1>Reset Password</h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} lgOffset={3}>
            <Form onSubmit={this.handleSubmit}>
              <FormGroup controlId="email">
                <ControlLabel>Email</ControlLabel>
                <FormControl
                  type="email"
                  value={email}
                  placeholder=""
                  onChange={this.onInputChange}
                />
              </FormGroup>
              <Button type="submit">Send password reset</Button>
            </Form>
          </Col>
        </Row>
      </Grid>
    );
  }
}
