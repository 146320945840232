/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import moment from "moment";
import React, { Component } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Modal,
  Row,
  Table
} from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { connect } from "react-redux";
import axios, { CUSTOM_TAGS_API, CUSTOM_WALLETS_API } from "../../api";
import { reactRouterHistoryShape } from "../../prop-types";
import { getCurrency } from "../../selectors/currency";
import ClientModeConfig from "../ClientModeConfig";
import { getClientMode } from "../../selectors/applicationConfig";
import WalletName from "../Wallet/CustomWallet/WalletName";
import { ShareButton } from "../Utils/ShareButton";
import { removeCustomTagSharedUser } from "../../actions/customTags";
import { CUSTOM_WALLET_DELETE_SUCCESS, WALLET_REMOVE_CUSTOM_TAG } from "../../actions/actionNames";
import { withSnackbar } from "notistack";
import history from "../history";
import { getEmail } from "../../selectors/authentication";
import { IconButton } from "@material-ui/core";
import { fetchCustomWalletSummary, removeCustomWalletSharedUser } from "../../actions/customWallet";

/**
 * List of custom wallets associated with user/is public with
 * modal for creating new custom wallets
 *
 * TODO rewrite component to use redux store for the wallet names
 */
class CustomWallets extends Component {
  state = {
    clusters: [], // custom wallets found, can be props
    showModal: false, // modal for create wallet
    title: ""
  };

  componentDidMount() {
    this.fetchWallets(); // fetch custom wallets for user
  }

  //TODO add notification for error handling
  fetchWallets = async () => {
    const { data } = await axios.get(CUSTOM_WALLETS_API(this.props.currency));
    this.setState({
      clusters: data
    });
  };

  openCreateWalletModal = () => this.setState({ showModal: true });

  closeCreateWalletModal = () => this.setState({ showModal: false });

  handleChange = e => {
    this.setState({ title: e.target.value });
  };

  /**
   * Sends request to create custom wallet using form data
   * and redirects to new custom wallet on success
   * @param {event} e
   * @returns {Promise<void>}
   *
   * TODO error notification on api failure
   */
  handleCreateWalletForm = async e => {
    e.preventDefault();
    const name = this.state.title.trim();

    try {
      const { data } = await axios.post(CUSTOM_WALLETS_API(this.props.currency), {
        name
      });

      const { clusterId } = data;
      if (clusterId) {
        this.props.history.push(`/${this.props.currency}/custom-wallet/${clusterId}`);
      }
    } catch (err) {
      throw err;
    }
  };

  /**
   * Handles when user shares the tag with another user
   * @param email email of the user to share to
   * @param clusterId
   */
  handleShare = async (email, clusterId) => {
    try {
      await axios.put(`${CUSTOM_WALLETS_API(this.props.currency)}/${clusterId}/share`, {
        email,
        permission: "view" // by default always view permission atm
      });
    } catch (err) {
      if (err && err.response && err.response.status === 403) {
        this.props.enqueueSnackbar(`Not authorized to share custom wallet`, {
          variant: "error"
        });
      } else {
        this.props.enqueueSnackbar("Error sharing custom wallet", {
          variant: "error"
        });
      }
      throw err;
    }
  };

  /**
   * Handles when user decides to stop sharing their tag with someone
   * @param email email of user to delete share reference
   * @param clusterID
   */
  handleDeleteShare = async (email, clusterID) => {
    await this.props.removeCustomWalletSharedUser(clusterID, email, this.props.enqueueSnackbar);
    this.setState({
      clusters: this.state.clusters.filter(({ clusterId }) => clusterId !== clusterID)
    });
  };

  /**
   * Gets list of users with share access to this custom tag
   */
  getSharedUsers = async clusterId => {
    try {
      const { data } = await axios.get(
        `${CUSTOM_WALLETS_API(this.props.currency)}/${clusterId}/share`
      );
      return data;
    } catch (err) {
      if (err && err.response && err.response.status === 403) {
        this.props.enqueueSnackbar(`Not authorized to access shared custom wallet`, {
          variant: "error"
        });
      } else {
        this.props.enqueueSnackbar("Error getting shared users custom wallet", {
          variant: "error"
        });
      }
      return [];
    }
  };

  render() {
    //TODO find cleaner way to represent this over all the files
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    const wallets = this.state.clusters.map(
      ({ clusterId, name, processing, creationDate, addressCount, transactionCount, email }) => {
        const creation = moment.unix(parseInt(creationDate, 10)).format("LLL");
        return (
          <tr className="inviteRow staticTableRow" key={clusterId}>
            <td>
              <Link to={`/${this.props.currency}/custom-wallet/${clusterId}`}>
                <WalletName
                  wallet_id={clusterId}
                  wallet_name={name}
                  custom={true}
                  loading={!processing}
                  fontsize={"12px"}
                  copyFontSize={"0px"}
                />
              </Link>
            </td>
            <td>{creation}</td>
            <td>{addressCount}</td>
            <td>{transactionCount}</td>
            <td>
              {this.props.user_email === email ? (
                <ShareButton
                  callback={email => this.handleShare(email, clusterId)}
                  getSharedUsers={() => this.getSharedUsers(clusterId)}
                  removeUser={email => this.handleDeleteShare(email, clusterId)}
                />
              ) : (
                // Not the owner of the tag so just show who owns it.
                <>
                  <>{`Owned by ${email}`}</>
                  <IconButton
                    size="small"
                    onClick={async () => {
                      await this.handleDeleteShare(this.props.user_email, clusterId);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="deleteButton"
                      style={{ fontSize: "20px" }}
                    />
                  </IconButton>
                </>
              )}
            </td>
          </tr>
        );
      }
    );

    return (
      <Grid>
        <Row>
          <Col lg={12}>
            <Button onClick={this.openCreateWalletModal} className="blueButton">
              <span>
                <FontAwesomeIcon icon={faPlus} />
                {"  "}
                Create {clientModeConfigObject.cap_word_for_collection}
              </span>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Table className="inviteTable">
              <thead>
                <tr>
                  <th>Entity</th>
                  <th>Creation Date</th>
                  <th>Address Count</th>
                  <th>Transaction Count</th>
                  <th />
                </tr>
              </thead>
              <tbody>{wallets}</tbody>
            </Table>
          </Col>
        </Row>
        <Modal show={this.state.showModal} onHide={this.closeCreateWalletModal}>
          <Modal.Header closeButton>
            <Modal.Title>{`Create Custom ${clientModeConfigObject.cap_word_for_collection}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleCreateWalletForm}>
              <FormGroup>
                <FormControl
                  type="text"
                  placeholder="Entity Name"
                  onChange={this.handleChange}
                  inputRef={ref => {
                    this.nameForm = ref;
                  }}
                  maxLength={50}
                />
              </FormGroup>
              <Button
                disabled={this.state.title.trim().length <= 0}
                type="submit"
                className="blueButton"
              >
                <span>
                  <FontAwesomeIcon icon={faPlus} />
                  {"  "}
                  Create Entity
                </span>
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </Grid>
    );
  }
}

CustomWallets.propTypes = {
  history: reactRouterHistoryShape.isRequired
};

const mapStateToProps = state => {
  return {
    user_email: getEmail(state),
    currency: getCurrency(state),
    clientMode: getClientMode(state)
  };
};

const mapDispatchToProps = dispatch => ({
  removeCustomWalletSharedUser: (walletId, email, enqueueSnackbar) =>
    dispatch(removeCustomWalletSharedUser(walletId, email, enqueueSnackbar))
});

export default withRouter(
  withSnackbar(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CustomWallets)
  )
);
