import { getGraph } from "../../selectors/ethereum/graph";
import { ethereumSetUpCytoscape, setUpCytoscape } from "./graphHelpers";
import {
  FETCHING_GRAPH,
  GRAPH_ADD_WALLET_TO_GRAPH,
  GRAPH_DELETE_WALLET_FROM_GRAPH,
  GRAPH_FETCH_FAILURE,
  GRAPH_FETCH_SUCCESS,
  GRAPH_SAVE_SUCCESS,
  GRAPH_SEARCH_FAILURE,
  GRAPH_SEARCH_LOADING,
  GRAPH_SEARCH_SUCCESS,
  GRAPH_UNDO_STACK_ADD,
  GRAPH_UNDO_STACK_GO_BACK,
  GRAPH_UNDO_STACK_GO_FORWARD,
  GRAPH_UPDATE_GRAPH_IN_REDUX,
  SET_GRAPH_CATEGORY_COLORS,
  SETTING_CURRENT_GRAPH
} from "../actionNames";
import axios, { GRAPH_API, SEARCH_API } from "../../api";
import history from "../../components/history";
import { getCurrentGraph, getGraphSaveInfo } from "../../selectors/graph";
import moment from "moment";
import { addNotification } from "../notification";
import { getCurrency } from "../../selectors/currency";
import { getAddressSummary, getName } from "../../selectors/ethereum/address";
import { fetchAddressSummaryEthereum } from "./address";
import {
  addUnclusteredTransactionNodeToGraph,
  addWalletToGraph,
  remakeNotes,
  saveGraph
} from "../graph";
import { setOriginalWalletTagInner, setWalletTagInner } from "../wallet";
import { clean_ethereum_address } from "../../components/Ethereum/helpers";

export const search = (query, graphId) => async (dispatch, getState) => {
  // Set the loading
  dispatch({
    type: GRAPH_SEARCH_LOADING,
    graphId: graphId.toString(),
    name: "ethereum"
  });

  if (query === "") {
    return;
  }
  try {
    const { data } = await axios.get(`${SEARCH_API("ethereum")}`, {
      params: { query: query, category: "address,tags,tokens,user_tags,ens" }
    });
    dispatch({
      type: GRAPH_SEARCH_SUCCESS,
      data,
      query,
      graphId: graphId.toString(),
      name: "ethereum"
    });
  } catch (err) {
    dispatch({
      type: GRAPH_SEARCH_FAILURE,
      graphId: graphId.toString(),
      name: "ethereum"
    });
    throw err;
  }
};

/**
 *
 * Dispatch action for fetching the graph for ethereum and sets it in cy as side effect
 * Changes graph to reflect attribution and category changes if needed.
 * Also sets the category colors for the graph key
 *
 * TODO check if it updates for new transactions between accounts already on graph
 * @param graphId
 * @param cy
 * @returns {(function(*, *): Promise<void>)|*}
 */
export const fetchGraph = (graphId, cy) => async (dispatch, getState) => {
  dispatch({
    type: FETCHING_GRAPH,
    graphId: graphId.toString(),
    name: "ethereum"
  });

  try {
    const { data } = await axios.get(`${GRAPH_API("ethereum")}/${graphId}`);

    // Setting category color data from graph call
    dispatch({
      type: SET_GRAPH_CATEGORY_COLORS,
      colors: data.colors,
      name: "ethereum"
    });
    // Set up cytoscape using fetched json and updating names/category
    let addressSet = [];
    if (data.graph) {
      const { graph: graphJson, colors } = data;
      addressSet = await ethereumSetUpCytoscape(graphJson, colors, cy).then(result => result); // this is actually an array...
    }
    // Successful graph fetching, sets graph in redux store
    dispatch({
      type: GRAPH_FETCH_SUCCESS,
      graphId: graphId.toString(),
      data,
      addresses: addressSet, // This will not include preload items
      name: "ethereum"
    });
    //Setting in redux store as current graph
    dispatch({
      type: SETTING_CURRENT_GRAPH,
      graphId: graphId.toString(),
      name: "ethereum"
    });
    // TODO add preloadAddresses
  } catch (err) {
    // On graph fetcing failure redirect to graph list page and send failure redux
    history.replace("/graph");
    dispatch({
      type: GRAPH_FETCH_FAILURE,
      graphId: graphId.toString(),
      name: "ethereum"
    });
    throw err;
  }
};

export const addNodeFromSearchResult = (addressId, graphId, cy) => async (dispatch, getState) => {
  await dispatch(fetchAddressSummaryEthereum(addressId));
  const addressSummary = getAddressSummary(getState(), addressId);

  const names = getName(addressSummary);
  const {
    input_count,
    output_count,
    first_transaction_id,
    internal_input_count,
    internal_output_count,
    creation_transaction_id,
    category
  } = addressSummary;

  await dispatch(
    addNodeToGraph(
      addressId,
      names.length > 0 ? names[0].name : addressId,
      null,
      null,
      graphId,
      cy,
      input_count,
      output_count,
      first_transaction_id,
      internal_input_count,
      internal_output_count,
      creation_transaction_id,
      category
    )
  );
};

/**
 * Adds account to ethereum graph using provided information
 * @param addressId address hash of account
 * @param label name of address to put on graph
 * @param x x coordinates, autogenerated if null
 * @param y y coordinates, autogenerated if null
 * @param graphId graph_id
 * @param cy cytoscape object to modify
 * Next ones are stats about the address
 * @param input_count
 * @param output_count
 * @param first_transaction_id
 * @param internal_input_count
 * @param internal_output_count
 * @param creation_transaction_id creation for contract addresses only

 * @param category Category information of the address
 * @param manuallySetColor Whether to overide default colors, null if don't care
 * @param modifyUndoStack boolean to add to undo stack if needed. True by default
 * @param notes To automatically put notes on by default empty by default
 * @param typeForEdges TODO might be unneeded
 * @returns {(function(*, *): Promise<null|undefined>)|*}
 */
export const addNodeToGraph = (
  addressId,
  label,
  x,
  y,
  graphId,
  cy,
  input_count,
  output_count,
  first_transaction_id,
  internal_input_count,
  internal_output_count,
  creation_transaction_id,
  category,
  manuallySetColor = null,
  modifyUndoStack = true,
  notes = "",
  typeForEdges = "default"
) => async (dispatch, getState) => {
  addressId = clean_ethereum_address(addressId.toString());
  // First check if the node is already in the graph
  if (cy.elements(`#${addressId}`).size() !== 0) {
    return null;
  }

  // If no coordinates are given, default to +- 50px away from the center of the current viewport
  // in both the x and y directions.
  let _x;
  let _y;
  if (x == null || y == null) {
    const { x1, x2, y1, y2 } = cy.extent();
    // The latter half of the following expression determines whether to multiply by pos or neg
    _x = (x1 + x2) / 2 + Math.random() * (Math.random() > 0.5 ? 150 : -150);
    _y = (y1 + y2) / 2 + Math.random() * (Math.random() > 0.5 ? 150 : -150);
  } else {
    _x = x;
    _y = y;
  }
  try {
    // Add the node to the cytoscape object
    cy.add({
      group: "nodes",
      data: {
        id: addressId,
        label: label || addressId,
        type: "default",
        input_count,
        output_count,
        first_transaction_id,
        internal_input_count,
        internal_output_count,
        creation_transaction_id,
        manuallySetColor,
        oldColor: category ? category.color : null // put category color if category exists
      },
      position: {
        x: _x,
        y: _y
      },
      selected: true
    });

    dispatch({
      type: GRAPH_ADD_WALLET_TO_GRAPH,
      graphId: graphId.toString(),
      address: addressId,
      name: "ethereum"
    });
    await getAndAddEdges(addressId, cy);
    dispatch(updateEthereumGraphInRedux(graphId, cy));

    if (modifyUndoStack) {
      // Add the node to the list of addresses in the redux store
      dispatch({
        type: GRAPH_UNDO_STACK_ADD,
        opType: "add",
        wallets: [
          {
            walletId: addressId,
            label: label || addressId.toString(),
            x,
            y,
            manuallySetColor,
            input_count,
            output_count,
            first_transaction_id,
            internal_input_count,
            internal_output_count,
            creation_transaction_id,
            notes,
            category
          }
        ],
        name: "ethereum"
      });
      // Save the graph
      await dispatch(saveGraph(graphId, cy));
    }
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const addEdgeToGraph = (
  inputAddress,
  outputAddress,
  transactionCountDict,
  transactionCount,
  cy,
  notes,
  manuallySetColor,
  type = "default"
) => {
  inputAddress = clean_ethereum_address(inputAddress.toString());
  outputAddress = clean_ethereum_address(outputAddress.toString());
  // Set the id
  const id = `${inputAddress}+${outputAddress}`;
  const edge = {
    group: "edges",
    data: {
      id,
      source: inputAddress,
      target: outputAddress,
      manuallySetColor,
      notes,
      transactionCountDict,
      transactionCount,
      type
    }
  };
  cy.add([edge]);
};

export const getAndAddEdges = async (addressId, cy) => {
  if (cy.nodes() === undefined) {
    return;
  }

  // Make an array of the address ids in the graph
  const addressIds = [];
  cy.nodes()
    .toArray()
    .forEach(node => {
      addressIds.push(node.data("id"));
    });

  // Fetch the edges
  const { data } = await axios.post(`${GRAPH_API("ethereum")}/edges`, {
    address: addressId,
    addresses: addressIds
  });

  // Add each of the edges to the graph
  data.forEach(({ input, output, values: { ether, erc20, erc721 } }) => {
    const totalSent = ether + erc20 + erc721;
    addEdgeToGraph(input, output, { ether, erc20, erc721 }, totalSent, cy, "", null, "transaction");
  });
};

export const updateEthereumGraphInRedux = (graphId, cy) => (dispatch, getState) => {
  // First check if the graph exists in redux from the initial fetch. If it does not, we don't want to 'save' it,
  // as that creates a redux entry for a graph that was never fetched. This seems to happen for graphs that take a long
  // time to fetch (10+ seconds)
  const graphExistsInRedux = getGraph(getState(), graphId);
  if (graphExistsInRedux !== undefined) {
    const graph = getGraphObject(cy);
    dispatch({
      type: GRAPH_UPDATE_GRAPH_IN_REDUX,
      graphId: graphId.toString(),
      graph,
      name: "ethereum"
    });
  }
};

export const getGraphObject = cy => {
  const graph = cy.json(); // cy is uncontrolled so must be passed
  delete graph.style; // we don't want to keep old styles

  return {
    graph,
    version: 1
  };
};

export const deleteAddressesFromGraph = (graphId, cy, elements, modifyUndoStack = true) => async (
  dispatch,
  getState
) => {
  const name = getCurrency(getState());

  if (modifyUndoStack) {
    const wallets = elements.map(address => {
      if (address.isNode()) {
        if (address.data("type") === "default") {
          return {
            walletId: address.data("id"),
            label: address.data("label"),
            x: address.position("x"),
            y: address.position("y"),
            manuallySetColor: address.data("manuallySetColor"),
            input_count: address.data("input_count"),
            output_count: address.data("output_count"),
            first_transaction_id: address.data("first_transaction_id"),
            internal_input_count: address.data("internal_input_count"),
            internal_output_count: address.data("internal_output_count"),
            creation_transaction_id: address.data("creation_transaction_id"),
            type: "default",
            notes: address.data("notes")
          };
        }
      }

      // These conditions only apply to edges
      return {
        walletId: address.data("id"),
        source: address.data("source"),
        target: address.data("target"),
        manuallySetColor: address.data("manuallySetColor"),
        notes: address.data("notes"),
        transactionCountDict: address.data("transactionCountDict"),
        transactionCount: address.data("transactionCount"),
        type: address.data("type")
      };
    });
    dispatch({
      type: GRAPH_UNDO_STACK_ADD,
      opType: "delete",
      wallets,
      name
    });
  }

  elements.forEach(address => {
    // wallet has backreference to graph that owns it.
    address.remove();

    if (address.note) {
      address.note.hide();
    }

    if (address.isNode()) {
      dispatch({
        type: GRAPH_DELETE_WALLET_FROM_GRAPH,
        graphId: graphId.toString(),
        address: address.data("id"),
        name
      });
    }
  });

  // Save the graph
  await dispatch(saveGraph(graphId, cy));
};

// Grabs the current change pointed at and applies the reverse the the cytoscape object.
export const undoGraphChange = (graphId, cy) => async (dispatch, getState) => {
  const currency = getCurrency(getState());

  const undoStackRecord = getState().getIn([currency, "graph", "view", "modals", "graphUndoStack"]);
  const showNotes = getState().getIn([currency, "graph", "view", "showNotes"]);
  const index = undoStackRecord.get("index");
  if (index === -1) {
    return;
  }
  const item = undoStackRecord.getIn(["stack", index]);

  // operations are **reversed** here!
  // wallets that were 'add'ed should be removed, while wallets that were 'delete'd
  // should be added back.
  const opType = item.get("opType");
  const addresses = item.get("wallets").toJS();

  if (opType === "add") {
    addresses.forEach(address => {
      // using getElementById because elements(:selector) would always truncate the id after the + on edges
      const element = cy.getElementById(`${address.walletId}`);
      if (element.note) {
        element.note.hide();
      }
      element.remove();
      dispatch({
        type: GRAPH_DELETE_WALLET_FROM_GRAPH,
        graphId: graphId.toString(),
        address: address.walletId,
        name: currency
      });
    });
  } else if (opType === "delete") {
    // Need to add all wallets and then all edges. Was getting exception before where it was trying to add an edge
    // before one of its nodes was added
    const nodes = [];
    const edges = [];
    addresses.forEach(address =>
      address.walletId.includes("+") ? edges.push(address) : nodes.push(address)
    );

    await Promise.all(
      nodes.map(address => {
        const {
          walletId,
          label,
          x,
          y,
          manuallySetColor,
          input_count,
          output_count,
          first_transaction_id,
          internal_input_count,
          internal_output_count,
          creation_transaction_id,
          notes,
          category
        } = address;
        return addNodeToGraph(
          walletId,
          label,
          x,
          y,
          graphId,
          cy,
          input_count,
          output_count,
          first_transaction_id,
          internal_input_count,
          internal_output_count,
          creation_transaction_id,
          category,
          manuallySetColor,
          false,
          notes
        )(dispatch, getState);
      })
    );

    // Add the edges to the graph
    edges.map(
      ({
        source,
        target,
        manuallySetColor,
        notes,
        transactionCountDict,
        transactionCount,
        type
      }) => {
        addEdgeToGraph(
          source,
          target,
          transactionCountDict,
          transactionCount,
          cy,
          notes,
          manuallySetColor,
          type
        );
      }
    );
  } else if (opType === "move") {
    addresses.forEach(wallet => {
      cy.elements(`#${wallet.walletId}`).position({
        x: wallet.prevX,
        y: wallet.prevY
      });
    });

    if (item.get("shouldRefit")) {
      cy.fit();
    }
  } else if (opType === "setTag") {
    addresses.forEach(wallet => {
      const { oldTag } = wallet.tagOperation;
      // Undo Operation is the same if the tag was deleted/changed.
      setWalletTagInner(cy.elements(`#${wallet.walletId}`), oldTag)(dispatch);
    });
  } else if (opType === "changeColor") {
    addresses.forEach(wallet => {
      const node = cy.getElementById(wallet.walletId);
      const { prevColor } = wallet;
      node.data({ manuallySetColor: prevColor });
      node.style({ [wallet.colorStyle]: prevColor });
    });
  } else if (opType === "setNotes") {
    addresses.forEach(wallet => {
      const node = cy.getElementById(wallet.walletId);
      const { prevNotes } = wallet;
      node.data({ notes: prevNotes });
    });
    remakeNotes(cy, showNotes);
  } else {
    throw new Error(`Unknown opType: ${opType}`);
  }

  dispatch({
    type: GRAPH_UNDO_STACK_GO_BACK,
    name: currency
  });

  const id = getCurrentGraph(getState());
  await dispatch(saveGraph(id, cy));
};

// Grabs the next change pointed to in the undo stack (cursor doesn't need to point
// to last item) and applies it to the cytoscape object.
export const redoGraphChange = (graphId, cy) => async (dispatch, getState) => {
  const currency = getCurrency(getState());
  const undoStackRecord = getState().getIn([currency, "graph", "view", "modals", "graphUndoStack"]);
  const showNotes = getState().getIn([currency, "graph", "view", "showNotes"]);
  const index = undoStackRecord.get("index");
  if (index === undoStackRecord.get("stack").size - 1) {
    return;
  }

  const item = undoStackRecord.getIn(["stack", index + 1]);
  const opType = item.get("opType");
  const addresses = item.get("wallets").toJS();

  if (opType === "add") {
    // Need to add all wallets and then all edges. Was getting exception before where it was trying to add an edge
    // before one of its nodes was added
    const nodes = [];
    const edges = [];
    addresses.forEach(address =>
      address.walletId.includes("+") ? edges.push(address) : nodes.push(address)
    );
    await Promise.all(
      nodes.map(address => {
        const {
          walletId,
          label,
          x,
          y,
          manuallySetColor,
          input_count,
          output_count,
          first_transaction_id,
          internal_input_count,
          internal_output_count,
          creation_transaction_id,
          notes,
          category
        } = address;
        return addNodeToGraph(
          walletId,
          label,
          x,
          y,
          graphId,
          cy,
          input_count,
          output_count,
          first_transaction_id,
          internal_input_count,
          internal_output_count,
          creation_transaction_id,
          category,
          manuallySetColor,
          false,
          notes
        )(dispatch, getState);
      })
    );
    // Add the edges to the graph
    edges.map(wallet => {
      const {
        source,
        target,
        manuallySetColor,
        notes,
        transactionCountDict,
        transactionCount,
        type
      } = wallet;
      addEdgeToGraph(
        source,
        target,
        transactionCountDict,
        transactionCount,
        cy,
        notes,
        manuallySetColor,
        type
      );
    });
  } else if (opType === "delete") {
    addresses.forEach(address => {
      // using getElementById because elements(:selector) would always truncate the id after the + on edges
      const element = cy.getElementById(`${address.walletId}`);
      if (element.note) {
        element.note.hide();
      }
      element.remove();
      dispatch({
        type: GRAPH_DELETE_WALLET_FROM_GRAPH,
        graphId: graphId.toString(),
        address: address.walletId,
        name: currency
      });
    });
  } else if (opType === "move") {
    addresses.forEach(address => {
      cy.elements(`#${address.walletId}`).position({
        x: address.x,
        y: address.y
      });
    });

    if (item.get("shouldRefit")) {
      cy.fit();
    }
  } else if (opType === "setTag") {
    addresses.forEach(address => {
      const { opType: tagOpType, newTag } = address.tagOperation;
      if (tagOpType === "change") {
        setWalletTagInner(cy.elements(`#${address.walletId}`), newTag)(dispatch);
      } else if (tagOpType === "delete") {
        // This redeletes the tag.
        setOriginalWalletTagInner(cy.elements(`#${address.walletId}`), newTag)(dispatch);
      }
    });
  } else if (opType === "changeColor") {
    addresses.forEach(address => {
      const node = cy.getElementById(address.walletId);
      const { color } = address;
      node.data({ manuallySetColor: color });
      node.style({ [address.colorStyle]: color });
    });
  } else if (opType === "setNotes") {
    addresses.forEach(address => {
      const node = cy.getElementById(address.walletId);
      const { notes } = address;
      node.data({ notes });
      remakeNotes(cy, showNotes);
    });
  } else {
    throw new Error(`Unknown opType: ${opType}`);
  }

  dispatch({
    type: GRAPH_UNDO_STACK_GO_FORWARD,
    name: currency
  });

  const id = getCurrentGraph(getState());
  await dispatch(saveGraph(id, cy));
};
