import { Box, Divider, Typography } from "@material-ui/core";
import * as React from "react";
import { ScrollDiv } from "../styled";

interface DefaultMessageContentProps {
  message: string;
  title?: string;
  divider?: boolean;
}
export const DefaultMessageContent = ({
  message,
  title,
  divider = true
}: DefaultMessageContentProps) => {
  return (
    <Box style={{ maxHeight: "34vh", overflow: "auto" }}>
      {title && (
        <Typography
          variant="h4"
          style={{
            whiteSpace: "pre-line",
            overflowWrap: "break-word",
            maxWidth: "inherit"
          }}
        >
          {title}
        </Typography>
      )}
      {divider ? <Divider style={{ marginBottom: 15 }} /> : <br />}
      <Typography
        variant="body1"
        style={{
          whiteSpace: "pre-line",
          overflowWrap: "break-word",
          maxWidth: "inherit"
        }}
      >
        {message}
      </Typography>
    </Box>
  );
};
