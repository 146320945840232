import axios, { ETHEREUM_API } from "../../api";
import {
  FETCHING_INTERNAL_TRANSACTIONS,
  FETCHING_TOKEN_TRANSFERS,
  FETCHING_TRANSACTION,
  INTERNAL_TRANSACTIONS_FETCH_FAILURE,
  INTERNAL_TRANSACTIONS_FETCH_SUCCESS,
  TOKEN_TRANSFERS_FETCH_FAILURE,
  TOKEN_TRANSFERS_FETCH_SUCCESS,
  TRANSACTION_SUMMARY_FETCH_FAILURE,
  TRANSACTION_SUMMARY_FETCH_SUCCESS
} from "../actionNames";
import {
  getInternalTransactions,
  getTransactionSummary
} from "../../selectors/ethereum/transaction";

export const fetchTransactionSummaryEthereum = transaction => async (dispatch, getState) => {
  // First grab the address summary from redux
  const summary = getTransactionSummary(getState(), transaction);

  // If the summary is already filled out, we don't need to fetch it again
  if (summary !== null) {
    return;
  }
  // If the data wasn't in the store, fetch it using the api
  dispatch({ type: FETCHING_TRANSACTION });
  try {
    const { data } = await axios.get(`${ETHEREUM_API}/transaction/${transaction}`);
    dispatch({
      type: TRANSACTION_SUMMARY_FETCH_SUCCESS,
      transaction,
      data,
      name: "ethereum"
    });
  } catch (err) {
    dispatch({
      type: TRANSACTION_SUMMARY_FETCH_FAILURE,
      transaction,
      name: "ethereum"
    });
    throw err;
  }
};

export const fetchTokenTransfersEthereum = transaction => async (dispatch, getState) => {
  // First grab the address summary from redux
  const summary = getTransactionSummary(getState(), transaction);

  // If the summary is already filled out, we don't need to fetch it again
  if (summary !== null) {
    return;
  }
  // If the data wasn't in the store, fetch it using the api
  dispatch({ type: FETCHING_TOKEN_TRANSFERS });
  try {
    const { data } = await axios.get(`${ETHEREUM_API}/transaction/${transaction}/tokens`);
    dispatch({
      type: TOKEN_TRANSFERS_FETCH_SUCCESS,
      transaction,
      data,
      name: "ethereum"
    });
  } catch (err) {
    dispatch({
      type: TOKEN_TRANSFERS_FETCH_FAILURE,
      transaction,
      name: "ethereum"
    });
    throw err;
  }
};

export const fetchInternalTransactions = transaction => async (dispatch, getState) => {
  // First grab the address summary from redux
  const internalTransactions = getInternalTransactions(getState(), transaction);

  // If the summary is already filled out, we don't need to fetch it again
  let offset = 0;
  if (internalTransactions) {
    offset = internalTransactions["offset"];
  }
  // If the data wasn't in the store, fetch it using the api
  dispatch({ type: FETCHING_INTERNAL_TRANSACTIONS });
  try {
    const { data } = await axios.get(`${ETHEREUM_API}/transaction/${transaction}/calls/${offset}`);
    dispatch({
      type: INTERNAL_TRANSACTIONS_FETCH_SUCCESS,
      transaction,
      data,
      name: "ethereum"
    });
  } catch (err) {
    dispatch({
      type: INTERNAL_TRANSACTIONS_FETCH_FAILURE,
      transaction,
      name: "ethereum"
    });
    throw err;
  }
};
