import React, { useState } from "react";
import CytoscapeComponent from "react-cytoscapejs";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { getCurrency } from "../../selectors/currency";
import CurrencyChooserConfig from "../CurrencyChooserConfig";
import { Modal } from "react-bootstrap";

function TransactionChainGraphDetailed(props) {
  const {
    transactionChain,
    nodeSelected,
    currency,
    onClickCloseDetailedHelp,
    onClickOpenDetailedHelp,
    showDetailedHelpModal
  } = props;
  const [width, setWith] = useState("100%"); // Width of the graph area
  const [height, setHeight] = useState(555); // Height of the graph area
  const [graphData, setGraphData] = useState({
    nodes: [],
    edges: []
  });

  // TODO chooser config for the currency with the print outs
  const [nodeSelectedCurrentGraph, setNodeSelectedCurrentGraph] = useState(null); // the node dictionary that is currently selected

  if (graphData.nodes.length === 0) {
    let newNodes = [];
    let newEdges = [];
    let newGraphData = graphData;
    const numHops = nodeSelected.split("_")[0];
    const tagLabel = nodeSelected.split("_")[1];
    const selectedChain = transactionChain[numHops][tagLabel];
    const selectedChainAmountPath = selectedChain["amountPath"][0];
    let positionMapping = {
      maxInputX: 500,
      maxInputY: 66,
      maxOutputX: 500 + 700,
      maxOutputY: 66
    };
    selectedChainAmountPath.map((transactionData, transactionIndex) => {
      let transactionInputs = transactionData["input"];
      let transactionOutputs = transactionData["output"];
      let otherAmountInput = 0; // Amount to associated with Other node on input side
      let otherAmountOutput = 0; // Amount to associate with Other node on output side

      // Create the boxes that will hold the nodes on the input side
      newNodes = newNodes.concat({
        data: {
          id: transactionIndex + "_parent_input",
          parent:
            transactionIndex +
            "_transaction_" +
            `${transactionChain[numHops][tagLabel]["transactions"][0][transactionIndex]}`,
          type: "parent"
        }
      });

      // Create the boxes that will hold the nodes on the output side
      newNodes = newNodes.concat({
        data: {
          id: transactionIndex + "_parent_output",
          parent:
            transactionIndex +
            "_transaction_" +
            `${transactionChain[numHops][tagLabel]["transactions"][0][transactionIndex]}`,
          type: "parent"
        }
      });

      // Go through all the addresses that are on the input side
      Object.keys(transactionInputs).map(transactionInputAddress => {
        if (transactionInputs[transactionInputAddress]["isMaj"]) {
          // If the address is classified as having the majority of funds/was the previously followed address, then
          // need to make sure that node is on the input node (not part of the Other category)
          if (transactionInputAddress.split(", ").length > 1) {
            // Deals with the case where the transaction contains multisig

            // This is the parent node that holds the nodes that are in the multisig
            newNodes = newNodes.concat({
              data: {
                id: transactionIndex + "_" + transactionInputAddress + "_input",
                label:
                  "(" +
                  `${Math.round(
                    transactionInputs[transactionInputAddress]["totalAmount"] * 10 ** -8 * 100
                  ) / 100}` +
                  " " +
                  CurrencyChooserConfig[currency]["abb"] +
                  ")",
                parent: transactionIndex + "_parent_input",
                btc_values: transactionInputs[transactionInputAddress]["totalAmount"] * 10 ** -8,
                type: "multiNodeParent"
              }
            });

            //
            newNodes = newNodes.concat({
              data: {
                id: transactionIndex + "_" + transactionInputAddress + "_input_node",
                label: `${transactionInputs[transactionInputAddress]["wallet_tag"] ||
                  transactionInputAddress
                    .split(", ")
                    .map(address => address.slice(0, 15) + "...\n")
                    .join(" ")}`,
                parent: transactionIndex + "_" + transactionInputAddress + "_input",
                type: "blackFont"
              },
              position: {
                x: positionMapping["maxInputX"],
                y: positionMapping["maxInputY"]
              }
            });
            positionMapping["maxInputY"] += 70;
          } else {
            newNodes = newNodes.concat({
              data: {
                id: transactionIndex + "_" + transactionInputAddress + "_input",
                label:
                  `${transactionInputs[transactionInputAddress]["wallet_tag"] ||
                    transactionInputAddress.slice(0, 15) + "..."}` +
                  " (" +
                  `${Math.round(
                    transactionInputs[transactionInputAddress]["totalAmount"] * 10 ** -8 * 100
                  ) / 100}` +
                  " " +
                  CurrencyChooserConfig[currency]["abb"] +
                  ")",
                parent: transactionIndex + "_parent_input",
                btc_values: transactionInputs[transactionInputAddress]["totalAmount"] * 10 ** -8
              },
              position: { x: positionMapping["maxInputX"], y: positionMapping["maxInputY"] }
            });
            positionMapping["maxInputY"] += 70;
          }
        } else {
          // If it is not part of the majority, then need to the amount to the "Other" node
          otherAmountInput += transactionInputs[transactionInputAddress]["totalAmount"];
        }
      });

      let majTransactionOutput = null; //tracks the majority transaction output (this is important for the tagging stuff)

      // Go through all the addresses that are on the output side of the transaction
      Object.keys(transactionOutputs).map(transactionOutputAddress => {
        // If the address is classified as having the majority of funds, then
        // need to make sure that node is on the output node (not part of the Other category)
        if (transactionOutputs[transactionOutputAddress]["isMaj"]) {
          majTransactionOutput = transactionOutputAddress;
          // Need to check for multisig
          if (transactionOutputAddress.split(", ").length > 1) {
            // This creates the parent node where the output multisig nodes will be
            newNodes = newNodes.concat({
              data: {
                id: transactionIndex + "_" + transactionOutputAddress + "_output",
                label:
                  "(" +
                  `${Math.round(
                    transactionOutputs[transactionOutputAddress]["totalAmount"] * 10 ** -8 * 100
                  ) / 100}` +
                  " " +
                  CurrencyChooserConfig[currency]["abb"] +
                  ")",
                parent: transactionIndex + "_parent_output",
                btc_values: transactionOutputs[transactionOutputAddress]["totalAmount"] * 10 ** -8,
                type: "multiNodeParent"
              }
            });

            newNodes = newNodes.concat({
              data: {
                id: transactionIndex + "_" + transactionOutputAddress + "_output_node",
                label: `${transactionOutputs[transactionOutputAddress]["wallet_tag"] ||
                  transactionOutputAddress
                    .split(", ")
                    .map(address => address.slice(0, 15) + "...\n")
                    .join(" ")}`,
                parent: transactionIndex + "_" + transactionOutputAddress + "_output",
                type: "blackFont"
              },
              position: {
                x: positionMapping["maxOutputX"],
                y: positionMapping["maxOutputY"]
              }
            });
            positionMapping["maxOutputY"] += 70;
          } else {
            // Creates the individual node that is categorized as the majority of funds
            newNodes = newNodes.concat({
              data: {
                id: transactionIndex + "_" + transactionOutputAddress + "_output",
                label:
                  `${transactionOutputs[transactionOutputAddress]["wallet_tag"] ||
                    transactionOutputAddress.slice(0, 15) + "..."}` +
                  " (" +
                  `${Math.round(
                    transactionOutputs[transactionOutputAddress]["totalAmount"] * 10 ** -8 * 100
                  ) / 100}` +
                  " " +
                  CurrencyChooserConfig[currency]["abb"] +
                  ")",
                parent: transactionIndex + "_parent_output",
                btc_values: transactionOutputs[transactionOutputAddress]["totalAmount"] * 10 ** -8
              },
              position: { x: positionMapping["maxOutputX"], y: positionMapping["maxOutputY"] }
            });
            positionMapping["maxOutputY"] += 70;
          }
        } else {
          // Since this is part of the output and there is a chance that the last node will not be the majority of
          // funds, we need to make sure to display those labeled nodes
          if (
            transactionIndex === selectedChainAmountPath.length - 1 &&
            transactionOutputs[transactionOutputAddress]["wallet_tag"]
          ) {
            newNodes = newNodes.concat({
              data: {
                id: transactionIndex + "_" + transactionOutputAddress + "_output",
                label:
                  `${transactionOutputs[transactionOutputAddress]["wallet_tag"] ||
                    transactionOutputAddress}` +
                  " (" +
                  `${Math.round(
                    transactionOutputs[transactionOutputAddress]["totalAmount"] * 10 ** -8 * 100
                  ) / 100}` +
                  " " +
                  CurrencyChooserConfig[currency]["abb"] +
                  ")",
                parent: transactionIndex + "_parent_output",
                btc_values: transactionOutputs[transactionOutputAddress]["totalAmount"] * 10 ** -8
              },

              position: {
                x: positionMapping["maxOutputX"],
                y: positionMapping["maxOutputY"]
              }
            });
            positionMapping["maxOutputY"] += 70;
          } else {
            // Otherwise, we want to put the amount towards the total amount for the "Other" node
            otherAmountOutput += transactionOutputs[transactionOutputAddress]["totalAmount"];
          }
        }
      });

      // Here we are dealing with the "Other" category
      if (otherAmountInput !== 0) {
        // Create the node for the "Other" category for input
        newNodes = newNodes.concat({
          data: {
            id: transactionIndex + "_Other_input",
            label:
              "Other" +
              " (" +
              `${Math.round(otherAmountInput * 10 ** -8 * 100) / 100}` +
              " " +
              CurrencyChooserConfig[currency]["abb"] +
              ")",
            parent: transactionIndex + "_parent_input",
            btc_values: otherAmountInput * 10 ** -8
          },
          position: {
            x: positionMapping["maxInputX"],
            y: positionMapping["maxInputY"]
          }
        });
      } else {
        positionMapping["maxInputY"] -= 70;
      }

      if (otherAmountOutput !== 0) {
        // Create the node for the "Other" category for output
        newNodes = newNodes.concat({
          data: {
            id: transactionIndex + "_Other_output",
            label:
              "Other" +
              " (" +
              `${Math.round(otherAmountOutput * 10 ** -8 * 100) / 100}` +
              " " +
              CurrencyChooserConfig[currency]["abb"] +
              ")",
            parent: transactionIndex + "_parent_output",
            btc_values: otherAmountOutput * 10 ** -8
          },
          position: {
            x: positionMapping["maxOutputX"],
            y: positionMapping["maxOutputY"]
          }
        });
      } else {
        positionMapping["maxOutputY"] -= 70;
      }

      // Creating the edges between the parent input and output
      newEdges = newEdges.concat({
        data: {
          source: transactionIndex + "_parent_input",
          target: transactionIndex + "_parent_output"
        }
      });

      // If this isn't the very last output, then we want to have an edges between the majority output node to the
      // parent input node
      if (transactionIndex < selectedChainAmountPath.length - 1) {
        newEdges = newEdges.concat({
          data: {
            source: transactionIndex + "_" + majTransactionOutput + "_output",
            target: `${transactionIndex + 1}` + "_parent_input",
            type: "transition"
          }
        });
      }

      // Creating the transaction parent node that encapsulates the input and output parent nodes
      newNodes = newNodes.concat({
        data: {
          id:
            transactionIndex +
            "_transaction_" +
            `${transactionChain[numHops][tagLabel]["transactions"][0][transactionIndex]}`,
          type: "transactionParent",
          label:
            "Transaction " +
            `${transactionIndex + 1}` +
            ": " +
            `${transactionChain[numHops][tagLabel]["transactions"][0][transactionIndex].slice(
              0,
              15
            )}` +
            "..."
        }
      });

      // Changes the position mapping to make sure there is space between the transaction parent blocks
      const differenceAddition = Math.abs(
        positionMapping["maxInputY"] - positionMapping["maxOutputY"]
      );
      if (positionMapping["maxInputY"] < positionMapping["maxOutputY"]) {
        positionMapping["maxInputY"] += 250 + differenceAddition;
        positionMapping["maxOutputY"] += 250;
      } else if (positionMapping["maxInputY"] > positionMapping["maxOutputY"]) {
        positionMapping["maxInputY"] += 250;
        positionMapping["maxOutputY"] += 250 + differenceAddition;
      } else {
        positionMapping["maxInputY"] += 250;
        positionMapping["maxOutputY"] += 250;
      }
    });

    // Updates the graph with the new nodes and edges
    newGraphData.nodes = newNodes;
    newGraphData.edges = newEdges;
    setGraphData(newGraphData);
  }

  // Creates the layout for the cytoscape object
  const layout = {
    name: "preset", // The name "preset" makes it so that we have to specify the position of the nodes
    fit: true,
    directed: true,
    padding: 50,
    animate: true,
    animationDuration: 1000,
    avoidOverlap: true,
    nodeDimensionsIncludeLabels: false
  };

  // The stylesheet for the cytoscape object. This has all the settings for the nodes and edges
  const styleSheet = [
    {
      selector: "node",
      style: {
        backgroundColor: "#608ca9",
        width: 30,
        height: 30,
        label: "data(label)",
        "overlay-padding": "6px",
        "z-index": "10",
        color: "#f9fafb",
        fontSize: 20,
        "text-wrap": "wrap"
      }
    },
    {
      selector: "node:selected",
      style: {
        "border-width": "4px",
        "border-color": "#AAD8FF",
        "background-color": "#77828C",
        color: "#f9fafb",
        width: 30,
        height: 30
      }
    },
    {
      selector: "node[type='parent']",
      style: {
        backgroundColor: "#253150"
      }
    },
    {
      selector: "node[type='transactionParent']",
      style: {
        backgroundColor: "#e1e4e7",
        color: "#081c29",
        fontSize: 30
      }
    },

    {
      selector: "node[type='multiNodeParent']",
      style: {
        backgroundColor: "#e1e4e7"
      }
    },
    {
      selector: "node[type='blackFont']",
      style: {
        color: "#081c29"
      }
    },
    {
      selector: "node[type='triangleBlackFont']",
      style: {
        shape: "triangle",
        color: "#081c29"
      }
    },
    {
      selector: "edge",
      style: {
        width: 3,
        label: "data(label)",
        "line-color": "#8eb7da",
        "target-arrow-color": "#8eb7da",
        "target-arrow-shape": "triangle",
        "curve-style": "bezier"
      }
    },
    {
      selector: "edge[type='transition']",
      style: {
        width: 3,
        label: "",
        "line-color": "#8eb7da",
        "target-arrow-color": "#8eb7da",
        "target-arrow-shape": "triangle",
        "curve-style": "bezier",
        "line-style": "dashed"
      }
    }
  ];

  // Cytoscape reference
  let myCyRef;

  return (
    <>
      <div>
        <div style={{ display: "flex" }}>
          <h1>Transaction Chain Details</h1>
          <FontAwesomeIcon
            icon={faQuestionCircle}
            style={{
              fontSize: "18px",
              marginTop: "23px",
              marginLeft: "5px",
              cursor: "pointer"
            }}
            onClick={onClickOpenDetailedHelp}
          />{" "}
        </div>
        {nodeSelectedCurrentGraph ? (
          nodeSelectedCurrentGraph.id.split("_")[1] === "parent" ? (
            <p>
              {nodeSelectedCurrentGraph.id
                .split("_")[2]
                .charAt(0)
                .toUpperCase() + nodeSelectedCurrentGraph.id.split("_")[2].slice(1)}
            </p>
          ) : nodeSelectedCurrentGraph.id.split("_")[1] === "transaction" ? (
            <p>
              Transaction Selected: {nodeSelectedCurrentGraph.id.split("_")[2]}{" "}
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                style={{ marginLeft: "8px", color: "var(--secondary-color)", cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    `/${currency}/transaction/${nodeSelectedCurrentGraph.id.split("_")[2]}`
                  );
                }}
              />
            </p>
          ) : (
            <p>
              Address Selected:{" "}
              {nodeSelectedCurrentGraph.btc_values
                ? nodeSelectedCurrentGraph.id.split("_")[1] +
                  " (" +
                  nodeSelectedCurrentGraph.btc_values +
                  " " +
                  CurrencyChooserConfig[currency]["abb"] +
                  ")"
                : nodeSelectedCurrentGraph.id.split("_")[1]}{" "}
              {nodeSelectedCurrentGraph.id.split("_")[1] !== "Other" &&
                nodeSelectedCurrentGraph.id.split("_")[1].split(", ").length === 1 && (
                  <FontAwesomeIcon
                    icon={faExternalLinkAlt}
                    style={{
                      marginLeft: "8px",
                      color: "var(--secondary-color)",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      window.open(
                        `/${currency}/address/${nodeSelectedCurrentGraph.id.split("_")[1]}`
                      );
                    }}
                  />
                )}
            </p>
          )
        ) : (
          <p>Nothing Selected</p>
        )}
        <div
          style={{
            border: "1px solid",
            backgroundColor: "#f5f6fe",
            height: height
          }}
        >
          <CytoscapeComponent
            elements={CytoscapeComponent.normalizeElements(graphData)}
            // pan={{ x: 200, y: 200 }}
            style={{ width: width, height: height }}
            zoomingEnabled={true}
            maxZoom={3}
            minZoom={0.1}
            autounselectify={false}
            boxSelectionEnabled={true}
            layout={layout}
            stylesheet={styleSheet}
            wheelSensitivity={0.3}
            cy={cy2 => {
              myCyRef = cy2;

              cy2.on("select", "node", evt => {
                cy2.edges(":selected").unselect();
                let node = evt.target;
                setNodeSelectedCurrentGraph(node.data());
              });

              cy2.on("unselect", "node", evt => {
                setNodeSelectedCurrentGraph(null);
              });
            }}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    currency: getCurrency(state)
  };
};
export default connect(
  mapStateToProps,
  null
)(TransactionChainGraphDetailed);
