/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonToolbar,
  DropdownMenu,
  Form,
  FormControl,
  FormGroup,
  Modal,
  OverlayTrigger,
  ToggleButton,
  ToggleButtonGroup
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faConciergeBell,
  faFlag,
  faPersonBooth
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import axios, { ISSUES_API } from "../api";
import { getFeature } from "../selectors/features";
import { FullWidthPopover } from "./styled";

export const ISSUE_TYPES = {
  attribution: "Attribution",
  performance: "Performance",
  suggestion: "Suggestion"
};

class ReportIssue extends Component {
  state = {
    showModal: false,
    memo: "",
    submitSuccess: false,
    issueType: "Attribution"
  };

  submitIssue = async e => {
    e.preventDefault();
    // Make the api call to report the issue
    const status = await axios.put(ISSUES_API(this.props.currency, "issues-user"), {
      entity_type_id: this.props.entityType,
      entity_id: this.props.entityName,
      memo: this.state.memo,
      issue_type: this.state.issueType
    });

    if (status.status === 200) {
      // Set the local state
      this.setState({
        submitSuccess: true
      });
    }
  };

  onChange(e) {
    this.setState({ memo: e.target.value });
  }

  render() {
    return (
      <>
        {this.props.issuesEnabled && (
          <>
            <OverlayTrigger
              overlay={
                <FullWidthPopover style={{ fontFamily: "Quicksand", fontWeight: "600" }}>
                  Support
                </FullWidthPopover>
              }
              placement="bottom"
              delayShow={300}
              delayHide={150}
            >
              <Button
                className={this.props.buttonClass}
                onClick={() => this.setState({ showModal: true })}
              >
                <FontAwesomeIcon icon={faConciergeBell} style={{ fontSize: "16px" }}>
                  {" "}
                </FontAwesomeIcon>{" "}
              </Button>
            </OverlayTrigger>

            <Modal
              show={this.state.showModal}
              onHide={() => this.setState({ showModal: false, submitSuccess: false, memo: "" })}
              className="issuesModal"
            >
              <Modal.Header closeButton style={{ borderBottom: "2px solid #aaa" }}>
                <Modal.Title className="entityTitle">Support Request</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.submitSuccess ? (
                  <div
                    style={{
                      alignContent: "center",
                      textAlign: "center",
                      margin: "auto",
                      width: "60%"
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{
                        color: "#4fb346",
                        width: "100%",
                        fontSize: "75px",
                        textAlign: "center",
                        display: "block",
                        marginBottom: "7px",
                        marginTop: "30px"
                      }}
                    >
                      {" "}
                    </FontAwesomeIcon>
                    <p
                      style={{
                        display: "block",
                        color: "#999",
                        fontFamily: "Quicksand, sans-serif",
                        fontSize: "30px",
                        marginBottom: "45px"
                      }}
                    >
                      {" "}
                      Issue Successfully Submitted!
                    </p>
                    <Button
                      className="greenButton"
                      onClick={() =>
                        this.setState({ showModal: false, submitSuccess: false, memo: "" })
                      }
                    >
                      {" "}
                      Dismiss{" "}
                    </Button>
                  </div>
                ) : (
                  <Form onSubmit={e => this.submitIssue(e)}>
                    <FormGroup>
                      <p className="entityPrompt"> Issue Category: </p>
                      <ButtonToolbar style={{ marginBottom: "20px" }}>
                        <ToggleButtonGroup
                          type="radio"
                          name="options"
                          defaultValue={ISSUE_TYPES.attribution}
                          onChange={e => this.setState({ issueType: e })}
                          className="toggleButtonGroup"
                        >
                          <ToggleButton value={ISSUE_TYPES.attribution}>
                            Attribution Support
                          </ToggleButton>
                          <ToggleButton value={ISSUE_TYPES.performance}>
                            Software Support
                          </ToggleButton>
                          <ToggleButton value={ISSUE_TYPES.suggestion}>
                            Investigation Support
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </ButtonToolbar>{" "}
                      <p className="entityPrompt">
                        {" "}
                        Please describe the issue you are experiencing:{" "}
                      </p>
                      <FormControl
                        componentClass="textarea"
                        placeholder="Issue Description"
                        onChange={e => this.onChange(e)}
                        style={{ resize: "vertical", width: "600px", fontFamily: "Quicksand" }}
                      />
                    </FormGroup>
                    <Button
                      type="submit"
                      className="greenButton"
                      disabled={this.state.memo.length === 0}
                    >
                      Submit
                    </Button>
                  </Form>
                )}
              </Modal.Body>
            </Modal>
          </>
        )}{" "}
      </>
    );
  }
}

ReportIssue.propTypes = {
  entityType: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired
};

ReportIssue.defaultProps = {
  buttonClass: "issueButton"
};

const mapStateToProps = state => {
  return {
    issuesEnabled: getFeature(state, "issues")
  };
};
export default connect(
  mapStateToProps,
  null
)(ReportIssue);
