/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, FormControl, FormGroup, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import ClientModeConfig from "../../ClientModeConfig";
import { getCurrency } from "../../../selectors/currency";
import { getClientMode } from "../../../selectors/applicationConfig";
import { connect } from "react-redux";
import Form from "react-bootstrap/lib/Form";
import { editCustomWalletName } from "../../../actions/customWallet";

class CustomWalletNameEdit extends Component {
  state = {
    showModal: false,
    value: "",
  };

  handleSetName = async e => {
    e.preventDefault();

    try {
      await this.props.setCustomWalletName(
        this.props.walletId,
        this.props.primaryTag,
        this.state.value
      );
      this.setState({ showModal: false, value: "" });
    } catch (err) {
      throw err;
    }
  };

  render() {
    const { clientMode } = this.props;
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
    };
    if (clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    return (
      <>
        <Button
          className="greenButton"
          onClick={() => this.setState({ showModal: true })}
          style={{ marginRight: "4px" }}
        >
          <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: "16px", marginRight: "7px" }}>
            {" "}
          </FontAwesomeIcon>{" "}
          {`Edit ${clientModeConfigObject.cap_word_for_collection} Name`}
        </Button>
        <Modal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false, value: "" })}
          className="issuesModal"
        >
          <Modal.Header closeButton style={{ borderBottom: "2px solid #aaa" }}>
            <Modal.Title className="entityTitle">
              {`Edit ${clientModeConfigObject.cap_word_for_collection} Name`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleSetName}>
              <FormGroup controlId="formBasicText">
                <FormControl
                  type="text"
                  style={{ display: "inline-block", verticalAlign: "middle" }}
                  value={this.state.value}
                  onChange={e => {
                    this.setState({ value: e.target.value });
                  }}
                  maxLength={50}
                />
              </FormGroup>
              <Button className="greenButton" type="submit">
                <FontAwesomeIcon icon={faSave} style={{ marginRight: "5px" }} /> Save
              </Button>
              <Button
                className="issueButton"
                onClick={() => this.setState({ showModal: false, value: "" })}
              >
                <FontAwesomeIcon icon={faTimes} style={{ marginRight: "5px" }} /> Cancel
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

CustomWalletNameEdit.propTypes = {
  primaryTag: PropTypes.string.isRequired,
  walletId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    currency: getCurrency(state),
    clientMode: getClientMode(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCustomWalletName: (wallet_id, primaryTag, newTag) =>
      dispatch(editCustomWalletName(wallet_id, primaryTag, newTag)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomWalletNameEdit);
