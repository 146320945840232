import { faCopy } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CopyText = ({
  text,
  color = "var(--secondary-color)",
  fontSize = "14px",
  marginLeft = "10px",
  marginRight = "10px",
}) => {
  return (
    <FontAwesomeIcon
      className="copyText"
      style={{ fontSize, color, marginLeft, marginRight }}
      onClick={() => navigator.clipboard.writeText(text)}
      icon={faCopy}
    />
  );
};

export default CopyText;
