/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import { getAddressResearchData, getAddressSummary } from "../selectors/address";

class ResearcherResults extends Component {
  render() {
    if (this.props.address === null || this.props.researchRawData === null) {
      return null;
    }
    return (
      <div
        style={{
          border: "2px solid var(--base-color)",
          borderRadius: "5px",
          padding: "10px",
          backgroundColor: "var(--base-color)",
          boxShadow: "2px 2px 2px grey",
        }}
      >
        <div
          style={{
            borderBottom: "1px solid white",
            marginBottom: "10px",
          }}
        >
          <p
            style={{
              color: "white",
              fontWeight: "600",
              fontFamily: "Quicksand, sans-serif",
              fontSize: "22px",
            }}
          >
            {" "}
            Results for {this.props.address}
          </p>
        </div>
        {this.props.summary !== null ? (
          <div
            style={{
              paddingLeft: "10px",
              borderLeft: "1px solid white",
            }}
          >
            <p
              style={{
                color: "white",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
                fontSize: "16px",
              }}
            >
              <span style={{ borderRadius: "10px", backgroundColor: "white", padding: "5px" }}>
                <p
                  style={{
                    display: "inline-block",
                    color: "var(--base-color)",
                    fontWeight: "700",
                    fontFamily: "Quicksand, sans-serif",
                    fontSize: "20px",
                    marginRight: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {this.props.summary.primaryTag || this.props.summary.walletId}
                </p>
              </span>
            </p>

            {this.props.researchRawData.length === 0 ? (
              <p
                style={{
                  color: "white",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                {" "}
                There is Currently No Raw Tag Data for this Address.
              </p>
            ) : (
              <Table
                style={{
                  color: "white",
                  backgroundColor: "var(--base-color)",
                  fontFamily: "Quicksand, sans-serif",
                }}
              >
                <th
                  style={{
                    color: "white",
                    backgroundColor: "var(--base-color)",
                    borderBottom: "1px solid white",
                  }}
                >
                  {" "}
                  Tag{" "}
                </th>
                <th
                  style={{
                    color: "white",
                    backgroundColor: "var(--base-color)",
                    borderBottom: "1px solid white",
                  }}
                >
                  {" "}
                  Source{" "}
                </th>
                <th
                  style={{
                    color: "white",
                    backgroundColor: "var(--base-color)",
                    borderBottom: "1px solid white",
                  }}
                >
                  {" "}
                  Category{" "}
                </th>
                {this.props.researchRawData.map(item => {
                  return (
                    <>
                      <tr>
                        <td>{item.tag} </td>
                        <td> {item.source} </td>
                        <td> {item.category} </td>
                      </tr>
                    </>
                  );
                })}
              </Table>
            )}
          </div>
        ) : (
          <p
            style={{
              color: "white",
              fontWeight: "600",
              fontFamily: "Quicksand, sans-serif",
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            {" "}
            Address not found
          </p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, { address }) => {
  return {
    summary: getAddressSummary(state, address),
    researchRawData: getAddressResearchData(state, address),
  };
};

ResearcherResults.propTypes = {
  currency: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  summary: PropTypes.object.isRequired,
  researchRawData: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ResearcherResults);
