import { FETCH_CURRENCY_SUCCESS } from "./actionNames";
import axios, { CURRENCY_API } from "../api";

export const fetchCurrencyUsdValues = () => async (dispatch, getState) => {
  // Fetch the current prices,
  const { data } = await axios.get(CURRENCY_API());

  dispatch({
    type: FETCH_CURRENCY_SUCCESS,
    data,
  });
};
