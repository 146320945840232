import Table from "react-bootstrap/lib/Table";
import CopyText from "../../CopyText";
import WrappedLink from "../../Utils/WrappedLink";
import React from "react";
import { numberWithCommas } from "../../../helpers";

export const TokenStatsTable = ({
  address,
  symbol,
  decimals,
  transfer_count,
  creation_transaction_id,
  token_standard,
  stateBased = false
}) => {
  return (
    <div style={{ width: "100%", marginLeft: "0", paddingBottom: "0" }}>
      <Table
        className="walletStatsTable purple"
        style={{
          width: "100%",
          tableLayout: "fixed",
          overflow: "auto",
          wordWrap: "break-word"
        }}
      >
        <thead>
          <th width={"30%"} />
          <th width={"70%"} />
        </thead>
        <tbody>
          <tr key="standard">
            <td className="header">Standard</td>
            <td className="text-left">
              {(token_standard && token_standard.toUpperCase()) || "N/A"}
            </td>
          </tr>
          <tr
            key="tokenSymbol"
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              paddingBottom: "0"
            }}
          >
            <td className="header">Symbol</td>
            <td className="text-left">
              {symbol && (
                <CopyText text={symbol} marginRight="10px" marginLeft="0" />
              )}
              {symbol || "N/A"}
            </td>
          </tr>
          <tr key="decimals">
            <td className="header">Decimals</td>
            <td className="text-left">{decimals}</td>
          </tr>
          <tr key="transfersCount">
            <td className="header">Total Transfers</td>
            <td className="text-left">{numberWithCommas(transfer_count)}</td>
          </tr>
          <tr
            key="contractAddr"
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              paddingBottom: "0"
            }}
          >
            <td className="header">Contract Address</td>
            <td className="text-left">
              <CopyText
                marginLeft={0}
                marginRight={2}
                text={address || "N/A"}
              />
              <WrappedLink
                entity={address}
                entityType={"address"}
                stateBased
                style={{ fontSize: "11px", fontWeight: "500" }}
              >
                {address || "N/A"}
              </WrappedLink>
            </td>
          </tr>
          <tr key="creationTrx">
            <td className="header" style={{ paddingBottom: "10px" }}>
              Contract Creation Trx
            </td>
            <td className="text-left">
              {!creation_transaction_id ? (
                "N/A"
              ) : (
                <>
                  <CopyText
                    text={creation_transaction_id}
                    marginRight="10px"
                    marginLeft="0"
                  />
                  <WrappedLink
                    entity={creation_transaction_id}
                    entityType={"transaction"}
                    stateBased={stateBased || false}
                    style={{ fontWeight: "500" }}
                  >
                    {creation_transaction_id}
                  </WrappedLink>
                </>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
