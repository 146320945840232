/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import { Alert, Button, Col, Form, FormControl, FormGroup, Grid, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CircularProgress } from "@material-ui/core";
import axios, { CHANGE_PASSWORD_API } from "../../api";
import PasswordRequirements from "../PasswordRequirements";
import { handleCheckMatch, handleCheckRequirements } from "../../helpers";

/**
 * Form used to change password
 * @extends React.Component
 */
class ChangePassword extends Component {
  state = {
    notification: null,
    specialCharacter: true,
    number: true,
    letter: true,
    loading: false,
    pwMatch: true,
    pwLength: true,
  };

  /**
   * Checks that the new password entered was entered correctly
   * in both fields and sends request to the server.
   * @param {event} e
   * @returns {Promise<void>}
   */
  handleChangePassword = async e => {
    e.preventDefault();
    const oldPassword = this.oldPassword.value;
    const newPassword1 = this.newPassword1.value;
    const newPassword2 = this.newPassword2.value;

    if (newPassword1 !== newPassword2) {
      this.setState({
        notification: "Passwords don't match",
      });
    } else {
      try {
        this.setState({
          loading: true,
        });

        await axios.post(CHANGE_PASSWORD_API, {
          oldPassword,
          newPassword: newPassword1,
          specialCharacter: true,
          number: true,
          letter: true,
          pwMatch: true,
        });

        this.setState({
          loading: false,
          notification: "Password successfully updated",
          specialCharacter: true,
          number: true,
          letter: true,
          pwMatch: true,
        });
      } catch (err) {
        if (err.response.status === 403) {
          this.setState({
            loading: false,
            notification: "Your Old Password was Incorrect",
            specialCharacter: true,
            number: true,
            letter: true,
            pwMatch: true,
          });
        } else {
          throw err;
        }
      }
    }
  };

  handleAlertDismiss = () => this.setState({ notification: null });

  showUpdateFailedAlert = () => {
    const { notification } = this.state;

    switch (notification) {
      case "Passwords don't match":
        return (
          <Row>
            <Col lg={6} lgOffset={3}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p className="alertText">Passwords don&apos;t match</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Password successfully updated":
        return (
          <Row>
            <Col lg={6} lgOffset={3}>
              <Alert bsStyle="success" onDismiss={this.handleAlertDismiss}>
                <p className="alertText">Password successfully updated</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Your Old Password was Incorrect":
        return (
          <Row>
            <Col lg={6} lgOffset={3}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p className="alertText">Your password was incorrect</p>
              </Alert>
            </Col>
          </Row>
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <div style={{ marginTop: "50px" }}>
        <Grid>
          <Helmet>
            <title>Change Password</title>
          </Helmet>
          {this.state.loading ? (
            <Row>
              <Col lg={6} lgOffset={3} style={{ textAlign: "center", paddingTop: "100px" }}>
                <CircularProgress size={200} style={{ color: "var(--base-color)" }} />
              </Col>
            </Row>
          ) : (
            <>
              {this.showUpdateFailedAlert()}
              <Row>
                <Col
                  lg={6}
                  lgOffset={3}
                  md={6}
                  mdOffset={3}
                  sm={6}
                  smOffset={3}
                  style={{
                    boxShadow: "0px 4px 12px 0px #666",
                    borderRadius: "10px",
                    paddingBottom: "20px",
                  }}
                >
                  <div className="changeUsernamePassword">
                    <h1 style={{ color: "var(--base-color)" }}> Change Password </h1>
                    <PasswordRequirements
                      pwMatch={this.state.pwMatch}
                      number={this.state.number}
                      letter={this.state.letter}
                      specialCharacter={this.state.specialCharacter}
                      pwLength={this.state.pwLength}
                    />
                    <Form onSubmit={this.handleChangePassword}>
                      <FormGroup>
                        <h3 style={{ color: "#666", fontWeight: "500" }}>Old Password</h3>
                        <FormControl
                          type="password"
                          inputRef={ref => {
                            this.oldPassword = ref;
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <h3 style={{ color: "#666", fontWeight: "500" }}>New Password</h3>
                        <FormControl
                          type="password"
                          inputRef={ref => {
                            this.newPassword1 = ref;
                          }}
                          onChange={() =>
                            handleCheckRequirements(
                              this.setState.bind(this),
                              this.newPassword1.value,
                              this.newPassword2.value
                            )
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <h3 style={{ color: "#666", fontWeight: "500" }}>Confirm New Password</h3>
                        <FormControl
                          type="password"
                          inputRef={ref => {
                            this.newPassword2 = ref;
                          }}
                          onChange={() =>
                            handleCheckMatch(
                              this.setState.bind(this),
                              this.newPassword1.value,
                              this.newPassword2.value
                            )
                          }
                        />
                      </FormGroup>
                      <Button
                        className="blueButton"
                        type="submit"
                        disabled={
                          this.state.letter ||
                          this.state.number ||
                          this.state.specialCharacter ||
                          this.state.pwMatch
                        }
                      >
                        Submit
                      </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Grid>
      </div>
    );
  }
}

export default withRouter(ChangePassword);
