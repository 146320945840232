import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getCurrency } from "../../../selectors/currency";
import { getClientMode } from "../../../selectors/applicationConfig";
import axios, { ADDRESS_API, UTXO_API, WALLET_API, WATCHLIST_API } from "../../../api";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Modal,
  Row,
  Tab,
  Table,
  Tabs
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye } from "@fortawesome/free-solid-svg-icons";
import { bitcoinToSatoshi, satoshiToBitcoin } from "../../../helpers";
import UtxoWatchlistModal from "./UtxoModal";
import { CircularProgress } from "@material-ui/core";
import WatchlistItem from "./WatchlistItem";
import ClientModeConfig from "../../ClientModeConfig";
import { CustomCheckbox } from "../../Utils/CustomCheckbox";
import { getWalletNameHelper } from "../../../selectors/wallet";

class Watchlist extends Component {
  state = {
    address_watchlist: {},
    wallet_watchlist: {},
    utxo_watchlist: {},
    current_watchlist_type: "Address",
    currentWatchlistItem: {
      id: null,
      input_trxs: [],
      output_trxs: [],
      pending_trxs: [],
      address: null,
      starting_block_id: null,
      ending_block_id: null,
      last_seen_block: null,
      max_merged_block: null,
      address_id: null,
      wallet_id: null,
      spend_trx: {},
      merged_into_wallet_id: null,
      tag: null,
      input_count: null,
      output_count: null,
      new_input_count: null,
      new_output_count: null,
      input_offset: null,
      output_offset: null,
      title: null,
      notes: null,
      mempool_tracking: false,
      sms_messaging: false
    },
    showInactiveAddressItems: false,
    showInactiveWalletItems: false,
    showInactiveUtxoItems: false,
    showModal: false,
    // This is a hacky state variable to force refresh the address modal after more addresses are fetched. Fix this later
    refresh: false,
    showAddAddressModal: false,
    showAddWalletModal: false,
    showAddUtxoModal: false,
    loading: false,
    notification: null
  };

  componentWillMount() {
    // fetch the data here. We're not using redux because we want to fetch for these on each mount anyways, so
    // there isn't any reason to cache it.
    this.fetchWatchlist();
  }

  fetchWatchlist = async () => {
    // Fetch the watchlist items from the api and store them in the local state
    try {
      const { data } = await axios.get(WATCHLIST_API(this.props.currency));
      this.setState({
        address_watchlist: data.address_watchlist || {},
        wallet_watchlist: data.wallet_watchlist || {},
        utxo_watchlist: data.utxo_watchlist || {}
      });
    } catch (err) {
      throw err;
    }
  };

  loadMore = async (item_type, item, input_or_output) => {
    try {
      const { data } = await axios.put(WATCHLIST_API(this.props.currency), {
        watchlist_item_type: item_type,
        offset: input_or_output === "input" ? item["input_offset"] : item["output_offset"],
        input_or_output: input_or_output,
        max_merged_block: item["max_merged_block"],
        starting_block_id: item["starting_block_id"],
        entity_id: item_type === "Wallet" ? item["wallet_id"] : item["address_id"]
      });

      const { items, more } = data;

      if (item_type === "Address") {
        let temp = this.state.address_watchlist;
        temp[item["id"]][input_or_output === "input" ? "input_trxs" : "output_trxs"] = temp[
          item["id"]
        ][input_or_output === "input" ? "input_trxs" : "output_trxs"].concat(items);

        temp[item["id"]][input_or_output === "input" ? "input_offset" : "output_offset"] = more
          ? temp[item["id"]][input_or_output === "input" ? "input_offset" : "output_offset"] +
            items.length
          : -1;

        this.setState({
          address_watchlist: temp,
          currentWatchlistItem: temp[item["id"]],
          refresh: !this.state.refresh
        });
      } else {
        let temp = this.state.wallet_watchlist;
        temp[item["id"]][input_or_output === "input" ? "input_trxs" : "output_trxs"] = temp[
          item["id"]
        ][input_or_output === "input" ? "input_trxs" : "output_trxs"].concat(items);
        temp[item["id"]][input_or_output === "input" ? "input_offset" : "output_offset"] = more
          ? temp[item["id"]][input_or_output === "input" ? "input_offset" : "output_offset"] +
            items.length
          : -1;
        this.setState({
          wallet_watchlist: temp,
          currentWatchlistItem: temp[item["id"]],
          refresh: !this.state.refresh
        });
      }
    } catch (err) {
      throw err;
    }
  };

  openWatchlistModal = async (id, item, entity_type) => {
    // If the item hasn't been seen, mark it as seen in the db

    if (entity_type === "UTXO") {
      try {
        await axios.put(WATCHLIST_API(this.props.currency) + "/modify", {
          watchlist_item_id: id,
          watchlist_item_type: "UTXO",
          last_seen_block: 0,
          merged: false
        });
      } catch (err) {
        throw err;
      }
    } else if (item["max_merged_block"] !== item["last_seen_block"]) {
      try {
        await axios.put(WATCHLIST_API(this.props.currency) + "/modify", {
          watchlist_item_id: id,
          watchlist_item_type: entity_type,
          last_seen_block: item["max_merged_block"],
          merged: entity_type === "address" ? false : item["merged_into_wallet_id"] !== null
        });
      } catch (err) {
        throw err;
      }
    }

    // Update the data in the watchlist element and show the modal
    if (entity_type === "Address") {
      let temp = this.state.address_watchlist;
      temp[id]["last_seen_block"] = temp[id]["max_merged_block"];
      temp[id]["seen"] = true;
      this.setState({
        address_watchlist: temp,
        showModal: true,
        currentWatchlistItem: temp[id]
      });
    } else if (entity_type === "Wallet") {
      let temp = this.state.wallet_watchlist;
      temp[id]["last_seen_block"] = temp[id]["max_merged_block"];
      temp[id]["seen"] = true;
      this.setState({
        wallet_watchlist: temp,
        showModal: true,
        currentWatchlistItem: temp[id]
      });
    } else {
      let temp = this.state.utxo_watchlist;
      temp[id]["seen"] = true;
      this.setState({
        utxo_watchlist: temp,
        showModal: true,
        currentWatchlistItem: temp[id]
      });
    }
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      currentWatchlistItem: {
        input_trxs: [],
        output_trxs: [],
        pending_trxs: [],
        spend_trx: {}
      }
    });
  };

  updateForStopWatching = (watchlist_data_type, id, block) => {
    if (watchlist_data_type === "Address") {
      // Create a temp version of the dict
      const temp = this.state.address_watchlist;
      temp[id]["ending_block_id"] = block === null ? null : temp[id]["max_merged_block"];
      temp[id]["last_seen_block"] = temp[id]["max_merged_block"];

      // replace the address watchlist with the temp dict
      this.setState({
        address_watchlist: temp
      });
    } else if (watchlist_data_type === "Wallet") {
      // Create a temp version of the dict
      const temp = this.state.wallet_watchlist;
      temp[id]["ending_block_id"] = block;
      temp[id]["last_seen_block"] = temp[id]["max_merged_block"];

      // replace the wallet watchlist with the temp dict
      this.setState({
        wallet_watchlist: temp
      });
    }
  };

  deleteItem = (watchlist_data_type, id) => {
    if (watchlist_data_type === "Address") {
      // Create a temp version of the dict
      const temp = this.state.address_watchlist;
      delete temp[id];

      // replace the address watchlist with the temp dict
      this.setState({
        address_watchlist: temp
      });
    } else if (watchlist_data_type === "Wallet") {
      // Create a temp version of the dict
      const temp = this.state.wallet_watchlist;
      delete temp[id];

      // replace the wallet watchlist with the temp dict
      this.setState({
        wallet_watchlist: temp
      });
    } else if (watchlist_data_type === "UTXO") {
      // Create a temp version of the dict
      const temp = this.state.utxo_watchlist;
      delete temp[id];

      // replace the utxo watchlist with the temp dict
      this.setState({
        utxo_watchlist: temp
      });
    }
  };

  setMempool = newMempoolValue => {
    const currentWatchlistItem = this.state.currentWatchlistItem;
    currentWatchlistItem.mempool_tracking = newMempoolValue;
    const id = currentWatchlistItem.id;
    const entityType = currentWatchlistItem.type;
    let temp;
    switch (entityType) {
      case "Address":
        temp = this.state.address_watchlist;
        temp[id].mempool_tracking = newMempoolValue;
        this.setState({ address_watchlist: temp });
        break;
      case "Wallet":
        temp = this.state.wallet_watchlist;
        temp[id].mempool_tracking = newMempoolValue;
        this.setState({ wallet_watchlist: temp });
        break;
      case "UTXO":
        temp = this.state.utxo_watchlist;
        temp[id].mempool_tracking = newMempoolValue;
        this.setState({ utxo_watchlist: temp });
        break;
    }
    this.setState({
      currentWatchlistItem: currentWatchlistItem,
      refresh: !this.state.refresh
    });
  };

  setSMS = newSMSValue => {
    const currentWatchlistItem = this.state.currentWatchlistItem;
    currentWatchlistItem.sms_messaging = newSMSValue;
    const id = currentWatchlistItem.id;
    const entityType = currentWatchlistItem.type;
    let temp;
    switch (entityType) {
      case "Address":
        temp = this.state.address_watchlist;
        temp[id].sms_messaging = newSMSValue;
        this.setState({ address_watchlist: temp });
        break;
      case "Wallet":
        temp = this.state.wallet_watchlist;
        temp[id].sms_messaging = newSMSValue;
        this.setState({ wallet_watchlist: temp });
        break;
      case "UTXO":
        temp = this.state.utxo_watchlist;
        temp[id].sms_messaging = newSMSValue;
        this.setState({ utxo_watchlist: temp });
        break;
    }
    this.setState({
      currentWatchlistItem: currentWatchlistItem,
      refresh: !this.state.refresh
    });
  };

  handleCloseAddWalletModal = () => {
    this.setState({
      showAddWalletModal: false,
      notification: null,
      loading: false
    });
  };

  handleCloseAddAddressModal = () => {
    this.setState({
      showAddAddressModal: false,
      notification: null,
      loading: false
    });
  };

  handleCloseAddUtxoModal = () => {
    this.setState({
      showAddUtxoModal: false,
      notification: null,
      loading: false
    });
  };

  /**
   * Cleans up input
   * Checks if address already in note.
   * Checks if address exists on the blockchain.
   * Adds address if it does exist.
   * @param {event} e
   * @returns {Promise<void>}
   */
  addAddressIfExists = async (e, activeAddresses) => {
    e.preventDefault();
    const address = this.addressForm.value.trim();

    if (address.length === 0) {
      return;
    }

    if (activeAddresses.includes(address)) {
      this.setState({
        notification: "Address already in Watchlist."
      });
      return;
    }

    // Get the spinning wheel going
    this.setState({
      loading: true
    });

    try {
      // See if the address exists
      await axios.get(`${ADDRESS_API(this.props.currency)}/${address}/exists`);
    } catch (err) {
      if (err.response.status === 404) {
        this.setState({
          notification: "Address does not exist"
        });
      } else {
        throw err;
      }
    }

    const status = await axios.post(WATCHLIST_API(this.props.currency), {
      watchlist_item_type: "Address",
      address: address,
      title: "",
      notes: ""
    });

    if (status.data === "true") {
      // Set the local state
      this.setState({
        notification: "Successfully added to watchlist.",
        loading: false
      });
    } else {
      this.setState({
        notification: "Add to Watchlist Failed. Please try again later.",
        loading: false
      });
    }

    // Refetch the watchlist now that things have changed
    await this.fetchWatchlist();
  };

  addWalletIfExists = async (e, wallets) => {
    e.preventDefault();
    let wallet = this.walletForm.value.trim();

    if (!wallet) {
      return;
    }

    this.setState({
      loading: true
    });

    try {
      const { data } = await axios.get(`${WALLET_API(this.props.currency)}/${wallet}/exists`);
      if (data.unique === false) {
        this.setState({
          notification: "Entity specified was not unique. Please use a wallet id instead.",
          loading: false
        });
        return;
      } else if (wallets.includes(data.walletId)) {
        this.setState({
          notification: "Wallet already in Watchlist.",
          loading: false
        });
        return;
      } else {
        wallet = data.walletId;
      }
    } catch (err) {
      if (err.response.status === 404) {
        this.setState({
          notification: "Entity does not exist",
          loading: false
        });
        return;
      } else {
        throw err;
      }
    }

    const status = await axios.post(WATCHLIST_API(this.props.currency), {
      watchlist_item_type: "Wallet",
      wallet_id: wallet,
      title: "",
      notes: ""
    });

    if (status.data === "true") {
      // Set the local state
      this.setState({
        notification: "Successfully added to watchlist.",
        loading: false
      });
    } else {
      this.setState({
        notification: "Add to Watchlist Failed. Please try again later.",
        loading: false
      });
    }

    // Refetch the watchlist now that things have changed
    await this.fetchWatchlist();
  };

  addUtxoIfExists = async (e, active_utxos) => {
    e.preventDefault();

    let title = this.utxoFormTitle.value.trim();
    let notes = this.utxoFormNotes.value.trim();
    let address = this.utxoFormAddress.value.trim();
    let bitcoin = this.utxoFormBitcoin.value.trim();
    let satoshi = bitcoinToSatoshi(bitcoin);
    let trx = this.utxoFormTrx.value.trim();

    if (!address || !bitcoin || !trx) {
      return;
    }

    this.setState({
      loading: true
    });

    let exists = false;

    // First check if the utxo is already in the watchlist
    active_utxos.forEach(item => {
      if (
        item["address"] === address &&
        item["bitcoin"] === bitcoin &&
        item["transaction_hash"] === trx
      ) {
        exists = true;
      }
    });

    if (exists) {
      this.setState({
        notification: "This UTXO is already in your Watchlist.",
        loading: false
      });
      return;
    }

    try {
      await axios.put(`${UTXO_API(this.props.currency)}/exists`, {
        address: address,
        satoshi: satoshi,
        trx_hash: trx
      });
    } catch (err) {
      this.setState({
        notification: "This UTXO does not exist, please double check and try again.",
        loading: false
      });
      return;
    }

    const status = await axios.post(WATCHLIST_API(this.props.currency), {
      watchlist_item_type: "UTXO",
      transaction_hash: trx,
      address: address,
      satoshi: parseFloat(satoshi),
      title: title,
      notes: notes
    });

    if (status.data === "true") {
      // Set the local state
      this.setState({
        notification: "Successfully added to watchlist.",
        loading: false
      });
    } else {
      this.setState({
        notification: "Add to Watchlist Failed. Please try again later.",
        loading: false
      });
    }

    // Refetch the watchlist now that things have changed
    await this.fetchWatchlist();
  };

  updateTitle = async (id, title, address_wallet_utxo) => {
    const entityType = address_wallet_utxo.toLowerCase();
    if (entityType === "address") {
      await axios.put(WATCHLIST_API(this.props.currency) + "/modify2", {
        watchlist_item_id: id,
        watchlist_item_type: "Address",
        title_or_notes: "title",
        content: title
      });

      let temp = this.state.address_watchlist;
      temp[id]["title"] = title;
      this.setState({
        address_watchlist: temp,
        currentWatchlistItem: temp[id],
        refresh: !this.state.refresh
      });
    } else if (entityType === "wallet") {
      await axios.put(WATCHLIST_API(this.props.currency) + "/modify2", {
        watchlist_item_id: id,
        watchlist_item_type: "Wallet",
        title_or_notes: "title",
        content: title
      });

      let temp = this.state.wallet_watchlist;
      temp[id]["title"] = title;
      this.setState({
        wallet_watchlist: temp,
        currentWatchlistItem: temp[id],
        refresh: !this.state.refresh
      });
    } else {
      await axios.put(WATCHLIST_API(this.props.currency) + "/modify2", {
        watchlist_item_id: id,
        watchlist_item_type: "UTXO",
        title_or_notes: "title",
        content: title
      });

      let temp = this.state.utxo_watchlist;
      temp[id]["title"] = title;
      this.setState({
        utxo_watchlist: temp,
        currentWatchlistItem: temp[id],
        refresh: !this.state.refresh
      });
    }
  };

  updateNotes = async (id, note, address_wallet_utxo) => {
    const entityType = address_wallet_utxo.toLowerCase();
    if (entityType === "address") {
      try {
        await axios.put(WATCHLIST_API(this.props.currency) + "/modify2", {
          watchlist_item_id: id,
          watchlist_item_type: "Address",
          title_or_notes: "notes",
          content: note
        });

        let temp = this.state.address_watchlist;
        temp[id]["notes"] = note;
        this.setState({
          address_watchlist: temp,
          currentWatchlistItem: temp[id],
          refresh: !this.state.refresh
        });
        return true;
      } catch (e) {
        return false;
      }
    } else if (entityType === "wallet") {
      try {
        await axios.put(WATCHLIST_API(this.props.currency) + "/modify2", {
          watchlist_item_id: id,
          watchlist_item_type: "Wallet",
          title_or_notes: "notes",
          content: note
        });

        let temp = this.state.wallet_watchlist;
        temp[id]["notes"] = note;
        this.setState({
          wallet_watchlist: temp,
          currentWatchlistItem: temp[id],
          refresh: !this.state.refresh
        });
        return true;
      } catch (e) {
        return false;
      }
    } else {
      try {
        await axios.put(WATCHLIST_API(this.props.currency) + "/modify2", {
          watchlist_item_id: id,
          watchlist_item_type: "UTXO",
          title_or_notes: "notes",
          content: note
        });

        let temp = this.state.utxo_watchlist;
        temp[id]["notes"] = note;
        this.setState({
          utxo_watchlist: temp,
          currentWatchlistItem: temp[id],
          refresh: !this.state.refresh
        });
        return true;
      } catch (e) {
        return false;
      }
    }
  };

  render() {
    // Handle the address data
    const address_rows_active_with_updates = [];
    const address_rows_active_without_updates = [];
    const address_rows_inactive = [];
    const active_addresses = [];

    Object.keys(this.state.address_watchlist).forEach(key => {
      const value = this.state.address_watchlist[key];
      // If the item hasn't been resolved yet then we add it to the active list
      if (value["ending_block_id"] === null) {
        active_addresses.push(value["address"]);
        if (
          (value["input_trxs"].length === 0 && value["output_trxs"].length === 0) ||
          value["seen"] === true
        ) {
          address_rows_active_without_updates.push(
            <tr
              key={key}
              onClick={() => this.openWatchlistModal(key, value, "Address")}
              className="inviteRow hoverableTableRow"
            >
              <td></td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["title"] || value["address"]}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["starting_block_id"]}{" "}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}> --</td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["new_input_count"] > 99 ? "100+" : value["new_input_count"]}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["new_output_count"] > 99 ? "100+" : value["new_output_count"]}{" "}
              </td>
            </tr>
          );
        } else {
          address_rows_active_with_updates.push(
            <tr
              key={key}
              onClick={() => this.openWatchlistModal(key, value, "Address")}
              className="inviteRow hoverableTableRow"
            >
              <td>
                <span className="seenDot"></span>
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["title"] || value["address"]}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["starting_block_id"]}{" "}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}> --</td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["new_input_count"] > 99 ? "100+" : value["new_input_count"]}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["new_output_count"] > 99 ? "100+" : value["new_output_count"]}{" "}
              </td>
            </tr>
          );
        }
      } else {
        address_rows_inactive.push(
          <tr
            key={key}
            onClick={() => this.openWatchlistModal(key, value, "Address")}
            className="inviteRow hoverableTableRow"
          >
            <td></td>
            <td
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden"
              }}
            >
              {" "}
              {value["title"] || value["address"]}{" "}
            </td>
            <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              {" "}
              {value["starting_block_id"]}{" "}
            </td>
            <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              {" "}
              {value["ending_block_id"]}{" "}
            </td>
            <td
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden"
              }}
            >
              {" "}
              {value["new_input_count"] > 99 ? "100+" : value["new_input_count"]}{" "}
            </td>
            <td
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden"
              }}
            >
              {" "}
              {value["new_output_count"] > 99 ? "100+" : value["new_output_count"]}{" "}
            </td>
          </tr>
        );
      }
    });

    // Handle the wallet data
    const wallet_rows_with_unseen_merges = [];
    const wallet_rows_active_with_updates = [];
    const wallet_rows_active_without_updates = [];
    const wallet_rows_inactive = [];
    const active_wallets = [];

    Object.keys(this.state.wallet_watchlist).forEach(key => {
      const value = this.state.wallet_watchlist[key];

      // If the item hasn't been resolved yet then we add it to the active list
      if (value["ending_block_id"] === null) {
        // Make a list of all wallet ids and tags
        active_wallets.push(value["wallet_id"]);

        if (
          (value["input_trxs"].length === 0 && value["output_trxs"].length === 0) ||
          value["seen"] === true
        ) {
          wallet_rows_active_without_updates.push(
            <tr
              key={key}
              onClick={() => this.openWatchlistModal(key, value, "Wallet")}
              className="inviteRow hoverableTableRow"
            >
              <td></td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["title"] || getWalletNameHelper(value["name"])[0]["name"]}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["starting_block_id"]}{" "}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}> --</td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["new_input_count"] > 99 ? "100+" : value["new_input_count"]}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["new_output_count"] > 99 ? "100+" : value["new_output_count"]}{" "}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                <span
                  style={{
                    border: "1px solid green",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                    paddingLeft: "2px",
                    paddingRight: "2px",
                    borderRadius: "5px",
                    color: "green"
                  }}
                >
                  {" "}
                  Open{" "}
                </span>
              </td>
            </tr>
          );
        } else {
          wallet_rows_active_with_updates.push(
            <tr
              key={key}
              onClick={() => this.openWatchlistModal(key, value, "Wallet")}
              className="inviteRow hoverableTableRow"
            >
              <td>
                <span className="seenDot" />
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["title"] || getWalletNameHelper(value["name"])[0]["name"]}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["starting_block_id"]}{" "}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}> --</td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["new_input_count"] > 99 ? "100+" : value["new_input_count"]}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["new_output_count"] > 99 ? "100+" : value["new_output_count"]}{" "}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                <span
                  style={{
                    border: "1px solid green",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                    paddingLeft: "2px",
                    paddingRight: "2px",
                    borderRadius: "5px",
                    color: "green"
                  }}
                >
                  {" "}
                  Open{" "}
                </span>
              </td>
            </tr>
          );
        }
      } else {
        if (
          value["merged_into_wallet_id"] === null ||
          (value["merged_into_wallet_id"] !== null && value["seen"] === true)
        ) {
          wallet_rows_inactive.push(
            <tr
              key={key}
              onClick={() => this.openWatchlistModal(key, value, "Wallet")}
              className="inviteRow hoverableTableRow"
            >
              <td></td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["title"] || getWalletNameHelper(value["name"])[0]["name"]}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["starting_block_id"]}{" "}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {" "}
                {value["ending_block_id"]}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["new_input_count"] > 99 ? "100+" : value["new_input_count"]}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["new_output_count"] > 99 ? "100+" : value["new_output_count"]}{" "}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                <span
                  style={{
                    border: "1px solid red",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                    paddingLeft: "2px",
                    paddingRight: "2px",
                    borderRadius: "5px",
                    color: "red"
                  }}
                >
                  {" "}
                  Closed{" "}
                </span>
              </td>
            </tr>
          );
        } else {
          wallet_rows_with_unseen_merges.push(
            <tr
              key={key}
              onClick={() => this.openWatchlistModal(key, value, "Wallet")}
              className="inviteRow hoverableTableRow"
            >
              <td>
                <span className="seenDot" />
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["title"] || getWalletNameHelper(value["name"])[0]["name"]}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["starting_block_id"]}{" "}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {" "}
                {value["ending_block_id"]}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["new_input_count"] > 99 ? "100+" : value["new_input_count"]}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["new_output_count"] > 99 ? "100+" : value["new_output_count"]}{" "}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                <span
                  style={{
                    border: "1px solid green",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                    paddingLeft: "2px",
                    paddingRight: "2px",
                    borderRadius: "5px",
                    color: "green"
                  }}
                >
                  Merged
                </span>
              </td>
            </tr>
          );
        }
      }
    });

    // Handle the utxo data
    const utxo_rows_active = [];
    const utxo_rows_inactive_without_updates = [];
    const utxo_rows_inactive_with_update = [];
    const active_utxo = [];
    Object.keys(this.state.utxo_watchlist).forEach(key => {
      const value = this.state.utxo_watchlist[key];

      // If the item hasn't been resolved yet then we add it to the active list
      if (Object.keys(value["spend_trx"]).length === 0) {
        active_utxo.push({
          address: value["address"],
          transaction_hash: value["transaction_hash"],
          bitcoin: satoshiToBitcoin(value["satoshi"]),
          satoshi: value["satoshi"]
        });

        utxo_rows_active.push(
          <tr
            key={key}
            onClick={() => this.openWatchlistModal(key, value, "UTXO")}
            className="inviteRow hoverableTableRow"
          >
            <td></td>
            <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}> {value["title"]} </td>
            <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}> {value["address"]} </td>
            <td
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden"
              }}
            >
              {" "}
              {satoshiToBitcoin(value["satoshi"])}{" "}
            </td>
            <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              {" "}
              {value["transaction_hash"]}{" "}
            </td>
            <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}> --</td>
          </tr>
        );
      } else {
        if (value["seen"]) {
          utxo_rows_inactive_without_updates.push(
            <tr
              key={key}
              onClick={() => this.openWatchlistModal(key, value, "UTXO")}
              className="inviteRow hoverableTableRow"
            >
              <td></td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}> {value["title"]} </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}> {value["address"]} </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {satoshiToBitcoin(value["satoshi"])}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["transaction_hash"]}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["spend_trx"]["transaction_hash"]}{" "}
              </td>
            </tr>
          );
        } else {
          utxo_rows_inactive_with_update.push(
            <tr
              key={key}
              onClick={() => this.openWatchlistModal(key, value, "UTXO")}
              className="inviteRow hoverableTableRow"
            >
              <td>
                <span className="seenDot" />
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}> {value["title"]} </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}> {value["address"]} </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {satoshiToBitcoin(value["satoshi"])}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["transaction_hash"]}{" "}
              </td>
              <td
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {" "}
                {value["spend_trx"]["transaction_hash"]}{" "}
              </td>
            </tr>
          );
        }
      }
    });

    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }
    // Send the number of notifications up to the parent
    this.props.sendTotal(
      utxo_rows_inactive_with_update.length +
        address_rows_active_with_updates.length +
        wallet_rows_active_with_updates.length +
        wallet_rows_with_unseen_merges.length
    );
    return (
      <div>
        <Grid>
          <Row style={{ display: "flex", paddingLeft: "15px", paddingRight: "15px" }}>
            <Tabs
              className="watchlistTabs"
              activeKey={this.state.current_watchlist_type}
              id="items-tab"
              onSelect={e => this.setState({ current_watchlist_type: e })}
              animation={false}
            >
              <Tab
                eventKey="Address"
                title={
                  address_rows_active_with_updates.length === 0 ? (
                    <p style={{ display: "inline", paddingRight: "4px" }}>Address</p>
                  ) : (
                    <>
                      <p style={{ display: "inline", paddingRight: "10px" }}>Address</p>
                      <span className="badge progress-bar-success">
                        {address_rows_active_with_updates.length}
                      </span>
                    </>
                  )
                }
                onClick={() => this.setState({ current_watchlist_type: "Address" })}
              >
                <br />
                <Row style={{ marginBottom: "15px" }}>
                  <Col lg={6} style={{ paddingTop: "15px" }}>
                    <CustomCheckbox
                      onClick={() =>
                        this.setState({
                          showInactiveAddressItems: !this.state.showInactiveAddressItems
                        })
                      }
                      style={{
                        marginRight: "5px",
                        display: "inline-block",
                        height: "20px",
                        width: "20px",
                        border: "2px solid var(--base-color)",
                        verticalAlign: "middle",
                        textAlign: "center",
                        fontFamily: "Quicksand",
                        cursor: "pointer"
                      }}
                      value={this.state.showInactiveAddressItems}
                    />
                    <span
                      style={{
                        fontFamily: "Quicksand",
                        fontWeight: "700",
                        color: "#444",
                        marginRight: "20px"
                      }}
                    >
                      {" "}
                      Show Old Watchlist Items{" "}
                    </span>
                  </Col>
                  <Col lg={6}>
                    {this.props.currency !== "ethereum" && (
                      <Button
                        onClick={() => this.setState({ showAddAddressModal: true })}
                        className="blueButton"
                        style={{ float: "right" }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                        {this.props.entityType !== "UTXO" && (
                          <p
                            style={{
                              marginLeft: "7px",
                              display: "inline",
                              fontFamily: "Quicksand"
                            }}
                          >
                            Add to Watchlist
                          </p>
                        )}
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Table
                      className="inviteTable"
                      style={{ maxWidth: "100%", tableLayout: "fixed" }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              width: "3%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          ></th>
                          <th
                            style={{
                              width: "31%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          >
                            Title
                          </th>
                          <th
                            style={{
                              width: "15%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          >
                            Starting Block
                          </th>
                          <th
                            style={{
                              width: "15%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          >
                            Ending Block
                          </th>
                          <th
                            style={{
                              width: "18%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          >
                            New Input Trx Count
                          </th>
                          <th
                            style={{
                              width: "18%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          >
                            New Output Trx Count
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {address_rows_active_with_updates}
                        {address_rows_active_without_updates}
                        {this.state.showInactiveAddressItems && address_rows_inactive}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="Wallet"
                title={
                  wallet_rows_active_with_updates.length === 0 &&
                  wallet_rows_with_unseen_merges.length === 0 ? (
                    <p style={{ display: "inline", paddingRight: "4px" }}>
                      {`${clientModeConfigObject.cap_plural_for_collection}`}
                    </p>
                  ) : (
                    <>
                      <p style={{ display: "inline", paddingRight: "10px" }}>
                        {`${clientModeConfigObject.cap_plural_for_collection}`}
                      </p>
                      <span className="badge progress-bar-success">
                        {wallet_rows_active_with_updates.length +
                          wallet_rows_with_unseen_merges.length}
                      </span>
                    </>
                  )
                }
                onClick={() => this.setState({ current_watchlist_type: "Wallet" })}
              >
                <br />
                <Row style={{ marginBottom: "15px" }}>
                  <Col lg={6} style={{ paddingTop: "15px" }}>
                    <div
                      onClick={() =>
                        this.setState({
                          showInactiveWalletItems: !this.state.showInactiveWalletItems
                        })
                      }
                      style={{
                        marginRight: "5px",
                        display: "inline-block",
                        height: "20px",
                        width: "20px",
                        border: "2px solid var(--base-color)",
                        verticalAlign: "middle",
                        textAlign: "center",
                        fontFamily: "Quicksand",
                        cursor: "pointer"
                      }}
                    >
                      {this.state.showInactiveWalletItems ? (
                        <FontAwesomeIcon
                          style={{
                            color: "var(--base-color)",
                            paddingBottom: "2px"
                          }}
                          icon={faCheck}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <span
                      style={{
                        fontFamily: "Quicksand",
                        fontWeight: "700",
                        color: "#444",
                        marginRight: "20px"
                      }}
                    >
                      {" "}
                      Show Old Watchlist Items{" "}
                    </span>
                  </Col>
                  <Col lg={6}>
                    {this.props.currency !== "ethereum" && (
                      <Button
                        onClick={() => this.setState({ showAddWalletModal: true })}
                        className="blueButton"
                        style={{ float: "right" }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                        <p
                          style={{
                            marginLeft: "7px",
                            display: "inline",
                            fontFamily: "Quicksand"
                          }}
                        >
                          Add to Watchlist
                        </p>
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Table
                      className="inviteTable"
                      style={{ maxWidth: "100%", tableLayout: "fixed" }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              width: "3%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          ></th>
                          <th
                            style={{
                              width: "27%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          >
                            Title
                          </th>
                          <th
                            style={{
                              width: "15%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          >
                            Starting Block
                          </th>
                          <th
                            style={{
                              width: "15%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          >
                            Ending Block
                          </th>
                          <th
                            style={{
                              width: "15%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          >
                            New Input Trx Count
                          </th>
                          <th
                            style={{
                              width: "15%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          >
                            New Output Trx Count
                          </th>
                          <th
                            style={{
                              width: "10%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {wallet_rows_with_unseen_merges}
                        {wallet_rows_active_with_updates}
                        {wallet_rows_active_without_updates}
                        {this.state.showInactiveWalletItems && wallet_rows_inactive}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="UTXO"
                title={
                  utxo_rows_inactive_with_update.length === 0 ? (
                    <p style={{ display: "inline", paddingRight: "4px" }}>UTXO</p>
                  ) : (
                    <div>
                      <p style={{ display: "inline", paddingRight: "10px" }}>
                        UTXO
                        <span className="badge progress-bar-success" style={{ marginLeft: "5px" }}>
                          {utxo_rows_inactive_with_update.length}
                        </span>
                      </p>
                    </div>
                  )
                }
                onClick={() => this.setState({ current_watchlist_type: "UTXO" })}
              >
                <br />
                <Row style={{ marginBottom: "15px" }}>
                  <Col lg={6} style={{ paddingTop: "15px" }}>
                    <div
                      onClick={() =>
                        this.setState({
                          showInactiveUtxoItems: !this.state.showInactiveUtxoItems
                        })
                      }
                      style={{
                        marginRight: "5px",
                        display: "inline-block",
                        height: "20px",
                        width: "20px",
                        border: "2px solid var(--base-color)",
                        verticalAlign: "middle",
                        textAlign: "center",
                        fontFamily: "Quicksand",
                        cursor: "pointer"
                      }}
                    >
                      {this.state.showInactiveUtxoItems ? (
                        <FontAwesomeIcon
                          style={{
                            color: "var(--base-color)",
                            paddingBottom: "2px"
                          }}
                          icon={faCheck}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <span
                      style={{
                        fontFamily: "Quicksand",
                        fontWeight: "700",
                        color: "#444",
                        marginRight: "20px"
                      }}
                    >
                      {" "}
                      Show Old Watchlist Items{" "}
                    </span>
                  </Col>
                  <Col lg={6}>
                    {this.props.currency !== "ethereum" && (
                      <Button
                        onClick={() => this.setState({ showAddUtxoModal: true })}
                        className="blueButton"
                        style={{ float: "right" }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                        <p
                          style={{
                            marginLeft: "7px",
                            display: "inline",
                            fontFamily: "Quicksand"
                          }}
                        >
                          Add to Watchlist
                        </p>
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Table
                      className="inviteTable"
                      style={{ maxWidth: "100%", tableLayout: "fixed" }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              width: "3%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          ></th>

                          <th
                            style={{
                              width: "17%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          >
                            Title
                          </th>
                          <th
                            style={{
                              width: "20%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          >
                            Address
                          </th>
                          <th
                            style={{
                              width: "10%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          >
                            Balance
                          </th>
                          <th
                            style={{
                              width: "25%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          >
                            Output Transaction
                          </th>
                          <th
                            style={{
                              width: "25%",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            }}
                          >
                            Input Transaction
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {utxo_rows_inactive_with_update}
                        {utxo_rows_active}
                        {this.state.showInactiveUtxoItems && utxo_rows_inactive_without_updates}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Row>
        </Grid>

        {this.state.current_watchlist_type !== "UTXO" ? (
          <WatchlistItem
            entityType={this.state.current_watchlist_type}
            showModal={this.state.showModal}
            currency={this.props.currency}
            closeModal={this.closeModal}
            currentWatchlistItem={this.state.currentWatchlistItem}
            stopWatching={this.updateForStopWatching}
            deleteItem={this.deleteItem}
            loadMore={this.loadMore}
            refresh={this.state.refresh}
            updateTitle={this.updateTitle}
            updateNotes={this.updateNotes}
            setMempool={this.setMempool}
            setSMS={this.setSMS}
          />
        ) : (
          <UtxoWatchlistModal
            showModal={this.state.showModal}
            currency={this.props.currency}
            closeModal={this.closeModal}
            currentWatchlistItem={this.state.currentWatchlistItem}
            deleteItem={this.deleteItem}
            updateTitle={this.updateTitle}
            updateNotes={this.updateNotes}
            refresh={this.state.refresh}
            setSMS={this.setSMS}
          />
        )}

        <Modal
          show={this.state.showAddAddressModal}
          onHide={this.handleCloseAddAddressModal}
          className="issuesModal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="entityTitle">Add Address To Watchlist</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.notification !== null ? (
              <p
                className={
                  this.state.notification === "Successfully added to watchlist."
                    ? "addToWatchlistNotificationSuccess"
                    : "addToWatchlistNotificationFail"
                }
              >
                {this.state.notification}{" "}
              </p>
            ) : this.state.loading === false ? (
              <Form onSubmit={e => this.addAddressIfExists(e, active_addresses)}>
                <FormGroup>
                  <FormControl
                    type="text"
                    placeholder="Address"
                    inputRef={ref => {
                      this.addressForm = ref;
                    }}
                  />
                </FormGroup>
                <Button className="blueButton" type="submit">
                  Add
                </Button>
              </Form>
            ) : (
              <CircularProgress style={{ color: "var(--secondary-color)" }} />
            )}
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showAddWalletModal}
          onHide={this.handleCloseAddWalletModal}
          className="issuesModal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="entityTitle">Add Wallet To Watchlist</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loading === false ? (
              <>
                {this.state.notification !== null && (
                  <p
                    className={
                      this.state.notification === "Successfully added to watchlist."
                        ? "addToWatchlistNotificationSuccess"
                        : "addToWatchlistNotificationFail"
                    }
                  >
                    {this.state.notification}
                  </p>
                )}
                <Form onSubmit={e => this.addWalletIfExists(e, active_wallets)}>
                  <FormGroup>
                    <FormControl
                      type="text"
                      placeholder="Wallet"
                      inputRef={ref => {
                        this.walletForm = ref;
                      }}
                    />
                  </FormGroup>
                  <Button className="blueButton" type="submit">
                    Add
                  </Button>
                </Form>
              </>
            ) : (
              <CircularProgress style={{ color: "var(--secondary-color)" }} />
            )}
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showAddUtxoModal}
          onHide={this.handleCloseAddUtxoModal}
          className="issuesModal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="entityTitle">Add UTXO To Watchlist</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loading === false ? (
              <>
                {this.state.notification !== null && (
                  <p
                    className={
                      this.state.notification === "Successfully added to watchlist."
                        ? "addToWatchlistNotificationSuccess"
                        : "addToWatchlistNotificationFail"
                    }
                  >
                    {this.state.notification}
                  </p>
                )}
                <Form onSubmit={e => this.addUtxoIfExists(e, active_wallets)}>
                  <FormGroup>
                    <FormControl
                      type="text"
                      placeholder="Title (optional)"
                      inputRef={ref => {
                        this.utxoFormTitle = ref;
                      }}
                    />
                    <br />
                    <FormControl
                      componentClass="textArea"
                      type="textarea"
                      placeholder="Notes (optional)"
                      inputRef={ref => {
                        this.utxoFormNotes = ref;
                      }}
                    />
                    <br />
                    <FormControl
                      type="text"
                      placeholder="Address"
                      inputRef={ref => {
                        this.utxoFormAddress = ref;
                      }}
                    />
                    <br />
                    <FormControl
                      type="text"
                      placeholder="Bitcoin"
                      inputRef={ref => {
                        this.utxoFormBitcoin = ref;
                      }}
                    />
                    <br />
                    <FormControl
                      type="text"
                      placeholder="Transaction Hash"
                      inputRef={ref => {
                        this.utxoFormTrx = ref;
                      }}
                    />
                  </FormGroup>
                  <Button className="blueButton" type="submit">
                    Add
                  </Button>
                </Form>
              </>
            ) : (
              <CircularProgress style={{ color: "var(--secondary-color)" }} />
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currency: getCurrency(state),
    clientMode: getClientMode(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Watchlist)
);
