import { Button, Form, FormControl, FormGroup, ListGroup, ListGroupItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faLocationArrow, faSearch } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  getAddressSet,
  getGraphSearchCurrentQuery,
  getGraphSearchResults
} from "../../../selectors/ethereum/graph";
import { getSearchDisplayName } from "../../SearchResults/SearchResults";
import WrappedLink from "../../Utils/WrappedLink";
import { clean_ethereum_address } from "../helpers";

interface GraphSearchProps {
  addToGraph: (addressId: string, graphId: number | string, cy: any) => void;
  search: (query: string) => Promise<any>;
  graphId: number | string;
  cy: any;
  sidebar: boolean;
  dispatch: any;
}

export const GraphSearch = ({
  graphId,
  addToGraph,
  search,
  cy,
  dispatch,
  sidebar = false
}: GraphSearchProps) => {
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  const searchResult = useSelector(state => getGraphSearchResults(state, graphId));
  const addresses = useSelector(state => getAddressSet(state, graphId));
  const query = useSelector(state => getGraphSearchCurrentQuery(state, graphId));
  return (
    <div
      style={{
        overflowY: "auto",
        width: "100%",
        marginTop: "10px"
      }}
    >
      <Form style={{ width: sidebar ? "100%" : "700px" }}>
        <FormGroup
          controlId="search"
          style={{
            margin: "0 0 0 0",
            padding: "0"
          }}
        >
          <div
            className="row"
            style={{
              paddingBottom: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
              margin: "0",
              backgroundColor: sidebar
                ? "var(--graph-sidebar-light-hover)"
                : "var(--graph-sidebar-base)",
              width: "100%"
            }}
          >
            <div
              className="col-lg-8 searchFilterPurple"
              style={{
                margin: "0",
                paddingRight: "0",
                paddingLeft: "0",
                paddingBottom: "0"
              }}
            >
              <FormControl
                type="text"
                placeholder={`Address`}
                value={searchText}
                onChange={e => setSearchText((e.target as HTMLTextAreaElement).value)}
                style={{
                  fontSize: "14px",
                  marginBottom: "0",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
              />
            </div>
            <div
              className="col-lg-4"
              style={{
                margin: "0",
                paddingLeft: "0"
              }}
            >
              <Button
                className={sidebar ? "control-whiteButton" : "allPurpleButton"}
                style={{
                  display: "inline-block",
                  marginTop: "10px",
                  marginBottom: "0",
                  height: "34px"
                }}
                onClick={e => {
                  e.preventDefault();
                  setLoading(true);
                  search(searchText).finally(() => setLoading(false));
                }}
                type="submit"
              >
                <FontAwesomeIcon icon={faSearch} /> Search
              </Button>
            </div>
          </div>
        </FormGroup>
      </Form>
      <div
        style={{
          marginRight: "0",
          marginLeft: "0",
          marginTop: "5px",
          marginBottom: "10px"
        }}
      >
        <>
          {query && (
            <p
              style={{
                paddingLeft: "15px",
                fontSize: "15px",
                color: "var(--graph-key-labels)",
                fontFamily: "Quicksand",
                fontWeight: "500"
              }}
            >
              Showing Results for {"  "}
              <span style={{ fontWeight: "700" }}>"{query}"</span>
            </p>
          )}
          {loading ? (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                paddingTop: "15px"
              }}
            >
              <CircularProgress style={{ color: "var(--graph-circular-loading-key)" }} />
            </div>
          ) : searchResult && searchResult.length > 0 ? (
            <ListGroup style={{ marginBottom: "0", maxHeight: "350px" }}>
              {searchResult.map((addressMap: any) => (
                <ListGroupItem>
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      paddingBottom: "0",
                      margin: "0"
                    }}
                  >
                    <div
                      className="col-lg-9"
                      style={{
                        overflow: "auto",
                        wordWrap: "break-word",
                        paddingLeft: "0",
                        paddingRight: "0",
                        paddingBottom: "0",
                        marginLeft: "0",
                        marginRight: "5px",
                        maxWidth: "600px"
                      }}
                    >
                      <WrappedLink
                        entity={addressMap["address"]}
                        entityType={"address"}
                        stateBased
                        style={{ color: "var(--link-colors)" }}
                      >
                        {getSearchDisplayName(addressMap)}
                      </WrappedLink>
                    </div>

                    <div
                      className="col-lg-3"
                      style={{
                        paddingLeft: "0",
                        paddingRight: "0",
                        paddingBottom: "0",
                        marginLeft: "5px",
                        marginRight: "0"
                      }}
                    >
                      {addresses.has(clean_ethereum_address(addressMap["address"])) ? (
                        <>
                          <Button
                            className="pull-right locateButtonGraph"
                            bsSize="xsmall"
                            onClick={() => {
                              cy.getElementById(addressMap["address"]).select();
                              cy.fit(cy.$(":selected"), 150);
                              dispatch({
                                type: "switchCurrentSelection",
                                payload: {
                                  view: "AddressView",
                                  data: cy.getElementById(addressMap["address"])
                                }
                              });
                            }}
                            // onClick={() => selectElementFromSearchResult(walletId)}
                          >
                            <FontAwesomeIcon
                              icon={faLocationArrow}
                              style={{ marginRight: "3px" }}
                            />{" "}
                            Locate
                          </Button>
                          <Button className="pull-right greenButtonGraph" bsSize="xsmall">
                            <FontAwesomeIcon icon={faCheck} /> Added
                          </Button>
                        </>
                      ) : (
                        <Button
                          className="pull-right allPurpleButton"
                          bsSize="xsmall"
                          onClick={() => addToGraph(addressMap["address"], graphId, cy)}
                        >
                          Add to Graph
                        </Button>
                      )}
                    </div>
                  </div>
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : (
            query && (
              <p
                style={{
                  paddingLeft: "15px",
                  fontSize: "15px",
                  color: "var(--graph-key-labels)",
                  fontFamily: "Quicksand",
                  fontWeight: "500"
                }}
              >
                No Results Found
              </p>
            )
          )}
        </>
      </div>
    </div>
  );
};
