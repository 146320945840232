import { List, Map, Record } from "immutable";
import { LOGGING_OUT, SEARCH_SUCCESS } from "../../actions/actionNames";

export const WalletResult = Record({
  walletId: 0,
  tag: "",
  addressCount: 0,
});

export const SearchResult = Record({
  addressResults: List(), // list of strings
  walletResults: List(), // list of WalletResult records
  transactionResults: List(), // list of transactions
  customWalletResults: List(), // list of custom wallets
});

export const addFuzzySearchResult = (state, { query, data }) => {
  const { addresses, wallets, transactions } = data;

  const addressResults = List.of(...addresses);
  const walletResults = List.of(...wallets).map(w => new WalletResult(w));
  const transactionResults = List.of(...transactions);

  const searchResult = new SearchResult({
    addressResults,
    walletResults,
    transactionResults,
  });

  return state.set(query, searchResult);
};

const makeFuzzySearchReducerEthereum = coin => {
  // This just returns the state, which is why each of the switch funcs returns an ImmutableJs set
  return (state = Map(), action) => {
    // If the coin doesn't match the reducer, just return the state.
    if (action === undefined || action.name !== coin) {
      return state;
    }

    switch (action.type) {
      case SEARCH_SUCCESS:
        return addFuzzySearchResult(state, action);
      case LOGGING_OUT:
        return Map();
      default:
        return state;
    }
  };
};

export default makeFuzzySearchReducerEthereum;
