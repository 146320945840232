/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

// This shouldn't be an issue, but I need a specific number of spaces for the row cell.
/* eslint-disable react/jsx-curly-brace-presence */
import moment from "moment";
import React, { Component } from "react";
import { Button, Col, Grid, Row, Table } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import axios, { GRAPH_API } from "../../api";
import { reactRouterHistoryShape } from "../../prop-types";
import CreateGraphModal from "./CreateGraphModal";
import CategoryColorsConfig from "../Graph/CategoryColorsConfig";
import { getCurrency } from "../../selectors/currency";
import { getClientMode } from "../../selectors/applicationConfig";
import ClientModeConfig from "../ClientModeConfig";
import { CustomCheckbox } from "../Utils/CustomCheckbox";

/**
 * List of graphs associated with user/is public with form modal
 * for creating new graphs
 */
class Graphs extends Component {
  state = {
    graphs: [],
    showModal: false,
    showPublic: false
  };

  componentDidMount() {
    this.fetchGraphs();
  }

  fetchGraphs = async () => {
    try {
      const { data } = await axios.get(GRAPH_API(this.props.currency));
      this.setState({
        graphs: data.graphs
      });
    } catch (err) {
      throw err;
    }
  };

  showCreateGraph = () => this.setState({ showModal: true });

  closeCreateGraph = () => this.setState({ showModal: false });

  /**
   * Sends request to create graph using form data and redirects
   * to new graph on success
   */
  createGraph = async (caseNumber, description, isPublic) => {
    try {
      const {
        data: { graphId }
      } = await axios.post(GRAPH_API(this.props.currency), {
        caseNumber,
        description,
        isPublic
      });

      if (graphId) {
        this.props.history.push(`/${this.props.currency}/graph/${graphId}`);
      }
    } catch (err) {
      throw err;
    }
  };

  getLink(version, graphId, caseNumber) {
    if (version > 12) {
      return (
        <span
          className="graphVersionTooNew"
          style={{ fontFamily: "Quicksand", fontWeight: "600" }}
        >
          {caseNumber || "(No case number)"}
          <span className="tooltiptext">
            {" "}
            This graph was created using a newer version of Cryptovoyant.{" "}
          </span>
        </span>
      );
    }
    return (
      <Link to={`/${this.props.currency}/graph/${graphId}`}>
        {caseNumber || "(No case number)"}
      </Link>
    );
  }

  render() {
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    const graphs = this.state.graphs.map(graph => {
      const {
        graphId,
        description,
        isPublic,
        lastModified_,
        caseNumber,
        version
      } = graph;
      const lastModified = moment
        .unix(parseInt(lastModified_, 10))
        .format("LLL");
      if (isPublic === "No" || (isPublic === "Yes" && this.state.showPublic)) {
        return (
          <tr className="inviteRow staticTableRow" key={graphId}>
            <td>{this.getLink(version, graphId, caseNumber)}</td>
            <td>{description}</td>
            <td>{isPublic}</td>
            <td>{lastModified}</td>
          </tr>
        );
      }
    });

    const colors = CategoryColorsConfig(clientModeConfigObject).map(
      categoryConfig => {
        const { label, hex, description } = categoryConfig;
        return (
          <div
            className="col-xs-6 col-md-6 keyElement"
            style={{ textAlign: "center", height: "250px" }}
          >
            <p>{label}</p>
            <br />
            {label === "Cold Entity" ? (
              <svg
                style={{ marginBottom: "28px", marginTop: "22px" }}
                height="65px"
                width="65px"
                aria-hidden="true"
                data-prefix="far"
                data-icon="snowflake"
                className="svg-inline--fa fa-snowflake fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="175 175 100 100"
              >
                <path
                  stroke="black"
                  strokeWidth="9"
                  strokeOpacity="1.0"
                  fill="white"
                  d="M440.1 355.2l-39.2-23 34.1-9.3c8.4-2.3 13.4-11.1 11.1-19.6l-4.1-15.5c-2.2-8.5-10.9-13.6-19.3-11.3L343 298.2 271.2 256l71.9-42.2 79.7 21.7c8.4 2.3 17-2.8 19.3-11.3l4.1-15.5c2.2-8.5-2.7-17.3-11.1-19.6l-34.1-9.3 39.2-23c7.5-4.4 10.1-14.2 5.8-21.9l-7.9-13.9c-4.3-7.7-14-10.3-21.5-5.9l-39.2 23 9.1-34.7c2.2-8.5-2.7-17.3-11.1-19.6l-15.2-4.1c-8.4-2.3-17 2.8-19.3 11.3l-21.3 81-71.9 42.2v-84.5L306 70.4c6.1-6.2 6.1-16.4 0-22.6l-11.1-11.3c-6.1-6.2-16.1-6.2-22.2 0l-24.9 25.4V16c0-8.8-7-16-15.7-16h-15.7c-8.7 0-15.7 7.2-15.7 16v46.1l-24.9-25.4c-6.1-6.2-16.1-6.2-22.2 0L142.1 48c-6.1 6.2-6.1 16.4 0 22.6l58.3 59.3v84.5l-71.9-42.2-21.3-81c-2.2-8.5-10.9-13.6-19.3-11.3L72.7 84c-8.4 2.3-13.4 11.1-11.1 19.6l9.1 34.7-39.2-23c-7.5-4.4-17.1-1.8-21.5 5.9l-7.9 13.9c-4.3 7.7-1.8 17.4 5.8 21.9l39.2 23-34.1 9.1c-8.4 2.3-13.4 11.1-11.1 19.6L6 224.2c2.2 8.5 10.9 13.6 19.3 11.3l79.7-21.7 71.9 42.2-71.9 42.2-79.7-21.7c-8.4-2.3-17 2.8-19.3 11.3l-4.1 15.5c-2.2 8.5 2.7 17.3 11.1 19.6l34.1 9.3-39.2 23c-7.5 4.4-10.1 14.2-5.8 21.9L10 391c4.3 7.7 14 10.3 21.5 5.9l39.2-23-9.1 34.7c-2.2 8.5 2.7 17.3 11.1 19.6l15.2 4.1c8.4 2.3 17-2.8 19.3-11.3l21.3-81 71.9-42.2v84.5l-58.3 59.3c-6.1 6.2-6.1 16.4 0 22.6l11.1 11.3c6.1 6.2 16.1 6.2 22.2 0l24.9-25.4V496c0 8.8 7 16 15.7 16h15.7c8.7 0 15.7-7.2 15.7-16v-46.1l24.9 25.4c6.1 6.2 16.1 6.2 22.2 0l11.1-11.3c6.1-6.2 6.1-16.4 0-22.6l-58.3-59.3v-84.5l71.9 42.2 21.3 81c2.2 8.5 10.9 13.6 19.3 11.3L375 428c8.4-2.3 13.4-11.1 11.1-19.6l-9.1-34.7 39.2 23c7.5 4.4 17.1 1.8 21.5-5.9l7.9-13.9c4.6-7.5 2.1-17.3-5.5-21.7z"
                />
              </svg>
            ) : (
              <span
                className="dot"
                style={{
                  backgroundColor: hex
                }}
              />
            )}
            <br />
            <p
              style={{
                fontSize: "14px",
                paddingTop: "30px",
                paddingLeft: "20px",
                paddingRight: "20px"
              }}
            >
              {" "}
              {description}{" "}
            </p>
          </div>
        );
      }
    );

    return (
      <Grid>
        <Row>
          <Col lg={12}>
            <Button onClick={this.showCreateGraph} className="blueButton">
              <span>
                <FontAwesomeIcon icon={faPlus} />
                {"  "}
                Create Graph
              </span>
            </Button>
            {this.props.currency !== "ethereum" && (
              <>
                <CustomCheckbox
                  onClick={() => {
                    this.setState({ showPublic: !this.state.showPublic });
                  }}
                  value={this.state.showPublic}
                />
                <span
                  style={{
                    fontFamily: "Quicksand",
                    fontWeight: "700",
                    color: "#444"
                  }}
                >
                  {" "}
                  Show Public Graphs{" "}
                </span>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Table className="inviteTable">
              <thead>
                <tr>
                  <th>Case #</th>
                  <th>Description</th>
                  <th>Public</th>
                  <th>Last Modified</th>
                </tr>
              </thead>
              <tbody>{graphs}</tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="row key">{colors}</div>
          </Col>
        </Row>
        <CreateGraphModal
          createGraph={this.createGraph}
          show={this.state.showModal}
          close={this.closeCreateGraph}
        />
      </Grid>
    );
  }
}

Graphs.propTypes = {
  history: reactRouterHistoryShape.isRequired
};

const mapStateToProps = state => {
  return {
    currency: getCurrency(state),
    clientMode: getClientMode(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Graphs)
);
