/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { useState } from "react";
import { Button, Col, Form, FormControl, FormGroup, Row } from "react-bootstrap";
import { CVIconAscii } from "./cv-icon-ascii";
import axios, { ISSUES_API } from "../api";
import { useSelector } from "react-redux";
import { getCurrency } from "../selectors/currency";
import { ISSUE_TYPES } from "./ReportIssue";
import { Link } from "react-router-dom";
import CopyText from "./CopyText";

/**
 * Used as general 404 response for requested pages gives user form to
 * submit issue on the spot
 * @returns {JSX.Element}
 * @constructor
 */
const GenericNotFound = () => {
  const [memo, setMemo] = useState(""); // Form text field
  const [submitted, setSubmitted] = useState(null); // if submitted correctly
  const currency = useSelector(state => getCurrency(state));
  const submitIssue = async e => {
    e.preventDefault();
    // Make the api call to report the issue bitcoin if not valid currency
    const status = await axios.put(ISSUES_API(currency || "bitcoin", "issues-user"), {
      entity_id: -1,
      memo: memo,
      issue_type: ISSUE_TYPES.performance // always performance since this is technical problem
    });

    if (status.status === 200) {
      // Set the local state
      setSubmitted(true);
    }
  };

  return (
    <div style={{ alignItems: "center" }}>
      {" "}
      <Row>
        <Col className={"text-center"}>
          <h1>404 - Resource Not Found</h1>
        </Col>
      </Row>
      <Row>
        <Col style={{ justifyContent: "center", display: "flex" }}>
          <CVIconAscii />
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col className={"text-center"}>
          <Link to={`/`} style={{ color: "var(--link-colors)" }}>
            It's too dangerous to go alone! Take this to go home!
          </Link>
        </Col>
      </Row>
      <Row marginHeight={10}>
        <Col className={"text-center"}>
          <h3>Do you believe this was an error?</h3>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col style={{ justifyContent: "center", display: "flex" }}>
          <Form onSubmit={e => submitIssue(e)} style={{ display: "block" }}>
            <FormGroup>
              <p className="entityPrompt"> Please describe the issue you are experiencing: </p>
              <FormControl
                componentClass="textarea"
                placeholder="Issue Description"
                onChange={e => {
                  setMemo(e.target.value);
                }}
                style={{ resize: "vertical", width: "600px", fontFamily: "Quicksand" }}
              />
            </FormGroup>
            {!submitted && (
              <Button
                type="submit"
                className="greenButton"
                disabled={memo.length === 0 || !!submitted}
              >
                Submit
              </Button>
            )}
          </Form>
        </Col>
      </Row>
      {submitted && (
        <Row>
          <Col className={"text-center"}>Your issue was submitted, thank you!</Col>
        </Row>
      )}
      {submitted === false && (
        <Row>
          <Col className={"text-center"}>There was an issue submitting your request</Col>
        </Row>
      )}
      <Row marginHeight={20}>
        <Col className={"text-center"}>
          <p>
            Or Contact us at
            <a href={"mailto:" + "support@sievakocorp.com"}> support@sievakocorp.com</a>
            <CopyText marginLeft={"5px"} text={"support@sievakocorp.com"} />
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default GenericNotFound;
