/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

// The following is borrowed from:
// https://github.com/facebook/draft-js/tree/master/examples/draft-0-10-0/rich
import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import {
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  RichUtils
} from "draft-js";
import { debounce } from "lodash";
import "draft-js/dist/Draft.css";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCurrency } from "../../selectors/currency";

import BlockStyleControls from "./BlockStyleControls";
import InlineStyleControls from "./InlineStyleControls";
import "../../../css/RichEditor.css";
import axios, {
  GRAPH_API,
  NOTES_API,
  ADDRESS_API,
  TRANSACTION_API
} from "../../api";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { Button, Col, Row, Table, Tab, Tabs } from "react-bootstrap";
import NoteEditor from "./index";
import { getClientMode } from "../../selectors/applicationConfig";
import ClientModeConfig from "../ClientModeConfig";

class WalletNoteEditor extends Component {
  state = {
    walletData: { location: null, locationId: null },
    walletMentionData: { location: null, locationId: null },
    allWalletData: { note: {}, wallet: {}, transaction: {}, graph: {} },
    walletNoteEditorState: null,
    walletMentionsEditorState: null,
    walletLoading: false,
    walletKey: "walletNote",
    walletDescription: "",
    openWalletMergeModel: false
  };

  componentDidMount() {
    this.fetchWalletNote(this.props.wallet, this.props.walletId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.walletId !== prevProps.walletId) {
      this.setState({ walletLoading: false });
      this.fetchWalletNote(this.props.wallet, this.props.walletId);
    }
  }

  getNoteId = async () => {
    let noteId = null;
    if (!this.props.mainLocationId) {
      if (this.props.mainLocationName === "address") {
        const walletData = await axios.get(
          `${ADDRESS_API(this.props.currency)}/${
            this.props.mainLocationName
          }/exists`
        );
        noteId = await walletData.data.walletId;
      } else if (this.props.mainLocationName === "transaction") {
        const transactionData = await axios.get(
          `${TRANSACTION_API(this.props.currency)}/${
            this.props.mainLocationName
          }/exists`
        );
        noteId = await transactionData.data.transactionId;
      }
    } else {
      noteId = await parseInt(this.props.mainLocationId, 10);
    }
    return noteId;
  };

  fetchWalletNote = async (wallet, walletId) => {
    try {
      const { data } = await axios.get(
        `${NOTES_API(this.props.currency)}/wallet/${walletId}`,
        { wallet: wallet }
      );

      const noteId = await this.getNoteId();
      if (!data[this.props.mainLocation][noteId]) {
        await axios.post(
          `${NOTES_API(this.props.currency)}/wallet/${walletId}`,
          {
            noteId: noteId,
            mainLocation: this.props.mainLocation
          }
        );
      }
      this.setState({ allWalletData: data }, () => {
        if (this.state.allWalletData[this.props.mainLocation][noteId]) {
          if (
            this.state.allWalletData[this.props.mainLocation][noteId]
              .editorContent
          ) {
            this.setState({
              walletNoteEditorState: EditorState.createWithContent(
                convertFromRaw(
                  this.state.allWalletData[this.props.mainLocation][noteId]
                    .editorContent
                )
              )
            });
          } else {
            this.setState({ walletNoteEditorState: EditorState.createEmpty() });
          }
        } else {
          this.setState({ walletNoteEditorState: EditorState.createEmpty() });
        }
        this.setState(
          {
            walletData: {
              ...this.state.walletData,
              location: this.props.mainLocation
            }
          },
          () => {
            this.setState({
              walletData: { ...this.state.walletData, locationId: noteId }
            });
          }
        );
        this.setState({ walletLoading: true });
      });
    } catch (err) {
      this.props.history.replace("/case-management");
      throw err;
    }
  };

  handleSave = async (message = false) => {
    const walletId = this.props.walletId;
    const walletLocation = this.state.walletData.location;
    const walletLocationId = this.state.walletData.locationId;
    const walletNote = JSON.stringify(
      convertToRaw(this.state.walletNoteEditorState.getCurrentContent())
    );
    if ("changeCreateNodeNoteButton" in this.props) {
      if (
        !(
          convertToRaw(this.state.walletNoteEditorState.getCurrentContent())[
            "blocks"
          ].length === 1 &&
          convertToRaw(this.state.walletNoteEditorState.getCurrentContent())[
            "blocks"
          ][0]["text"] === ""
        )
      ) {
        this.props.changeCreateNodeNoteButton(walletId, true);
      } else {
        this.props.changeCreateNodeNoteButton(walletId, false);
      }
    }
    try {
      await axios.put(`${NOTES_API(this.props.currency)}/wallet/${walletId}`, {
        walletLocation,
        walletLocationId,
        walletNote,
        walletId
      });
    } catch (err) {
      window.scrollTo(0, 0);
      throw err;
    }
  };

  handleSaveMentions = async (message = false) => {
    const walletId = this.props.walletId;
    const walletLocation = this.state.walletMentionData.location;
    const walletLocationId = this.state.walletMentionData.locationId;
    const walletNote = JSON.stringify(
      convertToRaw(this.state.walletMentionsEditorState.getCurrentContent())
    );

    try {
      const updatedEditor = {
        editorContent: convertToRaw(
          this.state.walletMentionsEditorState.getCurrentContent()
        ),
        locationName: this.state.allWalletData[walletLocation][walletLocationId]
          .locationName
      };
      let copyState = { ...this.state.allWalletData };
      copyState[walletLocation][walletLocationId] = updatedEditor;
      this.setState({ allWalletData: copyState });
      await axios.put(`${NOTES_API(this.props.currency)}/wallet/${walletId}`, {
        walletLocation,
        walletLocationId,
        walletNote
      });
    } catch (err) {
      window.scrollTo(0, 0);
      throw err;
    }
  };

  handleWalletSelect = walletKey => this.setState({ walletKey });
  onWalletEdit = walletNoteEditorState =>
    this.setState({ walletNoteEditorState });
  onWalletMentionEdit = walletMentionsEditorState =>
    this.setState({ walletMentionsEditorState });

  updateWalletMentionEditorState = (locationId, location) => {
    this.setState({
      walletDescription:
        location.charAt(0).toUpperCase() +
        location.slice(1).toLowerCase() +
        " - " +
        this.state.allWalletData[location][locationId].locationName
    });
    if (this.state.allWalletData[location][locationId].editorContent) {
      this.setState({
        walletMentionsEditorState: EditorState.createWithContent(
          convertFromRaw(
            this.state.allWalletData[location][locationId].editorContent
          )
        )
      });
    } else {
      this.setState({ walletMentionsEditorState: EditorState.createEmpty() });
    }
    this.setState(
      {
        walletMentionData: {
          ...this.state.walletMentionData,
          location: location
        }
      },
      () => {
        this.setState({
          walletMentionData: {
            ...this.state.walletMentionData,
            locationId: locationId
          }
        });
      }
    );
  };

  render() {
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    const walletMentionsNotes = Object.keys(this.state.allWalletData.note).map(
      locationId => {
        const locationName = this.state.allWalletData.note[locationId]
          .locationName;
        if (
          (this.props.mainLocation === "note" &&
            this.props.mainLocationId !== locationId) ||
          this.props.mainLocation !== "note"
        ) {
          return (
            <tr key={locationName}>
              <td>
                <Link
                  to={`/${this.props.currency}/case-management/${locationId}`}
                  target="_blank"
                  style={{ color: "var(--link-colors)" }}
                >
                  {locationName}
                </Link>{" "}
                <Button
                  className="pull-right greenButtonGraph"
                  bsSize="xsmall"
                  onClick={() =>
                    this.updateWalletMentionEditorState(locationId, "note")
                  }
                >
                  See Notes
                </Button>
              </td>
            </tr>
          );
        } else if (
          this.props.mainLocation === "note" &&
          this.props.mainLocationId === locationId &&
          Object.keys(this.state.allWalletData.note).length === 1
        ) {
          return (
            <tr>
              <td>None Found</td>
            </tr>
          );
        }
      }
    );

    const walletMentionsWallets = Object.keys(
      this.state.allWalletData.wallet
    ).map(locationId => {
      const locationName = this.state.allWalletData.wallet[locationId]
        .locationName;
      return (
        <tr key={locationName}>
          <td>
            <Link
              to={`/${this.props.currency}/wallet/${locationName}`}
              target="_blank"
              style={{ color: "var(--link-colors)" }}
            >
              {locationName || locationId}
            </Link>{" "}
            <Button
              className="pull-right greenButtonGraph"
              bsSize="xsmall"
              onClick={() =>
                this.updateWalletMentionEditorState(locationId, "wallet")
              }
            >
              See Notes
            </Button>
          </td>
        </tr>
      );
    });

    const walletMentionsGraphs = Object.keys(
      this.state.allWalletData.graph
    ).map(locationId => {
      const locationName = this.state.allWalletData.graph[locationId]
        .locationName;
      if (
        (this.props.mainLocationId !== parseInt(locationId) &&
          this.props.mainLocation === "graph") ||
        this.props.mainLocation !== "graph"
      ) {
        return (
          <tr key={locationId}>
            <td>
              <Link
                to={`/${this.props.currency}/graph/${locationId}`}
                target="_blank"
                style={{ color: "var(--link-colors)" }}
              >
                {locationName}
              </Link>{" "}
              <Button
                className="pull-right greenButtonGraph"
                bsSize="xsmall"
                onClick={() =>
                  this.updateWalletMentionEditorState(locationId, "graph")
                }
              >
                See Notes
              </Button>
            </td>
          </tr>
        );
      }
    });

    return (
      <>
        {this.state.walletLoading && (
          <Tabs
            className="inviteTabs"
            activeKey={this.state.walletKey}
            id="wallet-tab"
            onSelect={this.handleWalletSelect}
            animation={false}
            mountOnEnter
          >
            <Tab
              eventKey="walletNote"
              title={clientModeConfigObject.cap_word_for_collection}
              Note
            >
              <>
                <Button
                  style={{
                    position: "relative",
                    float: "right",
                    top: "10px",
                    right: "10px"
                  }}
                  onClick={() => this.handleSave()}
                  className="greenButton"
                >
                  Save
                </Button>{" "}
                <NoteEditor
                  height={700}
                  editorState={this.state.walletNoteEditorState}
                  onEdit={this.onWalletEdit}
                  saveGraph={this.handleSave}
                />
              </>
            </Tab>
            <Tab eventKey="otherWalletMentions" title="Other Mentions">
              <Row>
                <Col lg={6}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Note Mentions</th>
                      </tr>
                    </thead>
                    {Object.keys(this.state.allWalletData.note).length !== 0 ? (
                      <tbody>{walletMentionsNotes}</tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td>None Found</td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </Col>
                <Col lg={6}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Graph Mentions</th>
                      </tr>
                    </thead>
                    {Object.keys(this.state.allWalletData.graph).length > 1 &&
                    this.props.mainLocation === "graph" ? (
                      <tbody>{walletMentionsGraphs}</tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td>None Found</td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </Col>
                {this.props.mainLocation !== "wallet" && (
                  <Col lg={12}>
                    <Table>
                      <thead>
                        <tr>
                          <th>
                            {clientModeConfigObject.cap_word_for_collection}{" "}
                            Mentions
                          </th>
                        </tr>
                      </thead>
                      {Object.keys(this.state.allWalletData.wallet).length !==
                      0 ? (
                        <tbody>{walletMentionsWallets}</tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td>None Found</td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </Col>
                )}
              </Row>
              {this.state.walletMentionData.location ? (
                <>
                  <div>
                    <p
                      style={{
                        fontFamily: "Quicksand",
                        fontWeight: "600",
                        color: "#666",
                        marginRight: "10px",
                        marginBottom: "0",
                        fontSize: "30px"
                      }}
                    >
                      {this.state.walletDescription}
                    </p>
                    <Button
                      style={{
                        position: "relative",
                        float: "right",
                        top: "10px",
                        right: "10px"
                      }}
                      onClick={() => this.handleSaveMentions()}
                      className="greenButton"
                    >
                      Save
                    </Button>{" "}
                  </div>
                  <NoteEditor
                    height={700}
                    editorState={this.state.walletMentionsEditorState}
                    onEdit={this.onWalletMentionEdit}
                    saveGraph={this.handleSaveMentions}
                  />
                </>
              ) : (
                <></>
              )}
            </Tab>
          </Tabs>
        )}
      </>
    );
  }
}

WalletNoteEditor.defaultProps = {
  saveGraph: null
};

const mapStateToProps = state => {
  return {
    currency: getCurrency(state),
    clientMode: getClientMode(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(WalletNoteEditor)
);
