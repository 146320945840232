import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Row } from "react-bootstrap";
import StatCard from "../../StatCard";
import { satoshiToBitcoin } from "../../../helpers";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios, { WATCHLIST_API } from "../../../api";
import EditableTitle from "./EditableTitle";
import WatchlistNote from "./WatchlistNote";
import { SMSCheckbox } from "./SMSCheckbox";
import MempoolCheckbox from "./MempoolCheckbox";

class UtxoWatchlistModal extends Component {
  state = {
    openInput: false,
    openOutput: false,
    showStopWatchingModal: false,
    showDeleteModal: false
  };

  // We do this so that the state variables reset when the modal is launched for a new item
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentWatchlistItem["address"] !== this.props.currentWatchlistItem["address"]) {
      this.setState({
        openInput: false,
        openOutput: false,
        showStopWatchingModal: false,
        showDeleteModal: false
      });
    }
  }

  deleteItem = async () => {
    const { status } = await axios.delete(WATCHLIST_API(this.props.currency) + "/modify", {
      data: {
        watchlist_item_type: "UTXO",
        watchlist_item_id: this.props.currentWatchlistItem["id"]
      }
    });

    if (status === 200) {
      this.props.deleteItem("UTXO", this.props.currentWatchlistItem["id"]);
      // close the modals
      this.setState({ showDeleteModal: false });
      this.props.closeModal();
    }
  };

  render() {
    const { currentWatchlistItem, showModal, closeModal, currency, setSMS } = this.props;

    return (
      <Modal show={showModal} onHide={closeModal} className="issuesModal">
        <Modal.Header closeButton>
          <Modal.Title className="entityTitle">UTXO Watchlist Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ paddingLeft: "15px", paddingRight: "15px", marginBottom: "20px" }}>
            <EditableTitle
              title={currentWatchlistItem["title"]}
              updateTitle={this.props.updateTitle}
              id={currentWatchlistItem["id"]}
              type="utxo"
            />
          </div>
          <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
            <div
              style={{
                marginBottom: "10px",
                fontFamily: "Quicksand",
                color: "var(--secondary-color)",
                fontSize: "20px",
                paddingBottom: "5px",
                borderBottom: "2px solid var(--secondary-color)"
              }}
            >
              {" "}
              Summary{" "}
              <div style={{ float: "right", fontSize: "14px" }}>
                <SMSCheckbox
                  value={currentWatchlistItem["sms_messaging"]}
                  setValue={setSMS}
                  currency={currency}
                  entityType={"utxo"}
                  id={currentWatchlistItem["id"]}
                />
              </div>
            </div>
            <StatCard stat={"Address"} value={currentWatchlistItem["address"]} />
            <StatCard stat={"Balance"} value={satoshiToBitcoin(currentWatchlistItem["satoshi"])} />
            <StatCard
              stat={"Output Transaction"}
              value={currentWatchlistItem["transaction_hash"] || "--"}
            />
            <StatCard
              stat={"Input Transaction"}
              value={currentWatchlistItem["spend_trx"]["transaction_hash"] || "--"}
            />
          </div>

          <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
            <p
              style={{
                marginTop: "30px",
                marginBottom: "10px",
                fontFamily: "Quicksand",
                color: "var(--secondary-color)",
                fontSize: "20px",
                paddingBottom: "5px",
                borderBottom: "2px solid var(--secondary-color)"
              }}
            >
              {" "}
              Status{" "}
            </p>
            {!this.props.currentWatchlistItem["spend_trx"]["transaction_hash"] ? (
              <p style={{ color: "#666", fontFamily: "Quicksand", fontWeight: "700" }}>
                This UTXO has not yet been spent. You will be updated here when that happens.
              </p>
            ) : (
              <>
                <p style={{ color: "#666", fontFamily: "Quicksand", fontWeight: "700" }}>
                  This UTXO was used as an input in the following transaction:{" "}
                </p>
                <Link
                  to={`/${currency}/transaction/${
                    this.props.currentWatchlistItem["spend_trx"]["transaction_hash"]
                  }`}
                  target="_blank"
                  onClick={e => e.stopPropagation()}
                >
                  {this.props.currentWatchlistItem["spend_trx"]["transaction_hash"]}
                </Link>
              </>
            )}
          </div>
          <WatchlistNote
            updateNotes={this.props.updateNotes}
            note={currentWatchlistItem["notes"]}
            id={currentWatchlistItem["id"]}
            type="utxo"
          />

          <div style={{ marginTop: "30px", paddingLeft: "15px", paddingRight: "15px" }}>
            <p
              style={{
                marginBottom: "10px",
                fontFamily: "Quicksand",
                color: "var(--secondary-color)",
                fontSize: "20px",
                paddingBottom: "5px",
                borderBottom: "2px solid var(--secondary-color)"
              }}
            >
              {" "}
              Actions{" "}
            </p>
            <Button
              onClick={() => this.setState({ showDeleteModal: true })}
              className="issueButton"
            >
              {" "}
              <FontAwesomeIcon icon={faTrash} /> Delete Watchlist Item{" "}
            </Button>
          </div>
        </Modal.Body>
        <Modal show={this.state.showStopWatchingModal}>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
                <p
                  style={{
                    fontFamily: "Quicksand",
                    color: "#333",
                    fontSize: "18px",
                    fontWeight: "700",
                    textAlign: "center"
                  }}
                >
                  Are you sure you want to stop watching this item?
                </p>
              </Row>
              <Row>
                <Button className="greenButton" style={{ marginRight: "10px" }}>
                  {" "}
                  <FontAwesomeIcon icon={faCheck} style={{ marginRight: "7px" }} />
                  Yes
                </Button>
                <Button
                  className="issueButton"
                  onClick={() => this.setState({ showStopWatchingModal: false })}
                >
                  <FontAwesomeIcon icon={faTimes} style={{ marginRight: "7px" }} />
                  No
                </Button>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showDeleteModal}>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
                <p
                  style={{
                    fontFamily: "Quicksand",
                    color: "#333",
                    fontSize: "18px",
                    fontWeight: "700",
                    textAlign: "center"
                  }}
                >
                  Are you sure you want to delete this item?
                </p>
              </Row>
              <Row>
                <Button
                  className="greenButton"
                  style={{ marginRight: "10px" }}
                  onClick={this.deleteItem}
                >
                  {" "}
                  <FontAwesomeIcon icon={faCheck} style={{ marginRight: "7px" }} />
                  Yes
                </Button>
                <Button
                  className="issueButton"
                  onClick={() => this.setState({ showDeleteModal: false })}
                >
                  <FontAwesomeIcon icon={faTimes} style={{ marginRight: "7px" }} />
                  No
                </Button>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      </Modal>
    );
  }
}

UtxoWatchlistModal.propTypes = {
  currency: PropTypes.string.isRequired,
  currentWatchlistItem: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  setSMS: PropTypes.func.isRequired
};
export default connect(
  null,
  null
)(UtxoWatchlistModal);
