// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { getCurrency } from "../selectors/currency";
import axios, { CUSTOM_TAGS_API, ETHEREUM_API, WALLET_API } from "../api";
import { WALLET_SET_CUSTOM_TAG } from "./actionNames";

/**
 * Calls api to modify or insert custom tag for an entity.
 * @param entity_id
 * @param tag tag to give custom tag
 * @param email email of the owner
 * @param id custom tag id, if null then insert a new custom tag with name
 * @param permission permission for this tag
 * @param enqueueSnackbar notifications
 * @returns {(function(*, *): Promise<void>)|*}
 */
export const setWalletTag = (
  entity_id,
  tag,
  email,
  id,
  permission,
  enqueueSnackbar = null
) => async (dispatch, getState) => {
  // getting currency since the api call is slightly different for ethereum and optionally adding id if it is defined
  const name = getCurrency(getState());
  try {
    if (name === "ethereum") {
      await axios.put(`${ETHEREUM_API}/wallet/${entity_id}/primary-tag`, {
        tag,
        ...(id !== -1 && { id: id })
      });
    } else {
      const { data } = await axios.put(`${WALLET_API(name)}/${entity_id}/primary-tag`, {
        tag,
        ...(id !== -1 && { id: id })
      });
      id = !id ? data.id : id; // if id wasn't defined the api call will return the id of the newly inserted custom tag
    }
  } catch (err) {
    if (enqueueSnackbar) {
      // notify user of errors trying to modify custom tag
      if (err && err.response && err.response.status === 403) {
        enqueueSnackbar(`Not authorized to modify custom tag`, {
          variant: "error"
        });
      } else {
        enqueueSnackbar("Error modifying custom tag", { variant: "error" });
      }
    }
    throw err; //throw error for caller to handle
  }
  // dispatch action to change redux store with new custom tag info
  dispatch({
    type: WALLET_SET_CUSTOM_TAG,
    walletId: entity_id,
    primaryTag: tag,
    email,
    id,
    permission,
    name
  });
};

export const deleteWalletTag = walletId => async (dispatch, getState) => {
  const name = getCurrency(getState());
  try {
    if (name === "ethereum") {
      await axios.delete(`${ETHEREUM_API}/wallet/${walletId}/primary-tag`, {
        tag
      });
    } else {
      await axios.delete(`${WALLET_API(name)}/${walletId}/primary-tag`);
    }
  } catch (err) {}
};

export const removeCustomTagSharedUser = (
  entity_id,
  email,
  enqueueSnackbar = null
) => async (dispatch, getState) => {
  const name = getCurrency(getState());
  try {
    await axios.delete(`${CUSTOM_TAGS_API(name)}/${entity_id}/share`, {
      data: { email, id: entity_id }
    });
  } catch (err) {
    if (enqueueSnackbar) {
      // notify user of errors trying to modify custom tag
      if (err && err.response && err.response.status === 403) {
        enqueueSnackbar(`Not authorized to modify custom tag`, {
          variant: "error"
        });
      } else {
        enqueueSnackbar("Error removing user", { variant: "error" });
      }
    }
    throw err; //throw error for caller to handle
  }
};
