import { getReactRouterAddressLink, getReactRouterWalletLink } from "../../Wallet/helpers";
import { Button, Form, FormControl, FormGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons/faSave";
import {
  faArrowUp,
  faCrown,
  faEye,
  faEyeSlash,
  faPencilAlt,
  faTimesCircle,
  faTrash,
  faWindowClose
} from "@fortawesome/free-solid-svg-icons";
import { ShareButton } from "../../Utils/ShareButton";
import { IconButton } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmail } from "../../../selectors/authentication";
import axios, { CUSTOM_TAGS_API } from "../../../api";
import { removeCustomTagSharedUser } from "../../../actions/customTags";
import { WALLET_REMOVE_CUSTOM_TAG } from "../../../actions/actionNames";
import { useSnackbar } from "notistack";
import { setMainName } from "../../../actions/wallet";
import { formattedTagTypes } from "../../Wallet/CustomWallet/WalletName";

interface CustomTagRow {
  entity_id: number;
  id: number;
  main_name?: string;
  address?: string;
  tag: string;
  attributed_tag: string;
  permission: number;
  tagType: string;
  email: string;
  currency: string;
  handleSetTag: (tag: string, permission: number, id: number) => Promise<void>;
  handleSetOriginalTag: (id: number) => Promise<void>;
  showWalletName?: boolean;
  setMainNameBeforeCallback?: () => void;
}

/**
 * A row of data for CreateCustomTag and CustomTags.
 * @param entity_id entity_id for which the custom tag corresponds to
 * @param id custom tag id
 * @param email email of the owner of the tag
 * @param attributed_tag the original tag name if also attributed, null for not
 * @param address
 * @param tag the custom tag string
 * @param permission permission level for custom tag
 * @param currency
 * @param tagType
 * @param main_name whether or not this is the user's selected main name
 * @param handleSetTag Callback function to use when user modifies the tag
 * @param handleSetOriginalTag Callback function to use when user deletes a tag
 * @param showWalletName
 * @param setMainNameBeforeCallback
 * @constructor
 */
export const CustomTagRow = ({
  entity_id,
  id,
  email,
  attributed_tag,
  address,
  tag,
  permission,
  currency,
  tagType,
  main_name,
  handleSetTag,
  handleSetOriginalTag,
  showWalletName = true,
  setMainNameBeforeCallback
}: CustomTagRow) => {
  const [editTag, setEditTag] = useState(null); // holds value for form when editing the name, null means not editing
  const user_email = useSelector(state => getEmail(state));
  const { enqueueSnackbar } = useSnackbar(); // Notification snackbar
  const dispatch = useDispatch();

  /**
   * Handles when user shares the tag with another user
   * @param email email of the user to share to
   */
  const handleShare = async (email: string) => {
    try {
      await axios.put(`${CUSTOM_TAGS_API(currency)}/${id}/share`, {
        email,
        permission: "view" // by default always view permission atm
      });
    } catch (err) {
      if (err && err.response && err.response.status === 403) {
        enqueueSnackbar(`Not authorized to share custom tag`, {
          variant: "error"
        });
      } else {
        enqueueSnackbar("Error sharing custom tag", { variant: "error" });
      }
      throw err;
    }
  };

  /**
   * Handles when user decides to stop sharing their tag with someone
   * @param email email of user to delete share reference
   */
  const handleDeleteShare = async (email: string) => {
    await dispatch(removeCustomTagSharedUser(id, email, enqueueSnackbar));
    return dispatch({
      type: WALLET_REMOVE_CUSTOM_TAG,
      walletId: entity_id.toString(),
      id,
      name: currency
    });
  };

  // helper function for the save button to use
  const saveTag = async () => {
    await handleSetTag(editTag, permission, id);
    setEditTag(null); // set to not show editing
  };

  /**
   * Gets list of users with share access to this custom tag
   */
  const getSharedUsers = async () => {
    try {
      const { data } = await axios.get(`${CUSTOM_TAGS_API(currency)}/${id}/share`);
      return data;
    } catch (err) {
      if (err && err.response && err.response.status === 403) {
        enqueueSnackbar(`Not authorized to access shared custom tag`, {
          variant: "error"
        });
      } else {
        enqueueSnackbar("Error getting shared users custom tag", {
          variant: "error"
        });
      }
      return [];
    }
  };

  return (
    <tr className="inviteRow staticTableRow" key={`${entity_id}+${tag}`}>
      {showWalletName && (
        <td>
          {/* Getting entity link for ethereum the wallet page is not interesting so we do the address page*/}
          {currency !== "ethereum"
            ? getReactRouterWalletLink(entity_id, currency, attributed_tag, address)
            : getReactRouterAddressLink(entity_id, currency)}
        </td>
      )}
      <td>
        {editTag !== null ? (
          // If edittag is not null then we are editing
          <Form
            autoComplete="off"
            onSubmit={e => {
              e.preventDefault();
              saveTag();
            }}
          >
            <FormGroup controlId="primaryTag" style={{ margin: "0" }}>
              <FormControl
                type="text"
                maxLength={50} // Max custom length of 50
                value={editTag}
                style={{ display: "inline-block", verticalAlign: "middle" }}
                onChange={e => setEditTag((e.target as HTMLTextAreaElement).value)}
              />
            </FormGroup>
          </Form>
        ) : (
          <div key={tag}>{tag}</div>
        )}
      </td>
      <td>
        {main_name &&
          // If we have a main_name specified show a crown when the tag matches else show up arrow
          (tag === main_name ? (
            <FontAwesomeIcon
              icon={faEye}
              style={{
                marginRight: "10px",
                paddingLeft: "10px",
                fontSize: "15px"
              }}
            />
          ) : (
            // If not the main_name show arrow to give user the ability to upgrade this name as the main
            <FontAwesomeIcon
              icon={faEyeSlash}
              className="copyText"
              style={{
                marginRight: "10px",
                paddingLeft: "10px",
                fontSize: "15px",
                color: "grey"
              }}
              onClick={() => {
                setMainNameBeforeCallback && setMainNameBeforeCallback();
                return dispatch(setMainName(tag, tagType, id || 0, entity_id));
              }}
            />
          ))}
        {editTag !== null && permission === 1 ? (
          // Save and cancel button when editing tag
          <>
            <Button
              className="greenButton"
              style={{ marginLeft: "5px", fontSize: "12px" }}
              onClick={() => saveTag()}
              disabled={editTag.length === 0 || editTag === tag}
            >
              <FontAwesomeIcon icon={faSave} style={{ marginRight: "3px" }} /> Save
            </Button>
            <Button
              className="redButton"
              style={{ marginLeft: "5px", fontSize: "12px" }}
              onClick={() => setEditTag(null)}
            >
              <FontAwesomeIcon icon={faWindowClose} style={{ marginRight: "3px" }} />
              Cancel
            </Button>
          </>
        ) : user_email === email ? (
          // If not editing and the owner of the item is the current user show options
          <>
            <Button
              className="blueButton"
              style={{ marginLeft: "3px", fontSize: "12px" }}
              onClick={() => {
                setEditTag(tag);
              }}
            >
              <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: "3px" }} /> Edit
            </Button>
            <Button
              className="redButton"
              style={{ marginLeft: "3px", fontSize: "12px" }}
              onClick={() => handleSetOriginalTag(id)}
            >
              <FontAwesomeIcon icon={faTrash} style={{ marginRight: "3px" }} /> Delete
            </Button>
            <ShareButton
              callback={(email: string) => {
                return handleShare(email);
              }}
              removeUser={async email => {
                await handleDeleteShare(email);
              }}
              getSharedUsers={() => {
                return getSharedUsers();
              }}
            />
          </>
        ) : // Not the owner of the tag so just show who owns it.
        tagType === "customTag" ? (
          <>
            <>{`Owned by ${email}`}</>
            <IconButton
              size="small"
              onClick={async () => {
                await handleDeleteShare(user_email);
              }}
            >
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="deleteButton"
                style={{ fontSize: "20px" }}
              />
            </IconButton>
          </>
        ) : (
          <>{formattedTagTypes[tagType as keyof typeof formattedTagTypes] || tagType}</>
        )}
      </td>
    </tr>
  );
};
