/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";

import { numberWithCommas, satoshiToBitcoin } from "../../../helpers";
import { useSelector } from "react-redux";
import { getWalletName } from "../../../selectors/wallet";

const OutputWalletRow = ({
  keyProp,
  walletId,
  satoshi,
  primaryTag,
  handleOpenWalletModal,
  getWalletLink,
  currency,
  convertPriceToUsd,
  toggleUsdDisplay,
  showAsUsd
}) => {
  // Never mark change in this view.
  const showWallet = walletId !== 0;

  const bitcoin = satoshiToBitcoin(satoshi);

  const { name } = useSelector(state => {
    if (showWallet && walletId) {
      return getWalletName(state, walletId);
    } else {
      return {
        tagType: "coinbase",
        name: null
      };
    }
  });
  const walletString = name || "Nonstandard Output";
  let walletLink = null;
  if (walletString === "Nonstandard Output") {
    walletLink = <p>Nonstandard Output</p>;
  } else {
    walletLink = getWalletLink(walletId, name, handleOpenWalletModal, currency);
  }

  return (
    <tr key={keyProp} className="inviteRowThin staticTableRow">
      <td>{showWallet ? walletLink : <span>Unclustered</span>}</td>
      <td>
        <div className="text-right">
          {showAsUsd ? "$" + numberWithCommas(convertPriceToUsd(bitcoin)) : bitcoin}
        </div>
      </td>
    </tr>
  );
};

export default OutputWalletRow;

OutputWalletRow.propTypes = {
  keyProp: PropTypes.string.isRequired,
  walletId: PropTypes.number,
  satoshi: PropTypes.number.isRequired,
  // This is null when we have a coinbase transaction
  primaryTag: PropTypes.string,
  handleOpenWalletModal: PropTypes.func,
  getWalletLink: PropTypes.func.isRequired
};

OutputWalletRow.defaultProps = {
  primaryTag: null,
  walletId: null,
  handleOpenWalletModal: null
};
