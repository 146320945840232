import { List, Map, Record } from "immutable";
import {
  ADDRESS_COIN_SWAP_FETCH_SUCCESS,
  ADDRESS_GRAPH_TRANSACTIONS_FETCH_SUCCESS,
  ADDRESS_HISTORY_STATS_SUCCESS,
  ADDRESS_INTERNAL_TRANSACTIONS_FETCH_SUCCESS,
  ADDRESS_MUTUAL_WALLETS_FETCH_SUCCESS,
  ADDRESS_SUMMARY_FETCH_SUCCESS,
  ADDRESS_TOKEN_FETCH_SUCCESS,
  ADDRESS_TRANSACTION_TOKEN_FETCH_SUCCESS,
  LOGGING_OUT,
  MUTUAL_ADDRESS_TOKENS_FETCH_SUCCESS,
  MUTUAL_ADDRESS_TRANSACTIONS_FETCH_SUCCESS,
  WALLET_SET_CUSTOM_TAG
} from "../../actions/actionNames";
import { addAddressCoinSwap, CoinSwap } from "../address";
import { getWalletRecord } from "../wallet";
import { getAddressSummary } from "../../selectors/address";

export const AddressSummary = Record({
  account_id: "",
  attribution: null,
  currentBalance: "",
  inCase: false,
  lastUsedBlockHeight: 0,
  queryDepositCount: 0,
  queryDeposits: 0.0,
  queryEndingBalance: 0.0,
  querySpendCount: 0,
  querySpent: 0,
  totalDepositCount: 0,
  totalDeposits: 0.0,
  totalSpendCount: 0,
  totalSpent: 0.0,
  walletId: "",
  walletName: null,
  input_count: 0,
  output_count: 0,
  first_transaction_id: "",
  internal_input_count: 0,
  internal_output_count: 0,
  num_tokens: 0,
  token: null,
  primaryTag: "",
  originalTag: "",
  ens_names: [],
  creation_transaction_id: "",
  category: null,
  histSent: null,
  histRecv: null,
  source_name: null,
  source_confidence: null
});

export const TokenSummary = Record({
  creation_transaction_id: "",
  transfer_count: 0,
  token_standard: 0,
  token_name: null,
  symbol: null,
  decimals: 0,
  logo: null,
  color: "#bb4242"
});

export const AddressTransaction = Record({
  transaction: null,
  sender_id: null,
  receiver_id: null,
  block_id: null,
  value: null,
  trx_fee: null,
  price: 0,
  priceAvailable: false,
  timestamp: null
});

export const InternalTransaction = Record({
  transaction: null,
  sender_id: null,
  receiver_id: null,
  block_id: null,
  call_index: null,
  value: null,
  trx_fee: null,
  function_signature: null,
  price: 0,
  priceAvailable: false,
  timestamp: null
});

export const Transactions = Record({
  transactions: List(),
  offset: 0
});

export const AddressGraphTransactionList = Record({
  newestFirst: List(),
  newestFirstMore: true,
  oldestFirst: List(),
  oldestFirstMore: true,
  currentOrder: "newestFirst"
});

export const AddressGraphTransactionsForToken = Record({
  sent: new AddressGraphTransactionList(),
  received: new AddressGraphTransactionList()
});

export const AddressGraphTransactions = Record({
  ether: new AddressGraphTransactionsForToken(),
  erc20: new AddressGraphTransactionsForToken(),
  erc721: new AddressGraphTransactionsForToken()
});

export const AddressToken = Record({
  name: "",
  symbol: "",
  decimals: 0,
  token_account_id: "",
  token_balance: 0,
  total_token_output: 0,
  total_token_input: 0,
  input_count: 0,
  output_count: 0
});

export const AddressTokens = Record({
  tokens: List(),
  offset: 0
});

export const MutualTransactions = Record({
  tokens: List(),
  transactions: Map()
});

export const AddressRecord = Record({
  summary: new AddressSummary(),
  transactions: Map(),
  internalTransactions: Map(),
  mutualTransactions: Map(),
  tokens: new AddressTokens(),
  // Delete this once we sync transaction sources for summary pages and the graph
  graphTransactions: new AddressGraphTransactions(),
  ipHistory: Map(),
  coin_swap: false,
  coin_swap_data: List()
});

export const getAddressRecord = (state, address) => state.get(address) || new AddressRecord();

export const addAddressSummary = (state, action) => {
  // Pull the address and api data out of the action body
  const { address, data } = action;

  // Grab or create the address record and create the address summary
  let addressRecord = getAddressRecord(state, address);
  const addressSummary = new AddressSummary({
    // Idk if this is genius to fill in the values or peak laziness lol
    ...data,
    token: data["token_standard"]
      ? new TokenSummary({
          ...data
        })
      : null, // Put null if there is no token standard meaning this address is not a token
    primaryTag: data["custom_tag"] || data["attribution"],
    originalTag: data["attribution"] || data["account_id"]
  });

  addressRecord = addressRecord.set("summary", addressSummary);

  // set coin swap data
  let coinSwaps = addressRecord.get("coin_swap_data");

  data.coin_swap.forEach(coinSwap => {
    const coinSwapRecord = new CoinSwap(coinSwap);
    coinSwaps = coinSwaps.push(coinSwapRecord);
  });
  addressRecord = addressRecord.set("coinSwap", coinSwaps);
  addressRecord = addressRecord.set("coin_swap", data.coin_swap.length !== 0);
  addressRecord = addressRecord.set("coin_swap_data", coinSwaps);

  // set the address record and return
  return state.set(address, addressRecord);
};

export const addAddressHistory = (state, action) => {
  // Pull the address and api data out of the action body
  const { address, data } = action;
  let addressRecord = state.get(address);
  if (!addressRecord) {
    return state;
  }
  let addressSummary = addressRecord.get("summary");
  if (!addressSummary) {
    return state;
  }
  for (const [key, value] of Object.entries(data)) {
    addressSummary = addressSummary.set(key, value);
  }
  addressRecord = addressRecord.set("summary", addressSummary);
  return state.set(address, addressRecord);
};

export const addAddressTokens = (state, action) => {
  // Pull the address and api data out of the action body
  const { address, data } = action;

  // Grab or create the address record and create the address summary
  let addressRecord = getAddressRecord(state, address);

  let tokens = [];
  if (addressRecord.getIn(["tokens"])) {
    const tokensJS = addressRecord.get("tokens").toJS();
    if (tokensJS["tokens"]) {
      tokens = tokensJS["tokens"];
    }
  }
  data.forEach(({ token_account_id, ...curr }) => {
    tokens.push(
      new AddressToken({
        ...curr,
        token_account_id: token_account_id === "" ? "0" : token_account_id // Ether has an empty token_account_id
      })
    );
  });

  const offset = tokens.length + 1;
  const addressTokens = new AddressTokens({
    tokens: tokens,
    offset: offset
  });
  addressRecord = addressRecord.set("tokens", addressTokens);
  // set the address record and return
  return state.set(address, addressRecord);
};

export const addMutualAddressTransactions = (state, { source, target, data, token_account }) => {
  let addressRecord = getAddressRecord(state, source);
  let transactions = addressRecord.get("mutualTransactions");
  let cur_data = [];
  let transactionsJS = transactions.getIn([target, token_account, "transactions"]);
  if (transactionsJS) {
    transactionsJS = transactionsJS.toJS();
    if (transactionsJS["transactions"]) {
      cur_data = transactionsJS["transactions"];
    }
  }

  data.forEach(({ transaction, sender_id, receiver_id, value, trx_fee, block_id, timestamp }) => {
    cur_data.push(
      new AddressTransaction({
        transaction,
        sender_id,
        receiver_id,
        value,
        trx_fee,
        block_id,
        timestamp
      })
    );
  });

  const offset = cur_data.length + 1;
  const tokenTransactions = new Transactions({
    transactions: cur_data,
    offset: offset
  });
  // set the address record and return
  addressRecord = addressRecord.setIn(
    ["mutualTransactions", target, "transactions", token_account],
    tokenTransactions
  );
  return state.set(source, addressRecord);
};

export const addMutualAddressTokens = (state, { source, target, data }) => {
  let addressRecord = getAddressRecord(state, source);
  let tokens = [];
  let targetRecord = addressRecord.getIn(["mutualTransactions", target, "tokens"]);
  if (targetRecord) {
    const tokensJS = targetRecord.get("tokens").toJS();
    if (tokensJS["tokens"]) {
      tokens = tokensJS["tokens"];
    }
  }
  data.forEach(({ token_account_id, ...curr }) => {
    tokens.push(
      new AddressToken({
        ...curr,
        token_account_id: token_account_id === "" ? "0" : token_account_id // Ether has an empty token_account_id
      })
    );
  });

  const offset = tokens.length + 1;
  const addressTokens = new AddressTokens({
    tokens: tokens,
    offset: offset
  });
  addressRecord = addressRecord.setIn(["mutualTransactions", target, "tokens"], addressTokens);
  // set the address record and return
  return state.set(source, addressRecord);
};

export const addAddressTransactions = (state, action) => {
  // Pull the address and api data out of the action body
  const { address, data, token_account, filter } = action;
  //TODO find something else than stringify, make own consistent one maybe
  const filterString = filter ? JSON.stringify(filter) : "base";
  // Grab or create the address record and create the address summary
  let addressRecord = getAddressRecord(state, address);

  // Make the transactions object
  let transactions = addressRecord.getIn(["transactions", filterString]) || new Map();

  let cur_data = [];

  let transactionsJS = transactions.get(token_account);
  if (transactionsJS) {
    transactionsJS = transactionsJS.toJS();
    if (transactionsJS["transactions"]) {
      cur_data = transactionsJS["transactions"];
    }
  }

  data["transactions"].forEach(
    ({ transaction, sender_id, receiver_id, value, trx_fee, block_id, timestamp, price, priceAvailable }) => {
      cur_data.push(
        new AddressTransaction({
          transaction,
          sender_id,
          receiver_id,
          value,
          trx_fee,
          block_id,
          timestamp,
          price,
          priceAvailable
        })
      );
    }
  );

  const tokenTransactions = new Transactions({
    transactions: cur_data,
    offset: data["offset"]
  });
  transactions = transactions.set(token_account, tokenTransactions);
  addressRecord = addressRecord.setIn(["transactions", filterString], transactions);
  // set the address record and return
  return state.set(address, addressRecord);
};

export const addInternalTransactions = (state, action) => {
  // Pull the address and api data out of the action body
  const { address, data, filter } = action;
  const filterString = filter ? JSON.stringify(filter) : "base";

  // Grab or create the address record and create the address summary
  let addressRecord = getAddressRecord(state, address);

  // Make the transactions object
  let transactions = addressRecord.getIn(["internalTransactions", filterString]) || new Map();

  let cur_data = [];
  if (transactions && transactions["transactions"] && transactions["transactions"].length > 0) {
    cur_data = transactions["transactions"].toJS();
  }

  data["transactions"].forEach(
    ({
      transaction,
      sender_id,
      receiver_id,
      value,
      trx_fee,
      block_id,
      call_index,
      function_signature,
      price,
      priceAvailable,
      timestamp
    }) => {
      cur_data.push(
        new InternalTransaction({
          transaction,
          sender_id,
          receiver_id,
          value,
          trx_fee,
          block_id,
          call_index,
          function_signature,
          price,
          priceAvailable,
          timestamp
        })
      );
    }
  );

  const internalTransactions = new Transactions({
    transactions: new List(cur_data),
    offset: data["offset"]
  });
  addressRecord = addressRecord.setIn(["internalTransactions", filterString], internalTransactions);
  // set the address record and return
  return state.set(address, addressRecord);
};

const setCustomTag = (state, { walletId, primaryTag }) => {
  let walletRecord = getAddressRecord(state, walletId);
  walletRecord = walletRecord.setIn(["summary", "primaryTag"], primaryTag);
  return state.set(walletId, walletRecord);
};

const makeAddressReducerEthereum = coin => {
  // This just returns the state, which is why each of the switch funcs returns an ImmutableJs set
  return (state = Map(), action) => {
    // If the coin doesn't match the reducer, just return the state.
    if (action === undefined || action.name !== coin) {
      return state;
    }

    switch (action.type) {
      case ADDRESS_SUMMARY_FETCH_SUCCESS:
        return addAddressSummary(state, action);
      case ADDRESS_HISTORY_STATS_SUCCESS:
        return addAddressHistory(state, action);
      case ADDRESS_COIN_SWAP_FETCH_SUCCESS:
        return addAddressCoinSwap(state, action);
      case ADDRESS_GRAPH_TRANSACTIONS_FETCH_SUCCESS:
        return addGraphAddressTransactions(state, action);
      case MUTUAL_ADDRESS_TRANSACTIONS_FETCH_SUCCESS:
        return addMutualAddressTransactions(state, action);
      case MUTUAL_ADDRESS_TOKENS_FETCH_SUCCESS:
        return addMutualAddressTokens(state, action);
      case ADDRESS_TRANSACTION_TOKEN_FETCH_SUCCESS:
        return addAddressTransactions(state, action);
      case ADDRESS_INTERNAL_TRANSACTIONS_FETCH_SUCCESS:
        return addInternalTransactions(state, action);
      case ADDRESS_TOKEN_FETCH_SUCCESS:
        return addAddressTokens(state, action);
      case WALLET_SET_CUSTOM_TAG:
        return setCustomTag(state, action);
      case LOGGING_OUT:
        return Map();
      default:
        return state;
    }
  };
};

export default makeAddressReducerEthereum;
