import axios, { ETHEREUM_API } from "../../api";
import {
  FETCHING_WALLET_SUMMARY,
  WALLET_SUMMARY_FETCH_FAILURE,
  WALLET_SUMMARY_FETCH_SUCCESS,
} from "../actionNames";
import { getWalletSummary } from "../../selectors/ethereum/wallet";

export const fetchWalletSummaryEthereum = walletId => async (dispatch, getState) => {
  // First grab the address summary from redux
  const summary = getWalletSummary(getState(), walletId);

  // If the summary is already filled out, we don't need to fetch it again
  if (summary !== null) {
    return;
  }
  // If the data wasn't in the store, fetch it using the api
  dispatch({ type: FETCHING_WALLET_SUMMARY });
  try {
    const { data } = await axios.get(`${ETHEREUM_API}/wallet/${walletId}`);
    dispatch({
      type: WALLET_SUMMARY_FETCH_SUCCESS,
      walletId,
      data,
      name: "ethereum",
    });
  } catch (err) {
    dispatch({
      type: WALLET_SUMMARY_FETCH_FAILURE,
      walletId,
      name: "ethereum",
    });
    throw err;
  }
};
