// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import axios, { ETHEREUM_API, SEARCH_API } from "../api";
import { SEARCH_SUCCESS, WALLET_SET_NAMES } from "./actionNames";
import { getCurrency } from "../selectors/currency";
import history from "../components/history";

/**
 * Calls the search api call with query and all entity types and redirects to appropriate page based on search results.
 *
 * If it returns only 1 wallet result, redirect to wallet page.
 *  This is so if the user inputs a direct match to a wallet it just goes to the wallet
 *  instead of showing addresses and transactions, that are not useful
 *
 * If the search result returns one item, redirect to that item's page.
 *
 * If the search returns multiple items redirect to the search page to display results
 *
 * If the search returns nothing redirect to the no search results page.
 *
 * @param query term to search
 * @returns {(function(*, *): Promise<void>)|*}
 */
export const fetchSearchResult = query => async (dispatch, getState) => {
  // Do not cache these until a way of handling updated user_tags is added
  let searchResult;
  const name = getCurrency(getState());
  const api = `${SEARCH_API(name)}`;

  // Fetch data and dispatch to reducer for state update with search results
  try {
    ({ data: searchResult } = await axios.get(api, {
      params: { query: query, category: "all" }
    }));
    await dispatch({
      type: SEARCH_SUCCESS,
      query,
      data: searchResult,
      name
    });
    const walletIds = {};
    const { wallets } = searchResult;
    wallets &&
      wallets.forEach(({ walletId, name }) => !walletIds[walletId] && (walletIds[walletId] = name));
    const { custom_wallets } = searchResult;
    custom_wallets &&
      custom_wallets.forEach(
        ({ walletId, name }) => !walletIds[walletId] && (walletIds[walletId] = name)
      );
    Object.keys(walletIds).length > 0 &&
      dispatch({
        type: WALLET_SET_NAMES,
        walletIds,
        name
      });
  } catch (err) {
    console.error(err);
    throw err;
  }

  //Redirect logic
  if (searchResult) {
    // Counting total amount of search results returned by iterating through every entity type returned and adding list
    let amount = 0;
    for (let [_, value] of Object.entries(searchResult)) {
      amount += value.length;
    }

    // If the search result matched a wallet directly redirect to wallet page
    if (amount && amount === 1) {
      //search returned one item redirect to that page
      // Search results for the entity that had one search result
      for (let [_, value] of Object.entries(searchResult)) {
        if (value.length === 1) {
          history.push(`/${name}${value[0]["url"]}`);
        }
      }
    } else if (amount && amount > 1) {
      // if multiple results redirect to search page
      history.push(`/search/${query}`);
    } else {
      // redirect to no results
      history.push("/no-search-results");
    }
  }
};
