/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

// This wrapper is intended to provide Address, Wallet, and Transaction with
// modals for adding items to a graph. A function `getCreateGraphModal` is
// passed to the wrapped component to render a dropdown for adding an item
// to a graph.

import React from "react";
import PropTypes from "prop-types";
import { DropdownButton, MenuItem } from "react-bootstrap";

import CreateGraphModal from "./Home/CreateGraphModal";
import PreloadGraphModal from "./PreloadGraphModal";

// getPreloadInfo should, given the props passed to the HOC, return
// the type and item to pass to the preload modals.
const withAddPreloadModals = (Component, getPreloadInfo) => {
  class WithAddPreloadModals extends React.Component {
    static propTypes = {
      createGraph: PropTypes.func.isRequired,
      enableAddToGraph: PropTypes.bool
    };

    static defaultProps = {
      enableAddToGraph: null
    };

    constructor(props) {
      super(props);
      this.state = {
        showNewGraphModal: false,
        showExistingGraphModal: false
      };
    }

    getCreateGraphModal = () => {
      if (!this.props.enableAddToGraph) {
        return null;
      }

      const { type, item, processed } = getPreloadInfo(this.props);
      const buttonText = "Add to graph";
      // This was buggy when I tried to pass callbacks that directly opened the correct modal.
      if (!processed) {
        return <></>;
      } else {
        return (
          <span>
            <DropdownButton className="blueButton" title={buttonText} id="dropdown-basic">
              <MenuItem eventKey="new" onSelect={key => this.openGraphModal(key)}>
                New graph
              </MenuItem>
              <MenuItem eventKey="existing" onSelect={key => this.openGraphModal(key)}>
                Existing graph
              </MenuItem>
            </DropdownButton>
            <CreateGraphModal
              createGraph={this.props.createGraph}
              show={this.state.showNewGraphModal}
              close={this.closeNewGraphModal}
            />
            <PreloadGraphModal
              type={type}
              close={this.closeExistingGraphModal}
              show={this.state.showExistingGraphModal}
              item={item}
            />
          </span>
        );
      }
    };

    // This just forwards to either openExistingGraphModal or openNewGraphModal
    // depending on key.
    openGraphModal = key => {
      if (key === "new") {
        this.openNewGraphModal();
      } else {
        this.openExistingGraphModal();
      }
    };

    closeExistingGraphModal = () => {
      this.setState({
        showExistingGraphModal: false
      });
    };

    openExistingGraphModal = () => {
      this.setState({
        showExistingGraphModal: true
      });
    };

    closeNewGraphModal = () => {
      this.setState({
        showNewGraphModal: false
      });
    };

    openNewGraphModal = () => {
      this.setState({
        showNewGraphModal: true
      });
    };

    render() {
      return <Component getCreateGraphModal={this.getCreateGraphModal} {...this.props} />;
    }
  }

  WithAddPreloadModals.displayName = `withAddPreloadModals(${Component.displayName ||
    Component.name})`;

  return WithAddPreloadModals;
};

export default withAddPreloadModals;
