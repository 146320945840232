import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { RiskSpeedometer } from "./RiskSpeedometer";
import StatCard from "../StatCard";
import { useSelector } from "react-redux";
import { getCurrency } from "../../selectors/currency";
import { entityType } from "../../types";
import { RiskPieChart } from "./RiskPieChart";

export interface RiskScoreBaseProps {
  type: entityType;
  results: any;
}

export const RiskScoreBase: React.FC<RiskScoreBaseProps> = ({ type, results }) => {
  const { risk_level, risk_reason, risk_reasons, level, sent_risk, rec_risk } = results;
  const levelInt = parseInt(level, 10);
  const currency = useSelector(state => getCurrency(state));

  return (
    <Col style={{ padding: "0" }}>
      {type === "address" && risk_reason && (
        <div>
          {typeof risk_reason === "string" ? (
            <p
              style={{
                textAlign: "center",
                fontSize: "28px",
                color: "#666",
                marginBottom: "30px"
              }}
            >
              {risk_reason}
            </p>
          ) : (
            <div style={{ fontSize: 18 }}>
              {Object.entries(risk_reason)
                .filter(([key, value]) => key && value)
                .map(([key, value]) => (
                  <StatCard
                    stat={key.charAt(0).toUpperCase() + key.slice(1) + ":"}
                    value={value}
                    pullRight={false}
                  />
                ))}
            </div>
          )}
        </div>
      )}
      <div style={{ textAlign: "center" }}>
        <RiskSpeedometer level={levelInt} />
      </div>
      <p
        style={{
          textAlign: "center",
          fontSize: "30px",
          color: "#666",
          marginBottom: "50px"
        }}
      >
        {risk_level + " Risk"}{" "}
      </p>
      {type === "transaction" ? (
        <>
          <p style={{ paddingLeft: "15px", fontSize: "22px", color: "#666" }}>
            The Risk Score for this Transaction was determined because it...
          </p>
          {currency !== "ethereum" ? (
            Object.keys(risk_reasons).map(reason => {
              return (
                risk_reasons[reason].length !== 0 && (
                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    style={{ marginBottom: "20px", minHeight: "100px" }}
                  >
                    <p style={{ fontSize: "18px", fontWeight: "600" }}> {reason} the following: </p>
                    <ul>
                      {risk_reasons[reason].map((to: string) => (
                        <li key={reason + to}>
                          {" "}
                          <p style={{ fontSize: "15px" }}>{to}</p>{" "}
                        </li>
                      ))}
                    </ul>
                  </Col>
                )
              );
            })
          ) : (
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                color: "#666",
                marginBottom: "50px"
              }}
            >
              {risk_reason}
            </p>
          )}
        </>
      ) : (
        <Row style={{ textAlign: "center", marginTop: "10px" }}>
          <Col md={9} lg={9} style={{ textAlign: "center", float: "none", margin: "0 auto" }}>
            <Col md={6} lg={6} style={{ textAlign: "center" }}>
              <RiskPieChart direction={"Sent"} risks={sent_risk} />
            </Col>
            <Col md={6} lg={6} style={{ textAlign: "center" }}>
              <RiskPieChart direction={"Received"} risks={rec_risk} />
            </Col>
          </Col>
        </Row>
      )}
    </Col>
  );
};
