/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import moment from "moment";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, Grid, Modal, Row, Tab, Table, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import Papa from "papaparse/papaparse.min";
import axios, { ISSUES_API } from "../../api";
import { ChatBubble } from "./ChatBubble";

class AdminIssues extends Component {
  state = {
    issues: [],
    showModal: false,
    activeTab: "unresolved",
    currentIssue: {
      user_id: null,
      entity_id: null,
      entity_type_id: null,
      issue_id: null,
      status: "",
      messages: [],
      seen: false,
    },
  };

  entityType = id => {
    const types = {
      0: "Address",
      1: "Wallet",
      2: "Transaction",
      3: "Custom Wallet",
      4: "Note",
      5: "Graph",
    };
    return types[id];
  };

  currencyType = string => {
    const types = {
      bitcoin: "Bitcoin",
      "bitcoin-sv": "Bitcoin SV",
      "bitcoin-gold": "Bitcoin Gold",
      "bitcoin-cash": "Bitcoin Cash",
      litcoin: "Litecoin",
    };
    return types[string];
  };

  componentWillMount() {
    this.fetchIssues();
  }

  openIssueModal = issue => this.setState({ showModal: true, currentIssue: issue });

  closeIssueModal = e => {
    this.setState({
      showModal: false,
      resolutionNote: "",
      currentIssue: {
        user_id: null,
        entity_id: null,
        entity_type_id: null,
        issue_id: null,
        status: "",
        messages: [],
        seen: false,
      },
    });
  };

  fetchIssues = async () => {
    try {
      const { data } = await axios.get(ISSUES_API(this.props.currency, "issues-researcher"));
      this.setState({
        issues: data.issues,
      });
    } catch (err) {
      throw err;
    }
  };

  handleSelect = key => {
    this.setState({ activeTab: key });
  };

  csvDownload = () => {
    if (this.state.issues === null) {
      return null;
    }

    const data = this.state.issues.map(key => ({
      Currency: this.currencyType(key.currency),
      "Issue #": key.issue_id,
      "Issue Type": key.issue_type,
      "Entity Type": this.entityType(key.entity_type_id),
      Entity: key.entity_id,
      User_id: key.user_id,
      "User Email": key.email,
      Memo: key.messages[0].message,
      Submitted: moment(key.submitted_timestamp)
        .utc()
        .format("MM-DD-YYYY"),
      Status: key.status,
    }));

    let csv = Papa.unparse(data);

    // The encodeURIComponent wrapper around the csv object was necessary for firefox compatibility
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    }
    return (
      <a className="downloadButton2" href={csv} download="whitesail-admin-issues.csv">
        {" "}
        <FontAwesomeIcon icon={faDownload} /> Download{" "}
      </a>
    );
  };

  render() {
    // Need to add an undefined check
    const resolvedIssues = [];
    const unresolvedIssues = [];
    const underReviewIssues = [];

    Object.keys(this.state.issues).forEach(key => {
      const value = this.state.issues[key];
      if (value !== undefined) {
        const {
          user_id,
          entity_id,
          issue_id,
          memo,
          submitted_timestamp,
          status,
          currency,
          email,
          issue_type,
        } = value;

        // Only show unresolved issues unless the checkbox is active
        if (status === "Resolved") {
          resolvedIssues.push(
            <tr
              key={issue_id}
              className="inviteRow hoverableTableRow"
              onClick={() => {
                this.openIssueModal(value);
              }}
            >
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {this.currencyType(currency)}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{issue_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{issue_type}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{email}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{entity_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {moment(submitted_timestamp)
                  .utc()
                  .format("MM-DD-YYYY")}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>Resolved</td>
            </tr>
          );
        } else if (status === "Under Review") {
          underReviewIssues.push(
            <tr
              key={issue_id}
              className="inviteRow hoverableTableRow"
              onClick={() => {
                this.openIssueModal(value);
              }}
            >
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {this.currencyType(currency)}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{issue_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{issue_type}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{email}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{entity_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {moment(submitted_timestamp)
                  .utc()
                  .format("MM-DD-YYYY")}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>Under Review</td>
            </tr>
          );
        } else {
          unresolvedIssues.push(
            <tr
              key={issue_id}
              className="inviteRow hoverableTableRow"
              onClick={() => {
                this.openIssueModal(value);
              }}
            >
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {this.currencyType(currency)}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{issue_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{issue_type}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{email}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{entity_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {moment(submitted_timestamp)
                  .utc()
                  .format("MM-DD-YYYY")}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>Pending</td>
            </tr>
          );
        }
      }
    });

    return (
      <Grid>
        <Row style={{ marginBottom: "15px" }}>
          <Col lg={12}>
            <p
              style={{
                display: "inline",
                marginRight: "30px",
                fontSize: "30px",
                color: "var(--base-color)",
                fontWeight: "700",
              }}
            >
              {" "}
              All Support Tickets{" "}
            </p>
            {this.csvDownload()}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Tabs
              className="smallerTabs"
              activeKey={this.state.activeTab}
              id="items-tab"
              onSelect={this.handleSelect}
              animation={false}
            >
              <Tab eventKey="unresolved" title="Current">
                <br />
                <Table className="inviteTable" style={{ maxWidth: "100%", tableLayout: "fixed" }}>
                  <thead>
                    <tr>
                      <th style={{ width: "8%", textOverflow: "ellipsis", overflow: "hidden" }}>
                        Currency
                      </th>
                      <th style={{ width: "6%", textOverflow: "ellipsis", overflow: "hidden" }}>
                        Issue #
                      </th>
                      <th style={{ width: "10%", textOverflow: "ellipsis", overflow: "hidden" }}>
                        Issue Type
                      </th>
                      <th style={{ width: "22%", textOverflow: "ellipsis", overflow: "hidden" }}>
                        Email
                      </th>
                      <th style={{ width: "29%", textOverflow: "ellipsis", overflow: "hidden" }}>
                        Entity
                      </th>
                      <th style={{ width: "10%", textOverflow: "ellipsis", overflow: "hidden" }}>
                        Submitted
                      </th>
                      <th style={{ width: "15%", textOverflow: "ellipsis", overflow: "hidden" }}>
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {underReviewIssues}
                    {unresolvedIssues}
                  </tbody>
                </Table>
              </Tab>
              <Tab eventKey="resolved" title="Resolved">
                <br />
                <Table className="inviteTable" style={{ maxWidth: "100%", tableLayout: "fixed" }}>
                  <thead>
                    <tr>
                      <th style={{ width: "8%" }}>Currency</th>
                      <th style={{ width: "6%", textOverflow: "ellipsis", overflow: "hidden" }}>
                        Issue #
                      </th>

                      <th style={{ width: "10%", textOverflow: "ellipsis", overflow: "hidden" }}>
                        Issue Type
                      </th>
                      <th style={{ width: "22%", textOverflow: "ellipsis", overflow: "hidden" }}>
                        Email
                      </th>
                      <th style={{ width: "24%", textOverflow: "ellipsis", overflow: "hidden" }}>
                        Entity
                      </th>
                      <th style={{ width: "10%" }}>Submitted</th>
                      <th style={{ width: "20%" }}>Status</th>
                    </tr>
                  </thead>
                  <tbody>{resolvedIssues}</tbody>
                </Table>
              </Tab>
            </Tabs>
          </Col>
        </Row>
        <Modal show={this.state.showModal} onHide={this.closeIssueModal} className="issuesModal">
          <Modal.Header closeButton>
            <Modal.Title className="entityTitle">
              {this.currencyType(this.state.currentIssue.currency)} Issue #
              {this.state.currentIssue.issue_id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p className="entityType"> {this.currencyType(this.state.currentIssue.currency)}</p>{" "}
              <p className="entityType">
                {" "}
                {this.entityType(this.state.currentIssue.entity_type_id)}{" "}
              </p>
              <p className="entityName">{this.state.currentIssue.entity_id}</p>
            </div>
            <div className="entityDiv">
              <p className="entityPrompt"> Messages </p>
              <div className="chat">
                <div className="chatContent">
                  {this.state.currentIssue.messages
                    .map(x =>
                      !x.message_from_user ? (
                        <ChatBubble
                          timestamp={x.timestamp}
                          text={x.message}
                          email={"Cryptovoyant Support"}
                          outgoing_message={true}
                        >
                          {x.message}
                        </ChatBubble>
                      ) : (
                        <ChatBubble
                          timestamp={x.timestamp}
                          text={x.message}
                          email={this.state.currentIssue.email}
                          outgoing_message={false}
                        >
                          {x.message}
                        </ChatBubble>
                      )
                    )
                    .reverse()}
                </div>
              </div>
            </div>
            <div>
              <p className="entityPrompt">
                {this.state.currentIssue.status === "Resolved"
                  ? "This issue has been resolved."
                  : this.state.currentIssue.status === "Pending"
                  ? "This issue was successfully submitted. An analyst will address it shortly."
                  : "This issue is currently under analyst review."}
              </p>
            </div>
          </Modal.Body>
        </Modal>
        <br />
        {this.props.throwExceptionButtonEnabled && (
          <Button onClick={() => throwError()}> Throw error </Button>
        )}
      </Grid>
    );
  }
}

AdminIssues.propTypes = {
  currency: PropTypes.string.isRequired,
};

export default AdminIssues;
