/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Col, Form, FormControl, FormGroup, Grid, Row } from "react-bootstrap";

import axios, { CSRF_ERROR_MESSAGE, PASSWORD_RESET_API } from "../api";
import LogoHeader from "./LogoHeader";
import { reactRouterMatchShape } from "../prop-types";
import history from "./history";
import PasswordRequirements from "./PasswordRequirements";
import { handleCheckMatch, handleCheckRequirements } from "../helpers";

class PasswordReset extends Component {
  state = {
    notification: {
      type: null,
      message: "",
    },
    specialCharacter: true,
    number: true,
    letter: true,
    loading: false,
    pwMatch: true,
    pwLength: true,
  };

  handleSubmit = async e => {
    e.preventDefault();
    await this.resetPassword();
  };

  handleDismiss = () =>
    this.setState({
      notification: {
        type: null,
        message: "",
      },
    });

  resetPassword = async () => {
    const { token } = this.props.match.params;
    const password = this.newPassword1.value;
    try {
      await axios.post(PASSWORD_RESET_API, {
        token,
        password,
      });
      this.setState({
        notification: {
          type: "success",
          message: "Password successfully reset",
        },
      });
      history.push("/login");
    } catch (err) {
      if (err.response.status === 403) {
        this.setState({
          notification: {
            type: "danger",
            message: "Token is invalid.",
          },
        });
        return;
      }

      if (err.response.status === 403) {
        this.setState({
          notification: {
            type: "danger",
            message: CSRF_ERROR_MESSAGE,
          },
        });
        return;
      }

      throw err;
    }
  };

  renderNotification = () => {
    const { type, message } = this.state.notification;
    if (type) {
      return (
        <Row>
          <Col lg={6} lgOffset={3}>
            <Alert bsStyle={type} onDismiss={this.handleDismiss}>
              {message}
            </Alert>
          </Col>
        </Row>
      );
    }
    return null;
  };

  render() {
    return (
      <Grid>
        {this.renderNotification()}
        <LogoHeader />
        <br />
        <br />
        <br />
        <Row>
          <Col
            lg={6}
            lgOffset={3}
            md={6}
            mdOffset={3}
            sm={6}
            smOffset={3}
            style={{
              boxShadow: "0px 4px 12px 0px #666",
              borderRadius: "10px",
              paddingBottom: "20px",
            }}
          >
            <div className="changeUsernamePassword">
              <h1 style={{ color: "var(--base-color)" }}> Reset Password </h1>
              <PasswordRequirements
                pwMatch={this.state.pwMatch}
                number={this.state.number}
                letter={this.state.letter}
                specialCharacter={this.state.specialCharacter}
                pwLength={this.state.pwLength}
              />

              <Form onSubmit={this.handleSubmit}>
                <FormGroup controlId="password">
                  <h3 style={{ color: "#666", fontWeight: "500" }}>Password</h3>
                  <FormControl
                    type="password"
                    inputRef={ref => {
                      this.newPassword1 = ref;
                    }}
                    placeholder=""
                    onChange={() =>
                      handleCheckRequirements(
                        this.setState.bind(this),
                        this.newPassword1.value,
                        this.newPassword2.value
                      )
                    }
                  />
                </FormGroup>
                <FormGroup controlId="password2">
                  <h3 style={{ color: "#666", fontWeight: "500" }}> Confirm Password</h3>
                  <FormControl
                    type="password"
                    inputRef={ref => {
                      this.newPassword2 = ref;
                    }}
                    placeholder=""
                    onChange={() =>
                      handleCheckMatch(
                        this.setState.bind(this),
                        this.newPassword1.value,
                        this.newPassword2.value
                      )
                    }
                  />
                </FormGroup>
                <Button
                  bsStyle="info"
                  type="submit"
                  disabled={
                    this.state.letter ||
                    this.state.number ||
                    this.state.specialCharacter ||
                    this.state.pwMatch
                  }
                >
                  Reset Password
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

PasswordReset.propTypes = {
  match: reactRouterMatchShape({
    token: PropTypes.string.isRequired,
  }).isRequired,
};

export default PasswordReset;
