import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Row, Tab } from "react-bootstrap";
import {
  getAddressCoinSwap,
  getAddressSummary,
  getAddressTokens,
  getAddressTransactions,
  getInternalTransactions
} from "../../selectors/ethereum/address";
import {
  fetchAddressHistoricalEthereum,
  fetchAddressSummaryEthereum,
  fetchAddressTokens,
  fetchAddressTransactions,
  fetchInternalTransactions
} from "../../actions/ethereum/address";
import ReportIssue from "../ReportIssue";
import StatCard from "../StatCard";
import { chooseBetweenConversions, convertPriceToUsd, numberWithCommas } from "../../helpers";
import WrappedLink from "../Utils/WrappedLink";
import withStateBasedTabs from "../Address/withStateBasedTabs";
import withRouteBasedTabs from "../Address/withRouteBasedTabs";
import { TokenSummary } from "./SmartContracts/TokenSummary";
import { CoinSwap } from "../CoinSwap";
import { Helmet } from "react-helmet";
import { TransactionList } from "./TransactionList";
import { InternalTransactionList } from "./InternalTransactionList";
import Tabs from "react-bootstrap/lib/Tabs";
import { AddressName } from "./AddressName";
import AddToNote from "../NoteEditor/AddToNote";
import CategoryName from "../Wallet/CategoryName";
import BtcToUsdChooser from "../Utils/BtcToUsdChooser";
import CurrencyChooserConfig from "../CurrencyChooserConfig";
import { getCurrency, getUsdForCoin } from "../../selectors/currency";

const AddressEthereum = ({ address, ...props }) => {
  const {
    token,
    category,
    creation_transaction_id,
    totalSpent,
    totalDeposits,
    currentBalance,
    histSent,
    histRecv,
    ...addressSummary
  } = useSelector(state => getAddressSummary(state, address, true));
  const currency = useSelector(state => getCurrency(state));
  const coinSwap = useSelector(state => getAddressCoinSwap(state, address, true));
  const usdPrice = useSelector(state => getUsdForCoin(state));

  const dispatch = useDispatch();
  const { coin_swap_data, coin_swap } = coinSwap;
  const [loading, setLoading] = useState(true);
  const [histLoading, setHistLoading] = useState(true);

  const [usd, setUsd] = useState(false);
  const [histUsd, setHistUsd] = useState(false);
  const fetchAddress = async () => {
    setLoading(true);
    await dispatch(fetchAddressSummaryEthereum(address));
    setLoading(false);
  };
  useEffect(() => {
    setHistLoading(true);
    fetchAddress().catch(console.error);
    setHistDisplay(false);
  }, [address]);

  const contractNotToken = creation_transaction_id && !token;
  let currencyObject = CurrencyChooserConfig[currency];

  const setUsdDisplay = value => {
    setUsd(value);
    setHistUsd(false);
  };

  const setHistDisplay = async value => {
    setUsd(false);
    setHistUsd(value);
    if ((histSent === null || histRecv === null) && value) {
      setHistLoading(true);
      await dispatch(fetchAddressHistoricalEthereum(address));
      setHistLoading(false);
    }
  };
  const showUsdText = histUsd || usd;

  let contractText = token ? `${token.token_standard.toUpperCase()} Token` : "";
  contractText = contractNotToken ? "Smart Contract" : contractText;
  return (
    <div>
      <Helmet>
        <title>{`Addr ${address}`}</title>
      </Helmet>
      <Grid>
        <Row>
          <div
            className="container"
            style={{
              borderWidth: "0 0 0 2px",
              borderColor: "var(--secondary-color)",
              borderStyle: "solid",
              paddingBottom: "0"
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <h4 className="entityHeader">Address</h4>
                <p
                  style={{
                    color: "var(--secondary-color)",
                    fontFamily: "Quicksand",
                    fontWeight: "600",
                    fontSize: "20px"
                  }}
                >
                  {contractText}
                </p>
              </div>
              <div style={{ marginLeft: "auto" }}>
                <AddToNote page="address" itemId={address} />
                <ReportIssue entityName={address} entityType="transaction" currency="ethereum" />
              </div>
            </div>
            <div className="row" style={{ marginLeft: "0", alignItems: "center", display: "flex" }}>
              <div className="col txnSummaryTxnId">
                <AddressName address={address} stateBased={props.stateBased} />
              </div>
            </div>
            <div
              className="category-info"
              style={{ alignItems: "center", display: "flex", marginBottom: "10px" }}
            >
              {category && (
                <CategoryName
                  categoryLabel={category.category}
                  categoryColor={category.color}
                  categoryFontColor={"black"}
                  category={category}
                />
              )}
            </div>
            {token && <TokenSummary address={address} {...token} />}
            <div className="row">
              <div className="col-sm-4 col-md-4 col-lg-4">
                <p
                  style={{
                    color: "var(--secondary-color)",
                    fontFamily: "Quicksand",
                    fontWeight: "700",
                    fontSize: "20px"
                  }}
                >
                  Address Summary
                </p>
              </div>
              <div
                className="col-sm-6 col-md-6 col-lg-6"
                style={{ display: "inline", paddingTop: "8px" }}
              >
                <StatCard stat="Address: " value={address} loading={loading} pullRight={false} />
              </div>
              <div className="pull-right" style={{ display: "inline", paddingTop: "8px" }}>
                <BtcToUsdChooser
                  showAsUsd={usd}
                  toggleUsdDisplay={setUsdDisplay}
                  historicalToUsd={histUsd}
                  toggleHistoricalUsd={setHistDisplay}
                  currencyAbbreviation={currencyObject["abb"]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-md-4 col-lg-4 infoCol">
                <div className="infoCards">
                  <p
                    style={{
                      color: "var(--secondary-color)",
                      fontFamily: "Quicksand",
                      fontWeight: "700"
                    }}
                  >
                    Transactions
                  </p>
                  <StatCard
                    stat="Sent Transactions"
                    value={numberWithCommas(addressSummary.totalSpendCount)}
                    loading={loading}
                  />
                  <StatCard
                    stat="Received Transactions"
                    value={numberWithCommas(addressSummary.totalDepositCount)}
                    loading={loading}
                  />
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4 infoCol">
                <div className="infoCards">
                  <p
                    style={{
                      color: "var(--secondary-color)",
                      fontFamily: "Quicksand",
                      fontWeight: "700"
                    }}
                  >
                    Transaction Amounts
                  </p>
                  <StatCard
                    stat={`Recv ${showUsdText ? "USD" : currencyObject.abb}`}
                    value={chooseBetweenConversions(
                      [totalDeposits, !usd],
                      ["$" + numberWithCommas(convertPriceToUsd(totalDeposits, usdPrice)), usd],
                      ["$" + numberWithCommas(histRecv), histUsd],
                      true,
                      histRecv === -1
                    )}
                    loading={histUsd ? histLoading && histRecv == null : loading}
                  />
                  <StatCard
                    stat={`Sent ${showUsdText ? "USD" : currencyObject.abb}`} // value={numberWithCommas(addressSummary.totalSpent)}
                    value={chooseBetweenConversions(
                      [totalSpent, !usd],
                      ["$" + numberWithCommas(convertPriceToUsd(totalSpent, usdPrice)), usd],
                      ["$" + numberWithCommas(histSent), histUsd],
                      true,
                      histSent === -1
                    )}
                    loading={histUsd ? histLoading && histSent == null : loading}
                  />
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4 infoCol">
                <div className="infoCards">
                  <p
                    style={{
                      color: "var(--secondary-color)",
                      fontFamily: "Quicksand",
                      fontWeight: "700"
                    }}
                  >
                    Deposits
                  </p>
                  <StatCard
                    stat="Current Balance"
                    value={
                      showUsdText
                        ? "$" + numberWithCommas(convertPriceToUsd(currentBalance, usdPrice))
                        : currentBalance
                    }
                    loading={loading}
                  />
                  {/*<StatCard*/}
                  {/*  stat="Last Used Block Height"*/}
                  {/*  value={addressSummary.lastUsedBlockHeight}*/}
                  {/*  loading={loading}*/}
                  {/*/>*/}
                </div>
              </div>
            </div>
            {coin_swap && (
              <div
                className="col-sm-12 col-md-12 col-lg-12 infoCol"
                style={{ paddingLeft: "0", marginTop: "20px" }}
              >
                <CoinSwap coinSwapData={coin_swap_data[0]} />
              </div>
            )}
          </div>
        </Row>
        <Row style={{ paddingRight: "0", paddingTop: "0" }}>
          <div
            className="container"
            style={{ paddingRight: "0", paddingTop: "0", marginTop: "10px" }}
          >
            <Tabs className="inviteTabs" id="address-tab" animation={false}>
              <Tab eventKey="transactions" title="Transactions">
                <TransactionList
                  graphView={false}
                  fetchTransactions={(token_account_id, filter) =>
                    fetchAddressTransactions(address, token_account_id, filter)
                  }
                  fetchTokens={() => fetchAddressTokens(address)}
                  stateBased={props.stateBased}
                  bold={address}
                  getTokens={state => getAddressTokens(state, address, true)}
                  getTransactions={(state, token_account_id, filter = null) =>
                    getAddressTransactions(state, address, token_account_id, filter, true)
                  }
                  showUsd={usd}
                  showHist={histUsd}
                  searchKey={address}
                  enabledFilters={new Set(["side"])}
                />
              </Tab>
              <Tab eventKey="internal" title="Internal Transactions">
                <InternalTransactionList
                  graphView={false}
                  fetchTransactions={filter => fetchInternalTransactions(address, filter)}
                  stateBased={props.stateBased}
                  bold={address}
                  getTransactions={(state, filter) =>
                    getInternalTransactions(state, address, filter, true)
                  }
                  searchKey={address}
                  showUsd={usd}
                  showHist={histUsd}
                  enabledFilters={new Set(["side"])}
                />
              </Tab>
            </Tabs>
          </div>
        </Row>
      </Grid>
    </div>
  );
};

export default AddressEthereum;

export const EtherAddressWithRouteBasedTabs = withRouteBasedTabs(AddressEthereum);
export const EtherAddressWithStateBasedTabs = withStateBasedTabs(AddressEthereum);
