import fawkes from "../../img/fawkes.png";
import ct_white from "../../img/CipherTrace_logo_white.png";
import ct_home from "../../img/ciphertrace_logo_awarded.png";
import cryptotru_white from "../../img/cryptotru-white.png";
import cryptotru_color from "../../img/cryptotru-80.png";
import cryptovoyant_white from "../../img/cryptovoyant_white.png";
import cryptovoyant_color from "../../img/CryptoVoyant_On-white_shadow.png";
import WhitesailHeader from "../../img/whitesail-header.png";
import WhitesailLogo from "../../img/logo-large-2.png";
import cv_icon from "../../img/cv_icon.png";

const ClientModeConfig = {
  vci: {
    word_for_collection: "wallet",
    cap_word_for_collection: "Wallet",
    plural_for_collection: "wallets",
    cap_plural_for_collection: "Wallets",
    nav_logo: fawkes,
    login_logo: null,
    login_top_logo: WhitesailHeader,
    login_top_icon: WhitesailLogo
  },
  ct: {
    word_for_collection: "entity",
    cap_word_for_collection: "Entity",
    plural_for_collection: "entities",
    cap_plural_for_collection: "Entities",
    nav_logo: ct_white,
    login_logo: ct_home,
    login_top_logo: WhitesailHeader,
    login_top_icon: WhitesailLogo
  },
  cryptotru: {
    word_for_collection: "entity",
    cap_word_for_collection: "Entity",
    plural_for_collection: "entities",
    cap_plural_for_collection: "Entities",
    nav_logo: cryptotru_white,
    login_logo: cryptotru_color,
    icon: null,
    login_top_logo: null,
    login_top_icon: null
  },
  cryptovoyant_new: {
    word_for_collection: "entity",
    cap_word_for_collection: "Entity",
    plural_for_collection: "entities",
    cap_plural_for_collection: "Entities",
    nav_logo: cryptovoyant_color,
    login_logo: cryptovoyant_color,
    icon: cv_icon,
    login_top_logo: null,
    login_top_icon: null
  },
  cryptovoyant_old: {
    word_for_collection: "entity",
    cap_word_for_collection: "Entity",
    plural_for_collection: "entities",
    cap_plural_for_collection: "Entities",
    nav_logo: cryptovoyant_white,
    login_logo: cryptovoyant_color,
    icon: cv_icon,
    login_top_logo: null,
    login_top_icon: null
  }
};

export default ClientModeConfig;
