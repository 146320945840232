// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import Bitcoin from "../../img/bitcoin-btc-logo.svg";
import BitcoinCash from "../../img/bitcoin-cash-bch-logo.svg";
import BitcoinGold from "../../img/bitcoingold.png";
import BitcoinSv from "../../img/bitcoin-sv-bsv-logo.svg";
import Litecoin from "../../img/litecoin-ltc-logo.png";
import Ethereum from "../../img/ethereum.svg";
import dogecoin from "../../img/dogecoin.png";
import dash from "../../img/dash.png";

// Hex values taken directly from spreadsheet.
const CurrencyChooserConfig = {
  bitcoin: {
    label: "Bitcoin",
    abb: "BTC",
    hex: "#FF9900",
    logo: Bitcoin,
    apiString: "bitcoin",
  },
  "bitcoin-cash": {
    label: "Bitcoin Cash",
    abb: "BCH",
    hex: "#4d4d4d",
    logo: BitcoinCash,
    apiString: "bitcoin-cash",
  },
  "bitcoin-gold": {
    label: "Bitcoin Gold",
    abb: "BTG",
    hex: "#0c216b",
    logo: BitcoinGold,
    apiString: "bitcoin-gold",
  },
  "bitcoin-sv": {
    label: "Bitcoin SV",
    abb: "BSV",
    hex: "#FF9900",
    logo: BitcoinSv,
    apiString: "bitcoin-cash-sv",
  },
  litecoin: {
    label: "Litecoin",
    abb: "LTC",
    hex: "#d3d3d3",
    logo: Litecoin,
    apiString: "litecoin",
  },
  ethereum: {
    label: "Ethereum",
    abb: "ETH",
    hex: "#d3d3d3",
    logo: Ethereum,
    apiString: "ethereum",
  },
  doge: {
    label: "DogeCoin",
    abb: "DOGE",
    hex: "#d3d3d3",
    logo: dogecoin,
    apiString: "doge",
  },
  dash: {
    label: "Dash",
    abb: "Dash",
    hex: "#c3a634",
    logo: dash,
    apiString: "dash",
  },
};

export default CurrencyChooserConfig;
