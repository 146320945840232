/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import "../../../css/styles.css";
import Table from "react-bootstrap/es/Table";
import moment from "moment/moment";
import { faFilter } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import { CircularProgress } from "@material-ui/core";
import CategoryColorsConfig from "../Graph/CategoryColorsConfig";
import MutualWallet from "./MutualWallet";
import { getClientMode } from "../../selectors/applicationConfig";
import ClientModeConfig from "../ClientModeConfig";
import CurrencyChooserConfig from "../CurrencyChooserConfig";

import { chooseBetweenConversions, numberWithCommas, satoshiToBitcoin } from "../../helpers";

/**
 * List of panels that display tags associated with an address.
 */
class MutualWallets extends Component {
  state = {
    mutualWalletsSorted: {},
    currentWallet: null
  };

  categories = ["Custom"];

  componentWillMount() {
    CategoryColorsConfig().forEach(category => this.categories.push(category.label));
  }

  rowClick = entry => {
    this.props.fetchWalletTransactions(
      this.props.sourceId,
      entry.walletId,
      entry.tag,
      "newestFirst"
    );
    this.setState({ currentWallet: entry });
  };

  back = () => {
    this.setState({ currentWallet: null });
  };

  content = () => {
    const { convertPriceToUsd, showAsUsd, historicalToUsd, currentUsdPrice } = this.props;
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }
    let currencyObject = CurrencyChooserConfig[this.props.currency];

    if (!this.props.mostRecentCallSuccess) {
      return (
        <>
          <div className="row" style={{ marginLeft: "0", marginBottom: "15px", marginTop: "15px" }}>
            <div
              className="blueButtonFilter"
              style={{
                marginLeft: "0",
                marginRight: "15px",
                padding: "10px",
                textAlign: "left",
                border: "2px solid var(--secondary-color)",
                backgroundColor: "var(--secondary-color)",
                borderRadius: "4px"
              }}
            >
              <Button
                className="redButton"
                style={{ marginRight: "25px" }}
                onClick={this.props.handleSetWalletFilter}
              >
                <FontAwesomeIcon icon={faFilter} /> Remove Attribution Category Filter
              </Button>
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}>
            <p style={{ fontSize: "20px", fontWeight: "600", color: "#666" }}>
              Unable to Fetch those {clientModeConfigObject.cap_plural_for_collection} at this Time.
            </p>
            <p style={{ fontSize: "16px", fontWeight: "600", color: "#666" }}>
              This may be because there are too many transactions. Please try again in a couple
              minutes. If it persists please contact us through the support button and one of our
              staff will look into it right away. Please be sure to specify the entity or address
              page you were on when you experienced the issue
            </p>
          </div>
        </>
      );
    } else if (!this.props.walletsFetched && this.props.mostRecentCallSuccess) {
      return (
        <>
          <div className="row" style={{ marginLeft: "0", marginBottom: "15px", marginTop: "15px" }}>
            <div
              className="blueButtonFilter"
              style={{
                marginLeft: "0",
                marginRight: "15px",
                padding: "10px",
                textAlign: "left",
                border: "2px solid var(--secondary-color)",
                backgroundColor: "var(--secondary-color)",
                borderRadius: "4px"
              }}
            >
              <Button
                className="redButton"
                style={{ marginRight: "25px" }}
                onClick={this.props.handleSetWalletFilter}
              >
                <FontAwesomeIcon icon={faFilter} /> Remove Attribution Category Filter
              </Button>
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}>
            <p style={{ fontSize: "20px", fontWeight: "600", color: "#666" }}>
              {" "}
              Loading Category Data{" "}
            </p>
            <CircularProgress style={{ color: "var(--secondary-color)" }} />
          </div>
        </>
      );
    } else if (this.state.currentWallet === null) {
      return (
        <>
          <div className="row" style={{ marginLeft: "0", marginBottom: "15px", marginTop: "15px" }}>
            <div
              className="blueButtonFilter"
              style={{
                marginLeft: "0",
                marginRight: "15px",
                padding: "10px",
                textAlign: "left",
                border: "2px solid var(--secondary-color)",
                backgroundColor: "var(--secondary-color)",
                borderRadius: "4px"
              }}
            >
              <Button
                className="redButton"
                style={{ marginRight: "25px" }}
                onClick={this.props.handleSetWalletFilter}
              >
                <FontAwesomeIcon icon={faFilter} /> Remove Attribution Category Filter
              </Button>
            </div>
          </div>
          {this.props.mutualWallets.size === 0 ? (
            <div style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}>
              <p style={{ fontSize: "20px", fontWeight: "600", color: "#666" }}>
                This {clientModeConfigObject.cap_plural_for_collection} has no transactions with
                tagged wallets at this time.
              </p>
            </div>
          ) : (
            this.categories.map(category => {
              if (this.props.mutualWalletsSorted[category] === undefined) {
                return;
              }
              return (
                <div
                  style={{
                    borderLeft: "2px solid var(--secondary-color)",
                    paddingLeft: "5px",
                    marginBottom: "5px"
                  }}
                >
                  <h2
                    style={{
                      fontFamily: "Quicksand, sans-serif",
                      color: "#666"
                    }}
                  >
                    {" "}
                    {category}{" "}
                  </h2>
                  <Table
                    className="inviteTable"
                    style={{
                      width: "100%",
                      tableLayout: "fixed"
                    }}
                  >
                    <thead style={{ textAlign: "center" }}>
                      {this.props.entityType === "wallet" ? (
                        <tr>
                          <th style={{ width: "12%", textAlign: "left" }}>Wallet</th>
                          <th style={{ width: "12%", textAlign: "right" }}>Sent Trx Count</th>
                          <th style={{ width: "12%", textAlign: "right" }}>Recv Trx Count</th>
                          <th style={{ width: "14%", textAlign: "right" }}>
                            Sent {this.props.showAsUsd ? "USD" : currencyObject.abb}
                          </th>
                          <th style={{ width: "14%", textAlign: "right" }}>
                            Recv {this.props.showAsUsd ? "USD" : currencyObject.abb}
                          </th>
                          <th style={{ width: "16%", textAlign: "right" }}>First Trx</th>
                          <th style={{ width: "16%", textAlign: "right" }}>Last Trx</th>
                        </tr>
                      ) : (
                        <tr>
                          <th style={{ width: "25%", textAlign: "left" }}>Wallet</th>
                          <th style={{ width: "25%", textAlign: "right" }}> Transaction Count</th>
                          {/*<th style={{ width: "18%", textAlign: "right" }}>Sent Trx Count</th>*/}
                          {/*<th style={{ width: "18%", textAlign: "right" }}>Recv Trx Count</th>*/}
                          <th style={{ width: "25%", textAlign: "right" }}>First Trx</th>
                          <th style={{ width: "25%", textAlign: "right" }}>Last Trx</th>
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {this.props.mutualWalletsSorted[category].map(entry => {
                        const sentBitcoin = satoshiToBitcoin(entry.sent);
                        const recvBitcoin = satoshiToBitcoin(entry.recv);
                        return (
                          <tr className="mutualWalletRow" onClick={() => this.rowClick(entry)}>
                            <td
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap"
                              }}
                            >
                              {entry.tag}
                            </td>
                            {this.props.entityType === "wallet" ? (
                              <>
                                <td
                                  align="right"
                                  style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {entry.sentCount}
                                </td>
                                <td
                                  align="right"
                                  style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {entry.recvCount}
                                </td>

                                <td
                                  align="right"
                                  style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {chooseBetweenConversions(
                                    [sentBitcoin, !showAsUsd],
                                    [
                                      "$" +
                                        numberWithCommas(
                                          convertPriceToUsd(sentBitcoin, currentUsdPrice)
                                        ),
                                      showAsUsd || historicalToUsd
                                    ],
                                    ["Hard to calculate", false]
                                  )}
                                </td>
                                <td
                                  align="right"
                                  style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {chooseBetweenConversions(
                                    [recvBitcoin, !showAsUsd],
                                    [
                                      "$" +
                                        numberWithCommas(
                                          convertPriceToUsd(recvBitcoin, currentUsdPrice)
                                        ),
                                      showAsUsd || historicalToUsd
                                    ],
                                    ["Hard to calculate", false]
                                  )}
                                </td>
                              </>
                            ) : (
                              <td
                                align="right"
                                style={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {entry.totalTrx}
                              </td>
                            )}
                            <td
                              align="right"
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap"
                              }}
                            >
                              {moment
                                .unix(parseInt(entry.firstTrx))
                                .utc()
                                .format("LL")}
                            </td>
                            <td
                              align="right"
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap"
                              }}
                            >
                              {moment
                                .unix(parseInt(entry.lastTrx))
                                .utc()
                                .format("LL")}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              );
            })
          )}
        </>
      );
    }
    return (
      <MutualWallet
        sourceId={this.props.sourceId}
        sourceTag={this.props.sourceTag}
        destinationWallet={this.state.currentWallet}
        maxHeight={this.props.maxHeight}
        getTransactionLink={this.props.getTransactionLink}
        back={this.back}
        currentView={this.state.currentView}
        handleSetWalletFilter={this.props.handleSetWalletFilter}
        getMutualTransactions={this.props.getMutualTransactions}
        setDateRange={this.props.setDateRange}
        setLargest={this.props.setLargest}
        fetchMoreTransactions={this.props.fetchMoreTransactions}
        swapOrder={this.props.swapOrder}
        fromAddressView={this.props.fromAddressView}
        currency={this.props.currency}
        convertPriceToUsd={this.props.convertPriceToUsd}
        currentUsdPrice={currentUsdPrice}
        showAsUsd={this.props.showAsUsd}
        historicalToUsd={historicalToUsd}
      />
    );
  };

  render() {
    return (
      <div className="col-lg-12" style={{ paddingLeft: "0", paddingRight: "0", paddingTop: "0" }}>
        {this.content()}
      </div>
    );
  }
}

MutualWallets.propTypes = {
  apiBase: PropTypes.func.isRequired,
  sourceId: PropTypes.string.isRequired,
  sourceTag: PropTypes.string.isRequired,
  fetch: PropTypes.func.isRequired,
  fetchWalletTransactions: PropTypes.func.isRequired,
  nextKey: PropTypes.number,
  // sets the max height of the tags list
  maxHeight: PropTypes.number.isRequired,
  getTransactionLink: PropTypes.func.isRequired,
  fetchMoreWallets: PropTypes.func.isRequired,
  fetchTransactions: PropTypes.func.isRequired,
  getWallets: PropTypes.func.isRequired,
  fromAddressView: PropTypes.bool.isRequired,
  showAsUsd: PropTypes.bool,
  historicalToUsd: PropTypes.bool.isRequired,
  convertPriceToUsd: PropTypes.func.isRequired,
  currency: PropTypes.string,
  currentUsdPrice: PropTypes.number
};

MutualWallets.defaultProps = {
  sourceId: null,
  nextKey: null,
  showAsUsd: false,
  currency: "bitcoin",
  currentUsdPrice: 0
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps.getWallets(state, ownProps.sourceId),
    clientMode: getClientMode(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  // fetch will only be used if we decide we need pagination for wallet categories
  fetch: sourceId => dispatch(ownProps.fetchMoreWallets(ownProps.apiBase, sourceId)),

  // fetchWalletTransactions fetches the transactions for the selected wallet row
  fetchWalletTransactions: (sourceId, walletId, tag, order) =>
    dispatch(ownProps.fetchTransactions(sourceId, walletId, tag, order, null, null))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MutualWallets);
