import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getWalletAddresses, getWalletSummary } from "../../selectors/ethereum/wallet";
import { fetchWalletSummaryEthereum } from "../../actions/ethereum/wallet";
import { Grid, Row, Tab, Table, Tabs } from "react-bootstrap";
import ReportIssue from "../ReportIssue";
import { Link } from "react-router-dom";
import StatCard from "../StatCard";
import { ScrollDiv } from "../styled";
import { numberWithCommas } from "../../helpers";
import ClientModeConfig from "../ClientModeConfig";
import { getClientMode } from "../../selectors/applicationConfig";

class WalletEthereum extends Component {
  state = {
    currentTab: "addresses",
  };

  componentDidMount() {
    const {
      match: {
        params: { walletId },
      },
      history,
      ...passThroughProps
    } = this.props;

    this.props.fetchWalletSummaryEthereum(walletId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: {
        params: { walletId: oldAddress },
      },
    } = prevProps;

    const {
      match: {
        params: { walletId: newAddress },
      },
    } = this.props;

    // Check to see if the walletId has changed. If so, we want to fetch the data
    if (oldAddress !== newAddress) {
      this.props.fetchWalletSummaryEthereum(newAddress);
    }
  }

  render() {
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null,
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    const {
      match: {
        params: { walletId },
      },
      history,
      walletSummary,
      addresses,
      ...passThroughProps
    } = this.props;
    const addressRows = [];
    addresses.forEach(address =>
      addressRows.push(
        <tr className="inviteRow">
          <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            <Link to={`/ethereum/address/${address}`}>{address}</Link>
          </td>
        </tr>
      )
    );

    return (
      <div>
        <Grid>
          <Row>
            <div
              className="container"
              style={{
                borderWidth: "0 0 0 2px",
                borderColor: "var(--secondary-color)",
                borderStyle: "solid",
                paddingBottom: "0",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <h4 className="entityHeader">{clientModeConfigObject.cap_word_for_collection}</h4>
                <div style={{ marginLeft: "auto" }}>
                  {/* {addToGraph()}{" "} */}
                  {/*<Button className="blueButton">Add to Graph</Button>*/}
                  <ReportIssue
                    entityName={walletSummary.name || walletId}
                    entityType={"wallet"}
                    currency={"ethereum"}
                  >
                    {" "}
                  </ReportIssue>
                </div>
              </div>
              <div className="row" style={{ marginLeft: "0", alignItems: "center" }}>
                <div className="col txnSummaryTxnId">
                  <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {walletSummary.name || walletId}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <p
                    style={{
                      color: "var(--secondary-color)",
                      fontFamily: "Quicksand",
                      fontWeight: "700",
                      fontSize: "20px",
                    }}
                  >
                    {" "}
                    Summary{" "}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 infoCol">
                  <div className="infoCards">
                    <p
                      style={{
                        color: "var(--secondary-color)",
                        fontFamily: "Quicksand",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      Info{" "}
                    </p>
                    <StatCard
                      stat={`${clientModeConfigObject.cap_word_for_collection} Id`}
                      value={walletId}
                    />
                    <StatCard
                      stat="Address Count"
                      value={numberWithCommas(walletSummary.totalAddressCount)}
                    />
                    <StatCard stat="Subpoenable" value={walletSummary.subpoenable ? "Yes" : "No"} />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 infoCol">
                  <div className="infoCards">
                    <p
                      style={{
                        color: "var(--secondary-color)",
                        fontFamily: "Quicksand",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      Attribution{" "}
                    </p>
                    {walletSummary.name ? (
                      <>
                        <StatCard stat="Entity Name" value={walletSummary.name || "N/A"} />
                        <StatCard stat="URL" value={walletSummary.url || "N/A"} />
                        <StatCard stat="Country" value={walletSummary.country || "N/A"} />
                        <StatCard
                          stat="Entity Type"
                          value={
                            walletSummary.type
                              ? walletSummary.type[0].toUpperCase() + walletSummary.type.slice(1)
                              : "N/A"
                          }
                        />
                      </>
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          fontFamily: "Quicksand",
                          fontWeight: "700",
                          color: "#666",
                          paddingLeft: "10%",
                          paddingRight: "10%",
                        }}
                      >
                        {" "}
                        This {clientModeConfigObject.cap_word_for_collection} is currently
                        unattributed, but will be updated when new information becomes available.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <Row style={{ paddingRight: "0", paddingTop: "0" }}>
            <div
              className="container"
              style={{ paddingRight: "0", paddingTop: "0", marginTop: "10px" }}
            >
              <Tabs
                className="inviteTabs"
                activeKey={this.state.currentTab}
                id="address-tab"
                onSelect={e => this.setState({ currentTab: e })}
                animation={false}
              >
                <Tab eventKey="addresses" title="Addresses">
                  <ScrollDiv
                    maxHeight={"700px"}
                    id="addressTransactionsTable"
                    style={{ marginTop: "20px" }}
                  >
                    <Table
                      className="inviteTable"
                      style={{
                        width: "50%",
                        tableLayout: "fixed",
                        overflow: "auto",
                        wordWrap: "break-word",
                      }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "100%" }}>Address</th>
                        </tr>
                      </thead>
                      <tbody>{addressRows}</tbody>
                    </Table>
                  </ScrollDiv>
                </Tab>
              </Tabs>
            </div>
          </Row>
        </Grid>
      </div>
    );
  }
}

WalletEthereum.propTypes = {
  walletId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { walletId },
      path,
      url,
    },
    history,
    ...passThroughProps
  } = ownProps;
  return {
    walletSummary: getWalletSummary(state, walletId, true),
    addresses: getWalletAddresses(state, walletId, true),
    clientMode: getClientMode(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchWalletSummaryEthereum: walletId => dispatch(fetchWalletSummaryEthereum(walletId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletEthereum);
