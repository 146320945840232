import { ControlLabel } from "react-bootstrap";
import * as React from "react";
import { ModalMenuTypes } from "./interfaces";

interface GraphChangeTitleProps {
  selectedEntity: any;
  cy?: any;
  menuName: ModalMenuTypes;
  color?: string;
}

export const GraphChangeTitle = ({
  menuName,
  selectedEntity,
  cy,
  color = "white"
}: GraphChangeTitleProps) => {
  const getTitle = () => {
    switch (menuName) {
      case "WalletColorPicker":
        if (selectedEntity.isNode()) {
          return `Select a color for wallet ${selectedEntity.data("label")}`;
        } else {
          const [fromWalletId, toWalletId] = selectedEntity.id().split("+");
          const fromWallet = cy.getElementById(fromWalletId).data("label");
          const toWallet = cy.getElementById(toWalletId).data("label");
          return `Select color for edge ${fromWallet} to ${toWallet}`;
        }
      case "WalletTagSetter":
        return `Set a tag for wallet ${selectedEntity.data("label")}`;
      case "WalletNoteSetter":
        if (selectedEntity.isNode()) {
          return `Set a note for wallet ${selectedEntity.data("label")}`;
        } else {
          const [fromWalletId, toWalletId] = selectedEntity.id().split("+");
          const fromWallet = cy.getElementById(fromWalletId).data("label");
          const toWallet = cy.getElementById(toWalletId).data("label");
          return `Set a note for wallet ${fromWallet} to ${toWallet}`;
        }

      default:
        return "";
    }
  };
  return (
    <ControlLabel
      style={{
        color,
        fontFamily: "Quicksand",
        fontWeight: "600",
        fontSize: "16px"
      }}
    >
      {getTitle()}
    </ControlLabel>
  );
};
