/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { openWalletModal } from "../../actions/graph";

const TransactionNodeStats = ({ trxInfo }) => {
  const {
    block,
    collapsedInputAddresses,
    collapsedOutputAddresses,
    fee,
    inputBitcoin,
    inputWallets,
    inputs,
    outputBitcoin,
    outputWallets,
    outputs,
    timestamp,
    unclustered_input,
  } = trxInfo;

  return (
    <div style={{ width: "100%", marginLeft: "0", paddingBottom: "0" }}>
      <Table className="walletStatsTable purple">
        <tbody>
          <tr key="transactionCount">
            <td className="header"> Timestamp </td>
            <td className="text-left">{timestamp}</td>
          </tr>
          <tr key="transactionCount">
            <td className="header"> Block </td>
            <td className="text-left">{block}</td>
          </tr>
          <tr key="transactionCount">
            <td className="header"> Fee </td>
            <td className="text-left">{fee}</td>
          </tr>
          <tr key="outputCount">
            <td className="header">Sent</td>
            <td className="text-left">{inputBitcoin}</td>
          </tr>
          <tr key="outputSum">
            <td className="header">Received</td>
            <td className="text-left">{outputBitcoin}</td>
          </tr>
          <tr key="balance">
            <td className="header">Balance</td>
            <td className="text-left">{parseFloat(outputBitcoin) - parseFloat(inputBitcoin)}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

TransactionNodeStats.propTypes = {};

const mapDispatchToProps = dispatch => ({
  handleOpenWalletModal: walletId => dispatch(openWalletModal(walletId)),
});

export default connect(
  null,
  mapDispatchToProps
)(TransactionNodeStats);
