/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons/index";
import AddressModal from "./AddressModal";
import WalletModal from "./WalletModal";
import TransactionModal from "./TransactionModal";
import {
  addAddressToGraphInExplorer,
  addTransactionToGraphInExplorer,
  addWalletToGraphInExplorer,
  goBack,
  goForward
} from "../../actions/graph";
import { getButtonHistory, getWalletSetMemo } from "../../selectors/graph";

import { getTransactionInfo } from "../../selectors/transaction";
import { getAddressSummary } from "../../selectors/address";
// Helper functions to disable back and next buttons
export const firstIndex = index => {
  return index === 0;
};

export const lastIndex = (index, historyLength) => {
  return index === historyLength - 1;
};

// get selectors to pass props
const ExplorerModals = ({
  currentSet,
  entityType,
  entity,
  handleCloseModal,
  className,
  handleGoBack,
  handleGoForward,
  handleAddWalletToGraph,
  handleAddAddressToGraph,
  handleAddTransactionToGraph,
  index,
  historyLength,
  wallets,
  walletId,
  inputWallets,
  outputWallets,
  graphId,
  disabled = false,
  cy
}) => {
  const addEntityToGraphCallback = () => {
    if (entityType === "address") {
      handleAddAddressToGraph(entity);
    } else if (entityType === "wallet") {
      handleAddWalletToGraph(entity.toString());
    } else if (entityType === "transaction") {
      handleAddTransactionToGraph(entity);
    }
  };

  let hasAll = true;
  let allWallets;
  if (entityType === "transaction") {
    // See if every wallet in the transaction is in the graph
    allWallets = inputWallets.concat(outputWallets).map(wallet => {
      if (wallet.walletId) {
        return wallet.walletId.toString();
      }
      return null;
    });
    allWallets.forEach(wallet => {
      if (wallet) {
        if (!wallets.has(wallet.toString())) {
          hasAll = false;
        }
      }
    });
  } else if (entityType === "wallet") {
    // Check if the wallet is in the graph
    hasAll = wallets.has(entity);
  } else if (entityType === "address") {
    // Check if the addresses wallet is in the graph
    hasAll = wallets.has(walletId);
  }

  const addToGraph = hasAll ? (
    <Button className="greenButtonGraph" onClick={addEntityToGraphCallback}>
      <FontAwesomeIcon icon={faCheck} /> Added
    </Button>
  ) : (
    <Button className="blueButton" onClick={addEntityToGraphCallback} disabled={disabled}>
      Add to Graph
    </Button>
  );

  let modal;
  if (currentSet !== "explorer") {
    return null;
  }
  if (entityType === "address") {
    modal = (
      <AddressModal
        onHide={handleCloseModal}
        address={entity}
        addToGraph={addToGraph}
        disabled={disabled}
      />
    );
  } else if (entityType === "wallet") {
    modal = (
      <WalletModal
        show
        onHide={handleCloseModal}
        walletId={entity.toString()}
        addToGraph={addToGraph}
        graphId={graphId}
        disabled={disabled}
      />
    );
  } else if (entityType === "transaction") {
    modal = (
      <TransactionModal
        show
        onHide={handleCloseModal}
        transaction={entity}
        addToGraph={addToGraph}
        disabled={disabled}
        cy={cy}
        graphId={graphId}
      />
    );
  }

  return (
    <div className="col-md-12 col-lg-12">
      <Modal onHide={handleCloseModal} dialogClassName={className} show>
        <Modal.Body style={{ padding: "0" }}>
          <div className="container">
            <div
              className="row"
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center"
              }}
            >
              <div className="col" style={{ width: "100%", paddingTop: "5px" }}>
                <Button
                  className="blueButton"
                  style={{ textAlign: "center", margin: "5px" }}
                  onClick={handleGoBack}
                  disabled={firstIndex(index)}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
                <Button
                  className="blueButton"
                  style={{ textAlign: "center", margin: "5px" }}
                  onClick={handleGoForward}
                  disabled={lastIndex(index, historyLength)}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
            </div>
            <div
              className="row"
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              {modal}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

ExplorerModals.propTypes = {
  currentSet: PropTypes.string,
  entityType: PropTypes.string,
  entity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleCloseModal: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  handleGoForward: PropTypes.func.isRequired,
  handleAddWalletToGraph: PropTypes.func.isRequired,
  handleAddAddressToGraph: PropTypes.func.isRequired,
  handleAddTransactionToGraph: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  historyLength: PropTypes.number.isRequired
};

ExplorerModals.defaultProps = {
  currentSet: null,
  entityType: null,
  entity: null
};

const mapStateToProps = (state, { graphId, entity }) => {
  const history = getButtonHistory(state);
  const walletSet = {
    wallets: getWalletSetMemo(state, graphId)
  };
  const info = getTransactionInfo(state, entity);
  const addressSummary = getAddressSummary(state, entity);

  return {
    ...history,
    ...walletSet,
    ...info,
    ...addressSummary
  };
};

const mapDispatchToProps = (dispatch, { cy, graphId }) => ({
  handleGoBack: () => dispatch(goBack()),
  handleGoForward: () => dispatch(goForward()),
  handleAddWalletToGraph: walletId => dispatch(addWalletToGraphInExplorer(walletId, graphId, cy)),
  handleAddAddressToGraph: address => dispatch(addAddressToGraphInExplorer(address, graphId, cy)),
  handleAddTransactionToGraph: transaction =>
    dispatch(addTransactionToGraphInExplorer(transaction, cy, graphId))
});

// const mergeProps = (stateProps, dispatchProps, ownProps) => {
//   const { entity, entityType } = ownProps;
//   const { wallets } = stateProps;
//   let isEntityInGraph;
//   if (entityType === 'wallet') {
//     isEntityInGraph = wallets.has(entity.toString());
//   } else if (entityType === 'address') {
//     const addressSummary = getAddressSummary()
//   }
//
//   return Object.assign({
//     isEntityInGraph,
//   }, ownProps, stateProps, dispatchProps);
// };

const StyledExplorerModals = styled(ExplorerModals)`
  width: 90%;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledExplorerModals);
