// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import {
  getWalletAddresses,
  getWalletAddressesRecords,
  getWalletMentions,
  getWalletSummary
} from "../selectors/wallet";
import {
  createFetchMentions,
  createFetchSummary
} from "./actionCreatorFactories";
import axios, {
  ADDRESS_API,
  CUSTOM_TAGS_API,
  CUSTOM_WALLETS_API
} from "../api";
import {
  CUSTOM_WALLET_ADDRESSES_FETCH_SUCCESS,
  CUSTOM_WALLET_DELETE_ADDRESS,
  CUSTOM_WALLET_DELETE_SUCCESS,
  CUSTOM_WALLET_MENTIONS_FETCH_FAILURE,
  CUSTOM_WALLET_MENTIONS_FETCH_SUCCESS,
  CUSTOM_WALLET_SET_NAME,
  CUSTOM_WALLET_SUMMARY_FETCH_FAILURE,
  CUSTOM_WALLET_SUMMARY_FETCH_SUCCESS,
  FETCHING_CUSTOM_WALLET_ADDRESSES,
  FETCHING_CUSTOM_WALLET_MENTIONS,
  FETCHING_CUSTOM_WALLET_SUMMARY,
  WALLET_SET_PROCESS
} from "./actionNames";
import history from "../components/history";
import { getCurrency } from "../selectors/currency";

/**
 *  Dispatch WALLET_SET_PROCESS to set  processed for walletID in redux state
 * @param walletId
 * @param processed value to set processed
 * @returns {(function(*, *): void)|*}
 */
export const setProcessing = (walletId, processed) => (dispatch, getState) => {
  dispatch({
    type: WALLET_SET_PROCESS,
    walletId: walletId.toString(),
    processed,
    name: getCurrency(getState())
  });
};

/**
 * Fetches custom wallet summary related actions and dispatch action
 * cooresponding to if the api fetch fails or not.
 *
 * @type {(function(*=): function(*, *): Promise<void>)|*}
 */
export const fetchCustomWalletSummary = createFetchSummary(
  CUSTOM_WALLETS_API,
  (state, walletId) => getWalletSummary(state, walletId.toString(), true),
  {
    fetchAction: FETCHING_CUSTOM_WALLET_SUMMARY,
    successAction: CUSTOM_WALLET_SUMMARY_FETCH_SUCCESS,
    failureAction: CUSTOM_WALLET_SUMMARY_FETCH_FAILURE
  }
);

/**
 * Fetches mentions for custom wallets and dispatch action
 * cooresponding to if the api fetch fails or not.
 * @type {(function(*=, *=): function(*, *): Promise<void>)|*}
 */
export const fetchCustomWalletMentions = createFetchMentions(
  CUSTOM_WALLETS_API,
  (state, walletId) => getWalletMentions(state, walletId.toString()),
  {
    fetchAction: FETCHING_CUSTOM_WALLET_MENTIONS,
    successAction: CUSTOM_WALLET_MENTIONS_FETCH_SUCCESS,
    failureAction: CUSTOM_WALLET_MENTIONS_FETCH_FAILURE
  }
);

/**
 * Makes api calls to add addresses to an existing custom wallet makes notifications
 * if failed.
 *
 * @param walletId walletId of the custom wallet
 * @param addresses list of addresses to add to custom wallet
 * @param enqueueSnackbar Notistack snackbar to make notifications
 * @returns {function(*, *): Promise<AxiosResponse<any>>} Promise to the add address api call
 */
export const customWalletAddAddresses = (
  walletId,
  addresses,
  enqueueSnackbar
) => async (dispatch, getState) => {
  const name = getCurrency(getState());

  // filtering dups out of addresses to ensure no double adding
  const seen = {};
  addresses = addresses.filter(item => {
    return seen.hasOwnProperty(item) ? false : (seen[item] = true);
  });

  // custom wallet is changing so we set processed to false
  dispatch({
    type: WALLET_SET_PROCESS,
    walletId: walletId.toString(),
    processed: false,
    name
  });

  // below is querying the api for the existence of addresses and builds addressDict for redux state
  const addressDicts = [];
  await Promise.all(
    addresses.map(address => {
      // Waits for all requests to finish
      return axios
        .get(`${ADDRESS_API(name)}/${address}/stats`)
        .then(({ data }) => {
          addressDicts.push({
            address,
            transactionCount: data.transactionCount,
            addressId: data.addressId
          });
        })
        .catch(() => {
          // request for address failed so add notification
          enqueueSnackbar(`Address ${address} does not exist`, {
            variant: "warning"
          });
          dispatch({
            type: WALLET_SET_PROCESS,
            walletId: walletId.toString(),
            processed: true,
            name
          });
          throw err;
        });
    })
  );

  // Makes api call to add addresses to custom wallet then dispatches action
  return axios
    .put(`${CUSTOM_WALLETS_API(name)}/${walletId}`, {
      addresses,
      delete: false
    })
    .then(() => {
      // dispatch action to populate redux with new addresses
      dispatch({
        type: CUSTOM_WALLET_ADDRESSES_FETCH_SUCCESS,
        walletId: walletId.toString(),
        data: addressDicts,
        processed: true,
        name
      });
    })
    .catch(err => {
      // Request failed set processed to true and throw error
      dispatch({
        type: WALLET_SET_PROCESS,
        walletId: walletId.toString(),
        processed: true,
        name
      });
      throw err;
    });
};

/**
 * Bulk checking of addresses to make sure they are all valid.
 * @param wallet custom wallet id
 * @param addressValue list of addresses to check
 * @returns {Function}
 */
export const customWalletCheckBulkAddress = (wallet, addresses) => async (
  dispatch,
  getState
) => {
  const name = getCurrency(getState());

  // get wallet's addresses in the redux state
  const currentAddresses = getWalletAddressesRecords(getState(), wallet);
  const badAddresses = []; // badAddresses is the list of all problmatic inputs we found
  addresses.forEach(address => {
    if (
      currentAddresses.find(address_ => address_.get("address") === address)
    ) {
      badAddresses.push(`${address} is already in wallet`);
    }
  });

  // Make API to check addresses on database and returns all invalid ones
  // eslint-disable-next-line camelcase
  const {
    data: { bad_addresses }
  } = await axios.put(`${ADDRESS_API(name)}/check-addresses/`, { addresses });
  bad_addresses.forEach(address => {
    badAddresses.push(`${address} does not exist`);
  }); // add all bad addresses to our running total

  return badAddresses;
};

/**
 * Fetches the addresses for a custom wallet and puts them in the redux state
 *
 * @param walletId custom wallet id
 * @returns {(function(*, *): Promise<void>)|*}
 */
export const fetchCustomWalletAddresses = (walletId, graphId) => async (
  dispatch,
  getState
) => {
  // get wallet addresses from redux state
  const addresses = getWalletAddresses(getState(), walletId.toString(), true);
  if (addresses !== null && addresses.toJS().addressRecords.length > 0) {
    // if not null redux already has it so return out
    return Promise.resolve();
  }

  const name = getCurrency(getState());
  dispatch({
    // dispatch fetching of addresses
    type: FETCHING_CUSTOM_WALLET_ADDRESSES,
    walletId: walletId.toString()
  });

  // Return promise of api call to get address with then to dispatch action to populate redux
  return axios
    .get(
      `${CUSTOM_WALLETS_API(name)}/${walletId}/addresses${
        graphId > 0 ? `/${graphId}` : ""
      }`
    )
    .then(({ data: { addresses: addresses_ } }) => {
      dispatch({
        type: CUSTOM_WALLET_ADDRESSES_FETCH_SUCCESS,
        walletId: walletId.toString(),
        data: addresses_,
        processed: true,
        name
      });
    });
};

/**
 * Makes api call to delete address from custom wallet from database and redux state
 * @param walletId custom cluster id
 * @param address Address to delete
 * @returns {function(*, *): Promise<AxiosResponse<any>>}
 */
export const customWalletDeleteAddress = (walletId, address) => (
  dispatch,
  getState
) => {
  const name = getCurrency(getState());
  dispatch({
    // changing custom wallet so set processed to false
    type: WALLET_SET_PROCESS,
    walletId: walletId.toString(),
    processed: false,
    name
  });

  // returning promise for api call to delete address from custom wallet.
  return axios
    .put(`${CUSTOM_WALLETS_API(name)}/${walletId}`, {
      addresses: [address],
      delete: true
    })
    .then(() => {
      // on successful deletion remove address from redux and set processed to true
      dispatch({
        type: CUSTOM_WALLET_DELETE_ADDRESS,
        walletId: walletId.toString(),
        address,
        name
      });
      dispatch({
        type: WALLET_SET_PROCESS,
        walletId: walletId.toString(),
        processed: true,
        name
      });
    })
    .catch(err => {
      // if failed just set processed back to true and throw error
      dispatch({
        type: WALLET_SET_PROCESS,
        walletId: walletId.toString(),
        processed: true,
        name
      });
      throw err;
    });
};

/**
 * Changes the name of the custom wallet
 * @param walletId
 * @param oldName
 * @param newName
 * @returns {(function(*, *): void)|*}
 */
export const editCustomWalletName = (walletId, oldName, newName) => async (
  dispatch,
  getState
) => {
  const name = getCurrency(getState());
  if (oldName !== newName) {
    //Useless to make call if they are setting the same name.
    await axios.put(`${CUSTOM_WALLETS_API(name)}/${walletId}/primary-tag`, {
      tag: newName
    });
    dispatch({
      // changing custom wallet name in redux store
      type: CUSTOM_WALLET_SET_NAME,
      walletId: walletId.toString(),
      newName,
      name
    });
  }
};

/**
 * Changes the name of the custom wallet
 * @param walletId
 * @returns {(function(*, *): void)|*}
 */
export const getCustomWalletName = walletId => async (dispatch, getState) => {
  const name = getCurrency(getState());
  const { data } = await axios.get(`${CUSTOM_WALLETS_API(name)}/${walletId}`);
  dispatch({
    // changing custom wallet name in redux store
    type: CUSTOM_WALLET_SET_NAME,
    walletId: walletId.toString(),
    newName: data.name,
    name
  });
};

/**
 * Makes api call to delete entire custom wallet and deletes from redux state
 * @param walletId custom wallet id
 * @param clusterName Name of cluster for reporting notifications
 * @param enqueueSnackbar Notistack snackbar for reporting notifications
 * @returns {(function(*, *): void)|*}
 */
export const deleteCustomWallet = (walletId, clusterName, enqueueSnackbar) => (
  dispatch,
  getState
) => {
  const name = getCurrency(getState());
  dispatch({
    // changing custom wallet so set processed to false
    type: WALLET_SET_PROCESS,
    walletId: walletId.toString(),
    processed: false,
    name
  });

  // Make promise for deletion call to custom wallets
  axios
    .delete(`${CUSTOM_WALLETS_API(name)}/${walletId}`)
    .then(() => {
      // on success set process to true and remove wallet from redux state and
      // reroute to custom-wallet page
      dispatch({
        type: CUSTOM_WALLET_DELETE_SUCCESS,
        walletId: walletId.toString(),
        name
      });
      enqueueSnackbar(
        // notify custom wallet has been deleted
        `Deleted ${clusterName}`,
        { variant: "success" }
      );
      if (
        history.location.pathname.startsWith(
          `/${name}/custom-wallet/${walletId}`
        )
      ) {
        history.replace("/custom-wallet");
      }
    })
    .catch(() => {
      // on failure, set process to true so nothing changes
      dispatch({
        type: WALLET_SET_PROCESS,
        walletId: walletId.toString(),
        processed: true,
        name
      });
      enqueueSnackbar(
        // Notify user of error
        `Error deleting ${clusterName}`,
        { variant: "error" }
      );
    });
};

export const removeCustomWalletSharedUser = (
  cluster_id,
  email,
  enqueueSnackbar = null
) => async (dispatch, getState) => {
  const name = getCurrency(getState());
  try {
    await axios.delete(`${CUSTOM_WALLETS_API(name)}/${cluster_id}/share`, {
      data: { email }
    });
    dispatch({
      type: CUSTOM_WALLET_DELETE_SUCCESS,
      walletId: cluster_id.toString(),
      name
    });
    if (
      history.location.pathname.startsWith(
        `/${name}/custom-wallet/${cluster_id}`
      )
    ) {
      history.replace("/custom-wallet");
    }
  } catch (err) {
    if (enqueueSnackbar) {
      // notify user of errors trying to modify custom tag
      if (err && err.response && err.response.status === 403) {
        enqueueSnackbar(`Not authorized to modify custom wallet`, {
          variant: "error"
        });
      } else {
        enqueueSnackbar("Error removing user", { variant: "error" });
      }
    }
    throw err; //throw error for caller to handle
  }
};
