/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types/index";
import React, { Component } from "react";
import { Button, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { debounce } from "lodash";

import { ScrollDiv } from "../../styled";
import { makeGetWalletAddresses } from "../../../selectors/wallet";
import { fetchWalletAddresses, setAddressesScrollTop } from "../../../actions/wallet";
import "../../../../css/styles.css";
import { CircularProgress } from "@material-ui/core";
import CopyText from "../../CopyText";
import DataDownload from "../../DataDownload";

class WalletAddresses extends Component {
  componentDidMount() {
    this.setTableScrollTopOnMount();
  }

  componentDidUpdate(prevProps) {
    const { walletId: oldWalletId } = prevProps;
    const { walletId: newWalletId } = this.props;
    if (newWalletId !== oldWalletId) {
      this.setTableScrollTopOnMount();
    }
  }

  setTableScrollTopOnMount = () => {
    // const container = document.getElementById("walletAddressesTable");
    // if (this.props.scrollTop != null) {
    //   container.scrollTop = this.props.scrollTop;
    // }
  };

  setTableScrollTop = debounce(() => {
    // const container = document.getElementById("walletAddressesTable");
    // if (container == null) {
    //   return;
    // }
    // const { scrollTop } = container;
    // if (this.props.setScrollTop != null) {
    //   this.props.setScrollTop(this.props.walletId, scrollTop);
    // }
  }, 100);

  // change to receive args
  fetchWalletAddresses = async () => {
    const { walletId } = this.props;
    this.props.fetch(walletId);
  };

  render() {
    const {
      addresses,
      maxHeight,
      nextKey,
      getAddressLink,
      caption,
      fromModal,
      fromSidebar,
    } = this.props;
    const addressRows = addresses.map(address_ => {
      const {
        address,
        addressId,
        transactionCount,
        confidence_score,
        attribution_source,
      } = address_;

      return (
        <tr key={addressId} className={fromModal ? "" : "inviteRowThin staticTableRow"}>
          <td style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
            <CopyText text={address.split(":").pop()} marginLeft="0" />
            {getAddressLink(address, this.props.currency)}
          </td>
          {!fromSidebar && (
            <>
              {/* <td> {confidence_score} </td> */}
              {/* <td> {attribution_source} </td> */}
            </>
          )}
          <td>{transactionCount}</td>
        </tr>
      );
    });

    const buttonText = nextKey ? "Load More ..." : "No More   ";

    return (
      <div>
        {nextKey !== 0 ? (
          <>
            <div
              className="row"
              style={{ marginLeft: "0", marginBottom: "15px", marginTop: "15px" }}
            >
              <div
                // className="blueButtonFilter"
                style={{
                  marginLeft: "0",
                  marginRight: "15px",
                  padding: "10px",
                  textAlign: "right",
                  border: fromSidebar
                    ? "2px solid var(--graph-sidebar-light-hover)"
                    : "2px solid var(--secondary-color)",
                  backgroundColor: fromSidebar
                    ? "var(--graph-sidebar-light-hover)"
                    : "var(--secondary-color)",
                  borderRadius: "4px",
                }}
              >
                {addresses.length !== 0 && (
                  <DataDownload
                    data={addresses.map(item => {
                      return {
                        Address: item.address,
                        "Transaction Count": item.transactionCount,
                      };
                    })}
                    entity_type="address"
                    style={{ margin: "10px" }}
                  />
                )}
                <div className="dropdown btn-group" style={{}}>
                  <Button
                    className="whiteButton"
                    style={{}}
                    onClick={this.fetchWalletAddresses}
                    disabled={!nextKey}
                  >
                    {buttonText}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ margin: 0, textAlign: "center" }}>
              <ScrollDiv maxHeight={maxHeight} style={{ margin: "0", borderRadius: "0" }}>
                <Table className={fromModal ? "walletStatsTable purple" : "walletStatsTable"}>
                  <thead>
                    <tr>
                      <th>Address</th>
                      {!fromSidebar && (
                        <>
                          {/* <th>Confidence Score </th> */}
                          {/* < th > Attribution Source </th> */}
                        </>
                      )}
                      <th>Transaction Count</th>
                    </tr>
                  </thead>
                  <tbody>{addressRows}</tbody>
                </Table>
              </ScrollDiv>
            </div>
          </>
        ) : (
          <div style={{ margin: 0, textAlign: "center" }}>
            <CircularProgress size={80} style={{ color: "white" }} />
          </div>
        )}
      </div>
    );
  }
}

WalletAddresses.propTypes = {
  walletId: PropTypes.string.isRequired,
  nextKey: PropTypes.number,
  fetch: PropTypes.func.isRequired,
  maxHeight: PropTypes.number.isRequired,
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      addressId: PropTypes.number.isRequired,
      transactionCount: PropTypes.number.isRequired,
    })
  ).isRequired,
  getAddressLink: PropTypes.func.isRequired,
  caption: PropTypes.node,
  setScrollTop: PropTypes.func,
  scrollTop: PropTypes.number,
  fromModal: PropTypes.bool,
  fromSidebar: PropTypes.bool,
};

WalletAddresses.defaultProps = {
  nextKey: null,
  caption: null,
  setScrollTop: null,
  scrollTop: null,
  fromModal: false,
  fromSidebar: false,
};

const makeMapStateToProps = () => {
  const getWalletAddresses = makeGetWalletAddresses();
  return (state, { walletId }) => getWalletAddresses(state, walletId, false);
};

const mapDispatchToProps = dispatch => ({
  fetch: walletId => dispatch(fetchWalletAddresses(walletId)),
  setScrollTop: (walletId, scrollTop) => dispatch(setAddressesScrollTop(walletId, scrollTop)),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(WalletAddresses);
