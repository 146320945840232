/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import moment from "moment";
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faDownload } from "@fortawesome/free-solid-svg-icons";

import Papa from "papaparse/papaparse.min";
import axios, { ISSUES_API } from "../../api";
import { ChatBubble } from "./ChatBubble";

class ResearcherIssues extends Component {
  state = {
    issues: [],
    showModal: false,
    resolutionNote: "",
    currentIssue: {
      user_id: null,
      entity_id: null,
      entity_type_id: null,
      issue_id: null,
      status: "",
      messages: [],
      seen: false,
    },
    showResolvedIssues: false,
  };

  entityType = id => {
    const types = {
      0: "Address",
      1: "Wallet",
      2: "Transaction",
      3: "Custom Wallet",
      4: "Note",
      5: "Graph",
    };
    return types[id];
  };

  currencyType = string => {
    const types = {
      bitcoin: "Bitcoin",
      "bitcoin-sv": "Bitcoin SV",
      "bitcoin-gold": "Bitcoin Gold",
      "bitcoin-cash": "Bitcoin Cash",
      litecoin: "Litecoin",
    };
    return types[string];
  };

  componentWillMount() {
    this.fetchIssues();
  }

  onChange(e) {
    this.setState({ resolutionNote: e.target.value });
  }

  openIssueModal = issue => this.setState({ showModal: true, currentIssue: issue });

  closeIssueModal = e => {
    this.setState({
      showModal: false,
      resolutionNote: "",
      currentIssue: {
        user_id: null,
        entity_id: null,
        entity_type_id: null,
        issue_id: null,
        status: "",
        messages: [],
        seen: false,
        email: "",
      },
    });
  };

  fetchIssues = async () => {
    try {
      const { data } = await axios.get(ISSUES_API(this.props.currency, "issues-researcher"));
      this.setState({
        issues: data.issues,
      });
    } catch (err) {
      throw err;
    }
  };

  resolveIssue = async (e, message, status) => {
    // Prevents the page from refreshing on form submit
    e.preventDefault();

    // Make the api call to resolve the issue
    await axios.put(ISSUES_API(this.props.currency, "issues-researcher"), {
      issue_id: this.state.currentIssue.issue_id,
      resolution_message: message,
      status,
      memo: this.state.currentIssue.messages[0].message,
      email: this.state.currentIssue.email,
    });

    const x = this.state.issues;
    x.forEach(issue => {
      if (issue.issue_id === this.state.currentIssue.issue_id) {
        issue.status = status;
        issue.messages.push({
          message: message,
          message_from_user: false,
          timestamp: moment().utc(),
        });
      }
    });

    this.setState({
      showModal: false,
      resolutionNote: "",
      currentIssue: {
        user_id: null,
        entity_id: null,
        entity_type_id: null,
        issue_id: null,
        status: "",
        messages: [],
        seen: false,
        email: "",
      },
      issues: x,
    });
  };

  csvDownload = () => {
    if (this.state.issues === null) {
      return null;
    }

    const data = this.state.issues.map(item => ({
      Currency: this.currencyType(item.currency),
      "Issue #": item.issue_id,
      "Issue Type": item.issue_type,
      "Entity Type": this.entityType(item.entity_type_id),
      Entity: item.entity_id,
      User_id: item.user_id,
      "User Email": item.email,
      Memo: item.messages[0].message,
      Submitted: moment(item.submitted_timestamp)
        .utc()
        .format("MM-DD-YYYY"),
      Status: item.status,
    }));
    let csv = Papa.unparse(data);

    // The encodeURIComponent wrapper around the csv object was necessary for firefox compatibility
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    }
    return (
      <a className="downloadButton2" href={csv} download="whitesail-researcher-issues.csv">
        {" "}
        <FontAwesomeIcon icon={faDownload} /> Download{" "}
      </a>
    );
  };

  render() {
    // Need to add an undefined check
    const resolvedIssues = [];
    const underReviewIssues = [];
    const pendingIssues = [];

    this.state.issues.forEach(value => {
      if (value !== undefined) {
        const {
          user_id,
          entity_id,
          issue_id,
          submitted_timestamp,
          status,
          currency,
          issue_type,
        } = value;

        // Only show unresolved issues unless the checkbox is active
        if (status === "Resolved") {
          resolvedIssues.push(
            <tr
              key={issue_id}
              className="inviteRow hoverableTableRow"
              onClick={() => {
                this.openIssueModal(value);
              }}
            >
              <td />
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {this.currencyType(currency)}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}> {issue_type}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}> {issue_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{user_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{entity_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {moment(submitted_timestamp)
                  .utc()
                  .format("MM-DD-YYYY")}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>Resolved</td>
            </tr>
          );
        } else if (status === "Under Review") {
          underReviewIssues.push(
            <tr
              key={issue_id}
              className="inviteRow hoverableTableRow"
              onClick={() => {
                this.openIssueModal(value);
              }}
            >
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}></td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {this.currencyType(currency)}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}> {issue_type}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}> {issue_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{user_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{entity_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {moment(submitted_timestamp)
                  .utc()
                  .format("MM-DD-YYYY")}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{status}</td>
            </tr>
          );
        } else {
          pendingIssues.push(
            <tr
              key={issue_id}
              className="inviteRow hoverableTableRow"
              onClick={() => {
                this.openIssueModal(value);
              }}
            >
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                <span className="seenDot" />
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {this.currencyType(currency)}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}> {issue_type}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}> {issue_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{user_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{entity_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {moment(submitted_timestamp)
                  .utc()
                  .format("MM-DD-YYYY")}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{status}</td>
            </tr>
          );
        }
      }
    });

    this.props.sendTotal(underReviewIssues.length + pendingIssues.length);
    return (
      <Grid>
        <Row style={{ marginBottom: "15px" }}>
          <Col lg={12}>
            <div
              onClick={() => this.setState({ showResolvedIssues: !this.state.showResolvedIssues })}
              style={{
                marginRight: "5px",
                display: "inline-block",
                height: "20px",
                width: "20px",
                border: "2px solid var(--base-color)",
                verticalAlign: "middle",
                textAlign: "center",
                fontFamily: "Quicksand",
                cursor: "pointer",
              }}
            >
              {this.state.showResolvedIssues ? (
                <FontAwesomeIcon
                  style={{ color: "var(--base-color)", paddingBottom: "2px" }}
                  icon={faCheck}
                />
              ) : (
                ""
              )}
            </div>
            <span
              style={{
                fontFamily: "Quicksand",
                fontWeight: "700",
                color: "#444",
                marginRight: "20px",
              }}
            >
              {" "}
              Show Resolved Tickets{" "}
            </span>
            {this.csvDownload()}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Table className="inviteTable" style={{ maxWidth: "100%", tableLayout: "fixed" }}>
              <thead>
                <tr>
                  <th style={{ width: "3%", textOverflow: "ellipsis", overflow: "hidden" }} />
                  <th style={{ width: "10%", textOverflow: "ellipsis", overflow: "hidden" }}>
                    Currency
                  </th>
                  <th style={{ width: "10%", textOverflow: "ellipsis", overflow: "hidden" }}>
                    Category
                  </th>
                  <th style={{ width: "8%", textOverflow: "ellipsis", overflow: "hidden" }}>
                    {" "}
                    Issue Id{" "}
                  </th>
                  <th style={{ width: "8%", textOverflow: "ellipsis", overflow: "hidden" }}>
                    User Id
                  </th>
                  <th style={{ width: "31%", textOverflow: "ellipsis", overflow: "hidden" }}>
                    Entity
                  </th>
                  <th style={{ width: "10%", textOverflow: "ellipsis", overflow: "hidden" }}>
                    Submitted (UTC)
                  </th>
                  <th style={{ width: "20%", textOverflow: "ellipsis", overflow: "hidden" }}>
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {pendingIssues}
                {underReviewIssues}
                {this.state.showResolvedIssues ? <>{resolvedIssues}</> : ""}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Modal show={this.state.showModal} onHide={this.closeIssueModal} className="issuesModal">
          <Modal.Header closeButton>
            <Modal.Title className="entityTitle">
              {this.currencyType(this.state.currentIssue.currency)} Issue #
              {this.state.currentIssue.issue_id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p className="entityType"> {this.currencyType(this.state.currentIssue.currency)}</p>{" "}
              <p className="entityType">
                {" "}
                {this.entityType(this.state.currentIssue.entity_type_id)}{" "}
              </p>
              <p className="entityName">{this.state.currentIssue.entity_id}</p>
            </div>
            <div className="entityDiv">
              <p className="entityPrompt"> Messages </p>
              <div className="chat">
                <div className="chatContent">
                  {this.state.currentIssue.messages
                    .map(x =>
                      !x.message_from_user ? (
                        <ChatBubble
                          timestamp={x.timestamp}
                          text={x.message}
                          email={"Cryptovoyant Support"}
                          outgoing_message={true}
                        >
                          {x.message}
                        </ChatBubble>
                      ) : (
                        <ChatBubble
                          timestamp={x.timestamp}
                          text={x.message}
                          email={this.state.currentIssue.email}
                          outgoing_message={false}
                        >
                          {x.message}
                        </ChatBubble>
                      )
                    )
                    .reverse()}
                </div>
              </div>
            </div>
            <div>
              <Form onSubmit={e => this.resolveIssue(e, this.state.resolutionNote, "Resolved")}>
                <FormGroup>
                  <p className="entityPrompt"> Message to User </p>
                  <FormControl
                    componentClass="textarea"
                    placeholder="Resolution Message"
                    onChange={e => this.onChange(e)}
                    style={{ resize: "vertical", width: "600px", fontFamily: "Quicksand" }}
                  />
                </FormGroup>
                {this.state.currentIssue.status === "Pending" && (
                  <Button
                    type="submit"
                    className="blueIssuesButton"
                    onClick={e => this.resolveIssue(e, null, "Under Review")}
                  >
                    Mark as 'Under Review'
                  </Button>
                )}
                <Button
                  type="submit"
                  className="greenButton"
                  disabled={this.state.resolutionNote.length === 0}
                >
                  Resolve Issue
                </Button>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        <br />
        {this.props.throwExceptionButtonEnabled && (
          <Button onClick={() => throwError()}> Throw error </Button>
        )}
      </Grid>
    );
  }
}

ResearcherIssues.propTypes = {
  currency: PropTypes.string.isRequired,
};

export default ResearcherIssues;
