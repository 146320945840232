/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Router } from "react-router-dom";
import { hot } from "react-hot-loader";
import { Provider } from "react-redux";
import * as StackTrace from "stacktrace-js";
import "bootstrap/dist/css/bootstrap.css";

import { storeShape } from "../prop-types";
import Main from "./Main";
import history from "./history";
import { logError, stringifyStack } from "../helpers";
import GenericNotFound from "./GenericNotFound";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  //v5.0.0
  typography: {
    body1: {
      fontSize: [16, "!important"]
    }
  }
});

const onError = async (error, errorInfo) => {
  const stacktrace = await StackTrace.fromError(error).then(stringifyStack);
  await logError(error.message, stacktrace, errorInfo.componentStack);
};

class CustomErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    onError(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <GenericNotFound />;
    }

    return this.props.children;
  }
}

CustomErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired
};

const App = ({ store }) => (
  <ThemeProvider theme={theme}>
    <CustomErrorBoundary>
      <Provider store={store}>
        <Router history={history}>
          <Main />
        </Router>
      </Provider>
    </CustomErrorBoundary>
  </ThemeProvider>
);

App.propTypes = {
  store: storeShape.isRequired
};

export default hot(module)(App);
