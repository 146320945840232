/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

// The following is borrowed from:
// https://github.com/facebook/draft-js/tree/master/examples/draft-0-10-0/rich
import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import {
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  RichUtils
} from "draft-js";
import { debounce } from "lodash";
import "draft-js/dist/Draft.css";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCurrency } from "../../selectors/currency";

import BlockStyleControls from "./BlockStyleControls";
import InlineStyleControls from "./InlineStyleControls";
import "../../../css/RichEditor.css";
import axios, {
  GRAPH_API,
  NOTES_API,
  ADDRESS_API,
  TRANSACTION_API
} from "../../api";
import { CircularProgress, Tooltip } from "@material-ui/core";
import WalletNoteEditor from "./WalletNoteEditor";
import GraphTransactionNoteEditor from "./GraphTransactionNoteEditor";
import { Button, Col, Row, Table, Tab, Tabs } from "react-bootstrap";
import NoteEditor from "./index";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TransactionNoteEditor from "./TransactionNoteEditor";
import { getClientMode } from "../../selectors/applicationConfig";
import ClientModeConfig from "../ClientModeConfig";

class GraphNoteEditor extends Component {
  state = {
    graphData: { location: null, locationId: null },
    graphMentionData: { location: null, locationId: null },
    graphWalletData: { location: null, locationId: null },
    graphTransactionData: { sourceId: null, dstId: null },
    allGraphData: { note: {}, graph: {}, node: {}, edge: [] },
    graphNoteEditorState: null,
    graphMentionsEditorState: null,
    graphLoading: false,
    graphKey: "graphNote",
    graphDescription: "",
    showGraphWalletData: false,
    showGraphTransactionData: false,
    showSingleGraphTransactionData: false
  };

  componentDidMount() {
    this.fetchGraphNote(this.props.graph, this.props.graphId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.graphId !== prevProps.graphId) {
      this.setState({ graphLoading: false });
      this.fetchGraphNote(this.props.graph, this.props.graphId);
    }
  }

  getNoteId = async () => {
    let noteId = null;
    if (!this.props.mainLocationId) {
      if (this.props.mainLocationName === "address") {
        const graphData = await axios.get(
          `${ADDRESS_API(this.props.currency)}/${
            this.props.mainLocationName
          }/exists`
        );
        noteId = await graphData.data.graphId;
      } else if (this.props.mainLocationName === "transaction") {
        const transactionData = await axios.get(
          `${TRANSACTION_API(this.props.currency)}/${
            this.props.mainLocationName
          }/exists`
        );
        noteId = await transactionData.data.transactionId;
      }
    } else {
      noteId = await parseInt(this.props.mainLocationId, 10);
    }
    return noteId;
  };

  fetchGraphNote = async (graph, graphId) => {
    try {
      const { data } = await axios.get(
        `${NOTES_API(this.props.currency)}/graph/${graphId}`
      );
      const noteId = await this.getNoteId();
      if (!data[this.props.mainLocation][noteId]) {
        await axios.post(`${NOTES_API(this.props.currency)}/graph/${graphId}`, {
          noteId: noteId,
          mainLocation: this.props.mainLocation
        });
      }
      this.setState({ allGraphData: data }, () => {
        //             const noteId = parseInt(this.props.mainLocationId, 10);
        if (this.state.allGraphData[this.props.mainLocation][noteId]) {
          if (
            this.state.allGraphData[this.props.mainLocation][noteId]
              .editorContent
          ) {
            this.setState({
              graphNoteEditorState: EditorState.createWithContent(
                convertFromRaw(
                  this.state.allGraphData[this.props.mainLocation][noteId]
                    .editorContent
                )
              )
            });
          } else {
            this.setState({ graphNoteEditorState: EditorState.createEmpty() });
          }
        } else {
          this.setState({ graphNoteEditorState: EditorState.createEmpty() });
        }
        this.setState(
          {
            graphData: {
              ...this.state.graphData,
              location: this.props.mainLocation
            }
          },
          () => {
            this.setState({
              graphData: { ...this.state.graphData, locationId: noteId }
            });
          }
        );
        this.setState({ graphLoading: true });
      });
    } catch (err) {
      this.props.history.replace("/case-management");
      throw err;
    }
  };

  handleSave = async (message = false) => {
    const graphId = this.props.graphId;
    const graphLocation = this.state.graphData.location;
    const graphLocationId = this.state.graphData.locationId;
    const graphNote = JSON.stringify(
      convertToRaw(this.state.graphNoteEditorState.getCurrentContent())
    );
    if ("changeCreateGraphNoteButton" in this.props) {
      if (
        !(
          convertToRaw(this.state.graphNoteEditorState.getCurrentContent())[
            "blocks"
          ].length === 1 &&
          convertToRaw(this.state.graphNoteEditorState.getCurrentContent())[
            "blocks"
          ][0]["text"] === ""
        )
      ) {
        this.props.changeCreateGraphNoteButton(graphId, true);
      } else {
        this.props.changeCreateGraphNoteButton(graphId, false);
      }
    }
    try {
      await axios.put(`${NOTES_API(this.props.currency)}/graph/${graphId}`, {
        graphLocation,
        graphLocationId,
        graphNote
      });
    } catch (err) {
      window.scrollTo(0, 0);
      throw err;
    }
  };

  handleSaveMentions = async (message = false) => {
    const graphId = this.props.graphId;
    const graphLocation = this.state.graphMentionData.location;
    const graphLocationId = this.state.graphMentionData.locationId;
    const graphNote = JSON.stringify(
      convertToRaw(this.state.graphMentionsEditorState.getCurrentContent())
    );

    try {
      const updatedEditor = {
        editorContent: convertToRaw(
          this.state.graphMentionsEditorState.getCurrentContent()
        ),
        locationName: this.state.allGraphData[graphLocation][graphLocationId]
          .locationName
      };
      let copyState = { ...this.state.allGraphData };
      copyState[graphLocation][graphLocationId] = updatedEditor;
      this.setState({ allGraphData: copyState });
      await axios.put(`${NOTES_API(this.props.currency)}/graph/${graphId}`, {
        graphLocation,
        graphLocationId,
        graphNote
      });
    } catch (err) {
      window.scrollTo(0, 0);
      throw err;
    }
  };

  handleGraphSelect = graphKey => this.setState({ graphKey });
  onGraphEdit = graphNoteEditorState => this.setState({ graphNoteEditorState });
  onGraphMentionEdit = graphMentionsEditorState =>
    this.setState({ graphMentionsEditorState });

  updateGraphMentionEditorState = (locationId, location) => {
    this.setState({
      graphDescription:
        location.charAt(0).toUpperCase() +
        location.slice(1).toLowerCase() +
        " - " +
        this.state.allGraphData[location][locationId].locationName
    });
    if (this.state.allGraphData[location][locationId].editorContent) {
      this.setState({
        graphMentionsEditorState: EditorState.createWithContent(
          convertFromRaw(
            this.state.allGraphData[location][locationId].editorContent
          )
        )
      });
    } else {
      this.setState({ graphMentionsEditorState: EditorState.createEmpty() });
    }
    this.setState(
      {
        graphMentionData: { ...this.state.graphMentionData, location: location }
      },
      () => {
        this.setState({
          graphMentionData: {
            ...this.state.graphMentionData,
            locationId: locationId
          }
        });
      }
    );
  };

  graphWalletNoteSelect = (location, locationId) => {
    this.setState({ showGraphWalletData: true });
    this.setState({
      graphWalletData: { locationId: locationId, location: location }
    });
  };

  graphSingleTransactionNoteSelect = (location, locationId) => {
    this.setState({ showSingleGraphTransactionData: true });
    this.setState({
      graphWalletData: { locationId: locationId, location: location }
    });
  };

  graphTransactionNoteSelect = (sourceId, dstId) => {
    this.setState({ showGraphTransactionData: true });
    this.setState({
      graphTransactionData: { sourceId: sourceId, dstId: dstId }
    });
  };

  changeCreateNodeNoteButton = (noteId, hasNote) => {
    this.state.allGraphData.node[noteId] = {
      ...this.state.allGraphData.node[noteId],
      hasNote: hasNote
    };
  };

  changeCreateEdgeNoteButton = (
    currentSourceId,
    currentDstId,
    currentHasNote
  ) => {
    const newEdgeState = this.state.allGraphData.edge.map(edgeData_ => {
      const { sourceId, dstId, hasNote } = edgeData_;
      if (sourceId === currentSourceId && dstId === currentDstId) {
        return {
          sourceId: currentSourceId,
          dstId: currentDstId,
          hasNote: currentHasNote
        };
      }
      return edgeData_;
    });
    this.state.allGraphData.edge = newEdgeState;
  };

  render() {
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    const graphMentionsNotes = Object.keys(this.state.allGraphData.note).map(
      locationId => {
        const locationName = this.state.allGraphData.note[locationId]
          .locationName;
        if (
          (this.props.mainLocation === "note" &&
            this.props.mainLocationId !== locationId) ||
          this.props.mainLocation !== "note"
        ) {
          return (
            <tr key={locationName}>
              <td>
                <Link
                  to={`/${this.props.currency}/case-management/${locationId}`}
                  target="_blank"
                  style={{ color: "var(--link-colors)" }}
                >
                  {locationName}
                </Link>{" "}
                <Button
                  className="pull-right greenButtonGraph"
                  bsSize="xsmall"
                  onClick={() =>
                    this.updateGraphMentionEditorState(locationId, "note")
                  }
                >
                  See Notes
                </Button>
              </td>
            </tr>
          );
        } else if (
          this.props.mainLocation === "note" &&
          this.props.mainLocationId === locationId &&
          Object.keys(this.state.allGraphData.note).length === 1
        ) {
          return (
            <tr>
              <td>None Found</td>
            </tr>
          );
        }
      }
    );

    const graphMentionsGraphs = Object.keys(this.state.allGraphData.graph).map(
      locationId => {
        const locationName = this.state.allGraphData.graph[locationId]
          .locationName;
        //         const shorterTransactionName = locationName.substring(0, 55) + "...";
        //         if ()

        return (
          <tr key={locationId}>
            <td>
              <Link
                to={`/${this.props.currency}/graph/${locationId}`}
                target="_blank"
                style={{ color: "var(--link-colors)" }}
              >
                {locationName}
              </Link>{" "}
              <Button
                className="pull-right greenButtonGraph"
                bsSize="xsmall"
                onClick={() =>
                  this.updateGraphMentionEditorState(locationId, "graph")
                }
              >
                See Notes
              </Button>
            </td>
          </tr>
        );
      }
    );

    const graphWalletNotesData = Object.keys(this.state.allGraphData.node).map(
      locationId => {
        const locationName = this.state.allGraphData.node[locationId]["label"];
        const locationType = this.state.allGraphData.node[locationId]["type"];
        const hasNote = this.state.allGraphData.node[locationId]["hasNote"];
        if (locationType === "default") {
          return (
            <tr key={locationId}>
              <td>
                {locationId > 0 ?
                <Link
                  to={`/${this.props.currency}/wallet/${locationId}`}
                  target="_blank"
                  style={{ color: "var(--link-colors)" }}
                >
                  {locationName}
                </Link> :
                <Link
                  to={`/${this.props.currency}/custom-wallet/${locationId}/transactions`}
                  target="_blank"
                  style={{ color: "var(--link-colors)" }}
                >
                  {locationName}
                </Link>}{" "}
                {hasNote ? (
                  <Button
                    className="pull-right greenButtonGraph"
                    bsSize="xsmall"
                    onClick={() =>
                      this.graphWalletNoteSelect(locationName, locationId)
                    }
                  >
                    See Notes
                  </Button>
                ) : (
                  <Button
                    className="pull-right greenButtonGraph"
                    bsSize="xsmall"
                    onClick={() =>
                      this.graphWalletNoteSelect(locationName, locationId)
                    }
                  >
                    + Create Note
                  </Button>
                )}
              </td>
            </tr>
          );
        }
      }
    );

    const graphSingleTransactionNotesData = Object.keys(
      this.state.allGraphData.node
    ).map(locationId => {
      const locationName = this.state.allGraphData.node[locationId]["label"];
      const locationType = this.state.allGraphData.node[locationId]["type"];
      const hasNote = this.state.allGraphData.node[locationId]["hasNote"];
      if (locationType === "transaction") {
        return (
          <tr key={locationId}>
            <td>
              <Link
                to={`/${this.props.currency}/transaction/${locationId}`}
                target="_blank"
                style={{ color: "var(--link-colors)" }}
              >
                {locationName}
              </Link>{" "}
              {hasNote ? (
                <Button
                  className="pull-right greenButtonGraph"
                  bsSize="xsmall"
                  onClick={() =>
                    this.graphSingleTransactionNoteSelect(
                      locationName,
                      locationId
                    )
                  }
                >
                  See Notes
                </Button>
              ) : (
                <Button
                  className="pull-right greenButtonGraph"
                  bsSize="xsmall"
                  onClick={() =>
                    this.graphSingleTransactionNoteSelect(
                      locationName,
                      locationId
                    )
                  }
                >
                  + Create Note
                </Button>
              )}
            </td>
          </tr>
        );
      }
    });

    const graphTransactionNotesData = this.state.allGraphData.edge.map(
      edgeInfo_ => {
        const { sourceId, dstId, hasNote } = edgeInfo_;
        return (
          <tr key={sourceId}>
            <td>
              <Link
                to={`/${this.props.currency}/wallet/${sourceId}`}
                target="_blank"
                style={{ color: "var(--link-colors)" }}
              >
                {this.state.allGraphData.node[sourceId]["label"]}
              </Link>{" "}
              to{" "}
              <Link
                to={`/${this.props.currency}/wallet/${dstId}`}
                target="_blank"
                style={{ color: "var(--link-colors)" }}
              >
                {this.state.allGraphData.node[dstId]["label"]}
              </Link>{" "}
              {hasNote ? (
                <Button
                  className="pull-right greenButtonGraph"
                  bsSize="xsmall"
                  onClick={() =>
                    this.graphTransactionNoteSelect(sourceId, dstId)
                  }
                >
                  See Notes
                </Button>
              ) : (
                <Button
                  className="pull-right greenButtonGraph"
                  bsSize="xsmall"
                  onClick={() =>
                    this.graphTransactionNoteSelect(sourceId, dstId)
                  }
                >
                  + Create Note
                </Button>
              )}
            </td>
          </tr>
        );
      }
    );

    return (
      <>
        {this.state.graphLoading && (
          <Tabs
            className="inviteTabs"
            activeKey={this.state.graphKey}
            id="graph-tab"
            onSelect={this.handleGraphSelect}
            animation={false}
            mountOnEnter
          >
            <Tab eventKey="graphNote" title="Graph Note">
              <>
                <Button
                  style={{
                    position: "relative",
                    float: "right",
                    top: "10px",
                    right: "10px"
                  }}
                  className="greenButton"
                  onClick={() => this.handleSave()}
                >
                  Save
                </Button>{" "}
                <NoteEditor
                  height={700}
                  editorState={this.state.graphNoteEditorState}
                  onEdit={this.onGraphEdit}
                  saveGraph={this.handleSave}
                />
              </>
            </Tab>
            <Tab eventKey="otherGraphMentions" title="Other Mentions">
              <Row>
                <Col lg={6}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Note Mentions</th>
                      </tr>
                    </thead>
                    {Object.keys(this.state.allGraphData.note).length !== 0 ? (
                      <tbody>{graphMentionsNotes}</tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td>None Found</td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </Col>
                <Col lg={6}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Graph Mentions</th>
                      </tr>
                    </thead>
                    {Object.keys(this.state.allGraphData.graph).length !== 0 ? (
                      <tbody>{graphMentionsGraphs}</tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td>None Found</td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </Col>
              </Row>
              {this.state.graphMentionData.location ? (
                <>
                  <div>
                    <p
                      style={{
                        fontFamily: "Quicksand",
                        fontWeight: "600",
                        color: "#666",
                        marginRight: "10px",
                        marginBottom: "0",
                        fontSize: "30px"
                      }}
                    >
                      {this.state.graphDescription}
                    </p>
                    <Button
                      style={{
                        position: "relative",
                        float: "right",
                        top: "10px",
                        right: "10px"
                      }}
                      onClick={() => this.handleSaveMentions()}
                      className="greenButton"
                    >
                      Save
                    </Button>{" "}
                  </div>
                  <NoteEditor
                    height={700}
                    editorState={this.state.graphMentionsEditorState}
                    onEdit={this.onGraphMentionEdit}
                    saveGraph={this.handleSaveMentions}
                  />
                </>
              ) : (
                <></>
              )}
            </Tab>
            <Tab eventKey="graphWalletNotes" title="Graph Node Notes">
              <div className="RichEditor-root" style={{ margin: "0" }}>
                {this.state.showGraphWalletData ? (
                  <>
                    <Button
                      className="walletBackButton"
                      onClick={() =>
                        this.setState({ showGraphWalletData: false })
                      }
                    >
                      <FontAwesomeIcon icon={faArrowLeft} /> Graph Node List
                    </Button>
                    <WalletNoteEditor
                      wallet={this.state.graphWalletData.location}
                      walletId={this.state.graphWalletData.locationId}
                      mainLocationId={this.props.graphId}
                      mainLocation={"graph"}
                      changeCreateNodeNoteButton={
                        this.changeCreateNodeNoteButton
                      }
                    ></WalletNoteEditor>
                  </>
                ) : this.state.showSingleGraphTransactionData ? (
                  <>
                    <Button
                      className="walletBackButton"
                      onClick={() =>
                        this.setState({ showSingleGraphTransactionData: false })
                      }
                    >
                      <FontAwesomeIcon icon={faArrowLeft} /> Graph Node List
                    </Button>
                    <TransactionNoteEditor
                      transaction={this.state.graphWalletData.locationId}
                      mainLocationId={this.props.graphId}
                      mainLocation={"graph"}
                    ></TransactionNoteEditor>
                  </>
                ) : (
                  <Row>
                    <Col lg={12}>
                      <Table>
                        <thead>
                          <tr>
                            <th>
                              {clientModeConfigObject.cap_word_for_collection}{" "}
                              Notes
                            </th>
                          </tr>
                        </thead>
                        {Object.keys(this.state.allGraphData.node).length !==
                        0 ? (
                          <tbody>{graphWalletNotesData}</tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td>None Found</td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                      <Table>
                        <thead>
                          <tr>
                            <th>Transaction Notes</th>
                          </tr>
                        </thead>
                        {Object.keys(this.state.allGraphData.node).length !==
                        0 ? (
                          <tbody>{graphSingleTransactionNotesData}</tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td>None Found</td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                    </Col>
                  </Row>
                )}
              </div>
            </Tab>
            <Tab eventKey="graphTransactionNotes" title="Graph Edge Notes">
              <div className="RichEditor-root" style={{ margin: "0" }}>
                {this.state.showGraphTransactionData ? (
                  <>
                    <Button
                      className="walletBackButton"
                      onClick={() =>
                        this.setState({ showGraphTransactionData: false })
                      }
                    >
                      <FontAwesomeIcon icon={faArrowLeft} /> Graph Edge List
                    </Button>
                    <GraphTransactionNoteEditor
                      sourceId={this.state.graphTransactionData.sourceId}
                      dstId={this.state.graphTransactionData.dstId}
                      nodeData={this.state.allGraphData.node}
                      mainLocationId={this.props.graphId}
                      mainLocation={"graph"}
                      changeCreateEdgeNoteButton={
                        this.changeCreateEdgeNoteButton
                      }
                    ></GraphTransactionNoteEditor>
                  </>
                ) : (
                  <Row>
                    <Col lg={12}>
                      <Table>
                        <thead>
                          <tr>
                            <th>Graph Edge Notes</th>
                          </tr>
                        </thead>
                        {this.state.allGraphData.edge.length !== 0 ? (
                          <tbody>{graphTransactionNotesData}</tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td>None Found</td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                    </Col>
                  </Row>
                )}
              </div>
            </Tab>
          </Tabs>
        )}
      </>
    );
  }
}

GraphNoteEditor.defaultProps = {
  saveGraph: null
};

const mapStateToProps = state => {
  return {
    currency: getCurrency(state),
    clientMode: getClientMode(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(GraphNoteEditor)
);
