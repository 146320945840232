import * as React from "react";
import { Grid } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrency } from "../../selectors/currency";
import axios from "axios";
import { SHARE_API } from "../../api";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  withStyles
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  SWAP_CURRENCY,
  WALLET_SET_CUSTOM_TAG
} from "../../actions/actionNames";
import { setWalletTag } from "../../actions/customTags";

const ReadTextTypography = withStyles({
  root: {
    color: "#FF0000"
  }
})(Typography);

interface ShareProps {
  token: string;
  currency: string;
  showIgnore?: boolean;
  afterResolve?: () => void;
  redirect?: boolean;
}
export const Share = ({
  token,
  currency,
  showIgnore = false,
  afterResolve = () => {},
  redirect = true
}: ShareProps) => {
  const [customData, setCustomData] = useState<any>();
  const [conflictData, setConflictData] = useState<any>(null);
  let history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar(); // Notification snackbar

  useEffect(() => {
    const fetchShareData = async () => {
      try {
        const { data } = await axios.get(
          `${SHARE_API(currency)}/token/${token}`
        );
        setCustomData(data);
      } catch (e) {
        console.error(e);
        if (e && e.response && e.response.status === 403) {
          enqueueSnackbar(`Custom Entity does not exist anymore`, {
            variant: "error"
          });
          await handleDecline(false); // delete the record
        } else {
          enqueueSnackbar(`Not authorized or link has been exhausted`, {
            variant: "error"
          });
        }
        redirect && history.push("/");
      }
    };
    fetchShareData();
  }, [token]);

  const handleAdd = async (overwrite = false) => {
    try {
      await axios.put(`${SHARE_API(currency)}/token/${token}`, {
        overwrite
      });
      redirect &&
        dispatch(dispatch({ type: SWAP_CURRENCY, currency })) &&
        history.push("/custom-tags");
      enqueueSnackbar(`Custom tag added`, {
        variant: "success"
      });

      customData.custom_entity === "customTag" &&
        dispatch({
          type: WALLET_SET_CUSTOM_TAG,
          walletId: customData.entity_id.toString(),
          primaryTag: customData.primaryTag,
          email: customData.email,
          id: customData.user_tag_id,
          permission: customData.permission,
          name: currency
        });
      afterResolve();
    } catch (err) {
      console.error(err);
      if (err && err.response && err.response.status === 403) {
        const data = err.response.data;
        setConflictData(data);
      } else {
        enqueueSnackbar(`Error adding custom tag`, {
          variant: "error"
        });
        redirect && history.push("/");
      }
    }
  };

  const handleDecline = async (notification = true) => {
    try {
      const { data } = await axios.delete(
        `${SHARE_API(currency)}/token/${token}`
      );
      redirect && history.push("/custom-tags");
      notification &&
        enqueueSnackbar(`Custom tag invite declined`, {
          variant: "warning"
        });
      afterResolve();
    } catch (e) {
      console.error(e);
      notification &&
        enqueueSnackbar(`Error deleting shared custom tag`, {
          variant: "error"
        });
      redirect && history.push("/");
    }
  };

  return (
    <>
      {customData && (
        <Card>
          <CardHeader title={`Incoming shared ${customData.custom_entity}`} />
          <CardContent>
            <Typography variant="h5">{customData.message}</Typography>
            {conflictData && (
              <div>
                <br />
                <ReadTextTypography variant="h5">
                  {`This custom tag conflicts with your custom tag "${conflictData.tag}". Are you sure you want to overwrite it?`}
                </ReadTextTypography>
              </div>
            )}
          </CardContent>
          <CardActions>
            <Button
              variant="outlined"
              className={"greenButton"}
              onClick={() => handleAdd(!!conflictData)}
            >
              {conflictData ? "Overwrite" : "Add"}
            </Button>
            <Button
              variant="outlined"
              className={"redButton"}
              onClick={() => handleDecline()}
            >
              Decline
            </Button>
            {showIgnore && <Button variant="outlined">Ignore</Button>}
          </CardActions>
        </Card>
      )}
    </>
  );
};
