/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button } from "react-bootstrap";

import styled from "styled-components";
import { connect } from "react-redux";
import { cytoscapeNodeShape } from "../../prop-types";

import { addColorChangeToUndoStack } from "../../actions/graph";
import CategoryColorsConfig from "./CategoryColorsConfig";

const componentToHex = c => {
  const hex = parseInt(c).toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

// cytoscape stores colors in the following format, but the color input wants a hex
// string.
const rgbPat = /rgb\((\d+),(\d+),(\d+)\)/;

const rgbToHex = rgb => {
  const [_, r, g, b] = rgbPat.exec(rgb);
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
};

class ColorPicker extends Component {
  state = {
    currentColor: "",
    categories: []
  };

  componentWillMount() {
    const categories = [];
    CategoryColorsConfig().forEach(
      category =>
        category.label !== "Cold Entity" &&
        categories.push(category.hex.toUpperCase())
    );
    this.setState({ categories });
  }

  componentDidMount() {
    const { item, style } = this.props;
    this.setColorValueToWallets(item, style);
  }

  componentDidUpdate(prevProps) {
    const { item: oldWallet } = prevProps;
    const { item: newWallet, style } = this.props;
    if (oldWallet !== newWallet) {
      this.setColorValueToWallets(newWallet, style);
    }
  }

  setColorValueToWallets = (wallet, style) => {
    this.setState({ currentColor: rgbToHex(wallet.style()[style]) });
  };

  handleChangeColorFormSubmit = (e, color) => {
    e.preventDefault();
    const { item } = this.props;
    const cy = item.cy();
    const prevColor = rgbToHex(item.style(this.props.style));
    this.props.changeColor(prevColor, color, cy);
    this.setState({ currentColor: color });
  };

  render() {
    const { item } = this.props;
    const colors = this.state.categories.map(item => {
      return (
        <Button
          onClick={e => {
            this.handleChangeColorFormSubmit(e, item);
          }}
          onMouseDown={e => e.preventDefault()}
          style={{
            backgroundColor: item,
            height: "60px",
            width: "60px",
            margin: "10px",
            border: ` ${
              item.toUpperCase() === this.state.currentColor.toUpperCase()
                ? " 8px solid white"
                : " 2px solid transparent"
            }`,
            boxShadow: `${
              item.toUpperCase() === this.state.currentColor.toUpperCase()
                ? "0 0 10px white"
                : "none"
            }`
          }}
        />
      );
    });

    return <div>{colors}</div>;
  }
}

const StyledColorPicker = styled(ColorPicker)`
  width: 100px;
  height: 100px;
`;

ColorPicker.defaultProps = {
  fromModal: false
};

const mapDispatchToProps = (dispatch, { item, style }) => {
  return {
    changeColor: (prevColor, color, cy) =>
      dispatch(addColorChangeToUndoStack(item, style, prevColor, color, cy))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(StyledColorPicker);

ColorPicker.propTypes = {
  // This can also be a wallet or an edge.
  item: cytoscapeNodeShape.isRequired,
  // This is needed to distinguish the style for wallets and edges between wallets.
  // It should be 'background-color' for wallets and 'line-color' for edges.
  style: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
};
