/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormControl, FormGroup, Grid, Row, Table } from "react-bootstrap";
import { faSave } from "@fortawesome/free-solid-svg-icons/faSave";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faTimesCircle,
  faTrash,
  faWindowClose
} from "@fortawesome/free-solid-svg-icons";
import { getCurrency } from "../../../selectors/currency";
import axios, { CUSTOM_TAGS_API } from "../../../api";
import { getReactRouterWalletLink } from "../../Wallet/helpers";
import {
  removeCustomTagSharedUser,
  setWalletTag
} from "../../../actions/customTags";
import { setOriginalWalletTagFromPage } from "../../../actions/wallet";
import { getClientMode } from "../../../selectors/applicationConfig";
import ClientModeConfig from "../../ClientModeConfig";
import { ShareButton } from "../../Utils/ShareButton";
import { useSnackbar } from "notistack";
import { getEmail } from "../../../selectors/authentication";
import { IconButton } from "@material-ui/core";
import { WALLET_REMOVE_CUSTOM_TAG } from "../../../actions/actionNames";
import { CustomTagRow } from "./CustomTagRow";

/**
 * CustomTags shows the list of all the custom tags a user has for a currency on entities. Currently works for both ethereum
 * and bitcoin coins.
 */
export const CustomTags = () => {
  // Stores the result of the get custom tags call
  const [all_user_wallet_tag_results, setTagResults] = useState<any[]>([]);

  // TODO conflict results and stuff might be dead
  const [conflict_results, setConflictResults] = useState<any[]>([]);

  const currency = useSelector(state => getCurrency(state));
  const clientMode = useSelector(state => getClientMode(state));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar(); // Notification snackbar
  const dispatch = useDispatch();

  /**
   * Calls API to get list of custom tags and stores results in all_user_wallet_tag_results and conflict_results
   *   TODO change this to be redux actions for redirects
   */
  const fetchTags = async () => {
    if (currency === "ethereum") {
      try {
        const { data } = await axios.get("/api/v2/ethereum/graph/primary-tags");
        setTagResults(data.all_user_wallet_tag_results);
        setConflictResults([]);
      } catch (err) {
        throw err;
      }
    } else {
      try {
        const { data } = await axios.get(CUSTOM_TAGS_API(currency));
        setTagResults(data.all_user_wallet_tag_results);
        setConflictResults(data.conflict_results);
      } catch (err) {
        throw err;
      }
    }
  };

  // fetch custom tags on component mount and when they switch currencies
  useEffect(() => {
    const asyncFunc = async () => {
      await fetchTags();
    };
    asyncFunc().catch(console.error);
  }, [currency]);

  /**
   * Handles user modifying name of an existing tag. Dispatches action and modifies all_user_wallet_tag_results with the new tag
   * @param index index of entry in all_user_wallet_tag_results
   * @param entity_id id of the entity
   * @param email email of the owner of the custom tag
   * @param tag new tag name
   * @param id id of the custom tag
   * @param permission permission
   */
  const handleSetTag = async (
    index: number,
    entity_id: number,
    email: string,
    tag: string,
    id: number,
    permission: number
  ) => {
    // If they didn't actually change the tag but click save we don't want to send the request for no reason
    if (tag === all_user_wallet_tag_results[index].tag) {
      return Promise.resolve();
    }

    // dispatch the set wallet tag action
    try {
      await dispatch(setWalletTag(entity_id, tag, email, id, permission, enqueueSnackbar));
    } catch (err) {
      throw err;
    }

    // Set the primary tag in the copy and then save it to the state
    return setTagResults(all_user_wallet_tag_results =>
      all_user_wallet_tag_results.map((item, curI) => ({
        ...item,
        tag: curI === index ? tag : item.tag
      }))
    );
  };

  const handleDeleteConflict = async (wallet_id: number) => {
    try {
      const { data } = await axios.post(CUSTOM_TAGS_API(currency), {
        wallet_id
      });
      setConflictResults(conflict_results.filter(wallet => wallet.wallet_id !== wallet_id));
    } catch (err) {
      throw err;
    }
  };

  /**
   * Handles deleting a custom tag setting it back to the original tag, removes entry in all_user_wallet_tag_results
   * @param entity_id entity_id
   * @param id custom tag id
   */
  const handleSetOriginalTag = async (entity_id: number, id: number) => {
    try {
      dispatch(setOriginalWalletTagFromPage(entity_id, id));
    } catch (err) {
      throw err;
    }
    return setTagResults(all_user_wallet_tag_results.filter(wallet => wallet.id !== id));
  };

  let clientModeConfigObject = {
    word_for_collection: "",
    cap_word_for_collection: "",
    plural_for_collection: "",
    cap_plural_for_collection: ""
  };
  if (clientMode in ClientModeConfig) {
    // @ts-ignore
    clientModeConfigObject = ClientModeConfig[clientMode];
  }

  // Building list of CustomTagRows to show
  const tags = all_user_wallet_tag_results.map((item, index) => {
    const { id, entity_id, tag, attributed_tag, permission, email } = item;
    return (
      <CustomTagRow
        tagType={"customTag"}
        entity_id={entity_id}
        {...(item.address && { address: item.address })}
        id={id}
        tag={tag}
        attributed_tag={attributed_tag}
        permission={permission}
        email={email}
        currency={currency}
        handleSetTag={(tag, permission, id) =>
          handleSetTag(index, entity_id, email, tag, id, permission)
        }
        handleSetOriginalTag={id => handleSetOriginalTag(entity_id, id)}
      />
    );
  });

  // building list of conflicts. TODO might be dead
  const conflict = conflict_results.map(conflict => {
    const { wallet_id, current_custom_tag, conflicting_tags, attributed_tag } = conflict;
    return (
      <tr className="inviteRow staticTableRow" key={`${wallet_id}+${attributed_tag}`}>
        <td> {getReactRouterWalletLink(wallet_id, currency, attributed_tag)} </td>
        <td>
          {conflicting_tags.map((x: any) => (
            <li> {x} </li>
          ))}
        </td>
        <td>
          <Button
            className="redButton"
            style={{ marginLeft: "5px" }}
            onClick={() => handleDeleteConflict(wallet_id)}
          >
            Dismiss
          </Button>
        </td>
      </tr>
    );
  });

  return (
    <div>
      <Grid>
        <Row>
          <Col lg={12}>
            <Table className="inviteTable" style={{ marginBottom: "50px" }}>
              <thead>
                <tr>
                  <th style={{ width: "40%" }}>{clientModeConfigObject.cap_word_for_collection}</th>
                  <th style={{ width: "30%" }}>Custom Tag</th>
                  <th style={{ width: "30%" }} />
                </tr>
              </thead>
              <tbody>{tags}</tbody>
            </Table>
            {conflict.length > 0 && (
              <>
                <p
                  style={{
                    display: "inline",
                    marginRight: "30px",
                    fontSize: "30px",
                    color: "var(--base-color)",
                    fontWeight: "700"
                  }}
                >
                  User {clientModeConfigObject.cap_word_for_collection} Tag Conflicts
                </p>
                <p>
                  {" "}
                  The following custom tags belong to wallets that have since been reattributed, but
                  whose new {clientModeConfigObject.word_for_collection} already has a custom tag.{" "}
                </p>
                <Table className="inviteTable">
                  <thead>
                    <tr>
                      <th style={{ width: "40%" }}>
                        {clientModeConfigObject.cap_word_for_collection}
                      </th>
                      <th style={{ width: "30%" }}>Custom Tag</th>
                      <th style={{ width: "30%" }} />
                    </tr>
                  </thead>
                  <tbody>{conflict}</tbody>
                </Table>
              </>
            )}
          </Col>
        </Row>
      </Grid>
    </div>
  );
};
