/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import { shortenTransactionHash } from "../../helpers";
import { FakeLinkSpan } from "../styled";

export const getReactRouterWalletLink = (wallet, currency, primaryTag, address = null) => (
  <Link to={{ pathname: `/${currency}/wallet/${wallet}` }}>{primaryTag || address || wallet}</Link>
);

export const getModalWalletLink = (wallet, primaryTag, handleOpenWalletModal) => (
  <FakeLinkSpan onClick={() => handleOpenWalletModal(wallet)}>{primaryTag || wallet}</FakeLinkSpan>
);

// This should be used by default when you want an
// ordinary link when displaying wallet transactions
export const getReactRouterTransactionLink = (transaction, currency) => (
  <Link
    to={{ pathname: `/${currency}/transaction/${transaction}` }}
    style={{ fontFamily: "monospace" }}
  >
    {transaction}
  </Link>
);

// This is used within the Graph component since their is effectively
// no router available
// FIXME the callback is always the same, just import the function here
export const getModalTransactionLink = (transaction, handleOpenTransactionModal) => (
  <FakeLinkSpan onClick={() => handleOpenTransactionModal(transaction)}>
    {shortenTransactionHash(transaction)}
  </FakeLinkSpan>
);

export const getModalTransactionLinkUnshortened = (transaction, handleOpenTransactionModal) => (
  <FakeLinkSpan onClick={() => handleOpenTransactionModal(transaction)}>{transaction}</FakeLinkSpan>
);

export const getLongTransactionDateCell = timestamp => (
  <>
    {moment
      .unix(timestamp)
      .utc()
      .format("LLL")}
  </>
);

export const getShortTransactionDateCell = timestamp => (
  <div>
    {moment
      .unix(timestamp)
      .utc()
      .format("MM/DD/YY")}
  </div>
);

export const getModalAddressLink = (address, handleOpenAddressModal) => {
  return (
    <FakeLinkSpan onClick={() => handleOpenAddressModal(address)}>
      {address.split(":").pop()}
    </FakeLinkSpan>
  );
};

export const getReactRouterAddressLink = (address, currency) => {
  const add = address.split(":").pop();
  return <Link to={{ pathname: `/${currency}/address/${add}` }}>{add}</Link>;
};
