import React from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";

interface CircularProgressWithContentProps {
  color?: string;
  content: JSX.Element;
}

/**
 * Cryptovoyant loading symbol
 * @param content content to put in loading symbol
 * @param color color for progress notifier
 * @constructor
 */
export const CustomLoading: React.FC<CircularProgressWithContentProps> = ({ content, color }) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress size={150} style={{ color: color }} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {content}
        </Typography>
      </Box>
    </Box>
  );
};
