/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Alert,
  Button,
  ControlLabel,
  DropdownButton,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Table,
} from "react-bootstrap";

import {
  chooseBetweenConversions,
  getUnixTimestampFromDate,
  numberWithCommas,
  onInputChange,
  satoshiToBitcoin,
  satoshiToBitcoinNoCommas,
} from "../../helpers";
import { ScrollDiv } from "../styled";
import {
  getAddressMutualWallets2,
  makeGetAddressTransactions,
  makeGetWalletMutualTransactions,
} from "../../selectors/address";
import {
  addressMutualTransactionsSetLargestFirst,
  addressMutualTransactionsSwapOrder,
  addressMutualWalletTransactionsSetDateRange,
  addressTransactionSetOrder,
  addressTransactionsSetDateRange,
  addressTransactionsSetLargestFirst,
  addressTransactionsSwapOrder,
  fetchAddressMutualWallets,
  fetchAddressTransactions,
  fetchAddressTransactionsBySatoshi,
  fetchMutualWalletTransactions,
  setScrollTop,
} from "../../actions/address";
import { faFilter } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import MenuItem from "react-bootstrap/es/MenuItem";
import { faSort } from "@fortawesome/free-solid-svg-icons/faSort";
import CopyText from "../CopyText";
import DataDownload from "../DataDownload";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import TransactionSorter from "../Utils/TransactionSorter";
import { setOrder } from "../../reducers/wallet";
import { Skeleton } from "@material-ui/lab";
import MutualWallets from "./MutualWallets";
import { ADDRESS_API } from "../../api";

/**
 * Table of transactions associated with an address.
 * Transaction rows displayed have date, link to detailed transactions,
 * and bitcoin.
 */
class AddressTransactionsAll extends Component {
  state = {
    loading: false,
    currentView: "transactions",
  };

  componentDidMount() {
    this.getOrFetchAddressTransactions();
  }

  componentDidUpdate(prevProps) {
    // Make back button work when changing addresses
    const { address: oldAddress } = prevProps;
    const { address: newAddress } = this.props;
    if (oldAddress !== newAddress) {
      this.getOrFetchAddressTransactions();
    }
  }

  fetchAddressTransactions = () => {
    const { address, order, startDate, endDate } = this.props;
    this.setState({ loading: true });
    if (order === 2) {
      this.props.fetch(address, startDate, endDate).then(() => this.setState({ loading: false }));
    } else {
      this.props
        .fetch(address, order, startDate, endDate)
        .then(() => this.setState({ loading: false }));
    }
  };

  /**
    get if it already exists in state if not fetch
   */
  getOrFetchAddressTransactions = () => {
    if (!this.props.transactions || this.props.transactions.length === 0) {
      this.fetchAddressTransactions();
    } else {
      this.setState({ loading: false });
    }
  };

  handleSetWalletFilter = () => {
    if (this.state.currentView === "transactions") {
      this.setState({
        currentView: "mutualWallets",
      });
    } else {
      this.setState({
        currentView: "transactions",
      });
    }
  };

  getTransactions = () => {
    let alternate = false;
    let transactionComponents = this.props.transactions.map(transaction => {
      alternate = !alternate;
      const {
        transactionHash,
        transactionId,
        inputSatoshi,
        outputSatoshi,
        satoshi,
        timestamp,
        price,
        priceAvailable,
      } = transaction;
      const { showAsUsd, convertPriceToUsd, historicalToUsd, currentUsdPrice } = this.props;

      const inputBitcoin = satoshiToBitcoinNoCommas(inputSatoshi);
      const outputBitcoin = satoshiToBitcoinNoCommas(outputSatoshi);
      const bitcoin = satoshiToBitcoinNoCommas(satoshi);
      const time = moment
        .unix(timestamp)
        .utc()
        .format("LLL");

      // This is the only place getTableScrollTop works.
      return (
        <tr key={transactionId} className="inviteRowThin staticTableRow">
          <td style={{ backgroundColor: alternate === false ? "#F0F0F0" : "" }}>{time}</td>
          <td style={{ backgroundColor: alternate === false ? "#F0F0F0" : "" }}>
            <CopyText text={transactionHash} />
            {this.props.getTransactionLink(transactionHash, this.props.currency)}
          </td>
          <td
            style={{
              backgroundColor: alternate === false ? "#F0F0F0" : "",
              textAlign: "right",
            }}
          >
            {chooseBetweenConversions(
              [numberWithCommas(inputBitcoin), !showAsUsd],
              ["$" + numberWithCommas(convertPriceToUsd(inputBitcoin, currentUsdPrice)), showAsUsd],
              ["$" + numberWithCommas((inputBitcoin * price).toFixed(2)), historicalToUsd],
              priceAvailable
            )}
          </td>
          <td
            style={{
              backgroundColor: alternate === false ? "#F0F0F0" : "",
              textAlign: "right",
            }}
          >
            {chooseBetweenConversions(
              [numberWithCommas(outputBitcoin), !showAsUsd],
              [
                "$" + numberWithCommas(convertPriceToUsd(outputBitcoin, currentUsdPrice)),
                showAsUsd,
              ],
              ["$" + numberWithCommas((outputBitcoin * price).toFixed(2)), historicalToUsd],
              priceAvailable
            )}
          </td>
          <td
            style={{
              backgroundColor: alternate === false ? "#F0F0F0" : "",
              textAlign: "right",
            }}
          >
            {chooseBetweenConversions(
              [numberWithCommas(bitcoin), !showAsUsd],
              ["$" + numberWithCommas(convertPriceToUsd(bitcoin, currentUsdPrice)), showAsUsd],
              ["$" + numberWithCommas((bitcoin * price).toFixed(2)), historicalToUsd],
              priceAvailable
            )}
          </td>
        </tr>
      );
    });

    // LoadingSkeleton is for loading bars for entire table
    // currently only useful for custom wallets when processing deletion/addition of addresses
    let loadingSkeleton = [];
    if (this.state.loading) {
      // react list of skeleton rows for showing loading bars on table
      let i = 0;
      loadingSkeleton = [...Array(this.props.loadingRows)].map(_ => {
        i += 1;
        return (
          <tr key={"loading" + i} className="inviteRowThin staticTableRow">
            {[...Array(5)].map(_ => (
              <td>
                <Skeleton />
              </td>
            ))}
          </tr>
        );
      });
      transactionComponents = [...transactionComponents, ...loadingSkeleton];
    }
    return transactionComponents;
  };

  handleSetTransactionOrder = async (newOrder, startDateUnix, endDateUnix) => {
    const newOrderNumber = parseInt(newOrder); // make sure newOrder is an int
    this.setState({
      loading: true,
    });

    const {
      address,
      newestFirstNextKey,
      order,
      oldestFirstNextKey,
      largestFirstNextKey,
    } = this.props;

    await this.props.onSetOrder(address, newOrderNumber);
    if (newOrderNumber === -1 && oldestFirstNextKey === 0) {
      this.props
        .fetch(address, -1, startDateUnix, endDateUnix, true)
        .then(() => this.setState({ loading: false }));
    } else if (newOrderNumber === 1 && newestFirstNextKey === 0) {
      this.props
        .fetch(address, 1, startDateUnix, endDateUnix, true)
        .then(() => this.setState({ loading: false }));
    } else if (newOrderNumber === 2 && largestFirstNextKey === 0) {
      this.props
        .fetch(address, startDateUnix, endDateUnix, true)
        .then(() => this.setState({ loading: false }));
    } else {
      this.setState({ loading: false }); // fits none of the cases so we set loading to false
    }
  };

  render() {
    const {
      order,
      newestFirstNextKey,
      oldestFirstNextKey,
      maxHeight,
      byLargest,
      hasNextLargest,
      address,
      currency,
    } = this.props;

    let buttonDisabled = !(
      (order > 0 && newestFirstNextKey !== null) ||
      (order < 0 && oldestFirstNextKey !== null)
    );
    if (byLargest && !hasNextLargest) {
      buttonDisabled = true;
    }
    const buttonText = !buttonDisabled ? "Load More ..." : "No more   ";
    const transactionComponents = this.getTransactions();
    if (this.state.currentView === "transactions") {
      return (
        <div className="col-lg-12" style={{ paddingLeft: "0", paddingRight: "0", paddingTop: "0" }}>
          <div className="row" style={{ marginLeft: "0", marginBottom: "15px", marginTop: "15px" }}>
            <div className="blueButtonFilter">
              <Button
                className="greenButton"
                style={{ marginRight: "25px" }}
                onClick={this.handleSetWalletFilter}
              >
                <FontAwesomeIcon icon={faFilter} /> Add Attribution Category Filter
              </Button>
              <TransactionSorter
                entity_id={address}
                order={order}
                handleOnSetDate={this.props.onSetDate}
                handleSetTransactionOrder={this.handleSetTransactionOrder}
                setLoading={loading => this.setState({ loading: loading })}
              />
              <Button
                className="whiteButton"
                onClick={this.fetchAddressTransactions}
                disabled={buttonDisabled}
                style={{ float: "right" }}
              >
                {buttonText}
              </Button>
              {this.props.transactions.length !== 0 && (
                <div style={{ float: "right" }}>
                  <DataDownload
                    data={this.props.transactions.map(item => {
                      return {
                        "Transaction Hash": item.transactionHash,
                        Timestamp: moment
                          .unix(item.timestamp)
                          .utc()
                          .format("LLL"),
                        "Input (Bitcoin)": satoshiToBitcoin(item.inputSatoshi),
                        "Output (Bitcoin)": satoshiToBitcoin(item.outputSatoshi),
                        "Transaction Balance (Bitcoin)": satoshiToBitcoin(item.satoshi),
                        "Input (USD)": item.priceAvailable
                          ? "$" +
                            (satoshiToBitcoinNoCommas(item.inputSatoshi) * item.price).toFixed(2)
                          : "N/A",
                        "Output (USD)": item.priceAvailable
                          ? "$" +
                            (satoshiToBitcoinNoCommas(item.outputSatoshi) * item.price).toFixed(2)
                          : "N/A",
                        "Transaction Balance (USD)": item.priceAvailable
                          ? "$" + (satoshiToBitcoinNoCommas(item.satoshi) * item.price).toFixed(2)
                          : "N/A",
                        "USD Price": item.priceAvailable ? "$" + item.price.toFixed(2) : "N/A",
                      };
                    })}
                    entity_type="transaction"
                    style={{ float: "right", margin: "10px" }}
                  />
                </div>
              )}
            </div>
          </div>
          {// If the length is zero or not processed display regular table
          transactionComponents.length > 0 ? (
            <ScrollDiv maxHeight={maxHeight} id="addressTransactionsTable">
              <Table
                className="inviteTable"
                style={{
                  width: "100%",
                  tableLayout: "fixed",
                  overflow: "auto",
                  wordWrap: "break-word",
                }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "15%" }}>Timestamp</th>
                    <th style={{ width: "46%" }}>Transaction</th>
                    <th style={{ textAlign: "right", width: "13%" }}>Sent (Inputs)</th>
                    <th style={{ textAlign: "right", width: "13%" }}>Received (Outputs)</th>
                    <th style={{ textAlign: "right", width: "13%" }}>Trx Balance</th>
                  </tr>
                </thead>
                <tbody>{transactionComponents}</tbody>
              </Table>
            </ScrollDiv>
          ) : (
            // if transactionComponents has 0 length and is processed means no transactions
            <Alert style={{ boxShadow: "0px 2px 2px 0px #666" }} bsStyle="warning">
              No transactions found
            </Alert>
          )}
        </div>
      );
    }
    return (
      <MutualWallets
        sourceId={address}
        entityType={"address"}
        sourceTag={null}
        apiBase={ADDRESS_API}
        maxHeight={700}
        getTransactionLink={this.props.getTransactionLink}
        handleOpenDateSelectModal={this.handleOpenDateSelectModal}
        handleCloseDateSelectModal={this.handleCloseDateSelectModal}
        handleSetWalletFilter={this.handleSetWalletFilter}
        currentView={this.state.currentView}
        fetchMoreWallets={fetchAddressMutualWallets}
        fetchTransactions={fetchMutualWalletTransactions}
        getWallets={getAddressMutualWallets2}
        getMutualTransactions={makeGetWalletMutualTransactions}
        setDateRange={addressMutualWalletTransactionsSetDateRange}
        setLargest={addressMutualTransactionsSetLargestFirst}
        fetchMoreTransactions={fetchMutualWalletTransactions}
        swapOrder={addressMutualTransactionsSwapOrder}
        fromAddressView
        currency={currency}
        convertPriceToUsd={this.props.convertPriceToUsd}
        currentUsdPrice={this.props.currentUsdPrice}
        toggleUsdDisplay={this.props.toggleUsdDisplay}
        showAsUsd={this.props.showAsUsd}
        historicalToUsd={this.props.historicalToUsd}
        toggleHistoricalUsd={this.props.toggleHistoricalUsd}
        showChange={this.props.showChange}
        toggleChange={this.props.toggleChange}
      />
    );
  }
}

AddressTransactionsAll.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      transactionHash: PropTypes.string.isRequired,
      transactionId: PropTypes.number.isRequired,
      satoshi: PropTypes.number.isRequired,
      timestamp: PropTypes.number.isRequired,
    })
  ).isRequired,
  convertPriceToUsd: PropTypes.func.isRequired,
  currentUsdPrice: PropTypes.number.isRequired,
  toggleUsdDisplay: PropTypes.func.isRequired,
  showAsUsd: PropTypes.bool.isRequired,
  onSetOrder: PropTypes.func.isRequired,
  historicalToUsd: PropTypes.bool.isRequired,
  toggleHistoricalUsd: PropTypes.func.isRequired,
  showChange: PropTypes.bool.isRequired,
  loadingRows: PropTypes.number,
  toggleChange: PropTypes.func.isRequired,
  startDate: PropTypes.number.isRequired,
  endDate: PropTypes.number.isRequired,
};

AddressTransactionsAll.defaultProps = {
  loadingRows: 5,
};

const makeMapStateToProps = () => {
  const getAddressTransactions = makeGetAddressTransactions();
  return (state, { address }) => getAddressTransactions(state, address);
};

const mapDispatchToProps = dispatch => ({
  __fetchByDate: (address, order, startDate = null, endDate = null, initial = false) =>
    dispatch(fetchAddressTransactions(address, order, startDate, endDate, initial)),

  // This is to be used by the 'load more' button to load trx by satoshi
  __fetchBySatoshi: (address, startDate = null, endDate = null) =>
    dispatch(fetchAddressTransactionsBySatoshi(address, startDate, endDate, false)),

  // This is to set largestfirst in the redux store and fetch trx by satoshi
  onSetLargestFirst: address => {
    dispatch(addressTransactionsSetLargestFirst(address));
  },
  onSwapOrder: address => dispatch(addressTransactionsSwapOrder(address)),
  onSetDate: (address, startDate, endDate) =>
    dispatch(addressTransactionsSetDateRange(address, startDate, endDate)),
  setScrollTop: (address, scrollTop) => dispatch(setScrollTop(address, scrollTop)),

  onSetOrder: (address, order) => dispatch(addressTransactionSetOrder(address, order)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { order } = stateProps;
  const { __fetchByDate, __fetchBySatoshi } = dispatchProps;

  const fetch = order === 2 ? __fetchBySatoshi : __fetchByDate;
  return Object.assign(
    {
      fetch,
    },
    ownProps,
    stateProps,
    dispatchProps
  );
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AddressTransactionsAll);
