import { CustomCheckbox } from "../../Utils/CustomCheckbox";
import React, { useState } from "react";
import moment from "moment";
import axios, { WATCHLIST_API } from "../../../api";
import { Button, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const ACTIVE_CUTOFF = 30; //transactions
const ACTIVE_TIME = 24 * 7; //hours
const MempoolCheckbox = ({
  mempoolValue,
  setValue,
  entityType,
  entity_id,
  currency,
  style = {
    marginLeft: "25px",
    marginRight: "5px",
    display: "inline-block",
    height: "20px",
    width: "20px",
    border: "2px solid var(--base-color)",
    verticalAlign: "bottom",
    textAlign: "center",
    fontFamily: "Quicksand",
    cursor: "pointer"
  },
  watchlistItemExists = false
}) => {
  let [activeEntity, setActiveEntity] = useState(false);
  let [loading, setLoading] = useState(false);

  const onClick = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const newValue = !mempoolValue;
    if (newValue) {
      const activity = await checkActivity();
      if (activity) {
        setLoading(false);
        setActiveEntity(true);
        return;
      }
    }
    await setMempoolTracking(newValue);
    setValue(newValue);
    setLoading(false);
  };

  const setMempoolTracking = async value => {
    setActiveEntity(false);

    // if wathclist item doesn't exist just set the values and ignore api call since doesn't exist
    if (!watchlistItemExists) {
      setValue(value);
      setLoading(false);
      return true;
    }
    try {
      setLoading(true);
      const response = await axios.post(
        `${WATCHLIST_API(currency)}/mempool/${entityType}/${entity_id}`,
        {
          tracking_value: value
        }
      );
      setValue(value);
      setLoading(false);
      return true;
    } catch (e) {
      return false;
    }
  };

  const checkActivity = async () => {
    try {
      const response = await axios.get(
        `${WATCHLIST_API(currency)}/mempool/${entityType}/${entity_id}`
      );
      let { count, time } = response.data;
      const currentTime = moment().valueOf() / 1000;
      const secondsToSubtract = ACTIVE_TIME * 60 * 60;
      if (count >= ACTIVE_CUTOFF && time >= currentTime - secondsToSubtract) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };
  return (
    <>
      <CustomCheckbox
        loading={loading}
        value={mempoolValue}
        onClick={() => onClick()}
        style={style}
      />
      <span style={{ fontFamily: "Quicksand", fontWeight: "700", color: "#444" }}>
        Real-Time Tracking
      </span>
      <Modal show={activeEntity}>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
              <p
                style={{
                  fontFamily: "Quicksand",
                  color: "#333",
                  fontSize: "18px",
                  fontWeight: "700",
                  textAlign: "center"
                }}
              >
                This item has been very active recently are you sure you want to receive
                notifications for all new transactions?
              </p>
            </Row>
            <Row>
              <Button
                className="greenButton"
                style={{ marginRight: "10px" }}
                onClick={() => setMempoolTracking(true)}
              >
                {" "}
                <FontAwesomeIcon icon={faCheck} style={{ marginRight: "7px" }} />
                Yes
              </Button>
              <Button className="issueButton" onClick={() => setActiveEntity(false)}>
                <FontAwesomeIcon icon={faTimes} style={{ marginRight: "7px" }} />
                No
              </Button>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MempoolCheckbox;
