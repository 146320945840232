/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";
import { Button } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons/index";
import { satoshiToBitcoin } from "../../../helpers";
import { FakeLinkSpan } from "../../styled";
import { cytoscapeNodeShape, cytoscapeShape, reactRouterMatchShape } from "../../../prop-types";
import { getWalletSetMemo } from "../../../selectors/graph";
import { addWalletToGraphFromWalletView, openWalletModal } from "../../../actions/graph";
import { getWalletAddress, getWalletName } from "../../../selectors/wallet";

const SentWalletsRow = ({
  walletId,
  satoshi,
  tag,
  wallets,
  handleOpenWalletModal,
  handleAddWalletToGraph,
  sourceWallet,
  match: {
    params: { graphId }
  },
  cy,
  category
}) => {
  const bitcoin = satoshiToBitcoin(satoshi);
  const { name } = useSelector(state => getWalletName(state, walletId));
  const { address } = useSelector(state => getWalletAddress(state, walletId));

  return (
    <tr>
      <td style={{ width: "40%" }}>
        <FakeLinkSpan onClick={() => handleOpenWalletModal(walletId)}>
          {name || walletId}
        </FakeLinkSpan>
      </td>
      <td style={{ width: "60%" }} className="text-left">
        {bitcoin}
        {!wallets.has(walletId.toString()) ? (
          <Button
            style={{ float: "right" }}
            className="purpleButton"
            bsSize="xsmall"
            onClick={() =>
              handleAddWalletToGraph(
                walletId,
                satoshi,
                address,
                sourceWallet,
                name,
                category,
                graphId,
                cy
              )
            }
          >
            Add to Graph
          </Button>
        ) : (
          <Button
            style={{ float: "right" }}
            className="greenButtonGraph"
            bsSize="xsmall"
            onClick={() =>
              handleAddWalletToGraph(
                walletId,
                satoshi,
                address,
                sourceWallet,
                name,
                category,
                graphId,
                cy
              )
            }
          >
            <FontAwesomeIcon icon={faCheck} /> Added
          </Button>
        )}
      </td>
    </tr>
  );
};

SentWalletsRow.propTypes = {
  walletId: PropTypes.string.isRequired,
  satoshi: PropTypes.number.isRequired,
  tag: PropTypes.string,
  sourceWallet: cytoscapeNodeShape.isRequired,
  handleOpenWalletModal: PropTypes.func.isRequired,
  wallets: PropTypes.instanceOf(Set).isRequired,
  handleAddWalletToGraph: PropTypes.func.isRequired,
  match: reactRouterMatchShape({
    graphId: PropTypes.number.isRequired
  }).isRequired,
  cy: cytoscapeShape.isRequired,
  category: PropTypes.string
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { graphId }
    }
  }
) => {
  return {
    wallets: getWalletSetMemo(state, graphId)
  };
};

const mapDispatchToProps = dispatch => ({
  handleAddWalletToGraph: (
    walletId,
    satoshi,
    address,
    sourceWallet,
    tag,
    category,
    graphId,
    cy
  ) => {
    dispatch(
      addWalletToGraphFromWalletView(
        walletId,
        satoshi,
        address,
        sourceWallet,
        tag,
        category,
        "sent",
        graphId,
        cy
      )
    );
  },
  handleOpenWalletModal: wallet => dispatch(openWalletModal(wallet))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SentWalletsRow)
);

SentWalletsRow.defaultProps = {
  tag: null,
  category: null
};
