/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";

import InputWalletTable from "./InputWalletTable";
import OutputWalletTable from "./OutputWalletTable";
import { transactionWallet } from "../../../prop-types";

const WalletTables = ({
  inputBitcoin,
  outputBitcoin,
  changeWalletId,
  inputs,
  outputs,
  handleOpenWalletModal,
  getWalletLink,
  currency,
  convertPriceToUsd,
  toggleUsdDisplay,
  showAsUsd,
}) => (
  <Row>
    <Col lg={6}>
      <InputWalletTable
        totalBitcoin={inputBitcoin}
        outputs={inputs}
        handleOpenWalletModal={handleOpenWalletModal}
        getWalletLink={getWalletLink}
        currency={currency}
        convertPriceToUsd={convertPriceToUsd}
        toggleUsdDisplay={toggleUsdDisplay}
        showAsUsd={showAsUsd}
      />
    </Col>
    <Col lg={6}>
      <OutputWalletTable
        totalBitcoin={outputBitcoin}
        changeWalletId={changeWalletId}
        outputs={outputs}
        handleOpenWalletModal={handleOpenWalletModal}
        getWalletLink={getWalletLink}
        currency={currency}
        convertPriceToUsd={convertPriceToUsd}
        toggleUsdDisplay={toggleUsdDisplay}
        showAsUsd={showAsUsd}
      />
    </Col>
  </Row>
);

WalletTables.propTypes = {
  inputBitcoin: PropTypes.string.isRequired,
  outputBitcoin: PropTypes.string.isRequired,
  changeWalletId: PropTypes.number,
  inputs: PropTypes.arrayOf(transactionWallet).isRequired,
  outputs: PropTypes.arrayOf(transactionWallet).isRequired,
  handleOpenWalletModal: PropTypes.func,
  getWalletLink: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
};

WalletTables.defaultProps = {
  handleOpenWalletModal: null,
  changeWalletId: null,
};

export default WalletTables;
