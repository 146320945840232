/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

// The following is borrowed from:
// https://github.com/facebook/draft-js/tree/master/examples/draft-0-10-0/rich
import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import {
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  RichUtils
} from "draft-js";
import { debounce } from "lodash";
import "draft-js/dist/Draft.css";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCurrency } from "../../selectors/currency";

import BlockStyleControls from "./BlockStyleControls";
import InlineStyleControls from "./InlineStyleControls";
import "../../../css/RichEditor.css";
import axios, {
  GRAPH_API,
  NOTES_API,
  ADDRESS_API,
  TRANSACTION_API
} from "../../api";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { Button, Col, Row, Table, Tab, Tabs } from "react-bootstrap";
import NoteEditor from "./index";

class GraphTransactionNoteEditor extends Component {
  state = {
    graphTransactionData: { location: null, locationId: null },
    graphTransactionMentionData: { location: null, locationId: null },
    allGraphTransactionData: { graph: {} },
    graphTransactionNoteEditorState: null,
    graphTransactionMentionsEditorState: null,
    graphTransactionLoading: false,
    graphTransactionKey: "graphTransactionNote",
    graphTransactionDescription: ""
  };

  componentDidMount() {
    this.fetchGraphTransactionNote(this.props.sourceId, this.props.dstId);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.sourceId !== prevProps.sourceId &&
      this.props.dstId !== prevProps.dstId
    ) {
      this.setState({ graphTransactionLoading: false });
      this.fetchGraphTransactionNote(this.props.sourceId, this.props.dstId);
    }
  }

  getNoteId = async () => {
    let noteId = null;
    if (!this.props.mainLocationId) {
      if (this.props.mainLocationName === "address") {
        const graphTransactionData = await axios.get(
          `${ADDRESS_API(this.props.currency)}/${
            this.props.mainLocationName
          }/exists`
        );
        noteId = await graphTransactionData.data.graphTransactionId;
      } else if (this.props.mainLocationName === "transaction") {
        const transactionData = await axios.get(
          `${TRANSACTION_API(this.props.currency)}/${
            this.props.mainLocationName
          }/exists`
        );
        noteId = await transactionData.data.transactionId;
      }
    } else {
      noteId = await parseInt(this.props.mainLocationId, 10);
    }
    return noteId;
  };

  fetchGraphTransactionNote = async (sourceId, dstId) => {
    try {
      const nodeData = JSON.stringify(this.props.nodeData);
      const { data } = await axios.get(
        `${NOTES_API(
          this.props.currency
        )}/graphTransaction/${sourceId.toString()}/${dstId.toString()}`,
        { params: { nodeData: nodeData } }
      );
      const noteId = await this.getNoteId();
      if (!data[this.props.mainLocation][noteId]) {
        const jsonNodeData = JSON.parse(nodeData);
        await axios.post(
          `${NOTES_API(
            this.props.currency
          )}/graphTransaction/${sourceId.toString()}/${dstId.toString()}`,
          {
            noteId: noteId,
            mainLocation: this.props.mainLocation,
            sourceType: jsonNodeData[sourceId]["type"],
            dstType: jsonNodeData[dstId]["type"]
          }
        );
      }
      this.setState({ allGraphTransactionData: data }, () => {
        if (
          this.state.allGraphTransactionData[this.props.mainLocation][noteId]
        ) {
          if (
            this.state.allGraphTransactionData[this.props.mainLocation][noteId]
              .editorContent
          ) {
            this.setState({
              graphTransactionNoteEditorState: EditorState.createWithContent(
                convertFromRaw(
                  this.state.allGraphTransactionData[this.props.mainLocation][
                    noteId
                  ].editorContent
                )
              )
            });
          } else {
            this.setState({
              graphTransactionNoteEditorState: EditorState.createEmpty()
            });
          }
        } else {
          this.setState({
            graphTransactionNoteEditorState: EditorState.createEmpty()
          });
        }
        this.setState(
          {
            graphTransactionData: {
              ...this.state.graphTransactionData,
              location: this.props.mainLocation
            }
          },
          () => {
            this.setState({
              graphTransactionData: {
                ...this.state.graphTransactionData,
                locationId: noteId
              }
            });
          }
        );
        this.setState({ graphTransactionLoading: true });
      });
    } catch (err) {
      this.props.history.replace("/case-management");
      throw err;
    }
  };

  handleSave = async (message = false) => {
    const sourceId = this.props.sourceId;
    const dstId = this.props.dstId;
    const graphTransactionLocation = this.state.graphTransactionData.location;
    const graphTransactionLocationId = this.state.graphTransactionData
      .locationId;
    const nodeData = this.props.nodeData;
    const graphTransactionNote = JSON.stringify(
      convertToRaw(
        this.state.graphTransactionNoteEditorState.getCurrentContent()
      )
    );
    const sourceType = nodeData[sourceId]["type"];
    const dstType = nodeData[dstId]["type"];

    if ("changeCreateEdgeNoteButton" in this.props) {
      if (
        !(
          convertToRaw(
            this.state.graphTransactionNoteEditorState.getCurrentContent()
          )["blocks"].length === 1 &&
          convertToRaw(
            this.state.graphTransactionNoteEditorState.getCurrentContent()
          )["blocks"][0]["text"] === ""
        )
      ) {
        this.props.changeCreateEdgeNoteButton(sourceId, dstId, true);
      } else {
        this.props.changeCreateEdgeNoteButton(sourceId, dstId, false);
      }
    }
    try {
      await axios.put(
        `${NOTES_API(
          this.props.currency
        )}/graphTransaction/${sourceId.toString()}/${dstId.toString()}`,
        {
          graphTransactionLocation,
          graphTransactionLocationId,
          graphTransactionNote,
          sourceType,
          dstType
        }
      );
    } catch (err) {
      window.scrollTo(0, 0);
      throw err;
    }
  };

  handleSaveMentions = async (message = false) => {
    const sourceId = this.props.sourceId;
    const dstId = this.props.dstId;
    const graphTransactionLocation = this.state.graphTransactionMentionData
      .location;
    const graphTransactionLocationId = this.state.graphTransactionMentionData
      .locationId;
    const graphTransactionNote = JSON.stringify(
      convertToRaw(
        this.state.graphTransactionMentionsEditorState.getCurrentContent()
      )
    );

    try {
      const nodeData = JSON.stringify(this.props.nodeData);
      const sourceType = nodeData[sourceId]["type"];
      const dstType = nodeData[dstId]["type"];
      const updatedEditor = {
        editorContent: convertToRaw(
          this.state.graphTransactionMentionsEditorState.getCurrentContent()
        ),
        locationName: this.state.allGraphTransactionData[
          graphTransactionLocation
        ][graphTransactionLocationId].locationName
      };
      let copyState = { ...this.state.allGraphTransactionData };
      copyState[graphTransactionLocation][
        graphTransactionLocationId
      ] = updatedEditor;
      this.setState({ allGraphTransactionData: copyState });
      await axios.put(
        `${NOTES_API(
          this.props.currency
        )}/graphTransaction/${sourceId.toString()}/${dstId.toString()}`,
        {
          graphTransactionLocation,
          graphTransactionLocationId,
          graphTransactionNote,
          sourceType,
          dstType
        }
      );
    } catch (err) {
      window.scrollTo(0, 0);
      throw err;
    }
  };

  handleGraphTransactionSelect = graphTransactionKey =>
    this.setState({ graphTransactionKey });
  onGraphTransactionEdit = graphTransactionNoteEditorState =>
    this.setState({ graphTransactionNoteEditorState });
  onGraphTransactionMentionEdit = graphTransactionMentionsEditorState =>
    this.setState({ graphTransactionMentionsEditorState });

  updateGraphTransactionMentionEditorState = (locationId, location) => {
    this.setState({
      graphTransactionDescription:
        location.charAt(0).toUpperCase() +
        location.slice(1).toLowerCase() +
        " - " +
        this.state.allGraphTransactionData[location][locationId].locationName
    });
    if (
      this.state.allGraphTransactionData[location][locationId].editorContent
    ) {
      this.setState({
        graphTransactionMentionsEditorState: EditorState.createWithContent(
          convertFromRaw(
            this.state.allGraphTransactionData[location][locationId]
              .editorContent
          )
        )
      });
    } else {
      this.setState({
        graphTransactionMentionsEditorState: EditorState.createEmpty()
      });
    }
    this.setState(
      {
        graphTransactionMentionData: {
          ...this.state.graphTransactionMentionData,
          location: location
        }
      },
      () => {
        this.setState({
          graphTransactionMentionData: {
            ...this.state.graphTransactionMentionData,
            locationId: locationId
          }
        });
      }
    );
  };

  render() {
    const graphTransactionMentionsGraphs = Object.keys(
      this.state.allGraphTransactionData.graph
    ).map(locationId => {
      const locationName = this.state.allGraphTransactionData.graph[locationId]
        .locationName;
      if (
        (this.props.mainLocationId !== parseInt(locationId) &&
          this.props.mainLocation === "graph") ||
        this.props.mainLocation !== "graph"
      ) {
        return (
          <tr key={locationId}>
            <td>
              <Link
                to={`/${this.props.currency}/graph/${locationId}`}
                target="_blank"
                style={{ color: "var(--link-colors)" }}
              >
                {locationName}
              </Link>{" "}
              <Button
                className="pull-right greenButtonGraph"
                bsSize="xsmall"
                onClick={() =>
                  this.updateGraphTransactionMentionEditorState(
                    locationId,
                    "graph"
                  )
                }
              >
                See Notes
              </Button>
            </td>
          </tr>
        );
      }
    });

    return (
      <>
        {this.state.graphTransactionLoading && (
          <Tabs
            className="inviteTabs"
            activeKey={this.state.graphTransactionKey}
            id="graphTransaction-tab"
            onSelect={this.handleGraphTransactionSelect}
            animation={false}
            mountOnEnter
          >
            <Tab eventKey="graphTransactionNote" title="Graph Transaction Note">
              <>
                <Button
                  style={{
                    position: "relative",
                    float: "right",
                    top: "10px",
                    right: "10px"
                  }}
                  className="greenButton"
                  onClick={() => this.handleSave()}
                >
                  Save
                </Button>{" "}
                <NoteEditor
                  height={700}
                  editorState={this.state.graphTransactionNoteEditorState}
                  onEdit={this.onGraphTransactionEdit}
                  saveGraph={this.handleSave}
                />
              </>
            </Tab>
            <Tab
              eventKey="otherGraphTransactionMentions"
              title="Other Mentions"
            >
              <Row>
                <Col lg={12}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Graph Mentions</th>
                      </tr>
                    </thead>
                    {Object.keys(this.state.allGraphTransactionData.graph)
                      .length > 1 && this.props.mainLocation === "graph" ? (
                      <tbody>{graphTransactionMentionsGraphs}</tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td>None Found</td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </Col>
              </Row>
              {this.state.graphTransactionMentionData.location ? (
                <>
                  <div>
                    <p
                      style={{
                        fontFamily: "Quicksand",
                        fontWeight: "600",
                        color: "#666",
                        marginRight: "10px",
                        marginBottom: "0",
                        fontSize: "30px"
                      }}
                    >
                      {this.state.graphTransactionDescription}
                    </p>
                    <Button
                      style={{
                        position: "relative",
                        float: "right",
                        top: "10px",
                        right: "10px"
                      }}
                      onClick={() => this.handleSaveMentions()}
                      className="greenButton"
                    >
                      Save
                    </Button>{" "}
                  </div>
                  <NoteEditor
                    height={700}
                    editorState={this.state.graphTransactionMentionsEditorState}
                    onEdit={this.onGraphTransactionMentionEdit}
                    saveGraph={this.handleSaveMentions}
                  />
                </>
              ) : (
                <></>
              )}
            </Tab>
          </Tabs>
        )}
      </>
    );
  }
}

GraphTransactionNoteEditor.defaultProps = {
  saveGraph: null
};

const mapStateToProps = state => {
  return {
    currency: getCurrency(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(GraphTransactionNoteEditor)
);
