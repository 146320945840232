/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import PropTypes from "prop-types";

import { FakeLinkSpan } from "./styled";

// entity should already be in displayable for,
// handleOpenLink should take no arguments
const ModalLink = ({ entity, handleOpenLink }) => (
  <FakeLinkSpan onClick={handleOpenLink}>{entity}</FakeLinkSpan>
);

ModalLink.propTypes = {
  entity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleOpenLink: PropTypes.func.isRequired,
};

export default ModalLink;
