// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

// eslint-disable-next-line import/prefer-default-export
import { getCurrency } from "./currency";
import CopyText from "../components/CopyText";
import { Link } from "react-router-dom";
import React from "react";
import { getSearchDisplayName } from "../components/SearchResults/SearchResults";
import { getWalletName } from "./wallet";

export const getSearchResult = (state, query) => {
  const name = getCurrency(state);
  let results = state.getIn([name, "search", query]);
  if (results) {
    results = results.toJS();
    results.results.wallets &&
      results.results.wallets.forEach(({ walletId }, idx) => {
        const { name, custom } = getWalletName(state, walletId);
        results.results.wallets[idx]["name"] = name;
        results.results.wallets[idx]["custom"] = !!custom;
      });
    results.results.custom_wallets &&
      results.results.custom_wallets.forEach(
        ({ walletId }, idx) =>
          (results.results.custom_wallets[idx]["name"] = getWalletName(state, walletId)["name"])
      );
    return results;
  }
  return null;
};