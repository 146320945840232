/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";
import { EditorState } from "draft-js";
import "draft-js/dist/Draft.css";

import StyleButton from "./StyleButton";

const INLINE_STYLES = [
  {
    label: "Bold",
    style: "BOLD",
  },
  {
    label: "Italic",
    style: "ITALIC",
  },
  {
    label: "Underline",
    style: "UNDERLINE",
  },
  {
    label: "Monospace",
    style: "CODE",
  },
];

const InlineStyleControls = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

export default InlineStyleControls;

InlineStyleControls.propTypes = {
  editorState: PropTypes.instanceOf(EditorState).isRequired,
  onToggle: PropTypes.func.isRequired,
};
