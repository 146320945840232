/*
 * Copyright 2018-2021  DecisionQ Information Operations, Inc. All Rights Reserved.
 */

import React from "react";
import { CircularProgress, Divider } from "@material-ui/core";
import CopyText from "../../CopyText";
import { getWalletName, getWalletNames, getWisteriaData } from "../../../selectors/wallet";
import { useDispatch, useSelector } from "react-redux";
import { FullWidthPopover } from "../../styled";
import { Button, DropdownButton, MenuItem, OverlayTrigger } from "react-bootstrap";
import { setMainName } from "../../../actions/wallet";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-bootstrap";

export const formattedTagTypes = {
  wisteria_attribution: "Wisteria",
  customTag: "Custom Tag",
  tag: "Tag"
};

/**
 * Displays the wallet name with the name display from getWalletName.
 *  * Shows the processing loading symbol if a custom wallet loading.
 *  * Shows alternative names if wallet has many names.
 *  * Shows wisteria attribution tooltip
 * @param wallet_id
 * @param wallet_name set if you want to decide the name to display yourself instead of redux store
 * @param loading whether the custom wallet is processing
 * @param fontsize
 * @param copyFontSize fontsize of the copy button
 * @returns {JSX.Element}
 * @constructor
 */
const WalletName = ({
  wallet_id,
  wallet_name,
  loading = false,
  fontsize = "30px",
  copyFontSize = "18px",
  custom = false
}) => {
  const { name, wisteria, sourceName, sourceConfidence } = useSelector(state =>
    getWalletName(state, wallet_id)
  ); // getting main name of the wallet
  const names = useSelector(state => getWalletNames(state, wallet_id)); // getting all the wallet names
  const { wisteria_attribution_confidence_score } = useSelector(state =>
    getWisteriaData(state, wallet_id)
  );
  const dispatch = useDispatch();
  const get_wisteria_tooltip = (
    confidence_score // wisteria tooltip component
  ) => (
    <FullWidthPopover style={{ fontFamily: "Quicksand", fontWeight: "600", fontSize: "12px" }}>
      This category was predicted with {(confidence_score * 100).toFixed(0)}% certainty.
    </FullWidthPopover>
  );

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

  /**
   * Helper component to hold the name and the dropdown for the other tags
   * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
   */
  const Name = React.forwardRef(function Name(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
      <div {...props} style={{ display: "flex", alignItems: "center" }} ref={ref}>
        <CopyText
          text={wallet_name || name}
          fontSize={copyFontSize}
          marginLeft={5}
          marginRight={5}
          ref={ref}
        />

        <ConditionalWrapper
          condition={wisteria}
          wrapper={children => (
            <OverlayTrigger
              overlay={get_wisteria_tooltip(wisteria_attribution_confidence_score)}
              placement="top"
              delayShow={300}
              delayHide={150}
            >
              {children}
            </OverlayTrigger>
          )}
        >
          <div>
            <p
              style={{
                marginRight: 5,
                fontSize: fontsize
              }}
              ref={ref}
            >
              {wallet_name || name}
            </p>
          </div>
        </ConditionalWrapper>

        {/*If names has more than 1 element them make a dropdown with all the other names*/}
        {!custom && names.length > 1 && (
          <DropdownButton
            style={{
              height: 7,
              width: 7,
              marginRight: 15
            }}
            title={""}
            className={"customDropdown"}
            id="name-dropdown"
          >
            <MenuItem
              header
              style={{
                color: "black",
                fontSize: "12px",
                fontWeight: "700",
                fontFamily: "Quicksand",
                cursor: "default"
              }}
            >
              Also known as:
            </MenuItem>
            <Divider />
            {names.slice(1).map((elem, idx) => (
              <MenuItem
                eventKey={idx}
                style={{
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "700",
                  fontFamily: "Quicksand"
                }}
                header={elem.tagType === "ID"}
                {...(elem.tagType !== "ID" && {
                  onClick: () =>
                    dispatch(setMainName(elem.name, elem.tagType, elem.id || 0, wallet_id))
                })}
              >
                {elem.tagType ? (
                  elem.sourceName ? (
                    <>
                      <OverlayTrigger
                        placement="bottom"
                        style={{ position: "fixed" }}
                        overlay={
                          <Tooltip
                            id="button-tooltip-2"
                            style={{ position: "fixed", zIndex: 30000000 }}
                          >
                            <>
                              <div>Source: {elem.sourceName}</div>{" "}
                              {elem.sourceConfidence && (
                                <div>Source Confidence: {elem.sourceConfidence}</div>
                              )}
                            </>
                          </Tooltip>
                        }
                      >
                        <div style={{ cursor: "pointer" }}>
                          {formattedTagTypes[elem.tagType] || elem.tagType}: {elem.name}
                        </div>
                      </OverlayTrigger>
                    </>
                  ) : (
                    <div style={{ cursor: "default" }}>
                      {formattedTagTypes[elem.tagType] || elem.tagType}: {elem.name}
                    </div>
                  )
                ) : (
                  <div style={{ cursor: "default" }}>{elem.name}</div>
                )}
              </MenuItem>
            ))}
          </DropdownButton>
        )}
        {sourceName && !custom ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip-2" style={{ zIndex: 30000000 }}>
                <>
                  <div>Source: {sourceName}</div>{" "}
                  {sourceConfidence && <div>Source Confidence: {sourceConfidence}</div>}
                </>
              </Tooltip>
            }
          >
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ display: "inline", fontSize: "20px", cursor: "pointer" }}
            />
          </OverlayTrigger>
        ) : (
          <></>
        )}
      </div>
    );
  });

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center"
      }}
    >
      <div className="col txnSummaryTxnId" style={{ display: "flex", alignItems: "center" }}>
        <Name />
      </div>
      {loading && (
        <OverlayTrigger
          overlay={
            <FullWidthPopover
              style={{
                fontFamily: "Quicksand",
                fontWeight: "600",
                fontSize: "12px"
              }}
            >
              Processing
            </FullWidthPopover>
          }
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          <CircularProgress
            color="primary"
            size={fontsize}
            thickness={5}
            style={{
              marginLeft: "10px"
            }}
          />
        </OverlayTrigger>
      )}
    </div>
  );
};

export default WalletName;
