/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

// The following is borrowed from:
// https://github.com/facebook/draft-js/tree/master/examples/draft-0-10-0/rich
import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import {
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  RichUtils
} from "draft-js";
import { debounce } from "lodash";
import "draft-js/dist/Draft.css";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCurrency } from "../../selectors/currency";

import BlockStyleControls from "./BlockStyleControls";
import InlineStyleControls from "./InlineStyleControls";
import "../../../css/RichEditor.css";
import axios, { GRAPH_API, NOTES_API, TRANSACTION_API } from "../../api";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { Button, Col, Row, Table, Tab, Tabs } from "react-bootstrap";
import NoteEditor from "./index";

class TransactionNoteEditor extends Component {
  state = {
    transactionData: { location: null, locationId: null },
    transactionMentionData: { location: null, locationId: null },
    allTransactionData: { note: {}, transaction: {}, graph: {} },
    transactionNoteEditorState: null,
    transactionMentionsEditorState: null,
    transactionLoading: false,
    transactionKey: "transactionNote",
    transactionDescription: ""
  };

  componentDidMount() {
    this.fetchTransactionNote(this.props.transaction);
  }

  componentDidUpdate(prevProps) {
    if (this.props.transaction !== prevProps.transaction) {
      this.setState({ transactionLoading: false });
      this.fetchTransactionNote(this.props.transaction);
    }
  }

  getNoteId = async () => {
    let noteId = null;
    if (!this.props.mainLocationId) {
      const transactionData = await axios.get(
        `${TRANSACTION_API(this.props.currency)}/${
          this.props.mainLocationName
        }/exists`
      );
      noteId = await transactionData.data.transactionId;
    } else {
      noteId = await parseInt(this.props.mainLocationId, 10);
    }
    return noteId;
  };

  fetchTransactionNote = async transaction => {
    try {
      const { data } = await axios.get(
        `${NOTES_API(this.props.currency)}/transaction/${transaction}`
      );
      const noteId = await this.getNoteId();
      if (!data[this.props.mainLocation][noteId]) {
        await axios.post(
          `${NOTES_API(this.props.currency)}/transaction/${transaction}`,
          {
            noteId: noteId,
            mainLocation: this.props.mainLocation
          }
        );
      }
      this.setState({ allTransactionData: data }, () => {
        if (this.state.allTransactionData[this.props.mainLocation][noteId]) {
          if (
            this.state.allTransactionData[this.props.mainLocation][noteId]
              .editorContent
          ) {
            this.setState({
              transactionNoteEditorState: EditorState.createWithContent(
                convertFromRaw(
                  this.state.allTransactionData[this.props.mainLocation][noteId]
                    .editorContent
                )
              )
            });
          } else {
            this.setState({
              transactionNoteEditorState: EditorState.createEmpty()
            });
          }
        } else {
          this.setState({
            transactionNoteEditorState: EditorState.createEmpty()
          });
        }
        this.setState(
          {
            transactionData: {
              ...this.state.transactionData,
              location: this.props.mainLocation
            }
          },
          () => {
            this.setState({
              transactionData: {
                ...this.state.transactionData,
                locationId: noteId
              }
            });
          }
        );
        this.setState({ transactionLoading: true });
      });
    } catch (err) {
      if (err.response.status !== 404) {
        this.props.history.replace("/case-management");
        throw err;
      }
    }
  };

  handleSave = async (message = false) => {
    const transaction = this.props.transaction;
    const transactionLocation = this.state.transactionData.location;
    const transactionLocationId = this.state.transactionData.locationId;
    const transactionNote = JSON.stringify(
      convertToRaw(this.state.transactionNoteEditorState.getCurrentContent())
    );
    if ("changeCreateTransactionNoteButton" in this.props) {
      if (
        !(
          convertToRaw(
            this.state.transactionNoteEditorState.getCurrentContent()
          )["blocks"].length === 1 &&
          convertToRaw(
            this.state.transactionNoteEditorState.getCurrentContent()
          )["blocks"][0]["text"] === ""
        )
      ) {
        this.props.changeCreateTransactionNoteButton(transaction, true);
      } else {
        this.props.changeCreateTransactionNoteButton(transaction, false);
      }
    }

    try {
      await axios.put(
        `${NOTES_API(this.props.currency)}/transaction/${transaction}`,
        {
          transactionLocation,
          transactionLocationId,
          transactionNote
        }
      );
    } catch (err) {
      window.scrollTo(0, 0);
      throw err;
    }
  };

  handleSaveMentions = async (message = false) => {
    const transaction = this.props.transaction;
    const transactionLocation = this.state.transactionMentionData.location;
    const transactionLocationId = this.state.transactionMentionData.locationId;
    const transactionNote = JSON.stringify(
      convertToRaw(
        this.state.transactionMentionsEditorState.getCurrentContent()
      )
    );

    try {
      const updatedEditor = {
        editorContent: convertToRaw(
          this.state.transactionMentionsEditorState.getCurrentContent()
        ),
        locationName: this.state.allTransactionData[transactionLocation][
          transactionLocationId
        ].locationName
      };
      let copyState = { ...this.state.allTransactionData };
      copyState[transactionLocation][transactionLocationId] = updatedEditor;
      this.setState({ allTransactionData: copyState });
      await axios.put(
        `${NOTES_API(this.props.currency)}/transaction/${transaction}`,
        {
          transactionLocation,
          transactionLocationId,
          transactionNote
        }
      );
    } catch (err) {
      window.scrollTo(0, 0);
      throw err;
    }
  };

  handleTransactionSelect = transactionKey => this.setState({ transactionKey });
  onTransactionEdit = transactionNoteEditorState =>
    this.setState({ transactionNoteEditorState });
  onTransactionMentionEdit = transactionMentionsEditorState =>
    this.setState({ transactionMentionsEditorState });

  updateTransactionMentionEditorState = (locationId, location) => {
    this.setState({
      transactionDescription:
        location.charAt(0).toUpperCase() +
        location.slice(1).toLowerCase() +
        " - " +
        this.state.allTransactionData[location][locationId].locationName
    });
    if (this.state.allTransactionData[location][locationId].editorContent) {
      this.setState({
        transactionMentionsEditorState: EditorState.createWithContent(
          convertFromRaw(
            this.state.allTransactionData[location][locationId].editorContent
          )
        )
      });
    } else {
      this.setState({
        transactionMentionsEditorState: EditorState.createEmpty()
      });
    }
    this.setState(
      {
        transactionMentionData: {
          ...this.state.transactionMentionData,
          location: location
        }
      },
      () => {
        this.setState({
          transactionMentionData: {
            ...this.state.transactionMentionData,
            locationId: locationId
          }
        });
      }
    );
  };

  render() {
    const transactionMentionsNotes = Object.keys(
      this.state.allTransactionData.note
    ).map(locationId => {
      const locationName = this.state.allTransactionData.note[locationId]
        .locationName;
      //         const noteId = this.props.mainLocationId;
      //         if (locationId !== noteId) {
      if (
        (this.props.mainLocation === "note" &&
          this.props.mainLocationId !== locationId) ||
        this.props.mainLocation !== "note"
      ) {
        return (
          <tr key={locationName}>
            <td>
              <Link
                to={`/${this.props.currency}/case-management/${locationId}`}
                target="_blank"
                style={{ color: "var(--link-colors)" }}
              >
                {locationName}
              </Link>{" "}
              <Button
                className="pull-right greenButtonGraph"
                bsSize="xsmall"
                onClick={() =>
                  this.updateTransactionMentionEditorState(locationId, "note")
                }
              >
                See Notes
              </Button>
            </td>
          </tr>
        );
      } else if (
        this.props.mainLocation === "note" &&
        this.props.mainLocationId === locationId &&
        Object.keys(this.state.allTransactionData.note).length === 1
      ) {
        return (
          <tr>
            <td>None Found</td>
          </tr>
        );
      }
      //         } else {
      //           if (Object.keys(this.state.allTransactionData.note).length <= 1) {
      //             return(
      //           <tr>
      //             <td>
      //               None Found
      //             </td>
      //           </tr>
      //           );
      //           }
      //         }
    });

    const transactionMentionsTransaction = Object.keys(
      this.state.allTransactionData.transaction
    ).map(locationId => {
      const locationName = this.state.allTransactionData.transaction[locationId]
        .locationName;
      const shorterTransactionName = locationName.substring(0, 55) + "...";
      return (
        <tr key={locationName}>
          <td>
            <Link
              to={`/${this.props.currency}/transaction/${locationName}`}
              target="_blank"
              style={{ color: "var(--link-colors)" }}
            >
              {shorterTransactionName}
            </Link>{" "}
            <Button
              className="pull-right greenButtonGraph"
              bsSize="xsmall"
              onClick={() =>
                this.updateTransactionMentionEditorState(
                  locationId,
                  "transaction"
                )
              }
            >
              See Notes
            </Button>
          </td>
        </tr>
      );
    });

    const transactionMentionsGraph = Object.keys(
      this.state.allTransactionData.graph
    ).map(locationId => {
      const locationName = this.state.allTransactionData.graph[locationId]
        .locationName;

      return (
        <tr key={locationId}>
          <td>
            <Link
              to={`/${this.props.currency}/graph/${locationId}`}
              target="_blank"
              style={{ color: "var(--link-colors)" }}
            >
              {locationName || locationId}
            </Link>{" "}
            <Button
              className="pull-right greenButtonGraph"
              bsSize="xsmall"
              onClick={() =>
                this.updateTransactionMentionEditorState(locationId, "graph")
              }
            >
              See Notes
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <>
        {this.state.transactionLoading && (
          <Tabs
            className="inviteTabs"
            activeKey={this.state.transactionKey}
            id="transaction-tab"
            onSelect={this.handleTransactionSelect}
            animation={false}
            mountOnEnter
          >
            <Tab eventKey="transactionNote" title="Transaction Note">
              <>
                <Button
                  style={{
                    position: "relative",
                    float: "right",
                    top: "10px",
                    right: "10px"
                  }}
                  onClick={() => this.handleSave()}
                  className="greenButton"
                >
                  Save
                </Button>{" "}
                <NoteEditor
                  height={700}
                  editorState={this.state.transactionNoteEditorState}
                  onEdit={this.onTransactionEdit}
                  saveGraph={this.handleSave}
                />
              </>
            </Tab>
            <Tab eventKey="otherTransactionMentions" title="Other Mentions">
              <Row>
                <Col lg={6}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Note Mentions</th>
                      </tr>
                    </thead>
                    {Object.keys(this.state.allTransactionData.note).length !==
                    0 ? (
                      <tbody>{transactionMentionsNotes}</tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td>None Found</td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </Col>
                {this.props.mainLocation !== "transaction" && (
                  <Col lg={6}>
                    <Table>
                      <thead>
                        <tr>
                          <th>Transaction Mentions</th>
                        </tr>
                      </thead>
                      {Object.keys(this.state.allTransactionData.transaction)
                        .length !== 0 ? (
                        <tbody>{transactionMentionsTransaction}</tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td>None Found</td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </Col>
                )}
              </Row>
              <Row>
                <Col lg={6}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Graph Mentions</th>
                      </tr>
                    </thead>
                    {Object.keys(this.state.allTransactionData.graph).length !==
                    0 ? (
                      <tbody>{transactionMentionsGraph}</tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td>None Found</td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </Col>
              </Row>
              {this.state.transactionMentionData.location ? (
                <>
                  <div>
                    <p
                      style={{
                        fontFamily: "Quicksand",
                        fontWeight: "600",
                        color: "#666",
                        marginRight: "10px",
                        marginBottom: "0",
                        fontSize: "30px"
                      }}
                    >
                      {this.state.transactionDescription}
                    </p>
                    <Button
                      style={{
                        position: "relative",
                        float: "right",
                        top: "10px",
                        right: "10px"
                      }}
                      onClick={() => this.handleSaveMentions()}
                      className="greenButton"
                    >
                      Save
                    </Button>{" "}
                  </div>
                  <NoteEditor
                    height={700}
                    editorState={this.state.transactionMentionsEditorState}
                    onEdit={this.onTransactionMentionEdit}
                    saveGraph={this.handleSaveMentions}
                  />
                </>
              ) : (
                <></>
              )}
            </Tab>
          </Tabs>
        )}
      </>
    );
  }
}

TransactionNoteEditor.defaultProps = {
  saveGraph: null
};

const mapStateToProps = state => {
  return {
    currency: getCurrency(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(TransactionNoteEditor)
);
