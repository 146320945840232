// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import axios, { ADDRESS_API, CUSTOM_WALLETS_API, WALLET_API } from "../api";
import {
  getAddressCoinSwap,
  getAddressMentions,
  getAddressMoreInfo,
  getAddressMutualWallets,
  getAddressResearchData,
  getAddressRisk,
  getAddressSummary,
  getAddressTransactions,
  getClusterHistory,
  getWalletMutualTransactions
} from "../selectors/address";
import {
  createAddressFetchCoinSwap,
  createAddressFetchMoreInfo,
  createAddressFetchMutualWalletTransactions,
  createFetchAllBsa,
  createFetchBsa,
  createFetchMentions,
  createFetchMutualWallets,
  createFetchResearchRawData,
  createFetchSummary,
  createFetchTransactions,
  createFetchTransactionsBySatoshi,
  createRiskFetch
} from "./actionCreatorFactories";
import {
  ADDRESS_CLUSTER_HISTORY_FETCH_SUCCESS,
  ADDRESS_COIN_SWAP_FETCH_FAILURE,
  ADDRESS_COIN_SWAP_FETCH_SUCCESS,
  ADDRESS_MENTIONS_FETCH_FAILURE,
  ADDRESS_MENTIONS_FETCH_SUCCESS,
  ADDRESS_MORE_INFO_FETCH_FAILURE,
  ADDRESS_MORE_INFO_FETCH_SUCCESS,
  ADDRESS_MUTUAL_TRANSACTIONS_SWAP_ORDER,
  ADDRESS_MUTUAL_WALLETS_FETCH_FAILURE,
  ADDRESS_MUTUAL_WALLETS_FETCH_SUCCESS,
  ADDRESS_MUTUAL_WALLETS_TRANSACTIONS_SET_DATE_RANGE,
  ADDRESS_MUTUAL_WALLETS_TRANSACTIONS_SET_LARGEST_FIRST,
  ADDRESS_RESEARCH_RAW_DATA_FETCH_FAILURE,
  ADDRESS_RESEARCH_RAW_DATA_FETCH_SUCCESS,
  ADDRESS_SUMMARY_FETCH_FAILURE,
  ADDRESS_SUMMARY_FETCH_SUCCESS,
  ADDRESS_TRANSACTIONS_BY_SATOSHI_FETCH_FAILURE,
  ADDRESS_TRANSACTIONS_BY_SATOSHI_FETCH_SUCCESS,
  ADDRESS_TRANSACTIONS_FETCH_FAILURE,
  ADDRESS_TRANSACTIONS_FETCH_SUCCESS,
  ADDRESS_TRANSACTIONS_SCROLL_TOP,
  ADDRESS_TRANSACTIONS_SET_DATE_RANGE,
  ADDRESS_TRANSACTIONS_SET_ORDER,
  ADDRESS_TRANSACTIONS_SWAP_ORDER,
  ADDRESS_WALLET_MUTUAL_TRANSACTIONS_FETCH_FAILURE,
  ADDRESS_WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS,
  BSA_FETCH_FAILURE,
  BSA_FETCH_SUCCESS,
  BSA_KEY_FETCH,
  FETCHING_ADDRESS_CLUSTER_HISTORY,
  FETCHING_ADDRESS_COIN_SWAP,
  FETCHING_ADDRESS_MENTIONS,
  FETCHING_ADDRESS_MORE_INFO,
  FETCHING_ADDRESS_MUTUAL_WALLETS,
  FETCHING_ADDRESS_RESEARCH_RAW_DATA,
  FETCHING_ADDRESS_SUMMARY,
  FETCHING_ADDRESS_TRANSACTIONS,
  FETCHING_ADDRESS_TRANSACTIONS_BY_SATOSHI,
  FETCHING_ADDRESS_WALLET_MUTUAL_TRANSACTIONS,
  FETCHING_MORE_ADDRESS_SUMMARY,
  FETCHING_MORE_WALLET_SUMMARY,
  MORE_ADDRESS_SUMMARY_FETCH_FAILURE,
  MORE_ADDRESS_SUMMARY_FETCH_SUCCESS,
  MORE_WALLET_SUMMARY_FETCH_FAILURE,
  MORE_WALLET_SUMMARY_FETCH_SUCCESS,
  WALLET_TRANSACTIONS_SET_ORDER
} from "./actionNames";

import { getCurrency } from "../selectors/currency";
import { fetchWalletTransactions, fetchWalletTransactionsBySatoshi } from "./wallet";
import { getWalletSummary } from "../selectors/wallet";

export const fetchAddressSummary = createFetchSummary(ADDRESS_API, getAddressSummary, {
  fetchAction: FETCHING_ADDRESS_SUMMARY,
  successAction: ADDRESS_SUMMARY_FETCH_SUCCESS,
  failureAction: ADDRESS_SUMMARY_FETCH_FAILURE
});

/**
 * Fetch extra address stats for change and historical data and dispatches for fetching, success, and failure
 * @param item address_id to fetch for
 * @returns {(function(*, *): (Promise<void>))|*}
 */
export const fetchMoreAddressSummary = item => (dispatch, getState) => {
  const api = ADDRESS_API;

  const summary = getAddressSummary(getState(), item);
  if (!summary) {
    return;
  }
  // Get the current currency
  const name = getCurrency(getState());

  dispatch({ type: FETCHING_MORE_ADDRESS_SUMMARY });
  const api_call = `${api(name)}/${item}/summary-stats`;
  return axios.get(api_call).then(
    ({ data }) => {
      dispatch({
        type: MORE_ADDRESS_SUMMARY_FETCH_SUCCESS,
        item,
        data,
        name
      });
    },
    error => {
      dispatch({
        type: MORE_ADDRESS_SUMMARY_FETCH_FAILURE,
        item,
        name
      });
    }
  );
};

// change passed in selector once action works
export const fetchAddressResearchRawData = createFetchResearchRawData(
  ADDRESS_API,
  getAddressResearchData,
  {
    fetchAction: FETCHING_ADDRESS_RESEARCH_RAW_DATA,
    successAction: ADDRESS_RESEARCH_RAW_DATA_FETCH_SUCCESS,
    failureAction: ADDRESS_RESEARCH_RAW_DATA_FETCH_FAILURE
  }
);

export const fetchAddressTransactions = createFetchTransactions(
  ADDRESS_API,
  getAddressTransactions,
  {
    fetchAction: FETCHING_ADDRESS_TRANSACTIONS,
    successAction: ADDRESS_TRANSACTIONS_FETCH_SUCCESS,
    failureAction: ADDRESS_TRANSACTIONS_FETCH_FAILURE
  }
);

export const fetchAddressTransactionsBySatoshi = createFetchTransactionsBySatoshi(
  ADDRESS_API,
  getAddressTransactions,
  {
    fetchAction: FETCHING_ADDRESS_TRANSACTIONS_BY_SATOSHI,
    successAction: ADDRESS_TRANSACTIONS_BY_SATOSHI_FETCH_SUCCESS,
    failureAction: ADDRESS_TRANSACTIONS_BY_SATOSHI_FETCH_FAILURE
  }
);

// if new order has no values, then do a fetch?
export const addressTransactionsSwapOrder = address => (dispatch, getState) => {
  dispatch({
    type: ADDRESS_TRANSACTIONS_SWAP_ORDER,
    address,
    name: getCurrency(getState())
  });
};

export const addressMutualTransactionsSwapOrder = (address, walletId) => (dispatch, getState) => {
  dispatch({
    type: ADDRESS_MUTUAL_TRANSACTIONS_SWAP_ORDER,
    address,
    walletId,
    name: getCurrency(getState())
  });
};

export const addressTransactionSetOrder = (address, order) => async (dispatch, getState) => {
  const name = getCurrency(getState());
  return dispatch({
    type: ADDRESS_TRANSACTIONS_SET_ORDER,
    address,
    order: order,
    name
  });
};

export const addressTransactionsSetDateRange = (address, startDate, endDate) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: ADDRESS_TRANSACTIONS_SET_DATE_RANGE,
    address,
    startDate,
    endDate,
    name: getCurrency(getState())
  });

  // get the state of the rest of the objects
  const order = getAddressTransactions(getState(), address).get("order");

  if (order === 2) {
    return dispatch(
      fetchAddressTransactionsBySatoshi(address.toString(), startDate, endDate, false)
    );
  }
  return dispatch(fetchAddressTransactions(address.toString(), order, startDate, endDate, false));
};

export const addressMutualWalletTransactionsSetDateRange = (
  address,
  walletId,
  tag,
  order,
  startDate,
  endDate
) => async (dispatch, getState) => {
  dispatch({
    type: ADDRESS_MUTUAL_WALLETS_TRANSACTIONS_SET_DATE_RANGE,
    address,
    walletId,
    startDate,
    endDate,
    name: getCurrency(getState())
  });

  // get the state of the rest of the objects
  await dispatch(
    fetchMutualWalletTransactions(address, walletId, tag, "newestFirst", startDate, endDate, false)
  );
};

export const addressTransactionsSetLargestFirst = address => async (dispatch, getState) => {
  // This is just used to clear everything. Can phase this out if we incorporate largest logic into
  // newest/oldest
  dispatch({
    type: ADDRESS_TRANSACTIONS_SET_DATE_RANGE,
    address,
    name: getCurrency(getState())
  });

  await dispatch(fetchAddressTransactionsBySatoshi(address, false));
};

export const addressMutualTransactionsSetLargestFirst = (
  address,
  walletId,
  tag,
  order,
  startDate,
  endDate,
  initial
) => async (dispatch, getState) => {
  // This is just used to clear everything
  dispatch({
    type: ADDRESS_MUTUAL_WALLETS_TRANSACTIONS_SET_LARGEST_FIRST,
    address,
    walletId,
    tag,
    name: getCurrency(getState())
  });

  await dispatch(
    fetchMutualWalletTransactions(address, walletId, tag, order, startDate, endDate, initial)
  );
};

export const fetchAddressMentions = createFetchMentions(ADDRESS_API, getAddressMentions, {
  fetchAction: FETCHING_ADDRESS_MENTIONS,
  successAction: ADDRESS_MENTIONS_FETCH_SUCCESS,
  failureAction: ADDRESS_MENTIONS_FETCH_FAILURE
});

export const fetchAddressMutualWallets = createFetchMutualWallets(
  ADDRESS_API,
  getAddressMutualWallets,
  {
    fetchAction: FETCHING_ADDRESS_MUTUAL_WALLETS,
    successAction: ADDRESS_MUTUAL_WALLETS_FETCH_SUCCESS,
    failureAction: ADDRESS_MUTUAL_WALLETS_FETCH_FAILURE
  }
);

export const fetchMutualWalletTransactions = createAddressFetchMutualWalletTransactions(
  ADDRESS_API,
  getWalletMutualTransactions,
  {
    fetchAction: FETCHING_ADDRESS_WALLET_MUTUAL_TRANSACTIONS,
    successAction: ADDRESS_WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS,
    failureAction: ADDRESS_WALLET_MUTUAL_TRANSACTIONS_FETCH_FAILURE
  }
);

export const fetchAddressMoreInfo = createAddressFetchMoreInfo(ADDRESS_API, getAddressMoreInfo, {
  fetchAction: FETCHING_ADDRESS_MORE_INFO,
  successAction: ADDRESS_MORE_INFO_FETCH_SUCCESS,
  failureAction: ADDRESS_MORE_INFO_FETCH_FAILURE
});

export const fetchAddressCoinSwap = createAddressFetchCoinSwap(ADDRESS_API, getAddressCoinSwap, {
  fetchAction: FETCHING_ADDRESS_COIN_SWAP,
  successAction: ADDRESS_COIN_SWAP_FETCH_SUCCESS,
  failureAction: ADDRESS_COIN_SWAP_FETCH_FAILURE
});

export const fetchBsa = createFetchBsa(ADDRESS_API, {
  keyAction: BSA_KEY_FETCH,
  successAction: BSA_FETCH_SUCCESS,
  failureAction: BSA_FETCH_FAILURE
});

export const fetchAllBsa = createFetchAllBsa(ADDRESS_API, {
  keyAction: BSA_KEY_FETCH,
  successAction: BSA_FETCH_SUCCESS,
  failureAction: BSA_FETCH_FAILURE
});

export const fetchAddressClusterHistory = address => async (dispatch, getState) => {
  const api = ADDRESS_API;

  const clusterHistory = getClusterHistory(getState(), address);
  if (clusterHistory) {
    return;
  }
  // Get the current currency
  const name = getCurrency(getState());

  dispatch({ type: FETCHING_ADDRESS_CLUSTER_HISTORY });
  const api_call = `${api(name)}/${address}/cluster-reason`;
  try {
    const { data } = await axios.get(api_call);
    await dispatch({
      type: ADDRESS_CLUSTER_HISTORY_FETCH_SUCCESS,
      address,
      data,
      name
    });
  } catch (e) {
    console.error(e);
    await dispatch({
      type: ADDRESS_CLUSTER_HISTORY_FETCH_SUCCESS,
      address,
      name
    });
  }
};

export const addressFetchRisk = createRiskFetch("address", getAddressRisk);

export const setScrollTop = (address, scrollTop) => (dispatch, getState) => {
  dispatch({
    type: ADDRESS_TRANSACTIONS_SCROLL_TOP,
    address,
    scrollTop,
    name: getCurrency(getState())
  });
};
