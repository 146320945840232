/*
 * Copyright 2018-2021  DecisionQ Information Operations, Inc. All Rights Reserved.
 */

import {
  Button,
  ControlLabel,
  DropdownButton,
  Form,
  FormControl,
  FormGroup,
  MenuItem
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons/faSort";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/lib/Modal";
import { getUnixTimestampFromDate } from "../../helpers";

/**
 * Filter buttons for sorting transactions for wallets/addresses
 * @param entity_id
 * @param order
 * @param graphView
 * @param handleSetTransactionOrder
 * @param handleOnSetDate
 * @param setLoading
 * @param initialStartDate start date to start on
 * @param initialEndDate end date to start on
 * @returns {JSX.Element}
 * @constructor
 */
const TransactionSorter = ({
  entity_id,
  order,
  graphView = false,
  handleSetTransactionOrder,
  handleOnSetDate,
  setLoading = _ => {},
  initialStartDate,
  initialEndDate
}) => {
  let [showDateSelectModal, setShowDateSelectModal] = useState(false);
  let [startDate, setStartDate] = useState(
    initialStartDate ? new Date(initialStartDate * 1000) : ""
  );
  let [endDate, setEndDate] = useState(initialEndDate ? new Date(initialEndDate * 1000) : "");

  const getDatesAsUnix = () => {
    return {
      startDateUnix: getUnixTimestampFromDate(startDate),
      endDateUnix: getUnixTimestampFromDate(endDate, 1, "days")
    };
  };

  const setTransactionOrder = neworder => {
    const { startDateUnix, endDateUnix } = getDatesAsUnix();
    handleSetTransactionOrder(neworder, startDateUnix, endDateUnix);
  };

  const loadItemsByDate = e => {
    e.preventDefault();
    // this is only called by date submit form
    const { startDateUnix, endDateUnix } = getDatesAsUnix();
    setLoading(true);
    handleOnSetDate(entity_id, startDateUnix, endDateUnix).then(() => {
      setLoading(false);
    });

    setShowDateSelectModal(false);
  };

  const clearDates = () => {
    setStartDate("");
    setEndDate("");
  };

  let menuItemButtonClass = graphView ? "purpleFilterItem" : "";
  const dateFilter = startDate || endDate;

  let filterText;
  if (order === 2) {
    filterText = "Showing Largest First";
  } else if (order === 1) {
    filterText = "Showing Newest First";
  } else if (order === -1) {
    filterText = "Showing Oldest First";
  }

  const changeStartDate = e => {
    const newDate = new Date(e.target.value);
    if (!isNaN(newDate)) {
      setStartDate(newDate);
    }
  };

  const changeEndDate = e => {
    const newDate = new Date(e.target.value);
    if (!isNaN(newDate)) {
      setEndDate(newDate);
    }
  };

  return (
    <>
      <DropdownButton
        className="whiteButton"
        title={
          <>
            {" "}
            <FontAwesomeIcon icon={faSort} /> Sort{" "}
          </>
        }
        id={"transaction-sorter-sort"}
      >
        <MenuItem
          eventKey="1"
          onSelect={setTransactionOrder}
          className={menuItemButtonClass}
          active={order === 1}
        >
          Sort by Newest
        </MenuItem>
        <MenuItem
          eventKey="-1"
          onSelect={setTransactionOrder}
          className={menuItemButtonClass}
          active={order === -1}
        >
          Sort by Oldest
        </MenuItem>
        <MenuItem
          eventKey="2"
          onSelect={setTransactionOrder}
          className={menuItemButtonClass}
          active={order === 2}
        >
          Sort by Largest
        </MenuItem>
      </DropdownButton>
      <Button eventKey="date" onClick={() => setShowDateSelectModal(true)} className="whiteButton">
        <FontAwesomeIcon icon={faCalendarAlt} />
      </Button>
      <span
        style={{
          backgroundColor: "white",
          borderRadius: "7px",
          border: `2px solid ${graphView ? "var(--graph-sidebar-base)" : "var(--base-color)"}`,
          fontWeight: graphView ? "600" : "700",
          fontSize: graphView ? "10px" : "12px",
          fontFamily: "Quicksand",
          padding: "2px 10px 2px 10px",
          marginLeft: "10px"
        }}
      >
        {filterText}
      </span>
      {!showDateSelectModal && dateFilter && (
        <span
          style={{
            backgroundColor: "white",
            borderRadius: "7px",
            border: `2px solid ${graphView ? "var(--graph-sidebar-base)" : "var(--base-color)"}`,
            fontWeight: graphView ? "600" : "700",
            fontSize: graphView ? "10px" : "12px",
            fontFamily: "Quicksand",
            padding: "2px 10px 2px 10px",
            marginLeft: "10px"
          }}
        >
          {!startDate ? "Origin" : startDate.toISOString().split("T")[0]} to{" "}
          {!endDate ? "Present" : endDate.toISOString().split("T")[0]}
        </span>
      )}
      <Modal show={showDateSelectModal} onHide={() => setShowDateSelectModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select dates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={loadItemsByDate}>
            <FormGroup controlId="startDate">
              <ControlLabel>Start date</ControlLabel>
              <FormControl
                type="date"
                value={startDate && startDate.toISOString().split("T")[0]}
                max={endDate && endDate.toISOString().split("T")[0]}
                min={"1970-01-01"}
                placeholder=""
                onChange={e => changeStartDate(e)}
              />
            </FormGroup>
            <FormGroup controlId="endDate">
              <ControlLabel>End date</ControlLabel>
              <FormControl
                type="date"
                value={endDate && endDate.toISOString().split("T")[0]}
                placeholder=""
                min={startDate && startDate.toISOString().split("T")[0]}
                max={new Date().toISOString().split("T")[0]}
                onChange={e => changeEndDate(e)}
              />
            </FormGroup>
            <Button type="submit">Set date</Button>
            {dateFilter && (
              <Button
                onClick={clearDates}
                style={{
                  marginLeft: 10
                }}
              >
                Remove Date Selection
              </Button>
            )}
            <Button
              style={{
                marginLeft: 10
              }}
              className="redButton"
              onClick={() => setShowDateSelectModal(false)}
            >
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransactionSorter;
