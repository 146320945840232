// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { List, Map, Record } from "immutable";
import {
  AddressMention,
  AddressMutualWallet,
  CompoundKey,
  Mentions,
  Transaction,
  Transactions
} from "./sharedRecords";
import {
  ADDRESS_CLUSTER_HISTORY_FETCH_SUCCESS,
  ADDRESS_COIN_SWAP_FETCH_SUCCESS,
  ADDRESS_MENTIONS_FETCH_SUCCESS,
  ADDRESS_MORE_INFO_FETCH_SUCCESS,
  ADDRESS_MUTUAL_TRANSACTIONS_SWAP_ORDER,
  ADDRESS_MUTUAL_WALLETS_FETCH_FAILURE,
  ADDRESS_MUTUAL_WALLETS_FETCH_SUCCESS,
  ADDRESS_MUTUAL_WALLETS_TRANSACTIONS_SET_DATE_RANGE,
  ADDRESS_MUTUAL_WALLETS_TRANSACTIONS_SET_LARGEST_FIRST,
  ADDRESS_SUMMARY_FETCH_SUCCESS,
  ADDRESS_TRANSACTIONS_BY_SATOSHI_FETCH_SUCCESS,
  ADDRESS_TRANSACTIONS_FETCH_SUCCESS,
  ADDRESS_TRANSACTIONS_SCROLL_TOP,
  ADDRESS_TRANSACTIONS_SET_DATE_RANGE,
  ADDRESS_TRANSACTIONS_SET_ORDER,
  ADDRESS_TRANSACTIONS_SWAP_ORDER,
  ADDRESS_WALLET_MUTUAL_TRANSACTIONS_FETCH_FAILURE,
  ADDRESS_WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS,
  BSA_FETCH_SUCCESS,
  BSA_KEY_FETCH,
  LOGGING_OUT,
  MORE_ADDRESS_SUMMARY_FETCH_SUCCESS,
  RISK_SUCCESS,
  WALLET_SET_CUSTOM_TAG
} from "../actions/actionNames";
import { addWalletName } from "./wallet";
import { addRiskReducer } from "./reducerFactories";

/*
Address State
Address: Map<string, AddressRecord>

AddressRecord: {
  summary: AddressSummary: {
    walletId: number,
    inputCount: number,
    inputSatoshi: number,
    outputCount: number,
    outputSatoshi: number,
    firstTransaction: number,
    lastTransaction: number,
    transactionCount: number,
    balance: number,
    addressId: number,
    primaryTag: string | null,
    mentionsCount: number,
  },
  transactions: AddressTransactions: {
    transactionMap: Map<number, Transaction>,
    newestFirst: List<number>,
    newestFirstNextKey: number | null,
    oldestFirst: List<number>,
    oldestFirstNextKey: number | null,
    largestFirst: List<number>,
    largestFirstNextKey: CompoundKey | null,
    order: -1 | 1,
    startDate: number | null,
    endDate: number | null,
  },
  mentions: Mentions: {
    mentions: List<AddressMention>,
  },
  // If this is null we just don't set anything?
  scrollTop: number | null,
}

Transaction: {
  transactionHash: string,
  transactionId: number,
  inputSatoshi: number,
  outputSatoshi: number,
  satoshi: number,
  timestamp: number,
}

AddressMention: {
  address: string,
  username: string,
  onlineAccountId: string,
  sourceName: string,
  hasContent: bool,
}

CompoundKey: {
  primary: number,
  secondary: number,
}
 */

export const AddressSummary = Record({
  walletId: "",
  inputCount: 0,
  inputCountSeparated: 0,
  inputSatoshi: 0,
  outputCount: 0,
  outputCountSeparated: 0,
  outputSatoshi: 0,
  firstTransaction: 0,
  lastTransaction: 0,
  transactionCount: 0,
  balance: 0,
  addressId: 0,
  primaryTag: "",
  mentionsCount: 0,
  bsaMentionsCount: 0,
  sent_hist_usd: 0,
  recv_hist_usd: 0,
  sent_no_self: 0,
  recv_no_self: 0,
  sent_usd_no_self: 0,
  recv_usd_no_self: 0,
  confidence_score: null,
  attribution_source: null
});

export const AddressMoreInfo = Record({
  risk: 0,
  sanctions_risk: 0,
  gambling_ok_risk: 0,
  ip: List(),
  inCase: false
});

export const CoinSwap = Record({
  in_address: null,
  out_address: null,
  in_currency: null,
  in_amount: 0,
  out_amount: 0,
  in_token: null,
  out_currency: null,
  out_token: null,
  in_transaction_hash: null,
  out_transaction_hash: null
});

export const ClusterHistoryInfo = Record({
  wallet: null,
  transaction: null,
  reason: null,
  description: null,
  name: null
});

export const AddressIpItem = Record({
  ipAddress: "",
  city: "",
  country: "",
  latitude: 0.0,
  longitude: 0.0,
  date: 0
});

export const MutualWallets = Record({
  mutualWallets: Map(), // walletId --> walletSummary
  mostRecentCallSuccess: true,
  walletsFetched: false // flipped to true if the wallets have been fetched
});

export const BsaSummary = Record({
  bsaKey: 0,
  numberToShow: 0,
  bsaIds: List()
});

export const AddressRecord = Record({
  summary: new AddressSummary(),
  moreInfo: new AddressMoreInfo(),
  coinSwapData: List(),
  coinSwap: false,
  clusterHistory: null,
  transactions: new Transactions(),
  mentions: new Mentions(),
  bsa: new BsaSummary(),
  scrollTop: null,
  mutualWallets: new MutualWallets(),
  risk: null
});

export const getAddressRecord = (state, address) => state.get(address) || new AddressRecord();

const addAddressTransactions = (state, action) => {
  const { item: address_, order, data } = action;
  let addressRecord = getAddressRecord(state, address_);
  let transactions = addressRecord.get("transactions");
  let transactionMap = transactions.get("transactionMap");
  const key = order > 0 ? "newestFirst" : "oldestFirst";
  let transactionList = transactions.get(order > 0 ? "newestFirst" : "oldestFirst");

  data.transactions.forEach(transaction => {
    const { transactionId } = transaction;
    const transactionRecord = new Transaction(transaction);
    // transactionIds are pushed into the corresponding transaction list
    transactionList = transactionList.push(transactionId);
    // transactionId is mapped to corresponding transactionRecord
    transactionMap = transactionMap.set(transactionId, transactionRecord);
  });

  transactions = transactions
    .set("transactionMap", transactionMap)
    .set(key, transactionList)
    .set(`${key}NextKey`, data.key);

  addressRecord = addressRecord.set("transactions", transactions);

  return state.set(address_, addressRecord);
};

const addAddressTransactionsBySatoshi = (state, action) => {
  const { item: address_, data } = action;
  const key = "largestFirst";
  let addressRecord = getAddressRecord(state, address_);
  let transactions = addressRecord.get("transactions");
  let transactionMap = transactions.get("transactionMap");
  let transactionList = transactions.get("largestFirst");

  data.transactions.forEach(transaction => {
    const { transactionId } = transaction;
    const transactionRecord = new Transaction(transaction);
    // transactionIds are pushed into corresponding transaction list
    transactionList = transactionList.push(transactionId);
    // transactionId is mapped to corresponding transactionRecord
    transactionMap = transactionMap.set(transactionId, transactionRecord);
  });

  const compoundKey = new CompoundKey({
    primary: data.primaryKey,
    secondary: data.secondaryKey
  });

  transactions = transactions
    .set("transactionMap", transactionMap)
    .set(key, transactionList)
    .set(`${key}NextKey`, compoundKey);

  addressRecord = addressRecord.set("transactions", transactions);
  return state.set(address_, addressRecord);
};

const addAddressSummary = (state, action) => {
  const { item: address_, data } = action;
  let addressRecord = getAddressRecord(state, address_);
  const addressSummary = new AddressSummary(data);
  addressRecord = addressRecord.set("summary", addressSummary);
  return state.set(address_, addressRecord);
};

const addMoreAddressSummary = (state, action) => {
  const { item: address_, data } = action;
  let addressRecord = state.get(address_);
  let addressSummary = addressRecord.get("summary");
  for (const [key, value] of Object.entries(data)) {
    addressSummary = addressSummary.set(key, value);
  }
  addressRecord = addressRecord.set("summary", addressSummary);
  return state.set(address_, addressRecord);
};

const addAddressMoreInfo = (state, action) => {
  // Grab data from the dispatched action
  const { address, data } = action;

  // Get the address record from redux
  let addressRecord = getAddressRecord(state, address);

  // Create temp variables for the moreInfo and ip portions of the address record
  let moreInfo = addressRecord.get("moreInfo");
  let ip = moreInfo.get("ip");

  // For each ip address returned, store it in a new AddressIpItem object and store it in the ip list
  data.ip.forEach(ipEntry => {
    const ip_record = new AddressIpItem(ipEntry);
    ip = ip.push(ip_record);
  });

  // Set all of the retrieved variables in out temp moreInfo object
  moreInfo = moreInfo
    .set("ip", ip)
    .set("risk", data.risk)
    .set("gambling_ok_risk", data.gambling_ok_risk)
    .set("sanctions_risk", data.sanctions_risk)
    .set("inCase", data.inCase);

  // Set the temp address record to the new one with the updated moreInfo object
  addressRecord = addressRecord.set("moreInfo", moreInfo);

  // set the address record in redux and return
  return state.set(address, addressRecord);
};

export const addAddressCoinSwap = (state, action) => {
  // Grab data from the dispatched action
  const { address, data } = action;
  // Get the address record from redux
  let addressRecord = getAddressRecord(state, address);

  let coinSwaps = addressRecord.get("coinSwapData");

  data.forEach(coinSwap => {
    const coinSwapRecord = new CoinSwap(coinSwap);
    coinSwaps = coinSwaps.push(coinSwapRecord);
  });
  addressRecord = addressRecord.set("coinSwapData", coinSwaps);
  addressRecord = addressRecord.set("coinSwap", data.length !== 0);
  return state.set(address, addressRecord);
};

const swapOrder = (state, action) => {
  const { address: address_ } = action;
  const order = state.getIn([address_, "transactions", "order"]);
  return state.setIn([address_, "transactions", "order"], order > 0 ? -1 : 1);
};

/**
 * Sets the order for address transactions. Used for sort by newest/oldest and largest
 * @param state redux state
 * @param address address to change order for
 * @param order the new value of order
 * @returns {*}
 */
export const setOrder = (state, { address, order }) => {
  return state.setIn([address, "transactions", "order"], order);
};

const mutualTransactionSwapOrder = (state, action) => {
  const { address: address_, walletId } = action;

  let addressRecord = getAddressRecord(state, address_);
  let mutualWallets = addressRecord.getIn(["mutualWallets", "mutualWallets", walletId]);

  let transactions = mutualWallets.get("transactions");
  const order = transactions.get("order");
  const newOrder =
    order === "largestFirst" || order === "oldestFirst" ? "newestFirst" : "oldestFirst";
  transactions = transactions
    .set("order", newOrder)
    .set("startDate", null)
    .set("endDate", null);

  addressRecord = addressRecord.setIn(
    ["mutualWallets", "mutualWallets", walletId, "transactions"],
    transactions
  );
  return state.set(address_, addressRecord);
};

const mutualTransactionSetLargestFirst = (state, action) => {
  const { address: address_, walletId, startDate, endDate } = action;

  let addressRecord = getAddressRecord(state, address_);
  let mutualWallets = addressRecord.getIn(["mutualWallets", "mutualWallets", walletId]);
  const transactions = mutualWallets
    .get("transactions")
    .set("startDate", startDate)
    .set("endDate", endDate)
    .set("order", "largestFirst");
  addressRecord = addressRecord.setIn(
    ["mutualWallets", "mutualWallets", walletId, "transactions"],
    transactions
  );
  return state.set(address_, addressRecord);
};

/**
 * Sets the date range of transactions to search and clears old transactions
 * @param state
 * @param action
 */
const setDateRange = (state, action) => {
  const { address: address_, startDate, endDate } = action;

  let addressRecord = getAddressRecord(state, address_);
  const transactions = addressRecord
    .get("transactions")
    .set("transactionMap", Map())
    .set("newestFirst", List())
    .set("newestFirstNextKey", 0)
    .set("oldestFirst", List())
    .set("oldestFirstNextKey", 0)
    .set("largestFirst", List())
    .set("largestFirstNextKey", new CompoundKey())
    .set("startDate", startDate)
    .set("endDate", endDate);
  addressRecord = addressRecord.set("transactions", transactions);
  return state.set(address_, addressRecord);
};

/**
 * Sets the date range of transactions for the selected mutual wallet to search and clears old transactions
 * @param state
 * @param action
 */
const setDateRangeMutualWallets = (state, action) => {
  const { address: address_, walletId, tag, startDate, endDate } = action;

  let addressRecord = getAddressRecord(state, address_);
  let mutualWallets = addressRecord.getIn(["mutualWallets", "mutualWallets", walletId]);
  // Set the start and end dates, as well as resetting the trx's for the current sorting order
  const transactions = mutualWallets
    .get("transactions")
    .set("order", "newestFirst")
    .set("newestFirst", List())
    .set(`newestFirstHasMore`, true)
    .set("startDate", startDate)
    .set("endDate", endDate);
  addressRecord = addressRecord.setIn(
    ["mutualWallets", "mutualWallets", walletId, "transactions"],
    transactions
  );
  return state.set(address_, addressRecord);
};

const addAddressMentions = (state, action) => {
  const { item: address_, data } = action;
  let addressRecord = getAddressRecord(state, address_);
  let mentions = addressRecord.getIn(["mentions", "mentions"]);
  data.mentions.forEach(mention => {
    const mentionRecord = new AddressMention(mention);
    mentions = mentions.push(mentionRecord);
  });

  addressRecord = addressRecord
    .setIn(["mentions", "mentions"], mentions)
    .setIn(["mentions", "nextKey"], data.key);
  return state.set(address_, addressRecord);
};

const addAddressMutualWallets = (state, action) => {
  const { item: address_, data } = action;
  let addressRecord = getAddressRecord(state, address_);
  let mutualWallets = addressRecord.getIn(["mutualWallets", "mutualWallets"]);
  // Create an AddressMutualWallet object for each mutualWallet and add them to the map
  data.forEach(mutualWallet => {
    mutualWallets = mutualWallets.set(
      mutualWallet.walletId,
      new AddressMutualWallet({
        walletId: mutualWallet.walletId,
        tag: mutualWallet.tag,
        category: mutualWallet.category,
        totalTrx: mutualWallet.transactionCount,
        firstTrx: mutualWallet.firstTransaction,
        lastTrx: mutualWallet.lastTransaction,
        sentCount: mutualWallet.sentCount,
        recvCount: mutualWallet.recvCount
      })
    );
  });

  // Set the mutualWallets portion of the store to the list of mutualWallets
  addressRecord = addressRecord
    .setIn(["mutualWallets", "mutualWallets"], mutualWallets)
    .setIn(["mutualWallets", "mostRecentCallSuccess"], true)
    .setIn(["mutualWallets", "walletsFetched"], true);
  return state.set(address_, addressRecord);
};

const addAddressMutualWalletsFailure = (state, action) => {
  const { item: address_, data } = action;
  let addressRecord = getAddressRecord(state, address_);

  // Set the mutualWallets portion of the store to the list of mutualWallets
  addressRecord = addressRecord.setIn(["mutualWallets", "mostRecentCallSuccess"], false);
  return state.set(address_, addressRecord);
};

const addClusterHistory = (state, { address, data }) => {
  let addressRecord = getAddressRecord(state, address);
  const clusterHistory = new ClusterHistoryInfo({
    ...data
  });
  addressRecord = addressRecord.set("clusterHistory", clusterHistory);
  return state.set(address, addressRecord);
};

const addWalletMutualTransactions = (state, action) => {
  const { item: address_, walletId, order, data } = action;
  let addressRecord = getAddressRecord(state, address_);
  let transactions = addressRecord.getIn([
    "mutualWallets",
    "mutualWallets",
    walletId,
    "transactions"
  ]);
  let transactionMap = transactions.get("transactionMap");

  // determine whether the fetched data is newest first or oldest first
  const key = order;
  let transactionList = transactions.get(key);

  // Create an Transaction object for each mutualWallet and add them to the map
  data.transactions.forEach(transaction => {
    const {
      transactionId,
      transactionHash,
      inputSatoshi,
      outputSatoshi,
      timestamp,
      price
    } = transaction;
    transactionMap = transactionMap.set(
      transactionId,
      new Transaction({
        transactionId,
        transactionHash,
        inputSatoshi,
        outputSatoshi,
        timestamp,
        satoshi: outputSatoshi - inputSatoshi,
        price
      })
    );
    transactionList = transactionList.push(transactionId);
  });

  // Set the transactionmap and transactionlist in the transactions object
  transactions = transactions
    .set("transactionMap", transactionMap)
    .set(key, transactionList)
    .set(`${key}HasMore`, data.hasMore)
    .set("mostRecentCallSuccess", true);

  addressRecord = addressRecord
    .setIn(["mutualWallets", "mutualWallets", walletId, "transactions"], transactions)
    .setIn(["mutualWallets", "mutualWallets", walletId, "mostRecentCallSuccess"], true);
  return state.set(address_, addressRecord);
};

const addWalletMutualTransactionFailure = (state, action) => {
  const { item: address_, walletId, order, data } = action;
  let addressRecord = getAddressRecord(state, address_);
  let record = addressRecord.getIn(["mutualWallets", "mutualWallets", walletId]);
  record = record.set("mostRecentCallSuccess", false);

  addressRecord = addressRecord.setIn(["mutualWallets", "mutualWallets", walletId], record);
  return state.set(address_, addressRecord);
};

const addBsaKey = (state, action) => {
  const { data, address } = action;
  const addressRecord = getAddressRecord(state, address).setIn(["bsa", "bsaKey"], data.key);

  return state.set(address, addressRecord);
};

const addBsaMentions = (state, action) => {
  const { data, address, increaseNumberToShow } = action;
  let trans = List();
  let i;
  let addressRecord = getAddressRecord(state, address);
  let currentIdsInStore = addressRecord.getIn(["bsa", "bsaIds"]);
  if (addressRecord === undefined) {
    return state;
  }
  currentIdsInStore = currentIdsInStore.toJS();

  if (!data.bsaIds) {
    // Suboptimal since server side pagination is not necessarily 100.
    addressRecord = addressRecord.updateIn(
      ["bsa", "numberToShow"],
      numberToShow => numberToShow + 2
    );
    return state.set(address, addressRecord);
  }

  const numBsaIds = data.bsaIds.length;
  for (i = 0; i < numBsaIds; i += 1) {
    if (currentIdsInStore.includes(data.bsaIds[i].bsa_id) === false) {
      trans = trans.push(data.bsaIds[i].bsa_id);
    }
  }
  const updatedList = addressRecord.getIn(["bsa", "bsaIds"]).concat(trans);
  addressRecord = addressRecord.setIn(["bsa", "bsaIds"], updatedList);
  if (increaseNumberToShow) {
    addressRecord = addressRecord.updateIn(
      ["bsa", "numberToShow"],
      numberToShow => numberToShow + numBsaIds
    );
  }

  return state.set(address, addressRecord);
};

// Whenever a wallet's tag is changed, we need to change all the addresses that
// belongs to the wallet whose tag was changed.
const changeWalletTag = (state, action) => {
  const { walletId, primaryTag } = action;

  return state.map(addressRecord => {
    const addressWalletId = addressRecord.getIn(["summary", "walletId"]);

    if (addressWalletId === walletId) {
      if (addressWalletId !== primaryTag) {
        return addressRecord.setIn(["summary", "primaryTag"], primaryTag);
      }

      return addressRecord.setIn(["summary", "primaryTag"], null);
    }
    return addressRecord;
  });
};

const setScrollTop = (state, action) => {
  const { address, scrollTop } = action;

  let addressRecord = getAddressRecord(state, address);
  addressRecord = addressRecord.set("scrollTop", scrollTop);

  return state.set(address, addressRecord);
};

const makeAddressReducer = coin => {
  // This just returns the state, which is why each of the switch funcs returns an ImmutableJs set
  return (state = Map(), action) => {
    // If the coin doesn't match the reducer, just return the state.
    if (action === undefined || action.name !== coin) {
      return state;
    }

    if (action && action.entityType && action.entityType !== "address") {
      return state;
    }

    switch (action.type) {
      case ADDRESS_SUMMARY_FETCH_SUCCESS:
        return addAddressSummary(state, action);
      case MORE_ADDRESS_SUMMARY_FETCH_SUCCESS:
        return addMoreAddressSummary(state, action);
      case ADDRESS_TRANSACTIONS_FETCH_SUCCESS:
        return addAddressTransactions(state, action);
      case ADDRESS_TRANSACTIONS_SWAP_ORDER:
        return swapOrder(state, action);
      case ADDRESS_MUTUAL_TRANSACTIONS_SWAP_ORDER:
        return mutualTransactionSwapOrder(state, action);
      case ADDRESS_TRANSACTIONS_SET_ORDER:
        return setOrder(state, action);
      case ADDRESS_TRANSACTIONS_SET_DATE_RANGE:
        return setDateRange(state, action);
      case ADDRESS_MUTUAL_WALLETS_TRANSACTIONS_SET_DATE_RANGE:
        return setDateRangeMutualWallets(state, action);
      case ADDRESS_MUTUAL_WALLETS_TRANSACTIONS_SET_LARGEST_FIRST:
        return mutualTransactionSetLargestFirst(state, action);
      case ADDRESS_MENTIONS_FETCH_SUCCESS:
        return addAddressMentions(state, action);
      case ADDRESS_MUTUAL_WALLETS_FETCH_SUCCESS:
        return addAddressMutualWallets(state, action);
      case ADDRESS_WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS:
        return addWalletMutualTransactions(state, action);
      case ADDRESS_MUTUAL_WALLETS_FETCH_FAILURE:
        return addAddressMutualWalletsFailure(state, action);
      case ADDRESS_WALLET_MUTUAL_TRANSACTIONS_FETCH_FAILURE:
        return addWalletMutualTransactionFailure(state, action);
      case ADDRESS_TRANSACTIONS_BY_SATOSHI_FETCH_SUCCESS:
        return addAddressTransactionsBySatoshi(state, action);
      case BSA_KEY_FETCH:
        return addBsaKey(state, action);
      case BSA_FETCH_SUCCESS:
        return addBsaMentions(state, action);
      case ADDRESS_TRANSACTIONS_SCROLL_TOP:
        return setScrollTop(state, action);
      case ADDRESS_MORE_INFO_FETCH_SUCCESS:
        return addAddressMoreInfo(state, action);
      case ADDRESS_COIN_SWAP_FETCH_SUCCESS:
        return addAddressCoinSwap(state, action);
      case ADDRESS_CLUSTER_HISTORY_FETCH_SUCCESS:
        return addClusterHistory(state, action);
      case RISK_SUCCESS:
        return addRiskReducer(getAddressRecord, action.entityType)(state, action);
      case LOGGING_OUT:
        return Map();
      default:
        return state;
    }
  };
};

export default makeAddressReducer;
