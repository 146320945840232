import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMessages, getMessagesNotViewed, getShowMessages } from "../../selectors/messages";
import { useEffect, useState } from "react";
import {
  Badge,
  BadgeProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  styled
} from "@material-ui/core";
import { faCircle, faEnvelope, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteMessage, ignoreMessage, markAsRead } from "../../actions/messages";
import { Share } from "./Share";
import { DefaultMessageContent } from "./DefaultMessageContent";
import { NewMessageHandler } from "./NewMessageHandler";
import moment from "moment";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -10,
    top: 0,
    border: `2px solid black`,
    padding: "0 4px",
    fontSize: "12px"
  }
}));

export const MessageHandler = ({ fontSize = "1.5rem" }) => {
  const messages = useSelector(state => getMessages(state));
  const showMessages = useSelector(state => getShowMessages(state));

  const dispatch = useDispatch();

  const [messageIdx, setMessageIdx] = useState<number>(0);
  const [showMessagesIcon, setShowMessagesIcon] = useState<boolean>(true);
  const [showNewMessages, setShowNewMessages] = useState<boolean>(true);

  useEffect(() => {
    if (messageIdx >= messages.length) {
      setMessageIdx(messages.length - 1);
    }
  }, [messages]);

  const useStyles = makeStyles({
    dialog: {
      height: "50vh",
      position: "absolute"
    },
    dialogRoot: {
      zIndex: "100000 !important"
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: fontSize
    },
    listItemText: {
      fontSize: fontSize,
      overflow: "hidden"
    },
    listItemInset: {
      paddingLeft: 15
    },
    listItemIcon: {
      minWidth: 15
    },
    listItem: {
      "&.Mui-selected": {
        backgroundColor: "#BCE4F5"
      }
    }
  });
  const classes = useStyles();

  const dissectActionLink = (action_link: string, message_id: number) => {
    const url_split = action_link.split("/");
    const coin = url_split[1];
    const token = url_split[url_split.length - 1];
    return (
      <Share
        token={token}
        redirect={false}
        afterResolve={() => dispatch(deleteMessage(message_id))}
        currency={coin}
      />
    );
  };
  if (!showMessages) {
    return <></>;
  }
  return (
    <div>
      <IconButton
        onClick={() => {
          setShowMessagesIcon(!showMessagesIcon);
          setShowNewMessages(false);
        }}
        size={"small"}
      >
        <StyledBadge
          badgeContent={
            messages &&
            messages.reduce((total: number, item: any) => (item.viewed ? total : total + 1), 0)
          }
          color="secondary"
          max={9}
          key={"MessageButton"}
          overlap={"circular"}
        >
          <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: "20px" }} />
        </StyledBadge>
      </IconButton>
      {showNewMessages ? (
        <NewMessageHandler
          showNewMessages={showNewMessages}
          closeNewMessages={() => {
            setShowMessagesIcon(false);
            setShowNewMessages(false);
          }}
        />
      ) : (
        messages &&
        messages.length > 0 && (
          <Dialog
            open={showMessagesIcon}
            fullWidth
            onClose={() => {
              setShowMessagesIcon(false);
              setShowNewMessages(false);
            }}
            classes={{ paper: classes.dialog, root: classes.dialogRoot }}
            maxWidth={"md"}
          >
            <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
              <h4>Messages</h4>
              <IconButton
                onClick={() => {
                  setShowMessagesIcon(false);
                  setShowNewMessages(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes} style={{ fontSize: "15px" }} />
              </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Grid container spacing={5}>
                <Grid item xs={4} md={4}>
                  <List
                    disablePadding
                    style={{ maxHeight: "34vh", overflow: "auto" }}
                    key={`message-list`}
                  >
                    {messages.map((item: any, idx: number) => (
                      <>
                        <ListItem
                          autoFocus={idx === messageIdx}
                          key={`message-${item.id}`}
                          button
                          onClick={() => setMessageIdx(idx)}
                          selected={messageIdx === idx}
                          classes={{
                            selected: classes.listItem,
                            button: classes.listItem
                          }}
                        >
                          {!item.viewed && (
                            <ListItemIcon
                              classes={{
                                root: classes.listItemIcon
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCircle}
                                style={{
                                  fontSize: "10px",
                                  color: "green"
                                }}
                              />
                            </ListItemIcon>
                          )}
                          <ListItemText
                            primary={item.title}
                            secondary={
                              item.date && moment.unix(parseInt(item.date, 10)).format("LLL")
                            }
                            classes={{
                              primary: classes.listItemText,
                              secondary: classes.listItemText,
                              inset: classes.listItemInset
                            }}
                            primaryTypographyProps={{ noWrap: true }}
                            inset={item.viewed}
                          />
                        </ListItem>
                        <Divider />
                      </>
                    ))}
                  </List>
                </Grid>
                <Divider orientation={"vertical"} flexItem absolute />
                <Grid item xs md>
                  {messages[messageIdx].custom_entity ? (
                    dissectActionLink(
                      messages[messageIdx].action_link,
                      messages[messageIdx].message_id
                    )
                  ) : (
                    <DefaultMessageContent
                      message={messages[messageIdx].message}
                      title={messages[messageIdx].title}
                      divider={false}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setMessageIdx(messageIdx - 1)} disabled={messageIdx <= 0}>
                Previous
              </Button>
              <Button
                onClick={() => setMessageIdx(messageIdx + 1)}
                disabled={messageIdx >= messages.length - 1}
              >
                Next
              </Button>
              <Button
                onClick={() => {
                  dispatch(markAsRead(messages[messageIdx].message_id));
                  messageIdx < messages.length - 1 && setMessageIdx(messageIdx + 1);
                }}
                disabled={messages[messageIdx].viewed}
              >
                Mark as Read
              </Button>
            </DialogActions>
          </Dialog>
        )
      )}
    </div>
  );
};
