/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

/*
 * Displays a transaction input
 */
import PropTypes from "prop-types";
import React from "react";
import { OverlayTrigger } from "react-bootstrap";

import AddressCell from "../AddressCell";
import { numberWithCommas, satoshiToBitcoin } from "../../../helpers";
import { FullWidthPopover } from "../../styled";
import { useSelector } from "react-redux";
import { getWalletName } from "../../../selectors/wallet";

const InputAddressRow = ({
  keyProp,
  transactionHash,
  address,
  addressCount,
  walletId,
  satoshi,
  primaryTag,
  handleOpenAddressModal,
  handleOpenTransactionModal,
  handleOpenWalletModal,
  getAddressLink,
  getWalletLink,
  getTransactionLink,
  isChange,
  currency,
  convertPriceToUsd,
  toggleUsdDisplay,
  showAsUsd,
  isNote,
  onAddressNoteClick
}) => {
  // This is used to display the input's transaction hash when mousing over arrow in
  // first cell.
  const tooltip = <FullWidthPopover id={keyProp}>{transactionHash}</FullWidthPopover>;
  let bitcoin;
  if (address.length > 0 && address[0] === "Coinbase") {
    bitcoin = null;
  } else {
    bitcoin = satoshiToBitcoin(satoshi);
  }
  const showWallet = walletId !== 0;
  const { name, tagType } = useSelector(state => {
    if (showWallet && walletId) {
      return getWalletName(state, walletId);
    } else {
      return {
        tagType: "coinbase",
        name: null
      };
    }
  });
  let linkText;
  if (!name) {
    linkText = null;
  } else {
    linkText = `${
      tagType === "ID" ? `${name.slice(0, Math.min(name.length, 7))}...` : name
    } (${addressCount.toLocaleString()})`;
  }

  console.log(linkText);
  return (
    <tr key={keyProp} className="inviteRowThin staticTableRow">
      <OverlayTrigger overlay={tooltip} placement="top" delayShow={300} delayHide={150}>
        <td
          style={{
            fontSize: "12px",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          {/* Not sure when transactionHash can be false... */}
          {transactionHash &&
            getTransactionLink(transactionHash, "left", handleOpenTransactionModal, currency)}
        </td>
      </OverlayTrigger>
      <AddressCell
        address={address}
        walletId={walletId}
        handleOpenAddressModal={handleOpenAddressModal}
        getAddressLink={getAddressLink}
        keyProp={keyProp}
        input
        isChange={isChange}
        currency={currency}
        exists={showWallet}
        isNote={isNote}
        onAddressNoteClick={onAddressNoteClick}
      />
      <td
        style={{
          fontSize: "12px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "right"
        }}
      >
        {showWallet ? (
          getWalletLink(walletId, linkText, handleOpenWalletModal, currency)
        ) : (
          <span>Unclustered</span>
        )}
      </td>
      <td
        style={{
          fontSize: "12px",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        <div className="text-right">
          {showAsUsd ? "$" + numberWithCommas(convertPriceToUsd(bitcoin)) : bitcoin}
        </div>
      </td>
    </tr>
  );
};

export default InputAddressRow;

InputAddressRow.propTypes = {
  address: PropTypes.arrayOf(PropTypes.string).isRequired,
  keyProp: PropTypes.string.isRequired,
  primaryTag: PropTypes.string,
  satoshi: PropTypes.number,
  // null if coinbase
  transactionHash: PropTypes.string,
  // null if coinbase
  walletId: PropTypes.number,
  handleOpenAddressModal: PropTypes.func,
  handleOpenTransactionModal: PropTypes.func,
  handleOpenWalletModal: PropTypes.func,
  getAddressLink: PropTypes.func.isRequired,
  getWalletLink: PropTypes.func.isRequired,
  getTransactionLink: PropTypes.func.isRequired,
  isChange: PropTypes.bool.isRequired
};

InputAddressRow.defaultProps = {
  primaryTag: null,
  handleOpenAddressModal: null,
  handleOpenTransactionModal: null,
  handleOpenWalletModal: null,
  walletId: null,
  transactionHash: null,
  satoshi: 0.0
};
