/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";

import InputWalletRow from "./InputWalletRow";
import withTableSorter from "../withTableSorter";
import { PointerTableHeader } from "../styled";
import { transactionWallet } from "../../../prop-types";
import { Table } from "react-bootstrap";
import CurrencyChooserConfig from "../../CurrencyChooserConfig";
import { numberWithCommas } from "../../../helpers";
import { useSelector } from "react-redux";
import { getClientMode } from "../../../selectors/applicationConfig";
import ClientModeConfig from "../../ClientModeConfig";

const InputWalletTable = ({
  totalBitcoin,
  outputs,
  handleSortBySatoshi,
  handleOpenWalletModal,
  handleSortByWallet,
  getWalletLink,
  currency,
  convertPriceToUsd,
  toggleUsdDisplay,
  currentCaret,
  showAsUsd,
}) => {
  const inputs = outputs.map(input => {
    const { key, satoshi, walletId, primaryTag } = input;

    return (
      <InputWalletRow
        key={key}
        keyProp={key}
        walletId={walletId}
        satoshi={satoshi}
        primaryTag={primaryTag}
        handleOpenWalletModal={handleOpenWalletModal}
        getWalletLink={getWalletLink}
        currency={currency}
        convertPriceToUsd={convertPriceToUsd}
        toggleUsdDisplay={toggleUsdDisplay}
        showAsUsd={showAsUsd}
      />
    );
  });

  let count = outputs.length;
  if (outputs.length > 0 && outputs[0].walletId == null) {
    count = 0;
  }
  let currencyObject = CurrencyChooserConfig[currency];

  const clientMode = useSelector(state => getClientMode(state));
  let clientModeConfigObject = {
    word_for_collection: "",
    cap_word_for_collection: "",
    plural_for_collection: "",
    cap_plural_for_collection: "",
    nav_logo: null,
    login_logo: null,
    login_top_logo: null,
    login_top_icon: null,
  };
  if (clientMode in ClientModeConfig) {
    clientModeConfigObject = ClientModeConfig[clientMode];
  }
  return (
    <div>
      <p>
        <b>Input</b> ({count}){" "}
        {showAsUsd ? "$" + numberWithCommas(convertPriceToUsd(totalBitcoin)) : totalBitcoin}
      </p>
      <Table className="inviteTable">
        <thead>
          <tr>
            <PointerTableHeader onClick={handleSortByWallet}>
              {clientModeConfigObject.cap_word_for_collection}
              <div className="divider" />
              {currentCaret.wallet}
            </PointerTableHeader>
            <PointerTableHeader onClick={handleSortBySatoshi} className="text-right">
              {showAsUsd ? "$" : currencyObject["abb"]}
              <div className="divider" />
              {currentCaret.satoshi}
            </PointerTableHeader>
          </tr>
        </thead>
        <tbody>{inputs}</tbody>
      </Table>
    </div>
  );
};

InputWalletTable.propTypes = {
  outputs: PropTypes.arrayOf(transactionWallet).isRequired,
  totalBitcoin: PropTypes.string.isRequired,
  handleSortBySatoshi: PropTypes.func.isRequired,
  handleOpenWalletModal: PropTypes.func,
  getWalletLink: PropTypes.func.isRequired,
};

InputWalletTable.defaultProps = {
  handleOpenWalletModal: null,
};

export default withTableSorter(InputWalletTable, "wallet");
