/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "../../css/styles.css";
import { connect } from "react-redux";
import Papa from "../../../node_modules/papaparse/papaparse.min";
import axios from "../api";
import { satoshiToBitcoin } from "../helpers";
import TransactionsToDeposit from "../../img/transactions-to-deposit.png";
import AddressesToWallets from "../../img/addresses-to-wallets.png";
import { getCurrency } from "../selectors/currency";
import { getClientMode } from "../selectors/applicationConfig";
import ClientModeConfig from "./ClientModeConfig";

export class SubpoenaSupport extends Component {
  state = {
    dataFirst: null,
    dataFirstProcessed: null,
    nameFirst: "",
    dataSecond: null,
    nameSecond: "",
    dataSecondProcessed: null,
  };

  handleFirstFiles = selectorFiles => {
    this.setState({
      dataFirstProcessed: null,
    });
    const file = selectorFiles[0];
    Papa.parse(file, {
      header: true,
      complete: results => {
        this.setState(
          {
            dataFirst: results.data,
            nameFirst: `Selected File: ${file.name}`,
          },
          () => {
            this.addressesWallets();
          }
        );
      },
    });
  };

  handleSecondFiles = selectorFiles => {
    this.setState({
      dataSecondProcessed: null,
    });
    const file = selectorFiles[0];
    Papa.parse(file, {
      header: true,
      complete: results => {
        this.setState(
          {
            dataSecond: results.data,
            nameSecond: `Selected File: ${file.name}`,
          },
          () => {
            this.transactionsSatoshiAddresses();
          }
        );
      },
    });
  };

  download = data => {
    if (data === null) {
      return null;
    }
    if (data === "error") {
      return (
        <h3 style={{ fontWeight: "bold", color: "red" }}>
          {" "}
          Sorry, something was wrong with that file. Please check the column headers and try again.
        </h3>
      );
    }
    let csv = Papa.unparse(data);

    // The encodeURIComponent wrapper around the csv object was necessary for firefox compatibility
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    }
    return (
      <a href={csv} download="subpoena-output.csv">
        {" "}
        Download Output{" "}
      </a>
    );
  };

  addressesWallets = async () => {
    const apiData = { addresses: [] };
    const apiString = `/api/v2/${this.props.currency}/subpoena-support/address-wallets`;
    const arr = [];

    // Create an array with the addresses from the csv
    try {
      this.state.dataFirst.map(item => {
        if (item.address.length > 0) {
          arr.push(item.address);
        }
        return null;
      });
    } catch (e) {
      this.setState({
        dataFirstProcessed: "error",
        dataFirst: null,
        nameFirst: "",
      });
      return;
    }

    apiData.addresses = arr;
    let returnData;
    let groupedByHundred = [];
    const finalResults = { wallets: [] };

    // Break up the api requests into queries of 100 entries at a time, per the DB limitations.
    for (let i = 0; i < apiData.addresses.length; i += 100) {
      let endOfSection = i + 100;
      if (endOfSection > apiData.addresses.length) {
        endOfSection = apiData.addresses.length;
      }
      const currentHundred = apiData.addresses.slice(i, endOfSection);
      const currentData = { addresses: currentHundred };
      groupedByHundred = groupedByHundred.concat(currentData);
    }

    // Make the api requests simultaneously and add the results to the final dictionary
    await Promise.all(
      groupedByHundred.map(async item => {
        try {
          returnData = await axios.post(apiString, item);
          finalResults.wallets = finalResults.wallets.concat(returnData.data.wallets);
        } catch (e) {
          this.setState({
            dataFirstProcessed: "error",
            dataFirst: null,
            nameFirst: "",
          });
          throw e;
        }
      })
    );

    // Add the aggregate of all query output to the local state
    this.setState({ dataFirstProcessed: finalResults.wallets });
  };

  transactionsSatoshiAddresses = async () => {
    const apiData = { transactions: [] };
    const apiString = `/api/v2/${this.props.currency}/subpoena-support/transaction-addresses`;
    const arr = [];

    // Create an array with the addresses from the csv
    try {
      this.state.dataSecond.map(item => {
        if (item.transaction.length > 0) {
          arr.push({
            transactionHash: item.transaction,
            satoshi: Math.round(item.bitcoin * 100000000),
          });
        }
        return null;
      });
    } catch (e) {
      this.setState({
        dataSecondProcessed: "error",
        dataSecond: null,
        nameSecond: "",
      });
      return;
    }

    apiData.transactions = arr;
    let returnData;
    let groupedByHundred = [];
    const finalResults = { transactions: [] };

    // Break up the api requests into queries of 100 entries at a time, per the DB limitations.
    for (let i = 0; i < apiData.transactions.length; i += 100) {
      let endOfSection = i + 100;
      if (endOfSection > apiData.transactions.length) {
        endOfSection = apiData.transactions.length;
      }
      const currentHundred = apiData.transactions.slice(i, endOfSection);
      const currentData = { transactions: currentHundred };
      groupedByHundred = groupedByHundred.concat(currentData);
    }

    // Make the api requests simultaneously and add the results to the final dictionary
    await Promise.all(
      groupedByHundred.map(async item => {
        try {
          returnData = await axios.post(apiString, item);
          const transactions = returnData.data.transactions.map(transaction => {
            const { transactionHash, satoshi, addresses, wallets } = transaction;

            return {
              transaction: transactionHash,
              bitcoin: satoshiToBitcoin(satoshi),
              addresses,
              wallets,
            };
          });
          finalResults.transactions = finalResults.transactions.concat(transactions);
        } catch (e) {
          this.setState({
            dataSecondProcessed: "error",
            dataSecond: null,
            nameSecond: "",
          });
          throw e;
        }
      })
    );

    // Add the aggregate of all query output to the local state
    this.setState({ dataSecondProcessed: finalResults.transactions });
  };

  render() {
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null,
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    return (
      <div style={{ overflowX: "hidden" }}>
        <Helmet>
          <title>Subpoena Support</title>
        </Helmet>
        <div className="row">
          <div className="col-lg-3" />
          <div className="col-lg-6 ">
            <div className="row">
              <h1 className="subpoenaHeader"> Subpoena Support </h1>
            </div>
            <div className="row">
              <h1>{`Addresses to  ${clientModeConfigObject.cap_plural_for_collection}`}</h1>
              <p>
                Upload a csv file that has only one column. The column header should be the word
                <strong> address </strong>
                in lowercase letters. Then, each entry in the column should be an the address whose
                wallet you are interested in.
              </p>
              <p>
                <strong>Note:</strong>
                After file selection, a download button will appear once the new file is generated.
              </p>
              <img alt="" src={AddressesToWallets} style={{ maxWidth: "1000px" }} />
              <div className="row text-center">
                <div className="col-lg-3" />
                <div className="col-lg-6">
                  <div className="row">
                    <label htmlFor="upload1" className="upload">
                      Select a File
                      <input
                        id="upload1"
                        type="file"
                        accept=".csv"
                        onChange={e => this.handleFirstFiles(e.target.files)}
                        onClick={e => {
                          e.target.value = null;
                        }}
                      />
                    </label>
                  </div>
                  <div className="row">{this.state.nameFirst}</div>
                  <div className="row">{this.download(this.state.dataFirstProcessed)}</div>
                </div>
                <div className="col-lg-3" />
              </div>
            </div>
            <div className="row">
              <h1>
                {" "}
                Transactions and Bitcoin Amounts to Deposit Address/
                {clientModeConfigObject.cap_plural_for_collection}
              </h1>
              <p>
                Upload a csv file that has two columns. The first column header should be labeled
                <strong> transaction</strong>, while the second should be labeled
                <strong> bitcoin</strong>- both all lowercase. Each row should contain the
                transaction hash and Bitcoin amount of the deposit address you are interested in.
              </p>
              <p>
                <strong>Note:</strong>
                After file selection, a download button will appear once the new file is generated.
              </p>
              <img alt="" src={TransactionsToDeposit} style={{ maxWidth: "1000px" }} />
              <div className="row text-center">
                <div className="col-lg-3" />
                <div className="col-lg-6">
                  <div className="row">
                    <label htmlFor="upload2" className="upload">
                      Select a File
                      <input
                        id="upload2"
                        type="file"
                        accept=".csv"
                        onChange={e => this.handleSecondFiles(e.target.files)}
                        onClick={e => {
                          e.target.value = null;
                        }}
                      />
                    </label>
                  </div>
                  <div className="row">{this.state.nameSecond}</div>
                  <div className="row">{this.download(this.state.dataSecondProcessed)}</div>
                </div>
                <div className="col-lg-3" />
              </div>
            </div>
          </div>
          <div className="col-lg-3" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currency: getCurrency(state),
    clientMode: getClientMode(state),
  };
};

export default connect(
  mapStateToProps,
  null
)(SubpoenaSupport);
