/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";
import { Button, ListGroupItem } from "react-bootstrap";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { FakeLinkSpan } from "../../styled";
import { cytoscapeShape, reactRouterMatchShape } from "../../../prop-types";
import {
  addAddressToGraphInExplorer,
  addWalletToGraph,
  openAddressModal
} from "../../../actions/graph";
import { getWalletSetMemo } from "../../../selectors/graph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faLocationArrow, faPlus } from "@fortawesome/free-solid-svg-icons/index";
import CopyText from "../../CopyText";

const AddressSearchRow = ({
  primaryTag,
  walletId,
  cy,
  address,
  anchorAddress,
  wallets,
  addWalletToGraphCallback,
  className,
  handleOpenAddressModal,
  match: {
    params: { graphId }
  },
  category,
  selectElementFromSearchResult,
  disabled = false
}) => {
  const addWalletToGraph_ = () => {
    // The line below unselects all elements in the graph when a new one is added
    cy.elements().unselect();

    addWalletToGraphCallback(address, graphId, cy);
  };

  return (
    <ListGroupItem className={className}>
      <div
        className="row"
        style={{
          margin: "0",
          fontFamily: "Quicksand",
          fontWeight: "700",
          fontSize: "13px",
          paddingBottom: "5px"
        }}
      >
        <span style={{ borderBottom: "1px solid var(--graph-sidebar-base)", paddingBottom: "2px" }}>
          Address{" "}
          <span style={{ fontWeight: "500", paddingLeft: "5px" }}>
            {" "}
            ({primaryTag || anchorAddress || walletId || address}){" "}
          </span>
        </span>
      </div>
      <div
        className="row"
        style={{
          display: "flex",
          paddingBottom: "0",
          margin: "0"
        }}
      >
        <div
          className="col-lg-9"
          style={{
            overflow: "auto",
            wordWrap: "break-word",
            paddingLeft: "0",
            paddingRight: "0",
            paddingBottom: "0",
            marginLeft: "0",
            marginRight: "0",
            paddingTop: "5px"
          }}
        >
          <CopyText text={address.split(":").pop()} />
          <FakeLinkSpan
            onClick={() => handleOpenAddressModal(address)}
            className="graphSearchTagNameSmall"
          >
            {address}
          </FakeLinkSpan>
        </div>
        <div
          className="col-lg-3"
          style={{
            paddingLeft: "0",
            paddingRight: "0",
            paddingBottom: "0",
            marginLeft: "5px",
            marginRight: "0"
          }}
        >
          {!wallets.has(walletId.toString()) ? (
            <Button
              className="pull-right allPurpleButton"
              bsSize="xsmall"
              onClick={addWalletToGraph_}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: "3px" }} />
              Add to Graph
            </Button>
          ) : (
            <>
              <Button
                className="pull-right greenButtonGraph"
                bsSize="xsmall"
                onClick={addWalletToGraph_}
                disabled={disabled}
              >
                <FontAwesomeIcon icon={faCheck} style={{ marginRight: "3px" }} /> Added
              </Button>
              <Button
                className="pull-right locateButtonGraph"
                bsSize="xsmall"
                onClick={() => selectElementFromSearchResult(walletId)}
              >
                <FontAwesomeIcon icon={faLocationArrow} /> Locate
              </Button>
            </>
          )}
        </div>
      </div>
    </ListGroupItem>
  );
};

const StyledAddressSearchRow = styled(AddressSearchRow)`
  font-family: Monospace;
`;

AddressSearchRow.propTypes = {
  primaryTag: PropTypes.string,
  walletId: PropTypes.number.isRequired,
  cy: cytoscapeShape.isRequired,
  address: PropTypes.string,
  wallets: PropTypes.instanceOf(Set).isRequired,
  addWalletToGraphCallback: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  handleOpenAddressModal: PropTypes.func.isRequired,
  match: reactRouterMatchShape({ graphId: PropTypes.string.isRequired }).isRequired,
  category: PropTypes.string,
  disabled: PropTypes.bool
};

AddressSearchRow.defaultProps = {
  address: null,
  primaryTag: null,
  category: null
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { graphId }
    }
  }
) => {
  return {
    wallets: getWalletSetMemo(state, Number.parseInt(graphId, 10))
  };
};

const mapDispatchToProps = dispatch => ({
  handleOpenAddressModal: address => dispatch(openAddressModal(address)),
  addWalletToGraphCallback: (address, graphId, cy) =>
    dispatch(addAddressToGraphInExplorer(address, graphId, cy))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StyledAddressSearchRow)
);
