/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretRight,
  faHourglass,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { getRegistrationTimestamp, getRole } from "../selectors/authentication";

const TrialCountdown = ({ registrationTimestamp }) => {
  const [timeLeft, setTimeleft] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeleft(calculateTimeLeft());
    }, 60000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    setTimeleft(calculateTimeLeft());
  }, [registrationTimestamp]);

  const calculateTimeLeft = () => {
    setTimeleft(registrationTimestamp);
    let difference = Date.parse(new Date()) - new Date(Math.floor(registrationTimestamp * 1000));
    return {
      days: 89 - Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: ("00" + (23 - Math.floor((difference / (1000 * 60 * 60)) % 24))).slice(-2),
      minutes: ("00" + (59 - Math.floor((difference / 1000 / 60) % 60))).slice(-2),
      // seconds: ('00' + (59 - Math.floor((difference / 1000) % 60))).slice(-2)
    };
  };

  return (
    <div className="registrationCountdown">
      <p
        style={{
          fontSize: "20px",
          fontFamily: "Quicksand",
          fontWeight: "600",
          color: "lightcoral",
          margin: "0",
        }}
      >
        <FontAwesomeIcon icon={faHourglass} style={{ fontSize: "16px", marginBottom: "1px" }} />{" "}
        Trial Ends in:{" "}
      </p>
      <div style={{ flexBasis: "100%", height: 0 }} />
      <p style={{ marginBottom: "0", color: "#666", fontSize: "14px" }}>
        {" "}
        {timeLeft["days"]} Days {timeLeft["hours"]} Hours {timeLeft["minutes"]} Minutes
      </p>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    registrationTimestamp: getRegistrationTimestamp(state),
  };
};

export default connect(
  mapStateToProps,
  null
)(TrialCountdown);
