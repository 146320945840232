/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup
} from "react-bootstrap";

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { addNodeNoteChangeToUndoStack, saveGraph } from "../../actions/graph";
import { cytoscapeNodeShape } from "../../prop-types";
import NoteEditor from "../NoteEditor";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import axios, { NOTES_API } from "../../api";
import { getFeature } from "../../selectors/features";
import { getRole } from "../../selectors/authentication";
import { getCurrency } from "../../selectors/currency";
import { getClientMode } from "../../selectors/applicationConfig";

class WalletNoteSetter extends Component {
  state = {
    notificationVisible: false,
    walletNoteEditorState: null
  };

  componentDidMount() {
    const wallet = this.props.wallet;
    this.setNoteValueToWallets(wallet);
  }

  componentDidUpdate(prevProps) {
    const oldWallet = prevProps.wallet;
    const newWallet = this.props.wallet;
    if (oldWallet !== newWallet) {
      this.setNoteValueToWallets(newWallet);
    }
  }

  setNoteValueToWallets = async wallet => {
    try {
      if (wallet.data("notes")) {
        try {
          this.setState({
            walletNoteEditorState: EditorState.createWithContent(
              convertFromRaw(wallet.data("notes"))
            )
          });
        } catch (err) {
          console.log(err);
          if (typeof wallet.data("notes") === "string") {
            this.setState({
              walletNoteEditorState: EditorState.createWithContent(
                wallet.data("notes")
              )
            });
          }
        }
      } else {
        this.setState({ walletNoteEditorState: EditorState.createEmpty() });
      }
    } catch (err) {
      throw err;
    }
  };

  handleChangeNoteFormSubmit = e => {
    e.preventDefault();
    const wallet = this.props.wallet;
    const note = JSON.stringify(
      convertToRaw(this.state.walletNoteEditorState.getCurrentContent())
    );
    const cy = wallet.cy();
    this.props.setNote(note, cy);

    if (wallet.note) {
      wallet.note.destroy();
    }
    this.setState({ notificationVisible: true });
  };

  clearNote = e => {
    e.preventDefault();
    const wallet = this.props.wallet;
    wallet.data({ notes: "" });

    // this.note.value = "";

    if (wallet.note) {
      wallet.note.destroy();
    }

    this.setState({ notificationVisible: false });
    this.props.saveGraph(this.props.graphId, this.props.cy);
  };

  onWalletEdit = walletNoteEditorState =>
    this.setState({ walletNoteEditorState });

  handleNoteSave = async (message = false) => {
    const wallet = this.props.wallet;
    const walletId = wallet.data("id");
    const walletLocation = "graph";
    const walletLocationId = this.props.graphId;
    const walletNote = JSON.stringify(
      convertToRaw(this.state.walletNoteEditorState.getCurrentContent())
    );

    try {
      if (wallet.data("type") === "default") {
        await axios.put(
          `${NOTES_API(this.props.currency)}/wallet/${walletId}`,
          {
            walletLocation,
            walletLocationId,
            walletNote
          }
        );
      } else if (wallet.data("type") === "transaction") {
        await axios.put(
          `${NOTES_API(this.props.currency)}/transaction/${walletId}`,
          {
            transactionLocation: walletLocation,
            transactionLocationId: walletLocationId,
            transactionNote: walletNote
          }
        );
      }

      this.props.setNote(
        JSON.stringify(
          convertToRaw(this.state.walletNoteEditorState.getCurrentContent())
        ),
        cy
      );

      if (wallet.note) {
        wallet.note.destroy();
      }
      this.setState({ notificationVisible: false });
      this.props.saveGraph(this.props.graphId, this.props.cy);
    } catch (err) {
      window.scrollTo(0, 0);
      throw err;
    }
  };

  handleNoteTransactionSave = async (message = false) => {
    const wallet = this.props.wallet;

    const [fromWalletId, toWalletId] = wallet.id().split("+");
    const graphTransactionLocation = "graph";
    const graphTransactionLocationId = this.props.graphId;
    const graphTransactionNote = JSON.stringify(
      convertToRaw(this.state.walletNoteEditorState.getCurrentContent())
    );
    const sourceType = this.props.cy.getElementById(fromWalletId).data()[
      "type"
    ];
    const dstType = this.props.cy.getElementById(toWalletId).data()["type"];

    try {
      await axios.put(
        `${NOTES_API(
          this.props.currency
        )}/graphTransaction/${fromWalletId.toString()}/${toWalletId.toString()}`,
        {
          graphTransactionLocation,
          graphTransactionLocationId,
          graphTransactionNote,
          sourceType,
          dstType
        }
      );
      this.props.setNote(
        JSON.stringify(
          convertToRaw(this.state.walletNoteEditorState.getCurrentContent())
        ),
        cy
      );

      if (wallet.note) {
        wallet.note.destroy();
      }
      this.setState({ notificationVisible: false });
      this.props.saveGraph(this.props.graphId, this.props.cy);
    } catch (err) {
      window.scrollTo(0, 0);
      throw err;
    }
  };

  render() {
    return (
      <div>
        {!this.props.wallet.isNode() ? (
          <NoteEditor
            height={300}
            editorState={this.state.walletNoteEditorState}
            onEdit={this.onWalletEdit}
            saveGraph={this.handleNoteTransactionSave}
            removeInfo={true}
          />
        ) : (
          <NoteEditor
            height={300}
            editorState={this.state.walletNoteEditorState}
            onEdit={this.onWalletEdit}
            saveGraph={this.handleNoteSave}
            removeInfo={true}
          />
        )}
        <Button
          className={
            this.props.fromModal ? "allPurpleButton" : "control-whiteButton"
          }
          onClick={this.handleChangeNoteFormSubmit}
          type="submit"
        >
          <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faSave} />
          Save
        </Button>
        <Button
          style={{
            fontFamily: "QuickSand",
            fontWeight: "700",
            backgroundColor: "#9f1d35",
            color: "white",
            border: "2px solid #9f1d35",
            marginRight: "5px",
            marginLeft: "5px"
          }}
          onClick={this.clearNote}
          type="submit"
        >
          <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faTrash} />
          Delete Note
        </Button>
        <br /> <br />
        <span
          style={{
            visibility: this.state.notificationVisible ? "visible" : "hidden"
          }}
          className={
            this.state.fromSidebar ? "sidebarNotification" : "modalNotification"
          }
        >
          <>
            {" "}
            <FontAwesomeIcon icon={faCheck} /> {" Note Saved !"}{" "}
          </>
        </span>
      </div>
    );
  }
}

const StyledWalletNoteSetter = styled(WalletNoteSetter)`
  width: 100px;
  height: 100px;
`;

WalletNoteSetter.defaultProps = {
  fromModal: false
};

const mapStateToProps = state => {
  return {
    currency: getCurrency(state)
  };
};
const mapDispatchToProps = (dispatch, { wallet }) => {
  return {
    setNote: (notes, cy) => {
      dispatch(addNodeNoteChangeToUndoStack(wallet, notes, cy));
    },
    saveGraph: (graphId, cy) => dispatch(saveGraph(graphId, cy))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledWalletNoteSetter);

WalletNoteSetter.propTypes = {
  wallet: cytoscapeNodeShape.isRequired,
  className: PropTypes.string.isRequired,
  setNote: PropTypes.func.isRequired
};
