/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Button, DropdownButton, MenuItem, Table } from "react-bootstrap";
import { ScrollDiv } from "../styled";
import { getModalTransactionLink } from "../Wallet/helpers";
import { cytoscapeNodeShape } from "../../prop-types";
import { satoshiToBitcoin, satoshiToBitcoinNoCommas } from "../../helpers";
import "../../../css/styles.css";
import { getWalletMutualTransactionsData } from "../../selectors/graph";
import {
  openTransactionModal,
  setMutualTransactionsOrder,
  setOutputWalletId,
} from "../../actions/graph";
import { fetchMutualTransactions } from "../../actions/wallet";
import { faFilter } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import { getClientMode } from "../../selectors/applicationConfig";
import ClientModeConfig from "../ClientModeConfig";
import CopyText from "../CopyText";
import DataDownload from "../DataDownload";
import CurrencyChooserConfig from "../CurrencyChooserConfig";
import { getCurrency } from "../../selectors/currency";

class WalletMutualTransactions extends Component {
  getWallets = () => {
    return this.props.cy.nodes();
  };

  toggleAgeOrder = () => {
    if (this.props.mutualTransactionsOrder === "newestFirst") {
      this.props.setMutualTransactionsOrder("oldestFirst");
    } else {
      this.props.setMutualTransactionsOrder("newestFirst");
    }
  };

  toggleAmountOrder = () => {
    if (this.props.mutualTransactionsOrder === "largestFirst") {
      this.props.setMutualTransactionsOrder("smallestFirst");
    } else {
      this.props.setMutualTransactionsOrder("largestFirst");
    }
  };

  getDropDownLabel = () => {
    if (this.props.outputWalletId == null) {
      return "Select Entity";
    }
    const outputWallet = this.props.cy.getElementById(this.props.outputWalletId);

    // This seems to only show up in production...
    if (outputWallet == null || outputWallet.data() == null) {
      return "Select Entity";
    }
    return outputWallet.data().label;
  };

  renderComparison = () => {
    // This is a hack, I think we need to make sure to clear inputWallet and outputWalletId
    // when this component unmounts or something.
    if (this.props.inputWallet == null || this.props.inputWallet.data() == null) {
      return null;
    }

    if (this.props.outputWalletId == null) {
      return null;
    }

    const { transactions } = this.props;
    const inputWalletLabel = this.props.inputWallet.data().label;

    const outputWallet = this.props.cy.getElementById(this.props.outputWalletId);

    if (outputWallet == null || outputWallet.data() == null) {
      return null;
    }

    const outputWalletLabel = this.props.cy.getElementById(this.props.outputWalletId).data().label;

    /*  This isn't really needed unt
    if (transactions.length === 0) {
      return (
        <div style={{ textAlign: 'center' }}>
          <br />
          <br />
          <br />
          <br />
          <p style={{fontWeight: '600'}}>
            {`There are No Transactions from ${inputWalletLabel} to ${outputWalletLabel}.`}
          </p>
        </div>
      );
    }*/

    return (
      <ScrollDiv
        maxHeight={this.props.maxHeight}
        style={{ margin: "0", borderRadius: "0", paddingBottom: "0" }}
      >
        <Table className="walletStatsTable purple" style={{ width: "100%", tableLayout: "fixed" }}>
          <thead>
            <tr>
              <th style={{ width: "15%" }}>Timestamp</th>
              <th style={{ width: "65%" }}>Transaction</th>
              <th style={{ width: "20%" }}>Balance</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map(item => (
              <tr key={item.transactionHash}>
                <td>
                  {moment
                    .unix(item.timestamp)
                    .utc()
                    .format("MM/DD/YY")}
                </td>
                <td style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                  <CopyText text={item.transactionHash} marginLeft="0" />
                  {getModalTransactionLink(
                    item.transactionHash,
                    this.props.handleOpenTransactionModal
                  )}
                </td>
                <td>{satoshiToBitcoin(item.satoshi)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollDiv>
    );
  };

  render() {
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null,
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    let currencyObject = CurrencyChooserConfig[this.props.currency];

    const inputWalletId = this.props.inputWallet.data().id;
    const { outputWalletId, maxHeight } = this.props;

    let edgeBitcoin;
    if (inputWalletId != null && outputWalletId != null) {
      const edgeId = `${inputWalletId}+${outputWalletId}`;

      const edge = this.props.cy.getElementById(edgeId);
      if (edge.length !== 0) {
        edgeBitcoin = satoshiToBitcoin(edge.data().satoshi);
      }
    }

    let order;
    if (this.props.mutualTransactionsOrder === "largestFirst") {
      order = "Sorted by Largest.";
    } else if (this.props.mutualTransactionsOrder === "smallestFirst") {
      order = "Sorted by Smallest.";
    } else if (this.props.mutualTransactionsOrder === "newestFirst") {
      order = "Sorted by Newest.";
    } else {
      order = "Sorted by Oldest.";
    }

    let d;
    if (outputWalletId == null) {
      d = (
        <p style={{ fontSize: "12px" }}>
          Please Select an Edge from the Graph to See the Transactions Between Two{" "}
          {clientModeConfigObject.cap_plural_for_collection}.
        </p>
      );
    } else {
      d = (
        <>
          <div style={{ textAlign: "left", marginLeft: "0" }}>
            <p style={{ fontSize: "16px", paddingTop: "0" }}>
              Transactions from{" "}
              <span style={{ fontWeight: "700" }}>{this.props.inputWallet.data().label}</span> to{" "}
              <span style={{ fontWeight: "700" }}>{this.getDropDownLabel()}</span>{" "}
            </p>
            <p style={{ fontSize: "12px" }}>
              {" "}
              Total amount sent is{" "}
              <span style={{ fontWeight: "700", fontSize: "15px" }}>
                {edgeBitcoin} {currencyObject.abb}{" "}
              </span>
              .{" "}
            </p>
          </div>
          <div
            className="purpleButtonFilter"
            style={{
              marginLeft: "0",
              paddingBottom: "10px",
              textAlign: "left",
            }}
          >
            <DropdownButton
              className="whiteButton"
              title={
                <>
                  {" "}
                  <FontAwesomeIcon icon={faFilter} /> Filter{" "}
                </>
              }
            >
              <MenuItem
                eventKey="new"
                className="purpleFilterItem"
                onSelect={() => {
                  this.toggleAmountOrder();
                  this.props.fetchMutualTransactions(
                    inputWalletId,
                    this.props.outputWalletId,
                    true
                  );
                }}
              >
                Sort by{" "}
                {this.props.mutualTransactionsOrder === "largestFirst" ? "Smallest" : "Largest"}
              </MenuItem>
              <MenuItem
                className="purpleFilterItem"
                eventKey="new"
                onSelect={() => {
                  this.toggleAgeOrder();
                  this.props.fetchMutualTransactions(
                    inputWalletId,
                    this.props.outputWalletId,
                    true
                  );
                }}
              >
                Sort by {this.props.mutualTransactionsOrder === "newestFirst" ? "Oldest" : "Newest"}
              </MenuItem>
            </DropdownButton>
            <span
              style={{
                backgroundColor: "white",
                borderRadius: "7px",
                color: "var(--graph-sidebar-base)",
                fontWeight: "600",
                fontSize: "10px",
                fontFamily: "Quicksand",
                padding: "2px 5px 2px 5px",
                marginLeft: "10px",
              }}
            >
              {" "}
              {order}{" "}
            </span>
            {outputWalletId && (
              <Button
                className="whiteButton"
                style={{ float: "right" }}
                onClick={() => {
                  this.props.fetchMutualTransactions(
                    inputWalletId,
                    this.props.outputWalletId,
                    false
                  );
                }}
                disabled={!this.props.hasNext}
              >
                Load More ...
              </Button>
            )}
            <div style={{ float: "right" }}>
              <DataDownload
                data={this.props.transactions.map(item => {
                  return {
                    "Transaction Hash": item.transactionHash,
                    Timestamp: moment
                      .unix(item.timestamp)
                      .utc()
                      .format("LLL"),
                    "Transaction Balance (Bitcoin)": satoshiToBitcoin(item.satoshi),
                    "Transaction Balance (USD)": item.priceAvailable
                      ? "$" + (satoshiToBitcoinNoCommas(item.satoshi) * item.price).toFixed(2)
                      : "N/A",
                    "USD Price": item.priceAvailable ? "$" + item.price.toFixed(2) : "N/A",
                  };
                })}
                entity_type="transaction"
                style={{ float: "right", margin: "10px" }}
              />
            </div>
          </div>
        </>
      );
    }

    return (
      <div style={{ textAlign: "center", margin: "0" }}>
        {d}

        {this.renderComparison()}
      </div>
    );
  }
}

WalletMutualTransactions.propTypes = {
  inputWallet: cytoscapeNodeShape.isRequired,
  maxHeight: PropTypes.number.isRequired,
  // We could just keep the same one across calls?
  outputWalletId: PropTypes.string,
  fetchMutualTransactions: PropTypes.func.isRequired,
  setMutualTransactionsOrder: PropTypes.func.isRequired,
  setOutputWalletId: PropTypes.func.isRequired,
  handleOpenTransactionModal: PropTypes.func.isRequired,
  // We need to add outputWalletId and then find it from cytoscape.
  cy: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      satoshi: PropTypes.number.isRequired,
      timestamp: PropTypes.number.isRequired,
      transactionHash: PropTypes.string.isRequired,
      transactionId: PropTypes.number.isRequired,
    })
  ).isRequired,
  hasNext: PropTypes.bool,
  mutualTransactionsOrder: PropTypes.string.isRequired,
};

WalletMutualTransactions.defaultProps = {
  outputWalletId: null,
  hasNext: false,
};

// need to get next keys as well.
const mapStateToProps = (state, props) => {
  const inputWalletId = props.inputWallet.data().id;
  return {
    ...getWalletMutualTransactionsData(state, inputWalletId),
    clientMode: getClientMode(state),
    currency: getCurrency(state),
  };
};

const mapDispatchToProps = dispatch => ({
  setOutputWalletId: outputWalletId => dispatch(setOutputWalletId(outputWalletId)),
  handleOpenTransactionModal: transaction => dispatch(openTransactionModal(transaction)),
  fetchMutualTransactions: (inputWalletId, outputWalletId, initial) => {
    dispatch(fetchMutualTransactions(inputWalletId, outputWalletId, initial));
  },
  setMutualTransactionsOrder: order => dispatch(setMutualTransactionsOrder(order)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletMutualTransactions);
