import React from "react";
import { getCurrency } from "../../../selectors/currency";
import {
  getLastSavedTimestampForGraphId,
  getMainTabsInfo,
  getModalEntities
} from "../../../selectors/graph";
import { getFeature } from "../../../selectors/features";
import { getClientMode } from "../../../selectors/applicationConfig";
import {
  getGraphSearchCurrentQuery,
  getGraphSearchLoading,
  getGraphSearchResults
} from "../../../selectors/ethereum/graph";
import {
  addNodeFromSearchResult,
  search
} from "../../../actions/ethereum/graph";
import { connect } from "react-redux";
import { Button, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faCog,
  faPlus,
  faSearch,
  faWallet
} from "@fortawesome/free-solid-svg-icons";
import WalletTagSetter from "../../Graph/WalletTagSetter";
import WalletNoteSetter from "../../Graph/WalletNoteSetter";
import ColorPicker from "../../Graph/ColorPicker";
import { changeMainTabsKey, saveGraph } from "../../../actions/graph";
import ModalWrapper from "../../Graph/ModalWrapper";
import { EtherAddressWithStateBasedTabs } from "../Address";
import { EtherTransactionWithStateBasedTabs } from "../Transaction";
import { AddressView } from "./AddressView";
import { EdgeView } from "./EdgeView";
import { GraphSearch } from "./GraphSearch";
import Control from "../../Graph/Control";
import Modal from "react-bootstrap/lib/Modal";
import { MODAL_MENUS } from "../../Graph/interfaces";
import { GraphChangeTitle } from "../../Graph/GraphChangeTitle";

const EthereumSidebar = ({
  currentNode,
  showGraphData,
  toggleGraphData,
  graphId,
  entity,
  entityType,
  caseNumber,
  description,
  activeMenuOption,
  toggleMenuOption,
  focusedWallet,
  addressKey,
  addToGraph,
  search,
  cy,
  lastSaved,
  ...props
}) => {
  const pickModal = entityType => {
    switch (entityType) {
      case "address":
        return <EtherAddressWithStateBasedTabs address={entity} />;
      case "transaction":
        return (
          <EtherTransactionWithStateBasedTabs transaction={entity.toString()} />
        );
    }
  };
  const getWalletTab = (wallet_or_entity, modal = false) => {
    if (addressKey === "AddressView") {
      return (
        <AddressView
          address={currentNode.id()}
          name={currentNode.data("label")}
          stateBased={true}
          addToGraph={addressId => addToGraph(addressId, graphId, cy)}
        />
      );
    }
    if (addressKey === "EdgeView") {
      return (
        <EdgeView
          source={currentNode.data("source")}
          target={currentNode.data("target")}
          targetLabel={currentNode.data("label")}
          stateBased={true}
        />
      );
    }
    if (addressKey === "EdgeView") {
      return (
        <EdgeView
          source={currentNode.data("source")}
          target={currentNode.data("target")}
          targetLabel={currentNode.data("label")}
          stateBased={true}
        />
      );
    }

    if (addressKey === "WalletTagSetter") {
      return (
        <>
          <GraphChangeTitle
            selectedEntity={currentNode}
            menuName={addressKey}
            cy={cy}
          />
          <WalletTagSetter
            wallet={currentNode}
            cy={cy}
            currency={"ethereum"}
            fromModal={modal}
          />
        </>
      );
    }

    if (addressKey === "WalletNoteSetter") {
      return (
        <>
          <GraphChangeTitle
            selectedEntity={currentNode}
            menuName={addressKey}
            cy={cy}
          />
          <WalletNoteSetter
            wallet={currentNode}
            cy={cy}
            graphId={graphId.graphId}
            fromModal={modal}
          />
        </>
      );
    }

    if (addressKey === "WalletColorPicker") {
      const colorPickerStyle = currentNode.isNode()
        ? "background-color"
        : "line-color";
      return (
        <>
          <GraphChangeTitle
            selectedEntity={currentNode}
            menuName={addressKey}
            cy={cy}
          />
          <ColorPicker item={currentNode} style={colorPickerStyle} />
        </>
      );
    }

    return (
      <p style={{ fontSize: "12px", paddingLeft: "25px", fontWeight: "600" }}>
        Please Select an {wallet_or_entity} in the Graph for a Detailed View.
      </p>
    );
  };

  return (
    <>
      <ModalWrapper handleCloseModal={() => {}}>
        {pickModal(entityType)}
      </ModalWrapper>
      {showGraphData ? (
        <Col
          className="sidebar"
          lg={5}
          style={{
            background: "var(--graph-sidebar-base)",
            overflowY: "auto",
            overflowX: "hidden",
            paddingLeft: "0",
            paddingRight: "0",
            height: "100%",
            zIndex: "10000"
          }}
        >
          <div
            className="row topRow"
            style={{
              display: "flex",
              margin: "0",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingLeft: "10px",
              width: "100%",
              borderBottom: "1px solid white"
            }}
          >
            <div className="col-lg-9">
              <div
                style={{
                  width: "100%",
                  marginBottom: "15px"
                }}
              >
                <p
                  className="graphDataHeader"
                  style={{ cursor: "pointer", color: "white" }}
                  //onDoubleClick={this.handleOpenChangeCaseNumberModal}
                >
                  {caseNumber || "Add case number"}
                </p>
                <p
                  className="graphDataSubheader"
                  style={{ cursor: "pointer", color: "white" }}
                  //onDoubleClick={this.handleOpenChangeTitleModal}
                >
                  {description || "Add description"}
                </p>
              </div>
            </div>
            <div
              className="col-lg-3"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Button
                className="collapseButton"
                onClick={() => toggleGraphData()}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Collapse
              </Button>
            </div>
          </div>
          <div
            className={
              activeMenuOption === "add"
                ? "sidebarOption active"
                : "sidebarOption"
            }
            style={{ paddingRight: "25px", paddingLeft: "25px" }}
          >
            <p className="optionHeader" onClick={() => toggleMenuOption("add")}>
              {" "}
              <FontAwesomeIcon
                icon={faPlus}
                style={{ paddingRight: "5px" }}
              />{" "}
              Add New Entity
            </p>
            <div className="activeOptionMainDiv" style={{ margin: "0" }}>
              <GraphSearch
                addToGraph={addToGraph}
                search={search}
                graphId={graphId}
                cy={cy}
                sidebar
              />
            </div>
          </div>
          <div
            className={
              activeMenuOption === "data"
                ? "sidebarOption active"
                : "sidebarOption"
            }
            style={{ paddingRight: "25px", paddingLeft: "25px" }}
          >
            <p
              className="optionHeader"
              onClick={() => toggleMenuOption("data")}
            >
              {" "}
              <FontAwesomeIcon
                icon={faWallet}
                style={{ paddingRight: "5px" }}
              />{" "}
              Entity Data{" "}
            </p>
            {activeMenuOption === "data" && getWalletTab("Entity")}
          </div>
          <div
            className={
              activeMenuOption === "settings"
                ? "sidebarOption active"
                : "sidebarOption"
            }
            style={{ paddingRight: "25px", paddingLeft: "25px" }}
          >
            <p
              className="optionHeader"
              onClick={() => toggleMenuOption("settings")}
            >
              {" "}
              <FontAwesomeIcon
                icon={faCog}
                style={{ paddingRight: "5px" }}
              />{" "}
              <span>Settings</span>
            </p>
            {activeMenuOption === "settings" && (
              <Control
                cy={cy}
                graphId={graphId}
                saveCallback={async () => {
                  props.saveGraph(graphId, cy);
                }}
                exportCallback={() =>
                  window.open(`/api/v2/ethereum/graph/${graphId}`)
                }
              />
            )}
          </div>
          <p
            style={{
              color: "rgba(255,255,255,0.5)",
              marginLeft: "25px",
              marginTop: "10px"
            }}
          >
            {" "}
            {lastSaved !== "" && lastSaved !== undefined
              ? `Last Saved ${lastSaved}`
              : ""}
          </p>
        </Col>
      ) : (
        <>
          <Col
            lg={1}
            className="sidebar collapsedSideBar"
            style={{ paddingTop: "10px", zIndex: "10000" }}
          >
            <Button className="expandButton" onClick={() => toggleGraphData()}>
              Expand <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </Col>
          {activeMenuOption === "data" && (
            <Modal
              show={MODAL_MENUS.includes(addressKey)}
              onHide={() => {
                toggleMenuOption(null);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title
                  style={{ fontFamily: "Quicksand", fontWeight: "600" }}
                >
                  <GraphChangeTitle
                    menuName={addressKey}
                    cy={cy}
                    selectedEntity={currentNode}
                    color={"black"}
                  />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>{getWalletTab("Entity", true)}</Modal.Body>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

EthereumSidebar.defaultProps = {
  focusedWallet: null,
  addressKey: null,
  editorState: null,
  currentQuery: null,
  modal: "",
  lastSaved: "",
  selectedEdge: null
};

const mapStateToProps = (state, { graphId }) => {
  return {
    currency: getCurrency(state),
    lastSaved: getLastSavedTimestampForGraphId(state, graphId),
    issuesEnabled: getFeature(state, "issues"),
    clientMode: getClientMode(state),
    searchResult: getGraphSearchResults(state, graphId),
    searchLoading: getGraphSearchLoading(state, graphId),
    currentQuery: getGraphSearchCurrentQuery(state, graphId),
    ...getMainTabsInfo(state, graphId),
    ...getModalEntities(state)
  };
};

const mapDispatchToProps = (dispatch, { graphId }) => {
  return {
    changeMainTabsKey: mainTabsKey => dispatch(changeMainTabsKey(mainTabsKey)),
    search: query => dispatch(search(query, graphId)),
    addToGraph: (addressId, graphId, cy) =>
      dispatch(addNodeFromSearchResult(addressId, graphId, cy)),
    saveGraph: (graphId, cy) => dispatch(saveGraph(graphId, cy))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EthereumSidebar);
