/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Button, Form, FormControl, FormGroup, Grid, Row } from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import { fetchAddressResearchRawData, fetchAddressSummary } from "../actions/address";
import { getCurrency } from "../selectors/currency";
import ResearcherResults from "./ResearcherResults";

class Researcher extends Component {
  state = {
    address: null,
  };

  fetchAddress = e => {
    e.preventDefault();

    // fetch the relevant data for the address
    this.props.fetchAddressSummary(this.addressForm.value);
    this.props.fetchAddressResearchRawData(this.addressForm.value);

    // set the address in the local state
    this.setState({ address: this.addressForm.value });
  };

  render() {
    if (this.props.currency === "bitcoin") {
      return (
        <div>
          <Helmet>
            <title>Research</title>
          </Helmet>
          <Grid>
            <Row style={{ textAlign: "center" }}>
              <h1
                style={{
                  color: "var(--base-color)",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                }}
              >
                Researcher Portal
              </h1>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <p
                style={{
                  color: "var(--base-color)",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                }}
              >
                Enter a Bitcoin Address in the field below to see available tag information.
              </p>
            </Row>
            <Row
              style={{
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              <Form onSubmit={this.fetchAddress}>
                <FormGroup>
                  <FormControl
                    type="text"
                    placeholder="Address..."
                    inputRef={ref => {
                      this.addressForm = ref;
                    }}
                    style={{
                      color: "var(--base-color)",
                      fontWeight: "600",
                      fontFamily: "Quicksand, sans-serif",
                      width: "400px",
                      display: "inline-block",
                      marginRight: "15px",
                    }}
                  />
                  <Button type="submit" id="searchSubmitReverse">
                    <FontAwesomeIcon icon={faSearch} /> Search
                  </Button>
                </FormGroup>
              </Form>
            </Row>
            <Row>
              <ResearcherResults currency={this.props.currency} address={this.state.address} />
            </Row>
          </Grid>
        </div>
      );
    }
    return (
      <div>
        <Helmet>
          <title>Research</title>
        </Helmet>
        <Grid>
          <Row style={{ textAlign: "center" }}>
            <h1
              style={{
                color: "var(--base-color)",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              }}
            >
              Researcher Portal
            </h1>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <p
              style={{
                color: "var(--base-color)",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              }}
            >
              This tool is currently only available for Bitcoin.
            </p>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currency: getCurrency(state),
  };
};

const mapDispatchToProps = (dispatch, state) => {
  return {
    fetchAddressSummary: address => dispatch(fetchAddressSummary(address)),
    fetchAddressResearchRawData: address => dispatch(fetchAddressResearchRawData(address)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Researcher);
