/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Grid, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { MonospacedTable } from "../styled";
import { reactRouterMatchShape } from "../../prop-types";
import { getCurrency } from "../../selectors/currency";
import ClientModeConfig from "../ClientModeConfig";
import { getClientMode } from "../../selectors/applicationConfig";
import CopyText from "../CopyText";
import { getSearchResult } from "../../selectors/search";
import { fetchSearchResult } from "../../actions/search";

export const getSearchDisplayName = addressMap => {
  const addHash = addressMap["address"].split(":").pop();
  const displayName =
    addressMap["contract"] || addressMap["tag"] || addressMap["name"];
  return displayName ? `${displayName} (${addHash})` : addHash;
};
/**
 * Displays results of a partial search divided into
 * addresses, transactions, and wallets (by primary tag)
 */
export const SearchResults = ({ match }) => {
  const [key, setKey] = useState("addresses");

  //TODO expand using loading for showing loading symbol
  const [loading, setLoading] = useState(false);
  const { query } = match.params;
  const searchResults = useSelector(state => getSearchResult(state, query));

  const clientMode = useSelector(state => getClientMode(state));
  let clientModeConfigObject = {
    word_for_collection: "",
    cap_word_for_collection: "",
    plural_for_collection: "",
    cap_plural_for_collection: "",
    nav_logo: null,
    login_logo: null,
    login_top_logo: null,
    login_top_icon: null
  };
  if (clientMode in ClientModeConfig) {
    clientModeConfigObject = ClientModeConfig[clientMode];
  }
  const currency = useSelector(state => getCurrency(state));
  const dispatch = useDispatch();

  //when query is sent call api for result
  useEffect(() => {
    const fetchSearch = async () => {
      if (!searchResults) {
        setLoading(true);
        await dispatch(fetchSearchResult(query));
        setLoading(false);
      }
    };
    fetchSearch();
  }, [query]);

  // Loading for first time to set tab
  useEffect(() => {
    if (!loading) {
      setInitialTab();
    }
  }, [loading]);

  /**
   * Returns tab title with number of items in ttab
   * @param {string} name
   * @param {number} length
   * @returns {*}
   */
  const getTabTitle = (name, length) => (
    <React.Fragment>
      {name} <span className="badge progress-bar-info">{length}</span>
    </React.Fragment>
  );

  /**
   * Set initial tab to one with results
   */
  const setInitialTab = () => {
    if (!searchResults) {
      return;
    }
    const {
      results: { addresses, wallets, transactions, custom_wallets }
    } = searchResults;
    if (wallets && wallets.length > 0) {
      setKey("wallets");
    } else if (transactions && transactions.length > 0) {
      setKey("transactions");
    } else if (addresses && addresses.length > 0) {
      setKey("addresses");
    } else if (custom_wallets && custom_wallets.length > 0) {
      setKey("custom wallets");
    }
  };

  const addresses =
    searchResults && searchResults.results.addresses
      ? searchResults.results.addresses.map(addressMap => {
          const addHash = addressMap["address"].split(":").pop();
          return (
            <tr key={addressMap["address"]}>
              <td>
                <CopyText text={addHash} marginLeft="0" />
                <Link
                  to={`/${currency}/address/${addHash}`}
                  style={{
                    fontFamily: "Quicksand",
                    fontWeight: "600",
                    color: "var(--link-colors)"
                  }}
                >
                  {getSearchDisplayName(addressMap)}
                </Link>
              </td>
            </tr>
          );
        })
      : [];

  const transactions =
    searchResults && searchResults.results.transactions
      ? searchResults.results.transactions.map(transaction => (
          <tr key={transaction["transaction_hash"]}>
            <td>
              <CopyText text={transaction["transaction_hash"]} />
              <Link
                to={`/${currency}/transaction/${
                  transaction["transaction_hash"]
                }`}
                style={{
                  fontFamily: "Quicksand",
                  fontWeight: "600",
                  color: "var(--link-colors)"
                }}
              >
                {transaction["transaction_hash"]}
              </Link>
            </td>
          </tr>
        ))
      : [];

  // TODO change address and transactions to follow this pattern, if this way is preferred
  const wallets =
    searchResults && searchResults.results.wallets
      ? searchResults.results.wallets.filter(wallet => !wallet["custom"])
      : [];
  const user_wallets =
    searchResults && searchResults.results.wallets
      ? searchResults.results.wallets.filter(wallet => wallet["custom"])
      : [];

  const customWallets =
    searchResults && searchResults.results.custom_wallets
      ? searchResults.results.custom_wallets.map(wallet => {
          const { walletId, name, addressCount } = wallet;

          return (
            <tr key={walletId}>
              <td>
                <Link to={`/${currency}/custom-wallet/${walletId}`}>
                  {`${name} (${addressCount.toLocaleString()})`}
                </Link>
              </td>
            </tr>
          );
        })
      : [];

  return (
    <div>
      <Helmet>
        <title>{`${query} Results`}</title>
      </Helmet>
      <Grid>
        <Row>
          <Col lg={12}>
            <Tabs
              activeKey={key}
              id="search-tabs"
              onSelect={key => setKey(key)}
              animation={false}
            >
              {addresses.length > 0 && (
                <Tab
                  eventKey="addresses"
                  title={getTabTitle("Addresses", addresses.length)}
                >
                  <br />
                  <MonospacedTable striped hover>
                    <thead>
                      <tr>
                        <th
                          style={{ fontFamily: "Quicksand", fontSize: "14px" }}
                        >
                          Address
                        </th>
                      </tr>
                    </thead>
                    <tbody>{addresses}</tbody>
                  </MonospacedTable>
                </Tab>
              )}
              {(user_wallets.length > 0 || wallets.length > 0) && (
                <Tab
                  eventKey="wallets"
                  title={getTabTitle(
                    clientModeConfigObject.cap_plural_for_collection,
                    wallets.length + user_wallets.length
                  )}
                >
                  <br />
                  {user_wallets.length > 0 && (
                    <MonospacedTable striped hover>
                      <thead>
                        <tr>
                          <th
                            style={{
                              fontFamily: "Quicksand",
                              fontSize: "14px"
                            }}
                          >
                            {`Custom Tags`}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {user_wallets.map(
                          ({ walletId, name, addressCount }) => (
                            <tr key={walletId}>
                              <td>
                                <Link
                                  to={`/${currency}/wallet/${walletId}`}
                                  style={{
                                    fontFamily: "Quicksand",
                                    fontWeight: "600"
                                  }}
                                >{`${name} (${addressCount.toLocaleString()})`}</Link>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </MonospacedTable>
                  )}
                  {wallets.length > 0 && (
                    <MonospacedTable striped hover>
                      <thead>
                        <tr>
                          <th
                            style={{
                              fontFamily: "Quicksand",
                              fontSize: "14px"
                            }}
                          >
                            {clientModeConfigObject.cap_word_for_collection}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {wallets.map(({ walletId, name, addressCount }) => (
                          <tr key={walletId}>
                            <td>
                              <Link
                                to={`/${currency}/wallet/${walletId}`}
                                style={{
                                  fontFamily: "Quicksand",
                                  fontWeight: "600"
                                }}
                              >{`${name} (${addressCount.toLocaleString()})`}</Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </MonospacedTable>
                  )}
                </Tab>
              )}
              {customWallets.length > 0 && (
                <Tab
                  eventKey="custom wallets"
                  title={getTabTitle(
                    `Custom ${clientModeConfigObject.cap_plural_for_collection}`,
                    customWallets.length
                  )}
                >
                  <br />
                  <MonospacedTable striped hover>
                    <thead>
                      <tr>
                        <th>{`Custom ${clientModeConfigObject.cap_word_for_collection}`}</th>
                      </tr>
                    </thead>
                    <tbody>{customWallets}</tbody>
                  </MonospacedTable>
                </Tab>
              )}
              {transactions.length > 0 && (
                <Tab
                  eventKey="transactions"
                  title={getTabTitle("Transactions", transactions.length)}
                >
                  <br />
                  <MonospacedTable striped hover>
                    <thead>
                      <tr>
                        <th
                          style={{ fontFamily: "Quicksand", fontSize: "14px" }}
                        >
                          Transaction
                        </th>
                      </tr>
                    </thead>
                    <tbody>{transactions}</tbody>
                  </MonospacedTable>
                </Tab>
              )}
            </Tabs>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

SearchResults.propTypes = {
  match: reactRouterMatchShape({
    query: PropTypes.string.isRequired
  }).isRequired
};
