/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import validator from "validator";
import moment from "moment";
import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios, { INVITE_API, USER_API } from "../../api";
import { ScrollDiv } from "../styled";
import ModalLink from "../ModalLink";
import Papa from "../../../../node_modules/papaparse/papaparse.min";
import InviteRow from "./InviteRow";
import Switch from "../Switch/Switch";
import UserLogins from "./UserLogins";
import { getRole } from "../../selectors/authentication";
import "../../../css/styles.css";

/**
 * Form to send invites and lists current pending invites.
 */
class UserManagement extends Component {
  state = {
    notification: null,
    invites: [],
    users: [],
    tabKey: "invites",
    modalUserId: null,
    modalUserEmail: null,
    userSortVar: null,
    userSortOrder: null,
    inviteSortVar: null,
    inviteSortOrder: null,
    showLoginsModal: false,
    showDeleteModal: false,
    userSearchTerm: "",
    inviteSearchTerm: "",
  };

  componentDidMount() {
    this.fetchInvites();
  }

  onDeleteInvite = email => {
    this.setState(prevState => {
      const invites = prevState.invites.filter(invite => invite.email !== email);
      return {
        invites,
      };
    });
  };

  changeTab = tabKey => this.setState({ tabKey });

  fetchInvites = async () => {
    try {
      const { data } = await axios.get(INVITE_API);
      this.setState({
        invites: data.invites,
        users: data.users,
        visibleUsers: data.users,
      });
    } catch (err) {
      throw err;
    }
  };

  handleAlertDismiss = () => this.setState({ notification: null });

  /**
   * Check is email is valid and then sends request, updating invites
   * list if needed.
   * @param {event} e
   * @returns {Promise<void>}
   */
  handleSendInvite = async e => {
    e.preventDefault();
    const email = this.email.value;
    if (validator.isEmail(email)) {
      try {
        const { data } = await axios.post(INVITE_API, {
          email,
        });

        this.setState({
          notification: "Invite sent",
        });

        const newInvite = {
          email: data.email,
          inviteDate_: data.inviteDate,
        };

        this.setState(prevState => ({
          invites: prevState.invites.concat([newInvite]),
        }));

        this.email.value = "";
      } catch (err) {
        if (err.response.status === 409) {
          this.setState({
            notification: "Email already exists",
          });
        } else {
          throw err;
        }
      }
    } else {
      this.setState({
        notification: "Invalid email",
      });
    }
  };

  showUpdateFailedAlert = () => {
    const { notification } = this.state;
    switch (notification) {
      case "Invalid email":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p>Invalid email</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Invite sent":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="success" onDismiss={this.handleAlertDismiss}>
                <p>Invite sent</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Email already exists":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p>Email already exists</p>
              </Alert>
            </Col>
          </Row>
        );
      default:
        return null;
    }
  };

  openModal = (userId, email, modalName) => {
    if (modalName === "delete") {
      this.setState({
        modalUserId: userId,
        modalUserEmail: email,
        showDeleteModal: true,
      });
    } else {
      this.setState({
        modalUserId: userId,
        modalUserEmail: email,
        showLoginsModal: true,
      });
    }
  };

  closeModal = () => {
    this.setState({
      showLoginsModal: false,
      showDeleteModal: false,
      modalUserId: null,
      modalUserEmail: null,
    });
  };

  toggleUser = async (setDisabledTo, id) => {
    try {
      const method = setDisabledTo ? "disable" : "enable";
      await axios.patch(`${USER_API}/${id}`, {
        method,
      });

      this.setState(state => {
        const users = state.users.map(user => {
          if (user.userId === id) {
            user.disabled = setDisabledTo;
          }

          return user;
        });
        return {
          users,
        };
      });
    } catch (err) {
      throw err;
    }
  };

  deleteUser = async id => {
    try {
      const method = "delete";
      await axios.patch(`${USER_API}/${id}`, {
        method,
      });

      this.setState(state => {
        const users = state.users.filter(user => {
          return user.userId !== id;
        });
        return {
          users,
        };
      });
    } catch (err) {
      throw err;
    }
    this.closeModal();
  };

  sortUsers = async variable => {
    this.setState(state => {
      const userSortOrder = !state.userSortOrder;
      const users = state.users.sort(this.compare(variable, userSortOrder));
      const userSortVar = variable;
      return {
        users,
        userSortOrder,
        userSortVar,
      };
    });
  };

  sortInvites = async variable => {
    this.setState(state => {
      const inviteSortOrder = !state.inviteSortOrder;
      const invites = state.invites.sort(this.compare(variable, inviteSortOrder));
      const inviteSortVar = variable;
      return {
        invites,
        inviteSortOrder,
        inviteSortVar,
      };
    });
  };

  compare(variable, asc) {
    return function(a, b) {
      // eslint-disable-next-line no-prototype-builtins
      if (!a.hasOwnProperty(variable) || !b.hasOwnProperty(variable)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[variable] === "string" ? a[variable].toUpperCase() : a[variable];
      const varB = typeof b[variable] === "string" ? b[variable].toUpperCase() : b[variable];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return asc === false ? comparison * -1 : comparison;
    };
  }

  csvDownload = () => {
    if (this.state.users === null) {
      return null;
    }

    const data = this.state.users.map(user => ({
      Email: user.email,
      "Last Active": user.lastActive
        ? moment.unix(parseInt(user.lastActive, 10)).format("LLL")
        : "N/A",
      "Login Count": user.loginCount,
      "Account Status": user.disabled ? "Deactivated" : "Activated",
    }));
    let csv = Papa.unparse(data);

    // The encodeURIComponent wrapper around the csv object was necessary for firefox compatibility
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    }
    return (
      <a className="downloadButton" href={csv} download="whitesail-users.csv">
        {" "}
        <FontAwesomeIcon icon={faDownload} /> Download{" "}
      </a>
    );
  };

  render() {
    const invites = this.state.invites.map(invite => {
      const { email } = invite;
      const inviteDate = moment.unix(parseInt(invite.inviteDate_, 10)).format("LLL");

      if (email.includes(this.state.inviteSearchTerm)) {
        return (
          <InviteRow
            key={email}
            email={email}
            inviteDate={inviteDate}
            onDeleteInvite={() => this.onDeleteInvite(email)}
          />
        );
      }
    });

    const users = this.state.users.map(user => {
      const { userId, email, lastActive, loginCount, disabled } = user;

      const lastActiveDate = lastActive
        ? moment.unix(parseInt(lastActive, 10)).format("LLL")
        : "N/A";
      if (user.email.includes(this.state.userSearchTerm)) {
        return (
          <tr
            style={{
              backgroundColor: disabled ? "#fab6c2" : "#c3fac0",
              color: "black",
            }}
            className="inviteRow"
            key={userId}
          >
            <td
              style={{
                verticalAlign: "middle",
                fontFamily: "Quicksand",
              }}
            >
              <ModalLink
                handleOpenLink={() => this.openModal(userId, email, "info")}
                entity={email}
              />
            </td>
            <td>{lastActiveDate}</td>
            <td>{loginCount}</td>
            <td>
              <div style={{ zIndex: "0" }}>
                {disabled ? "Deactivated" : "Activated"}
                <br />
                <Switch
                  checked={!disabled}
                  offColor="red"
                  onChange={() => this.toggleUser(!disabled, user.userId)}
                />
              </div>
            </td>
            <td>
              <ModalLink
                entity={<Button className="xButton"> X </Button>}
                handleOpenLink={() => this.openModal(userId, email, "delete")}
              />
            </td>
          </tr>
        );
      }
    });

    const { tabKey } = this.state;
    const isAdmin = ["admin", "supervisor", "researcher"].includes(this.props.role);

    return (
      <div>
        <Helmet>
          <title>User Management</title>
        </Helmet>
        <Grid>
          {this.showUpdateFailedAlert()}
          <Row>
            <Col
              xs={10}
              xsOffset={1}
              sm={10}
              smOffset={1}
              md={8}
              lg={6}
              style={{
                borderWidth: "0 0 0 2px",
                borderStyle: "solid",
                borderColor: "var(--base-color)",
              }}
            >
              <h1
                style={{
                  fontFamily: "Quicksand",
                  fontWeight: "600",
                  fontSize: "35",
                  color: "var(--base-color)",
                }}
              >
                New Invite
              </h1>
              <Form onSubmit={this.handleSendInvite} style={{ marginBottom: "22px" }}>
                <FormGroup>
                  <p style={{ fontFamily: "Quicksand", fontWeight: "700" }}>Email</p>
                  <FormControl
                    type="email"
                    inputRef={ref => {
                      this.email = ref;
                    }}
                  />
                </FormGroup>
                <Button
                  style={{ fontFamily: "Quicksand" }}
                  className="blueButton"
                  type="submit"
                  disabled={!isAdmin}
                >
                  Send invite
                </Button>
              </Form>
            </Col>
          </Row>
        </Grid>
        <br />
        <Grid>
          <Row>
            <Col xs={12} sm={12} md={11} lg={11} mdOffset={1} lgOffset={1}>
              <Tabs
                className="inviteTabs"
                activeKey={tabKey}
                id="invite-tab"
                onSelect={this.changeTab}
                animation={false}
              >
                <Tab eventKey="invites" title="Invites">
                  <div className="searchFilter" style={{ marginTop: "10px" }}>
                    <FontAwesomeIcon icon={faSearch} />
                    <input
                      placeholder="Email"
                      onChange={e => this.setState({ inviteSearchTerm: e.target.value })}
                    />
                  </div>
                  <Table className="inviteTable" striped hover>
                    <thead>
                      <tr>
                        <th onClick={() => this.sortInvites("email", this.state.userSortOrder)}>
                          Email
                          {"  "}
                          {this.state.inviteSortVar === "email"
                            ? this.state.inviteSortOrder === true
                              ? "\u25B2"
                              : "\u25BC"
                            : ""}
                        </th>
                        <th
                          onClick={() => this.sortInvites("inviteDate_", this.state.userSortOrder)}
                        >
                          Sent
                          {"  "}
                          {this.state.inviteSortVar === "inviteDate_"
                            ? this.state.inviteSortOrder === true
                              ? "\u25B2"
                              : "\u25BC"
                            : ""}
                        </th>
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <th />
                      </tr>
                    </thead>
                    <tbody>{invites}</tbody>
                  </Table>
                </Tab>
                {isAdmin ? (
                  <Tab eventKey="users" title="Users">
                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                        marginLeft: "0px",
                        maxWidth: "100%",
                      }}
                    >
                      <div className="searchFilter col-6" style={{ display: "inline-block" }}>
                        <FontAwesomeIcon icon={faSearch} />
                        <input
                          placeholder="Email"
                          onChange={e => this.setState({ userSearchTerm: e.target.value })}
                        />
                      </div>
                      <div className="col-6" style={{ display: "inline-block" }}>
                        {this.csvDownload()}
                      </div>
                    </div>
                    <br />
                    <ScrollDiv maxHeight={900}>
                      <Table className="inviteTable" striped hover>
                        <thead>
                          <tr>
                            <th onClick={() => this.sortUsers("email", this.state.userSortOrder)}>
                              Email{" "}
                              {this.state.userSortVar === "email"
                                ? this.state.userSortOrder === true
                                  ? "\u25B2"
                                  : "\u25BC"
                                : " "}
                            </th>
                            <th
                              onClick={() => this.sortUsers("lastActive", this.state.userSortOrder)}
                            >
                              Last Active{" "}
                              {this.state.userSortVar === "lastActive"
                                ? this.state.userSortOrder === false
                                  ? "\u25B2"
                                  : "\u25BC"
                                : " "}
                            </th>
                            <th
                              onClick={() => this.sortUsers("loginCount", this.state.userSortOrder)}
                            >
                              Recent Logins (Past 90 days){" "}
                              {this.state.userSortVar === "loginCount"
                                ? this.state.userSortOrder === false
                                  ? "\u25B2"
                                  : "\u25BC"
                                : " "}
                            </th>
                            <th
                              onClick={() => this.sortUsers("disabled", this.state.userSortOrder)}
                            >
                              Account Status{" "}
                              {this.state.userSortVar === "disabled"
                                ? this.state.userSortOrder === true
                                  ? "\u25B2"
                                  : "\u25BC"
                                : " "}
                            </th>
                            <th> Delete User</th>
                          </tr>
                        </thead>
                        <tbody>{users}</tbody>
                      </Table>
                    </ScrollDiv>
                  </Tab>
                ) : null}
              </Tabs>
            </Col>
          </Row>
        </Grid>
        <Modal show={this.state.showLoginsModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalUserEmail}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <UserLogins userId={this.state.modalUserId} />
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showDeleteModal} onHide={this.closeModal}>
          <Modal.Body>
            <div style={{ textAlign: "Center" }}>
              <h3
                style={{
                  color: "black",
                  fontFamily: "Quicksand",
                  fontWeight: "600",
                  fontSize: "15px",
                  marginRight: "10px",
                  marginBottom: "30px",
                }}
              >
                Are you sure you want to delete{" "}
                <span
                  style={{
                    color: "var(--base-color)",
                    fontFamily: "Quicksand",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  {this.state.modalUserEmail}
                </span>
                ?
              </h3>
              <Button
                className="xButton"
                style={{ marginRight: "5px" }}
                onClick={() => this.deleteUser(this.state.modalUserId)}
              >
                Yes
              </Button>
              <Button
                className="greenButton"
                style={{ marginLeft: "5px" }}
                onClick={this.closeModal}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

UserManagement.propTypes = {
  role: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  role: getRole(state),
});

export default connect(mapStateToProps)(UserManagement);
