/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";
import { Button, ListGroupItem } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { FakeLinkSpan } from "../../styled";
import { cytoscapeShape, reactRouterMatchShape } from "../../../prop-types";
import { addWalletToGraph, openWalletModal } from "../../../actions/graph";
import { getWalletSetMemo } from "../../../selectors/graph";
import { satoshiToBitcoin } from "../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faLocationArrow } from "@fortawesome/free-solid-svg-icons/index";

const AssociatedSearchRow = ({
  primaryTag,
  walletId,
  cy,
  wallets,
  addWalletToGraphCallback,
  handleOpenWalletModal,
  inputSatoshi,
  outputSatoshi,
  anchorAddress,
  match: {
    params: { graphId }
  },
  category,
  selectElementFromSearchResult
}) => {
  const addWalletToGraph_ = () => {
    // The line below unselects all elements in the graph when a new one is added
    cy.elements().unselect();
    addWalletToGraphCallback(walletId, null, null, graphId, cy);
  };

  return (
    <ListGroupItem>
      <div
        className="row"
        style={{
          display: "flex",
          paddingBottom: "0",
          margin: "0"
        }}
      >
        <div
          className="col-lg-3"
          style={{
            overflow: "auto",
            wordWrap: "break-word",
            paddingLeft: "0",
            paddingRight: "0",
            paddingBottom: "0",
            marginLeft: "0",
            marginRight: "5px"
          }}
        >
          <FakeLinkSpan
            onClick={() => handleOpenWalletModal(walletId)}
            className="graphSearchTagName"
          >
            {primaryTag || anchorAddress || walletId}
          </FakeLinkSpan>
        </div>
        <div
          className="col-lg-3"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingLeft: "0",
            paddingRight: "0",
            paddingBottom: "0",
            marginLeft: "0",
            marginRight: "5px",
            fontFamily: "QuickSand, sans-serif",
            fontWeight: "500",
            fontSize: "12px"
          }}
        >
          {satoshiToBitcoin(outputSatoshi)}
        </div>
        <div
          className="col-lg-3"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingLeft: "0",
            paddingRight: "0",
            paddingBottom: "0",
            marginLeft: "0",
            marginRight: "5px",
            fontFamily: "QuickSand, sans-serif",
            fontWeight: "500",
            fontSize: "12px"
          }}
        >
          {satoshiToBitcoin(inputSatoshi)}
        </div>

        <div
          className="col-lg-3"
          style={{
            paddingLeft: "0",
            paddingRight: "0",
            paddingBottom: "0",
            marginLeft: "5px",
            marginRight: "0"
          }}
        >
          {!wallets.has(walletId.toString()) ? (
            <Button
              className="pull-right allPurpleButton"
              bsSize="xsmall"
              onClick={addWalletToGraph_}
            >
              Add to Graph
            </Button>
          ) : (
            <>
              <Button
                className="pull-right greenButtonGraph"
                bsSize="xsmall"
                onClick={addWalletToGraph_}
              >
                <FontAwesomeIcon icon={faCheck} /> Added
              </Button>
              <Button
                className="pull-right locateButtonGraph"
                bsSize="xsmall"
                onClick={() => selectElementFromSearchResult(walletId)}
                style={{ marginRight: "10px" }}
              >
                <FontAwesomeIcon icon={faLocationArrow} /> Locate
              </Button>
            </>
          )}
        </div>
      </div>
    </ListGroupItem>
  );
};

AssociatedSearchRow.propTypes = {
  primaryTag: PropTypes.string.isRequired,
  walletId: PropTypes.string.isRequired,
  cy: cytoscapeShape.isRequired,
  wallets: PropTypes.instanceOf(Set).isRequired,
  addWalletToGraphCallback: PropTypes.func.isRequired,
  handleOpenWalletModal: PropTypes.func.isRequired,
  match: reactRouterMatchShape({ graphId: PropTypes.string.isRequired }).isRequired,
  category: PropTypes.string
};

AssociatedSearchRow.defaultProps = {
  category: null
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { graphId }
    }
  }
) => {
  return {
    wallets: getWalletSetMemo(state, Number.parseInt(graphId, 10))
  };
};

const mapDispatchToProps = dispatch => ({
  handleOpenWalletModal: wallet => dispatch(openWalletModal(wallet)),
  addWalletToGraphCallback: (walletId, x, y, graphId, cy) =>
    dispatch(addWalletToGraph(walletId, x, y, graphId, cy, null, true))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AssociatedSearchRow)
);
