/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import moment from "moment";
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";

import Papa from "papaparse/papaparse.min";
import axios, { ISSUES_API } from "../../api";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import { ChatBubble } from "./ChatBubble";

class UserIssues extends Component {
  state = {
    issues: {},
    showModal: false,
    activeTab: "unresolved",
    newMessage: "",
    currentIssue: {
      user_id: null,
      entity_id: null,
      entity_type_id: null,
      issue_id: null,
      status: "",
      messages: [],
      seen: false,
    },
  };

  entityType = id => {
    const types = {
      0: "Address",
      1: "Wallet",
      2: "Transaction",
      3: "Custom Wallet",
      4: "Note",
      5: "Graph",
    };
    return types[id];
  };

  currencyType = string => {
    const types = {
      bitcoin: "Bitcoin",
      "bitcoin-sv": "Bitcoin SV",
      "bitcoin-gold": "Bitcoin Gold",
      "bitcoin-cash": "Bitcoin Cash",
      litecoin: "Litecoin",
    };
    return types[string];
  };

  componentWillMount() {
    this.fetchIssues();
  }

  onChange(e) {
    this.setState({ newMessage: e.target.value });
  }

  openIssueModal = issue =>
    this.setState({ showModal: true, currentIssue: issue, resolutionNote: issue.memo });

  fetchIssues = async () => {
    try {
      const { data } = await axios.get(ISSUES_API(this.props.currency, "issues-user"));
      this.setState({
        issues: data.issues,
      });
    } catch (err) {
      throw err;
    }
  };

  seeResolvedIssue = async () => {
    // If it's an unresolved issue, then we want seen to remain false
    if (this.state.currentIssue.status !== "Resolved") {
      this.setState({
        showModal: false,
        newMessage: "",
        currentIssue: {
          user_id: null,
          entity_id: null,
          entity_type_id: null,
          issue_id: null,
          status: "",
          messages: [],
          seen: false,
        },
      });
    } else {
      // Make the api call to resolve the issue
      await axios.post(ISSUES_API(this.props.currency, "issues-user"), {
        issue_id: this.state.currentIssue.issue_id,
        action: "seen",
      });

      // Update the seen field for this issue. Doing this because this component wont touch redux
      const x = this.state.issues;
      x[this.state.currentIssue.issue_id].seen = true;

      this.setState({
        showModal: false,
        resolutionNote: "",
        currentIssue: {
          user_id: null,
          entity_id: null,
          entity_type_id: null,
          issue_id: null,
          status: "",
          messages: [],
          seen: false,
        },
        issues: x,
      });
    }
  };

  deleteIssue = async () => {
    // Make the api call to resolve the issue
    await axios.post(ISSUES_API(this.props.currency, "issues-user"), {
      issue_id: this.state.currentIssue.issue_id,
      action: "delete",
    });

    // Delete the issue from the array in state
    const x = this.state.issues;
    delete x[this.state.currentIssue.issue_id];
    this.setState({
      showModal: false,
      resolutionNote: "",
      currentIssue: {
        user_id: null,
        entity_id: null,
        entity_type_id: null,
        issue_id: null,
        status: "",
        messages: [],
        seen: false,
      },
      issues: x,
    });
  };

  submitNewMessage = async (e, message) => {
    // Prevents the page from refreshing on form submit
    e.preventDefault();

    // Make the api call to resolve the issue
    await axios.put(ISSUES_API(this.props.currency, "issues-message"), {
      issue_id: this.state.currentIssue.issue_id,
      message: message,
      message_sent_by_user: true,
    });

    // Edit the issue in the issues array to avoid refetching for info we already know
    const x = this.state.issues;
    x[this.state.currentIssue.issue_id].status = "Pending";
    x[this.state.currentIssue.issue_id].messages.push({
      message: message,
      message_from_user: true,
      timestamp: moment().utc(),
    });

    this.setState({
      showModal: true,
      resolutionNote: "",
      currentIssue: x[this.state.currentIssue.issue_id],
      issues: x,
    });
  };

  handleSelect = key => {
    this.setState({ activeTab: key });
  };

  getIssuesBadge = total => {
    if (total === 0) {
      return (
        <div>
          <p style={{ display: "inline", paddingRight: "4px" }}>Current</p>
        </div>
      );
    }
    return (
      <div>
        <p style={{ display: "inline", paddingRight: "10px" }}>Current</p>
        <span className="badge progress-bar-success">{total}</span>
      </div>
    );
  };

  csvDownload = () => {
    if (this.state.issues === null) {
      return null;
    }

    const data = Object.keys(this.state.issues).map(key => ({
      Currency: this.currencyType(this.state.issues[key].currency),
      "Issue #": this.state.issues[key].issue_id,
      "Issue Type": this.state.issues[key].issue_type,
      "Entity Type": this.entityType(this.state.issues[key].entity_type_id),
      Entity: this.state.issues[key].entity_id,
      Memo: this.state.issues[key].messages[0].message,
      Submitted: moment(this.state.issues[key].submitted_timestamp)
        .utc()
        .format("MM-DD-YYYY"),
      Status: this.state.issues[key].status,
    }));
    let csv = Papa.unparse(data);

    // The encodeURIComponent wrapper around the csv object was necessary for firefox compatibility
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    }
    return (
      <a className="downloadButton2" href={csv} download="whitesail-user-issues.csv">
        {" "}
        <FontAwesomeIcon icon={faDownload} /> Download{" "}
      </a>
    );
  };

  render() {
    // Create three arrays to store issues. Seen issues, unseen issues, and unresolved issues.
    const seenIssues = [];
    const unseenIssues = [];
    const unresolvedIssues = [];
    const underReviewIssues = [];

    Object.keys(this.state.issues).forEach(key => {
      const value = this.state.issues[key];
      if (value !== undefined) {
        const { entity_id, issue_id, submitted_timestamp, status, currency, seen } = value;

        if (!seen && status === "Resolved") {
          unseenIssues.push(
            <tr
              key={issue_id}
              className="inviteRow hoverableTableRow"
              onClick={() => {
                this.openIssueModal(value);
              }}
            >
              <td>
                <span className="seenDot" />
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {this.currencyType(currency)}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{issue_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{entity_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {moment(submitted_timestamp)
                  .utc()
                  .format("MM-DD-YYYY")}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>Resolved</td>
            </tr>
          );
        } else if (status === "Pending") {
          unresolvedIssues.push(
            <tr
              key={issue_id}
              className="inviteRow hoverableTableRow"
              onClick={() => {
                this.openIssueModal(value);
              }}
            >
              <td />
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {this.currencyType(currency)}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{issue_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{entity_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {moment(submitted_timestamp)
                  .utc()
                  .format("MM-DD-YYYY")}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>Pending</td>
            </tr>
          );
        } else if (status === "Under Review") {
          underReviewIssues.push(
            <tr
              key={issue_id}
              className="inviteRow hoverableTableRow"
              onClick={() => {
                this.openIssueModal(value);
              }}
            >
              <td />
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {this.currencyType(currency)}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{issue_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{entity_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {moment(submitted_timestamp)
                  .utc()
                  .format("MM-DD-YYYY")}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>Under Review</td>
            </tr>
          );
        } else {
          seenIssues.push(
            <tr
              key={issue_id}
              className="inviteRow hoverableTableRow"
              onClick={() => {
                this.openIssueModal(value);
              }}
            >
              <td />
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {this.currencyType(currency)}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{issue_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{entity_id}</td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {moment(submitted_timestamp)
                  .utc()
                  .format("MM-DD-YYYY")}
              </td>
              <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>Resolved</td>
            </tr>
          );
        }
      }
    });

    this.props.sendTotal(unseenIssues.length);
    return (
      <Grid>
        <Row style={{ marginBottom: "15px" }}>
          <Col lg={12}>
            <p
              style={{
                display: "inline",
                marginRight: "30px",
                fontSize: "30px",
                color: "var(--base-color)",
                fontWeight: "700",
              }}
            >
              {" "}
              My Support Tickets{" "}
            </p>
            {this.csvDownload()}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Tabs
              className="smallerTabs"
              activeKey={this.state.activeTab}
              id="items-tab"
              onSelect={this.handleSelect}
              animation={false}
            >
              <Tab eventKey="unresolved" title={this.getIssuesBadge(unseenIssues.length)}>
                <br />
                <>
                  {unresolvedIssues.length === 0 &&
                  seenIssues.length === 0 &&
                  underReviewIssues.length === 0 &&
                  unseenIssues.length === 0 ? (
                    <p style={{ textAlign: "center", marginTop: "40px", fontSize: "24px" }}>
                      No Support Tickets to View
                    </p>
                  ) : (
                    <Table
                      className="inviteTable"
                      style={{ maxWidth: "100%", tableLayout: "fixed" }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "3%" }} />
                          <th
                            style={{ width: "10%", textOverflow: "ellipsis", overflow: "hidden" }}
                          >
                            Currency
                          </th>
                          <th
                            style={{ width: "10%", textOverflow: "ellipsis", overflow: "hidden" }}
                          >
                            Issue #
                          </th>
                          <th
                            style={{ width: "41%", textOverflow: "ellipsis", overflow: "hidden" }}
                          >
                            Entity
                          </th>
                          <th
                            style={{ width: "14%", textOverflow: "ellipsis", overflow: "hidden" }}
                          >
                            Submitted
                          </th>
                          <th
                            style={{ width: "22%", textOverflow: "ellipsis", overflow: "hidden" }}
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {unseenIssues}
                        {underReviewIssues}
                        {unresolvedIssues}
                      </tbody>
                    </Table>
                  )}
                </>
              </Tab>
              <Tab eventKey="resolved" title="Resolved">
                <br />
                <>
                  {seenIssues.length === 0 ? (
                    <p style={{ textAlign: "center", marginTop: "40px", fontSize: "24px" }}>
                      No Support Tickets to View
                    </p>
                  ) : (
                    <Table
                      className="inviteTable"
                      style={{ maxWidth: "100%", tableLayout: "fixed" }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "3%" }} />
                          <th
                            style={{ width: "10%", textOverflow: "ellipsis", overflow: "hidden" }}
                          >
                            Currency
                          </th>
                          <th
                            style={{ width: "10%", textOverflow: "ellipsis", overflow: "hidden" }}
                          >
                            Issue #
                          </th>
                          <th
                            style={{ width: "41%", textOverflow: "ellipsis", overflow: "hidden" }}
                          >
                            Entity
                          </th>
                          <th
                            style={{ width: "14%", textOverflow: "ellipsis", overflow: "hidden" }}
                          >
                            Submitted
                          </th>
                          <th
                            style={{ width: "22%", textOverflow: "ellipsis", overflow: "hidden" }}
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>{seenIssues}</tbody>
                    </Table>
                  )}
                </>
              </Tab>
            </Tabs>
          </Col>
        </Row>
        <Modal
          show={this.state.showModal}
          onHide={() => this.seeResolvedIssue()}
          className="issuesModal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="entityTitle">
              {this.currencyType(this.state.currentIssue.currency)} Issue #
              {this.state.currentIssue.issue_id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p className="entityType"> {this.currencyType(this.state.currentIssue.currency)}</p>{" "}
              <p className="entityType">
                {" "}
                {this.entityType(this.state.currentIssue.entity_type_id)}{" "}
              </p>
              <p className="entityName">{this.state.currentIssue.entity_id}</p>
            </div>
            <div className="entityDiv">
              <p className="entityPrompt"> Messages </p>
              <>
                <div className="chat">
                  <div className="chatContent">
                    {this.state.currentIssue.messages
                      .map(x =>
                        x.message_from_user ? (
                          <ChatBubble
                            timestamp={x.timestamp}
                            text={x.message}
                            email={this.state.currentIssue.email}
                            outgoing_message={true}
                          >
                            {x.message}
                          </ChatBubble>
                        ) : (
                          <ChatBubble
                            timestamp={x.timestamp}
                            text={x.message}
                            email={"Cryptovoyant Support"}
                            outgoing_message={false}
                          >
                            {x.message}
                          </ChatBubble>
                        )
                      )
                      .reverse()}
                  </div>
                </div>
                <div>
                  <Form onSubmit={e => this.submitNewMessage(e, this.state.newMessage)}>
                    <FormGroup>
                      <FormControl
                        componentClass="textarea"
                        value={this.state.resolutionNote}
                        onChange={e => this.onChange(e)}
                        style={{ resize: "vertical", width: "600px", fontFamily: "Quicksand" }}
                      />
                    </FormGroup>
                    <Button type="submit" className="greenIssuesButton">
                      <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: "10px" }} />
                      Send Message
                    </Button>
                    <Button className="redButton" onClick={() => this.deleteIssue()}>
                      <FontAwesomeIcon icon={faTrash} style={{ marginRight: "10px" }} />
                      Delete Issue
                    </Button>
                  </Form>
                </div>
              </>
            </div>
            <div>
              <p className="entityPrompt">
                {this.state.currentIssue.status === "Pending"
                  ? "This issue was successfully submitted. An analyst will address it shortly."
                  : this.state.currentIssue.status === "Resolved"
                  ? "This issue has been resolved."
                  : "This issue is currently under analyst review."}
              </p>
            </div>
            {/*<div>*/}
            {/*<Form onSubmit={e => this.submitNewMessage(e, this.state.newMessage)}>*/}
            {/*<FormGroup>*/}
            {/*<p className="entityPrompt"> Resolution Message to User </p>*/}
            {/*<FormControl*/}
            {/*componentClass="textarea"*/}
            {/*placeholder="Resolution Message"*/}
            {/*onChange={e => this.onChange(e)}*/}
            {/*style={{resize: "vertical", width: "600px", fontFamily: "Quicksand"}}*/}
            {/*/>*/}
            {/*</FormGroup>*/}
            {/*{this.state.currentIssue.status === "Pending" && (*/}
            {/*<Button*/}
            {/*type="submit"*/}
            {/*className="blueIssuesButton"*/}
            {/*onClick={e => this.resolveIssue(e, null, "Under Review")}*/}
            {/*>*/}
            {/*Mark as 'Under Review'*/}
            {/*</Button>*/}
            {/*)}*/}
            {/*<Button*/}
            {/*type="submit"*/}
            {/*className="greenButton"*/}
            {/*disabled={this.state.resolutionNote.length === 0}*/}
            {/*>*/}
            {/*Resolve Issue*/}
            {/*</Button>*/}
            {/*</Form>*/}
            {/*</div>*/}
          </Modal.Body>
        </Modal>
        <br />
        {this.props.throwExceptionButtonEnabled && (
          <Button onClick={() => throwError()}> Throw error </Button>
        )}
      </Grid>
    );
  }
}

UserIssues.propTypes = {
  currency: PropTypes.string.isRequired,
};

export default UserIssues;
