/*
 * Copyright 2018-2021  DecisionQ Information Operations, Inc. All Rights Reserved.
 */

import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, Collapse, Modal, Row, Tab, Table, Well } from "react-bootstrap";
import StatCard from "../../StatCard";
import { satoshiToBitcoin } from "../../../helpers";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPlayCircle,
  faStopCircle,
  faTimes,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import axios, { WATCHLIST_API } from "../../../api";
import EditableTitle from "./EditableTitle";
import WatchlistNote from "./WatchlistNote";
import Tabs from "react-bootstrap/lib/Tabs";
import { getTabBadge } from "../../Address/Address";
import MempoolCheckbox from "./MempoolCheckbox";
import { getFeature } from "../../../selectors/features";
import { getEmail, getRole } from "../../../selectors/authentication";
import { getCurrency } from "../../../selectors/currency";
import { getClientMode } from "../../../selectors/applicationConfig";
import ClientModeConfig from "../../ClientModeConfig";
import { getWalletNameHelper } from "../../../selectors/wallet";
import { CustomCheckbox } from "../../Utils/CustomCheckbox";
import { SMSCheckbox } from "./SMSCheckbox";

class WatchlistItem extends Component {
  state = {
    openInput: false,
    openOutput: false,
    showStopWatchingModal: false,
    showDeleteModal: false,
    openPending: false
  };

  // We do this so that the state variables reset when the modal is launched for a new item
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.currentWatchlistItem["wallet_id"] !== this.props.currentWatchlistItem["wallet_id"]
    ) {
      this.setState({ openInput: false, openOutput: false });
    }
  }

  stopWatching = async () => {
    const { status } = await axios.post(WATCHLIST_API(this.props.currency) + "/modify", {
      watchlist_item_type: this.props.entityType,
      watchlist_item_id: this.props.currentWatchlistItem["id"],
      watchlist_ending_block:
        this.props.currentWatchlistItem["current_processed_block"] ||
        this.props.currentWatchlistItem["starting_block_id"]
    });

    if (status === 200) {
      this.props.stopWatching(
        this.props.entityType,
        this.props.currentWatchlistItem["id"],
        this.props.currentWatchlistItem["current_processed_block"] ||
          this.props.currentWatchlistItem["starting_block_id"]
      );

      // close the modals
      this.setState({ showStopWatchingModal: false });
      this.props.closeModal();
    }
  };

  /**
   * restarts a watchlist item, kinda a hack by setting ending_block back to null TODO fix logic
   * @returns {Promise<void>}
   */
  startWatching = async () => {
    const { status } = await axios.post(WATCHLIST_API(this.props.currency) + "/modify", {
      watchlist_item_type: this.props.entityType,
      watchlist_item_id: this.props.currentWatchlistItem["id"],
      watchlist_ending_block: null
    });

    if (status === 200) {
      this.props.stopWatching(this.props.entityType, this.props.currentWatchlistItem["id"], null);

      // close the modals
      this.setState({ showStopWatchingModal: false });
      this.props.closeModal();
    }
  };

  deleteItem = async () => {
    const { status } = await axios.delete(WATCHLIST_API(this.props.currency) + "/modify", {
      data: {
        watchlist_item_type: this.props.entityType,
        watchlist_item_id: this.props.currentWatchlistItem["id"]
      }
    });

    if (status === 200) {
      this.props.deleteItem(this.props.entityType, this.props.currentWatchlistItem["id"]);
      // close the modals
      this.setState({ showDeleteModal: false });
      this.props.closeModal();
    }
  };

  getTransactionRows = transactionList => {
    const { currency } = this.props;
    return transactionList.map(key => {
      const { input_satoshi, output_satoshi, transaction_hash } = key;

      return (
        <tr className="inviteRow" style={{ backgroundColor: "white" }} key={transaction_hash}>
          <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            <Link
              to={`/${currency}/transaction/${transaction_hash}`}
              target="_blank"
              onClick={e => e.stopPropagation()}
            >
              {transaction_hash}
            </Link>
          </td>
          <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            {satoshiToBitcoin(input_satoshi)}
          </td>
          <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            {satoshiToBitcoin(output_satoshi)}
          </td>
        </tr>
      );
    });
  };

  getEntityName = () => {
    const { currentWatchlistItem, entityType } = this.props;
    if (entityType === "Wallet") {
      if (currentWatchlistItem["name"]) {
        const { name, tagType } = getWalletNameHelper(currentWatchlistItem["name"])[0];
        if (tagType === "ID") {
          return name;
        } else {
          return `${name} (${currentWatchlistItem["name"]["address"]})`;
        }
      } else {
        return currentWatchlistItem["wallet_id"];
      }
    }
    return currentWatchlistItem["address"];
  };

  render() {
    const {
      currentWatchlistItem,
      showModal,
      closeModal,
      currency,
      entityType,
      setMempool,
      setSMS
    } = this.props;

    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    const input_rows = this.getTransactionRows(currentWatchlistItem["input_trxs"]);
    const output_rows = this.getTransactionRows(currentWatchlistItem["output_trxs"]);
    const pending_rows = this.getTransactionRows(currentWatchlistItem["pending_trxs"]);
    const entity_column = entityType === "Address" ? "address_id" : "wallet_id";

    return (
      <Modal show={showModal} onHide={closeModal} className="issuesModal">
        <Modal.Header closeButton>
          <Modal.Title className="entityTitle">
            {entityType !== "Address" ? clientModeConfigObject.cap_word_for_collection : entityType}{" "}
            Watchlist Item
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              marginBottom: "20px"
            }}
          >
            <EditableTitle
              title={currentWatchlistItem["title"]}
              updateTitle={this.props.updateTitle}
              id={currentWatchlistItem["id"]}
              type={entityType}
            />
          </div>
          <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
            {entityType === "wallet" && currentWatchlistItem["merged_into_wallet_id"] && (
              <p
                style={{
                  marginBottom: "10px",
                  fontFamily: "Quicksand",
                  color: "green",
                  fontSize: "20px",
                  paddingBottom: "5px",
                  fontWeight: "600",
                  textAlign: "center"
                }}
              >
                {" "}
                This Wallet was Merged into Wallet {
                  currentWatchlistItem["merged_into_wallet_id"]
                }{" "}
              </p>
            )}
            <div
              style={{
                marginBottom: "10px",
                fontFamily: "Quicksand",
                color: "var(--secondary-color)",
                fontSize: "20px",
                paddingBottom: "5px",
                borderBottom: "2px solid var(--secondary-color)"
              }}
            >
              {" "}
              Summary{" "}
              <div style={{ float: "right", fontSize: "14px" }}>
                <MempoolCheckbox
                  mempoolValue={currentWatchlistItem["mempool_tracking"]}
                  setValue={mempool_tracking => {
                    setMempool(mempool_tracking);
                  }}
                  entityType={entityType.toLowerCase()}
                  entity_id={currentWatchlistItem[entity_column]}
                  currency={currency}
                  watchlistItemExists
                />
              </div>
              <div style={{ float: "right", fontSize: "14px" }}>
                <SMSCheckbox
                  value={currentWatchlistItem["sms_messaging"]}
                  setValue={setSMS}
                  currency={currency}
                  entityType={entityType.toLowerCase()}
                  id={currentWatchlistItem["id"]}
                />
              </div>
            </div>
            <StatCard
              stat={entityType === "Address" ? "Address" : "Wallet"}
              value={this.getEntityName()}
            />
            <StatCard stat={"Starting Block"} value={currentWatchlistItem["starting_block_id"]} />
            <StatCard
              stat={"Ending Block"}
              value={currentWatchlistItem["ending_block_id"] || "--"}
            />
            {currentWatchlistItem["merged_into_wallet_id"] === null && (
              <StatCard
                stat={"Input Transaction Count"}
                value={
                  currentWatchlistItem["input_count"] > 999
                    ? "1000+"
                    : currentWatchlistItem["input_count"]
                }
              />
            )}
            {currentWatchlistItem["merged_into_wallet_id"] === null && (
              <StatCard
                stat={"Output Transaction Count"}
                value={
                  currentWatchlistItem["output_count"] > 999
                    ? "1000+"
                    : currentWatchlistItem["output_count"]
                }
              />
            )}
          </div>

          {(entityType !== "wallet" || currentWatchlistItem["merged_into_wallet_id"] === null) && (
            <div
              style={{
                marginTop: "30px",
                paddingLeft: "15px",
                paddingRight: "15px"
              }}
            >
              <Tabs
                className="inviteTabs"
                // activeKey={tabKey}
                id="watchlist-tab"
                // onSelect={onTabSelect}
                animation={false}
              >
                <Tab
                  eventKey="input transactions"
                  title={getTabBadge(
                    "Inputs ",
                    currentWatchlistItem["new_input_count"] > 99
                      ? "100+"
                      : currentWatchlistItem["new_input_count"]
                  )}
                >
                  <p
                    style={{
                      fontFamily: "Quicksand",
                      color: "var(--base-color)",
                      fontSize: "14px",
                      fontWeight: "700",
                      marginTop: "10px"
                    }}
                  >
                    Unseen Input Transactions:{" "}
                    {currentWatchlistItem["new_input_count"] > 99
                      ? "100+"
                      : currentWatchlistItem["new_input_count"]}
                    <br></br>
                    Input Transactions:{" "}
                    {currentWatchlistItem["input_count"] > 999
                      ? "1000+"
                      : currentWatchlistItem["input_count"]}
                  </p>
                  <Row>
                    <Col
                      lg={12}
                      style={{
                        overflow: "auto",
                        overflowY: "auto",
                        maxHeight: "400px"
                      }}
                    >
                      {input_rows.length > 0 ? (
                        <Table
                          className="inviteTable"
                          style={{ maxWidth: "100%", tableLayout: "fixed" }}
                          onClick={e => e.stopPropagation()}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  width: "60%",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden"
                                }}
                              >
                                Transaction
                              </th>
                              <th
                                style={{
                                  width: "20%",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden"
                                }}
                              >
                                Input
                              </th>
                              <th
                                style={{
                                  width: "20%",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden"
                                }}
                              >
                                Output
                              </th>
                            </tr>
                          </thead>
                          <tbody>{input_rows}</tbody>
                        </Table>
                      ) : (
                        <p
                          style={{
                            fontFamily: "Quicksand",
                            color: "black",
                            fontWeight: "700",
                            textAlign: "center"
                          }}
                        >
                          There are no transactions to view at this time
                        </p>
                      )}
                      {currentWatchlistItem["input_offset"] !== -1 && (
                        <Row style={{ textAlign: "center", marginTop: "10px" }}>
                          <Button
                            className="blueButton"
                            onClick={() =>
                              this.props.loadMore(entityType, currentWatchlistItem, "input")
                            }
                          >
                            {" "}
                            Load Older Transactions{" "}
                          </Button>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="output transactions"
                  title={getTabBadge(
                    "Outputs ",
                    currentWatchlistItem["new_output_count"] > 99
                      ? "100+"
                      : currentWatchlistItem["new_output_count"]
                  )}
                >
                  <p
                    style={{
                      fontFamily: "Quicksand",
                      color: "var(--base-color)",
                      fontSize: "14px",
                      fontWeight: "700",
                      marginTop: "10px"
                    }}
                  >
                    Unseen Output Transactions:{" "}
                    {currentWatchlistItem["new_output_count"] > 99
                      ? "100+"
                      : currentWatchlistItem["new_output_count"]}
                    <br></br>
                    Output Transactions:{" "}
                    {currentWatchlistItem["output_count"] > 999
                      ? "1000+"
                      : currentWatchlistItem["output_count"]}
                  </p>
                  <Row>
                    <Col
                      lg={12}
                      style={{
                        overflow: "auto",
                        overflowY: "auto",
                        maxHeight: "400px"
                      }}
                    >
                      {output_rows.length > 0 ? (
                        <Table
                          className="inviteTable"
                          style={{ maxWidth: "100%", tableLayout: "fixed" }}
                          onClick={e => e.stopPropagation()}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  width: "60%",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden"
                                }}
                              >
                                Transaction
                              </th>
                              <th
                                style={{
                                  width: "20%",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden"
                                }}
                              >
                                Input
                              </th>
                              <th
                                style={{
                                  width: "20%",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden"
                                }}
                              >
                                Output
                              </th>
                            </tr>
                          </thead>
                          <tbody>{output_rows}</tbody>
                        </Table>
                      ) : (
                        <p
                          style={{
                            fontFamily: "Quicksand",
                            color: "black",
                            fontWeight: "700",
                            textAlign: "center"
                          }}
                        >
                          There are no transactions to view at this time
                        </p>
                      )}
                      {currentWatchlistItem["output_offset"] !== -1 && (
                        <Row style={{ textAlign: "center", marginTop: "10px" }}>
                          <Button
                            className="blueButton"
                            onClick={() =>
                              this.props.loadMore(entityType, currentWatchlistItem, "output")
                            }
                          >
                            {" "}
                            Load Older Transactions{" "}
                          </Button>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Tab>
                {currentWatchlistItem["ending_block_id"] === null && (
                  <Tab
                    eventKey="pending transactions"
                    title={getTabBadge("Pending ", currentWatchlistItem["pending_count"])}
                  >
                    <p
                      style={{
                        fontFamily: "Quicksand",
                        color: "var(--base-color)",
                        fontSize: "14px",
                        fontWeight: "700",
                        marginTop: "10px"
                      }}
                    >
                      Pending Apporval Transactions: {currentWatchlistItem["pending_count"]}
                    </p>
                    <Row>
                      <Col
                        lg={12}
                        style={{
                          overflow: "auto",
                          overflowY: "auto",
                          maxHeight: "400px"
                        }}
                      >
                        {pending_rows.length > 0 ? (
                          <Table
                            className="inviteTable"
                            style={{ maxWidth: "100%", tableLayout: "fixed" }}
                            onClick={e => e.stopPropagation()}
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    width: "60%",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden"
                                  }}
                                >
                                  Transaction
                                </th>
                                <th
                                  style={{
                                    width: "20%",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden"
                                  }}
                                >
                                  Input
                                </th>
                                <th
                                  style={{
                                    width: "20%",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden"
                                  }}
                                >
                                  Output
                                </th>
                              </tr>
                            </thead>
                            <tbody>{pending_rows}</tbody>
                          </Table>
                        ) : (
                          <p
                            style={{
                              fontFamily: "Quicksand",
                              color: "black",
                              fontWeight: "700",
                              textAlign: "center"
                            }}
                          >
                            There are no transactions to view at this time
                          </p>
                        )}
                        {currentWatchlistItem["output_offset"] !== -1 && (
                          <Row style={{ textAlign: "center", marginTop: "10px" }}>
                            <Button
                              className="blueButton"
                              onClick={() =>
                                this.props.loadMore(entityType, currentWatchlistItem, "output")
                              }
                            >
                              {" "}
                              Load Older Transactions{" "}
                            </Button>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Tab>
                )}
              </Tabs>
            </div>
          )}
          <WatchlistNote
            updateNotes={this.props.updateNotes}
            note={currentWatchlistItem["notes"]}
            id={currentWatchlistItem["id"]}
            type={this.props.entityType}
          />
          <div
            style={{
              marginTop: "30px",
              paddingLeft: "15px",
              paddingRight: "15px"
            }}
          >
            <p
              style={{
                marginBottom: "10px",
                fontFamily: "Quicksand",
                color: "var(--secondary-color)",
                fontSize: "20px",
                paddingBottom: "5px",
                borderBottom: "2px solid var(--secondary-color)"
              }}
            >
              {" "}
              Actions{" "}
            </p>
            {!this.props.currentWatchlistItem["ending_block_id"] ? (
              <Button
                onClick={() => this.setState({ showStopWatchingModal: true })}
                className="issueButton"
                style={{ marginRight: "10px", border: "none" }}
              >
                {" "}
                <FontAwesomeIcon icon={faStopCircle} /> Stop Watching{" "}
              </Button>
            ) : (
              <Button
                onClick={() => this.startWatching()}
                className="greenButton"
                style={{ marginRight: "10px", border: "none" }}
              >
                {" "}
                <FontAwesomeIcon icon={faPlayCircle} /> Restart Watching{" "}
              </Button>
            )}
            <Button
              onClick={() => this.setState({ showDeleteModal: true })}
              className="issueButton"
            >
              {" "}
              <FontAwesomeIcon icon={faTrash} /> Delete Watchlist Item{" "}
            </Button>
          </div>
        </Modal.Body>
        <Modal show={this.state.showStopWatchingModal}>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
                <p
                  style={{
                    fontFamily: "Quicksand",
                    color: "#333",
                    fontSize: "18px",
                    fontWeight: "700",
                    textAlign: "center"
                  }}
                >
                  Are you sure you want to stop watching this item?
                </p>
              </Row>
              <Row>
                <Button
                  className="greenButton"
                  style={{ marginRight: "10px" }}
                  onClick={this.stopWatching}
                >
                  {" "}
                  <FontAwesomeIcon icon={faCheck} style={{ marginRight: "7px" }} />
                  Yes
                </Button>
                <Button
                  className="issueButton"
                  onClick={() => this.setState({ showStopWatchingModal: false })}
                >
                  <FontAwesomeIcon icon={faTimes} style={{ marginRight: "7px" }} />
                  No
                </Button>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showDeleteModal}>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
                <p
                  style={{
                    fontFamily: "Quicksand",
                    color: "#333",
                    fontSize: "18px",
                    fontWeight: "700",
                    textAlign: "center"
                  }}
                >
                  Are you sure you want to delete this item?
                </p>
              </Row>
              <Row>
                <Button
                  className="greenButton"
                  style={{ marginRight: "10px" }}
                  onClick={this.deleteItem}
                >
                  {" "}
                  <FontAwesomeIcon icon={faCheck} style={{ marginRight: "7px" }} />
                  Yes
                </Button>
                <Button
                  className="issueButton"
                  onClick={() => this.setState({ showDeleteModal: false })}
                >
                  <FontAwesomeIcon icon={faTimes} style={{ marginRight: "7px" }} />
                  No
                </Button>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      </Modal>
    );
  }
}

WatchlistItem.propTypes = {
  currency: PropTypes.string.isRequired,
  currentWatchlistItem: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  stopWatching: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  entityType: PropTypes.string.isRequired,
  setMempool: PropTypes.func.isRequired,
  setSMS: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    clientMode: getClientMode(state),
    userEmail: getEmail(state)
  };
};

export default connect(
  mapStateToProps,
  null
)(WatchlistItem);
