import React, { useState } from "react";
import { DropdownButton, MenuItem } from "react-bootstrap";
import CreateGraphModal from "../Home/CreateGraphModal";
import PreloadGraphModal from "../PreloadGraphModal";
import { useDispatch } from "react-redux";
import { createGraphInNewTabWithPreload } from "../../actions/graph";

interface AddToAnotherGraphProps {
  selectedWallets: Array<any | string>;
  curGraph: number;
}

/**
 * Button to add selected wallets to another graph
 * @param selectedWallets
 * @param curGraph
 * @constructor
 */
export const AddToAnotherGraph: React.FC<AddToAnotherGraphProps> = ({
  selectedWallets,
  curGraph
}) => {
  const [newGraphOpen, setNewGraphOpen] = useState(false);
  const [existingGraphOpen, setExistingGraphOpen] = useState(false);
  const dispatch = useDispatch();

  const createGraph = (caseNumber: string, description: string, isPublic: boolean) => {
    dispatch(
      createGraphInNewTabWithPreload(caseNumber, description, isPublic, {
        preloadAddresses: [],
        preloadWallets: [
          ...selectedWallets
            .filter(elem => elem.type === "default" || elem.type === "custom")
            .map(elem => elem.id)
        ],
        preloadTransactions: [],
        preloadUnclusteredTransactions: [
          ...selectedWallets.filter(elem => elem.type === "transaction").map(elem => elem.id)
        ]
      })
    );
  };
  return (
    <>
      <DropdownButton
        className="graphToggle"
        title={"Add Selected Nodes to Graph"}
        id="dropdown-basic"
      >
        <MenuItem eventKey="new" onSelect={() => setNewGraphOpen(true)}>
          New graph
        </MenuItem>
        <MenuItem eventKey="existing" onSelect={() => setExistingGraphOpen(true)}>
          Existing graph
        </MenuItem>
      </DropdownButton>
      <CreateGraphModal
        createGraph={createGraph}
        show={newGraphOpen}
        close={() => setNewGraphOpen(false)}
      />
      <PreloadGraphModal
        type={"wallet"}
        close={() => setExistingGraphOpen(false)}
        show={existingGraphOpen}
        item={selectedWallets}
        curGraph={curGraph.valueOf()}
      />
    </>
  );
};
