import { IconButton } from "@material-ui/core";
import { SnackbarKey, useSnackbar } from "notistack";
import * as React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface SnackbarCloseButtonProps {
  snackbarKey: SnackbarKey;
}
function SnackbarCloseButton({ snackbarKey }: SnackbarCloseButtonProps) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <FontAwesomeIcon
        icon={faTimes}
        style={{ fontSize: "14px", color: "black" }}
      />
    </IconButton>
  );
}

export default SnackbarCloseButton;
