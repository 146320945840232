/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Login from "../Login";
import PasswordResetRequest from "../PasswordResetRequest";
import PasswordReset from "../PasswordReset";
import Register from "../Register";
import { connect } from "react-redux";
import { getFeature } from "../../selectors/features";
import { getCurrency } from "../../selectors/currency";
import { getRole, getTwoFAConfigured } from "../../selectors/authentication";
import { getCoins } from "../../selectors/coins";
import TwoFAEnter from "../TwoFA/TwoFAEnter";
import TwoFARegister from "../TwoFA/TwoFARegister";
import TwoFAEnterSms from "../TwoFA/TwoFAEnterSms";

const TwoFactorRoutes = ({ twoConfigured }) => (
  <Switch>
    {/*<Route exact path="/2fa-register" component={TwoFARegister} />*/}
    <Route exact path="/2fa-enter-sms" component={TwoFAEnterSms} />
    {/*<Redirect to={!twoConfigured ? "/2fa-register" : "/2fa-enter-sms"} />*/}
    <Redirect to={"/2fa-enter-sms"} />
  </Switch>
);

const mapStateToProps = state => {
  return {
    throwExceptionComponentEnabled: getFeature(state, "throw_exception_test"),
    issuesEnabled: getFeature(state, "issues"),
    currency: getCurrency(state),
    isResearcher: getRole(state) === "researcher",
    twoConfigured: getTwoFAConfigured(state),
  };
};

export default connect(
  mapStateToProps,
  null
)(TwoFactorRoutes);
