/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Button,
  ControlLabel,
  DropdownButton,
  Form,
  FormControl,
  FormGroup,
  MenuItem,
  Modal,
  Table
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  chooseBetweenConversions,
  getUnixTimestampFromDate,
  numberWithCommas,
  onInputChange,
  satoshiToBitcoin,
  satoshiToBitcoinNoCommas
} from "../../helpers";
import moment from "moment/moment";
import { ScrollDiv } from "../styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/index";
import { faFilter, faSort } from "@fortawesome/free-solid-svg-icons/index";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import CopyText from "../CopyText";
import DataDownload from "../DataDownload";
import { getWalletName } from "../../selectors/wallet";

class MutualWallet extends Component {
  state = {
    fields: {
      startDate: "",
      endDate: ""
    },
    showDateSelectModal: false
  };

  handleInputChange = onInputChange.bind(this);

  handleSetLargestFirst = () => {
    const { sourceId, destinationWallet } = this.props;
    this.props.onSetLargestFirst(
      sourceId,
      destinationWallet.walletId,
      destinationWallet.tag,
      "largestFirst",
      null,
      null,
      false
    );
  };

  handleOpenDateSelectModal = () => this.setState({ showDateSelectModal: true });

  handleCloseDateSelectModal = () => this.setState({ showDateSelectModal: false });

  /**
   * Resets the list of transactions and loads the new ones in opposite order.
   * @callback
   */
  handleSwapTransactionOrder = () => {
    const { sourceId, destinationWallet, order } = this.props;

    this.props.onSwapOrder(sourceId, destinationWallet.walletId);

    // Prefetch reverse order if there are none
    if (order === "newestFirst") {
      this.props.fetch(
        sourceId,
        destinationWallet.walletId,
        destinationWallet.tag,
        "oldestFirst",
        null,
        null,
        false
      );
    } else {
      this.props.fetch(
        sourceId,
        destinationWallet.walletId,
        destinationWallet.tag,
        "newestFirst",
        null,
        null,
        false
      );
    }
  };

  fetchAddressTransactions = () => {
    const { sourceId, destinationWallet, order } = this.props;

    // we want to pass the dates in because they may have been set since selecting the initial filter
    const { startDateUnix, endDateUnix } = this.getDatesAsUnix();

    // pass the loadMore variable in as true so that we know this fetch was done with the intention
    // of fetching new results if they are available
    this.props.fetch(
      sourceId,
      destinationWallet.walletId,
      destinationWallet.tag,
      order,
      startDateUnix,
      endDateUnix,
      true
    );
  };

  loadItemsByDate = e => {
    e.preventDefault();
    const { sourceId, destinationWallet, order } = this.props;
    const { startDateUnix, endDateUnix } = this.getDatesAsUnix();
    this.props.onSetDate(
      sourceId,
      destinationWallet.walletId,
      destinationWallet.tag,
      order,
      startDateUnix,
      endDateUnix,
      false
    );
    this.setState({
      showDateSelectModal: false
    });
  };

  getDatesAsUnix = () => {
    const { startDate, endDate } = this.state.fields;
    return {
      startDateUnix: getUnixTimestampFromDate(startDate),
      endDateUnix: getUnixTimestampFromDate(endDate, 1, "days")
    };
  };

  render() {
    const {
      order,
      hasMore,
      showAsUsd,
      convertPriceToUsd,
      historicalToUsd,
      currentUsdPrice
    } = this.props;
    const priceAvailable = trx => this.props.transactions[trx].priceAvailable;
    const buttonText = hasMore ? "Load More ..." : "No more   ";

    let filterText;
    if (this.props.startDate !== null || this.props.endDate !== null) {
      filterText = `Showing from ${
        this.props.startDate === null
          ? "Origin"
          : moment
              .unix(this.props.startDate)
              .utc()
              .format("MM/DD/YY")
      } to ${
        this.props.endDate === null
          ? "Present"
          : moment
              .unix(this.props.endDate)
              .utc()
              .format("MM/DD/YY")
      }`;
    } else if (this.props.currentView === "mutualWallets") {
      filterText = "Attribution Categories";
    } else if (order === "largestFirst") {
      filterText = "Showing Largest First";
    } else if (order === "newestFirst") {
      filterText = "Showing Newest First";
    } else if (order === "oldestFirst") {
      filterText = "Showing Oldest First";
    }

    return (
      <>
        <div className="row" style={{ marginLeft: "0", marginBottom: "15px", marginTop: "15px" }}>
          <div
            // className="blueButtonFilter"
            style={{
              marginLeft: "0",
              marginRight: "15px",
              padding: "10px",
              textAlign: "left",
              border: "2px solid var(--secondary-color)",
              backgroundColor: "var(--secondary-color)",
              borderRadius: "4px"
            }}
          >
            <Button
              className="redButton"
              style={{ marginRight: "25px" }}
              onClick={this.props.handleSetWalletFilter}
            >
              <FontAwesomeIcon icon={faFilter} style={{ marginRight: "7px" }} />
              Remove Attribution Category Filter
            </Button>
            <DropdownButton
              className="whiteButton"
              title={
                <>
                  {" "}
                  <FontAwesomeIcon icon={faSort} /> Sort{" "}
                </>
              }
            >
              <MenuItem eventKey="new" onSelect={this.handleSwapTransactionOrder}>
                {order === "oldestFirst" || order === "largestFirst"
                  ? "Sort by Newest"
                  : "Sort by Oldest"}
              </MenuItem>
              <MenuItem eventKey="new" onSelect={this.handleSetLargestFirst}>
                Sort by Largest
              </MenuItem>
              <MenuItem eventKey="new" onSelect={this.handleOpenDateSelectModal}>
                Sort by Date Range
              </MenuItem>
            </DropdownButton>
            <span
              style={{
                backgroundColor: "var(--base-color)",
                borderRadius: "7px",
                border: "2px solid #337ab7",
                color: "white",
                fontWeight: "700",
                fontSize: "12px",
                fontFamily: "Quicksand",
                padding: "2px 10px 2px 10px",
                marginLeft: "10px"
              }}
            >
              {filterText}
            </span>
            <Button
              className="whiteButton"
              onClick={this.fetchAddressTransactions}
              disabled={!this.props.hasMore}
              style={{ float: "right" }}
            >
              {buttonText}
            </Button>
            {this.props.transactions.length !== 0 && this.props.fromAddressView ? (
              <div style={{ float: "right" }}>
                <DataDownload
                  data={Object.keys(this.props.transactions).map(trx => {
                    const item = this.props.transactions[trx];

                    return {
                      "Transaction Hash": item.transactionHash,
                      Timestamp: moment
                        .unix(item.timestamp)
                        .utc()
                        .format("LLL"),
                      "Input (Bitcoin)": satoshiToBitcoin(item.inputSatoshi),
                      "Output (Bitcoin)": satoshiToBitcoin(item.outputSatoshi),
                      "Transaction Balance (Bitcoin)": satoshiToBitcoin(item.satoshi),
                      "Input (USD)": item.priceAvailable
                        ? "$" +
                          (satoshiToBitcoinNoCommas(item.inputSatoshi) * item.price).toFixed(2)
                        : "N/A",
                      "Output (USD)": item.priceAvailable
                        ? "$" +
                          (satoshiToBitcoinNoCommas(item.outputSatoshi) * item.price).toFixed(2)
                        : "N/A",
                      "Transaction Balance (USD)": item.priceAvailable
                        ? "$" + (satoshiToBitcoinNoCommas(item.satoshi) * item.price).toFixed(2)
                        : "N/A",
                      "USD Price": item.priceAvailable ? "$" + item.price.toFixed(2) : "N/A"
                    };
                  })}
                  entity_type="transaction"
                  style={{ float: "right", margin: "10px" }}
                />
              </div>
            ) : (
              <div style={{ float: "right" }}>
                <DataDownload
                  data={this.props.transactions.map(item => {
                    let received;
                    let sent;
                    // When a wallet interacts with itself it will return 2 results. Otherwise, it'll be one
                    if (item.satoshi.length === 2) {
                      received = Math.max.apply(null, item.satoshi);
                      sent = Math.min.apply(null, item.satoshi);
                    } else {
                      if (item.satoshi[0] > 0) {
                        received = item.satoshi[0];
                        sent = 0;
                      } else {
                        received = 0;
                        sent = item.satoshi[0];
                      }
                    }
                    return {
                      "Transaction Hash": item.transactionHash,
                      Timestamp: moment
                        .unix(item.timestamp)
                        .utc()
                        .format("LLL"),
                      "Input (Bitcoin)": satoshiToBitcoin(sent),
                      "Output (Bitcoin)": satoshiToBitcoin(received),
                      "Transaction Balance (Bitcoin)": satoshiToBitcoin(sent + received),
                      "Input (USD)": item.priceAvailable
                        ? "$" + (satoshiToBitcoinNoCommas(sent) * item.price).toFixed(2)
                        : "N/A",
                      "Output (USD)": item.priceAvailable
                        ? "$" + (satoshiToBitcoinNoCommas(received) * item.price).toFixed(2)
                        : "N/A",
                      "Transaction Balance (USD)": item.priceAvailable
                        ? "$" + (satoshiToBitcoinNoCommas(sent + received) * item.price).toFixed(2)
                        : "N/A",
                      "USD Price": item.priceAvailable ? "$" + item.price.toFixed(2) : "N/A"
                    };
                  })}
                  entity_type="transaction"
                  style={{ float: "right", margin: "10px" }}
                />
              </div>
            )}
          </div>
        </div>
        <div style={{ display: "inline" }}>
          <Button className="blueButton" style={{ marginRight: "10px" }} onClick={this.props.back}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "5px" }} />
            Back
          </Button>
          <p
            style={{
              display: "inline-block",
              fontWeight: "600",
              fontSize: "20px",
              color: "#666"
            }}
          >
            {" "}
            Transactions between{" "}
            <span style={{ color: "var(--base-color)", fontWeight: "700" }}>
              {this.props.name}
            </span>{" "}
            and{" "}
            <span style={{ color: "var(--base-color)", fontWeight: "700" }}>
              {this.props.destinationWallet.tag || this.props.destinationWallet.walletId}
            </span>
          </p>
        </div>
        {this.props.mostRecentCallSuccess ? (
          this.props.transactions.length > 0 ? (
            <ScrollDiv maxHeight={this.props.maxHeight} id="addressTransactionsTable">
              <Table
                className="inviteTable"
                style={{
                  width: "100%",
                  tableLayout: "fixed",
                  overflow: "auto",
                  wordWrap: "break-word"
                }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "15%" }}>Timestamp</th>
                    <th style={{ width: "46%" }}>Transaction</th>
                    <th style={{ textAlign: "right", width: "13%" }}>Sent (Inputs)</th>
                    <th style={{ textAlign: "right", width: "13%" }}>Received (Outputs)</th>
                    <th style={{ textAlign: "right", width: "13%" }}>Trx Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.fromAddressView
                    ? Object.keys(this.props.transactions).map(trx => {
                        let price = this.props.transactions[trx].price;
                        let received = this.props.transactions[trx].outputSatoshi;
                        let sent = -this.props.transactions[trx].inputSatoshi;
                        const balance = satoshiToBitcoin(received + sent);
                        received = satoshiToBitcoinNoCommas(received);
                        sent = satoshiToBitcoinNoCommas(sent);
                        return (
                          <tr className="inviteRowThin staticTableRow">
                            <td>
                              {moment
                                .unix(this.props.transactions[trx].timestamp)
                                .utc()
                                .format("LLL")}
                            </td>
                            <td>
                              <CopyText
                                text={this.props.transactions[trx].transactionHash}
                                marginLeft="0"
                              />
                              <Link
                                to={`/${this.props.currency}/transaction/${this.props.transactions[trx].transactionHash}`}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {" "}
                                {this.props.transactions[trx].transactionHash}{" "}
                              </Link>
                            </td>

                            <td style={{ textAlign: "right" }}>
                              {chooseBetweenConversions(
                                [numberWithCommas(sent), !showAsUsd],
                                [
                                  "$" + numberWithCommas(convertPriceToUsd(sent, currentUsdPrice)),
                                  showAsUsd
                                ],
                                [
                                  "$" + numberWithCommas((sent * price).toFixed(2)),
                                  historicalToUsd
                                ],
                                priceAvailable(trx)
                              )}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {chooseBetweenConversions(
                                [numberWithCommas(received), !showAsUsd],
                                [
                                  "$" +
                                    numberWithCommas(convertPriceToUsd(received, currentUsdPrice)),
                                  showAsUsd
                                ],
                                [
                                  "$" + numberWithCommas((received * price).toFixed(2)),
                                  historicalToUsd
                                ],
                                priceAvailable(trx)
                              )}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {chooseBetweenConversions(
                                [balance, !showAsUsd],
                                [
                                  "$" +
                                    numberWithCommas(convertPriceToUsd(balance, currentUsdPrice)),
                                  showAsUsd
                                ],
                                [
                                  "$" + numberWithCommas((balance * price).toFixed(2)),
                                  historicalToUsd
                                ],
                                priceAvailable(trx)
                              )}
                            </td>
                          </tr>
                        );
                      })
                    : Object.keys(this.props.transactions).map(trx => {
                        let received;
                        let sent;
                        let price = this.props.transactions[trx].price;
                        // When a wallet interacts with itself it will return 2 results. Otherwise, it'll be one
                        if (this.props.transactions[trx].satoshi.length === 2) {
                          received = Math.max.apply(null, this.props.transactions[trx].satoshi);
                          sent = Math.min.apply(null, this.props.transactions[trx].satoshi);
                        } else {
                          if (this.props.transactions[trx].satoshi[0] > 0) {
                            received = this.props.transactions[trx].satoshi[0];
                            sent = 0;
                          } else {
                            received = 0;
                            sent = this.props.transactions[trx].satoshi[0];
                          }
                        }
                        const balance = satoshiToBitcoin(received + sent);
                        received = satoshiToBitcoinNoCommas(received);
                        sent = satoshiToBitcoinNoCommas(sent);

                        return (
                          <tr className="inviteRowThin staticTableRow">
                            <td>
                              {moment
                                .unix(this.props.transactions[trx].timestamp)
                                .utc()
                                .format("LLL")}
                            </td>
                            <td>
                              <CopyText
                                text={this.props.transactions[trx].transactionHash}
                                marginLeft="0"
                              />
                              <Link
                                to={`/${this.props.currency}/transaction/${this.props.transactions[trx].transactionHash}`}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {" "}
                                {this.props.transactions[trx].transactionHash}{" "}
                              </Link>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {chooseBetweenConversions(
                                [numberWithCommas(sent), !showAsUsd],
                                [
                                  "$" + numberWithCommas(convertPriceToUsd(sent, currentUsdPrice)),
                                  showAsUsd
                                ],
                                [
                                  "$" + numberWithCommas((sent * price).toFixed(2)),
                                  historicalToUsd
                                ],
                                priceAvailable(trx)
                              )}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {chooseBetweenConversions(
                                [numberWithCommas(received), !showAsUsd],
                                [
                                  "$" +
                                    numberWithCommas(convertPriceToUsd(received, currentUsdPrice)),
                                  showAsUsd
                                ],
                                [
                                  "$" + numberWithCommas((received * price).toFixed(2)),
                                  historicalToUsd
                                ],
                                priceAvailable(trx)
                              )}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {chooseBetweenConversions(
                                [balance, !showAsUsd],
                                [
                                  "$" +
                                    numberWithCommas(convertPriceToUsd(balance, currentUsdPrice)),
                                  showAsUsd
                                ],
                                [
                                  "$" + numberWithCommas((balance * price).toFixed(2)),
                                  historicalToUsd
                                ],
                                priceAvailable(trx)
                              )}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </Table>
            </ScrollDiv>
          ) : (
            <div style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}>
              <CircularProgress style={{ color: "var(--secondary-color)" }} />
            </div>
          )
        ) : (
          <div style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}>
            <p style={{ fontSize: "20px", fontWeight: "600" }}>
              {" "}
              Unable to fetch those transactions at this time.{" "}
            </p>
          </div>
        )}

        <Modal show={this.state.showDateSelectModal} onHide={this.handleCloseDateSelectModal}>
          <Modal.Header closeButton>
            <Modal.Title>Select dates</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.loadItemsByDate}>
              <FormGroup controlId="startDate">
                <ControlLabel>Start date</ControlLabel>
                <FormControl
                  type="date"
                  value={this.state.fields.startDate}
                  placeholder=""
                  onChange={this.handleInputChange}
                />
              </FormGroup>
              <FormGroup controlId="endDate">
                <ControlLabel>End date</ControlLabel>
                <FormControl
                  type="date"
                  value={this.state.fields.endDate}
                  placeholder=""
                  onChange={this.handleInputChange}
                />
              </FormGroup>
              <Button type="submit">Set date</Button>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

MutualWallet.propTypes = {
  //sourceId takes either a walletID or an address
  sourceId: PropTypes.string.isRequired,
  sourceTag: PropTypes.string.isRequired,
  destinationWallet: PropTypes.string.isRequired,
  getTransactionLink: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  getMutualTransactions: PropTypes.func.isRequired,
  setDateRange: PropTypes.func.isRequired,
  setLargest: PropTypes.func.isRequired,
  fetchMoreTransactions: PropTypes.func.isRequired,
  swapOrder: PropTypes.func.isRequired,
  fromAddressView: PropTypes.bool.isRequired,
  convertPriceToUsd: PropTypes.func.isRequired,
  currentUsdPrice: PropTypes.number,
  historicalToUsd: PropTypes.bool.isRequired
};

const makeMapStateToProps = (state, ownProps) => {
  const getWalletMutualTransactions = ownProps.getMutualTransactions();
  return (state, { sourceId, destinationWallet }) => ({
    ...getWalletName(state, sourceId),
    ...getWalletMutualTransactions(state, sourceId, destinationWallet.walletId)
  });
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSetDate: (sourceId, walletId, tag, order, startDate, endDate) =>
    dispatch(ownProps.setDateRange(sourceId, walletId, tag, order, startDate, endDate)),

  // fetch is used to fetch the next set of transactions after clicking the load more button
  fetch: (sourceId, walletId, tag, order, startDate = null, endDate = null, loadMore = false) => {
    dispatch(
      ownProps.fetchMoreTransactions(sourceId, walletId, tag, order, startDate, endDate, loadMore)
    );
  },
  onSetLargestFirst: (
    sourceId,
    walletId,
    tag,
    order = "largestFirst",
    startDate = null,
    endDate = null,
    loadMore = false
  ) => {
    dispatch(ownProps.setLargest(sourceId, walletId, tag, order, startDate, endDate, loadMore));
  },
  onSwapOrder: (sourceId, walletId) => dispatch(ownProps.swapOrder(sourceId, walletId))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(MutualWallet);
