import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import Countries from "../countries";
import StatCard from "../StatCard";
import { getCurrency } from "../../selectors/currency";
import { getClientMode } from "../../selectors/applicationConfig";

class MoreInfoAddressSummary extends Component {
  state = {
    expanded: false,
    showModal: false,
    current_ips: this.props.ip,
    current_ip_location: "Everywhere"
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.ip !== this.props.ip) {
      this.setState({
        current_ips: this.props.ip,
        current_ip_location: "Everywhere"
      });
    }
  }

  showModal = () => {
    this.setState({ showModal: true });
  };

  setCurrentIps = (ips, withCities = true) => {
    let city = null;
    let country = null;

    ips.forEach(info => {
      if (city === null && info.city !== null && info.city !== "" && withCities) {
        city = info.city;
      }
      if (country === null && info.country !== null && info.country !== "") {
        country = info.country;
      }
    });

    this.setState({
      current_ips: ips,
      current_ip_location: `${city !== null ? `${city}, ` : ""}${
        country !== null ? Countries[country] : ""
      }`
    });
  };

  render() {
    const {
      risk,
      sanctions_risk,
      gambling_ok_risk,
      ip,
      inCase,
      confidence_score,
      attribution_source,
      countries,
      cities,
      long_lat
    } = this.props;
    // Move the countries to an array for the chart
    let _countries = Object.keys(countries).map(country => [country, countries[country].length]);
    _countries = [["country", "value"]].concat(_countries);

    // Move the cities to an array for the chart
    let _cities = Object.keys(cities).map(city => [city, cities[city].length]);
    _cities = [["city", "value"]].concat(_cities);

    // Make the markers for the maps
    const markers = [];

    long_lat &&
      Object.keys(long_lat).forEach(long => {
        Object.keys(long_lat[long]).forEach(lat => {
          const ips = [];
          Object.keys(long_lat[long][lat]).forEach(ip => ips.push(long_lat[long][lat][ip]));
          markers.push([long, lat, ips]);
        });
      });

    const _setCurrentIps = this.setCurrentIps;
    return (
      <>
        {/*  {this.state.expanded ? (*/}
        <div>
          <div className="row" style={{ paddingLeft: "15px" }}>
            {/*{this.props.clientMode !== "vci" && (*/}
            {/*  <div className="col-md-4 col-lg-4 infoCol">*/}
            {/*    <div className="infoCards">*/}
            {/*      <p*/}
            {/*        style={{*/}
            {/*          color: "var(--secondary-color)",*/}
            {/*          fontFamily: "Quicksand",*/}
            {/*          fontWeight: "700",*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        {" "}*/}
            {/*        Risk{" "}*/}
            {/*      </p>*/}
            {/*      <StatCard stat="Risk Score" value={risk} />*/}
            {/*      <StatCard stat="Gambling Ok Risk Score" value={gambling_ok_risk} />*/}
            {/*      <StatCard stat="Sanctions Risk Score" value={sanctions_risk} />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*)}*/}
            {this.props.clientMode !== "vci" && confidence_score && attribution_source && (
              <div className="col-md-4 col-lg-4 infoCol">
                <div className="infoCards">
                  <p
                    style={{
                      color: "var(--secondary-color)",
                      fontFamily: "Quicksand",
                      fontWeight: "700"
                    }}
                  >
                    {" "}
                    Attribution{" "}
                  </p>
                  <StatCard stat="Attribution Source" value={attribution_source} />
                  <StatCard stat="Confidence Score" value={confidence_score} />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

MoreInfoAddressSummary.propTypes = {
  address: PropTypes.string.isRequired
};

MoreInfoAddressSummary.defaultProps = {
  countries: {},
  cities: {},
  long_lat: {},
  ip: [],
  risk: 0,
  gambling_ok_risk: 0,
  sanctions_risk: 0
};

const mapStateToProps = (state, { address }) => {
  return {
    // ...getAddressMoreInfo(state, address),
    currency: getCurrency(state),
    clientMode: getClientMode(state)
  };
};

export default connect(
  mapStateToProps,
  null
)(MoreInfoAddressSummary);
