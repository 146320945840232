// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { List, Map, Record } from "immutable";
import { ADDRESS_RESEARCH_RAW_DATA_FETCH_SUCCESS, LOGGING_OUT } from "../actions/actionNames";

/*
Search State
Search: List<SearchResult>

SearchResult: {
  url: string | null,
  hasMultipleResults: string | null,
}
 */

export const ResearchResults = Record({
  results: new List(),
});

export const ResearchResult = Record({
  tag: "",
  source: "",
  category: "",
});

const getResearchRecord = (state, address) => state.get(address) || new ResearchResults();

const addResearchRawResults = (state, action) => {
  const { item: address_, data } = action;
  let record = getResearchRecord(state, address_);
  let resultList = record.get("results");
  // Iterate through the list of results and add them to the result list
  data.results.forEach(item => {
    const result = new ResearchResult(item);
    resultList = resultList.push(result);
  });
  // Set the result list in the record
  record = record.set("results", resultList);
  // set the results record for the address
  return state.set(address_, record);
};

const makeResearchReducer = coin => {
  return (state = Map(), action) => {
    // If the coin doesn't match the reducer, just return the state.
    if (action === undefined || action.name !== coin) {
      return state;
    }

    switch (action.type) {
      case ADDRESS_RESEARCH_RAW_DATA_FETCH_SUCCESS:
        return addResearchRawResults(state, action);
      case LOGGING_OUT:
        return Map();
      default:
        return state;
    }
  };
};

export default makeResearchReducer;
