// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { List, Map, Record } from "immutable";

import * as fromTag from "../actions/mention";

/*
Mention State
Mention: Map<number, Mention>

Mention: {
  addressToPosts: Map<number, Post>,
  walletToPosts: Map<number, Post>,
}

Post: {
  content: string,
  originUrl: string | null,
  firstSeenAt: number,
}
 */

export const Post = Record({
  content: "",
  originUrl: null,
  firstSeenAt: 0,
});

export const Mention = Record({
  addressToPosts: Map(), // List of Mention records
  walletToPosts: Map(), // List of Mention records
});

const getMention = (state, onlineAccountId) => state.get(onlineAccountId) || new Mention();

const setAddressMention = (state, action) => {
  const {
    address,
    onlineAccountId,
    data: { posts },
  } = action;

  const mentionRecords = List(posts.map(post => new Post(post)));
  let mention = getMention(state, onlineAccountId);
  mention = mention.setIn(["addressToPosts", address], mentionRecords);
  return state.set(onlineAccountId, mention);
};

const setWalletMention = (state, action) => {
  const {
    wallet,
    onlineAccountId,
    data: { posts },
  } = action;

  const mentionRecords = List(posts.map(mention => new Post(mention)));
  let tag = getMention(state, onlineAccountId);
  tag = tag.setIn(["walletToPosts", wallet], mentionRecords);
  return state.set(onlineAccountId, tag);
};

const makeMentionReducer = coin => {
  return (state = Map(), action) => {
    // If the coin doesn't match the reducer, just return the state.
    if (action === undefined || action.name !== coin) {
      return state;
    }

    switch (action.type) {
      case fromTag.MENTION_ADDRESS_POSTS_FETCH_SUCCESS:
        return setAddressMention(state, action);
      case fromTag.MENTION_WALLET_POSTS_FETCH_SUCCESS:
        return setWalletMention(state, action);
      default:
        return state;
    }
  };
};

export default makeMentionReducer;
