/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AuthenticatedRoutes from "./AuthenticatedRoutes";
import UnauthenticatedRoutes from "./UnauthenticatedRoutes";
import {
  getLoggedIn,
  getTwoFAConfigured,
  getTwoFASuccess
} from "../../selectors/authentication";
import TwoFactorRoutes from "./TwoFactorRoutes";

export const Routes = ({ loggedIn, twoSuccess }) => {
  // If logged in and successfully entered 2fa code
  if (loggedIn && twoSuccess) {
    return <AuthenticatedRoutes style={{ color: "blue" }} />;
  }

  // If logged in but have not yet entered 2fa code
  if (loggedIn && !twoSuccess) {
    return <TwoFactorRoutes style={{ color: "blue" }} />;
  }

  // If not logged in
  return <UnauthenticatedRoutes style={{ color: "blue" }} />;
};

Routes.propTypes = {
  loggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  loggedIn: getLoggedIn(state),
  twoConfigured: getTwoFAConfigured(state),
  twoSuccess: getTwoFASuccess(state)
});

// withRouter needed to ensure redirects work
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
export default withRouter(connect(mapStateToProps)(Routes));
