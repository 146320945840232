import React, { useEffect, useState } from "react";
import WrappedLink from "../Utils/WrappedLink";

export const TokenTransferItem = ({
  sender_id,
  receiver_id,
  value,
  decimals,
  token_account_id,
  label,
  standard,
  stateBased = false,
}) => {
  const niceValue = Number(value / Math.pow(10, decimals).toFixed(5)).toLocaleString("en");
  return (
    <p>
      <WrappedLink entity={sender_id} entityType={"address"} stateBased={stateBased}>
        {sender_id}
      </WrappedLink>
      {` sent ${standard !== "erc721" ? niceValue : ""}(`}
      <WrappedLink entity={token_account_id} entityType={"address"} stateBased={stateBased}>
        {label}
      </WrappedLink>
      {`) to `}
      <WrappedLink entity={receiver_id} entityType={"address"} stateBased={stateBased}>
        {receiver_id}
      </WrappedLink>
    </p>
  );
};
