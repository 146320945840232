import React from "react";
import StatCard from "../../StatCard";
import { numberWithCommas } from "../../../helpers";
import CopyText from "../../CopyText";

export const TokenSummary = ({
  address,
  creation_transaction_id,
  transfer_count,
  symbol,
  decimals
}) => {
  return (
    <div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <p
            style={{
              color: "var(--secondary-color)",
              fontFamily: "Quicksand",
              fontWeight: "700",
              fontSize: "20px"
            }}
          >
            Token Summary
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-6 col-lg-6 infoCol">
          <div className="infoCards">
            <p
              style={{
                color: "var(--secondary-color)",
                fontFamily: "Quicksand",
                fontWeight: "700"
              }}
            >
              Token Info
            </p>
            <StatCard
              stat="Symbol"
              value={
                <div>
                  {symbol && <CopyText text={symbol} marginRight="10px" marginLeft="0" />}
                  {symbol || "N/A"}
                </div>
              }
            />
            <StatCard stat="Decimals" value={decimals !== null ? decimals : "N/A"} />

            <StatCard stat="Total Transfers" value={numberWithCommas(transfer_count || 0)} />
          </div>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 infoCol">
          <div className="infoCards">
            <p
              style={{
                color: "var(--secondary-color)",
                fontFamily: "Quicksand",
                fontWeight: "700"
              }}
            >
              Creation
            </p>
            <StatCard
              stat="Contract Address"
              value={
                <div
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    paddingBottom: "0"
                  }}
                >
                  <CopyText text={address} marginRight="10px" marginLeft="0" />
                  {address}
                </div>
              }
            />
            {creation_transaction_id && (
              <StatCard stat="Contract Creation Trx" value={creation_transaction_id || "N/A"} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
