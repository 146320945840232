import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMessagesNotViewed, getShowMessages } from "../../selectors/messages";
import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { faEnvelope, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteMessage, ignoreMessage, markAsRead } from "../../actions/messages";
import { Share } from "./Share";
import { DefaultMessageContent } from "./DefaultMessageContent";

export const NewMessageHandler = ({
  fontSize = "1.5rem",
  showNewMessages = true,
  closeNewMessages = () => {}
}) => {
  const messages = useSelector(state => getMessagesNotViewed(state));
  const showMessages = useSelector(state => getShowMessages(state));

  const dispatch = useDispatch();

  const [messageIdx, setMessageIdx] = useState<number>(0);

  useEffect(() => {
    if (messageIdx >= messages.length) {
      setMessageIdx(messages.length - 1);
    }
  }, [messages]);
  const useStyles = makeStyles({
    dialog: {
      maxHeight: "60vh",
      position: "absolute"
    },
    dialogRoot: {
      zIndex: "100000 !important"
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: fontSize
    }
  });
  const classes = useStyles();

  const dissectActionLink = (action_link: string, message_id: number) => {
    const url_split = action_link.split("/");
    const coin = url_split[1];
    const token = url_split[url_split.length - 1];
    return (
      <Share
        token={token}
        redirect={false}
        afterResolve={() => {
          if (messageIdx >= messages.length - 1) {
            setMessageIdx(Math.max(0, messages.length - 2));
          }
          dispatch(deleteMessage(message_id));
        }}
        currency={coin}
      />
    );
  };
  if (!showMessages) {
    return <></>;
  }
  return (
    <>
      {messages && messages.length > 0 && (
        <Dialog
          open={showNewMessages}
          fullWidth
          onClose={closeNewMessages}
          classes={{ paper: classes.dialog, root: classes.dialogRoot }}
        >
          <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
            <h4>{`New Message ${messageIdx + 1} of ${messages.length}`}</h4>
            <IconButton onClick={closeNewMessages}>
              <FontAwesomeIcon icon={faTimes} style={{ fontSize: "15px" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {messages[messageIdx].custom_entity ? (
              dissectActionLink(messages[messageIdx].action_link, messages[messageIdx].message_id)
            ) : (
              <DefaultMessageContent
                message={messages[messageIdx].message}
                title={messages[messageIdx].title}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setMessageIdx(messageIdx - 1)} disabled={messageIdx <= 0}>
              Previous
            </Button>
            <Button
              onClick={() => setMessageIdx(messageIdx + 1)}
              disabled={messageIdx >= messages.length - 1}
            >
              Next
            </Button>
            <Button
              onClick={() => {
                const originalIdx = messageIdx;
                if (messageIdx === messages.length - 1 && messageIdx > 0) {
                  setMessageIdx(messageIdx - 1);
                }
                dispatch(ignoreMessage(messages[originalIdx].message_id));
              }}
            >
              Ignore
            </Button>
            <Button
              onClick={() => {
                const originalIdx = messageIdx;
                if (messageIdx === messages.length - 1 && messageIdx > 0) {
                  setMessageIdx(messageIdx - 1);
                }
                dispatch(markAsRead(messages[originalIdx].message_id));
              }}
            >
              Mark as Read
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
