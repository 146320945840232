// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { createSelector } from "reselect";
import { AddressSummary, ClusterHistoryInfo } from "../reducers/address";
import { getCurrency } from "./currency";
import Countries from "../components/countries";

export const getAddress = (state, address) => {
  return state.getIn([getCurrency(state), "address", address]);
};

export const getAddressResearch = (state, address) => {
  return state.getIn([getCurrency(state), "research", address]);
};

export const getAddressRisk = (state, address) => {
  const addressMap = getAddress(state, address);
  if (addressMap) {
    return addressMap.get("risk");
  }
  return null;
};

export const getMutualTransactions = (state, address, walletId) => {
  const currency = getCurrency(state);
  return state.getIn([currency, "address", address, "mutualWallets", "mutualWallets", walletId]);
};

export const getAddressSummary = (state, address, noNull = false) => {
  const addressMap = getAddress(state, address);
  if (addressMap) {
    return addressMap.get("summary").toJS();
  }
  if (noNull) {
    return new AddressSummary().toJS();
  }
  return null;
};

export const getAddressResearchData = (state, address) => {
  const addressMap = getAddressResearch(state, address);
  if (addressMap) {
    return addressMap.get("results").toJS();
  }
  return null;
};

export const getAddressMentions = (state, address) => {
  const currency = getCurrency(state);
  return state.getIn([currency, "address", address, "mentions"], null);
};

export const getClusterHistory = (state, address, noNull = false) => {
  const addressMap = getAddress(state, address);
  if (addressMap) {
    return addressMap.get("clusterHistory") && addressMap.get("clusterHistory").toJS();
  }
  if (noNull) {
    return new ClusterHistoryInfo().toJS();
  }
  return null;
};

export const getAddressMoreInfo = (state, address) => {
  // Get the address record from redux
  const addressMap = getAddress(state, address);

  if (addressMap) {
    const items = addressMap.get("moreInfo").toJS();
    const ips = items.ip;
    const countries = {};
    const cities = {};
    const long_lat = {};

    ips.forEach(ip => {
      let { city, country, longitude, latitude } = ip;

      // Add the record for the city to the dict
      if (city !== null && city !== "") {
        Object.keys(cities).includes(city) ? cities[city].push(ip) : (cities[city] = [ip]);
      }

      // Add the record for the country to the dict
      if (country !== null && country !== "") {
        Object.keys(countries).includes(Countries[country])
          ? countries[Countries[country]].push(ip)
          : (countries[Countries[country]] = [ip]);
      }

      // Add the lat/long as a dict
      if (longitude !== null && latitude !== null) {
        // Round the lat and long solely for plotting purposes
        longitude = longitude.toFixed(1);
        latitude = latitude.toFixed(1);
        Object.keys(long_lat).includes(longitude)
          ? Object.keys(long_lat[longitude]).includes(latitude)
            ? long_lat[longitude][latitude].push(ip)
            : (long_lat[longitude][latitude] = [ip])
          : (long_lat[longitude] = {
              [latitude]: [ip]
            });
      }
    });

    return {
      ...items,
      countries,
      cities,
      long_lat
    };

    // const moreInfo = addressMap.get("moreInfo");
    // return {
    //   risk: moreInfo.get("risk"),
    //   sanctions_risk: moreInfo.get("sanctions_risk"),
    //   gambling_ok_risk: moreInfo.get("gambling_ok_risk"),
    //   ip: moreInfo.get("ip"),
    //   inCase: moreInfo.get("inCase"),
    // }
  }
  return null;
};

export const getAddressCoinSwap = (state, address) => {
  // Get the address record from redux
  const addressMap = getAddress(state, address);

  if (addressMap) {
    return {
      coinSwapData: addressMap.get("coinSwapData").toJS(),
      coinSwap: addressMap.get("coinSwap")
    };
  }
  return null;
};

export const getAddressMutualWallets = (state, address) => {
  const addressMap = getAddress(state, address);

  if (addressMap) {
    const mutualWallets = addressMap.get("mutualWallets");
    return {
      mutualWallets: mutualWallets.get("mutualWallets"),
      mostRecentCallSuccess: mutualWallets.get("mostRecentCallSuccess"),
      walletsFetched: mutualWallets.get("walletsFetched")
    };
  }
  return null;
};

export const getWalletMutualTransactions = (state, address, walletId) => {
  const addressMap = getAddress(state, address);

  // Make sure the addressMap exists before attempting to access inner attributes
  if (addressMap) {
    const walletTransactionMap = getMutualTransactions(state, address, walletId);
    if (walletTransactionMap) {
      return {
        transactions: walletTransactionMap.get("transactions"),
        mostRecentCallSuccess: walletTransactionMap.get("mostRecentCallSuccess")
      };
    }
  }
  return null;
};

export const getAddressMutualWallets2 = (state, address) => {
  const f = () => {
    const addressMap = getAddress(state, address);
    // This is race condition if addressMap gets filled by some other API call.
    // There needs to be a better way of preventing the call.
    if (addressMap) {
      const mutualWallets = addressMap.get("mutualWallets");
      return {
        mutualWallets: mutualWallets.get("mutualWallets"),
        mostRecentCallSuccess: mutualWallets.get("mostRecentCallSuccess"),
        walletsFetched: mutualWallets.get("walletsFetched")
      };
    }
    return null;
  };

  const result = f(state, address);

  if (!result) {
    return {
      mutualWallets: [],
      mostRecentCallSuccess: true,
      mutualWalletsSorted: {},
      walletsFetched: false
    };
  }

  const { mostRecentCallSuccess } = result;
  const { walletsFetched } = result;
  const mutualWalletsSorted = {};
  result.mutualWallets.forEach(entry => {
    const { category } = entry;

    if (mutualWalletsSorted[category] === undefined) {
      mutualWalletsSorted[category] = [entry];
    } else {
      mutualWalletsSorted[category].push(entry);
    }
  });

  // Sort each category array by the number of transactions
  Object.keys(mutualWalletsSorted).forEach(entry => {
    mutualWalletsSorted[entry].sort((a, b) => {
      const x = a.totalTrx;
      const y = b.totalTrx;
      return x > y ? -1 : x < y ? 1 : 0;
    });
  });

  return {
    mutualWallets: result.mutualWallets,
    mostRecentCallSuccess,
    mutualWalletsSorted,
    walletsFetched
  };
};

export const makeGetAddressMutualWallets = () =>
  createSelector(
    [getAddressMutualWallets],
    addressMutualWallets => {
      if (!addressMutualWallets) {
        return {
          mutualWallets: {},
          mostRecentCallSuccess: true
        };
      }

      const { mostRecentCallSuccess } = addressMutualWallets;

      return {
        mutualWallets: addressMutualWallets.mutualWallets,
        mostRecentCallSuccess
      };
    }
  );

export const makeGetWalletMutualTransactions = () =>
  createSelector(
    [getWalletMutualTransactions],
    walletMutualTransactions => {
      if (!walletMutualTransactions) {
        return {
          transactions: {},
          newestFirst: [],
          oldestFirst: [],
          order: "newestFirst",
          hasMore: false
        };
      }
      const { transactionMap } = walletMutualTransactions.transactions;

      // get the key to the list of transactionIds
      let key;
      if (walletMutualTransactions.transactions.get("order") === "largestFirst") {
        key = "largestFirst";
      } else if (walletMutualTransactions.transactions.get("order") === "newestFirst") {
        key = "newestFirst";
      } else {
        key = "oldestFirst";
      }

      const hasMore = walletMutualTransactions.transactions.get(`${key}HasMore`);
      const transactionIds = walletMutualTransactions.transactions.get(key);

      const transactions = transactionIds
        .map(transactionId => transactionMap.get(transactionId).toJS())
        .toJS();

      const { startDate } = walletMutualTransactions.transactions;
      const { endDate } = walletMutualTransactions.transactions;
      const { mostRecentCallSuccess } = walletMutualTransactions;
      return {
        transactions,
        newestFirst: walletMutualTransactions.transactions.get("newestFirst").toJS(),
        oldestFirst: walletMutualTransactions.transactions.get("oldestFirst").toJS(),
        largestFirst: walletMutualTransactions.transactions.get("largestFirst").toJS(),
        order: walletMutualTransactions.transactions.get("order"),
        hasMore,
        mostRecentCallSuccess,
        startDate,
        endDate
      };
    }
  );

export const makeGetAddressMentions = () =>
  createSelector(
    [getAddressMentions],
    addressMentions => {
      if (!addressMentions) {
        return {
          mentions: [],
          key: 0
        };
      }
      return {
        mentions: addressMentions
          .get("mentions")
          .map(mention => mention.toJS())
          .toJS(),
        nextKey: addressMentions.get("nextKey")
      };
    }
  );

// have this keep as immutable as default and used by action creators
// have a version that converts that will be used by components
export const getAddressTransactions = (state, address) => {
  return state.getIn([getCurrency(state), "address", address, "transactions"], null);
};

export const getScrollTop = (state, address) => {
  return state.getIn([getCurrency(state), "address", address, "scrollTop"], null);
};

export const makeGetAddressTransactions = () =>
  createSelector(
    [getAddressTransactions],
    addressTransactions => {
      if (!addressTransactions) {
        return {
          transactions: [], // list of transaction objects
          newestFirstNextKey: 0,
          oldestFirstNextKey: 0,
          order: 1,
          largestFirstNextKey: 0,
          hasNextLargest: false,
          startDate: null,
          endDate: null
        };
      }
      const transactionMap = addressTransactions.get("transactionMap");

      // get the key to the list of transactionIds
      let key;
      if (addressTransactions.get("order") === 2) {
        key = "largestFirst";
      } else if (addressTransactions.get("order") === 1) {
        key = "newestFirst";
      } else if (addressTransactions.get("order") === -1) {
        key = "oldestFirst";
      }
      const largestFirstNextKey = addressTransactions.getIn(["largestFirstNextKey", "primary"]);
      const hasNextLargest = addressTransactions.getIn(["largestFirstNextKey", "primary"]) != null;
      const transactionIds = addressTransactions.get(key);
      const startDate = addressTransactions.get("startDate");
      const endDate = addressTransactions.get("endDate");
      const transactions = transactionIds
        .map(transactionId => transactionMap.get(transactionId).toJS())
        .toJS();
      return {
        transactions,
        newestFirstNextKey: addressTransactions.get("newestFirstNextKey"),
        oldestFirstNextKey: addressTransactions.get("oldestFirstNextKey"),
        order: addressTransactions.get("order"),
        largestFirstNextKey,
        hasNextLargest,
        startDate,
        endDate
      };
    }
  );

export const retrieveBsa = (state, address) => {
  const currency = getCurrency(state);
  const final = state.getIn([currency, "address", address, "bsa", "bsaIds"]);
  const len = state.getIn([currency, "address", address, "bsa", "numberToShow"]);

  if (final !== undefined) {
    return final.take(len).toJS();
  }
  return [];
};

export const getBsaKey = (state, address) => {
  return state.getIn([getCurrency(state), "address", address, "bsa", "bsaKey"]);
};

export const retrieveBsaCount = (state, address) => {
  return state.getIn([getCurrency(state), "address", address, "summary", "bsaMentionsCount"], 0);
};

export const retrieveNumberToShow = (state, address) => {
  return state.getIn([getCurrency(state), "address", address, "bsa", "numberToShow"], 0);
};

export const retrieveBsaCountRemaining = (state, address) => {
  const currency = getCurrency(state);

  let ids = state.getIn([currency, "address", address, "bsa", "bsaIds"]);
  const total = state.getIn([currency, "address", address, "summary", "bsaMentionsCount"], 0);
  let final;
  if (ids !== undefined) {
    ids = ids.toJS();
    final = total - ids.length;
    return final;
  }
  return total;
};

export const retrieveMentionsCount = (state, address) => {
  return state.getIn([getCurrency(state), "address", address, "summary", "mentionsCount"], 0);
};
