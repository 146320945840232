import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Grid, Row } from "react-bootstrap";
import Bitcoin from "../../img/bitcoin-btc-logo.svg";
import BitcoinCash from "../../img/bitcoin-cash-bch-logo.svg";
import BitcoinGold from "../../img/bitcoingold.png";
import BitcoinSv from "../../img/bitcoin-sv-bsv-logo.svg";
import Litecoin from "../../img/litecoin-ltc-logo.png";
import Ethereum from "../../img/ethereum.svg";
import Thorchain from "../../img/thorchain-rune-logo.svg";
import BinanceCoin from "../../img/binance-coin-bnb-logo.svg";
import StatCard from "./StatCard";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCoins } from "../selectors/coins";

export const CoinSwap = ({ coinSwapData }) => {
  const {
    in_address,
    out_address,
    in_currency,
    in_amount,
    out_amount,
    in_token,
    out_currency,
    out_token,
    in_transaction_hash,
    out_transaction_hash,
  } = coinSwapData;

  const coinsEnabled = useSelector(state => getCoins(state));

  //TODO this static list is pretty hacky need to implement a more future proof solution
  const currency = {
    BTC: {
      label: "Bitcoin",
      route: "bitcoin",
      enabled: coinsEnabled.includes("bitcoin"),
      logo: Bitcoin,
    },
    BTH: {
      label: "Bitcoin Cash",
      route: "bitcoin-cash",
      enabled: coinsEnabled.includes("bitcoin-cash"),
      logo: BitcoinCash,
    },
    BTG: {
      label: "Bitcoin Gold",
      route: "bitcoin-gold",
      enabled: coinsEnabled.includes("bitcoin-gold"),
      logo: BitcoinGold,
    },
    BSV: {
      label: "Bitcoin SV",
      route: "bitcoin-sv",
      enabled: coinsEnabled.includes("bitcoin-sv"),
      logo: BitcoinSv,
    },
    LTC: {
      label: "Litecoin",
      route: "litecoin",
      enabled: coinsEnabled.includes("litecoin"),
      logo: Litecoin,
    },
    ETH: {
      label: "Ethereum",
      route: "ethereum",
      enabled: coinsEnabled.includes("ethereum"),
      logo: Ethereum,
    },
    BNB: {
      label: "Binance Coin",
      route: "",
      enabled: coinsEnabled.includes("binance"),
      logo: BinanceCoin,
    },
    THOR: {
      label: "Thorchain",
      route: "",
      enabled: coinsEnabled.includes("thorchain"),
      logo: Thorchain,
    },
  };

  return (
    <Grid>
      <Row>
        <div style={{ display: "inline" }}>
          <p
            style={{
              color: "var(--secondary-color)",
              fontFamily: "Quicksand",
              fontWeight: "700",
              fontSize: "20px",
              display: "inline",
            }}
          >
            {" "}
            Coin Swap{" "}
          </p>
        </div>
        <div style={{ display: "inline" }}>
          <p
            style={{
              color: "#666",
              fontFamily: "Quicksand",
              fontWeight: "700",
            }}
          >
            This transaction was identified as a Coin Swap from{" "}
            <p
              style={{
                fontSize: "16px",
                display: "inline",
                color: "rgb(51, 122, 183)",
              }}
            >
              {currency[in_currency].label}
            </p>{" "}
            to{" "}
            <p
              style={{
                fontSize: "16px",
                display: "inline",
                color: "rgb(51, 122, 183)",
              }}
            >
              {currency[out_currency].label}
            </p>
            .
          </p>
        </div>
        <div>
          <Col lg={2} style={{ paddingLeft: "0" }}>
            <p
              style={{
                fontWeight: "700",
                display: "inline",
                color: "#666",
                marginRight: "20px",
              }}
            >
              Input Transaction
            </p>
          </Col>
          <Col lg={10} style={{ paddingLeft: "0" }}>
            <p style={{ fontWeight: "700", display: "inline" }}>
              {currency[in_currency].enabled && in_transaction_hash !== null ? (
                <Link to={`/${currency[in_currency].route}/transaction/${in_transaction_hash}`}>
                  {in_transaction_hash}
                </Link>
              ) : in_transaction_hash === null ? (
                "Transaction Not Available"
              ) : (
                in_transaction_hash
              )}
            </p>
          </Col>
        </div>
        <div>
          <Col lg={2} style={{ paddingLeft: "0" }}>
            <p
              style={{
                fontWeight: "700",
                display: "inline",
                color: "#666",
                marginRight: "20px",
              }}
            >
              Output Transaction
            </p>
          </Col>
          <Col lg={10} style={{ paddingLeft: "0" }}>
            <p style={{ fontWeight: "700", display: "inline" }}>
              {currency[out_currency].enabled && out_transaction_hash !== null ? (
                <Link to={`/${currency[out_currency].route}/transaction/${out_transaction_hash}`}>
                  {out_transaction_hash}
                </Link>
              ) : out_transaction_hash === null ? (
                "Transaction Not Available"
              ) : (
                out_transaction_hash
              )}
            </p>
          </Col>
        </div>
      </Row>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        {/*<Col lg={1}/>*/}
        <Col style={{ display: "inline", textAlign: "right", padding: "0" }} lg={5}>
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "inline-block",
                border: "2px solid var(--secondary-color)",
                borderRight: "none",
                borderRadius: "5px 0 0 5px",
                paddingTop: "8px",
                paddingBottom: "8px",
                fontSize: "16px",
                fontFamily: "Quicksand",
                height: "45px",
                width: "50%",
                textAlign: "center",
              }}
            >
              {in_amount}
            </div>

            <div
              style={{
                display: "inline-block",
                border: "2px solid var(--secondary-color)",
                borderLeft: "none",
                backgroundColor: "rgba(111,197,238,.5)",
                borderRadius: "0 5px 5px 0",
                paddingTop: "8px",
                paddingBottom: "8px",
                color: "#333",
                fontSize: "16px",
                fontWeight: "500",
                fontFamily: "Quicksand",
                height: "45px",
                width: "50%",
                textAlign: "center",
              }}
            >
              <img
                style={{
                  height: "25px",
                  width: "25px",
                  marginRight: "5px",
                  verticalAlign: "middle",
                }}
                src={currency[in_currency].logo}
                alt=""
              />{" "}
              {currency[in_currency].label} {"  "} ({in_currency})
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "left", marginTop: "5px" }}>
            <StatCard stat={"Token"} value={in_token} />
            <StatCard
              stat={"Address"}
              value={
                currency[in_currency].enabled ? (
                  <Link to={`/${currency[in_currency].route}/address/${in_address}`}>
                    {in_address}
                  </Link>
                ) : (
                  in_address
                )
              }
            />
          </div>
        </Col>
        <Col lg={2} style={{ display: "inline", height: "45px" }}>
          <div
            style={{
              display: "inline-block",
              paddingTop: "8px",
              paddingBottom: "8px",
              fontSize: "20px",
              fontFamily: "Quicksand",
              height: "45px",
            }}
          >
            <FontAwesomeIcon
              style={{ fontSize: "30px", color: "rgb(51, 122, 183)" }}
              icon={faExchangeAlt}
            />
          </div>
        </Col>
        <Col style={{ display: "inline", textAlign: "left", padding: "0" }} lg={5}>
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "inline-block",
                border: "2px solid var(--secondary-color)",
                borderRight: "none",
                borderRadius: "5px 0 0 5px",
                paddingTop: "8px",
                paddingBottom: "8px",
                fontSize: "16px",
                fontFamily: "Quicksand",
                height: "45px",
                width: "50%",
                textAlign: "center",
              }}
            >
              {out_amount}
            </div>

            <div
              style={{
                display: "inline-block",
                border: "2px solid var(--secondary-color)",
                borderLeft: "none",
                backgroundColor: "rgba(111,197,238,.5)",
                borderRadius: "0 5px 5px 0",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "25px",
                paddingRight: "25px",
                color: "#333",
                fontSize: "16px",
                fontWeight: "500",
                fontFamily: "Quicksand",
                height: "45px",
                width: "50%",
                textAlign: "center",
              }}
            >
              <img
                style={{
                  height: "25px",
                  width: "25px",
                  marginRight: "5px",
                  verticalAlign: "middle",
                }}
                src={currency[out_currency].logo}
                alt=""
              />{" "}
              {currency[out_currency].label} {"  "} ({out_currency})
            </div>
            <div style={{ width: "100%", textAlign: "left", marginTop: "5px" }}>
              <StatCard stat={"Token"} value={out_token} />
              <StatCard
                stat={"Address"}
                value={
                  currency[out_currency].enabled ? (
                    <Link to={`/${currency[out_currency].route}/address/${out_address}`}>
                      {out_address}
                    </Link>
                  ) : (
                    out_address
                  )
                }
              />
            </div>
          </div>
        </Col>
        {/*<Col lg={1}/>*/}
      </div>
    </Grid>
  );
};
