import { faArrowsAltH } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";

// FILTERS
export type Filters = "side";

export interface FilterType {
  side: "everything" | "sent" | "received";
}

export const filterStyles = {
  everything: {
    displayName: "Everything",
    icon: faArrowsAltH
  },
  sent: {
    displayName: "Sent",
    icon: faArrowRight
  },
  received: {
    displayName: "Received",
    icon: faArrowLeft
  }
};

// TOKEN
export interface Token {
  name: string;
  token_account_id?: string;
  symbol?: string;
  decimals: number;
  token_balance?: number;
}
