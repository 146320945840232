/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";
import { Button, ListGroupItem } from "react-bootstrap";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { FakeLinkSpan } from "../../styled";
import { cytoscapeShape } from "../../../prop-types";
import {
  addTransactionToGraph,
  addTransactionToGraphInExplorer,
  addUnclusteredTransactionToGraph,
  openTransactionModal
} from "../../../actions/graph";
import { getWalletSetMemo } from "../../../selectors/graph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons/index";
import CopyText from "../../CopyText";

const TransactionSearchRow = ({
  transactionHash,
  className,
  wallets,
  transactionWallets,
  addTransactionToGraphCallback,
  unclustered_inputs = false,
  addUnclusteredTransactionToGraphCallback,
  cy,
  handleOpenTransactionModal,
  disabled = false
}) => {
  const addTransactionToGraph_ = () => {
    // The line below unselects all elements in the graph when a new one is added
    cy.elements().unselect();
    addTransactionToGraphCallback();
  };

  const addUnclusteredTransactionToGraph = () => {
    cy.elements().unselect();
    addUnclusteredTransactionToGraphCallback();
  };

  const transactionWalletList = transactionWallets
    ? transactionWallets.map(wallet => wallet.toString())
    : [];
  let hasAll = !transactionWallets ? true : false;

  transactionWalletList.forEach(wallet => {
    if (!wallets || (!wallets.has(wallet.toString()) && wallet !== transactionHash)) {
      hasAll = false;
    }
  });

  // TODO fix if transactionhash does exist in it.
  if (unclustered_inputs) {
    if (!wallets || !wallets.has(transactionHash)) {
      hasAll = false;
    }
  }

  return (
    <ListGroupItem className={className}>
      <div
        className="row"
        style={{
          margin: "0",
          fontFamily: "Quicksand",
          fontWeight: "700",
          fontSize: "13px",
          paddingBottom: "5px"
        }}
      >
        <span
          style={{
            borderBottom: "1px solid var(--graph-sidebar-base)",
            paddingBottom: "2px"
          }}
        >
          Transaction
        </span>
        {unclustered_inputs && (
          <p style={{ display: "inline-block", color: "red", marginLeft: "15px" }}>
            {" "}
            NOTE: This is an Unclustered Transaction and will appear as its own node on the graph.
          </p>
        )}
      </div>
      <div
        className="row"
        style={{
          display: "flex",
          paddingBottom: "0",
          margin: "0"
        }}
      >
        <div
          className="col-lg-9"
          style={{
            overflow: "auto",
            wordWrap: "break-word",
            paddingLeft: "0",
            paddingRight: "0",
            paddingBottom: "0",
            marginRight: "0",
            marginLeft: "0",
            paddingTop: "5px"
          }}
        >
          <CopyText text={transactionHash} marginLeft="0" />
          <FakeLinkSpan
            onClick={() => handleOpenTransactionModal(transactionHash)}
            className="graphSearchTagNameSmall"
          >
            {transactionHash}
          </FakeLinkSpan>
        </div>
        <div
          className="col-lg-3"
          style={{
            paddingLeft: "0",
            paddingRight: "0",
            paddingBottom: "0",
            marginLeft: "5px",
            marginRight: "0"
          }}
        >
          {!hasAll ? (
            <Button
              className="pull-right allPurpleButton"
              bsSize="xsmall"
              onClick={
                unclustered_inputs === false
                  ? addTransactionToGraph_
                  : addUnclusteredTransactionToGraph
              }
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: "3px" }} />
              Add to Graph
            </Button>
          ) : (
            <Button
              className="pull-right greenButtonGraph"
              bsSize="xsmall"
              onClick={
                unclustered_inputs === false
                  ? addTransactionToGraph_
                  : addUnclusteredTransactionToGraph
              }
            >
              <FontAwesomeIcon icon={faCheck} /> Added
            </Button>
          )}
        </div>
      </div>
    </ListGroupItem>
  );
};

TransactionSearchRow.propTypes = {
  transactionHash: PropTypes.string.isRequired,
  transactionWallets: PropTypes.arrayOf(PropTypes.number).isRequired,
  addTransactionToGraphCallback: PropTypes.func.isRequired,
  addUnclusteredTransactionToGraphCallback: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  cy: cytoscapeShape.isRequired,
  wallets: PropTypes.instanceOf(Set).isRequired,
  handleOpenTransactionModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

const StyledTransactionSearchRow = styled(TransactionSearchRow)`
  font-family: Monospace;
`;

const mapStateToProps = (
  state,
  {
    // The match/params prop comes from react router. Not overly apparent
    match: {
      params: { graphId }
    }
  }
) => {
  return {
    wallets: getWalletSetMemo(state, Number.parseInt(graphId, 10))
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { graphId }
    },
    transactionHash,
    transactionWallets,
    cy
  }
) => ({
  handleOpenTransactionModal: transaction => dispatch(openTransactionModal(transaction)),
  addTransactionToGraphCallback: () =>
    dispatch(addTransactionToGraphInExplorer(transactionHash, cy, graphId)),
  addUnclusteredTransactionToGraphCallback: () =>
    dispatch(addUnclusteredTransactionToGraph(transactionHash, transactionWallets, cy, graphId))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StyledTransactionSearchRow)
);
