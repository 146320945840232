/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Glyphicon } from "react-bootstrap";
import {
  sortByAddressAsc,
  sortByAddressDesc,
  sortBySatoshiAsc,
  sortBySatoshiDesc,
  sortByWalletAsc,
  sortByWalletDesc,
} from "./Sorting";
import {
  transactionAddress,
  transactionAddressCollapsed,
  transactionWallet,
} from "../../prop-types";

// The second argument to withTableSorter defines the default sort order. For
// tables focused on addresses, we sort by address. For tables focused on
// wallets, we sort by wallets.
const withTableSorter = (WrappedComponent, type) =>
  class extends Component {
    static propTypes = {
      outputs: PropTypes.arrayOf(
        PropTypes.oneOfType([transactionAddress, transactionAddressCollapsed, transactionWallet])
      ).isRequired,
    };

    constructor(props) {
      super(props);
      this.handleSortByAddress = this.handleSortByAddress.bind(this);
      this.handleSortByWallet = this.handleSortByWallet.bind(this);
      this.handleSortBySatoshi = this.handleSortBySatoshi.bind(this);
      this.state = {
        sort: type === "address" ? "addressAsc" : "walletAsc",
      };
    }

    handleSortByAddress = () => {
      const { sort } = this.state;
      if (sort === "addressAsc") {
        this.setState({
          sort: "addressDesc",
        });
      } else {
        this.setState({
          sort: "addressAsc",
        });
      }
    };

    sortByAddressAsc = () => sortByAddressAsc(this.props.outputs);

    sortByAddressDesc = () => sortByAddressDesc(this.props.outputs);

    handleSortByWallet = () => {
      const { sort } = this.state;
      if (sort === "walletAsc") {
        this.setState({
          sort: "walletDesc",
        });
      } else {
        this.setState({
          sort: "walletAsc",
        });
      }
    };

    sortByWalletAsc = () => sortByWalletAsc(this.props.outputs);

    sortByWalletDesc = () => sortByWalletDesc(this.props.outputs);

    handleSortBySatoshi = () => {
      const { sort } = this.state;
      if (sort === "satoshiAsc") {
        this.setState({
          sort: "satoshiDesc",
        });
      } else {
        this.setState({
          sort: "satoshiAsc",
        });
      }
    };

    sortBySatoshiAsc = () => sortBySatoshiAsc(this.props.outputs);

    sortBySatoshiDesc = () => sortBySatoshiDesc(this.props.outputs);

    render() {
      const { outputs, ...passThroughProps } = this.props;

      const { sort } = this.state;
      const currentCaret = {
        address: <Glyphicon glyph="minus" />,
        wallet: <Glyphicon glyph="minus" />,
        satoshi: <Glyphicon glyph="minus" />,
      };
      let sortedOutputs;
      if (sort === "addressDesc") {
        sortedOutputs = this.sortByAddressDesc();
        currentCaret.address = <Glyphicon glyph="triangle-top" />;
      } else if (sort === "walletAsc") {
        sortedOutputs = this.sortByWalletAsc();
        currentCaret.wallet = <Glyphicon glyph="triangle-bottom" />;
      } else if (sort === "walletDesc") {
        sortedOutputs = this.sortByWalletDesc();
        currentCaret.wallet = <Glyphicon glyph="triangle-top" />;
      } else if (sort === "satoshiAsc") {
        sortedOutputs = this.sortBySatoshiAsc();
        currentCaret.satoshi = <Glyphicon glyph="triangle-top" />;
      } else if (sort === "satoshiDesc") {
        sortedOutputs = this.sortBySatoshiDesc();
        currentCaret.satoshi = <Glyphicon glyph="triangle-bottom" />;
      } else {
        sortedOutputs = this.sortByAddressAsc();
        currentCaret.address = <Glyphicon glyph="triangle-bottom" />;
      }

      return (
        <WrappedComponent
          outputs={sortedOutputs}
          handleSortByAddress={this.handleSortByAddress}
          handleSortByWallet={this.handleSortByWallet}
          handleSortBySatoshi={this.handleSortBySatoshi}
          currentCaret={currentCaret}
          {...passThroughProps}
        />
      );
    }
  };

export default withTableSorter;
