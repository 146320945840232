/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import { Col, Row } from "react-bootstrap";

const LogoHeader = ({ icon, logo }) => (
  <>
    <Row>
      <Col lg={6} lgOffset={3}>
        <span style={{ maxWidth: "550px" }}>
          {icon && (
            <img
              src={icon}
              style={{ maxWidth: "80px", marginRight: "15px" }}
              alt="Whitesail Logo"
            />
          )}{" "}
          {logo && <img src={logo} style={{ maxWidth: "450px" }} alt="Whitesail Header" />}
        </span>
      </Col>
    </Row>
  </>
);

export default LogoHeader;
