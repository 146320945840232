import axios from "../../api";
import { getName } from "../../selectors/ethereum/address";

/**
 * Gets graph json and builds cytoscape with graph json, and updates graph names and categories
 * @param graph graph json
 * @param colors category colors
 * @param cy cytoscape object
 * @returns {Promise<null|*[]|*>} List of addresses on the graph
 */
export const ethereumSetUpCytoscape = async (graph, colors, cy) => {
  if (!graph || graph.version > 12) {
    return null;
  }

  // Adding this in for the rare weird case where graphJson is null
  if (!graph.graph) {
    return [];
  }

  const { graph: graphJson } = graph;
  // Check if the cytoscape instance has been destroyed before attempting to call a method on it
  if (cy.destroyed()) {
    return [];
  }
  //modifies graph details
  await updateNodeDetails(graph, colors);
  cy.json(graphJson);
  return cy
    .nodes()
    .toArray()
    .map(node => node.id());
};

/**
 * Queries for nodes changing on the graph and updates their attributions and categories if needed.
 * @param graph
 * @param colors
 * @returns {Promise<void>}
 */
const updateNodeDetails = async (graph, colors) => {
  const { graph: graphJson } = graph;

  // If there are no nodes then the nodes array will not exist, so we just return
  if (graphJson.elements.nodes === undefined) {
    return;
  }

  // Get all node ids into an array
  const ids = [];
  graphJson.elements.nodes.forEach(ele => {
    ids.push(ele.data.id);
  });

  // update the tags and colors by calling tags which returns that information
  const { data } = await axios.post("/api/v2/ethereum/graph/tags", {
    accounts: ids
  });
  graphJson.elements.nodes.forEach(ele => {
    // If it has a name set the label as the name
    const label = getName(data[ele.data.id])[0];
    if (label) {
      ele.data.label = label.name;
    }
    // If it has a category set the category color
    if (data[ele.data.id]["generalCategory"]) {
      ele.data.oldColor = colors[data[ele.data.id]["generalCategory"]];
    }
  });
};
