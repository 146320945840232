/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class PasswordRequirements extends Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: "rgba(60, 177, 232, 0.3)",
          borderRadius: "10px",
          paddingLeft: "10px",
          paddingTop: "10px",
          paddingBottom: "5px",
        }}
      >
        <p
          style={{
            fontFamily: "Quicksand",
            fontWeight: "500",
            fontSize: "16px",
            borderBottom: "2px solid var(--base-color)",
            width: "50%",
          }}
        >
          {" "}
          Password Requirements{" "}
        </p>
        {this.props.letter ? (
          <p style={{ color: "red", fontFamily: "Quicksand", fontWeight: "600" }}>
            <FontAwesomeIcon icon={faTimes} /> Contains a Letter
          </p>
        ) : (
          <p style={{ color: "green", fontFamily: "Quicksand", fontWeight: "600" }}>
            <FontAwesomeIcon icon={faCheck} /> Contains a Letter
          </p>
        )}

        {this.props.number ? (
          <p style={{ color: "red", fontFamily: "Quicksand", fontWeight: "600" }}>
            <FontAwesomeIcon icon={faTimes} /> Contains a Number
          </p>
        ) : (
          <p style={{ color: "green", fontFamily: "Quicksand", fontWeight: "600" }}>
            <FontAwesomeIcon icon={faCheck} /> Contains a Number
          </p>
        )}

        {this.props.specialCharacter ? (
          <p style={{ color: "red", fontFamily: "Quicksand", fontWeight: "600" }}>
            <FontAwesomeIcon icon={faTimes} /> Contains a Special Character
          </p>
        ) : (
          <p style={{ color: "green", fontFamily: "Quicksand", fontWeight: "600" }}>
            <FontAwesomeIcon icon={faCheck} /> Contains a Special Character
          </p>
        )}

        {this.props.pwLength ? (
          <p style={{ color: "red", fontFamily: "Quicksand", fontWeight: "600" }}>
            <FontAwesomeIcon icon={faTimes} /> 14 Character Minimum
          </p>
        ) : (
          <p style={{ color: "green", fontFamily: "Quicksand", fontWeight: "600" }}>
            <FontAwesomeIcon icon={faCheck} /> 14 Character Minimum
          </p>
        )}

        {this.props.pwMatch ? (
          <p style={{ color: "red", fontFamily: "Quicksand", fontWeight: "600" }}>
            <FontAwesomeIcon icon={faTimes} /> Passwords Match
          </p>
        ) : (
          <p style={{ color: "green", fontFamily: "Quicksand", fontWeight: "600" }}>
            <FontAwesomeIcon icon={faCheck} /> Passwords Match
          </p>
        )}
      </div>
    );
  }
}

export default PasswordRequirements;
