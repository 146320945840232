/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import PropTypes from "prop-types";

import { reactRouterHistoryShape, reactRouterMatchShape } from "../../prop-types";

const withRouteBasedTabs = Component => {
  const WrappedComponent = props => {
    const {
      match: {
        params: { transaction },
      },
      history,
      ...passThroughProps
    } = props;

    return <Component transaction={transaction} enableAddToGraph {...passThroughProps} />;
  };

  WrappedComponent.propTypes = {
    match: reactRouterMatchShape({
      transaction: PropTypes.string.isRequired,
    }).isRequired,
    history: reactRouterHistoryShape.isRequired,
  };

  WrappedComponent.displayName = `withRouteBasedTabs(${Component.displayName || Component.name})`;

  return WrappedComponent;
};

export default withRouteBasedTabs;
