/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Row
} from "react-bootstrap";
import Terms from "../../other/2022_DQIO_SaaS_Use_Agreement_2Sep2022.pdf";
import { Helmet } from "react-helmet";

import LogoHeader from "./LogoHeader";
import { dismissFailureNotification, login } from "../actions/authentication";
import { getError } from "../selectors/authentication";
import { onInputChange } from "../helpers";
import axios, { CSRF_ERROR_MESSAGE } from "../api";
import { getClientMode } from "../selectors/applicationConfig";
import ClientModeConfig from "./ClientModeConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import UserGuide from "../../other/TheWhitesailGuide.pdf";
import CopyText from "./CopyText";

export class Login extends Component {
  state = {
    fields: {
      email: "",
      password: ""
    },
    agreementAccepted: false,
    errorMessages: []
  };

  componentDidMount = async () => {
    const { data } = await axios.get("/api/v2/login-messages");
    this.setState({ errorMessages: data });
  };

  onInputChange = onInputChange.bind(this);

  login = async e => {
    e.preventDefault();
    const { email, password } = this.state.fields;

    await this.props.onSubmit(email, password);
  };

  handleDismiss = () => this.props.dismissNotification();

  renderNotification = () => {
    const { error } = this.props;
    let message = null;
    if (error === "loginFailure") {
      message = "Incorrect email or password.";
    } else if (error === "autoLogout") {
      message = "Automatically logged out.";
    } else if (error === "trialEnded") {
      message = "Your 90 day trial has ended.";
    } else if (error === "loginDisabled") {
      message = "This account has been disabled.";
    } else if (error === "loginOverload") {
      message = "Too many accounts logged in";
    } else if (error === "csrf") {
      message = CSRF_ERROR_MESSAGE;
    } else if (error === "loginDeleted") {
      message = "This account has been deleted.";
    }

    if (message) {
      return (
        <Row>
          <Col lg={6} lgOffset={3}>
            <Alert bsStyle="danger" onDismiss={this.handleDismiss} id="login-failure-notification">
              <strong>{message}</strong>
            </Alert>
          </Col>
        </Row>
      );
    }
    return null;
  };

  render() {
    const { email, password } = this.state.fields;

    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    return (
      <div>
        <Helmet>
          <title>Login</title>
        </Helmet>
        <Grid>
          {this.renderNotification()}
          <LogoHeader
            logo={clientModeConfigObject.login_top_logo}
            icon={clientModeConfigObject.login_top_icon}
            test={"hey"}
          />

          <br />
          <Row style={{ marginTop: "20px" }}>
            <Col
              lg={8}
              lgOffset={2}
              style={{
                border: "3px solid var(--base-color)",
                backgroundColor: "white",
                borderRadius: "10px 10px 0 0",
                padding: "0"
              }}
            >
              <h1
                style={{
                  fontFamily: "Quicksand",
                  display: "inline-block",
                  paddingRight: "10px",
                  color: "var(--base-color)",
                  margin: "0",
                  fontSize: "50px",
                  marginLeft: "10px",
                  marginBottom: "20px",
                  fontWeight: "500"
                }}
              >
                Login
              </h1>
            </Col>
          </Row>
          <Row style={{ marginTop: "0px" }}>
            <Col
              lg={8}
              lgOffset={2}
              style={{
                border: "3px solid var(--base-color)",
                backgroundColor: "var(--base-color)",
                borderRadius: "0 0 10px 10px",
                padding: "0"
              }}
            >
              <Form
                onSubmit={this.login}
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  fontFamily: "Quicksand"
                }}
              >
                <FormGroup controlId="email">
                  <ControlLabel style={{ color: "white" }}>Email</ControlLabel>
                  <FormControl
                    type="email"
                    value={email}
                    placeholder=""
                    onChange={this.onInputChange}
                    required
                    style={{ width: "75%" }}
                  />
                </FormGroup>
                <FormGroup controlId="password">
                  <ControlLabel style={{ color: "white" }}>Password</ControlLabel>
                  <FormControl
                    type="password"
                    value={password}
                    placeholder=""
                    onChange={this.onInputChange}
                    required
                    style={{ width: "75%" }}
                  />
                </FormGroup>
                <div>
                  <Row style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                    <Col
                      lg={12}
                      style={{
                        backgroundColor: "white",
                        padding: "0",
                        borderRadius: "5px",
                        paddingBottom: "10px"
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Quicksand",
                          fontStyle: "italic",
                          fontWeight: "500",
                          fontSize: "12px",
                          marginTop: "10px",
                          marginBottom: "10px",
                          paddingLeft: "10px",
                          paddingRight: "10px"
                        }}
                      >
                        The data and documents on this system include Federal records that contain
                        sensitive information protected by various Federal statutes, including the
                        Privacy Act, 5 U.S.C. § 552a.
                        <br />
                        <br />
                        All access or use of this system constitutes user understanding and
                        acceptance of these terms and constitutes unconditional consent to review
                        and action by all authorized Government and law enforcement personnel.
                        <br />
                        <br />
                        Unauthorized user attempts or acts to (1) access, upload, change, or delete
                        information on this system, (2) modify this system, (3) deny access to this
                        system, (4) accrue resources for unauthorized use or (5) otherwise misuse
                        this system are strictly prohibited. Such attempts or acts are subject to
                        action that may result in criminal, civil, or administrative penalties.
                      </p>
                      <a
                        onClick={() => window.open(Terms)}
                        style={{
                          fontFamily: "Quicksand",
                          fontStyle: "italic",
                          fontWeight: "500",
                          fontSize: "12px",
                          marginTop: "10px",
                          marginBottom: "10px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          display: "block"
                        }}
                      >
                        Additional terms and conditions may be viewed here.
                      </a>
                      <div
                        onClick={() =>
                          this.setState({
                            agreementAccepted: !this.state.agreementAccepted
                          })
                        }
                        style={{
                          marginRight: "5px",
                          marginLeft: "10px",
                          display: "inline-block",
                          height: "20px",
                          width: "20px",
                          border: "2px solid var(--base-color)",
                          backgroundColor: "white",
                          verticalAlign: "middle",
                          textAlign: "center",
                          fontFamily: "Quicksand",
                          cursor: "pointer"
                        }}
                      >
                        {this.state.agreementAccepted ? (
                          <FontAwesomeIcon
                            style={{
                              color: "var(--base-color)",
                              paddingBottom: "2px"
                            }}
                            icon={faCheck}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <span
                        style={{
                          fontFamily: "Quicksand",
                          fontWeight: "700",
                          color: "#444",
                          marginRight: "20px"
                        }}
                      >
                        {" "}
                        I agree to the above terms and conditions{" "}
                      </span>
                    </Col>
                  </Row>
                </div>

                <Button
                  className="loginButton"
                  type="submit"
                  disabled={!this.state.agreementAccepted}
                  style={{ marginTop: "15px" }}
                >
                  Login
                </Button>
              </Form>
              <p
                style={{
                  fontFamily: "Quicksand",
                  marginTop: "10px",
                  paddingLeft: "10px",
                  color: "white"
                }}
              >
                <Link
                  to={{ pathname: "/reset-request" }}
                  style={{
                    fontFamily: "Quicksand",
                    fontWeight: "500",
                    color: "white"
                  }}
                >
                  Forgot your password?
                </Link>
              </p>

              <p
                style={{
                  fontFamily: "Quicksand",
                  marginTop: "10px",
                  paddingLeft: "10px",
                  color: "white"
                }}
              >
                Help at
                <a href={"mailto:" + "support@sievakocorp.com"}> support@sievakocorp.com</a>
                <CopyText marginLeft={"5px"} text={"support@sievakocorp.com"} color={"white"} />
              </p>
            </Col>
          </Row>

          <br />
          {this.state.errorMessages.length !== 0 && (
            <Row>
              <Col lg={8} lgOffset={2}>
                <h3 style={{ color: "red" }}>Maintenance Notes</h3>
                <ul>
                  {this.state.errorMessages.map(x => (
                    <li>
                      <p style={{ color: "red", fontWeight: "700" }}> {x} </p>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          )}

          <Row>
            <Col lg={4} lgOffset={4}>
              <br />
              <img alt="" src={clientModeConfigObject.login_logo} style={{ width: "100%" }} />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

Login.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  dismissNotification: PropTypes.func.isRequired
};

Login.defaultProps = {
  error: null
};

const mapStateToProps = state => ({
  error: getError(state),
  clientMode: getClientMode(state)
});

const mapDispatchToProps = dispatch => ({
  onSubmit: (email, password) => dispatch(login(email, password)),
  dismissNotification: () => dispatch(dismissFailureNotification())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
