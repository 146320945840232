// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import axios from "axios";
import moment from "moment";
import {LOGGING_OUT_AUTOMATICALLY, LOGIN_OVERLOAD} from "./actions/actionNames";

// Grab a map containing all the cookies
//
// Why is this api so stupid? You can set individual cookies but not get them? Wtf???
const getCookies = () => {
  const cookies = document.cookie.split(";");
  const map = new Map();

  cookies.forEach(cookie => {
    const values = cookie.split("=");
    if (values.length >= 2) {
      map.set(values[0], values[1]);
    }
  });

  return map;
};

// Creates axios instance that is used throughout the app
const instance = axios.create({
  headers: {
    "X-CSRF-Token": getCookies().get("X-CSRF")
  }
});

// Add a response interceptor


// Failsafe used if server does not respond to logouts for whatever reason.
export const deleteSessionCookie = () => {
  document.cookie = "session= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
};

// Effectively reset the automatic logout timer.
export const setLastAction = () => {
  const timestamp = moment().unix();
  localStorage.setItem("lastAction", timestamp);
};

// This is our way of adding a logout timer that forces a logout if the user does
// not click. This is set up in the initial IIFE that sets up the website in index.jsx.
// We need to use localStorage to make this work across different tabs, since we don't
// want the function here to make a logout request if the user has two tabs open and
// isn't using one of them. In order to handle this, we store the unix timestamp of
// the most recent action in localStorage and check if that timestamp exceeds the
// timeout. The current timeout is 8 hours.
export const logoutIfInactive = async dispatch => {
  try {
    const now = moment().unix();
    const loggedIn = localStorage.getItem("loggedIn");
    // We don't want this to spam the server if we aren't logged in.
    if (loggedIn === "false") {
      return;
    }
    const lastAction = localStorage.getItem("lastAction");
    if (now - lastAction < 60 * 60 * 8) {
      return;
    }
    await instance.post("/api/v1/logout", {
      reason: "session expired"
    });
  } catch (err) {
    deleteSessionCookie();
  }

  dispatch({ type: LOGGING_OUT_AUTOMATICALLY });
};


export const autoLoggedOut = async dispatch => {
  try {
    const loggedIn = localStorage.getItem("loggedIn");
    // We don't want this to spam the server if we aren't logged in.
    if (loggedIn === "false") {
      return;
    }
    await instance.post("/api/v1/logout", {
      reason: "session expired"
    });
  } catch (err) {
    deleteSessionCookie();
  }

  await dispatch({ type: LOGIN_OVERLOAD });
  await dispatch({ type: LOGGING_OUT_AUTOMATICALLY });
};

export const CSRF_ERROR_MESSAGE =
  "Unknown error. Please ensure your browser is up to date, refresh the page, and try again.";

export default instance;

// v2 endpoints
export const ADDRESS_API = currency => `/api/v2/${currency}/address`;
export const CURRENCY_API = () => `/api/v2/usdValues`;
export const WALLET_API = currency => `/api/v2/${currency}/wallet`;
export const UTXO_API = currency => `/api/v2/${currency}/utxo`;
export const TAG_API = currency => `/api/v2/${currency}/tag`;
export const GRAPH_API = currency => `/api/v2/${currency}/graph`;
export const CUSTOM_WALLETS_API = currency =>
  `/api/v2/${currency}/custom-wallet`;
export const CUSTOM_TAGS_API = currency => `/api/v2/${currency}/custom-tags`;
export const NOTES_API = currency => `/api/v2/${currency}/notes`;
export const TRANSACTION_API = currency => `/api/v2/${currency}/transaction`;
export const SEARCH_API = currency => `/api/v2/${currency}/search`;
export const FUZZY_SEARCH_API = currency => `/api/v2/${currency}/fuzzy-search`;
export const ISSUES_API = (currency, user_type) =>
  `/api/v2/${currency}/${user_type}`;
export const WATCHLIST_API = currency => `/api/v2/${currency}/watchlist`;
export const DOWNLOAD_API = currency => `/api/v2/${currency}/data-download`;
export const RISK_SCORING_API = currency => `/api/v2/${currency}/risk-scoring`;
export const ETHEREUM_API = `/api/v2/ethereum`;
export const ETHEREUM_NOTES_API = `/api/v2/ethereum/notes`;
export const SHARE_API = currency => `/api/v2/${currency}/share`;
export const MESSAGE_API = `/api/v2/message`;

// v1 endpoints
export const CHANGE_EMAIL_API = "/api/v1/change-email";
export const CHANGE_PASSWORD_API = "/api/v1/change-password";
export const INVITE_API = "/api/v1/invite";
export const USER_API = "/api/v1/user";
export const LOGIN_API = "/api/v1/login";
export const LOGOUT_API = "/api/v1/logout";
export const REGISTER_API = "/api/v1/register";
export const PASSWORD_RESET_REQUEST_API = "/api/v1/password-reset-request";
export const PASSWORD_RESET_API = "/api/v1/password-reset";
