import React, { useEffect, useReducer, useRef, useState } from "react";
import { connect } from "react-redux";
import CytoscapeComponent from "react-cytoscapejs/src/component";
import { getCurrency } from "../../../selectors/currency";
import { getLastSavedTimestamp, getUndoStatus } from "../../../selectors/graph";
import { getFeature } from "../../../selectors/features";
import { getClientMode } from "../../../selectors/applicationConfig";
import { style } from "./EthGraphStyle";
import {
  addNodeFromSearchResult,
  addNodeToGraph,
  fetchGraph,
  search,
  redoGraphChange,
  undoGraphChange
} from "../../../actions/ethereum/graph";
import { useForceUpdate } from "../../Hooks";
import cytoscape from "cytoscape";
import dagre from "cytoscape-dagre";
import { Button, Col, DropdownButton } from "react-bootstrap";
import {
  faCaretDown,
  faCaretUp,
  faRedo,
  faSave,
  faTrash,
  faUndo
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getAddressSet,
  getCategoryColors,
  getGraphSearchCurrentQuery,
  getGraphSearchLoading,
  getGraphSearchResults,
  getMainTabsInfo
} from "../../../selectors/ethereum/graph";
import popper from "cytoscape-popper";
import tippy, { followCursor, roundArrow } from "tippy.js";
import "tippy.js/themes/light.css";
import "tippy.js/dist/svg-arrow.css";
import EthereumSidebar from "./EthereumSidebar";
import MakeCxtMenuSettings from "./MakeCxtMenuSettings";
import { deleteAddressesFromGraph } from "../../../actions/ethereum/graph";
import { GraphSearch } from "./GraphSearch";
import { addNodeMovementToUndoStack, saveGraph } from "../../../actions/graph";
import CategoryColorsConfig from "../../Graph/CategoryColorsConfig";

//cytoscape.use(dagre);
cytoscape.use(popper);
const EthereumGraph = props => {
  // Set the initial state of the component
  const initialState = {
    showGraphData: true,
    graphDataFetched: false,
    search: null,
    showAddFromGraph: false,
    currentNode: null,
    activeMenuOption: null,
    addressKey: null
  };

  // The reducer used to access and manipulate local state
  function reducer(state, action) {
    switch (action.type) {
      case "toggleShowGraphData":
        return {
          elements: state.elements,
          showGraphData: !state.showGraphData,
          graphDataFetched: state.graphDataFetched,
          search: state.search,
          showAddFromGraph: state.showAddFromGraph,
          currentNode: state.currentNode,
          activeMenuOption: state.activeMenuOption,
          addressKey: state.addressKey
        };
      case "updateFetchedStatus":
        return {
          elements: state.elements,
          showGraphData: state.showGraphData,
          graphDataFetched: true,
          search: state.search,
          showAddFromGraph: state.showAddFromGraph,
          currentNode: state.currentNode,
          activeMenuOption: state.activeMenuOption,
          addressKey: state.addressKey
        };
      case "setSearchTerm":
        return {
          elements: state.elements,
          showGraphData: state.showGraphData,
          graphDataFetched: true,
          search: action.payload,
          showAddFromGraph: state.showAddFromGraph,
          currentNode: state.currentNode,
          activeMenuOption: state.activeMenuOption,
          addressKey: state.addressKey
        };
      case "showAddFromGraph":
        return {
          elements: state.elements,
          showGraphData: state.showGraphData,
          graphDataFetched: true,
          search: state.search,
          showAddFromGraph: !state.showAddFromGraph,
          currentNode: state.currentNode,
          activeMenuOption: state.activeMenuOption,
          addressKey: state.addressKey
        };
      case "setAddFromGraph":
        return {
          ...state,
          showAddFromGraph: action.payload
        };
      case "switchCurrentSelection":
        return {
          ...state,
          graphDataFetched: true,
          currentNode: action.payload.data,
          activeMenuOption: "data",
          addressKey: action.payload.view,
          showAddFromGraph: false
        };
      case "switchActiveMenuOption":
        return {
          elements: state.elements,
          showGraphData: state.showGraphData,
          graphDataFetched: true,
          search: state.search,
          showAddFromGraph: false,
          currentNode: state.currentNode,
          activeMenuOption: action.payload,
          addressKey: action.payload ? state.addressKey : null
        };
      case "unselectAll":
        return {
          ...state,
          activeMenuOption: null,
          currentNode: null,
          addressKey: null,
          showAddFromGraph: false
        };
      case "WalletColorPicker":
        return {
          ...state,
          currentNode: action.payload,
          activeMenuOption: "data",
          addressKey: action.type,
          showAddFromGraph: false
        };
      case "WalletTagSetter":
        return {
          ...state,
          currentNode: action.payload,
          activeMenuOption: "data",
          addressKey: action.type,
          showAddFromGraph: false
        };
      case "WalletNoteSetter":
        return {
          ...state,
          currentNode: action.payload,
          activeMenuOption: "data",
          addressKey: action.type,
          showAddFromGraph: false
        };
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const walletIdToPosition = useRef(new Map());
  // Establish the ref to the cytoscape object
  const _cy = useRef(null);

  // This is a custom hook to force rerender
  const forceUpdate = useForceUpdate();

  const toggleGraphData = () => dispatch({ type: "toggleShowGraphData" });
  const hideTippys = () => {
    // Hide all non-note tippys
    _cy.current.elements().forEach(node => {
      if (node.tippy) {
        node.tippy.hide();
      }
    });
  };

  const toggleMenuOption = menuItem => {
    dispatch({ type: "switchActiveMenuOption", payload: menuItem });
  };

  const removeElements = () => {
    const wallets = _cy.current.elements(":selected");
    props.removeNodes(_cy.current, wallets);
  };

  const redo = async () => {
    props.redoGraphChange(_cy.current);
    dispatch({ type: "unselectAll" });
  };

  const undo = async () => {
    props.undoGraphChange(_cy.current);
    dispatch({ type: "unselectAll" });
  };

  // This is called when the component mounts to fetch the graph data
  useEffect(() => {
    // Assign the reference to cy
    const opts = {
      boxSelectionEnabled: true,
      autounselectify: true,
      style: style(),
      elements: null,
      layout: { name: "dagre" },
      cytoscapeOptions: { wheelSensitivity: 0.1, hideEdgesOnViewport: true }
    };

    const fetchGraph = async () => {
      await props.fetch(props.match.params.graphId, _cy.current);
      // menu for the nodes
      _cy.current.cxtmenu(
        MakeCxtMenuSettings({
          handleSelectColorPicker: wallet =>
            dispatch({ type: "WalletColorPicker", payload: wallet }),
          handleSelectNoteSetter: wallet => dispatch({ type: "WalletNoteSetter", payload: wallet }),
          handleSelectTagSetter: wallet => dispatch({ type: "WalletTagSetter", payload: wallet })
        })
      );
      // set cytoscape onclick rules
      _cy.current.on("tap", "node", async evt => {
        dispatch({
          type: "switchCurrentSelection",
          payload: { view: "AddressView", data: evt.target }
        });
      });
      _cy.current.on("tap", async evt => {
        if (evt.target === _cy.current) {
          dispatch({ type: "unselectAll" });
        }
      });

      let nodeHoverTimeout = null;
      _cy.current.on("mouseover", "node", async evt => {
        nodeHoverTimeout = await setTimeout(async () => {
          hideTippys();

          // If the tippy hasn't been created yet, do so with makeNodePopper()
          if (!evt.target.tippy) {
            //await this.props.fetchWalletSummary(evt.target.data("id"));
            await makeNodePopper(evt.target);
          }
          // Show the tippy
          if (evt.target.tippy) {
            evt.target.tippy.show();
          }
        }, 250);
      });
      _cy.current.on("mouseout", "node", evt => {
        clearTimeout(nodeHoverTimeout);
        if (evt.target.tippy) {
          evt.target.tippy.hide();
        } else {
          // If the tippy doesn't exist yet, wait 800ms and then you can hide it.
          setTimeout(() => {
            if (evt.target.tippy) {
              evt.target.tippy.hide();
              hideTippys();
            }
          }, 800);
        }
      });

      _cy.current.on("mouseover", "edge", async evt => {
        nodeHoverTimeout = await setTimeout(async () => {
          hideTippys();

          // If the tippy hasn't been created yet, do so with makeNodePopper()
          if (!evt.target.tippy) {
            //await this.props.fetchWalletSummary(evt.target.data("id"));
            await makeTransactionPopper(evt.target);
          }
          // Show the tippy
          if (evt.target.tippy) {
            evt.target.tippy.show();
          }
        }, 250);
      });
      _cy.current.on("mouseout", "edge", evt => {
        clearTimeout(nodeHoverTimeout);
        if (evt.target.tippy) {
          evt.target.tippy.hide();
        } else {
          // If the tippy doesn't exist yet, wait 800ms and then you can hide it.
          setTimeout(() => {
            if (evt.target.tippy) {
              evt.target.tippy.hide();
              hideTippys();
            }
          }, 800);
        }
      });

      _cy.current.on("tap", "edge", evt => {
        dispatch({
          type: "switchCurrentSelection",
          payload: { view: "EdgeView", data: evt.target }
        });
      });
      _cy.current.on("grab", "node", evt => {
        // This is used to get previous positions for dragged nodes.
        let newWalletIdToPosition = new Map();

        evt.target
          .cy()
          .nodes(":selected")
          .union(evt.target)
          .forEach(wallet => {
            // Need to make copy or else cytoscape will update the reference!
            const position = {
              x: wallet.position("x"),
              y: wallet.position("y")
            };
            newWalletIdToPosition = newWalletIdToPosition.set(wallet.id(), position);
          });
        walletIdToPosition.current = newWalletIdToPosition;
      });
      _cy.current.on("dragfreeon", "node", evt => {
        // This only has the node directly clicked on for the evt. The user can select
        // multiple wallets and move them at once, so all selected nodes are grabbed
        // here.
        // dragfree doesn't provide what we want either.

        const walletIds = evt.target
          .cy()
          .nodes(":selected")
          .union(evt.target)
          .map(wallet => wallet.id());
        props.addNodeMovementToUndoStack(_cy.current, walletIds, walletIdToPosition.current);
      });
      dispatch({ type: "updateFetchedStatus" });
    };

    fetchGraph();
  }, []);

  // TODO TAKEN out of Node popper to remove first transaction put back in once first transaction is populated in db
  // <tr>
  //   <td className="text-left header">First Trx</td>
  //   <td className="text-left">${
  //     data["first_transaction_id"] === null
  //       ? "N/A"
  //       : data["first_transaction_id"].substring(0, 9) + "..."
  //   }</td>
  const makeNodePopper = async ele => {
    const data = ele.data();
    ele.tippy = new tippy(document.createElement("div"), {
      getReferenceClientRect: ele.popperRef().getBoundingClientRect,
      content: () => {
        return `
                  <Table style="width: 280px">
                    <tr>
                       <td class="text-left header">Input Count</td>
                       <td class="text-left">${data["input_count"]}</td>
                    </tr>
                    <tr>
                       <td class="text-left header">Output Count</td>
                       <td class="text-left">${data["output_count"]}</td>
                    </tr>
                    <tr>
                       <td class="text-left header">Internal Input Count</td>
                       <td class="text-left">${data["internal_input_count"]}</td>
                    </tr>
                    <tr>
                       <td class="text-left header">External Output Count</td>
                       <td class="text-left">${data["internal_output_count"]}</td>
                  </tr>

                   </tr>
                  </Table>`;
      },
      theme: "light",
      trigger: "manual",
      //interactive: true,
      arrow: roundArrow,
      allowHTML: "true"
    });
  };

  /**
   * Makes color category key list from colors stored in redux store
   * @returns List of components for the key of colors
   */
  const colors = () => {
    return Object.keys(props.colors).map(generalCategory => {
      return (
        <li className="dropdownKeyItem" style={{ textAlign: "center" }}>
          <p>{generalCategory}</p>
          <span
            className="dot-dropdown"
            style={{
              backgroundColor: props.colors[generalCategory]
            }}
          />
        </li>
      );
    });
  };

  const makeTransactionPopper = async ele => {
    const data = ele.data();
    ele.tippy = new tippy(document.createElement("div"), {
      getReferenceClientRect: ele.popperRef().getBoundingClientRect,
      content: () => {
        return `
          <Table style="width: 280px">
            <tr>
               <td class="text-left header">Ethereum Transactions: </td>
               <td class="text-left">${data["transactionCountDict"]["ether"]}</td>
            </tr>
            <tr>
               <td class="text-left header">ERC20 Transactions</td>
               <td class="text-left">${data["transactionCountDict"]["erc20"]}</td>
            </tr>
            <tr>
               <td class="text-left header">ERC721 Transactions</td>
               <td class="text-left">${data["transactionCountDict"]["erc721"]}</td>
            </tr>
          </Table>`;
      },
      theme: "light",
      trigger: "manual",
      //interactive: true,
      arrow: roundArrow,
      allowHTML: "true",
      followCursor: true,
      plugins: [followCursor]
    });
  };

  return (
    <div
      style={{
        marginTop: "-10px",
        height: "calc(100vh - 65px)",
        paddingTop: "0"
      }}
    >
      <div className="container-fluid" style={{ height: "100%", overflowY: "hidden" }}>
        <div className="row" style={{ display: "flex", height: "100%" }}>
          <EthereumSidebar
            cy={_cy.current}
            currentNode={state.currentNode}
            toggleGraphData={toggleGraphData}
            showGraphData={state.showGraphData}
            graphId={props.match.params.graphId}
            toggleMenuOption={toggleMenuOption}
            activeMenuOption={state.activeMenuOption}
            addressKey={state.addressKey}
            caseNumber={props.graphInfo.caseNumber}
            description={props.graphInfo.description}
          />
          <Col
            className="sidebar"
            lg={state.showGraphData ? 7 : 11}
            style={{ backgroundColor: "white", padding: "0" }}
          >
            <div style={{ height: "100%" }}>
              <div
                style={{
                  backgroundColor: "var(--graph-background-color)",
                  borderColor: "transparent",
                  height: "100%"
                }}
              >
                <div
                  className="graphButtons"
                  style={{
                    position: "absolute",
                    zIndex: "999",
                    backgroundColor: "var(--graph-button-bar-color)",
                    padding: "10px"
                  }}
                >
                  <Button
                    className="graphToggle"
                    style={{ display: "inline-block", position: "relative" }}
                    onClick={() => dispatch({ type: "showAddFromGraph" })}
                  >
                    Add New Entity{" "}
                    {state.showAddFromGraph ? (
                      <FontAwesomeIcon icon={faCaretUp} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}
                  </Button>
                  <DropdownButton
                    className="graphToggle negativeLeft"
                    title="Key"
                    onClick={() => {
                      dispatch({ type: "setAddFromGraph", payload: false });
                    }}
                  >
                    {colors()}
                  </DropdownButton>
                  <Button className="graphToggle" disabled={!props.undoCanGoBack} onClick={undo}>
                    <FontAwesomeIcon icon={faUndo} />
                  </Button>
                  <Button className="graphToggle" disabled={!props.undoCanGoForward} onClick={redo}>
                    <FontAwesomeIcon icon={faRedo} />
                  </Button>
                  <Button className="graphToggleRed" onClick={removeElements}>
                    <FontAwesomeIcon icon={faTrash} style={{ marginRight: "7px" }} /> Remove
                    Selected
                  </Button>
                  <Button
                    className="greenButtonGraphToolbar"
                    onClick={() =>
                      props.saveGraph(parseInt(props.match.params.graphId), _cy.current)
                    }
                  >
                    <FontAwesomeIcon icon={faSave} style={{ marginRight: "7px" }} /> Save
                  </Button>
                  {state.showAddFromGraph && (
                    <GraphSearch
                      addToGraph={props.addToGraph}
                      search={props.search}
                      graphId={props.graphId}
                      cy={_cy.current}
                      dispatch={dispatch}
                    />
                  )}
                </div>
                <CytoscapeComponent
                  elements={[]}
                  cy={cy => (_cy.current = cy)}
                  style={{ width: "100%", height: "100%" }}
                  stylesheet={style()}
                />
              </div>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { graphId }
    }
  }
) => {
  return {
    currency: getCurrency(state),
    lastSaved: getLastSavedTimestamp(state),
    issuesEnabled: getFeature(state, "issues"),
    clientMode: getClientMode(state),
    searchResult: getGraphSearchResults(state, graphId),
    searchLoading: getGraphSearchLoading(state, graphId),
    currentQuery: getGraphSearchCurrentQuery(state, graphId),
    graphInfo: getMainTabsInfo(state, graphId),
    addresses: getAddressSet(state, graphId),
    graphId: graphId,
    colors: getCategoryColors(state),
    ...getUndoStatus(state)
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { graphId }
    }
  }
) => {
  return {
    fetch: (graphId, cy) => dispatch(fetchGraph(graphId, cy)),
    search: query => dispatch(search(query, graphId)),
    addToGraph: (addressId, graphId, cy) =>
      dispatch(addNodeFromSearchResult(addressId, graphId, cy)),
    saveGraph: (graphId, cy) => dispatch(saveGraph(graphId, cy)),
    removeNodes: (cy, addressIds) => dispatch(deleteAddressesFromGraph(graphId, cy, addressIds)),
    undoGraphChange: cy => dispatch(undoGraphChange(graphId, cy)),
    redoGraphChange: cy => dispatch(redoGraphChange(graphId, cy)),
    addNodeMovementToUndoStack: (cy, walletIds, walletIdToPosition) => {
      dispatch(addNodeMovementToUndoStack(cy, walletIds, walletIdToPosition));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EthereumGraph);
