// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { ADD_NOTIFICATION, DISMISS_NOTIFICATION } from "./actionNames";
import { getCurrency } from "../selectors/currency";

/**
 *
 * @param text: string
 * @param {string} style one of [success, warning, danger, info]
 * @returns {Function}
 */
export const addNotification = (text, style) => (dispatch, getState) => {
  const name = getCurrency(getState());
  dispatch({
    type: ADD_NOTIFICATION,
    text,
    style,
    name,
  });
};

export const dismissNotification = () => (dispatch, getState) => {
  const name = getCurrency(getState());

  dispatch({
    type: DISMISS_NOTIFICATION,
    name,
  });
};
