// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { List, Map } from "immutable";
import { CompoundKey, Transaction, WalletSummary } from "./sharedRecords";

export const addWalletTransactionsFactory = WalletRecord => (state, action) => {
  const { item: walletId, order, data } = action;
  let walletRecord = state.get(walletId) || new WalletRecord();
  let transactions = walletRecord.get("transactions");
  let transactionMap = transactions.get("transactionMap");
  const key = order > 0 ? "newestFirst" : "oldestFirst";
  let transactionList = transactions.get(order > 0 ? "newestFirst" : "oldestFirst");

  data.transactions.forEach(transaction => {
    const { transactionId } = transaction;
    const transactionRecord = new Transaction(transaction);
    transactionList = transactionList.push(transaction.transactionId);
    transactionMap = transactionMap.set(transactionId, transactionRecord);
  });

  transactions = transactions
    .set("transactionMap", transactionMap)
    .set(key, transactionList)
    .set(`${key}NextKey`, data.key);

  walletRecord = walletRecord.set("transactions", transactions);

  return state.set(walletId, walletRecord);
};

export const addWalletTransactionsBySatoshiFactory = WalletRecord => (state, action) => {
  const { item: walletId, data } = action;
  const key = "largestFirst";
  let walletRecord = state.get(walletId) || new WalletRecord();
  let transactions = walletRecord.get("transactions");
  let transactionMap = transactions.get("transactionMap");
  let transactionList = transactions.get("largestFirst");

  data.transactions.forEach(transaction => {
    const { transactionId } = transaction;
    const transactionRecord = new Transaction(transaction);
    // transactionIds are pushed into corresponding transaction list
    transactionList = transactionList.push(transactionId);
    // transactionId is mapped to corresponding transactionRecord
    transactionMap = transactionMap.set(transactionId, transactionRecord);
  });

  const compoundKey = new CompoundKey({
    primary: data.primaryKey,
    secondary: data.secondaryKey
  });

  transactions = transactions
    .set("transactionMap", transactionMap)
    .set(key, transactionList)
    .set(`${key}NextKey`, compoundKey);

  walletRecord = walletRecord.set("transactions", transactions);
  return state.set(walletId, walletRecord);
};

/**
 * Sets the date range of transactions to search and clears old transactions
 */
export const setDateRangeFactory = WalletRecord => (state, action) => {
  const { walletId, startDate, endDate } = action;

  let walletRecord = state.get(walletId) || new WalletRecord();
  const transactions = walletRecord
    .get("transactions")
    .set("transactionMap", Map())
    .set("newestFirst", List())
    .set("newestFirstNextKey", 0)
    .set("oldestFirst", List())
    .set("oldestFirstNextKey", 0)
    .set("largestFirst", List())
    .set("largestFirstNextKey", new CompoundKey())
    .set("startDate", startDate)
    .set("endDate", endDate);
  walletRecord = walletRecord.set("transactions", transactions);
  return state.set(walletId, walletRecord);
};

export const addWalletMentionsFactory = (WalletRecord, Mention) => (state, action) => {
  const { item: walletId, data } = action;

  let walletRecord = state.get(walletId) || new WalletRecord();
  let mentions = walletRecord.getIn(["mentions", "mentions"]);
  data.mentions.forEach(mention => {
    const mentionRecord = new Mention(mention);
    mentions = mentions.push(mentionRecord);
  });

  walletRecord = walletRecord
    .setIn(["mentions", "mentions"], mentions)
    .setIn(["mentions", "nextKey"], data.key);
  return state.set(walletId, walletRecord);
};

export const addRiskReducer = (recordSelector, entityType) => (state, action) => {
  const { item: item, data, entityType: _entityType } = action;
  console.log("here", action);
  if (entityType !== _entityType) {
    return state;
  }

  let record = recordSelector(state, item);
  record = record.set("risk", data);
  return state.set(item, record);
};
