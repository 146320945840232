// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import axios, { ADDRESS_API, CUSTOM_WALLETS_API, RISK_SCORING_API } from "../api";
import { getCurrency } from "../selectors/currency";
import { FETCHING_RISK, RISK_FAILURE, RISK_SUCCESS, WALLET_SET_NAMES } from "./actionNames";
import history from "../components/history";
/**
 * THIS IS CONFUSING AF
 * I am going to try to do it a little justice.
 * This is a function that builds and returns a function that takes the walletID
 * meant to be passed as a dispatch to a WalletSummary or CustomWalletSummary component in
 * mapDispatchToProps. But those components just wrap the returned function in another function
 * in which it passes the walletId parameter and wraps in a dispatch to sets the props for
 * WalletSummaryBase. WalletSummaryBase has the actually react logic for the wallet summary.
 * It uses the props from WalletSummary or CustomWalletSummary for the fetch function.
 *
 * TLDR: function that returns a function, used by a component which makes another function
 * to wrap the function with dispatch, that component passes that function into the props of another
 * component which calls its prop.
 *
 * createFetchSummary creates a template for checking redux state using getSummary,
 * then if empty uses api to make an api call to get the wallet summary from the api.
 * In the meantime it dispatches fetchAction, successAction, and failureAction for
 * how the api call went.
 *
 * @param api prefix for the api to call
 * @param getSummary function to get wallet summary from redux state
 * @param fetchAction dispatch action for fetching
 * @param successAction dispatch action for success
 * @param failureAction dispatch action for failure
 * @param graphId
 */
export const createFetchSummary = (
  api,
  getSummary,
  { fetchAction, successAction, failureAction }
) => (item, graphId = 0) => async (dispatch, getState) => {
  const summary = getSummary(getState(), item);
  if (summary) {
    // Handle wallets. A positive address count means it's a wallet
    if (summary.addressCount !== undefined) {
      if (summary.addressCount !== 0) {
        return;
      }
      // Handle addresses
    } else if (summary.addressId !== 0) {
      return;
    }
  }
  const name = getCurrency(getState());

  // If the data wasn't in the store, fetch it using the api
  dispatch({ type: fetchAction });
  try {
    const { data } =
      item > 0 || api === ADDRESS_API
        ? await axios.get(`${api(name)}/${item}/stats`)
        : await axios.get(
            `${CUSTOM_WALLETS_API(name)}/${item}/stats${graphId > 0 ? `/${graphId}` : ""}`
          );
    dispatch({
      type: successAction,
      item,
      data,
      name
    });
    if (api === ADDRESS_API) {
      /*
       * If the address summary was called we need to populate the wallet's name in the redux store,
       * wallet summary already does this automatically
       */
      dispatch({
        type: WALLET_SET_NAMES,
        walletIds: {
          [data["walletId"]]: data["name"]
        },
        name
      });
    }
  } catch (err) {
    dispatch({
      type: failureAction,
      item,
      name
    });
    // if fails probably address doesn't exist so redirect to 404
    // TODO find redux way to not change url but put 404 page
    history.push("/404");
    throw err;
  }
};

export const createFetchResearchRawData = (
  api,
  selector,
  { fetchAction, successAction, failureAction }
) => item => async (dispatch, getState) => {
  const name = getCurrency(getState());
  const researchData = selector(getState(), item);
  if (researchData) {
    // Handle wallets. A positive address count means it's a wallet
    return;
  }
  // If the data wasn't in the store, fetch it using the api
  dispatch({ type: fetchAction });
  try {
    const { data } = await axios.get(`${api(name)}/${item}/raw`);
    dispatch({
      type: successAction,
      item,
      data,
      name
    });
  } catch (err) {
    dispatch({
      type: failureAction,
      item,
      name
    });
    throw err;
  }
};

export const createRiskFetch = (type, selector) => item => async (dispatch, getState) => {
  const name = getCurrency(getState());
  const riskData = selector(getState(), item);
  if (riskData) {
    return;
  }
  // If the data wasn't in the store, fetch it using the api
  dispatch({ type: FETCHING_RISK, entityType: type, item });
  try {
    const { data } = await axios.get(`${RISK_SCORING_API(name)}/${type}/${item}`);
    dispatch({
      type: RISK_SUCCESS,
      item: item.toString(),
      data,
      name,
      entityType: type
    });
  } catch (err) {
    dispatch({
      type: RISK_FAILURE,
      item,
      name,
      entityType: type
    });
    throw err;
  }
};

// when the route changes to an address, fetch only if hasn't been done before,
// otherwise wait for button to be clicked
// This is super confusing to follow
export const createFetchTransactions = (
  api,
  getTransactions,
  { fetchAction, successAction, failureAction }
) =>
  /**
   * Fetches batch of transactions for given wallet
   *
   * initial is set to true when you want to perform initial fetch to display some
   * transactions (e.g. mounting) but don't want to load additional if some transactions
   * are already loaded.
   * @param {string} item
   * @param {number} order either 1 to fetch for newest transactions or -1 for oldest
   * @param {number} startDate unix epoch
   * @param {number} endDate unix epoch
   * @param {boolean} initial whether to load new values
   * @returns {Function} that returns a promise
   */
  (item, order, startDate, endDate, initial = false) => (dispatch, getState) => {
    const transactions = getTransactions(getState(), item);
    const keyName = order > 0 ? "newestFirstNextKey" : "oldestFirstNextKey";
    // Setting key null interferes with URL building, instead use -1 as default
    // for when address is not currently in store and will be created in the reducer.
    let key = -1;
    if (transactions) {
      if (initial) {
        if (transactions.get(keyName) !== 0) {
          // Initial fetches are done only when address is loaded for the first time
          // To load more transactions, initial should be set to false
          return;
        }
      }
      key = transactions.get(keyName);
      if (key === null) {
        // There are no more transactions to fetch
        return;
      }
    }

    // Get the current currency
    const name = getCurrency(getState());

    dispatch({ type: fetchAction });
    let API = `${api(name)}/${item}/transactions`;
    API = order > 0 ? `${API}/newest` : `${API}/oldest`;
    API = key === -1 || key === 0 ? API : `${API}/${key}`;
    return new Promise((resolve, reject) => {
      axios
        .get(API, {
          params: {
            startDate,
            endDate
          }
        })
        .then(({ data }) => {
          dispatch({
            type: successAction,
            item,
            order,
            data,
            name
          });
          resolve();
        })
        .catch(() => {
          dispatch({
            type: failureAction,
            item,
            order,
            startDate,
            endDate,
            initial,
            name
          });
          reject();
        });
    });
  };

// when the route changes to an address, fetch only if hasn't been done before,
// otherwise wait for button to be clicked
export const createFetchTransactionsBySatoshi = (
  api,
  getTransactions,
  { fetchAction, successAction, failureAction }
) =>
  /**
   * Fetches batch of transactions Satoshi
   *
   * initial is set to true when you want to perform initial fetch to display some
   * transactions (e.g. mounting) but don't want to load additional if some transactions
   * are already loaded.
   * @param {string} item
   * @param startDate
   * @param endDate
   * @param {boolean} initial whether to load new values
   * @returns {Function}
   */
  (item, startDate = null, endDate = null, initial = false) => (dispatch, getState) => {
    const transactions = getTransactions(getState(), item);
    const keyName = "largestFirstNextKey";
    // Setting key null interferes with URL building, instead use -1 as default
    // for when address is not currently in store and will be created in the reducer.
    let key = -1;
    if (transactions) {
      if (initial) {
        if (transactions.get(keyName) !== 0) {
          // Initial fetches are done only when address is loaded for the first time
          // To load more transactions, initial should be set to false
          return;
        }
      }
      key = transactions.get(keyName);
      if (key === null) {
        // There are no more transactions to fetch
        return;
      }
    }
    // Get the current currency
    const name = getCurrency(getState());

    dispatch({ type: fetchAction });
    let API = `${api(name)}/${item}/transactions/largest`;
    API = key.primary === -1 || key.primary === 0 ? API : `${API}/${key.primary}/${key.secondary}`;
    return axios
      .get(API, {
        params: {
          startDate,
          endDate
        }
      })
      .then(({ data }) => {
        dispatch({
          type: successAction,
          item,
          data,
          name
        });
      })
      .catch(() => {
        dispatch({
          type: failureAction,
          item,
          initial,
          name
        });
      });
  };

export const createFetchWalletAddresses = (
  api,
  getWalletAddresses,
  { fetchAction, successAction, failureAction }
) => (walletId, initial = false) => async (dispatch, getState) => {
  const walletAddresses = getWalletAddresses(getState(), walletId.toString());
  let primaryKey = -1;
  let secondaryKey = -1;
  if (walletAddresses) {
    if (initial) {
      if (walletAddresses.get("primaryKey") !== 0) {
        return;
      }
    }
    primaryKey = walletAddresses.get("primaryKey");
    secondaryKey = walletAddresses.get("secondaryKey");
    if (primaryKey === null) {
      return;
    }
  }
  // Get the current currency
  const name = getCurrency(getState());

  dispatch({ type: fetchAction });
  let API = `${api(name)}/${walletId}/addresses`;
  API =
    primaryKey == null || primaryKey === -1 || primaryKey === 0
      ? API
      : `${API}/${primaryKey}/${secondaryKey}`;
  try {
    const { data } = await axios.get(API);
    dispatch({
      type: successAction,
      walletId: walletId.toString(),
      data,
      name
    });
  } catch (err) {
    dispatch({
      type: failureAction,
      walletId: walletId.toString(),
      initial,
      name
    });
    throw err;
  }
};

export const createFetchMutualWallets = (
  api,
  getMutualWallets,
  { fetchAction, successAction, failureAction }
) => item => async (dispatch, getState) => {
  const mutualWallets = getMutualWallets(getState(), item);

  // Get the current currency
  const name = getCurrency(getState());

  // If the address record exists, but the mutual wallets haven't been fetched, fetch them.
  if (!mutualWallets || !mutualWallets.walletsFetched) {
    dispatch({ type: fetchAction });
    const API = `${api(name)}/${item}/tagged-transactions-summary`;
    try {
      const { data } = await axios.get(API);
      dispatch({
        type: successAction,
        item,
        data,
        name
      });
    } catch (err) {
      dispatch({
        type: failureAction,
        item,
        name
      });
      throw err;
    }
  }
};

export const createAddressFetchMoreInfo = (
  api,
  getAddressMoreInfo,
  { fetchAction, successAction, failureAction }
) => address => async (dispatch, getState) => {
  const addressMoreInfo = getAddressMoreInfo(getState(), address);

  // If the address info isn't already in redux, perform the fetch operation
  if (!addressMoreInfo || addressMoreInfo["ip"].length === 0) {
    // First dispatch the fetch action
    dispatch({ type: fetchAction });

    // Get the current currency
    const name = getCurrency(getState());

    // This is the api we'll need to hit
    const API = `${api(name)}/${address}/more-info`;

    try {
      // Make the api request
      const { data } = await axios.get(API);

      dispatch({
        type: successAction,
        data,
        name,
        address
      });
    } catch (e) {
      dispatch({
        type: failureAction
      });
    }
  }
};

export const createAddressFetchCoinSwap = (
  api,
  getAddressCoinSwap,
  { fetchAction, successAction, failureAction }
) => address => async (dispatch, getState) => {
  const addressCoinSwap = getAddressCoinSwap(getState(), address);
  // If the address info isn't already in redux, perform the fetch operation
  if (!addressCoinSwap["coinSwap"] || addressCoinSwap["coinSwapData"].length === 0) {
    // First dispatch the fetch action
    dispatch({ type: fetchAction });

    // Get the current currency
    const name = getCurrency(getState());

    // This is the api we'll need to hit
    const API = `${api(name)}/${address}/coin-swap`;
    try {
      // Make the api request
      const { data } = await axios.get(API);
      dispatch({
        type: successAction,
        data,
        name,
        address
      });
    } catch (e) {
      dispatch({
        type: failureAction
      });
    }
  }
};

export const createAddressFetchMutualWalletTransactions = (
  api,
  getWalletTransactions,
  { fetchAction, successAction, failureAction }
) => (item, walletId, tag, order, startDate, endDate, loadMore = false) => async (
  dispatch,
  getState
) => {
  // before doing the fetch, use the passed in selector to see if we need to fetch at an offset
  const walletTransactions = getWalletTransactions(getState(), item, walletId).transactions.toJS();

  let offset = 0;
  if (walletTransactions.order === "newestFirst") {
    offset = walletTransactions.newestFirst.length;
  } else if (walletTransactions.order === "oldestFirst") {
    offset = walletTransactions.oldestFirst.length;
  } else {
    offset = walletTransactions.largestFirst.length;
  }

  // Determine if we want/need to fetch more or not
  // we don't if the current order has no more results
  // we don't if 'loadMore' is false and there are zero fetched results
  if (
    !walletTransactions[`${order}HasMore`] ||
    (walletTransactions[order].length > 0 && !loadMore)
  ) {
    return Promise.resolve();
  }
  // Get the current currency
  const name = getCurrency(getState());

  dispatch({ type: fetchAction });
  const params = {
    order,
    offset,
    startDate,
    endDate
  };
  const API = `${api(name)}/${item}/tagged-transactions/${walletId}`;

  try {
    // fetch the data
    const response = await axios.get(API, { params });
    dispatch({
      type: successAction,
      item,
      walletId,
      order,
      data: response.data,
      name
    });
  } catch (err) {
    dispatch({
      type: failureAction,
      item,
      walletId,
      name
    });
    throw err;
  }
};

export const createWalletFetchMutualWalletTransactions = (
  api,
  getWalletTransactions,
  { fetchAction, successAction, failureAction }
) => (item, walletId, tag, order, startDate, endDate, loadMore = false, graphId = 0) => async (
  dispatch,
  getState
) => {
  // before doing the fetch, use the passed in selector to see if we need to fetch at an offset
  const walletTransactions = getWalletTransactions(getState(), item, walletId).transactions.toJS();
  const key = walletTransactions[`${walletTransactions.order}NextKey`];

  // Determine if we want/need to fetch more or not
  // we don't if the current order has no more results
  // we don't if 'loadMore' is false and there are zero fetched results
  if (key === null || (walletTransactions[order].length > 0 && !loadMore)) {
    return Promise.resolve();
  }
  // Get the current currency
  const name = getCurrency(getState());

  dispatch({ type: fetchAction });

  // We can only include the key in the query string after the initial fetch. no default val
  const params =
    key !== 0
      ? {
          order,
          key,
          startDate,
          endDate
        }
      : {
          order,
          startDate,
          endDate
        };

  const API = `${api(name)}/merged-pair/${item}/${walletId}`;

  try {
    // fetch the data
    const response = await axios.get(API, { params });
    dispatch({
      type: successAction,
      item,
      walletId,
      order,
      data: response.data,
      name
    });
  } catch (err) {
    dispatch({
      type: failureAction,
      item,
      walletId,
      name
    });
    throw err;
  }
};

export const createFetchMentions = (
  api,
  getTags,
  { fetchAction, successAction, failureAction }
) => (item, initial = false, graphId = 0) =>
  async function fetchMentions(dispatch, getState) {
    const tags = getTags(getState(), item);
    // let seenTags;
    let key = -1;
    if (tags) {
      // seenTags = tags.get('seenTags');
      if (initial) {
        if (tags.nextKey !== 0) {
          return;
        }
      }
      key = tags.nextKey;
      if (key === null) {
        return;
      }
    }
    // Get the current currency
    const name = getCurrency(getState());

    dispatch({ type: fetchAction });
    if (item < 0 && api !== ADDRESS_API) {
      api = CUSTOM_WALLETS_API;
    }
    let API = `${api(name)}/${item}/mentions`;
    API = key == null || key === -1 || key === 0 ? API : `${API}/${key}`;
    API = item < 0 && api !== ADDRESS_API && graphId > 0 ? `${API}/${graphId}` : API;
    try {
      const { data } = await axios.get(API);
      dispatch({
        type: successAction,
        item,
        data,
        name
      });
    } catch (err) {
      dispatch({
        type: failureAction,
        item,
        initial,
        name
      });
      throw err;
    }
  };

export const createFetchBsa = (api, { keyAction, successAction, failureAction }) => (
  address,
  initial = false
) => async (dispatch, getState) => {
  const state = getState();
  // Get the current currency
  const name = getCurrency(state);

  // Get the key to determine if the data already exists in redux
  const key = state.getIn([name, "address", address, "bsa", "bsaKey"]);
  if (initial && key !== undefined) {
    return;
  }

  let apiFull;
  // Check if its the first pull or not.
  if (key === undefined || key === 0 || initial === true) {
    apiFull = `${api(name)}/${address}/bsa`;
  } else if (key !== null) {
    apiFull = `${api(name)}/${address}/bsa/${key}`;
  } else {
    apiFull = null;
  }

  try {
    if (apiFull === null) {
      // Just increase the number of tags we want to show if everything is
      // loaded already.
      dispatch({
        type: successAction,
        data: [],
        address,
        increaseNumberToShow: true,
        name
      });
      return;
    }
    const { data } = await axios.get(apiFull);
    dispatch({
      type: successAction,
      data,
      address,
      increaseNumberToShow: true,
      name
    });
    dispatch({
      type: keyAction,
      data,
      address,
      name
    });
  } catch (err) {
    dispatch({
      type: failureAction
    });
  }
};

export const createFetchAllBsa = (api, { keyAction, successAction, failureAction }) => (
  address,
  initial = false
) => async (dispatch, getState) => {
  const state = getState();
  // Get the current currency
  const name = getCurrency(state);

  let key = state.getIn([name, "address", address, "bsa", "bsaKey"]);
  /* TODO see if this can just be undefined */
  let apiFull = false;

  try {
    if (apiFull === null) {
      return;
    }
    const makeCalls = async () => {
      // Check if its the first pull or not.
      if (key === undefined || key === 0 || initial === true) {
        apiFull = `${api(name)}/${address}/bsa`;
      } else if (key !== null) {
        apiFull = `${api(name)}/${address}/bsa/${key}`;
      } else {
        apiFull = null;
        return;
      }
      const { data } = await axios.get(apiFull);
      // eslint-disable-next-line prefer-destructuring
      key = data.key;
      dispatch({
        type: successAction,
        data,
        address,
        increaseNumberToShow: false,
        name
      });
      dispatch({
        type: keyAction,
        data,
        address,
        name
      });
      if (apiFull != null) {
        makeCalls();
      }
    };
    makeCalls();
  } catch (err) {
    dispatch({
      type: failureAction,
      name
    });
  }
};
