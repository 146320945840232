import React, { useState } from "react";
import {useParams} from "react-router-dom";
import {changePhoneNumber} from "../../actions/authentication";
import {useDispatch} from "react-redux";
import EnterPhoneNumber from "./EnterPhoneNumber";
import {useSnackbar} from "notistack";
import {autoLoggedOut} from "../../api";
import history from "../history";

export const ChangePhone = () => {
    let { token } = useParams();
    const [codeSent, setCodeSent] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    const dispatch = useDispatch();
    const registerNewPhone = async (phone) => {
        try {
          dispatch(changePhoneNumber(phone, token))
          enqueueSnackbar("Phone Changed", {variant: "success"})
          await autoLoggedOut(dispatch)
          history.replace("/login");
        } catch (e) {

        }

    }
    return <EnterPhoneNumber setCodeSent={setCodeSent} registerPhone={registerNewPhone}/>;
}