import React from "react";
import PropTypes from "prop-types";
import DismissableAlert from "./DissmissableAlert";

/**
 * Component used to display multiple alerts in a queue
 */
export default class AlertsQueue extends React.Component {
  constructor(props) {
    super(props);

    const falseDismissed = [];
    for (let i = 0; i < this.props.alerts.length; i += 1) {
      falseDismissed.push(false);
    }
    this.state = {
      dismissedAlerts: falseDismissed,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.state.dismissedAlerts.length !== prevProps.alerts.length) {
      const falseDismissed = [];
      for (let i = 0; i < this.props.alerts.length; i += 1) {
        falseDismissed.push(false);
      }
      this.setState({ dismissedAlerts: falseDismissed });
    }
  }

  onDismissed = idx => {
    this.props.dismiss();
    const newValue = this.state.dismissedAlerts;
    newValue[idx] = true;
    if (this.state.dismissedAlerts.length !== this.props.alerts.length) {
      const falseDismissed = [];
      for (let i = 0; i < this.props.alerts.length; i += 1) {
        falseDismissed.push(false);
      }
      this.setState({ dismissedAlerts: falseDismissed });
    } else {
      this.setState({ dismissedAlerts: newValue });
    }
  };

  getVisibleAlerts = () =>
    this.props.alerts
      .map((val, idx) => ({
        alert: val,
        idx,
      }))
      .filter(({ idx }) => !this.state.dismissedAlerts[idx], this)
      .slice(0, this.props.limit);

  render() {
    return (
      <div key={JSON.stringify(this.state.dismissedAlerts)}>
        {this.getVisibleAlerts().map(({ alert: { style, text }, idx }) => (
          <DismissableAlert
            key={idx}
            bsStyle={style}
            dismissable
            onDismissed={() => this.onDismissed(idx)}
          >
            {text}
          </DismissableAlert>
        ))}
      </div>
    );
  }
}

AlertsQueue.propTypes = {
  alerts: PropTypes.array,
  limit: PropTypes.number,
  dismiss: PropTypes.func,
};

AlertsQueue.defaultProps = {
  alerts: [],
  limit: 3,
  dismiss: () => {},
};
