// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import addresses from "../../videos/addresses.mp4";
import wallets from "../../videos/wallets_2.mp4";
import watchlist from "../../videos/watchlist.mp4";
import custom_tags from "../../videos/custom_tags.mp4";
import graph from "../../videos/graphing_3.mp4";
import support from "../../videos/support.mp4";
import notes_2 from "../../videos/Notes_2.mp4";
import transactions from "../../videos/transations.mp4";
import subpoena from "../../videos/subpoena_support_2.mp4";

// Hex values taken directly from spreadsheet.
const HelpDialogueChooser = (
  clientModeObject = {
    word_for_collection: "",
    cap_word_for_collection: "",
    plural_for_collection: "",
    cap_plural_for_collection: "",
    nav_logo: null,
    login_logo: null,
    login_top_logo: null,
    login_top_icon: null,
  }
) => {
  return {
    "case-management": {
      title: "Case Management",
      elements: [
        {
          video: notes_2,
          title: "Case Management Feature",
          description: "",
          duplicate: false,
        },
      ],
    },
    // graphs: {
    //   title: "Graphs Tab",
    //   elements: [
    //     {
    //       video: graph,
    //       title: "Graphing",
    //       description: "This video explains how to use the site's Graphing Tool.",
    //       duplicate: true,
    //     },
    //   ],
    // },
    graph: {
      title: "Graph",
      elements: [
        {
          video: graph,
          title: "Graphing",
          description: "This video explains how to use the site's Graphing Tool.",
          duplicate: false,
        },
      ],
    },
    "custom-wallet": {
      title: "Custom Entities",
      elements: [
        {
          video: graph,
          title: "Custom Entities",
          description: "This video explains how to work with custom entities.",
          duplicate: false,
        },
      ],
    },
    address: {
      title: "Addresses",
      elements: [
        {
          video: addresses,
          title: "Addresses",
          description:
            "This video tells you everything you need to know about working with Bitcoin Addresses on the site.",
          duplicate: false,
        },
      ],
    },
    transaction: {
      title: "Transactions",
      elements: [
        {
          video: transactions,
          title: "Transactions",
          description:
            "This video tells you everything you need to know about working with Bitcoin Transactions on the site.",
          duplicate: false,
        },
      ],
    },
    wallet: {
      title: clientModeObject.cap_word_for_collection,
      elements: [
        {
          video: wallets,
          title: clientModeObject.cap_plural_for_collection,
          description: `This video tells you everything you need to know about working with ${clientModeObject.plural_for_collection} on the site.`,
          duplicate: false,
        },
      ],
    },
    watchlist: {
      title: "Watchlist",
      elements: [
        {
          video: watchlist,
          title: "Watchlist",
          description: `This video tells you everything you need to know about working with the watchlist.`,
          duplicate: false,
        },
      ],
    },
    "custom-tags": {
      title: "Custom Tags",
      elements: [
        {
          video: custom_tags,
          title: "Custom Tags",
          description:
            "This video explains everything you need to know about working with Custom Tags on the site.",
          duplicate: false,
        },
      ],
    },
    support: {
      title: "Support",
      elements: [
        {
          video: support,
          title: "Submitting Support Tickets",
          description: "",
          duplicate: false,
        },
      ],
    },
    // "subpoena-support": {
    //   title: "Subpoena Support",
    //   elements: [
    //     {
    //       video: subpoena,
    //       title: "Subpoena Support",
    //       description: "This video explains how to use The Subpoena Support tool.",
    //       duplicate: false,
    //     },
    //   ],
    // },
    // 'graphs': {
    //       'title': 'Graphs',
    //       'elements': [
    //           {
    //               'video': Example_Video,
    //               'title': 'This is the title',
    //               'description': ''
    //           }
    //       ]
    //   },
  };
};

export default HelpDialogueChooser;
