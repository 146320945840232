// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { createSelector } from "reselect";

import { WalletSummary } from "../reducers/sharedRecords";
import { getGraphInfoMemo } from "./graph";
import { getCurrency } from "./currency";
import { getProcessed } from "./customWallet";
import { getEmail } from "./authentication";
import { getAddressSummary, getName } from "./ethereum/address";
import { getAddress } from "./address";

export const getWallet = (state, walletId) => {
  const currency = getCurrency(state);
  return state.getIn([
    currency,
    currency === "ethereum" ? "address" : "wallet",
    walletId.toString()
  ]);
};

export const getWalletSummary = (state, walletId, noNull = false) => {
  const walletMap = getWallet(state, walletId);
  if (walletMap) {
    return walletMap.get("summary").toJS();
  }
  if (noNull) {
    return new WalletSummary().toJS();
  }
  return null;
};

export const getWalletRisk = (state, walletId) => {
  const wallet = getWallet(state, walletId);
  if (wallet) {
    return wallet.get("risk");
  }
  return null;
};

/**
 * Gets wisteria data for a given wallet,
 * @param state: redux state
 * @param {number} walletId
 * @returns {{}|{wisteria_category_confidence_score: *, wisteria_category: *, wisteria_attribution: *, wisteria_attribution_confidence_score: *}}
 */
export const getWisteriaData = (state, walletId) => {
  const walletMap = getWallet(state, walletId);
  if (walletMap) {
    const names = walletMap.get("name").toJS();
    return {
      wisteria_attribution: names["wisteria_attribution"] && names["wisteria_attribution"]["name"],
      wisteria_attribution_confidence_score:
        names["wisteria_attribution"] && names["wisteria_attribution"]["score"],
      wisteria_category: names["wisteria_category"] && names["wisteria_category"]["name"],
      wisteria_category_confidence_score:
        names["wisteria_category"] && names["wisteria_category"]["score"]
    };
  }
  return {};
};

export const getWalletNameHelper = (names, user_email, skip_custom = false) => {
  let wallet_names = [];
  if (names["name"]) {
    // custom wallet name
    wallet_names.push({
      name: names["name"],
      wisteria: false,
      tagType: "Custom Wallet",
      sourceName: "Custom",
      sourceConfidence: null
    });
    return wallet_names;
  }
  if (names["customTag"] && !skip_custom) {
    // custom tags is a list so we have to order and push to wallet_names for every custom tag
    names["customTag"]
      .sort((a, b) => (a.email === user_email || (b.email !== user_email && a.id < b.id) ? -1 : 1))
      .forEach(({ main, ...item }) => {
        wallet_names.push({
          ...item,
          wisteria: false,
          custom: true,
          tagType: "customTag",
          sourceName: "Custom",
          sourceConfidence: null,
          ...(main && { main: true })
        });
      });
  }
  if (names["tag"]) {
    wallet_names.push({
      ...names["tag"],
      name: names["tag"].name,
      wisteria: false,
      tagType: "tag",
      sourceName: names["tag"].source_name,
      sourceConfidence: names["tag"].source_confidence,
      ...(names["tag"].main && { main: true })
    });
  }
  // We only show wallet names for wisteria attributions if they have high confidence
  if (names["wisteria_attribution"] && names["wisteria_attribution"]["score"] > 0.7) {
    wallet_names.push({
      ...names["wisteria_attribution"],
      name: `${names["wisteria_attribution"]["name"]} (${(
        names["wisteria_attribution"]["score"] * 100
      ).toFixed(0)}%)`,
      wisteria: true,
      tagType: "wisteria_attribution",
      sourceName: names["wisteria_attribution"].source_name,
      sourceConfidence: names["wisteria_attribution"].source_confidence,
      ...(names["wisteria_attribution"]["main"] && { main: true })
    });
  }
  if (names["address"]) {
    wallet_names.push({
      ...names["addresss"],
      name: names["address"],
      wistia: false,
      tagType: "ID",
      sourceName: null,
      sourceConfidence: null,
      ...(names["address"].main && { main: true })
    });
  }
  wallet_names.sort((a, b) => (a.main ? -1 : b.main ? 1 : 0));
  return wallet_names;
};

/**
 * Gets all the wallet names in the order of
 * custom wallet name >custom tag(s) > tag > wisteria > wallet id
 * except if one of those names are set to be the main in which case that name always goes first
 *
 * Note: wisteria attribution is returned only for confidence scores > 0.7
 * @param state redux state
 * @param {number} walletId
 * @param {boolean} skip_custom if you don't want to get the custom tag.
 *
 * TODO change wisteria cutoff score to a constant
 */
export const getWalletNames = (state, walletId, skip_custom = false) => {
  if (!walletId) {
    return [];
  }
  const currency = getCurrency(state);
  if (currency === "ethereum") {
    const addressSummary = getAddressSummary(state, walletId, true);
    const names = getName(addressSummary);
    let wallet_names = names.map(({ source, name }) => ({
      name,
      tagType: source
    }));
    wallet_names.push({
      name: walletId,
      tagType: "ID"
    });
    return wallet_names;
  }
  const walletMap = getWallet(state, walletId);
  const user_email = getEmail(state);
  let wallet_names = [];
  if (walletMap) {
    const names = walletMap.get("name").toJS();
    wallet_names = getWalletNameHelper(names, user_email, skip_custom);
  }

  wallet_names.push({
    name: walletId.toString(),
    wisteria: false,
    tagType: "ID",
    sourceName: "Internal",
    sourceConfidence: null
  });
  // sort to put the main name first
  wallet_names.sort((a, b) => (a.main ? -1 : b.main ? 1 : 0));
  return wallet_names;
};

/**
 * Gets the wallet name from the redux store and automatically decides on what name based on the hierarchy that
 * custom tag > tag > wisteria > wallet id
 *
 * Note: wisteria attribution is returned only for confidence scores > 0.7
 * @param state redux state
 * @param {number} walletId
 * @param {boolean} skip_custom if you don't want to get the custom tag.
 *
 * TODO change wisteria cutoff score to a constant
 */
export const getWalletName = (state, walletId, skip_custom = false) => {
  return getWalletNames(state, walletId, skip_custom)[0];
};

export const getWalletAddress = (state, walletId) => {
  const walletMap = getWallet(state, walletId);
  if (walletMap) {
    return walletMap.get("name").toJS();
  }
  return null;
};

/**
 * Gets the list of custom tags associated with this wallet in the redux store
 * @param state
 * @param walletId
 * @returns {null|any}
 */
export const getCustomTagInfo = (state, walletId) => {
  const walletMap = getWallet(state, walletId);
  if (walletMap) {
    return walletMap.getIn(["name", "customTag"]);
  }
  return null;
};

export const getMutualTransactions = (state, address, walletId) =>
  state.getIn([getCurrency(state), "wallet", address, "mutualWallets", "mutualWallets", walletId]);

export const getAllWalletSummaries = (state, custom, noNull = false) => {
  const walletMap = state.get("wallet");
  if (walletMap) {
    return walletMap.toJS();
  }
  return null;
};

export const getWalletPeelChainData = (state, walletId) => {
  const walletMap = getWallet(state, walletId);
  if (walletMap) {
    const in_peel_chain = walletMap.get("peel");
    const data = walletMap.get("peel_chain_data");
    return {
      peel: in_peel_chain,
      peel_chain_data: data
    };
  }

  return {
    peel: false,
    peel_chain_data: null
  };
};

const getAllWalletSummariesMemo = createSelector(
  state => {
    const currency = getCurrency(state);
    return state.getIn([currency, "wallet"]);
  },
  wallets => {
    return wallets.toJS();
  }
);

export const makeGetAllWalletSummaries = (state, custom, noNull, graphId) => {
  const summaries = getAllWalletSummariesMemo(state);
  const graphInfo = getGraphInfoMemo(state, graphId);
  return {
    walletSummaries: summaries,
    graphInfo
  };
};

export const getWalletMentions = (state, walletId, custom) => {
  const walletMap = getWallet(state, walletId, custom);
  if (walletMap) {
    return walletMap.get("mentions");
  }
  return null;
};

export const getMentionsCount = (state, walletId) => {
  const walletMap = getWallet(state, walletId);
  if (walletMap) {
    return walletMap.getIn(["summary", "mentionsCount"]);
  }
  return 0;
};

export const getSentWallets = (state, walletId) =>
  state.getIn([getCurrency(state), "wallet", walletId.toString(), "sentWallets"]);

export const getReceivedWallets = (state, walletId) => {
  return state.getIn([getCurrency(state), "wallet", walletId.toString(), "receivedWallets"]);
  //const unclustered_wallets = state.getIn([getCurrency(state), "wallet", walletId.toString(), "unclustered_amounts", "unclustered_recv"]);
  //return received_wallets.concat(unclustered_wallets)
};

export const getUnclusteredReceivedAmounts = (state, walletId) => {
  return state.getIn([
    getCurrency(state),
    "wallet",
    walletId.toString(),
    "unclustered_amounts",
    "unclustered_recv"
  ]);
};

export const getUnclusteredSentAmounts = (state, walletId) => {
  return state.getIn([
    getCurrency(state),
    "wallet",
    walletId.toString(),
    "unclustered_amounts",
    "unclustered_sent"
  ]);
};

export const getSentFiltered = (state, walletId) =>
  state.getIn([getCurrency(state), "wallet", walletId.toString(), "sentWallets", "filtered"]);

export const getReceivedFiltered = (state, walletId) =>
  state.getIn([getCurrency(state), "wallet", walletId.toString(), "receivedWallets", "filtered"]);

export const makeGetWalletMentions = () =>
  createSelector(
    [getWalletMentions],
    walletMentions => {
      if (!walletMentions) {
        return {
          mentions: [],
          nextKey: 0
        };
      }
      return {
        mentions: walletMentions
          .get("mentions")
          .map(mention => mention.toJS())
          .toJS(),
        nextKey: walletMentions.get("nextKey")
      };
    }
  );

// have this keep as immutable as default and used by action creators
// have a version that converts that will be used by components
export const getWalletTransactions = (state, walletId) => {
  const walletMap = getWallet(state, walletId);
  if (walletMap) {
    return walletMap.get("transactions");
  }
  return null;
};

export const getTransactionsScrollTop = (state, walletId) => {
  const walletMap = getWallet(state, walletId);
  if (walletMap) {
    return walletMap.get("transactionsScrollTop");
  }
  return null;
};

export const getAddressesScrollTop = (state, walletId) => {
  const walletMap = getWallet(state, walletId);
  if (walletMap) {
    return walletMap.get("addressesScrollTop");
  }
  return null;
};

export const makeGetWalletTransactions = () =>
  createSelector(
    [getWalletTransactions, getTransactionsScrollTop, getWalletSummary, getProcessed],
    (walletTransactions, scrollTop, walletSummary, processed) => {
      if (!walletTransactions) {
        return {
          transactions: [], // list of transaction objects
          newestFirstNextKey: 0,
          oldestFirstNextKey: 0,
          order: 1,
          byLargest: false,
          scrollTop: null,
          processed: true,
          startDate: null,
          endDate: null
        };
      }
      const tag = walletSummary.primaryTag;
      const transactionMap = walletTransactions.get("transactionMap");

      let key;
      if (walletTransactions.get("order") === 2) {
        key = "largestFirst";
      } else if (walletTransactions.get("order") === 1) {
        key = "newestFirst";
      } else if (walletTransactions.get("order") === -1) {
        key = "oldestFirst";
      }
      const largestFirstNextKey = walletTransactions.getIn(["largestFirstNextKey", "primary"]);
      const hasNextLargest = walletTransactions.getIn(["largestFirstNextKey", "primary"]) != null;
      const transactionIds = walletTransactions.get(key);
      const transactions = transactionIds
        .map(transactionId => transactionMap.get(transactionId).toJS())
        .toJS();
      const startDate = walletTransactions.get("startDate");
      const endDate = walletTransactions.get("endDate");
      return {
        transactions,
        newestFirstNextKey: walletTransactions.get("newestFirstNextKey"),
        oldestFirstNextKey: walletTransactions.get("oldestFirstNextKey"),
        order: walletTransactions.get("order"),
        largestFirstNextKey,
        hasNextLargest,
        scrollTop,
        tag,
        processed,
        startDate,
        endDate
      };
    }
  );

export const getWalletAddresses = (state, walletId) => {
  const walletMap = getWallet(state, walletId);
  if (walletMap) {
    return walletMap.get("addresses");
  }
  return null;
};

export const getWalletAddressesRecords = (state, walletId) => {
  const walletMap = getWallet(state, walletId);
  if (walletMap) {
    return walletMap.getIn(["addresses", "addressRecords"]);
  }
  return null;
};

export const makeGetWalletAddresses = () =>
  createSelector(
    [getWalletAddresses, getAddressesScrollTop],
    (walletAddresses, scrollTop) => {
      if (!walletAddresses) {
        return {
          addresses: [],
          nextKey: 0,
          scrollTop: null
        };
      }
      let { addressRecords } = walletAddresses;
      addressRecords = addressRecords.map(address => address.toJS()).toJS();
      return {
        addresses: addressRecords,
        nextKey: walletAddresses.primaryKey,
        scrollTop
      };
    }
  );

export const getShowAddAddressModal = (state, walletId) => {
  const walletMap = getWallet(state, walletId, true);
  if (walletMap) {
    return walletMap.get("showAddAddressModal");
  }
  return null;
};

export const getShowDeleteWalletModal = (state, walletId) => {
  const walletMap = getWallet(state, walletId, true);
  if (walletMap) {
    return walletMap.get("showDeleteWalletModal");
  }
  return null;
};

export const makeGetCustomWalletAddresses = () =>
  createSelector(
    [getWalletAddresses, getProcessed],
    (walletAddresses, processed) => {
      if (!walletAddresses) {
        return {
          addresses: [],
          nextKey: 0,
          processed: true
        };
      }

      let { addressRecords } = walletAddresses;
      addressRecords = addressRecords.map(address => address.toJS()).toJS();
      return {
        addresses: addressRecords,
        nextKey: walletAddresses.nextKey,
        processed
      };
    }
  );

export const getWalletAssociations = (state, walletId) => {
  const walletMap = getWallet(state, walletId);
  if (walletMap) {
    return walletMap.get("associations");
  }
  return null;
};

export const makeGetWalletAssociations = () =>
  createSelector(
    [getWalletAssociations],
    walletAssociations => {
      if (!walletAssociations) {
        return {
          associations: [],
          nextKey: 1
        };
      }
      let { associations } = walletAssociations;
      associations = associations.map(association => association.toJS()).toJS();
      return {
        associations,
        nextKey: walletAssociations.nextKey
      };
    }
  );

const makeGetAssociatedWallets = (getAssociatedWallet, getUnclusteredAmounts) => () =>
  createSelector(
    [getAssociatedWallet, getUnclusteredAmounts],
    (associatedWallets, unclusteredAmounts) => {
      if (!associatedWallets) {
        return {
          associatedWallets: [],
          unclusteredAmounts: [],
          filtered: false,
          nextPrimaryKey: 0,
          nextSecondaryKey: 0
        };
      }

      const wallets = associatedWallets.wallets.map(wallet => wallet.toJS()).toJS();
      const unclusteredAmts = unclusteredAmounts.toJS();

      return {
        associatedWallets: wallets,
        unclusteredAmounts: unclusteredAmts,
        nextPrimaryKey: associatedWallets.get("nextPrimaryKey"),
        nextSecondaryKey: associatedWallets.get("nextSecondaryKey"),
        filtered: associatedWallets.get("filtered")
      };
    }
  );

export const makeGetReceivedWallets = makeGetAssociatedWallets(
  getReceivedWallets,
  getUnclusteredReceivedAmounts
);

export const makeGetSentWallets = makeGetAssociatedWallets(
  getSentWallets,
  getUnclusteredSentAmounts
);

export const getTags = (state, walletId) => {
  const walletMap = getWallet(state, walletId);
  if (!walletMap) {
    return {
      primaryTag: "",
      originalTag: ""
    };
  }
  const walletSummary = walletMap.get("summary");
  return {
    primaryTag: walletSummary.get("primaryTag"),
    originalTag: walletSummary.get("originalTag")
  };
};

export const getWalletMutualWallets = (state, address) => {
  const walletMap = getWallet(state, address);

  if (walletMap) {
    const mutualWallets = walletMap.get("mutualWallets");
    return {
      mutualWallets: mutualWallets.get("mutualWallets"),
      mostRecentCallSuccess: mutualWallets.get("mostRecentCallSuccess"),
      walletsFetched: mutualWallets.get("walletsFetched")
    };
  }
  return null;
};

export const getWalletMutualWallets2 = (state, address) => {
  const f = () => {
    const walletMap = getWallet(state, address);
    if (walletMap) {
      const mutualWallets = walletMap.get("mutualWallets");
      return {
        mutualWallets: mutualWallets.get("mutualWallets"),
        mostRecentCallSuccess: mutualWallets.get("mostRecentCallSuccess"),
        walletsFetched: mutualWallets.get("walletsFetched")
      };
    }
    return null;
  };

  const result = f(state, address);

  if (!result) {
    return {
      mutualWallets: [],
      mostRecentCallSuccess: true,
      mutualWalletsSorted: {},
      walletsFetched: false
    };
  }

  const { mostRecentCallSuccess } = result;
  const { walletsFetched } = result;

  // sort the mutual wallets by category
  const mutualWalletsSorted = {};
  result.mutualWallets.forEach(entry => {
    const { category } = entry;

    if (mutualWalletsSorted[category] === undefined) {
      mutualWalletsSorted[category] = [entry];
    } else {
      mutualWalletsSorted[category].push(entry);
    }
  });

  // Sort each category array by the number of transactions
  Object.keys(mutualWalletsSorted).forEach(entry => {
    mutualWalletsSorted[entry].sort((a, b) => {
      const x = a["totalTrx"];
      const y = b["totalTrx"];
      return x > y ? -1 : x < y ? 1 : 0;
    });
  });

  return {
    mutualWallets: result.mutualWallets,
    mostRecentCallSuccess,
    mutualWalletsSorted,
    walletsFetched
  };
};

export const getWalletMutualTransactions = (state, address, walletId) => {
  const walletMap = getWallet(state, address);

  // Make sure the addressMap exists before attempting to access inner attributes
  if (walletMap) {
    const walletTransactionMap = getMutualTransactions(state, address, walletId);
    if (walletTransactionMap) {
      return {
        transactions: walletTransactionMap.get("transactions"),
        mostRecentCallSuccess: walletTransactionMap.get("mostRecentCallSuccess")
      };
    }
  }
  return null;
};

export const makeGetWalletMutualTransactions = () =>
  createSelector(
    [getWalletMutualTransactions],
    walletMutualTransactions => {
      if (!walletMutualTransactions) {
        return {
          transactions: {},
          newestFirst: [],
          oldestFirst: [],
          order: "newestFirst",
          hasMore: false
        };
      }
      const { transactionMap } = walletMutualTransactions.transactions;

      // get the key to the list of transactionIds
      let key;
      if (walletMutualTransactions.transactions.get("order") === "largestFirst") {
        key = "largestFirst";
      } else if (walletMutualTransactions.transactions.get("order") === "newestFirst") {
        key = "newestFirst";
      } else {
        key = "oldestFirst";
      }

      const hasMore = walletMutualTransactions.transactions.get(`${key}NextKey`) !== null;
      const transactionIds = walletMutualTransactions.transactions.get(key);

      const transactions = transactionIds
        .map(transactionId => transactionMap.get(transactionId).toJS())
        .toJS();

      const { startDate } = walletMutualTransactions.transactions;
      const { endDate } = walletMutualTransactions.transactions;
      const { mostRecentCallSuccess } = walletMutualTransactions;
      return {
        transactions,
        newestFirst: walletMutualTransactions.transactions.get("newestFirst").toJS(),
        oldestFirst: walletMutualTransactions.transactions.get("oldestFirst").toJS(),
        largestFirst: walletMutualTransactions.transactions.get("largestFirst").toJS(),
        order: walletMutualTransactions.transactions.get("order"),
        hasMore,
        mostRecentCallSuccess,
        startDate,
        endDate
      };
    }
  );
