/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getTransactionMentions } from "../../../selectors/transaction";
import BsaMentionsTransactionView from "./BsaMentionsTransactionView";
import {
  fetchAddressMentions,
  fetchAddressSummary,
  fetchAddressTransactions,
  fetchBsa,
} from "../../../actions/address";
import "../../../../css/styles.css";

class BsaTransactionView extends Component {
  constructor() {
    super();
    this.state = {
      activeAddress: null,
      visited: [],
    };
  }

  getBsaBadge(count) {
    if (count === 0) {
      return (
        <div>
          <p style={{ display: "inline", paddingRight: "4px" }}>BSA Ids</p>
          <span className="badge progress-bar-danger">{count}</span>
        </div>
      );
    }
    return (
      <div>
        <p style={{ display: "inline", paddingRight: "4px" }}>BSA Ids</p>
        <span className="badge progress-bar-info">{count}</span>
      </div>
    );
  }

  activateRow(address) {
    // The visited part of the local state keeps track of which addresses have had their a
    // initial BSA values retrieved. This is here so that if a user clicks on Address 1,
    // (fetchNextBsa( Address 1 ) is called) then Address 2 (fetchNextBsa( Address 2 ) is called),
    // and then returns to Address 1, fetchNextBsa will not be called again for Address 1.
    if (this.state.visited.includes(address)) {
      this.setState({
        activeAddress: address,
      });
    } else {
      this.setState(
        prevState => ({
          activeAddress: address,
          visited: [...prevState.visited, address],
        }),
        () => {
          this.props.fetchAddressSummary(address);
          this.props.fetchAddressTransactions(address, 1, null, null);
          this.props.fetchAddressTags(address);
          this.props.fetchBsa(address);
        }
      );
    }
  }

  loadBsa() {
    const { mentions } = this.props;
    if (mentions !== undefined) {
      return (
        <div>
          <div className="verticalDivider" />
          <table className="table table-hover">
            <thead>
              <tr>
                <th> Address </th>
                <th> BSA Mentions Count</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {mentions.map(mention => {
                if (mention.address === this.state.activeAddress) {
                  return (
                    <tr
                      key={mention.address}
                      onClick={() => this.setState({ activeAddress: null })}
                      className="activeRow"
                      style={{ fontFamily: "Monospace", fontSize: "11px" }}
                    >
                      <td className="hoverable">
                        {" "}
                        {this.props.getAddressLink(
                          mention.address,
                          mention.address,
                          this.props.handleOpenAddressModal,
                          this.props.currency
                        )}{" "}
                      </td>
                      <td className="hoverable">{this.getBsaBadge(mention.bsaMentionsCount)}</td>
                      <td className="hoverable">
                        <i className="arrow right" />
                      </td>
                    </tr>
                  );
                }
                if (mention.bsaMentionsCount === 0) {
                  return (
                    <tr key={mention.address} style={{ fontFamily: "Monospace", fontSize: "11px" }}>
                      <td className="notHoverable">
                        {this.props.getAddressLink(
                          mention.address,
                          mention.address,
                          this.props.handleOpenAddressModal,
                          this.props.currency
                        )}
                      </td>
                      <td className="notHoverable">{this.getBsaBadge(mention.bsaMentionsCount)}</td>
                      <td className="notHoverable">
                        <i />
                      </td>
                    </tr>
                  );
                }
                return (
                  <tr
                    key={mention.address}
                    onClick={() => this.activateRow(mention.address)}
                    style={{
                      fontFamily: "Monospace",
                      fontSize: "11px",
                    }}
                  >
                    <td className="hoverable">
                      {this.props.getAddressLink(
                        mention.address,
                        mention.address,
                        this.props.handleOpenAddressModal,
                        this.props.currency
                      )}
                    </td>
                    <td className="hoverable">{this.getBsaBadge(mention.bsaMentionsCount)}</td>
                    <td className="hoverable" />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div>
        <div className="verticalDivider" />
        <div className="row">
          <div className="col-lg-7">{this.loadBsa()}</div>
          <div className="col-lg-5 col-centered">
            <BsaMentionsTransactionView
              address={this.state.activeAddress}
              currency={this.props.currency}
            />
          </div>
        </div>
      </div>
    );
  }
}

BsaTransactionView.propTypes = {
  fetchAddressSummary: PropTypes.func.isRequired,
  fetchAddressTransactions: PropTypes.func.isRequired,
  fetchAddressTags: PropTypes.func.isRequired,
  fetchBsa: PropTypes.func.isRequired,
  getAddressLink: PropTypes.func.isRequired,
  mentions: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      bsaMentionsCount: PropTypes.number.isRequired,
      mentionsCount: PropTypes.number.isRequired,
      primaryTag: PropTypes.string,
    })
  ).isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    mentions: getTransactionMentions(state, props.transaction),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchAddressSummary: address => dispatch(fetchAddressSummary(address)),
  fetchAddressTransactions: (address, order, startDate, endDate) => {
    dispatch(fetchAddressTransactions(address, order, startDate, endDate, true));
  },
  fetchAddressTags: address => dispatch(fetchAddressMentions(address, true)),
  fetchBsa: (address, initial) => {
    dispatch(fetchBsa(address, initial));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BsaTransactionView);
