import ReactSpeedometer, { Props, Transition } from "react-d3-speedometer";
import React from "react";

export const SEGMENT_COLORS = [
  "#35e506",
  "#35FF00",
  "#6AFF00",
  "#9FFF00",
  "#D4FF00",
  "#FFF600",
  "#FFC100",
  "#FF8C00",
  "#FF5700",
  "#FF2300",
  "#FF0000"
];

export const MAX_VALUE = 300;

interface RiskSpeedometerProps extends Partial<Props> {
  level: number;
  width?: number;
  height?: number;
}

export const RiskSpeedometer: React.FC<RiskSpeedometerProps> = ({
  level,
  width = 500,
  height = 300,
  ...props
}) => {
  return (
    <ReactSpeedometer
      width={width}
      height={height}
      minValue={0}
      maxValue={MAX_VALUE}
      segments={10}
      ringWidth={100}
      segmentColors={SEGMENT_COLORS}
      currentValueText="${value}"
      value={level}
      needleTransitionDuration={800}
      needleTransition={Transition.easeCircleInOut}
      paddingVertical={15}
      needleHeightRatio={0.8}
      valueTextFontSize={"30px"}
      {...props}
    />
  );
};
