/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import HelpDialogueChooser from "./HelpDialogueChooser";
import { getClientMode } from "../selectors/applicationConfig";
import ClientModeConfig from "./ClientModeConfig";

export class BottomHelpPopup extends Component {
  state = {
    showModal: false,
    expanded: [0],
  };

  render() {
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null,
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    // Split the path by the slashes
    const pathArray = this.props.location.pathname.split("/");

    // If the path has more than 2 elements, the coin is specified so we grab the 3rd element (first always blank)
    // If it's not specified, we just grab the 2nd. Eventually we should normalize this w coin always specified
    const path = pathArray.length > 2 ? pathArray[2] : pathArray[1];
    const helpElements = HelpDialogueChooser(clientModeConfigObject)[path];

    // If the route has no helpElements, it will be undefined. If it's not, we create the video elements.
    const videos =
      helpElements === undefined
        ? []
        : helpElements.elements.map((ele, index) => {
            return this.state.expanded.includes(index) ? (
              <div style={{ marginBottom: "40px" }}>
                <div
                  className="expandableDiv"
                  onClick={() =>
                    this.setState({ expanded: this.state.expanded.filter(e => e !== index) })
                  }
                >
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    style={{ display: "inline", fontSize: "20px" }}
                  >
                    {" "}
                  </FontAwesomeIcon>
                  <p className="videoHeader" style={{ display: "inline" }}>
                    {" "}
                    {ele.title}{" "}
                  </p>
                  <p className="videoDescription"> {ele.description} </p>
                </div>
                <ReactPlayer
                  controls
                  url={ele.video}
                  style={{ border: "2px solid var(--secondary-color)" }}
                />
              </div>
            ) : (
              <div
                style={{ marginBottom: "40px" }}
                className="expandableDiv"
                onClick={() => this.setState({ expanded: this.state.expanded.concat(index) })}
              >
                <FontAwesomeIcon
                  icon={faCaretRight}
                  style={{ display: "inline", fontSize: "20px" }}
                >
                  {" "}
                </FontAwesomeIcon>
                <p className="videoHeader" style={{ display: "inline" }}>
                  {" "}
                  {ele.title}{" "}
                </p>
              </div>
            );
          });
    return (
      <>
        {helpElements !== undefined && (
          <>
            <div className="bottomHelp" onClick={() => this.setState({ showModal: true })}>
              <p
                style={{
                  fontSize: "26px",
                  fontFamily: "Quicksand",
                  fontWeight: "600",
                  color: "var(--secondary-color-dark-hover)",
                  margin: "0",
                }}
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  style={{ fontSize: "20px", marginBottom: "1px" }}
                />{" "}
                Help{" "}
              </p>
            </div>
            <Modal
              className="videoModal"
              show={this.state.showModal}
              onHide={() => this.setState({ showModal: false, expanded: [0] })}
            >
              <Modal.Header closeButton>{helpElements.title} Training Videos</Modal.Header>
              <Modal.Body>{videos}</Modal.Body>
            </Modal>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    clientMode: getClientMode(state),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(BottomHelpPopup)
);
