/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Table } from "react-bootstrap";
import { connect } from "react-redux";

import { CircularProgress } from "@material-ui/core";
import SentWalletsRow from "./SentWalletsRow";
import { ScrollDiv } from "../../styled";
import { cytoscapeNodeShape, cytoscapeShape } from "../../../prop-types";
import { fetchSentWallets, toggleSentFilter } from "../../../actions/wallet";
import { makeGetSentWallets } from "../../../selectors/wallet";
import DummyWalletsRow from "../DummyWalletsRow";
import { getClientMode } from "../../../selectors/applicationConfig";
import ClientModeConfig from "../../ClientModeConfig";
import CurrencyChooserConfig from "../../CurrencyChooserConfig";
import { getCurrency } from "../../../selectors/currency";

class SentWallets extends Component {
  handleToggleFilter = () => {
    this.props.toggleFilter(this.props.wallet.id());
  };

  render() {
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null,
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    let currencyObject = CurrencyChooserConfig[this.props.currency];

    const {
      wallet,
      maxHeight,
      associatedWallets,
      nextPrimaryKey,
      filtered,
      cy,
      unclusteredAmounts,
    } = this.props;

    const buttonText = nextPrimaryKey ? "Load More ..." : "No more   ";
    const walletRows = associatedWallets.map(wallet_ => {
      const { walletId: walletId_, satoshi, tag, category } = wallet_;

      return (
        <SentWalletsRow
          key={walletId_}
          walletId={walletId_}
          satoshi={satoshi}
          sourceWallet={wallet}
          tag={tag}
          cy={cy}
          category={category}
        />
      );
    });

    const unclustered_amounts = unclusteredAmounts.map(amount => {
      return <DummyWalletsRow satoshi={amount} sent={-1} />;
    });

    return (
      <>
        {nextPrimaryKey !== 0 || unclustered_amounts.length !== 0 ? (
          <div style={{ margin: 0, textAlign: "center" }}>
            {unclusteredAmounts.length > 0 && (
              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  border: "2px solid green",
                  width: "80%",
                  padding: "5px",
                  borderRadius: "5px",
                  marginBottom: "10px",
                  textAlight: "center",
                  backgroundColor: "white",
                }}
              >
                <p
                  style={{
                    color: "green",
                    fontFamily: "Quicksand",
                    fontWeight: "700",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  Some {clientModeConfigObject.cap_plural_for_collection} in this Summary are
                  Currently Pending Analyst Review
                </p>
              </div>
            )}
            <div style={{ marginBottom: "5px", textAlign: "left", paddingBottom: "0" }}>
              <input
                type="checkbox"
                id="scales"
                name="scales"
                checked={this.props.filtered}
                onChange={() => this.handleToggleFilter()}
              />
              <label
                style={{
                  paddingLeft: "10px",
                  paddingBottom: "5px",
                  fontFamily: "Quicksand",
                  color: "white",
                }}
                htmlFor="scales"
              >
                Filter Unknown {clientModeConfigObject.cap_plural_for_collection}
              </label>
            </div>
            <ScrollDiv
              maxHeight={maxHeight}
              style={{ margin: "0", borderRadius: "0", paddingBottom: "0" }}
            >
              <Table className="walletStatsTable purple">
                <thead>
                  <tr>
                    <th>Entity</th>
                    <th className="text-left">{currencyObject.abb}</th>
                  </tr>
                </thead>
                <tbody>
                  {walletRows}
                  {!nextPrimaryKey && unclustered_amounts}
                </tbody>
              </Table>
            </ScrollDiv>
            <Button
              style={{ marginTop: "10px" }}
              className="purpleButton"
              onClick={() => this.props.fetch(wallet.id())}
              disabled={!nextPrimaryKey}
            >
              {buttonText}
            </Button>
          </div>
        ) : (
          <div style={{ margin: 0, textAlign: "center" }}>
            <CircularProgress size={80} style={{ color: "white" }} />
          </div>
        )}
      </>
    );
  }
}

SentWallets.propTypes = {
  wallet: cytoscapeNodeShape.isRequired,
  maxHeight: PropTypes.number.isRequired,
  fetch: PropTypes.func.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  filtered: PropTypes.bool.isRequired,
  nextPrimaryKey: PropTypes.number,
  associatedWallets: PropTypes.arrayOf(
    PropTypes.shape({
      walletId: PropTypes.string.isRequired,
      satoshi: PropTypes.number.isRequired,
      tag: PropTypes.string,
      category: PropTypes.string,
    })
  ).isRequired,
  cy: cytoscapeShape.isRequired,
  unclusteredAmounts: PropTypes.array.isRequired,
};

SentWallets.defaultProps = {
  nextKey: null,
};

const mapStateToProps = () => {
  const getSentWallets = makeGetSentWallets();
  return (state, { wallet }) => {
    return {
      ...getSentWallets(state, wallet.id()),
      clientMode: getClientMode(state),
      currency: getCurrency(state),
    };
  };
};

const mapDispatchToProps = dispatch => ({
  fetch: wallet => dispatch(fetchSentWallets(wallet, false)),
  toggleFilter: wallet => dispatch(toggleSentFilter(wallet)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SentWallets);
