import React from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import { FakeLinkSpan } from "../../styled";
import CopyText from "../../CopyText";
import WrappedLink from "../../Utils/WrappedLink";

const AddressStats = ({
  input_count,
  output_count,
  internal_input_count,
  internal_output_count,
  first_transaction_id,
  stateBased = false,
}) => {
  return (
    <div style={{ width: "100%", marginLeft: "0", paddingBottom: "0" }}>
      <Table
        className="walletStatsTable purple"
        style={{
          width: "100%",
          tableLayout: "fixed",
          overflow: "auto",
          wordWrap: "break-word",
        }}
      >
        <thead>
          <th width={"30%"} />
          <th width={"70%"} />
        </thead>
        <tbody>
          <tr key="addressCount">
            <td className="header">Input Count</td>
            <td className="text-left">{input_count}</td>
          </tr>
          <tr key="transactionCount">
            <td className="header">Output Count</td>
            <td className="text-left">{output_count}</td>
          </tr>
          <tr key="outputCount">
            <td className="header">Internal Input Count</td>
            <td className="text-left">{internal_input_count}</td>
          </tr>
          <tr key="outputSum">
            <td className="header">Internal Output Count</td>
            <td className="text-left">{internal_output_count}</td>
          </tr>
          {/*TEMP COMMENT out while building tables*/}
          {/*<tr key="anchorAddress">*/}
          {/*  <td className="header" style={{ paddingBottom: "10px" }}>*/}
          {/*    First Transaction*/}
          {/*  </td>*/}
          {/*  <td*/}
          {/*    className="text-left content"*/}
          {/*    style={{*/}
          {/*      textOverflow: "ellipsis",*/}
          {/*      overflow: "hidden",*/}
          {/*      whiteSpace: "nowrap",*/}
          {/*      paddingBottom: "0"*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {!first_transaction_id ? (*/}
          {/*      "N/A"*/}
          {/*    ) : (*/}
          {/*      <>*/}
          {/*        <CopyText*/}
          {/*          text={first_transaction_id}*/}
          {/*          marginRight="10px"*/}
          {/*          marginLeft="0"*/}
          {/*        />*/}
          {/*        <WrappedLink*/}
          {/*          entity={first_transaction_id}*/}
          {/*          entityType={"transaction"}*/}
          {/*          stateBased={stateBased || false}*/}
          {/*          style={{ fontWeight: "500" }}*/}
          {/*        >*/}
          {/*          {first_transaction_id}*/}
          {/*        </WrappedLink>*/}
          {/*      </>*/}
          {/*    )}*/}
          {/*  </td>*/}
          {/*</tr>*/}
        </tbody>
      </Table>
    </div>
  );
};

export default AddressStats;
