/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { WalletWithStateBasedTabs } from "../Wallet";
import {
  getModalAddressLink,
  getModalTransactionLink,
  getModalWalletLink,
  getShortTransactionDateCell
} from "../Wallet/helpers";
import {
  openAddressModal,
  openTransactionModal,
  openWalletModal
} from "../../actions/graph";
import { CustomWalletWithStateBasedTabs } from "../Wallet/CustomWallet";

const WalletModal = ({
  walletId,
  handleOpenAddressModal,
  handleOpenTransactionModal,
  handleOpenWalletModal,
  addToGraph,
  graphId
}) => (
  <>
    {walletId < 0 ? (
      <CustomWalletWithStateBasedTabs
        walletId={walletId}
        graphId={graphId}
        getTransactionLink={transaction =>
          getModalTransactionLink(transaction, handleOpenTransactionModal)
        }
        getWalletLink={wallet =>
          getModalWalletLink(wallet, handleOpenWalletModal)
        }
        getAddressLink={address =>
          getModalAddressLink(address, handleOpenAddressModal)
        }
        getDateCell={getShortTransactionDateCell}
        getCreateGraphModal={() => addToGraph}
      />
    ) : (
      <WalletWithStateBasedTabs
        walletId={walletId}
        getTransactionLink={transaction =>
          getModalTransactionLink(transaction, handleOpenTransactionModal)
        }
        getWalletLink={wallet =>
          getModalWalletLink(wallet, handleOpenWalletModal)
        }
        getAddressLink={address =>
          getModalAddressLink(address, handleOpenAddressModal)
        }
        getDateCell={getShortTransactionDateCell}
        getCreateGraphModal={() => addToGraph}
      />
    )}
  </>
);

WalletModal.propTypes = {
  walletId: PropTypes.string.isRequired,
  handleOpenAddressModal: PropTypes.func.isRequired,
  handleOpenTransactionModal: PropTypes.func.isRequired,
  handleOpenWalletModal: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  handleOpenAddressModal: address => dispatch(openAddressModal(address)),
  handleOpenWalletModal: wallet => dispatch(openWalletModal(wallet)),
  handleOpenTransactionModal: transaction =>
    dispatch(openTransactionModal(transaction))
});

export default connect(
  null,
  mapDispatchToProps
)(WalletModal);
