// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import axios, { TRANSACTION_API, ADDRESS_API } from "../api";
import { getTransaction } from "../selectors/transaction";
import {
  FETCHING_TRANSACTION,
  FETCHING_TRANSACTION_SEARCHER,
  TRANSACTION_FETCH_FAILURE,
  TRANSACTION_FETCH_SUCCESS,
  TRANSACTION_SEARCHER_FETCH_FAILURE,
  TRANSACTION_SEARCHER_FETCH_SUCCESS,
  TRANSACTION_ADDRESS_NOTE_ADD_SUCCESS,
  TRANSACTION_ADDRESS_NOTE_ADD_FAILURE,
  WALLET_SET_NAMES,
  FETCH_TRANSACTION_CHAIN_SUCCESS,
  FETCH_TRANSACTION_CHAIN_FAILURE
} from "./actionNames";
import { getCurrency } from "../selectors/currency";
import { getClientMode } from "../selectors/applicationConfig";

/* eslint-disable-next-line import/prefer-default-export */
export const fetchTransaction = transaction => async (dispatch, getState) => {
  // First check if the transaction already exists in redux
  const transactionState = getTransaction(getState(), transaction);
  if (transactionState) {
    return transactionState;
  }

  // Get the current currency
  const name = getCurrency(getState());
  dispatch({ type: FETCHING_TRANSACTION, transaction, name });

  const clientMode = getClientMode(getState());
  // const risk = clientMode === "vci" ? 0 : 1;
  const risk = 0; //TODO TEMP code to disable RISK, CHANGE THIS LATER
  try {
    // Fetch the transaction data and dispatch the success action to put it in redux
    const { data } = await axios.get(`${TRANSACTION_API(name)}/${transaction}/${risk}`);
    dispatch({
      type: TRANSACTION_FETCH_SUCCESS,
      transactionHash: transaction,
      data,
      name
    });
    const { inputs, outputs } = data;
    const walletIds = {};
    inputs.forEach(({ walletId, name }) => !walletIds[walletId] && (walletIds[walletId] = name));
    outputs.forEach(({ walletId, name }) => !walletIds[walletId] && (walletIds[walletId] = name));
    dispatch({
      type: WALLET_SET_NAMES,
      walletIds,
      name
    });
    // Return the data to avoid using a selector to grab the data elsewhere
    return getTransaction(getState(), transaction).toJS();
  } catch (err) {
    dispatch({ type: TRANSACTION_FETCH_FAILURE, name });
    throw err;
  }
};

export const fetchTransactionChains = transaction => async (dispatch, getState) => {
  try {
    const name = getCurrency(getState());
    const { data } = await axios.get(`${TRANSACTION_API(name)}/${transaction}/majority-funds`, {
      params: { timeLimit: 20, percentLimit: 0.05, usdLimit: 5 }
    });
    dispatch({
      type: FETCH_TRANSACTION_CHAIN_SUCCESS,
      transactionHash: transaction,
      data,
      name
    });
  } catch (err) {
    dispatch({ type: FETCH_TRANSACTION_CHAIN_FAILURE });
    throw err;
  }
};

export const fetchAddressTransactionNote = (address, transaction) => async (dispatch, getState) => {
  try {
    const name = getCurrency(getState());
    const { data } = await axios.get(`${ADDRESS_API(name)}/${address}/exists`);
    dispatch({
      type: TRANSACTION_ADDRESS_NOTE_ADD_SUCCESS,
      address,
      transactionHash: transaction,
      name
    });
  } catch (err) {
    dispatch({ type: TRANSACTION_ADDRESS_NOTE_ADD_FAILURE });
    throw err;
  }
};
