import { WalletSummary } from "../../reducers/ethereum/wallet";

export const getWallet = (state, walletId) => {
  return state.getIn(["ethereum", "wallet", walletId]);
};

export const getWalletSummary = (state, walletId, noNull = false) => {
  const walletMap = getWallet(state, walletId);
  if (walletMap) {
    return walletMap.get("summary").toJS();
  }
  if (noNull) {
    return new WalletSummary().toJS();
  }
  return null;
};

export const getWalletAddresses = (state, walletId, noNull = false) => {
  const walletMap = getWallet(state, walletId);
  if (walletMap) {
    return walletMap.get("addresses").toJS();
  }
  if (noNull) {
    return [];
  }
  return null;
};
