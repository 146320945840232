/*
 * Copyright 2018-2022  DecisionQ Information Operations, Inc. All Rights Reserved.
 */

import { FakeLinkSpan } from "../styled";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrency } from "../../selectors/currency";
import { Link, useLocation } from "react-router-dom";
import { openExplorerModal } from "../../actions/graph";
import WalletTransactions from "../Wallet/WalletTransactionsBase";
import PropTypes from "prop-types";

const WrappedLink = ({ pathname = null, entity, entityType, stateBased = false, ...props }) => {
  const dispatch = useDispatch();
  const currency = useSelector(state => getCurrency(state));
  let location = useLocation();
  // https://codesandbox.io/s/react-router-modal-gallery-forked-rc97c?file=/example.js
  // TODO figure out how to do this with react router location links https://stackblitz.com/github/remix-run/react-router/tree/main/examples/modal?file=src%2FApp.tsx
  return stateBased ? (
    <FakeLinkSpan onClick={() => dispatch(openExplorerModal({ entityType, entity }))} {...props}>
      {props.children}
    </FakeLinkSpan>
  ) : (
    <Link
      to={pathname ? { pathname: pathname } : { pathname: `/${currency}/${entityType}/${entity}` }}
      // state={stateBased && { backgroundLocation: location }}
      {...props}
    >
      {props.children}
    </Link>
  );
};

WalletTransactions.propTypes = {
  stateBased: PropTypes.bool.isRequired,
  pathname: PropTypes.string,
  entity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entityType: PropTypes.string,
};
export default WrappedLink;
