import * as React from "react";
import CopyText from "../../CopyText";
import WrappedLink from "../../Utils/WrappedLink";
import { Tab, Tabs } from "react-bootstrap";
import { changeDataTabsKey } from "../../../actions/graph";
import { useDispatch, useSelector } from "react-redux";
import { getWalletViewData } from "../../../selectors/graph";
import {
  getAddressMutualTokens,
  getAddressMutualTransactions
} from "../../../selectors/ethereum/address";
import {
  fetchMutualAddressTokens,
  fetchMutualAddressTransactions
} from "../../../actions/ethereum/address";
import { TransactionList } from "../TransactionList";

interface EdgeViewProps {
  source: string;
  sourceLabel?: string;
  target: string;
  targetLabel?: string;
  stateBased?: boolean;
}

export const EdgeView = ({
  source,
  target,
  stateBased,
  sourceLabel,
  targetLabel
}: EdgeViewProps) => {
  const getTransactions = (state: any, token_account_id: string) =>
    getAddressMutualTransactions(state, source, target, token_account_id, true);
  const getTokens = (state: any) =>
    getAddressMutualTokens(state, source, target, true);

  return (
    <div className="activeOptionMainDiv">
      <p style={{ fontSize: "24px" }}>Transactions</p>
      <p style={{ fontSize: "15px" }}>
        <CopyText text={sourceLabel || source} color={"white"} />
        <WrappedLink
          entity={source}
          entityType={"address"}
          stateBased={stateBased}
          style={{ color: "white", fontSize: "12px", fontWeight: "500" }}
        >
          <span
            style={{
              color: "white",
              fontSize: "12px",
              fontWeight: "500",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "20ch",
              whiteSpace: "nowrap",
              display: "inline-block",
              marginRight: 10,
              verticalAlign: "bottom"
            }}
          >
            {source}
          </span>
        </WrappedLink>
        {"to"}
        <CopyText text={targetLabel || target} color={"white"} />
        <WrappedLink
          entity={target}
          entityType={"address"}
          stateBased={stateBased}
        >
          <span
            style={{
              color: "white",
              fontSize: "12px",
              fontWeight: "500",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "20ch",
              display: "inline-block",
              verticalAlign: "bottom"
            }}
          >
            {target}
          </span>
        </WrappedLink>
      </p>
      <TransactionList
        graphView={true}
        fetchTransactions={(token_account_id: string) =>
          fetchMutualAddressTransactions(source, target, token_account_id)
        }
        fetchTokens={() => fetchMutualAddressTokens(source, target)}
        getTransactions={getTransactions}
        getTokens={getTokens}
        stateBased={stateBased}
        key={source + target}
        searchKey={source + target}
      />
    </div>
  );
};
