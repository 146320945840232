/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";

import { getModalTransactionLink, getModalWalletLink } from "../Wallet/helpers";
import { AddressWithStateBasedTabs } from "../Address";
import { openTransactionModal, openWalletModal } from "../../actions/graph";

const AddressModal = ({
  address,
  handleOpenTransactionModal,
  handleOpenWalletModal,
  addToGraph,
}) => (
  <AddressWithStateBasedTabs
    address={address}
    getTransactionLink={transaction =>
      getModalTransactionLink(transaction, handleOpenTransactionModal)
    }
    getWalletLink={(wallet, primaryTag) =>
      getModalWalletLink(wallet, primaryTag, handleOpenWalletModal)
    }
    getCreateGraphModal={() => addToGraph}
    handleOpenWalletModal={handleOpenWalletModal}
  />
);

const StyledAddressModal = styled(AddressModal)`
  width: 90%;
`;

const mapDispatchToProps = dispatch => ({
  handleOpenWalletModal: wallet => dispatch(openWalletModal(wallet)),
  handleOpenTransactionModal: transaction => dispatch(openTransactionModal(transaction)),
});

AddressModal.propTypes = {
  address: PropTypes.string.isRequired,
  handleOpenWalletModal: PropTypes.func.isRequired,
  handleOpenTransactionModal: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(StyledAddressModal);
