/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";

import { Table } from "react-bootstrap";
import InputAddressRow from "./InputAddressRow";
import withTableSorter from "../withTableSorter";
import { PointerTableHeader } from "../styled";
import { transactionAddress } from "../../../prop-types";
import "../../../../css/styles.css";
import { numberWithCommas } from "../../../helpers";
import CurrencyChooserConfig from "../../CurrencyChooserConfig";
import { getTransactionInfo } from "../../../selectors/transaction";
import { getFeature } from "../../../selectors/features";
import { getCurrency } from "../../../selectors/currency";
import { getClientMode } from "../../../selectors/applicationConfig";
import { connect, useSelector } from "react-redux";
import ClientModeConfig from "../../ClientModeConfig";

const InputAddressTable = ({
  totalBitcoin,
  outputs,
  handleSortByAddress,
  handleSortBySatoshi,
  handleSortByWallet,
  currentCaret,
  handleOpenAddressModal,
  handleOpenTransactionModal,
  handleOpenWalletModal,
  getAddressLink,
  getWalletLink,
  getTransactionLink,
  currency,
  convertPriceToUsd,
  toggleUsdDisplay,
  showAsUsd,
  onAddressNoteClick,
}) => {
  // map addresses to table rows
  const inputs = outputs.map(input => {
    const {
      key,
      satoshi,
      walletId,
      address,
      addressCount,
      transactionHash,
      primaryTag,
      isChange,
      isNote,
    } = input;
    return (
      <InputAddressRow
        key={key}
        keyProp={key}
        transactionHash={transactionHash}
        address={address}
        walletId={walletId}
        addressCount={addressCount}
        satoshi={satoshi}
        primaryTag={primaryTag}
        handleOpenAddressModal={handleOpenAddressModal}
        handleOpenTransactionModal={handleOpenTransactionModal}
        handleOpenWalletModal={handleOpenWalletModal}
        getAddressLink={getAddressLink}
        getWalletLink={getWalletLink}
        getTransactionLink={getTransactionLink}
        isChange={isChange}
        currency={currency}
        convertPriceToUsd={convertPriceToUsd}
        toggleUsdDisplay={toggleUsdDisplay}
        showAsUsd={showAsUsd}
        isNote={isNote}
        onAddressNoteClick={onAddressNoteClick}
      />
    );
  });

  let count = outputs.length;
  if (outputs.length > 0 && outputs[0].address[0] === "Coinbase") {
    count = 0;
  }
  let currencyObject = CurrencyChooserConfig[currency];

  const clientMode = useSelector(state => getClientMode(state));
  let clientModeConfigObject = {
    word_for_collection: "",
    cap_word_for_collection: "",
    plural_for_collection: "",
    cap_plural_for_collection: "",
    nav_logo: null,
    login_logo: null,
    login_top_logo: null,
    login_top_icon: null,
  };
  if (clientMode in ClientModeConfig) {
    clientModeConfigObject = ClientModeConfig[clientMode];
  }

  return (
    <div>
      <p style={{ fontFamily: "Quicksand", fontSize: "15px" }}>
        <b style={{ color: "#666" }}>Input</b> ({count}){" "}
        {showAsUsd ? "$" + numberWithCommas(convertPriceToUsd(totalBitcoin)) : totalBitcoin}
      </p>
      <Table
        className="inviteTable"
        style={{
          width: "100%",
          tableLayout: "fixed",
          overflow: "auto",
          wordWrap: "break-word",
        }}
      >
        <thead>
          <tr>
            <th style={{ width: "3%" }} />
            <PointerTableHeader style={{ width: "56%" }} onClick={handleSortByAddress}>
              Address
              <div className="divider" />
              {currentCaret.address}
            </PointerTableHeader>
            <PointerTableHeader
              style={{ width: "24%", textAlign: "right" }}
              onClick={handleSortByWallet}
            >
              {clientModeConfigObject.cap_word_for_collection}
              <div className="divider" />
              {currentCaret.wallet}
            </PointerTableHeader>
            <PointerTableHeader
              style={{ width: "17%" }}
              onClick={handleSortBySatoshi}
              className="text-right"
            >
              {showAsUsd ? "$" : currencyObject["abb"]}
              <div className="divider" />
              {currentCaret.satoshi}
            </PointerTableHeader>
          </tr>
        </thead>
        <tbody>{inputs}</tbody>
      </Table>
    </div>
  );
};

InputAddressTable.propTypes = {
  totalBitcoin: PropTypes.string.isRequired,
  handleSortByAddress: PropTypes.func.isRequired,
  handleSortBySatoshi: PropTypes.func.isRequired,
  handleSortByWallet: PropTypes.func.isRequired,
  outputs: PropTypes.arrayOf(transactionAddress).isRequired,
  handleOpenAddressModal: PropTypes.func,
  handleOpenTransactionModal: PropTypes.func,
  handleOpenWalletModal: PropTypes.func,
  getAddressLink: PropTypes.func.isRequired,
  getWalletLink: PropTypes.func.isRequired,
  getTransactionLink: PropTypes.func.isRequired,
  // PropTypes sucks and will incorrectly complain about this.
  // /* eslint-disable */
  currentCaret: PropTypes.shape({
    address: PropTypes.object.isRequired,
    wallet: PropTypes.object.isRequired,
    satoshi: PropTypes.object.isRequired,
  }).isRequired,
};

InputAddressTable.defaultProps = {
  handleOpenAddressModal: null,
  handleOpenTransactionModal: null,
  handleOpenWalletModal: null,
};

export default withTableSorter(InputAddressTable, "address");
