/*
 * Copyright 2018-2022  DecisionQ Information Operations, Inc. All Rights Reserved.
 */

import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { getButtonHistory, getModalShow } from "../../selectors/graph";
import styled from "styled-components";
import { closeExplorerModal, goBack, goForward } from "../../actions/graph";
import { useLocation } from "react-router-dom";

// Helper functions to disable back and next buttons
export const firstIndex = index => {
  return index === 0;
};

export const lastIndex = (index, historyLength) => {
  return index === historyLength - 1;
};

// get selectors to pass props
const ModalWrapper = ({ handleCloseModal = () => {}, className, show, ...props }) => {
  const history = useSelector(state => getButtonHistory(state));
  const location = useLocation();
  const { currentSet } = useSelector(state => getModalShow(state));
  const dispatch = useDispatch();
  const { historyLength, index } = history;

  const onClose = () => {
    handleCloseModal();
    dispatch(closeExplorerModal());
  };
  return (
    <Modal onHide={onClose} dialogClassName={className} show={currentSet === "explorer"}>
      <Modal.Header closeButton>
        <div
          className="row"
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div className="col" style={{ width: "100%", paddingTop: "5px" }}>
            <Button
              className="blueButton"
              style={{ textAlign: "center", margin: "5px" }}
              onClick={() => dispatch(goBack())}
              disabled={firstIndex(index)}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <Button
              className="blueButton"
              style={{ textAlign: "center", margin: "5px" }}
              onClick={() => dispatch(goForward())}
              disabled={lastIndex(index, historyLength)}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body style={{ padding: "0" }}>{props.children}</Modal.Body>
    </Modal>
  );
};

ModalWrapper.propTypes = {
  handleCloseModal: PropTypes.func,
  className: PropTypes.string,
  show: PropTypes.bool,
};

const StyledModalWrapper = styled(ModalWrapper)`
  width: 90%;
`;

export default StyledModalWrapper;
