// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

// Hex values taken directly from spreadsheet.
const CategoryColorsConfig = (
  clientModeObject = {
    word_for_collection: "",
    cap_word_for_collection: "",
    plural_for_collection: "",
    cap_plural_for_collection: "",
    nav_logo: null,
    login_logo: null,
    login_top_logo: null,
    login_top_icon: null,
  }
) => [
  {
    category: "Untagged - Positive Balance",
    label: "Untagged - Positive Balance",
    color: "Grey",
    hex: "#c7c5c5",
    description: `Untagged ${clientModeObject.plural_for_collection} with a positive balance.`,
  },
  {
    category: "Untagged - Zero Balance",
    label: "Untagged - Zero Balance",
    color: "White",
    hex: "#FFFFFF",
    description: `Untagged ${clientModeObject.plural_for_collection} with a zero balance.`,
  },
  {
    category: `Cold ${clientModeObject.cap_word_for_collection}`,
    label: `Cold ${clientModeObject.cap_word_for_collection}`,
    color: "White",
    hex: "#FFFFFF",
    description: `${clientModeObject.cap_plural_for_collection} with zero outgoing transactions.`,
  },
  {
    category: "Exchanges",
    label: "Exchanges",
    color: "Blue",
    hex: "#008cff",
    description: "Site to buy, sell, send, and receive cryptocurrencies.",
  },
  {
    category: "Marketplace",
    label: "Marketplace",
    color: "Red",
    hex: "#ff0000",
    description:
      "Site to purchase illicit goods or services using bitcoin or other cryptocurrencies.",
  },
  {
    category: "Mixer",
    label: "Mixer",
    color: "Orange",
    hex: "#ff7b00",
    description:
      "Site allowing users to mix their cryptocurrency with other users, making the " +
      "cryptocurrency harder to trace.",
  },
  {
    category: "Gambling",
    label: "Gambling",
    color: "Green",
    hex: "#00ff00",
    description: "Site allowing users to gamble using cryptocurrency.",
  },
  {
    category: "Ransomware",
    label: "Ransomware",
    color: "Black",
    hex: "#000000",
    description: `${clientModeObject.cap_word_for_collection} associated with ransomware attacks.`,
  },
  {
    category: "P2P",
    label: "P2P",
    color: "Purple",
    hex: "#684ce6",
    description:
      "Site where users can find other users to buy, sell, send, and receive cryptocurrencies.",
  },
  {
    category: "Pools",
    label: "Mining Pools",
    color: "Yellow",
    hex: "#d9d925",
    description:
      'Mining Pools, which as part of the cryptocurrency protocol, produce "new" units of a cryptocurrency.',
  },
  {
    category: "Faucet",
    label: "Satoshi Rewards",
    color: "Cyan",
    hex: "#00ffff",
    description: "Sites which reward visitors with small amounts of cryptocurrency.",
  },
  {
    category: "Postage",
    label: "Postage",
    color: "Maroon",
    hex: "#800000",
    description: "Sites selling postage for cryptocurrency.",
  },
  {
    category: "Payment Service",
    label: "Payment Service",
    color: "Magenta",
    hex: "#FF00FF",
    description: `${clientModeObject.cap_plural_for_collection} that accept cryptocurrency in order to pay merchants in fiat currency.`,
  },
  {
    category: "Deep Web Hosting",
    label: "Deep Web Hosting",
    color: "Dark Green",
    hex: "#228b22",
    description: `${clientModeObject.plural_for_collection} that provide hosting services for websites on the deep web.`,
  },
];

export default CategoryColorsConfig;
