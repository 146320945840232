/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";

import { ScrollDiv } from "../styled";
import { fetchAddressMentions } from "../../actions/address";
import { makeGetAddressMentions } from "../../selectors/address";
import "../../../css/styles.css";
import Mention from "../Wallet/Mention";
import { getAddressPostsConverted } from "../../selectors/mention";
import { fetchMentionAddressPosts } from "../../actions/mention";
import { CircularProgress } from "@material-ui/core";

/**
 * List of panels that display tags associated with an address.
 */
class AddressMentions extends Component {
  fetchAddressMentions = async () => {
    const { address } = this.props;
    this.props.fetch(address);
  };

  render() {
    const { address, mentions, nextKey, maxHeight } = this.props;

    let buttonText;
    if (mentions.length === 0) {
      buttonText = "None     ";
    } else if (nextKey) {
      buttonText = "Load More ...";
    } else {
      buttonText = "No more  ";
    }

    const mentionRows = mentions.map(mention_ => {
      const { username, onlineAccountId, sourceName, count } = mention_;

      return (
        <Mention
          key={onlineAccountId}
          username={username}
          sourceName={sourceName}
          address={address}
          onlineAccountId={onlineAccountId}
          count={count}
          getPostConverted={getAddressPostsConverted}
          fetchMentionPosts={fetchMentionAddressPosts}
        />
      );
    });

    const col1 = [];
    const col2 = [];
    this.props.mentions.forEach((mention, index) => {
      const { username, onlineAccountId, sourceName, count } = mention;
      if (index % 2 === 0) {
        col1.push(
          <Mention
            key={onlineAccountId}
            onlineAccountId={onlineAccountId}
            username={username}
            sourceName={sourceName}
            id={address}
            count={count}
            getPostConverted={getAddressPostsConverted}
            fetchMentionPosts={fetchMentionAddressPosts}
          />
        );
      } else {
        col2.push(
          <Mention
            key={onlineAccountId}
            onlineAccountId={onlineAccountId}
            username={username}
            sourceName={sourceName}
            id={address}
            count={count}
            getPostConverted={getAddressPostsConverted}
            fetchMentionPosts={fetchMentionAddressPosts}
          />
        );
      }
    });

    return (
      <>
        {nextKey === 0 ? (
          <div style={{ margin: 0, textAlign: "center" }}>
            <CircularProgress size={80} style={{ color: "white" }} />
          </div>
        ) : (
          <div
            className="container"
            style={{
              width: "100%",
              padding: "0",
              marginLeft: "0",
              textAlign: "center",
            }}
          >
            <ScrollDiv
              maxHeight={maxHeight}
              style={{ margin: "0", marginBottom: "15px", overflowY: "scroll" }}
            >
              <div className="row" style={{ padding: "0", margin: "0" }}>
                <div
                  className="col-sm-6 col-md-6 col-lg-6"
                  style={{ paddingLeft: "60px", paddingRight: "30px" }}
                >
                  {col1}
                </div>
                <div
                  className="col-sm-6 col-md-6 col-lg-6"
                  style={{ paddingRight: "60px", paddingLeft: "30px" }}
                >
                  {col2}
                </div>
                {/*{mentionRows}*/}
              </div>
            </ScrollDiv>
            <Button
              className="purpleButton"
              onClick={this.fetchAddressMentions}
              disabled={!nextKey}
            >
              {buttonText}
            </Button>
          </div>
        )}
      </>
    );
  }
}

AddressMentions.propTypes = {
  address: PropTypes.string,
  mentions: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      onlineAccountId: PropTypes.number.isRequired,
      sourceName: PropTypes.string.isRequired,
      hasContent: PropTypes.bool.isRequired,
    })
  ).isRequired,
  fetch: PropTypes.func.isRequired,
  nextKey: PropTypes.number,
  // sets the max height of the tags list
  maxHeight: PropTypes.number.isRequired,
};

AddressMentions.defaultProps = {
  address: null,
  nextKey: null,
};

const makeMapStateToProps = () => {
  const getAddressTags = makeGetAddressMentions();
  return (state, { address }) => getAddressTags(state, address);
};

const mapDispatchToProps = dispatch => ({
  fetch: address => dispatch(fetchAddressMentions(address, false)),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(AddressMentions);
