/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Snackbar } from "@material-ui/core";
import { getNotifications } from "../selectors/notification";
import { dismissNotification } from "../actions/notification";
import AlertsQueue from "./Utils/Alerts/AlertsQueue";

class Notification extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={this.props.notifications.length > 0}
      >
        <AlertsQueue alerts={this.props.notifications} dismiss={this.props.onDismiss} limit={3} />
      </Snackbar>
    );
  }
}

Notification.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      style: PropTypes.string.isRequired,
    })
  ).isRequired,
  onDismiss: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notifications: getNotifications(state),
});

const mapDispatchToProps = dispatch => ({
  onDismiss: () => dispatch(dismissNotification()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notification);
