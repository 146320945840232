export const MODAL_MENUS = [
  "WalletTagSetter",
  "WalletNoteSetter",
  "WalletColorPicker"
];

export type ModalMenuTypes =
  | "WalletTagSetter"
  | "WalletNoteSetter"
  | "WalletColorPicker";
