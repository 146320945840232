/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

// The following is borrowed from:
// https://github.com/facebook/draft-js/tree/master/examples/draft-0-10-0/rich
import React, {
  Component,
  createRef,
  forwardRef,
  useImperativeHandle,
  useRef
} from "react";
import PropTypes from "prop-types";
import { convertToRaw, EditorState, RichUtils } from "draft-js";

import Editor, { composeDecorators } from "@draft-js-plugins/editor";
import { TextField, Button, Tooltip, Popover, Icon } from "@material-ui/core";

import createAlignmentPlugin from "@draft-js-plugins/alignment";

import createFocusPlugin from "@draft-js-plugins/focus";

import createResizeablePlugin from "@draft-js-plugins/resizeable";

import createBlockDndPlugin from "@draft-js-plugins/drag-n-drop";
import createDragNDropUploadPlugin from "@draft-js-plugins/drag-n-drop-upload";

import createImagePlugin from "@draft-js-plugins/image";
// import "@draft-js-plugins/focus/lib/plugin.css";
// import "@draft-js-plugins/image/lib/plugin.css";
// import "@draft-js-plugins/alignment/lib/plugin.css";
import { debounce } from "lodash";
import "draft-js/dist/Draft.css";

import BlockStyleControls from "./BlockStyleControls";
import InlineStyleControls from "./InlineStyleControls";
import "../../../css/RichEditor.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import AtomicBlockUtils from "draft-js/lib/AtomicBlockUtils";
// import { Button } from "react-bootstrap";

function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2
  }
};

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;
const decorator = composeDecorators(
  focusPlugin.decorator,
  alignmentPlugin.decorator,
  resizeablePlugin.decorator,
  blockDndPlugin.decorator
);
const imagePlugin = createImagePlugin({
  decorator,
  imageComponent: forwardRef((props, ref) => {
    return <ImageComponent {...props} ref={ref} />;
  })
});

const ImageComponent = forwardRef(
  (
    {
      block,
      className,
      theme = {},
      blockProps,
      customStyleMap,
      customStyleFn,
      decorator,
      forceSelection,
      offsetKey,
      selection,
      tree,
      contentState,
      ...elementProps
    },
    ref
  ) => {
    const innerRef = useRef();
    useImperativeHandle(ref, () => innerRef.current);
    const { src } = contentState.getEntity(block.getEntityAt(0)).getData();
    return (
      <>
        <img ref={innerRef} src={src} {...elementProps} />
      </>
    );
  }
);

// TODO: this is broken. Check back later
const dragNDropFileUploadPlugin = createDragNDropUploadPlugin({
  // handleUpload: mockUpload,
  // addImage: imagePlugin.addImage
});

const plugins = [
  dragNDropFileUploadPlugin,
  blockDndPlugin,
  focusPlugin,
  alignmentPlugin,
  resizeablePlugin,
  imagePlugin
];

export default class NoteEditor extends Component {
  state = {
    imageClickOpen: false,
    anchorEl: null,
    imageURLValue: null,
    thisEditorState: this.props.editorState
  };

  onTab = e => {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  };

  onChange = editorState => {
    this.props.onEdit(editorState);
    this.saveDebouncer();
  };

  saveDebouncer = debounce(() => {
    if (this.props.saveGraph) {
      if (this.props.fetchGraphComplete) {
        this.props.saveGraph();
      }
    }
  }, 3000);

  focus = () => this.editorRef.current.focus();

  editorRef = createRef();

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  };

  toggleBlockType = blockType => {
    this.onChange(RichUtils.toggleBlockType(this.props.editorState, blockType));
  };

  toggleInlineStyle = inlineStyle => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.props.editorState, inlineStyle)
    );
  };

  handleAddImageClick = event => {
    this.setState({ anchorEl: event.currentTarget });
    this.setState({ imageClickOpen: !this.state.imageClickOpen });
  };

  insertImage = (editorState, base64) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "image",
      "IMMUTABLE",
      { src: base64 }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  };

  onImageAdd = e => {
    const newEditor = this.insertImage(
      this.props.editorState,
      this.state.imageURLValue
    );
    this.props.onEdit(newEditor);
  };

  onImageUpload = async e => {
    const base64 = await this.convert(e.target.files[0]);
    const newEditor = this.insertImage(this.props.editorState, base64);
    this.props.onEdit(newEditor);
  };

  convert = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result); //base64encoded string
      };
      reader.onerror = error => {
        reject(error);
      };
    });
  };

  //TODO: if plugin gets fixed

  // mockUpload = (data, success, failed, progress) => {
  //   function doProgress(percent) {
  //     progress(percent || 1);
  //     if (percent === 100) {
  //       // Start reading the file
  //       Promise.all(data.files.map(readFile)).then(files =>
  //         success(files, { retainSrc: true })
  //       );
  //     } else {
  //       setTimeout(doProgress, 250, (percent || 0) + 10);
  //     }
  //   }
  //
  //   doProgress();
  // };

  render() {
    const { height } = this.props;
    const editorState = this.props.editorState || EditorState.createEmpty();

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = "RichEditor-editor";
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== "unstyled"
      ) {
        className += " RichEditor-hidePlaceholder";
      }
    }

    return (
      <div className="RichEditor-root" style={{ margin: "0" }}>
        <BlockStyleControls
          editorState={editorState}
          onToggle={this.toggleBlockType}
        />
        <InlineStyleControls
          editorState={editorState}
          onToggle={this.toggleInlineStyle}
        />

        <Button
          style={{ fontSize: "13px" }}
          onClick={this.handleAddImageClick}
          className="blueButton"
        >
          <span>
            <FontAwesomeIcon icon={faPlus} />
            {"  "}
            Add Image
          </span>
        </Button>
        <Popover
          open={this.state.imageClickOpen}
          onClose={this.handleAddImageClick}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          style={{ zIndex: 20000 }}
        >
          <div style={{ zIndex: 20000 }}>
            <p
              style={{
                fontFamily: "Quicksand",
                marginTop: "10px",
                paddingLeft: "10px",
                color: "dimgray"
              }}
            >
              Using Image URL:
                {(!this.props.removeInfo) &&
              <Tooltip
                title={
                  <p style={{ fontSize: "10px" }}>
                    On the web, right click an image and click 'Copy Image Link'
                  </p>
                }
                placement="top"
              >
                <span>
                  <FontAwesomeIcon
                    style={{
                      color: "var(--secondary-colo)",
                      fontSize: "16px",
                      marginLeft: "5px"
                    }}
                    icon={faCircleInfo}
                  />
                </span>
              </Tooltip>}
            </p>
            <TextField
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              label="Base64 Link"
              variant="outlined"
              onChange={v => this.setState({ imageURLValue: v.target.value })}
            />
            <div>
              <Button
                onClick={this.onImageAdd}
                className="blueButton"
                variant="contained"
                component="label"
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginLeft: "10px"
                }}
              >
                Add
              </Button>
            </div>

            <p
              style={{
                fontFamily: "Quicksand",
                marginTop: "10px",
                paddingLeft: "10px",
                color: "dimgray"
              }}
            >
              Upload from Local Machine:
            </p>
            <div>
              <Button
                className="blueButton"
                variant="contained"
                component="label"
                style={{
                  textAlign: "center",
                  marginBottom: "10px",
                  marginLeft: "10px"
                }}
              >
                Upload
                <input
                  onChange={e => this.onImageUpload(e)}
                  accept="image/*"
                  multiple
                  type="file"
                />
              </Button>
            </div>
          </div>
        </Popover>
        <div
          className={className}
          onClick={this.focus}
          style={{
            height,
            marginLeft: "0",
            marginTop: "2px",
          }}
        >
          <Editor
            style={{ margin: "0", width: "100%" }}
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            onTab={this.onTab}
            placeholder="..."
            ref={this.editorRef}
            spellCheck
            plugins={plugins}
          />
          {/*<AlignmentTool />*/}
        </div>
      </div>
    );
  }
}

NoteEditor.propTypes = {
  height: PropTypes.number.isRequired,
  editorState: PropTypes.instanceOf(EditorState).isRequired,
  onEdit: PropTypes.func.isRequired,
  saveGraph: PropTypes.func
};

NoteEditor.defaultProps = {
  saveGraph: null
};
