import React, { useState, useEffect } from "react";
import {connect, useDispatch} from "react-redux";
import { getFeature } from "../../selectors/features";
import { getCurrency } from "../../selectors/currency";
import {
  getRole,
  getTwoFAPhone,
  getTwoFASuccess
} from "../../selectors/authentication";
import { getCoins } from "../../selectors/coins";
import { SWAP_CURRENCY } from "../../actions/actionNames";
import { Col, Grid, Row, Alert } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ReactCodeInput from "react-code-input";
import { Button } from "@material-ui/core";
import {
  registerPhoneNumber,
  submitTwoFactor,
  submitTwoFactorSms
} from "../../actions/authentication";
import { Redirect } from "react-router-dom";
import axios, {autoLoggedOut} from "../../api";
import EnterPhoneNumber from "./EnterPhoneNumber";
import { SupportText } from "./SupportText";
import {useSnackbar} from "notistack";
import history from "../history";

const TwoFAEnterSms = ({ submit, success, phone, registerPhone }) => {
  const [code, setCode] = useState(false);
  const [codeSent, setCodeSent] = useState(null);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar(); // Notification snackbar
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => setShow(true), 60000);
  }, [setCodeSent, show])

  const submitCode = async () => {
    const data = await submit(code);
    if (!data) {
      setError(true);
    }
  };

  const sendCode = async () => {
    setShow(false);
    let { data } = await axios.put("/api/v2/2fa-text", {
      phone: phone
    },
      {
        withCredentials: true
      });
    if (!data) {
      setError(true);
    } else {
      setCodeSent(true);
    }
  };
  const changePhoneRequest = async () => {
    try {
      await axios.put("/api/v2/change-phone");
      enqueueSnackbar("Phone request sent to email", {variant: "success"})
      await autoLoggedOut(dispatch)
      history.replace("/login");
    } catch (e) {
      enqueueSnackbar("Request failed", {variant: "error"})
    }
  }
  return (
    <>
      {/* The success variable measures if we've successfully entered the code. If so, redirect us to home*/}
      {success ? (
        <Redirect to="/" />
      ) : (
        <Grid>
          <Helmet>
            <title>2FA Enter</title>
          </Helmet>
          {/* We first check if there is a phone number on file. If not, we display the phone number enter screen*/}
          {phone !== null ? (
            // This logic should be put in its own component
            <Row style={{ paddingTop: "50px" }}>
              {/* Here we check if a code has been sent yet or not. */}
              {codeSent ? (
                <Col lg={12}>
                  <Row style={{ textAlign: "center", marginBottom: "20px" }}>
                    <p style={{ fontSize: "20px" }}>
                      {" "}
                      Enter your Two Factor Authentication Code{" "}
                    </p>
                  </Row>
                  <Row style={{ textAlign: "center", marginBottom: "20px" }}>
                    <ReactCodeInput
                      type="text"
                      fields={6}
                      onChange={e => {
                        setCode(e);
                        setError(false);
                      }}
                    />
                  </Row>
                  <Row style={{ textAlign: "center", marginBottom: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "var(--base-color)",
                        color: "white",
                        fontFamily: "Quicksand",
                        fontSize: "12px"
                      }}
                      onClick={() => submitCode()}
                    >
                      Submit
                    </Button>
                  </Row>
                  {error && (
                    <Row style={{ textAlign: "center" }}>
                      <p style={{ color: "red", fontSize: "14px" }}>
                        {" "}
                        There was an error, please try again.
                      </p>
                    </Row>
                  )}
                  {show && (
                  <>
                    <Row style={{ textAlign: "center", marginBottom: "5px", marginTop: "10px" }}>
                      <Alert bsStyle="warning">
                        <strong>Your verification code expired. Click the button below to get a new one sent to{" "}
                        {phone.slice(-4)}.{" "}</strong>
                        <Row style={{marginTop: "5px"}}>
                          <Button
                          style={{
                            backgroundColor: "var(--secondary-text-colors)",
                            color: "white",
                            fontFamily: "Quicksand",
                            fontSize: "11px",
                          }}
                          onClick={() => sendCode()}
                          >
                            Re-Send Code
                          </Button>
                        </Row>
                      </Alert>
                    </Row>
                  </>)}
                </Col>
              ) : (
                <Col lg={12}>
                  <Row style={{ textAlign: "center", marginBottom: "20px" }}>
                    <p style={{ fontSize: "20px" }}>
                      {" "}
                      Click the button below to send a verification code to phone ending in {" "}
                      {phone.slice(-4)}{" "}
                    </p>
                  </Row>
                  <Row style={{ textAlign: "center", marginBottom: "20px" }}>
                    <Button
                      style={{
                        backgroundColor: "var(--base-color)",
                        color: "white",
                        fontFamily: "Quicksand",
                        fontSize: "12px"
                      }}
                      onClick={() => sendCode()}
                    >
                      Send Code
                    </Button>
                  </Row>
                  {error && (
                    <Row style={{ textAlign: "center" }}>
                      <p style={{ color: "red", fontSize: "14px" }}>
                        {" "}
                        There was an error, please try again.
                      </p>
                    </Row>
                  )}
                </Col>
              )}
            </Row>
          ) : (
            <EnterPhoneNumber setCodeSent={setCodeSent} registerPhone={registerPhone} />
          )}
          {phone && <Row justifyContent={"center"} style={{marginTop: 200}}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", fontSize: 14}}>
              <p style={{padding: 0, marginBottom: 3}}>Change your phone</p>
              <Button
                  style={{paddingTop: 0, paddingBottom: 0, color: "#337ab7", fontWeight: 400, fontSize: 11}}
                  onClick={changePhoneRequest}
              >
                click here to send email request
              </Button>
            </div>
          </Row>}
          <SupportText />
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    throwExceptionComponentEnabled: getFeature(state, "throw_exception_test"),
    issuesEnabled: getFeature(state, "issues"),
    currency: getCurrency(state),
    isResearcher: getRole(state) === "researcher",
    success: getTwoFASuccess(state),
    phone: getTwoFAPhone(state)
  };
};

const mapDispatchToProps = dispatch => ({
  swapCurrency: currency => dispatch({ type: SWAP_CURRENCY, currency }),
  submit: code => dispatch(submitTwoFactorSms(code)),
  registerPhone: number => dispatch(registerPhoneNumber(number)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoFAEnterSms);
