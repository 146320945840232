import { getCurrency } from "../../../selectors/currency";
import { getClientMode } from "../../../selectors/applicationConfig";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEye } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, FormControl, FormGroup, Modal } from "react-bootstrap";
import React, { Component } from "react";
import axios, { WATCHLIST_API } from "../../../api";
import { getFeature } from "../../../selectors/features";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { CircularProgress } from "@material-ui/core";
import MempoolCheckbox from "./MempoolCheckbox";
import { SMSCheckbox } from "./SMSCheckbox";

class AddToWatchlist extends Component {
  state = {
    success: false,
    loading: false,
    showModal: false,
    showTitleModal: false,
    mempool_tracking: false,
    sms_messaging: false
  };

  addToWatchlist = async e => {
    e.preventDefault();
    let status;
    let title = this.title.value.trim();
    let notes = this.notes.value.trim();
    this.setState({
      loading: true,
      showModal: true,
      showTitleModal: false
    });

    const { mempool_tracking, sms_messaging } = this.state;

    if (this.props.entityType === "Address") {
      status = await axios.post(WATCHLIST_API(this.props.currency), {
        watchlist_item_type: "Address",
        address: this.props.address,
        title: title,
        notes: notes,
        mempool_tracking: mempool_tracking,
        sms_messaging
      });
    } else if (this.props.entityType === "Wallet") {
      status = await axios.post(WATCHLIST_API(this.props.currency), {
        watchlist_item_type: "Wallet",
        wallet_id: this.props.wallet_id,
        title: title,
        notes: notes,
        mempool_tracking: mempool_tracking,
        sms_messaging
      });
    } else {
      status = await axios.post(WATCHLIST_API(this.props.currency), {
        watchlist_item_type: "UTXO",
        address: this.props.address,
        transaction_hash: this.props.transaction_hash,
        satoshi: this.props.satoshi,
        output_index: this.props.output_index,
        title: title,
        notes: notes,
        mempool_tracking: mempool_tracking,
        sms_messaging
      });
    }

    if (status.data === "true") {
      // Set the local state
      this.setState({
        success: true,
        loading: false
      });
    } else {
      this.setState({
        success: false,
        loading: false
      });
    }
  };

  render() {
    const { address, wallet_id, currency, entityType, watchlistEnabled } = this.props;
    const entity_id = entityType === "Address" ? address : wallet_id;
    return (
      <>
        {watchlistEnabled && currency !== "ethereum" && (
          <Button onClick={() => this.setState({ showTitleModal: true })} className="blueButton">
            <FontAwesomeIcon icon={faEye} />
            {entityType !== "UTXO" && (
              <p style={{ marginLeft: "7px", display: "inline", fontFamily: "Quicksand" }}>
                Add to Watchlist
              </p>
            )}
          </Button>
        )}
        <Modal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false, loading: false })}
        >
          <Modal.Body>
            <div
              style={{
                alignContent: "center",
                textAlign: "center",
                margin: "auto",
                width: "60%"
              }}
            >
              {this.state.loading ? (
                <>
                  <CircularProgress style={{ color: "var(--secondary-color)" }} />
                </>
              ) : this.state.success ? (
                <>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{
                      color: "#4fb346",
                      width: "100%",
                      fontSize: "75px",
                      textAlign: "center",
                      display: "block",
                      marginBottom: "7px",
                      marginTop: "30px"
                    }}
                  >
                    {" "}
                  </FontAwesomeIcon>
                  <p
                    style={{
                      display: "block",
                      color: "#999",
                      fontFamily: "Quicksand, sans-serif",
                      fontSize: "30px",
                      marginBottom: "45px"
                    }}
                  >
                    {" "}
                    This entity was successfully added to your Watchlist.
                  </p>
                </>
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{
                      color: "red",
                      width: "100%",
                      fontSize: "75px",
                      textAlign: "center",
                      display: "block",
                      marginBottom: "7px",
                      marginTop: "30px"
                    }}
                  >
                    {" "}
                  </FontAwesomeIcon>
                  <p
                    style={{
                      display: "block",
                      color: "#999",
                      fontFamily: "Quicksand, sans-serif",
                      fontSize: "24px",
                      marginBottom: "45px"
                    }}
                  >
                    {" "}
                    This entity is already in your Watchlist. Please stop the watchlist item before
                    adding it again.
                  </p>
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showTitleModal}
          onHide={() => this.setState({ showTitleModal: false })}
          className="issuesModal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="entityTitle">Add To Watchlist</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={e => this.addToWatchlist(e)}>
              <FormGroup>
                <FormControl
                  type="text"
                  placeholder="Title"
                  inputRef={ref => {
                    this.title = ref;
                  }}
                />
                <br />
                <FormControl
                  type="textarea"
                  placeholder="Notes"
                  inputRef={ref => {
                    this.notes = ref;
                  }}
                />
                <br />
                <div>
                  <MempoolCheckbox
                    mempoolValue={this.state.mempool_tracking}
                    setValue={mempool_tracking => {
                      this.setState({ mempool_tracking: mempool_tracking });
                    }}
                    entityType={entityType.toLowerCase()}
                    entity_id={entity_id}
                    currency={currency}
                    style={{
                      marginRight: "5px",
                      display: "inline-block",
                      height: "20px",
                      width: "20px",
                      border: "2px solid var(--base-color)",
                      verticalAlign: "middle",
                      textAlign: "center",
                      fontFamily: "Quicksand",
                      cursor: "pointer"
                    }}
                  />
                  <div style={{ float: "right", fontSize: "14px" }}>
                    <SMSCheckbox
                      value={this.state.sms_messaging}
                      setValue={sms_messaging => {
                        this.setState({ sms_messaging: sms_messaging });
                      }}
                      currency={currency}
                      entityType={entityType.toLowerCase()}
                    />
                  </div>
                </div>
              </FormGroup>
              <Button className="blueButton" type="submit">
                Add
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

AddToWatchlist.propTypes = {
  currency: PropTypes.string.required,
  entityType: PropTypes.string.required,
  address: PropTypes.number,
  wallet_id: PropTypes.number,
  transaction_hash: PropTypes.string,
  satoshi: PropTypes.number,
  output_index: PropTypes.number
};

const mapStateToProps = state => {
  return {
    currency: getCurrency(state),
    clientMode: getClientMode(state),
    watchlistEnabled: getFeature(state, "watchlist")
  };
};

export default connect(
  mapStateToProps,
  null
)(AddToWatchlist);
