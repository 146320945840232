// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { createSelector } from "reselect";

export const getAuthentication = state => state.get("authentication");

export const getLoggedIn = createSelector(
  [getAuthentication],
  authentication => authentication.get("loggedIn")
);

export const getTwoFAConfigured = createSelector(
  [getAuthentication],
  authentication => authentication.get("twoConfigured")
);

export const getTwoFAPhone = createSelector(
  [getAuthentication],
  authentication => authentication.get("phone")
);

export const getTwoFASuccess = createSelector(
  [getAuthentication],
  authentication => authentication.get("twoSuccess")
);

export const getError = createSelector(
  [getAuthentication],
  authentication => authentication.get("error")
);

export const getRole = createSelector(
  [getAuthentication],
  authentication => authentication.get("role")
);

export const getRegistrationTimestamp = createSelector(
  [getAuthentication],
  authentication => authentication.get("registrationTimestamp")
);

export const getCtAccess = createSelector(
  [getAuthentication],
  authentication => authentication.get("ctAccess")
);

/**
 * Gets email for the current user logged in
 * @type {OutputSelector<[(function(*): *)], *, (...args: SelectorResultArray<[(function(*): *)]>) => (* & {clearCache: () => void}), GetParamsFromSelectors<[(function(*): *)]>> & {clearCache: () => void}}
 */
export const getEmail = createSelector(
  [getAuthentication],
  authentication => authentication.get("email")
);
