// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { is, List, Map, Record, Set } from "immutable";
import { convertFromRaw, EditorState } from "draft-js";

import {
  GRAPH_ADD_WALLET_TO_GRAPH,
  GRAPH_CHANGE_CASE_NUMBER,
  GRAPH_CHANGE_DATA_TABS_KEY,
  GRAPH_CHANGE_DATES,
  GRAPH_CHANGE_DESCRIPTION,
  GRAPH_CHANGE_MAIN_TABS_KEY,
  GRAPH_CHANGE_TOGGLE_STATE,
  GRAPH_CLOSE_EXPLORER_MODAL,
  GRAPH_DELETE_SUCCESS,
  GRAPH_DELETE_WALLET_FROM_GRAPH,
  GRAPH_EXPLORER_MODAL_GO_BACK,
  GRAPH_EXPLORER_MODAL_GO_FORWARD,
  GRAPH_FETCH_SUCCESS,
  GRAPH_IMPORT_GRAPH,
  GRAPH_MUTUAL_TRANSACTION_CHANGE_ORDER,
  GRAPH_OPEN_EXPLORER_MODAL,
  GRAPH_PEEL_CHAIN_STATUS_SUCCESS,
  GRAPH_SAVE_SUCCESS,
  GRAPH_SEARCH_ASSOCIATIONS_SUCCESS,
  GRAPH_SEARCH_SUCCESS,
  GRAPH_SET_CURRENT_QUERY,
  GRAPH_SET_OUTPUT_WALLET_ID,
  GRAPH_SHOW_NOTES_SET,
  GRAPH_UNDO_STACK_ADD,
  GRAPH_UNDO_STACK_GO_BACK,
  GRAPH_UNDO_STACK_GO_FORWARD,
  GRAPH_UPDATE_EDITOR_STATE,
  GRAPH_UPDATE_GRAPH_IN_REDUX,
  LOGGING_OUT,
  SETTING_CURRENT_GRAPH
} from "../actions/actionNames";

/*
Graph State
Graph: {
  view: GraphView: {
    currentQuery: string,
    queries: Map<string, QueryResult>,
    currentAssociationQuery: AssociationQuery,
    // First layer is the wallet_id (as a string). Second layer is the actual query.
    associationQueries: Map<string, Map<string, WalletAssociation>>,
    modals: Modals: {
      currentSet: 'explorer' | 'graph' | null,
      explorer: ExplorerModals: {
        history: List<HistoryRecord>,
        index: number,
      },
      graphUndoStack: {
        stack: List<GraphUndoRecord>,
        index: number,
      },
      mainTabsKey: 'data' | 'editor' | 'control' | 'search' | 'console',
      dataTabsKey:
        'WalletStats' |
        'WalletAddresses' |
        'ReceivedWallets' |
        'SentWallets' |
        'WalletMentions' |
        'WalletTransactions' |
        'WalletMutualTransactions',
    },
    outputWalletId: string | null,
    mutualTransactionsOrder: 'largestFirst' | 'smallestFirst' | 'newestFirst' | 'oldestFirst',
    showNotes: boolean,
  },
  graphIdToGraphData: Map<string, GraphRecord>,
}

QueryResult = WalletResult | TransactionResult | TransactionResultWallet

WalletResult: {
  address: string,
  primaryTag: string | null,
  walletId: number,
  category: string | null,
  anchorAddress: string,
}

TransactionResult: {
  transactionHash: string,
  wallets: List<TransactionResultWallet>,
}

TransactionResultWallet: {
  walletId: number,
  primaryTag: string | null,
  category: string | null,
}

GraphRecord: {
  caseNumber: string,
  description: string,
  originalEditorState: string,
  editorState: EditorState,
  graph: output of cytoscape.json(),
  // This should be strings...
  wallets: Set<number>,
}

HistoryRecord: {
  entityType: 'address' | 'wallet' | 'transaction',
  entity: string | number,
}

GraphUndoRecord: {
  opType: 'add' | 'delete' | 'move' | 'setTag' | 'changeColor' | 'setNotes',
  wallets: List<number>,
  shouldRefit: bool,
}

// This is a catch-all of all possible fields. Maybe make it a sum type at some
// point.
// Pretty much all the fields here can be null except walletId.
GraphUndoWallet: {
  walletId: string, // number as a string
  label: string,
  category: string,
  x: number,
  y: number,
  prevX: number,
  prevY: number,
  manuallySetColor: string,
  tagOperation: TagOperation,
  prevNotes: string,
  notes: string,
  // Needed since style that manages wallet and edge colors are different.
  colorStyle: string,
  prevColor: string,
  color: string,
}

TagOperation: {
  // optype is used to correctly handle side-effects on the server.
  // If the tag was deleted, we want to restore and delete it when undoing, not
  // set a custom tag.
  opType: 'delete' | 'change',
  oldTag: string,
  newTag: string,
}

// This association is a combined sent/received when compared to WalletAssociation
// for the wallet reducers.
WalletAssociation: {
  inputSatoshi: number,
  outputSatoshi: number,
  tag: string,
}

AssociationQuery: {
  walletId: string,
  query: string,
}
 */

export const GraphRecord = Record({
  caseNumber: "", // supposed to be something like 123-567-9413, no check is done though
  description: "",
  originalEditorState: "",
  editorState: EditorState.createEmpty(),
  graph: {}, // just keep as a PJO, use the to/from json calls, when graph unmounts/changes save
  // list of STRINGS, which represent wallet ids. Cytoscape stores the wallets ids
  // as strings, so rather than converting between numbers and strings, just keep
  // them as a string in this set
  wallets: Set(),
  lastSaved: "",
  startDate: null,
  endDate: null,
  attributedEdgeToggleState: true
});

export const HistoryRecord = Record({
  entityType: null, // address, wallet, transaction
  entity: null
});

const GraphUndoStack = Record({
  stack: List(), // List of GraphUndoRecord
  index: -1
});

const GraphUndoRecord = Record({
  opType: null,
  wallets: List(),
  // The following are only set when doing layout operations.
  shouldRefit: false
});

const GraphUndoWallet = Record({
  walletId: "",
  label: "",
  category: "",
  x: 0,
  y: 0,
  prevX: 0,
  prevY: 0,
  manuallySetColor: "",
  tagOperation: null,
  prevNotes: "",
  notes: "",
  satoshi: "",
  bitcoin: "",
  type: "default",
  attributed: false,
  // Needed since style that manages wallet and edge colors are different.
  colorStyle: "",
  prevColor: "",
  color: "",
  hist_usd: "",

  //Ethereum specific Stuff
  address: "",
  input_count: 0,
  output_count: 0,
  first_transaction_id: 0,
  internal_input_count: 0,
  internal_output_count: 0,
  creation_transaction_id: 0,
  source: "",
  target: "",
  transactionCount: 0,
  transactionCountDict: Map()
});

const TagOperation = Record({
  opType: null,
  oldTag: "",
  newTag: ""
});

export const ExplorerModals = Record({
  history: List(), // List of HistoryRecord
  index: -1
});

export const Modals = Record({
  // currentSet is the set of modals to display, if any
  // either graph modals or explorer modals are shown
  currentSet: null, // either 'explorer', 'graph', null
  explorer: new ExplorerModals(),
  graphUndoStack: new GraphUndoStack(),
  currentGraph: null
});

export const WalletResult = Record({
  address: null,
  primaryTag: null,
  walletId: null,
  category: null,
  anchorAddress: null, // This should never be null
  peel: null,
  peel_chain_data: null
});

export const TransactionResult = Record({
  transactionHash: null,
  wallets: List(),
  unclustered_inputs: false
});

export const TransactionResultWallet = Record({
  walletId: null,
  primaryTag: null,
  category: null,
  anchorAddress: null
});

export const GraphView = Record({
  currentQuery: null, // this is query displayed in search results
  // map of Query string to list of TransactionResult, TransactionResult, TransactionResultWallet
  queries: Map(),
  currentAssociationQuery: null,
  associationQueries: Map(),
  modals: new Modals(),
  mainTabsKey: "editor", // one of 'data', 'editor', 'control', 'search', 'console'
  dataTabsKey: "WalletStats",
  outputWalletId: null,
  mutualTransactionsOrder: "largestFirst",
  showNotes: false
});

export const Graph = Record({
  view: new GraphView(), // view state for current graph being shown
  graphIdToGraphData: Map() // map of int -> GraphRecord
});

const WalletAssociation = Record({
  walletId: "",
  inputSatoshi: 0,
  outputSatoshi: 0,
  tag: "",
  category: "",
  anchorAddress: ""
});

const AssociationQuery = Record({
  walletId: "",
  query: ""
});

export const getGraphSearchCurrentQuery = state => {
  return state.getIn(["view", "currentQuery"]);
};

export const closeExplorerModal = state => {
  return state.setIn(["view", "modals", "currentSet"], null);
};

export const updateGraphInRedux = (state, { graphId, graph }) => {
  return state.setIn(["graphIdToGraphData", graphId, "graph"], graph);
};

/**
 * Adds a graph to the store.
 * Called when Graph component mounts or changes its graphId prop
 * @param state
 * @param action
 */
const addGraphData = (
  state,
  {
    graphId,
    wallets,
    data: { caseNumber, attributedEdgeToggleState, description, editorContent, graph }
  }
) => {
  let startDate, endDate, graph_;
  if (graph) {
    ({ startDate, endDate, ...graph_ } = graph);
  }
  startDate = startDate ? new Date(startDate) : null;
  endDate = endDate ? new Date(endDate) : null;
  if (Array.isArray(editorContent)) {
    editorContent = editorContent[0];
  }
  const editorState = editorContent
    ? EditorState.createWithContent(convertFromRaw(editorContent))
    : EditorState.createEmpty();
  const graphRecord = GraphRecord({
    caseNumber,
    attributedEdgeToggleState,
    description,
    editorState,
    graph: graph_,
    wallets: Set(wallets),
    startDate,
    endDate,
    lastSaved: ""
  });

  return state.setIn(["graphIdToGraphData", graphId], graphRecord);
};

export const addCurrentGraph = (state, { graphId }) =>
  state.setIn(["view", "modals", "currentGraph"], graphId);

export const addSavedTimestamp = (state, { graphId, timestamp }) =>
  state.setIn(["graphIdToGraphData", graphId, "lastSaved"], timestamp);

const importGraph = (
  state,
  {
    graphId,
    wallets,
    caseNumber,
    description,
    graph: graph_,
    editorContent,
    attributedEdgeToggleState = true
  }
) => {
  const editorState = editorContent
    ? EditorState.createWithContent(convertFromRaw(editorContent))
    : EditorState.createEmpty();
  let graphRecord = state.getIn(["graphIdToGraphData", graphId]);

  graphRecord = graphRecord
    .set("graph", graph_)
    .set("wallets", Set(wallets))
    .set("caseNumber", caseNumber)
    .set("attributedEdgeToggleState", attributedEdgeToggleState)
    .set("description", description)
    .set("editorState", editorState);

  return state.setIn(["graphIdToGraphData", graphId], graphRecord);
};

const changeDescription = (state, { graphId, description }) =>
  state.setIn(["graphIdToGraphData", graphId, "description"], description);

const changeCaseNumber = (state, { graphId, caseNumber }) =>
  state.setIn(["graphIdToGraphData", graphId, "caseNumber"], caseNumber);

const changeAttributedEdgeToggleState = (state, { graphId, attributedEdgeToggleState }) =>
  state.setIn(
    ["graphIdToGraphData", graphId, "attributedEdgeToggleState"],
    attributedEdgeToggleState
  );

const changeDates = (state, { graphId, startDate, endDate }) => {
  state = state.setIn(["graphIdToGraphData", graphId, "startDate"], startDate);
  return state.setIn(["graphIdToGraphData", graphId, "endDate"], endDate);
};

/**
 * Move index forward one if possible, otherwise do nothing
 */
export const historyGoForward = state => {
  let explorerModals = state.getIn(["view", "modals", "explorer"]);
  const history = explorerModals.get("history");
  const index = explorerModals.get("index");
  if (index < history.size - 1) {
    explorerModals = explorerModals.set("index", index + 1);
    return state.setIn(["view", "modals", "explorer"], explorerModals);
  }

  return state;
};

export const historyGoBack = state => {
  return state.updateIn(["view", "modals", "explorer", "index"], index => {
    if (index > 0) {
      return index - 1;
    }
    return index;
  });
};

const graphUndoStackGoForward = state => {
  let record = state.getIn(["view", "modals", "graphUndoStack"]);
  const stack = record.get("stack");
  const index = record.get("index");
  if (index < stack.size - 1) {
    record = record.set("index", index + 1);
    return state.setIn(["view", "modals", "graphUndoStack"], record);
  }

  return state;
};

const graphUndoStackGoBack = state => {
  return state.updateIn(["view", "modals", "graphUndoStack", "index"], index => {
    if (index >= 0) {
      return index - 1;
    }
    return index;
  });
};

const addQueryResult = (state, { query, data }) => {
  // map over results to create list
  let resultRecords = new Map(data);
  return state
    .setIn(["view", "queries", query], resultRecords)
    .setIn(["view", "currentQuery"], query);
};

const addAssociationResult = (state, { walletId, query, results }) => {
  let resultRecords = new Map();
  results.forEach(result => {
    resultRecords = resultRecords.set(result.walletId, new WalletAssociation(result));
  });

  const walletStr = walletId.toString();
  const associationQuery = AssociationQuery({
    walletId: walletStr,
    query
  });

  return state
    .setIn(["view", "associationQueries", walletStr, query], resultRecords)
    .setIn(["view", "currentAssociationQuery"], associationQuery);
};

const setCurrentQuery = (state, { query }) => state.setIn(["view", "currentQuery"], query);

const setOutputWalletId = (state, { outputWalletId }) =>
  state.setIn(["view", "outputWalletId"], outputWalletId);

/**
 * When a link is clicked, this reducer will add the entity to
 * history and discard any that are ahead
 * In addition, if the current index points to the same entity, don't modify history
 * currentSet will still be set, which will open up the modal
 */
export const historyAddItem = (state, { entityData }) => {
  let modals = state.getIn(["view", "modals"]).set("currentSet", "explorer");

  // Why would entityType be null?
  // Seems like its not relevant anymore. It would be null if explorer was opened
  // without adding an item. I don't think the button for this exists anymore.
  if (entityData.entityType != null) {
    // Add item to history if needed
    let explorerModals = modals.get("explorer");
    let history = explorerModals.get("history"); // List()
    const index = explorerModals.get("index"); // int

    // if index is not at end of history,
    // remove all values after index
    if (index !== history.size - 1) {
      history = history.take(index + 1);
    }
    const newHistoryRecord = new HistoryRecord(entityData);
    const currentHistoryRecord = history.get(index);
    // do not add to end if record is the same
    // This is needed since this action is called whenever the user clicks on an
    // entity, which may also happen to be the most recent one.
    // (Open Address A in explorer, close explorer, open Address A again.)
    // We want the stack to be [Address A], not [Address A, Address A].
    if (!is(newHistoryRecord, currentHistoryRecord)) {
      history = history.push(new HistoryRecord(entityData));
      // update doesn't make sense here... If we remove items from history, it won't
      // be accurate.
      explorerModals = explorerModals.set("history", history).update("index", i => i + 1);
    }
    modals = modals.set("explorer", explorerModals);
  }
  return state.setIn(["view", "modals"], modals);
};

const graphUndoStackAddItem = (state, { opType, wallets, shouldRefit }) => {
  let record = state.getIn(["view", "modals", "graphUndoStack"]);
  let stack = record.get("stack");
  const index = record.get("index");

  if (index !== stack.size - 1) {
    stack = stack.take(index + 1);
  }

  const walletRecords = List(
    wallets.map(wallet => {
      return new GraphUndoWallet(wallet);
    })
  );

  const newStackItem = new GraphUndoRecord({
    opType,
    wallets: walletRecords,
    shouldRefit
  });
  stack = stack.push(newStackItem);
  record = record.set("stack", stack).set("index", stack.size - 1);

  return state.setIn(["view", "modals", "graphUndoStack"], record);
};

const changeMainTabsKey = (state, { mainTabsKey }) =>
  state.setIn(["view", "mainTabsKey"], mainTabsKey);

const changeDataTabsKey = (state, { dataTabsKey }) =>
  state.setIn(["view", "dataTabsKey"], dataTabsKey);

const updateEditorState = (state, { graphId, editorState }) => {
  if (state.getIn(["graphIdToGraphData", graphId])) {
    return state.setIn(["graphIdToGraphData", graphId, "editorState"], editorState);
  } else {
    return state;
  }
};

const deleteGraph = (state, { graphId }) => {
  // Uncomment below as sanity check.
  // I think redux devtools is just buggy and doesn't correctly
  // show that updated state.
  // const initialSize = state.get('graphIdToGraphData').size;
  const newState = state.deleteIn(["graphIdToGraphData", graphId]);
  // const newSize = newState.get('graphIdToGraphData').size;
  // if (initialSize === newSize) {
  //   throw Error('Unable to delete graph in redux');
  // }
  return newState;
};

const addWalletToGraph = (state, { walletId, graphId }) => {
  return state.updateIn(["graphIdToGraphData", graphId, "wallets"], wallets =>
    wallets.add(walletId)
  );
};

const deleteWalletFromGraph = (state, { walletId, graphId }) => {
  return state.updateIn(["graphIdToGraphData", graphId, "wallets"], wallets => {
    return wallets.delete(walletId);
  });
};

const setMutualTransactionsOrder = (state, { order }) => {
  return state.setIn(["view", "mutualTransactionsOrder"], order);
};

const setShowNotes = (state, { showNotes }) => {
  return state.setIn(["view", "showNotes"], showNotes);
};

const graphAddPeelChainStatus = (state, { walletId, data }) => {
  const currentQuery = getGraphSearchCurrentQuery(state);
  return state.setIn(["view", "queries", currentQuery, walletId, "peel"], data);
};

export const sharedGraphReducer = (state, action, coin) => {
  if (action === undefined || action.name !== coin) {
    return state;
  }
  switch (action.type) {
    case GRAPH_UPDATE_EDITOR_STATE:
      return updateEditorState(state, action);
    case GRAPH_SAVE_SUCCESS:
      return addSavedTimestamp(state, action);
    case SETTING_CURRENT_GRAPH:
      return addCurrentGraph(state, action);
    case GRAPH_CHANGE_CASE_NUMBER:
      return changeCaseNumber(state, action);
    case GRAPH_CHANGE_DESCRIPTION:
      return changeDescription(state, action);
    case GRAPH_CHANGE_MAIN_TABS_KEY:
      return changeMainTabsKey(state, action);
    case GRAPH_OPEN_EXPLORER_MODAL:
      return historyAddItem(state, action);
    case GRAPH_CLOSE_EXPLORER_MODAL:
      return closeExplorerModal(state, action);
    case GRAPH_EXPLORER_MODAL_GO_BACK:
      return historyGoBack(state, action);
    case GRAPH_EXPLORER_MODAL_GO_FORWARD:
      return historyGoForward(state, action);
    case GRAPH_DELETE_SUCCESS:
      return deleteGraph(state, action);
    case GRAPH_UPDATE_GRAPH_IN_REDUX:
      return updateGraphInRedux(state, action);
    case GRAPH_IMPORT_GRAPH:
      return importGraph(state, action);
    case GRAPH_CHANGE_DATA_TABS_KEY:
      return changeDataTabsKey(state, action);
    case GRAPH_SET_OUTPUT_WALLET_ID:
      return setOutputWalletId(state, action);
    case GRAPH_MUTUAL_TRANSACTION_CHANGE_ORDER:
      return setMutualTransactionsOrder(state, action);
    case GRAPH_SEARCH_ASSOCIATIONS_SUCCESS:
      return addAssociationResult(state, action);
    case GRAPH_UNDO_STACK_ADD:
      return graphUndoStackAddItem(state, action);
    case GRAPH_UNDO_STACK_GO_BACK:
      return graphUndoStackGoBack(state, action);
    case GRAPH_UNDO_STACK_GO_FORWARD:
      return graphUndoStackGoForward(state, action);
    case GRAPH_SHOW_NOTES_SET:
      return setShowNotes(state, action);
    case LOGGING_OUT:
      return new Graph();
    default:
      return state;
  }
};

const makeGraphReducer = coin => {
  return (state = new Graph(), action) => {
    // If the coin doesn't match the reducer, just return the state.
    if (action === undefined || action.name !== coin) {
      return state;
    }
    switch (action.type) {
      case GRAPH_FETCH_SUCCESS:
        return addGraphData(state, action);
      case GRAPH_CHANGE_CASE_NUMBER:
        return changeCaseNumber(state, action);
      case GRAPH_CHANGE_DATES:
        return changeDates(state, action);
      case GRAPH_CHANGE_TOGGLE_STATE:
        return changeAttributedEdgeToggleState(state, action);
      case GRAPH_CHANGE_DESCRIPTION:
        return changeDescription(state, action);
      case GRAPH_CHANGE_MAIN_TABS_KEY:
        return changeMainTabsKey(state, action);
      case GRAPH_SEARCH_SUCCESS:
        return addQueryResult(state, action);
      case GRAPH_SET_CURRENT_QUERY:
        return setCurrentQuery(state, action);
      case GRAPH_OPEN_EXPLORER_MODAL:
        return historyAddItem(state, action);
      case GRAPH_CLOSE_EXPLORER_MODAL:
        return closeExplorerModal(state, action);
      case GRAPH_EXPLORER_MODAL_GO_BACK:
        return historyGoBack(state, action);
      case GRAPH_EXPLORER_MODAL_GO_FORWARD:
        return historyGoForward(state, action);
      case GRAPH_DELETE_SUCCESS:
        return deleteGraph(state, action);
      case GRAPH_ADD_WALLET_TO_GRAPH:
        return addWalletToGraph(state, action);
      case GRAPH_DELETE_WALLET_FROM_GRAPH:
        return deleteWalletFromGraph(state, action);
      case GRAPH_UPDATE_GRAPH_IN_REDUX:
        return updateGraphInRedux(state, action);
      case GRAPH_IMPORT_GRAPH:
        return importGraph(state, action);
      case GRAPH_CHANGE_DATA_TABS_KEY:
        return changeDataTabsKey(state, action);
      case GRAPH_SET_OUTPUT_WALLET_ID:
        return setOutputWalletId(state, action);
      case GRAPH_MUTUAL_TRANSACTION_CHANGE_ORDER:
        return setMutualTransactionsOrder(state, action);
      case GRAPH_SEARCH_ASSOCIATIONS_SUCCESS:
        return addAssociationResult(state, action);
      case GRAPH_UNDO_STACK_ADD:
        return graphUndoStackAddItem(state, action);
      case GRAPH_UNDO_STACK_GO_BACK:
        return graphUndoStackGoBack(state, action);
      case GRAPH_UNDO_STACK_GO_FORWARD:
        return graphUndoStackGoForward(state, action);
      case GRAPH_SHOW_NOTES_SET:
        return setShowNotes(state, action);
      case GRAPH_PEEL_CHAIN_STATUS_SUCCESS:
        return graphAddPeelChainStatus(state, action);
      case LOGGING_OUT:
        return new Graph();
      default:
        return sharedGraphReducer(state, action, coin);
    }
  };
};

export default makeGraphReducer;
