import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import React from "react";

const CustomMapComponent = withScriptjs(
  withGoogleMap(props => {
    return (
      <GoogleMap defaultZoom={1} defaultCenter={{ lat: 0, lng: 0 }}>
        {props.markers &&
          props.markers.map((marker, index) => {
            return (
              <Marker
                focusable={false}
                key={index}
                position={{
                  lat: parseFloat(marker[1]),
                  lng: parseFloat(marker[0]),
                }}
                onClick={e => {
                  props.setCurrentIps(marker[2]);
                }}
              />
            );
          })}
      </GoogleMap>
    );
  })
);

CustomMapComponent.defaultProps = {
  isMarkerShown: true,
  googleMapURL:
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyC8cw2oXxsKHcK6SJMbbLLfUmke8yDThWY&v=3.exp&libraries=geometry,drawing,places",
  loadingElement: <div style={{ height: `100%` }} />,
  containerElement: <div style={{ height: `300px`, width: "100%" }} />,
  mapElement: <div style={{ height: `100%`, width: "95%" }} />,
};

export default CustomMapComponent;
