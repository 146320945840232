// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

// This file exists to avoid circular imports, which caused issues at one point

/* Address actions */
export const FETCHING_ADDRESS_SUMMARY = "FETCHING_ADDRESS_SUMMARY";
export const ADDRESS_SUMMARY_FETCH_SUCCESS = "ADDRESS_SUMMARY_FETCH_SUCCESS";
export const ADDRESS_SUMMARY_FETCH_FAILURE = "ADDRESS_SUMMARY_FETCH_FAILURE";
export const FETCHING_MORE_ADDRESS_SUMMARY = "FETCHING_MORE_ADDRESS_SUMMARY";
export const MORE_ADDRESS_SUMMARY_FETCH_SUCCESS = "MORE_ADDRESS_SUMMARY_FETCH_SUCCESS";
export const MORE_ADDRESS_SUMMARY_FETCH_FAILURE = "MORE_ADDRESS_SUMMARY_FETCH_FAILURE";

export const FETCHING_ADDRESS_HISTORY_STATS = "FETCHING_ADDRESS_HISTORY_STATS";
export const ADDRESS_HISTORY_STATS_SUCCESS = "ADDRESS_HISTORY_STATS_SUCCESS";
export const ADDRESS_HISTORY_STATS_FAILURE = "ADDRESS_HISTORY_STATS_FAILURE";
export const FETCHING_ADDRESS_RESEARCH_RAW_DATA = "FETCHING_ADDRESS_RESEARCH_RAW_DATA";
export const ADDRESS_RESEARCH_RAW_DATA_FETCH_SUCCESS = "ADDRESS_RESEARCH_RAW_DATA_FETCH_SUCCESS";
export const ADDRESS_RESEARCH_RAW_DATA_FETCH_FAILURE = "ADDRESS_RESEARCH_RAW_DATA_FETCH_FAILURE";

export const FETCHING_ADDRESS_TRANSACTIONS = "FETCHING_ADDRESS_TRANSACTIONS";
export const ADDRESS_TRANSACTIONS_FETCH_SUCCESS = "ADDRESS_TRANSACTIONS_FETCH_SUCCESS";
export const ADDRESS_TRANSACTIONS_FETCH_FAILURE = "ADDRESS_TRANSACTIONS_FETCH_FAILURE";

export const FETCHING_ADDRESS_TRANSACTIONS_BY_SATOSHI = "FETCHING_ADDRESS_TRANSACTIONS_BY_SATOSHI";
export const ADDRESS_TRANSACTIONS_BY_SATOSHI_FETCH_SUCCESS =
  "ADDRESS_TRANSACTIONS_BY_SATOSHI_FETCH_SUCCESS";
export const ADDRESS_TRANSACTIONS_BY_SATOSHI_FETCH_FAILURE =
  "ADDRESS_TRANSACTIONS_BY_SATOSHI_FETCH_FAILURE";

export const ADDRESS_TRANSACTIONS_SWAP_ORDER = "ADDRESS_TRANSACTIONS_SWAP_ORDER";
export const ADDRESS_TRANSACTIONS_SET_ORDER = "ADDRESS_TRANSACTIONS_SET_ORDER";
export const ADDRESS_MUTUAL_TRANSACTIONS_SWAP_ORDER = "ADDRESS_MUTUAL_TRANSACTIONS_SWAP_ORDER";
export const ADDRESS_TRANSACTIONS_SET_DATE_RANGE = "ADDRESS_TRANSACTIONS_SET_DATE_RANGE";
export const ADDRESS_MUTUAL_WALLETS_TRANSACTIONS_SET_LARGEST_FIRST =
  "ADDRESS_MUTUAL_WALLETS_TRANSACTIONS_SET_LARGEST_FIRST";

export const ADDRESS_MUTUAL_WALLETS_TRANSACTIONS_SET_DATE_RANGE =
  "ADDRESS_MUTUAL_WALLETS_TRANSACTIONS_SET_DATE_RANGE";

export const FETCHING_ADDRESS_MENTIONS = "FETCHING_ADDRESS_MENTIONS";
export const ADDRESS_MENTIONS_FETCH_SUCCESS = "ADDRESS_MENTIONS_FETCH_SUCCESS";
export const ADDRESS_MENTIONS_FETCH_FAILURE = "ADDRESS_MENTIONS_FETCH_FAILURE";

export const FETCHING_ADDRESS_MUTUAL_WALLETS = "FETCHING_ADDRESS_MUTUAL_WALLETS";
export const ADDRESS_MUTUAL_WALLETS_FETCH_SUCCESS = "ADDRESS_MUTUAL_WALLETS_FETCH_SUCCESS";
export const ADDRESS_MUTUAL_WALLETS_FETCH_FAILURE = "ADDRESS_MUTUAL_WALLETS_FETCH_FAILURE";

export const FETCHING_ADDRESS_WALLET_MUTUAL_TRANSACTIONS =
  "FETCHING_ADDRESS_WALLET_MUTUAL_TRANSACTIONS";
export const ADDRESS_WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS =
  "ADDRESS_WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS";

export const ADDRESS_WALLET_MUTUAL_TRANSACTIONS_FETCH_FAILURE =
  "ADDRESS_WALLET_MUTUAL_TRANSACTIONS_FETCH_FAILURE";

export const FETCHING_ADDRESS_MORE_INFO = "FETCHING_ADDRESS_MORE_INFO";
export const ADDRESS_MORE_INFO_FETCH_SUCCESS = "ADDRESS_MORE_INFO_FETCH_SUCCESS";
export const ADDRESS_MORE_INFO_FETCH_FAILURE = "ADDRESS_MORE_INFO_FETCH_FAILURE";

export const FETCHING_ADDRESS_COIN_SWAP = "FETCHING_ADDRESS_COIN_SWAP";
export const ADDRESS_COIN_SWAP_FETCH_SUCCESS = "ADDRESS_COIN_SWAP_FETCH_SUCCESS";
export const ADDRESS_COIN_SWAP_FETCH_FAILURE = "ADDRESS_COIN_SWAP_FETCH_FAILURE";

export const FETCHING_ADDRESS_CLUSTER_HISTORY = "FETCHING_ADDRESS_CLUSTER_HISTORY";
export const ADDRESS_CLUSTER_HISTORY_FETCH_SUCCESS = "ADDRESS_CLUSTER_HISTORY_FETCH_SUCCESS";
export const ADDRESS_CLUSTER_HISTORY_FETCH_FAILURE = "ADDRESS_CLUSTER_HISTORY_FETCH_FAILURE";

export const FETCHING_ADDRESS_WALLET_MUTUAL_TRANSACTIONS_BY_SATOSHI =
  "FETCHING_ADDRESS_WALLET_MUTUAL_TRANSACTIONS_BY_SATOSHI";
export const ADDRESS_WALLET_MUTUAL_TRANSACTIONS_BY_SATOSHI_FETCH_SUCCESS =
  "ADDRESS_WALLET_MUTUAL_TRANSACTIONS_BY_SATOSHI_FETCH_SUCCESS";
export const ADDRESS_WALLET_MUTUAL_TRANSACTIONS_BY_SATOSHI_FETCH_FAILURE =
  "ADDRESS_WALLET_MUTUAL_TRANSACTIONS_BY_SATOSHI_FETCH_FAILURE";

export const ADDRESS_TRANSACTIONS_SCROLL_TOP = "ADDRESS_TRANSACTIONS_SCROLL_TOP";

/* Wallet actions */
export const FETCHING_WALLET_SUMMARY = "FETCHING_WALLET_SUMMARY";
export const WALLET_SUMMARY_FETCH_SUCCESS = "WALLET_SUMMARY_FETCH_SUCCESS";
export const WALLET_SUMMARY_FETCH_FAILURE = "WALLET_SUMMARY_FETCH_FAILURE";
export const WALLET_SUMMARY_PURGE = "WALLET_SUMMARY_PURGE";
/* More summary Wallet actions */
export const FETCHING_MORE_WALLET_SUMMARY = "FETCHING_MORE_WALLET_SUMMARY";
export const MORE_WALLET_SUMMARY_FETCH_SUCCESS = "MORE_WALLET_SUMMARY_FETCH_SUCCESS";
export const MORE_WALLET_SUMMARY_FETCH_FAILURE = "MORE_WALLET_SUMMARY_FETCH_FAILURE";

export const FETCHING_WALLET_TRANSACTIONS = "FETCHING_WALLET_TRANSACTIONS";
export const WALLET_TRANSACTIONS_FETCH_SUCCESS = "WALLET_TRANSACTIONS_FETCH_SUCCESS";
export const WALLET_TRANSACTIONS_FETCH_FAILURE = "WALLET_TRANSACTIONS_FETCH_FAILURE";
export const WALLET_TRANSACTIONS_PURGE = "WALLET_TRANSACTIONS_PURGE";

export const FETCHING_WALLET_TRANSACTIONS_BY_SATOSHI = "FETCHING_WALLET_TRANSACTIONS_BY_SATOSHI";
export const WALLET_TRANSACTIONS_BY_SATOSHI_FETCH_SUCCESS =
  "WALLET_TRANSACTIONS_BY_SATOSHI_FETCH_SUCCESS";
export const WALLET_TRANSACTIONS_BY_SATOSHI_FETCH_FAILURE =
  "WALLET_TRANSACTIONS_BY_SATOSHI_FETCH_FAILURE";

export const WALLET_TRANSACTIONS_SWAP_ORDER = "WALLET_TRANSACTIONS_SWAP_ORDER";
export const WALLET_TRANSACTIONS_SET_ORDER = "WALLET_TRANSACTIONS_SET_ORDER";

export const WALLET_TRANSACTIONS_SET_DATE_RANGE = "WALLET_TRANSACTIONS_SET_DATE_RANGE";

export const FETCHING_WALLET_MENTIONS = "FETCHING_WALLET_TAGS";
export const WALLET_MENTIONS_FETCH_SUCCESS = "WALLET_TAGS_FETCH_SUCCESS";
export const WALLET_MENTIONS_FETCH_FAILURE = "WALLET_TAGS_FETCH_FAILURE";
export const WALLET_MENTIONS_PURGE = "WALLET_MENTIONS_PURGE";

export const FETCHING_WALLET_ADDRESSES = "FETCHING_WALLET_ADDRESSES";
export const WALLET_ADDRESSES_FETCH_SUCCESS = "WALLET_ADDRESSES_FETCH_SUCCESS";
export const WALLET_ADDRESSES_FETCH_FAILURE = "WALLET_ADDRESSES_FETCH_FAILURE";

export const FETCHING_WALLET_ASSOCIATIONS = "FETCHING_WALLET_ASSOCIATIONS";
export const WALLET_ASSOCIATIONS_FETCH_SUCCESS = "WALLET_ASSOCIATIONS_FETCH_SUCCESS";
export const WALLET_ASSOCIATIONS_FETCH_FAILURE = "WALLET_ASSOCIATIONS_FETCH_FAILURE";

export const WALLET_FETCHING_SENT_WALLETS = "WALLET_FETCHING_SENT_WALLETS";
export const WALLET_SENT_WALLETS_FETCH_SUCCESS = "WALLET_SENT_WALLETS_FETCH_SUCCESS";
export const WALLET_SENT_WALLETS_TOGGLE_FILTER = "WALLET_SENT_WALLETS_TOGGLE_FILTER";

export const WALLET_FETCHING_RECEIVED_WALLETS = "WALLET_FETCHING_RECEIVED_WALLETS";
export const WALLET_RECEIVED_WALLETS_FETCH_SUCCESS = "WALLET_RECEIVED_WALLETS_FETCH_SUCCESS";
export const WALLET_RECEIVED_WALLETS_TOGGLE_FILTER = "WALLET_RECEIVED_WALLETS_TOGGLE_FILTER";

export const WALLET_TOGGLE_SENT_FILTER = "WALLET_TOGGLE_SENT_FILTER";
export const WALLET_TOGGLE_RECEIVED_FILTER = "WALLET_TOGGLE_RECEIVED_FILTER";

export const WALLET_SET_NAMES = "WALLET_SET_NAMES";
export const SET_WALLET_MAIN_NAME = "SET_WALLET_MAIN_NAME";
export const WALLET_SET_CUSTOM_TAG = "WALLET_SET_CUSTOM_TAG";
export const WALLET_REMOVE_CUSTOM_TAG = "WALLET_REMOVE_CUSTOM_TAG";
export const WALLET_RISK_SCORE = "WALLET_RISK_SCORE";
export const WALLET_TRANSACTIONS_SCROLL_TOP = "WALLET_TRANSACTIONS_SCROLL_TOP";
export const WALLET_ADDRESSES_SCROLL_TOP = "WALLET_ADDRESSES_SCROLL_TOP";

export const FETCHING_WALLET_MUTUAL_WALLETS = "FETCHING_WALLET_MUTUAL_WALLETS";
export const WALLET_MUTUAL_WALLETS_FETCH_SUCCESS = "WALLET_MUTUAL_WALLETS_FETCH_SUCCESS";
export const WALLET_MUTUAL_WALLETS_FETCH_FAILURE = "WALLET_MUTUAL_WALLETS_FETCH_FAILURE";

export const FETCHING_WALLET_WALLET_MUTUAL_TRANSACTIONS =
  "FETCHING_WALLET_WALLET_MUTUAL_TRANSACTIONS";
export const WALLET_WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS =
  "WALLET_WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS";
export const WALLET_WALLET_MUTUAL_TRANSACTIONS_FETCH_FAILURE =
  "WALLET_WALLET_MUTUAL_TRANSACTIONS_FETCH_FAILURE";

export const WALLET_MUTUAL_TRANSACTIONS_SWAP_ORDER = "WALLET_MUTUAL_TRANSACTIONS_SWAP_ORDER";

export const WALLET_MUTUAL_WALLETS_TRANSACTIONS_SET_LARGEST_FIRST =
  "WALLET_MUTUAL_WALLETS_TRANSACTIONS_SET_LARGEST_FIRST";
export const WALLET_MUTUAL_WALLETS_TRANSACTIONS_SET_DATE_RANGE =
  "WALLET_MUTUAL_WALLETS_TRANSACTIONS_SET_DATE_RANGE";
export const WALLET_UNCLUSTERED_FETCH_SUCCESS = "WALLET_UNCLUSTERED_FETCH_SUCCESS";

/* Peel Chain */
export const FETCHING_WALLET_PEEL_CHAIN = "FETCHING_WALLET_PEEL_CHAIN";
export const WALLET_PEEL_CHAIN_FETCH_SUCCESS = "WALLET_PEEL_CHAIN_FETCH_SUCCESS";

/* Transaction actions */
export const FETCHING_TRANSACTION = "FETCHING_TRANSACTION";
export const TRANSACTION_FETCH_SUCCESS = "TRANSACTION_FETCH_SUCCESS";
export const TRANSACTION_FETCH_FAILURE = "TRANSACTION_FETCH_FAILURE";
export const TRANSACTION_ADDRESS_NOTE_ADD_SUCCESS = "TRANSACTION_ADDRESS_NOTE_ADD_SUCCESS";
export const TRANSACTION_ADDRESS_NOTE_ADD_FAILURE = "TRANSACTION_ADDRESS_NOTE_ADD_FAILURE";
export const FETCH_TRANSACTION_CHAIN_SUCCESS = "FETCH_TRANSACTION_CHAIN_SUCCESS";
export const FETCH_TRANSACTION_CHAIN_FAILURE = "FETCH_TRANSACTION_CHAIN_FAILURE";

/* BSA mentions */
export const BSA_KEY_FETCH = "BSA_KEY_FETCH";
export const BSA_FETCH_SUCCESS = "BSA_FETCH_SUCCESS";
export const BSA_FETCH_FAILURE = "BSA_FETCH_FAILURE";

/* Wallet mutual transaction actions */
export const GRAPH_MUTUAL_TRANSACTION_CHANGE_ORDER = "GRAPH_MUTUAL_TRANSACTION_CHANGE_ORDER";

export const WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS = "WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS";

/* Custom wallet actions */
export const WALLET_SET_PROCESS = "CUSTOM_WALLET_SET_PROCESS";

export const CUSTOM_WALLET_SET_NAME = "CUSTOM_WALLET_SET_NAME";

export const FETCHING_CUSTOM_WALLET_SUMMARY = "FETCHING_CUSTOM_WALLET_SUMMARY";
export const CUSTOM_WALLET_SUMMARY_FETCH_SUCCESS = "CUSTOM_WALLET_SUMMARY_FETCH_SUCCESS";
export const CUSTOM_WALLET_SUMMARY_FETCH_FAILURE = "CUSTOM_WALLET_SUMMARY_FETCH_FAILURE";

export const FETCHING_CUSTOM_WALLET_TRANSACTIONS = "FETCHING_CUSTOM_WALLET_TRANSACTIONS";
export const CUSTOM_WALLET_TRANSACTIONS_FETCH_SUCCESS = "CUSTOM_WALLET_TRANSACTIONS_FETCH_SUCCESS";

export const CUSTOM_WALLET_TRANSACTIONS_SET_DATE_RANGE =
  "CUSTOM_WALLET_TRANSACTIONS_SET_DATE_RANGE";

export const FETCHING_CUSTOM_WALLET_MENTIONS = "FETCHING_CUSTOM_WALLET_MENTIONS";
export const CUSTOM_WALLET_MENTIONS_FETCH_SUCCESS = "CUSTOM_WALLET_MENTIONS_FETCH_SUCCESS";
export const CUSTOM_WALLET_MENTIONS_FETCH_FAILURE = "CUSTOM_WALLET_MENTIONS_FETCH_FAILURE";

export const FETCHING_CUSTOM_WALLET_ADDRESSES = "FETCHING_CUSTOM_WALLET_ADDRESSES";
export const CUSTOM_WALLET_ADDRESSES_FETCH_SUCCESS = "CUSTOM_WALLET_ADDRESSES_FETCH_SUCCESS";

export const CUSTOM_WALLET_TRANSACTIONS_SWAP_ORDER = "CUSTOM_WALLET_TRANSACTIONS_SWAP_ORDER";

export const CUSTOM_WALLET_ADD_ADDRESSES = "CUSTOM_WALLET_ADD_ADDRESSES";
export const CUSTOM_WALLET_DELETE_ADDRESS = "CUSTOM_WALLET_DELETE_ADDRESS";

export const DELETING_CUSTOM_WALLET = "DELETING_CUSTOM_WALLET";
export const CUSTOM_WALLET_DELETE_SUCCESS = "CUSTOM_WALLET_DELETE_SUCCESS";

export const SAVING_CUSTOM_WALLET = "SAVING_CUSTOM_WALLET";
export const CUSTOM_WALLET_SAVE_SUCCESS = "CUSTOM_WALLET_SAVE_SUCCESS";
export const CUSTOM_WALLET_OPEN_DELETE_WALLET_MODAL = "CUSTOM_WALLET_OPEN_DELETE_WALLET_MODAL";
export const CUSTOM_WALLET_CLOSE_DELETE_WALLET_MODAL = "CUSTOM_WALLET_CLOSE_DELETE_WALLET_MODAL";

/* Notification actions */
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";

/* Authentication actions */
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const TWO_FACTOR_REGISTRATION_SUCCESS = "TWO_FACTOR_REGISTRATION_SUCCESS";
export const TWO_FACTOR_AUTHENTICATION_SUCCESS = "TWO_FACTOR_AUTHENTICATION_SUCCESS";
export const TWO_FACTOR_AUTHENTICATION_FAILURE = "TWO_FACTOR_AUTHENTICATION_FAILURE";
export const TWO_FACTOR_PHONE_SET_SUCCESSFUL = "TWO_FACTOR_PHONE_SET_SUCCESSFUL";
export const LOGIN_DISABLED = "LOGIN_DISABLED";
export const LOGIN_OVERLOAD = "LOGIN_OVERLOAD";
export const LOGIN_TRIAL_ENDED = "LOGIN_TRIAL_ENDED";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_DELETED = "LOGIN_DELETED";
export const LOGGING_IN = "LOGGING_IN";
export const LOGGING_OUT = "LOGGING_OUT";
export const LOGGING_OUT_AUTOMATICALLY = "LOGGING_OUT_AUTOMATICALLY";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGIN_CSRF_ERROR = "LOGIN_CSRF_ERROR";
export const DISMISS_LOGIN_FAILURE_NOTIFICATION = "DISMISS_LOGIN_FAILURE_NOTIFICATION";

/* Graph actions */
export const FETCHING_GRAPH = "FETCHING_GRAPH";
export const SETTING_CURRENT_GRAPH = "SETTING_CURRENT_GRAPH";
export const GRAPH_FETCH_SUCCESS = "GRAPH_FETCH_SUCCESS";
export const GRAPH_FETCH_FAILURE = "GRAPH_FETCH_FAILURE";

export const FETCHING_GRAPH_SEARCH_RESULTS = "FETCHING_GRAPH_SEARCH_RESULTS";
export const GRAPH_SEARCH_SUCCESS = "GRAPH_SEARCH_SUCCESS";
export const GRAPH_SEARCH_FAILURE = "GRAPH_SEARCH_FAILURE";
export const GRAPH_SEARCH_LOADING = "GRAPH_SEARCH_LOADING";

export const GRAPH_SEARCH_ASSOCIATIONS_SUCCESS = "GRAPH_SEARCH_ASSOCIATIONS_SUCCESS";

export const GRAPH_UPDATE_GRAPH_IN_REDUX = "GRAPH_UPDATE_GRAPH_IN_REDUX";

export const GRAPH_CHANGE_CASE_NUMBER = "GRAPH_CHANGE_CASE_NUMBER";
export const GRAPH_CHANGE_TOGGLE_STATE = "GRAPH_CHANGE_TOGGLE_STATE";
export const GRAPH_CHANGE_DESCRIPTION = "GRAPH_CHANGE_DESCRIPTION";
export const GRAPH_CHANGE_DATES = "GRAPH_CHANGE_DATES";

export const GRAPH_SELECT_SINGLE_NODE = "GRAPH_SELECT_SINGLE_NODE";

export const GRAPH_PEEL_CHAIN_STATUS_SUCCESS = "GRAPH_PEEL_CHAIN_STATUS_SUCCESS";

export const GRAPH_CHANGE_MAIN_TABS_KEY = "GRAPH_CHANGE_MAIN_TABS_KEY";
export const GRAPH_CHANGE_DATA_TABS_KEY = "GRAPH_CHANGE_DATA_TABS_KEY";
export const GRAPH_SET_CURRENT_QUERY = "GRAPH_SET_CURRENT_QUERY";
export const GRAPH_SET_OUTPUT_WALLET_ID = "GRAPH_SET_OUTPUT_WALLET_ID";

export const GRAPH_HANDLE_SAVE = "GRAPH_HANDLE_SAVE";
export const GRAPH_SAVE_SUCCESS = "GRAPH_SAVE_SUCCESS";
export const GRAPH_SAVE_FAILURE = "GRAPH_SAVE_FAILURE";

export const GRAPH_HANDLE_DELETE = "GRAPH_HANDLE_DELETE";
export const GRAPH_DELETE_SUCCESS = "GRAPH_DELETE_SUCCESS";
export const GRAPH_DELETE_FAILURE = "GRAPH_DELETE_FAILURE";

export const GRAPH_UPDATE_EDITOR_STATE = "GRAPH_UPDATE_EDITOR_STATE";
export const GRAPH_OPEN_EXPLORER_MODAL = "GRAPH_OPEN_EXPLORER_MODAL";

export const GRAPH_CLOSE_EXPLORER_MODAL = "GRAPH_CLOSE_EXPLORER_MODAL";
export const GRAPH_EXPLORER_MODAL_GO_BACK = "GRAPH_EXPLORER_MODAL_PREVIOUS";
export const GRAPH_EXPLORER_MODAL_GO_FORWARD = "GRAPH_EXPLORER_MODAL_NEXT";

export const GRAPH_UNDO_STACK_ADD = "GRAPH_UNDO_STACK_ADD";
export const GRAPH_UNDO_STACK_GO_BACK = "GRAPH_UNDO_STACK_GO_BACK";
export const GRAPH_UNDO_STACK_GO_FORWARD = "GRAPH_UNDO_STACK_GO_FORWARD";

export const GRAPH_ADD_WALLET_TO_GRAPH = "GRAPH_ADD_WALLET_TO_GRAPH";
export const GRAPH_DELETE_WALLET_FROM_GRAPH = "GRAPH_DELETE_WALLET_FROM_GRAPH";

export const SET_GRAPH_CATEGORY_COLORS = "SET_GRAPH_CATEGORY_COLORS";

export const GRAPH_IMPORT_GRAPH = "GRAPH_IMPORT_GRAPH";

export const GRAPH_SHOW_NOTES_SET = "GRAPH_SHOW_NOTES_SET";

/* Search actions */
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const FUZZY_SEARCH_SUCCESS = "FUZZY_SEARCH_SUCCESS";

export const SWAP_CURRENCY = "SWAP_CURRENCY";
export const FETCH_CURRENCY_SUCCESS = "FETCH_CURRENCY_SUCCESS";

// Ethereum
export const FETCHING_TOKEN_TRANSFERS = "FETCHING_TOKEN_TRANSFERS";
export const TOKEN_TRANSFERS_FETCH_SUCCESS = "TOKEN_TRANSFERS_FETCH_SUCCESS";
export const TOKEN_TRANSFERS_FETCH_FAILURE = "TOKEN_TRANSFERS_FETCH_FAILURE";

export const FETCHING_INTERNAL_TRANSACTIONS = "FETCHING_INTERNAL_TRANSACTIONS";
export const INTERNAL_TRANSACTIONS_FETCH_SUCCESS = "INTERNAL_TRANSACTIONS_FETCH_SUCCESS";
export const INTERNAL_TRANSACTIONS_FETCH_FAILURE = "INTERNAL_TRANSACTIONS_FETCH_FAILURE";

export const TRANSACTION_SUMMARY_FETCH_SUCCESS = "TRANSACTION_SUMMARY_FETCH_SUCCESS";
export const TRANSACTION_SUMMARY_FETCH_FAILURE = "TRANSACTION_SUMMARY_FETCH_FAILURE";

export const ADDRESS_GRAPH_TRANSACTIONS_FETCH_SUCCESS = "ADDRESS_GRAPH_TRANSACTIONS_FETCH_SUCCESS";
export const ADDRESS_TRANSACTION_TOKEN_FETCHING = "ADDRESS_TRANSACTION_TOKEN_FETCHING";
export const ADDRESS_TRANSACTION_TOKEN_FAILURE = "ADDRESS_TRANSACTION_TOKEN_FAILURE";
export const ADDRESS_TRANSACTION_TOKEN_FETCH_SUCCESS = "ADDRESS_TRANSACTION_TOKEN_FETCH_SUCCESS";
export const ADDRESS_TOKEN_FETCHING = "ADDRESS_TOKEN_FETCHING";
export const ADDRESS_TOKEN_FAILURE = "ADDRESS_TOKEN_FAILURE";
export const ADDRESS_TOKEN_FETCH_SUCCESS = "ADDRESS_TOKEN_FETCH_SUCCESS";

export const ADDRESS_INTERNAL_TRANSACTIONS_FETCHING = "ADDRESS_INTERNAL_TRANSACTIONS_FETCHING";
export const ADDRESS_INTERNAL_TRANSACTIONS_FAILURE = "ADDRESS_INTERNAL_TRANSACTIONS_FAILURE";
export const ADDRESS_INTERNAL_TRANSACTIONS_FETCH_SUCCESS =
  "ADDRESS_INTERNAL_TRANSACTIONS_FETCH_SUCCESS";

export const FETCHING_MUTUAL_ADDRESS_TRANSACTIONS = "FETCHING_MUTUAL_ADDRESS_TRANSACTIONS";
export const MUTUAL_ADDRESS_TRANSACTIONS_FETCH_SUCCESS =
  "MUTUAL_ADDRESS_TRANSACTIONS_FETCH_SUCCESS";
export const MUTUAL_ADDRESS_TRANSACTIONS_FETCH_FAILURE =
  "MUTUAL_ADDRESS_TRANSACTIONS_FETCH_FAILURE";
export const FETCHING_MUTUAL_ADDRESS_TOKENS = "FETCHING_MUTUAL_ADDRESS_TOKENS";
export const MUTUAL_ADDRESS_TOKENS_FETCH_SUCCESS = "MUTUAL_ADDRESS_TOKENS_FETCH_SUCCESS";
export const MUTUAL_ADDRESS_TOKENS_FETCH_FAILURE = "MUTUAL_ADDRESS_TOKENS_FETCH_FAILURE";

export const FETCHING_GRAPH_ADDRESS_TRANSACTIONS = "FETCHING_GRAPH_ADDRESS_TRANSACTIONS";
export const GRAPH_ADDRESS_TRANSACTIONS_FETCH_SUCCESS = "GRAPH_ADDRESS_TRANSACTIONS_FETCH_SUCCESS";

// MESSAGES
export const MESSAGE_MARKING_AS_READ = "MESSAGE_MARKING_AS_READ";
export const MESSAGE_MARK_AS_READ = "MESSAGE_MARK_AS_READ";
export const MESSAGE_MARK_AS_READ_FAILURE = "MESSAGE_MARK_AS_READ_FAILURE";
export const MESSAGE_IGNORE = "MESSAGE_IGNORE";
export const MESSAGE_DELETE = "MESSAGE_DELETE";
export const SET_MESSAGE_SHOW = "SET_MESSAGE_SHOW";

// RISK
export const FETCHING_RISK = "FETCHING_RISK";
export const RISK_SUCCESS = "RISK_SUCCESS";
export const RISK_FAILURE = "RISK_FAILURE";
