import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getAddressSummary } from "../../selectors/ethereum/address";
import { significantDecimals } from "../../helpers";

const DropDownTitle = ({ token, address }) => {
  const [img, setImg] = useState(null);
  const { currentBalance } = useSelector(state => getAddressSummary(state, address, true));
  useEffect(() => {
    let path = "";
    try {
      path = require(`../../../img/token_img/0x${token.token_account_id}.png`);
    } catch (e) {
      path = require(`../../../img/ethereum.svg`);
    }
    setImg(path);
  }, [token]);

  const value =
    token.token_account_id === "0" && currentBalance
      ? currentBalance
      : token.token_balance / Math.pow(10, token.decimals);
  return (
    <>
      <img
        style={{
          height: "25px",
          width: "25px",
          marginRight: "5px",
          display: "inline-block"
        }}
        src={img}
        alt=""
      />{" "}
      <b
        style={{
          marginRight: "15px",
          height: "auto",
          display: "inline-block",
          fontWeight: "600"
        }}
      >
        {(token.symbol || token.name || token.token_account_id).substring(0, 15)}
      </b>
      <p
        style={{
          height: "auto",
          color: "green",
          display: "inline-block",
          fontSize: 14,
          fontWeight: 600,
          paddingTop: 0,
          paddingBottom: 0
        }}
      >
        {value &&
          Number(significantDecimals(value, 3)).toLocaleString("en", { maximumFractionDigits: 10 })}
      </p>
    </>
  );
};

export default DropDownTitle;
