/*
 * Copyright 2018-2021  DecisionQ Information Operations, Inc. All Rights Reserved.
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";

// TODO find the approate ratio between fontsize and box size
export const CustomCheckbox = ({
  onClick,
  value,
  loading,
  width = "20px",
  height = "20px",
  style = {
    marginLeft: "25px",
    marginRight: "5px",
    display: "inline-block",
    height: height,
    width: width,
    border: "2px solid var(--base-color)",
    verticalAlign: "middle",
    textAlign: "center",
    fontFamily: "Quicksand",
    cursor: "pointer"
  }
}) => {
  return (
    <div onClick={onClick} style={style}>
      {value && !loading ? (
        <FontAwesomeIcon
          style={{ color: "var(--base-color)", height: "100%", fontSize: "12px" }}
          icon={faCheck}
        />
      ) : loading ? (
        <FontAwesomeIcon
          style={{ color: "var(--base-color)", height: "100%", fontSize: "12px" }}
          icon={faSpinner}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
