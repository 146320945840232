import React, { useCallback, useState } from "react";
import { Col, DropdownButton, Grid, MenuItem, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { getCurrency } from "../../selectors/currency";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { RiskScoreBase } from "../Risk/RiskScoreBase";
import { getAddressRisk } from "../../selectors/address";
import { getTransactionRisk } from "../../selectors/transaction";
import { createRiskFetch } from "../../actions/actionCreatorFactories";
import ClientModeConfig from "../ClientModeConfig";
import { getClientMode } from "../../selectors/applicationConfig";
import { getWalletNameHelper, getWalletRisk } from "../../selectors/wallet";
import { Searchbar } from "../Searchbar";
import axios, { SEARCH_API } from "../../api";
import { getEmail } from "../../selectors/authentication";

const selectorMap = {
  address: getAddressRisk,
  transaction: getTransactionRisk,
  wallet: getWalletRisk
};

const RiskScore = () => {
  const [type, setType] = useState("transaction");
  const [searchTerm, setSearchTerm] = useState(null);
  const [currentEntity, setCurrentEntity] = useState({
    kind: null,
    data: null,
    name: null
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const clientMode = useSelector(state => getClientMode(state));
  const results = useSelector(state => {
    if (!currentEntity.kind || !currentEntity.data) {
      return null;
    }
    return selectorMap[currentEntity.kind]
      ? selectorMap[currentEntity.kind](state, currentEntity.data)
      : null;
  });
  let clientModeConfigObject = null;
  if (clientMode in ClientModeConfig) {
    clientModeConfigObject = ClientModeConfig[clientMode];
  }
  const email = useSelector(state => getEmail(state));

  const dispatch = useDispatch();

  /**
   * Searches the value. If there is only one possible result,
   * a redirect to that result is made. If there are multiple,
   * a redirect to the partial search page is made. Otherwise,
   * redirect to 404? (why?) Probably old behavior before
   * partial search is made.
   * @returns {Promise<*[]|[]>}
   * @param value
   * @param currency
   */
  const handleSearch = useCallback(
    async (value, currency) => {
      let searchResults;
      const api = `${SEARCH_API(currency)}`;
      const categories =
        type === "wallet" ? ["wallet", "tags", "user_tags", "address_wallets"].join(",") : type;
      try {
        ({ data: searchResults } = await axios.get(api, {
          params: { query: value, category: categories, limit: 5, no_stats: true }
        }));
      } catch (err) {
        setError(true);
        throw err;
      }
      setSearchTerm(value);
      const queryResults = [];
      searchResults &&
        searchResults.addresses &&
        searchResults.addresses.forEach(({ address, url, tag, contract }) => {
          const addHash = address.split(":").pop();
          queryResults.push({
            type: "Address",
            label: contract || tag || addHash,
            ...(contract && { contract: contract }),
            url: url,
            id: addHash
          });
        });
      searchResults &&
        searchResults.transactions &&
        searchResults.transactions.forEach(transaction => {
          queryResults.push({
            type: "Transaction",
            label: transaction["transaction_hash"],
            url: transaction["url"],
            id: transaction["transaction_hash"]
          });
        });
      searchResults &&
        searchResults.custom_wallets &&
        searchResults.custom_wallets.forEach(({ name, url, walletId }) => {
          queryResults.push({
            type: "Custom Wallet",
            label: getWalletNameHelper(name, email)[0]["name"],
            url: url,
            id: walletId
          });
        });
      // TODO make custom  a list of custom tags need helper function to extract it
      searchResults &&
        searchResults.wallets &&
        searchResults.wallets.forEach(({ name, url, walletId }) => {
          const names = getWalletNameHelper(name, email);
          const custom_tags = names.reduce((filtered, { name, tagType }) => {
            tagType === "customTag" && filtered.push(name);
            return filtered;
          }, []);
          queryResults.push({
            type: "Wallet",
            label: getWalletNameHelper(name, email, true)[0]["name"],
            ...(custom_tags && { custom: custom_tags }),
            url: url,
            id: walletId
          });
        });
      return queryResults;
    },
    [type]
  );

  const handleSelectOption = useCallback(
    async option => {
      console.log(option);
      if (!option.type) {
        // if not valid selected type do nothing
        return;
      }
      try {
        setError(false);
        setLoading(true);
        await dispatch(createRiskFetch(type, selectorMap[type])(option.id));
        setCurrentEntity({
          kind: type,
          data: option.id,
          name: option.label
        });
        setSearchTerm(option.label);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setCurrentEntity({
          kind: type,
          data: option.id,
          name: option.label
        });
        setError(true);
        setLoading(false);
      }
    },
    [type]
  );

  const useStyles = makeStyles(theme => ({
    popper: {
      zIndex: 10101
    },
    inputField: {
      fontSize: 14
      // backgroundColor: "white"
    },
    textField: {
      "& .MuiOutlinedInput-root": {
        paddingLeft: 5,
        backgroundColor: "white",
        height: 40
      }
    },
    noBorder: {
      border: "2px solid #525452"
    },
    adornment: {
      // backgroundColor: "gray",
      padding: "11px 10px",
      marginLeft: -10,
      fontSize: 14,
      backgroundColor: theme.palette.divider,
      borderTopLeftRadius: theme.shape.borderRadius + "px",
      borderBottomLeftRadius: theme.shape.borderRadius + "px",
      color: "var(--secondary-color-dark-hover) !important",
      cursor: "pointer"
    }
  }));
  const classes = useStyles();

  const displayType = type
    ? type === "wallet"
      ? clientModeConfigObject.cap_word_for_collection
      : type.charAt(0).toUpperCase() + type.slice(1)
    : "";
  const changeType = type => {
    setType(type);
    setSearchTerm(searchTerm === "" ? null : "");
  };

  return (
    <div style={{ marginTop: "30px" }}>
      <Grid>
        <Row>
          <Col lg={4} style={{ padding: "0", marginBottom: "30px" }}>
            <p style={{ display: "block", fontSize: "25px", fontWeight: "700", color: "#666" }}>
              {" "}
              Select Type
            </p>

            <DropdownButton
              className="blueButton"
              style={{
                fontFamily: "Quicksand",
                paddingBottom: "3px"
              }}
              title={<> {displayType} </>}
              id={"risk-score-type"}
            >
              <MenuItem
                style={{
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "700",
                  fontFamily: "Quicksand"
                }}
                eventKey="Transaction"
                onSelect={() => changeType("transaction")}
              >
                {" "}
                Transaction{" "}
              </MenuItem>
              <MenuItem
                style={{
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "700",
                  fontFamily: "Quicksand"
                }}
                eventKey="Address"
                onSelect={() => changeType("address")}
              >
                {" "}
                Address{" "}
              </MenuItem>
              <MenuItem
                style={{
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "700",
                  fontFamily: "Quicksand"
                }}
                eventKey="entity"
                onSelect={() => changeType("wallet")}
              >
                {clientModeConfigObject ? clientModeConfigObject.cap_word_for_collection : "Wallet"}
              </MenuItem>
            </DropdownButton>
          </Col>
        </Row>
        <Row>
          <Col lg={8} style={{ padding: "0" }}>
            <p style={{ display: "block", fontSize: "25px", fontWeight: "700", color: "#666" }}>
              {" "}
              Enter the {displayType}
            </p>
            <Searchbar
              label={displayType}
              query={handleSearch}
              handleSelect={handleSelectOption}
              customClasses={classes}
              manualValue={searchTerm}
              type={type}
            />
          </Col>
        </Row>
      </Grid>
      <div style={{ marginTop: 20 }}>
        {error || (results && results.level === null) ? (
          <p style={{ fontSize: "15px" }}> No results found for {currentEntity.data}</p>
        ) : (
          <Col style={{ padding: "0" }}>
            {loading ? (
              <div style={{ position: "absolute", left: "900px" }}>
                <CircularProgress style={{ color: "var(--secondary-color)" }} />
              </div>
            ) : (
              results && (
                <>
                  {results["risk_level"] && (
                    <p
                      style={{
                        fontFamily: "Quicksand",
                        fontSize: "28px",
                        fontWeight: "500",
                        color: "#666",
                        marginTop: "30px",
                        textAlign: "center"
                      }}
                    >
                      {currentEntity.name}{" "}
                    </p>
                  )}
                  {currentEntity && currentEntity.kind && (
                    <RiskScoreBase type={currentEntity.kind.toLowerCase()} results={results} />
                  )}
                </>
              )
            )}
          </Col>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    currency: getCurrency(state)
  };
};

export default connect(
  mapStateToProps,
  null
)(RiskScore);
