// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { List, Record } from "immutable";

import {
  Mentions,
  Transactions,
  WalletAddress,
  WalletSummary
} from "./sharedRecords";
import { getWalletRecord } from "./wallet";

/*
Custom Wallet State
CustomWallet: Map<number, CustomWalletRecord>

CustomWalletRecord: {
  summary: WalletSummary: {
    addressCount: number,
    inputCount: number,
    inputSatoshi: number,
    outputCount: number,
    outputSatoshi: number,
    firstTransaction: number,
    lastTransaction: number,
    transactionCount: number,
    balance: number,
    anchorAddress: string,
    primaryTag: string | null,
    originalTag: string | null,
  },
  transactions: Transactions: {
    transactionMap: Map<string, Transaction>,
    newestFirst: List<number>,
    newestFirstNextKey: number,
    oldestFirst: List<number>,
    oldestFirstNextKey: number,
    order: -1 | 1,
    startDate: number | null,
    endDate: number | null,
  },
  mentions: Mentions: {
    mentions: List<AddressMention>,
  },
  addresses: CustomWalletAddresses: {
    addressRecords: List<WalletAddress>,
    dirty: bool,
  },
  showAddAddressModal: bool,
  showDeleteWalletModal: bool,
}

WalletAddress: {
  address: '',
  addressId: 0,
  transactionCount: 0,
}

AddressMention: {
  address: string,
  username: string,
  onlineAccountId: string,
  sourceName: string,
  hasContent: bool,
}

Transaction: {
  transactionHash: string,
  transactionId: number,
  satoshi: number,
  timestamp: number,
}
 */

const CustomWalletAddresses = Record({
  addressRecords: List() // list of wallet addresses
});

export const CustomWalletRecord = Record({
  summary: new WalletSummary(),
  transactions: new Transactions(),
  mentions: new Mentions(),
  addresses: new CustomWalletAddresses(),
  processed: false
});

/**
 * @param state
 * @param data a list of addresses { addressId: number, address: string, transactionCount: number }
 * @param walletId custom wallet number
 * @param dirty whether adding address makes custom wallet dirty
 */
export const addAddresses = (state, { data, walletId, processed }) => {
  let walletRecord = getWalletRecord(state, walletId);
  const newAddressRecords = data.map(address => new WalletAddress(address));
  walletRecord = walletRecord.updateIn(
    ["addresses", "addressRecords"],
    addressRecords => addressRecords.push(...newAddressRecords)
  );
  walletRecord = walletRecord.set("processed", processed);
  return state.set(walletId, walletRecord);
};

export const deleteAddress = (state, { address, walletId }) => {
  let walletRecord = getWalletRecord(state, walletId);
  walletRecord = walletRecord
    .updateIn(["addresses", "addressRecords"], addresses =>
      addresses.filter(curAddress => curAddress.get("address") !== address)
    )
    .setIn(["addresses", "dirty"], true);

  return state.set(walletId, walletRecord);
};

/**
 * Used upon successfully saving a custom wallet and clears values
 * so they can be repopulated
 * @param state
 * @param walletId
 * @returns {*}
 */
export const clearWalletData = (state, { walletId }) => {
  const addresses = state.getIn([walletId, "addresses"]).set("dirty", false);
  // TODO: why is the following even needed?
  return state.set(
    walletId,
    new CustomWalletRecord().set("addresses", addresses)
  );
};

/**
 * Sets the processed field for the wallet to false
 *
 * Processed is the flag for showing the processing loading circle on custom wallet pages
 * Also controls when wallet components should be reloaded
 * @param state redux state
 * @param walletId
 * @param processed
 * @returns {*}
 */
export const setProcessed = (state, { walletId, processed }) => {
  let walletRecord = getWalletRecord(state, walletId);

  walletRecord = walletRecord.set("processed", processed);
  return state.set(walletId, walletRecord);
};

/**
 * Sets the custom wallet by changing the primary tag (almost treating it as a user_wallet_tag)
 * @param state
 * @param walletId
 * @param newName new name of the wallet
 * @returns {*}
 */
export const setCustomWalletName = (state, { walletId, newName }) => {
  let walletRecord = getWalletRecord(state, walletId);
  walletRecord = walletRecord.setIn(["name", "name"], newName);
  return state.set(walletId, walletRecord);
};

/**
 * Used upon successfully deleting a custom wallet
 * @param state
 * @param walletId
 * @returns {*}
 */
export const deleteWalletInfo = (state, { walletId }) => state.delete(walletId);
