import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getFeature } from "../../selectors/features";
import { getCurrency } from "../../selectors/currency";
import { getRole, getTwoFAConfigured } from "../../selectors/authentication";
import { getCoins } from "../../selectors/coins";
import { SWAP_CURRENCY } from "../../actions/actionNames";
import { Col, Grid, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ReactCodeInput from "react-code-input";
import { Button } from "@material-ui/core";
import axios from "../../api";
import { registerTwoFactorCode } from "../../actions/authentication";
import { Redirect } from "react-router-dom";

const TwoFaRegister = ({ register, registered }) => {
  const [code, setCode] = useState(null);

  const getCode = async () => {
    const { data } = await axios.get("/api/v2/2fa");
    setCode(data);
  };
  useEffect(() => {
    getCode();
  }, []);

  const registerCode = async () => {};
  return (
    <>
      {registered ? (
        <Redirect to="/2fa-enter-sms" />
      ) : (
        <Grid>
          <Helmet>
            <title>2FA Enter</title>
          </Helmet>
          <Row style={{ paddingTop: "50px" }}>
            <Col lg={12}>
              <Row style={{ textAlign: "center", marginBottom: "20px" }}>
                <p style={{ fontSize: "22px" }}> Register For Two Factor Authentication</p>
              </Row>
              <Row style={{ textAlign: "center", marginBottom: "20px" }}>
                <p style={{ fontSize: "16px" }}>
                  {" "}
                  Scan the QR code below in the Authy App on your smartphone to enable Two Factor
                  Authentication.
                </p>
              </Row>
              <Row style={{ textAlign: "center", marginBottom: "20px" }}>
                {code !== null && <div dangerouslySetInnerHTML={{ __html: code }} />}
              </Row>

              <Row style={{ textAlign: "center", marginBottom: "20px" }}>
                <p style={{ fontSize: "16px" }}>
                  {" "}
                  After registering your code, click Submit below.
                </p>
              </Row>
              <Row style={{ textAlign: "center" }}>
                <Button
                  style={{
                    backgroundColor: "var(--base-color)",
                    color: "white",
                    fontFamily: "Quicksand",
                    fontSize: "12px",
                  }}
                  onClick={() => register()}
                >
                  Submit
                </Button>
              </Row>
            </Col>
          </Row>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    throwExceptionComponentEnabled: getFeature(state, "throw_exception_test"),
    issuesEnabled: getFeature(state, "issues"),
    currency: getCurrency(state),
    isResearcher: getRole(state) === "researcher",
    coinsEnabled: getCoins(state),
    registered: getTwoFAConfigured(state),
  };
};

const mapDispatchToProps = dispatch => ({
  swapCurrency: currency => dispatch({ type: SWAP_CURRENCY, currency }),
  register: () => dispatch(registerTwoFactorCode()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoFaRegister);
