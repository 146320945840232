/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import {
  Alert,
  // Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Glyphicon,
  Grid,
  Modal,
  Row,
  Table,
  Tab,
  Tabs
} from "react-bootstrap";
import {
  faArrowLeft,
  faArrowRight,
  faCog,
  faPlus,
  faSearch,
  faStickyNote,
  faWallet,
  faCircleXmark
} from "@fortawesome/free-solid-svg-icons/index";
import { Link } from "react-router-dom";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoteEditor from "./NoteEditor/index";
import GraphNoteEditor from "./NoteEditor/GraphNoteEditor";
import AddressNoteEditor from "./NoteEditor/AddressNoteEditor";
import TransactionNoteEditor from "./NoteEditor/TransactionNoteEditor";
import WalletNoteEditor from "./NoteEditor/WalletNoteEditor";
import axios, {
  ADDRESS_API,
  NOTES_API,
  TRANSACTION_API,
  WALLET_API,
    CUSTOM_WALLETS_API,
  GRAPH_API
} from "../api";
import { reactRouterHistoryShape, reactRouterMatchShape } from "../prop-types";
import { getCurrency } from "../selectors/currency";
import ReportIssue from "./ReportIssue";
import { getClientMode } from "../selectors/applicationConfig";
import ClientModeConfig from "./ClientModeConfig";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  TextField,
  Box
} from "@material-ui/core";
import { faTimes, faDownload } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import {Autocomplete} from "@material-ui/lab";
import moment from "moment";

class Note extends Component {
  state = {
    title: "",
    provisionalTitle: "",
    addresses: [],
    wallets: [],
    transactions: [],
    graphs: [],
    otherFiles: [],
    addressesChanged: false,
    walletsChanged: false,
    transactionsChanged: false,
    graphsChanged: false,
    otherFilesChanged: false,
    notification: null, // set to true on update failure
    showAddAddressModal: false,
    showAddWalletModal: false,
    showChangeTitleModal: false,
    showAddTransactionModal: false,
    showDeleteNoteModal: false,
    showAddGraphModal: false,
    showAddOtherFileModal: false,
    editorState: EditorState.createEmpty(),
    key: "summaryEditor",
    selectedGraphNote: false,
    selectedAddressNote: false,
    selectedTransactionNote: false,
    selectedWalletNote: false,
    showMergeModal: false,
    addFileName: null,
    otherFileBase64: null,
    addFileNotificationVisible: false,
    fileNotificationSuccess: false,
    addGraphSelection: null
  };

  onImageUpload = async e => {
    const base64 = await this.convert(e.target.files[0]);
    if (base64) {
      var stringLength = base64.length - "data:image/png;base64,".length;
      var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
      var sizeInKb = sizeInBytes / 1000;
      if (sizeInKb < 50000000) {
        this.setState({
          addFileNotificationVisible: true,
          fileNotificationSuccess: true,
          otherFileBase64: base64
        });
      } else {
        this.setState({
          addFileNotificationVisible: true,
          fileNotificationSuccess: false
        });
      }
    } else {
      this.setState({
        addFileNotificationVisible: true,
        fileNotificationSuccess: false
      });
    }
  };

  convert = file => {
    if (["application/vnd.ms-excel", "application/pdf", "text/csv"].includes(file.type)) {
      return new Promise((resolve, reject) => {
        if (file.name.length > 50) {
          const extension = file.name.split(".").pop();
          this.setState({
            addFileName: file.name.slice(0, 50) + "...." + extension
          });
        } else {
          this.setState({ addFileName: file.name });
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result); //base64encoded string
        };
        reader.onerror = error => {
          reject(error);
        };
      });
    } else {
      return null;
    }
  };

  componentDidMount() {
    const { noteId } = this.props.match.params;
    this.fetchNote(noteId);
  }

  componentDidUpdate(prevProps) {
    const { noteId: oldNoteId } = prevProps.match.params;
    const { noteId: newNoteId } = this.props.match.params;
    if (oldNoteId !== newNoteId) {
      this.fetchNote(newNoteId);
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  onEdit = editorState => this.setState({ editorState });

  changeCreateAddressNoteButton = (checkAddress, changedHasNote) => {
    const newAddressState = this.state.addresses.map(address_ => {
      const { address, addressId, hasNote } = address_;
      if (address === checkAddress) {
        return {
          address: address,
          addressId: addressId,
          hasNote: changedHasNote
        };
      }
      return address_;
    });
    this.setState({ addresses: newAddressState });
  };

  changeCreateTransactionNoteButton = (checkTransaction, changedHasNote) => {
    const newTransactionState = this.state.transactions.map(transaction_ => {
      const { transactionId, transaction, hasNote } = transaction_;
      if (transaction === checkTransaction) {
        return {
          transaction: transaction,
          transactionId: transactionId,
          hasNote: changedHasNote
        };
      }
      return transaction_;
    });
    this.setState({ transactions: newTransactionState });
  };

  changeCreateWalletNoteButton = (checkWallet, changedHasNote) => {
    const newWalletState = this.state.wallets.map(wallet_ => {
      const { wallet, tag, hasNote } = wallet_;
      if (wallet === checkWallet) {
        return {
          wallet: wallet,
          tag: tag,
          hasNote: changedHasNote
        };
      }
      return wallet_;
    });
    this.setState({ wallets: newWalletState });
  };

  changeCreateGraphNoteButton = (checkGraphId, changedHasNote) => {
    const newGraphState = this.state.graphs.map(graph_ => {
      const { graphId, caseNumber, hasNote } = graph_;
      if (graphId === checkGraphId) {
        return {
          caseNumber: caseNumber,
          graphId: graphId,
          hasNote: changedHasNote
        };
      }
      return graph_;
    });
    this.setState({ graphs: newGraphState });
  };

  fetchNote = async noteId => {
    try {
      const { data } = await axios.get(
        `${NOTES_API(this.props.currency)}/${noteId}`
      );
      if (data.note.editorContent) {
        const editorState = EditorState.createWithContent(
          convertFromRaw(data.note.editorContent)
        );
        this.setState({
          editorState
        });
      }

      this.mergeWalletModal(data.note.wallets);

      if (this.props.currency !== "ethereum") {
        this.setState({
          title: data.note.title,
          provisionalTitle: data.note.title,
          addresses: data.note.addresses,
          wallets: data.note.wallets,
          transactions: data.note.transactions,
          graphs: data.note.graphs,
          otherFiles: data.note.otherFiles,
          allGraphList: data.note.allGraphList
        });
      } else {
        this.setState({
          title: data.note.title,
          provisionalTitle: data.note.title,
          addresses: data.note.addresses,
          wallets: data.note.wallets,
          transactions: data.note.transactions
        });
      }
    } catch (err) {
      this.props.history.replace("/case-management");
      throw err;
    }
  };

  /*
    If there were wallets that merged, then need to display the mergeWalletModal that display the
    wallets that have been merged as well as indicates whether an action needs to be taken by the user
   */
  mergeWalletModal = walletMergeData => {
    walletMergeData.map(walletData => {
      if (walletData["merge_data"]) {
        this.setState({ showMergeModal: true });
      }
    });
  };

  fetchGraphNote = async (graphId, caseNumber) => {
    try {
      const { noteId } = this.props.match.params;
      this.setState({ selectedGraphNote: [graphId, caseNumber, noteId] });
      this.setState({ key: "graphEditor" });
    } catch (err) {
      this.props.history.replace("/case-management");
      throw err;
    }
  };

  fetchAddressNote = async (addressId, address) => {
    try {
      const { noteId } = this.props.match.params;
      this.setState({ selectedAddressNote: [address, addressId, noteId] });
      this.setState({ key: "addressEditor" });
    } catch (err) {
      this.props.history.replace("/case-management");
      throw err;
    }
  };

  fetchTransactionNote = async (transactionId, transaction) => {
    try {
      const { noteId } = this.props.match.params;
      this.setState({
        selectedTransactionNote: [transaction, transactionId, noteId]
      });
      this.setState({ key: "transactionEditor" });
    } catch (err) {
      this.props.history.replace("/case-management");
      throw err;
    }
  };

  fetchWalletNote = async (walletId, wallet) => {
    try {
      const { noteId } = this.props.match.params;
      this.setState({
        selectedWalletNote: [wallet, walletId, noteId]
      });
      this.setState({ key: "walletEditor" });
    } catch (err) {
      this.props.history.replace("/case-management");
      throw err;
    }
  };

  updateGraphEditorState = (graphId, caseNumber) => {
    this.setState({ selectedGraphNote: false });
    this.fetchGraphNote(graphId, caseNumber);
  };

  updateAddressEditorState = (addressId, address) => {
    this.setState({ selectedAddressNote: false });
    this.fetchAddressNote(addressId, address, "note");
  };

  updateTransactionEditorState = (transactionId, transaction) => {
    this.setState({ selectedTransactionNote: false });
    this.fetchTransactionNote(transactionId, transaction, "note");
  };

  updateWalletEditorState = (walletId, wallet) => {
    this.setState({ selectedWalletNote: null });
    this.fetchWalletNote(walletId, wallet);
  };

  handleSelect = key => this.setState({ key });

  handleChange = e => {
    this.setState({ provisionalTitle: e.target.value });
  };

  handleSave = async (message = false) => {
    const { noteId } = this.props.match.params;

    try {
      if (this.props.currency !== "ethereum") {
        const {
          title,
          addressesChanged,
          walletsChanged,
          transactionsChanged,
          graphsChanged,
          otherFilesChanged,
          otherFiles
        } = this.state;

        const wallets = this.state.wallets.map(wallet => wallet.wallet);
        const graphs = this.state.graphs.map(graph => graph.graphId);
        const addresses = this.state.addresses.map(address => address.address);
        const transactions = this.state.transactions.map(
          transaction => transaction.transaction
        );
        const note = JSON.stringify(
          convertToRaw(this.state.editorState.getCurrentContent())
        );
        await axios.put(`${NOTES_API(this.props.currency)}/${noteId}`, {
          noteId,
          title,
          addresses,
          wallets,
          transactions,
          graphs,
          note,
          addressesChanged,
          walletsChanged,
          transactionsChanged,
          graphsChanged,
          otherFilesChanged,
          otherFiles
        });

        if (message) {
          this.setState({
            addressesChanged: false,
            walletsChanged: false,
            transactionsChanged: false,
            graphsChanged: false,
            otherFilesChanged: false,
            notification: "Update succeeded"
          });
        } else {
          this.setState({
            addressesChanged: false,
            walletsChanged: false,
            transactionsChanged: false,
            graphsChanged: false,
            otherFilesChanged: false
          });
        }
      } else {
        const {
          title,
          addresses,
          addressesChanged,
          transactionsChanged,
          transactions
        } = this.state;

        const note = JSON.stringify(
          convertToRaw(this.state.editorState.getCurrentContent())
        );

        await axios.put(`${NOTES_API(this.props.currency)}/${noteId}`, {
          noteId,
          title,
          addresses,
          transactions,
          note,
          addressesChanged,
          transactionsChanged
        });

        if (message) {
          this.setState({
            addressesChanged: false,
            walletsChanged: false,
            transactionsChanged: false,
            notification: "Update succeeded"
          });
        } else {
          this.setState({
            addressesChanged: false,
            walletsChanged: false,
            transactionsChanged: false
          });
        }
      }
    } catch (err) {
      if (message) {
        this.setState({
          notification: "Update failed"
        });
      }
      throw err;
    }
  };

  handleAlertDismiss = () => {
    this.setState({
      notification: null
    });
  };

  //TODO this is disgusting change to use notistack example in custom wallets
  showUpdateFailedAlert = () => {
    const { notification } = this.state;
    switch (notification) {
      case "Update failed":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p>Update failed.</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Update succeeded":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="success" onDismiss={this.handleAlertDismiss}>
                <p>Successfully updated.</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Address added":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="success" onDismiss={this.handleAlertDismiss}>
                <p>Address added.</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Address does not exist":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p>Address does not exist.</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Transaction does not exist":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p>Transaction does not exist or is currently being processed</p>
              </Alert>
            </Col>
          </Row>
        );

      case "Graph does not exist":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p>Graph does not exist.</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Transaction already in note":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p>Transaction already in note.</p>
              </Alert>
            </Col>
          </Row>
        );

      case "Graph already in note":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p>Graph already in note.</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Entity added":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="success" onDismiss={this.handleAlertDismiss}>
                <p>{`Entity added.`}</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Entity does not exist":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p>{`Entity does not exist.`}</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Address already in note":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p>Address already in note</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Entity already in note":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p>{`Entity already in note`}</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Entity specified was not unique. Please use a wallet id instead.":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p>
                  {`Entity specified was not unique. Please use an address
                  instead.`}
                </p>
              </Alert>
            </Col>
          </Row>
        );
      case "Graph specified was not unique. Please use a graph id instead.":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p>
                  {`Graph specified was not unique. Please use a graph id
                  instead.`}
                </p>
              </Alert>
            </Col>
          </Row>
        );
      case "Duplicate file name":
        return (
          <Row>
            <Col lg={12}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p>{`Duplicate file name`}</p>
              </Alert>
            </Col>
          </Row>
        );
      default:
        return null;
    }
  };

  /**
   * Cleans up input
   * Checks if address already in note.
   * Checks if address exists on the blockchain.
   * Adds address if it does exist.
   * @param {event} e
   * @returns {Promise<void>}
   */
  addAddressIfExists = async e => {
    e.preventDefault();
    const address = this.addressForm.value.trim();

    if (address.length === 0) {
      return;
    }

    const {addresses} = this.state;
    if (this.props.currency !== "ethereum") {
      if (addresses.includes(address)) {
        this.setState({
          notification: "Address already in note",
          showAddAddressModal: false
        });
        return;
      }
    } else {
      const addressCheck = (() => {
          if (address.slice(0, 2) === '0x') {
          return (address.slice(2, address.length)).toLowerCase()
        } else {
          return address.toLowerCase()
        }
        })();

        const verifyAddressConflict = addresses.map(address_ => {
          if (address_.slice(0, 2) === '0x') {
            if ((address_.slice(2, address_.length)).toLowerCase() === addressCheck) {
              this.setState({
                notification: "Address already in note",
                showAddAddressModal: false
              });
              return false;
            }
          } else {
            if (address_.toLowerCase() === addressCheck) {
              this.setState({
                notification: "Address already in note",
                showAddAddressModal: false
              });
              return false;
            }
          }
        })
      if (verifyAddressConflict.includes(false)) {
        return;
      }
    }

    try {
      const { data } = await axios.get(
        `${ADDRESS_API(this.props.currency)}/${address}/exists`
      );

      if (this.props.currency !== "ethereum") {
        const addressObj = {
          addressId: data.addressId,
          address: data.address
        };

        this.setState(prevState => ({
          addresses: prevState.addresses.concat(addressObj),
          showAddAddressModal: false,
          addressesChanged: true
        }));
      } else {
        this.setState(prevState => ({
          addresses: prevState.addresses.concat(address),
          showAddAddressModal: false,
          addressesChanged: true
        }));
      }

      await this.handleSave();
    } catch (err) {
      if (err.response.status === 404) {
        this.setState({
          notification: "Address does not exist",
          showAddAddressModal: false
        });
      } else {
        throw err;
      }
    }
  };

  addOtherFile = async e => {
    const currentFileName = this.state.addFileName;
    const otherFiles = this.state.otherFiles.slice();
    otherFiles.map(({ fileName }) => {
      if (currentFileName === fileName) {
        this.setState({
          notification: "Duplicate file name",
          showAddOtherFileModal: false,
          addFileNotificationVisible: false,
          addFileName: null,
          fileNotificationSuccess: false,
          otherFileBase64: null
        });
        throw "exit";
      }
    }); ////going to check if the name of the file is already in there. If the name of the file is already in there, then need to send a notification and also not add it to the otherFiles list
    this.setState({
      otherFiles: [
        ...this.state.otherFiles,
        { fileName: currentFileName, base64Data: this.state.otherFileBase64 }
      ],
      otherFilesChanged: true
    });
    this.setState({
      otherFilesChanged: true,
      showAddOtherFileModal: false,
      addFileNotificationVisible: false,
      addFileName: null,
      fileNotificationSuccess: false,
      otherFileBase64: null
    });
  };

  /**
   * Cleans up input
   * Checks if wallet already in note.
   * Checks if wallet is valid number
   * Checks if wallet exists.
   * Adds wallet if it does exist.
   * @param {event} e
   * @returns {Promise<void>}
   */
  addWalletIfExists = async e => {
    e.preventDefault();
    const wallet = this.walletForm.value.trim();

    if (!wallet) {
      return;
    }

    const { wallets } = this.state;

    const contains =
      wallets.filter(walletObj => {
        return (
          walletObj.tag === wallet || walletObj.wallet === parseInt(wallet, 10)
        );
      }).length > 0;

    if (contains) {
      this.setState({
        notification: "Entity already in note",
        showAddWalletModal: false
      });
      return;
    }
    try {
      const customData = await axios.get(`${CUSTOM_WALLETS_API(this.props.currency)}/${wallet}/exists`)
      const contains =
          wallets.filter(walletObj => {
            return (
              walletObj.tag === customData.data.tag || walletObj.wallet === parseInt(customData.data.wallet, 10)
            );
          }).length > 0;

        if (contains) {
          this.setState({
            notification: "Entity already in note",
            showAddWalletModal: false
          });
          return;
        }

          const walletObj = {
            wallet: customData.data.walletId,
            tag: customData.data.tag
          };

          this.setState(prevState => ({
            wallets: prevState.wallets.concat(walletObj),
            showAddWalletModal: false,
            walletsChanged: true
          }));

          await this.handleSave();
      return;
    } catch (err) {
      if (err.response.status === 404) {
        const hasCustomWallet = false
      } else {
        throw err;
      }
    }

    try {
      const { data } = await axios.get(
        `${WALLET_API(this.props.currency)}/${wallet}/exists`
      );
      if (data.unique === false) {
        this.setState({
          notification:
            "Entity specified was not unique. Please use a wallet id instead.",
          showAddWalletModal: false
        });
        return;
      }

      const contains =
      wallets.filter(walletObj => {
        return (
          walletObj.tag === data.tag || walletObj.wallet === parseInt(data.wallet, 10)
        );
      }).length > 0;

    if (contains) {
      this.setState({
        notification: "Entity already in note",
        showAddWalletModal: false
      });
      return;
    }

      const walletObj = {
        wallet: data.walletId,
        tag: data.tag
      };

      this.setState(prevState => ({
        wallets: prevState.wallets.concat(walletObj),
        showAddWalletModal: false,
        walletsChanged: true
      }));

      await this.handleSave();
    } catch (err) {
      if (err.response.status === 404) {
        this.setState({
          notification: "Entity does not exist",
          showAddWalletModal: false
        });
      } else {
        throw err;
      }
    }
  };

  addTransactionIfExists = async e => {
    e.preventDefault();
    const transaction = this.transactionForm.value.trim();

    if (transaction.length === 0) {
      return;
    }

    const { transactions } = this.state;
    if (transactions.includes(transaction)) {
      this.setState({
        notification: "Transaction already in note",
        showAddTransactionModal: false
      });
      return;
    }

    try {
      const { data } = await axios.get(
        `${TRANSACTION_API(this.props.currency)}/${transaction}/exists`
      );
      if (this.props.currency !== "ethereum") {
        const transactionObj = {
          transactionId: data.transactionId,
          transaction: data.transaction
        };

        this.setState(prevState => ({
          transactions: prevState.transactions.concat(transactionObj),
          showAddTransactionModal: false,
          transactionsChanged: true
        }));
      } else {
        this.setState(prevState => ({
          transactions: prevState.transactions.concat(transaction),
          showAddTransactionModal: false,
          transactionsChanged: true
        }));
      }

      await this.handleSave();
    } catch (err) {
      if (err.response.status === 404) {
        this.setState({
          notification: "Transaction does not exist",
          showAddTransactionModal: false
        });
      } else {
        throw err;
      }
    }
  };

  addGraphIfExists = async e => {
    e.preventDefault();
    const graphCaseNumber = this.state.addGraphSelection.caseNumber.trim();
    const graphId = this.state.addGraphSelection.id;

    // Check if user put in a case number
    if (!graphCaseNumber) {
      return;
    }

    // Check if we have already added this graph
    const { graphs } = this.state;
    const contains =
      graphs.filter(item => {
        return item.graphId === graphId;
      }).length > 0;

    if (contains) {
      this.setState({
        notification: "Graph already in note",
        showAddGraphModal: false
      });
      return;
    }
    try {
      const { data } = await axios.get(
        `${GRAPH_API(this.props.currency)}/${graphCaseNumber}/exists`
      );
      if (data.unique === false) {
        this.setState({
          notification:
            "Graph specified was not unique. Please use a graph id instead.",
          showAddWalletModal: false
        });
        return;
      }

      const graphObj = {
        graphId: data.graphId,
        caseNumber: data.caseNumber
      };
      this.setState(prevState => ({
        graphs: prevState.graphs.concat(graphObj),
        showAddGraphModal: false,
        graphsChanged: true,
        notification: null
      }));
      await this.handleSave();
    } catch (err) {
      if (err.response.status === 404) {
        this.setState({
          notification: "Graph does not exist",
          showAddGraphModal: false
        });
      } else {
        throw err;
      }
    }
  };

  handleOpenAddAddressModal = () => {
    this.setState({
      showAddAddressModal: true
    });
  };

  handleOpenAddOtherFileModal = () => {
    this.setState({
      showAddOtherFileModal: true
    });
  };

  handleCloseAddAddressModal = () => {
    this.setState({
      showAddAddressModal: false
    });
  };

  handleCloseAddOtherFileModal = () => {
    this.setState({
      showAddOtherFileModal: false,
      addFileNotificationVisible: false,
      addFileName: null,
      fileNotificationSuccess: false,
      otherFileBase64: null
    });
  };

  handleOpenAddGraphModal = () => {
    this.setState({
      showAddGraphModal: true
    });
  };

  handleCloseAddGraphModal = () => {
    this.setState({
      showAddGraphModal: false
    });
  };

  handleOpenAddWalletModal = () => {
    this.setState({
      showAddWalletModal: true
    });
  };

  handleOpenAddTransactionModal = () => {
    this.setState({
      showAddTransactionModal: true
    });
  };

  handleCloseAddWalletModal = () => {
    this.setState({
      showAddWalletModal: false
    });
  };

  handleCloseAddTransactionModal = () => {
    this.setState({
      showAddTransactionModal: false
    });
  };

  handleOpenChangeTitleModal = () => {
    this.setState({
      showChangeTitleModal: true
    });
  };

  handleCloseChangeTitleModal = () => {
    this.setState({
      showChangeTitleModal: false
    });
  };

  handleChangeTitle = e => {
    const title = this.titleForm.value;
    this.setState({
      title,
      showChangeTitleModal: false
    });
    e.preventDefault();
  };

  handleDeleteAddress = address => {
    const addresses = this.state.addresses.slice();
    _.remove(addresses, val => val.address === address);
    this.setState({
      addresses,
      addressesChanged: true
    });
  };

  handleDeleteAddressEthereum = address => {
    const addresses = this.state.addresses.slice();
    _.remove(addresses, val => val === address);
    this.setState({
      addresses,
      addressesChanged: true
    });
  }

  handleDeleteTransaction = transaction => {
    const transactions = this.state.transactions.slice();
    _.remove(transactions, val => val.transaction === transaction);
    this.setState({
      transactions,
      transactionsChanged: true
    });
  };

  handleDeleteTransactionEthereum = transaction => {
    const transactions = this.state.transactions.slice();
    _.remove(transactions, val => val === transaction);
    this.setState({
      transactions,
      transactionsChanged: true
    });
  };

  handleDeleteGraph = graph => {
    const graphs = this.state.graphs.slice();
    _.remove(graphs, val => val.graphId === graph);
    this.setState({
      graphs,
      graphsChanged: true
    });
  };

  handleDeleteWallet = wallet => {
    const wallets = this.state.wallets.slice();
    _.remove(wallets, val => val.wallet === wallet);
    this.setState({
      wallets,
      walletsChanged: true
    });
  };

  handleDeleteOtherFile = otherFile => {
    const otherFiles = this.state.otherFiles.slice();
    _.remove(otherFiles, val => val.base64Data === otherFile);
    this.setState({
      otherFiles,
      otherFilesChanged: true
    });
  };

  handleOpenDeleteNoteModal = () => {
    this.setState({
      showDeleteNoteModal: true
    });
  };

  handleCloseDeleteNoteModal = () => {
    this.setState({
      showDeleteNoteModal: false
    });
  };

  handleDeleteNote = async () => {
    const { noteId } = this.props.match.params;
    try {
      await axios.delete(`${NOTES_API(this.props.currency)}/${noteId}`);
      this.props.history.push("/case-management");
    } catch (err) {
      throw err;
    }
  };

  render() {
    const { title } = this.state;
    const { noteId } = this.props.match.params;
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    const allGraphOptions = () => this.state.allGraphList

    const walletMergeMessages = this.state.wallets.map(wallet_ => {
      const { wallet, tag, merge_data } = wallet_;
      if (merge_data) {
        if (merge_data["important"]) {
          return (
            <li>
              Wallet(s) {merge_data["wallets"].toString()} merged into wallet{" "}
              {tag || wallet} *{" "}
            </li>
          );
        } else {
          return (
            <li>
              Wallet(s) {merge_data["wallets"].toString()} merged into wallet{" "}
              {tag || wallet}{" "}
            </li>
          );
        }
      }
    });

    const addresses = this.state.addresses.map(address_ => {
      if (this.props.currency !== "ethereum") {
        const { addressId, address, hasNote } = address_;
        const add = address.split(":").pop();
        return (
          <tr key={addressId}>
            <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              <Link
                to={`/${this.props.currency}/address/${add}`}
                target="_blank"
                style={{ color: "var(--link-colors)" }}
              >
                {add}
              </Link>{" "}
              <Glyphicon
                style={{ cursor: "pointer" }}
                onClick={() => this.handleDeleteAddress(address)}
                glyph="remove"
              />
              {hasNote ? (
                <Button
                  className="pull-right greenButtonGraph"
                  bsSize="xsmall"
                  onClick={() => {
                    this.updateAddressEditorState(addressId, add);
                  }}
                  style={{
                    fontSize: "12px",
                    fontFamily: "Quicksand",
                    height: "27px",
                    textTransform: "unset"
                  }}
                >
                  See Notes
                </Button>
              ) : (
                <Button
                  className="pull-right greenButtonGraph"
                  bsSize="xsmall"
                  onClick={() => {
                    this.updateAddressEditorState(addressId, add);
                  }}
                  style={{
                    fontSize: "12px",
                    fontFamily: "Quicksand",
                    height: "27px",
                    textTransform: "unset"
                  }}
                >
                  + Create Note
                </Button>
              )}
            </td>
          </tr>
        );
      } else {
        const add = address_.split(":").pop();
        return (
          <tr key={address_}>
            <td>
              <Link
                to={`/${this.props.currency}/address/${add}`}
                target="_blank"
                style={{ color: "var(--link-colors)" }}
              >
                {add}
              </Link>{" "}
              <Glyphicon
                style={{ cursor: "pointer" }}
                onClick={() => this.handleDeleteAddressEthereum(address_)}
                glyph="remove"
              />
            </td>
          </tr>
        );
      }
    });

    const otherFiles = this.state.otherFiles.map(otherFile_ => {
      const { fileName, base64Data } = otherFile_;
      return (
        <tr>
          <td
            style={{
              fontSize: "14px",
              fontFamily: "Roboto, sansSerif"
            }}
          >
            {fileName}
            <FontAwesomeIcon
              style={{ cursor: "pointer", marginLeft: "10px" }}
              icon={faDownload}
              onClick={() => saveAs(base64Data, fileName)}
            />{" "}
            <Glyphicon
              style={{ cursor: "pointer" }}
              onClick={() => this.handleDeleteOtherFile(base64Data)}
              glyph="remove"
            />
          </td>
        </tr>
      );
    });

    const wallets = this.state.wallets.map(wallet_ => {
      const { wallet, tag, hasNote } = wallet_;
      return (
        <tr key={wallet}>
          <td>
            {wallet > 0 ?
            <Link
              to={`/${this.props.currency}/wallet/${wallet}`}
              target="_blank"
              style={{ color: "var(--link-colors)" }}
            >
              {tag || wallet}
            </Link>
                :
                <Link
              to={`/${this.props.currency}/custom-wallet/${wallet}/transactions`}
              target="_blank"
              style={{ color: "var(--link-colors)" }}
            >
              {tag || wallet}
            </Link>
            }{" "}
            <Glyphicon
              style={{ cursor: "pointer" }}
              onClick={() => this.handleDeleteWallet(wallet)}
              glyph="remove"
            />
            {hasNote ? (
              <Button
                className="pull-right greenButtonGraph"
                bsSize="xsmall"
                onClick={() => {
                  this.updateWalletEditorState(wallet, tag);
                }}
                style={{
                  fontSize: "12px",
                  fontFamily: "Quicksand",
                  height: "27px",
                  textTransform: "unset"
                }}
              >
                See Notes
              </Button>
            ) : (
              <Button
                className="pull-right greenButtonGraph"
                bsSize="xsmall"
                onClick={() => {
                  this.updateWalletEditorState(wallet, tag);
                }}
                style={{
                  fontSize: "12px",
                  fontFamily: "Quicksand",
                  height: "27px",
                  textTransform: "unset"
                }}
              >
                + Create Note
              </Button>
            )}
          </td>
        </tr>
      );
    });

    const transactions = this.state.transactions.map(transaction_ => {
      if (this.props.currency !== "ethereum") {
        const { transactionId, transaction, hasNote } = transaction_;
        return (
          <tr key={transaction}>
            <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              <Link
                to={`/${this.props.currency}/transaction/${transaction}`}
                target="_blank"
                style={{ color: "var(--link-colors)" }}
              >
                {transaction.substring(0, 30) + "..."}
              </Link>{" "}
              <Glyphicon
                style={{ cursor: "pointer" }}
                onClick={() => this.handleDeleteTransaction(transaction)}
                glyph="remove"
              />
              {hasNote ? (
                <Button
                  className="pull-right greenButtonGraph"
                  bsSize="xsmall"
                  onClick={() => {
                    this.updateTransactionEditorState(
                      transactionId,
                      transaction
                    );
                  }}
                  style={{
                    fontSize: "12px",
                    fontFamily: "Quicksand",
                    height: "27px",
                    textTransform: "unset"
                  }}
                >
                  See Notes
                </Button>
              ) : (
                <Button
                  className="pull-right greenButtonGraph"
                  bsSize="xsmall"
                  onClick={() => {
                    this.updateTransactionEditorState(
                      transactionId,
                      transaction
                    );
                  }}
                  style={{
                    fontSize: "12px",
                    fontFamily: "Quicksand",
                    height: "27px",
                    textTransform: "unset"
                  }}
                >
                  + Create Note
                </Button>
              )}
            </td>
          </tr>
        );
      } else {
        return (
          <tr key={transaction_}>
            <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              <Link
                to={`/${this.props.currency}/transaction/${transaction_}`}
                target="_blank"
                style={{ color: "var(--link-colors)" }}
              >
                {transaction_}
              </Link>{" "}
              <Glyphicon
                style={{ cursor: "pointer" }}
                onClick={() => this.handleDeleteTransactionEthereum(transaction_)}
                glyph="remove"
              />
            </td>
          </tr>
        );
      }
    });

    const graphs = this.state.graphs.map(graph_ => {
      const { graphId, caseNumber, hasNote } = graph_;
      return (
        <tr key={graphId}>
          <td style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            <Link
              to={`/${this.props.currency}/graph/${graphId}`}
              target="_blank"
              style={{ color: "var(--link-colors)" }}
            >
              {caseNumber}
            </Link>{" "}
            <Glyphicon
              style={{ cursor: "pointer" }}
              onClick={() => this.handleDeleteGraph(graphId)}
              glyph="remove"
            />
            {hasNote ? (
              <Button
                className="pull-right greenButtonGraph"
                bsSize="xsmall"
                onClick={() => {
                  this.updateGraphEditorState(graphId, caseNumber);
                }}
                style={{
                  fontSize: "12px",
                  fontFamily: "Quicksand",
                  height: "27px",
                  textTransform: "unset"
                }}
              >
                See Notes
              </Button>
            ) : (
              <Button
                className="pull-right greenButtonGraph"
                bsSize="xsmall"
                onClick={() => {
                  this.updateGraphEditorState(graphId, caseNumber);
                }}
                style={{
                  fontSize: "12px",
                  fontFamily: "Quicksand",
                  height: "27px",
                  textTransform: "unset"
                }}
              >
                + Create Note
              </Button>
            )}
          </td>
        </tr>
      );
    });

    const selectedGraphNote = this.state.selectedGraphNote;

    return (
      <div>
        <Dialog
          open={this.state.showMergeModal}
          fullWidth
          onClose={() => this.setState({ showMergeModal: false })}
        >
          <DialogTitle disableTypography>
            <IconButton
              onClick={() => this.setState({ showMergeModal: false })}
            >
              <FontAwesomeIcon icon={faTimes} style={{ fontSize: "15px" }} />
            </IconButton>
            <h4>Wallet Merge</h4>
          </DialogTitle>
          <DialogContent>
            <p style={{ fontSize: "20px" }}>
              The following {clientModeConfigObject.cap_word_for_collection}{" "}
              have merged.
            </p>
            <p style={{ fontSize: "18px" }}>
              (Items with an asterisk require merge conflict resolution)
            </p>
            <ul>{walletMergeMessages}</ul>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ showMergeModal: false })}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Grid>
          {this.showUpdateFailedAlert()}
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col lg={12}>
              <h3
                onDoubleClick={this.handleOpenChangeTitleModal}
                className="entityHeader"
              >
                Case File
              </h3>
            </Col>
            <a href={`${NOTES_API(this.props.currency)}/download/${noteId}`}>
              <Button
                className="blueButton"
                style={{
                  fontSize: "12px",
                  fontFamily: "Quicksand, sans-serif",
                  height: "33.5px",
                  textTransform: "unset",
                  width: "150px",
                  borderColor: "#ccc",
                  fontWeight: "700"
                }}
                variant="outlined"
              >
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={faDownload}
                />
                Download Note
              </Button>
            </a>
            <ReportIssue
              entityName={noteId}
              entityType="note"
              currency={this.props.currency}
            >
              {" "}
            </ReportIssue>
          </Row>
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px"
            }}
          >
            <Col lg={12}>
              <p
                style={{
                  fontFamily: "Quicksand",
                  fontWeight: "600",
                  color: "#666",
                  marginRight: "10px",
                  marginBottom: "0",
                  fontSize: "30px"
                }}
                onDoubleClick={this.handleOpenChangeTitleModal}
              >
                {title}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div
                className="container"
                style={{
                  paddingRight: "0",
                  paddingTop: "0",
                  marginTop: "10px"
                }}
              >
                <Tabs
                  className="inviteTabs"
                  activeKey={this.state.key}
                  id="transaction-tab"
                  onSelect={this.handleSelect}
                  animation={false}
                  mountOnEnter
                >
                  <Tab eventKey="summaryEditor" title="Summary">
                    {this.props.currency !== "ethereum" ? (
                      <>
                        <Row>
                          <Col lg={6}>
                            <Table
                              striped
                              hover
                              style={{ fontFamily: "Monospace" }}
                            >
                              <thead>
                                <tr>
                                  <th style={{ fontFamily: "Quicksand" }}>
                                    Addresses
                                    <Button
                                      onClick={this.handleOpenAddAddressModal}
                                      className="greenButton"
                                      style={{
                                        float: "right",
                                        fontSize: "12px",
                                        fontFamily: "Quicksand",
                                        height: "27px",
                                        textTransform: "unset"
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPlus}
                                        style={{ marginRight: "5px" }}
                                      />
                                      Add Address
                                    </Button>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>{addresses}</tbody>
                            </Table>
                          </Col>
                          <Col lg={6}>
                            <Table
                              striped
                              hover
                              style={{ fontFamily: "Monospace" }}
                            >
                              <thead>
                                <tr>
                                  <th style={{ fontFamily: "Quicksand" }}>
                                    {
                                      clientModeConfigObject.cap_plural_for_collection
                                    }
                                    <Button
                                      onClick={this.handleOpenAddWalletModal}
                                      className="greenButton"
                                      style={{
                                        float: "right",
                                        fontSize: "12px",
                                        fontFamily: "Quicksand",
                                        height: "27px",
                                        textTransform: "unset"
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPlus}
                                        style={{ marginRight: "5px" }}
                                      />
                                      {`Add ${clientModeConfigObject.cap_word_for_collection}`}
                                    </Button>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>{wallets}</tbody>
                            </Table>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Row>
                        <Col lg={12}>
                          <Table
                            striped
                            hover
                            style={{ fontFamily: "Monospace" }}
                          >
                            <thead>
                              <tr>
                                <th style={{ fontFamily: "Quicksand" }}>
                                  Addresses
                                  <Button
                                    onClick={this.handleOpenAddAddressModal}
                                    className="greenButton"
                                    style={{
                                      float: "right",
                                      fontSize: "12px",
                                      fontFamily: "Quicksand",
                                      height: "27px",
                                      textTransform: "unset"
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      style={{ marginRight: "5px" }}
                                    />
                                    Add Address
                                  </Button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>{addresses}</tbody>
                          </Table>
                        </Col>
                      </Row>
                    )}
                    <br />
                    {this.props.currency !== "ethereum" ? (
                      <>
                        <Row>
                          <Col lg={6}>
                            <Table
                              striped
                              hover
                              style={{ fontFamily: "Monospace" }}
                            >
                              <thead>
                                <tr>
                                  <th style={{ fontFamily: "Quicksand" }}>
                                    Transactions
                                    <Button
                                      onClick={
                                        this.handleOpenAddTransactionModal
                                      }
                                      className="greenButton"
                                      style={{
                                        float: "right",
                                        fontSize: "12px",
                                        fontFamily: "Quicksand",
                                        height: "27px",
                                        textTransform: "unset"
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPlus}
                                        style={{ marginRight: "5px" }}
                                      />
                                      Add Transaction
                                    </Button>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>{transactions}</tbody>
                            </Table>
                          </Col>
                          <Col lg={6}>
                            <Table
                              striped
                              hover
                              style={{ fontFamily: "Monospace" }}
                            >
                              <thead>
                                <tr>
                                  <th style={{ fontFamily: "Quicksand" }}>
                                    Graphs
                                    <Button
                                      onClick={this.handleOpenAddGraphModal}
                                      className="greenButton"
                                      style={{
                                        float: "right",
                                        fontSize: "12px",
                                        fontFamily: "Quicksand",
                                        height: "27px",
                                        textTransform: "unset"
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPlus}
                                        style={{ marginRight: "5px" }}
                                      />
                                      Add Graph
                                    </Button>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>{graphs}</tbody>
                            </Table>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <Table
                              striped
                              hover
                              style={{ fontFamily: "Monospace" }}
                            >
                              <thead>
                                <tr>
                                  <th style={{ fontFamily: "Quicksand" }}>
                                    Other Files
                                    <Button
                                      onClick={this.handleOpenAddOtherFileModal}
                                      className="greenButton"
                                      style={{
                                        float: "right",
                                        fontSize: "12px",
                                        fontFamily: "Quicksand",
                                        height: "27px",
                                        textTransform: "unset"
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPlus}
                                        style={{ marginRight: "5px" }}
                                      />
                                      Add Other Files
                                    </Button>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>{otherFiles}</tbody>
                            </Table>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Row>
                        <Col lg={12}>
                          <Table
                            striped
                            hover
                            style={{ fontFamily: "Monospace" }}
                          >
                            <thead>
                              <tr>
                                <th>
                                  Transactions
                                  <Button
                                    onClick={this.handleOpenAddTransactionModal}
                                    className="greenButton"
                                    style={{
                                      float: "right",
                                      fontSize: "12px",
                                      fontFamily: "Quicksand",
                                      height: "27px",
                                      textTransform: "unset"
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      style={{ marginRight: "5px" }}
                                    />
                                    Add Transaction
                                  </Button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>{transactions}</tbody>
                          </Table>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <br />
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <Button
                            variant="outlined"
                            style={{float: "right", borderColor: "#ed1f1f", borderWidth: "2px", marginLeft: "2px", fontSize: "12px",
                  fontFamily: "Quicksand, sans-serif", textTransform: "unset", marginBottom: "5px"}}
                          className="redButton"
                          bsStyle="danger"
                          onClick={this.handleOpenDeleteNoteModal}
                        >
                          Delete
                        </Button>
                        <Button
                            variant="outlined"
                            style={{float: "right", borderColor: "var(--create-color)", borderWidth: "2px", fontSize: "12px",
                  fontFamily: "Quicksand, sans-serif", textTransform: "unset", marginRight: "2px", marginBottom: "5px"}}
                          className="greenButton"
                          onClick={() => this.handleSave(true)}
                        >
                          Save
                        </Button>{" "}

                      </Col>
                    </Row>
                    <NoteEditor
                      height={700}
                      editorState={this.state.editorState}
                      onEdit={this.onEdit}
                      saveGraph={this.handleSave}
                    />

                  </Tab>
                  {/*{this.props.currency !== "ethereum" && (*/}
                  {/*  <>*/}
                  {this.props.currency !== "ethereum" && (
                    <Tab eventKey="graphEditor" title="Graphs">
                      <div className="RichEditor-root" style={{ margin: "0" }}>
                        {selectedGraphNote ? (
                          <>
                            <Button
                              className="noteBackButton"
                              onClick={() =>
                                this.setState({ selectedGraphNote: null })
                              }
                            >
                              <FontAwesomeIcon icon={faArrowLeft} /> Graph List
                            </Button>
                            <h3
                              className="entityHeader"
                              style={{
                                fontSize: "30px",
                                color: "var(--secondary-text-colors)"
                              }}
                            >
                              {this.state.selectedGraphNote[1]}
                            </h3>
                            <GraphNoteEditor
                              graph={this.state.selectedGraphNote[1]}
                              graphId={this.state.selectedGraphNote[0]}
                              mainLocationId={this.state.selectedGraphNote[2]}
                              mainLocation={"note"}
                              changeCreateGraphNoteButton={
                                this.changeCreateGraphNoteButton
                              }
                            ></GraphNoteEditor>
                          </>
                        ) : (
                          <>
                            <h3
                              className="entityHeader"
                              style={{
                                fontSize: "30px",
                                color: "var(--secondary-text-colors)"
                              }}
                            >
                              Select a Graph
                            </h3>
                            <Row>
                              <Col lg={12}>
                                <Table
                                  striped
                                  hover
                                  style={{ fontFamily: "Monospace" }}
                                >
                                  <thead>
                                    <tr>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>{graphs}</tbody>
                                </Table>
                              </Col>
                            </Row>
                          </>
                        )}
                      </div>
                    </Tab>
                  )}

                  {this.props.currency !== "ethereum" && (
                    <Tab eventKey="addressEditor" title="Addresses">
                      <div className="RichEditor-root" style={{ margin: "0" }}>
                        {this.state.selectedAddressNote ? (
                          <>
                            <Button
                              className="noteBackButton"
                              onClick={() =>
                                this.setState({ selectedAddressNote: null })
                              }
                            >
                              <FontAwesomeIcon icon={faArrowLeft} /> Addresses
                              List
                            </Button>
                            <h3
                              className="entityHeader"
                              style={{
                                fontSize: "30px",
                                color: "var(--secondary-text-colors)"
                              }}
                            >
                              {this.state.selectedAddressNote[0]}
                            </h3>
                            <AddressNoteEditor
                              address={this.state.selectedAddressNote[0]}
                              mainLocationId={this.state.selectedAddressNote[2]}
                              mainLocation={"note"}
                              mainLocationName={
                                this.state.selectedAddressNote[0]
                              }
                              changeCreateAddressNoteButton={
                                this.changeCreateAddressNoteButton
                              }
                            ></AddressNoteEditor>
                          </>
                        ) : (
                          <>
                            <h3
                              className="entityHeader"
                              style={{
                                fontSize: "30px",
                                color: "var(--secondary-text-colors)"
                              }}
                            >
                              Select an Address
                            </h3>
                            <Row>
                              <Col lg={12}>
                                <Table
                                  striped
                                  hover
                                  style={{ fontFamily: "Monospace" }}
                                >
                                  <thead>
                                    <tr>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>{addresses}</tbody>
                                </Table>
                              </Col>
                            </Row>
                          </>
                        )}
                      </div>
                    </Tab>
                  )}

                  {this.props.currency !== "ethereum" && (
                    <Tab eventKey="transactionEditor" title="Transactions">
                      <div className="RichEditor-root" style={{ margin: "0" }}>
                        {this.state.selectedTransactionNote ? (
                          <>
                            <Button
                              className="noteBackButton"
                              onClick={() =>
                                this.setState({
                                  selectedTransactionNote: null
                                })
                              }
                            >
                              <FontAwesomeIcon icon={faArrowLeft} /> Transaction
                              List
                            </Button>
                            <h3
                              className="entityHeader"
                              style={{
                                fontSize: "30px",
                                color: "var(--secondary-text-colors)"
                              }}
                            >
                              {this.state.selectedTransactionNote[0]}
                            </h3>
                            <TransactionNoteEditor
                              transaction={
                                this.state.selectedTransactionNote[0]
                              }
                              mainLocationId={
                                this.state.selectedTransactionNote[2]
                              }
                              mainLocation={"note"}
                              changeCreateTransactionNoteButton={
                                this.changeCreateTransactionNoteButton
                              }
                            ></TransactionNoteEditor>
                          </>
                        ) : (
                          <>
                            <h3
                              className="entityHeader"
                              style={{
                                fontSize: "30px",
                                color: "var(--secondary-text-colors)"
                              }}
                            >
                              Select a Transaction
                            </h3>
                            <Row>
                              <Col lg={12}>
                                <Table
                                  striped
                                  hover
                                  style={{ fontFamily: "Monospace" }}
                                >
                                  <thead>
                                    <tr>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>{transactions}</tbody>
                                </Table>
                              </Col>
                            </Row>
                          </>
                        )}
                      </div>
                    </Tab>
                  )}

                  {this.props.currency !== "ethereum" && (
                    <Tab
                      eventKey="walletEditor"
                      title={clientModeConfigObject.cap_word_for_collection}
                    >
                      <div className="RichEditor-root" style={{ margin: "0" }}>
                        {this.state.selectedWalletNote ? (
                          <>
                            <Button
                              className="noteBackButton"
                              onClick={() =>
                                this.setState({ selectedWalletNote: null })
                              }
                            >
                              <FontAwesomeIcon icon={faArrowLeft} /> Wallet List
                            </Button>
                            {this.state.selectedWalletNote[0] ? (
                              <h3
                                className="entityHeader"
                                style={{
                                  fontSize: "30px",
                                  color: "var(--secondary-text-colors)"
                                }}
                              >
                                {this.state.selectedWalletNote[0]}
                              </h3>
                            ) : (
                              <h3
                                className="entityHeader"
                                style={{
                                  fontSize: "30px",
                                  color: "var(--secondary-text-colors)"
                                }}
                              >
                                {this.state.selectedWalletNote[1]}
                              </h3>
                            )}
                            <WalletNoteEditor
                              wallet={this.state.selectedWalletNote[0]}
                              walletId={this.state.selectedWalletNote[1]}
                              mainLocationId={this.state.selectedWalletNote[2]}
                              mainLocation={"note"}
                              changeCreateNodeNoteButton={
                                this.changeCreateWalletNoteButton
                              }
                            ></WalletNoteEditor>
                          </>
                        ) : (
                          <>
                            <h3
                              className="entityHeader"
                              style={{
                                fontSize: "30px",
                                color: "var(--secondary-text-colors)"
                              }}
                            >
                              Select{" "}
                              {clientModeConfigObject.cap_word_for_collection}
                            </h3>
                            <Row>
                              <Col lg={12}>
                                <Table
                                  striped
                                  hover
                                  style={{ fontFamily: "Monospace" }}
                                >
                                  <thead>
                                    <tr>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>{wallets}</tbody>
                                </Table>
                              </Col>
                            </Row>
                          </>
                        )}
                      </div>
                    </Tab>
                  )}
                </Tabs>
              </div>
            </Col>
          </Row>
          <br />
          <Modal
            show={this.state.showAddAddressModal}
            onHide={this.handleCloseAddAddressModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.addAddressIfExists}>
                <FormGroup>
                  <FormControl
                    type="text"
                    placeholder="Address"
                    inputRef={ref => {
                      this.addressForm = ref;
                    }}
                  />
                </FormGroup>
                <Button type="submit" style={{borderColor: "#ccc"}} variant="outlined">Add</Button>
              </Form>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.showAddOtherFileModal}
            onHide={this.handleCloseAddOtherFileModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Other Files (PDF or CSV)</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TextField
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  width: "550px"
                }}
                placeholder="File Name"
                label={this.state.addFileName ? this.state.addFileName : null}
                variant="filled"
                inputProps={{ maxLength: 50 }}
                onChange={v => this.setState({ addFileName: v.target.value })}
              />

              <div>
                <Button
                  className="blueButton"
                  variant="contained"
                  component="label"
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    marginLeft: "10px",
                    marginBottom: "5px"
                  }}
                >
                  Upload
                  <input
                    onChange={e => this.onImageUpload(e)}
                    accept=".csv, .pdf"
                    multiple
                    type="file"
                  />
                </Button>
                <span
                  style={{
                    visibility: this.state.addFileNotificationVisible
                      ? "visible"
                      : "hidden",
                    marginLeft: "5px"
                  }}
                >
                  {this.state.fileNotificationSuccess ? (
                    <>
                      {" "}
                      <FontAwesomeIcon icon={faCheck} /> {" File Uploaded !"}{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        style={{ color: "#cc0000" }}
                      />{" "}
                      {" Upload Failed !"}{" "}
                    </>
                  )}
                </span>
              </div>
              <Button
                style={{ marginLeft: "10px", borderColor: "#ccc" }}
                variant="outlined"
                type="submit"
                disabled={
                  !this.state.addFileName || !this.state.fileNotificationSuccess
                }
                onClick={this.addOtherFile}
              >
                Add
              </Button>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.showAddWalletModal}
            onHide={this.handleCloseAddWalletModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>{`Add ${clientModeConfigObject.cap_word_for_collection}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.addWalletIfExists}>
                <FormGroup>
                  <FormControl
                    type="text"
                    placeholder={clientModeConfigObject.cap_word_for_collection}
                    inputRef={ref => {
                      this.walletForm = ref;
                    }}
                  />
                </FormGroup>
                <Button type="submit" style={{borderColor: "#ccc"}} variant="outlined">Add</Button>
              </Form>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.showAddTransactionModal}
            onHide={this.handleCloseAddTransactionModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Transaction</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.addTransactionIfExists}>
                <FormGroup>
                  <FormControl
                    type="text"
                    placeholder="Transaction"
                    inputRef={ref => {
                      this.transactionForm = ref;
                    }}
                  />
                </FormGroup>
                <Button type="submit" style={{borderColor: "#ccc"}} variant="outlined">Add</Button>
              </Form>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.showAddGraphModal}
            onHide={this.handleCloseAddGraphModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Graph</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.addGraphIfExists}>
                <FormGroup>
                  <Autocomplete
                    onChange={(event, value) => this.setState({"addGraphSelection": value})}
                  disablePortal
                  id="combo-box-demo"
                  options={allGraphOptions()}
                  sx={{ width: 300 }}
                  getOptionLabel={(option) => option.caseNumber.toString()}
                  renderOption={option => (
                      <p>
                        <span>
                      {option.caseNumber}</span><span style={{fontSize: '11px', color: "gray"}}> Last Modified: {moment
        .unix(parseInt(option.lastModified, 10))
        .format("LLL")}</span>
                    </p>
                  )}
                  renderInput={(params) => <TextField {...params} label="Graph"/>}
                />
                </FormGroup>
                <Button type="submit" style={{borderColor: "#ccc"}} variant="outlined">Add</Button>
              </Form>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.showChangeTitleModal}
            onHide={this.handleCloseChangeTitleModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Title</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.handleChangeTitle}>
                <FormGroup>
                  <FormControl
                    type="text"
                    placeholder="Title"
                    onChange={this.handleChange}
                    inputRef={ref => {
                      this.titleForm = ref;
                    }}
                    defaultValue={this.state.title}
                  />
                </FormGroup>
                <Button
                  type="submit"
                  disabled={this.state.provisionalTitle.length <= 0}
                >
                  Change
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.showDeleteNoteModal}
            onHide={this.handleCloseDeleteNoteModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Button variant="outlined" className="greenButton" style = {{ borderColor: "var(--create-color)", borderWidth: "2px", fontSize: "12px",
                  fontFamily: "Quicksand, sans-serif", textTransform: "unset"}} onClick={this.handleCloseDeleteNoteModal}>Keep</Button>{" "}
              <Button variant="outlined" className="redButton" onClick={this.handleDeleteNote} style={{borderColor: "#ed1f1f", borderWidth: "2px", fontSize: "12px",
                  fontFamily: "Quicksand, sans-serif", textTransform: "unset"}} bsStyle="danger">
                Delete
              </Button>
            </Modal.Body>
          </Modal>
        </Grid>
      </div>
    );
  }
}

Note.propTypes = {
  match: reactRouterMatchShape({
    noteId: PropTypes.string.isRequired
  }).isRequired,
  history: reactRouterHistoryShape.isRequired
};

const mapStateToProps = state => {
  return {
    currency: getCurrency(state),
    clientMode: getClientMode(state)
  };
};

export default connect(
  mapStateToProps,
  null
)(Note);
