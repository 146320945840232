/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

/*
 * Displays a transaction output
 */
import PropTypes from "prop-types";
import React from "react";
import { OverlayTrigger } from "react-bootstrap";

import AddressCell from "../AddressCell";
import { numberWithCommas, satoshiToBitcoin } from "../../../helpers";
import { FullWidthPopover } from "../../styled";
import AddToWatchlist from "../../Home/Watchlist/AddToWatchlist";
import { useSelector } from "react-redux";
import { getWalletName } from "../../../selectors/wallet";

const OutputAddressRow = ({
  keyProp,
  transactionHash,
  address,
  addressCount,
  walletId,
  satoshi,
  primaryTag,
  handleOpenAddressModal,
  handleOpenTransactionModal,
  handleOpenWalletModal,
  getAddressLink,
  getWalletLink,
  getTransactionLink,
  isChange,
  currency,
  originatingTransaction,
  convertPriceToUsd,
  toggleUsdDisplay,
  showAsUsd,
  isNote,
  onAddressNoteClick
}) => {
  const tooltip = (
    <FullWidthPopover id={keyProp} style={{ fontFamily: "Quicksand" }}>
      {transactionHash}
    </FullWidthPopover>
  );
  const tooltipWatchlist = (
    <FullWidthPopover id={keyProp} style={{ fontFamily: "Quicksand" }}>
      Add To Watchlist
    </FullWidthPopover>
  );
  const bitcoin = satoshiToBitcoin(satoshi);
  const output_index = parseInt(keyProp.split("+")[1]);
  const showWallet = walletId !== 0;

  const { name, tagType } = useSelector(state => {
    if (showWallet && walletId) {
      return getWalletName(state, walletId);
    } else {
      return {
        tagType: "coinbase",
        name: null
      };
    }
  });
  const linkText = `${
    name && tagType === "ID" ? `${name.slice(0, Math.min(name.length, 7))}...` : name
  } (${addressCount.toLocaleString()})`;
  return (
    <tr key={keyProp} className="inviteRowThin staticTableRow">
      <AddressCell
        address={address}
        walletId={walletId}
        addressCount={addressCount}
        handleOpenAddressModal={handleOpenAddressModal}
        getAddressLink={getAddressLink}
        keyProp={keyProp}
        isChange={isChange}
        currency={currency}
        exists={showWallet}
        isNote={isNote}
        onAddressNoteClick={onAddressNoteClick}
      />
      <td
        style={{
          fontSize: "12px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "right"
        }}
      >
        {showWallet ? (
          getWalletLink(walletId, linkText, handleOpenWalletModal, currency)
        ) : (
          <span>Unclustered</span>
        )}
      </td>
      <td
        style={{
          fontSize: "12px",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        <div className="text-right">
          <div className="text-right">
            {showAsUsd ? "$" + numberWithCommas(convertPriceToUsd(bitcoin)) : bitcoin}
          </div>
        </div>
      </td>
      {transactionHash !== null ? (
        <OverlayTrigger overlay={tooltip} placement="top" delayShow={300} delayHide={150}>
          <td style={{ textAlign: "center" }}>
            {getTransactionLink(transactionHash, "right", handleOpenTransactionModal, currency)}
          </td>
        </OverlayTrigger>
      ) : (
        showWallet && (
          <OverlayTrigger
            overlay={tooltipWatchlist}
            placement="top"
            delayShow={300}
            delayHide={150}
          >
            <td style={{ textAlign: "center" }}>
              <AddToWatchlist
                transaction_hash={originatingTransaction}
                satoshi={satoshi}
                output_index={output_index}
                address={address[0]}
                entityType="UTXO"
              />
            </td>
          </OverlayTrigger>
        )
      )}
    </tr>
  );
};

export default OutputAddressRow;

OutputAddressRow.propTypes = {
  address: PropTypes.arrayOf(PropTypes.string).isRequired,
  keyProp: PropTypes.string.isRequired,
  primaryTag: PropTypes.string,
  satoshi: PropTypes.number.isRequired,
  transactionHash: PropTypes.string,
  walletId: PropTypes.number,
  handleOpenAddressModal: PropTypes.func,
  handleOpenTransactionModal: PropTypes.func,
  handleOpenWalletModal: PropTypes.func,
  getAddressLink: PropTypes.func.isRequired,
  getWalletLink: PropTypes.func.isRequired,
  getTransactionLink: PropTypes.func,
  isChange: PropTypes.bool.isRequired
};

OutputAddressRow.defaultProps = {
  primaryTag: null,
  transactionHash: null,
  walletId: null,
  handleOpenAddressModal: null,
  handleOpenTransactionModal: null,
  handleOpenWalletModal: null,
  getTransactionLink: null
};
