/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button } from "react-bootstrap";

import axios, { INVITE_API } from "../../api";

export default class InviteRow extends Component {
  handleDeleteInvite = async () => {
    const { email } = this.props;
    try {
      await axios.delete(`${INVITE_API}/${email}`);
      this.props.onDeleteInvite();
    } catch (err) {
      throw err;
    }
  };

  render() {
    const { email, inviteDate } = this.props;

    return (
      <tr className="inviteRow staticTableRow">
        <td className="staticTableRow">{email}</td>
        <td className="staticTableRow">{inviteDate}</td>
        <td className="staticTableRow">
          <Button onClick={this.handleDeleteInvite} bsStyle="danger" bsSize="small">
            Revoke
          </Button>
        </td>
      </tr>
    );
  }
}

InviteRow.propTypes = {
  email: PropTypes.string.isRequired,
  inviteDate: PropTypes.string.isRequired,
  onDeleteInvite: PropTypes.func.isRequired,
};
