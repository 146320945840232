// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { fromJS, Set } from "immutable";

import { LOGIN_SUCCESS } from "../actions/actionNames";

const features = (state = Set(), action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return Set(fromJS(action.features));
    default:
      return state;
  }
};

export default features;
