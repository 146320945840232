/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

/*
 * Displays a transaction input
 */
import PropTypes from "prop-types";
import React from "react";

import AddressCell from "../AddressCell";
import { numberWithCommas, satoshiToBitcoin } from "../../../helpers";
import { useSelector } from "react-redux";
import { getWalletName } from "../../../selectors/wallet";

const CollapsedInputAddressRow = ({
  keyProp,
  address,
  walletId,
  satoshi,
  primaryTag,
  handleOpenAddressModal,
  handleOpenWalletModal,
  getAddressLink,
  getWalletLink,
  currency,
  convertPriceToUsd,
  showAsUsd
}) => {
  const bitcoin = address !== "Coinbase" ? satoshiToBitcoin(satoshi) : null;
  const showWallet = walletId !== 0;
  const { name, tagType } = useSelector(state => {
    if (showWallet && walletId) {
      return getWalletName(state, walletId);
    } else {
      return {
        tagType: "coinbase",
        name: null
      };
    }
  });
  let linkText;
  if (!name) {
    linkText = null;
  } else {
    linkText = tagType === "ID" ? `${name.slice(0, Math.min(name.length, 7))}...` : name;
  }
  return (
    <tr key={keyProp} className="inviteRowThin staticTableRow">
      <AddressCell
        address={address}
        walletId={walletId}
        handleOpenAddressModal={handleOpenAddressModal}
        getAddressLink={getAddressLink}
        keyProp={keyProp}
        currency={currency}
        exists={showWallet}
      />
      <td>
        {showWallet ? (
          getWalletLink(walletId, linkText, handleOpenWalletModal, currency)
        ) : (
          <span> Unclustered </span>
        )}
      </td>
      <td>
        <div className="text-right">
          {showAsUsd ? "$" + numberWithCommas(convertPriceToUsd(bitcoin)) : bitcoin}
        </div>
      </td>
    </tr>
  );
};

export default CollapsedInputAddressRow;

CollapsedInputAddressRow.propTypes = {
  address: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  keyProp: PropTypes.string.isRequired,
  primaryTag: PropTypes.string,
  satoshi: PropTypes.number.isRequired,
  walletId: PropTypes.number,
  handleOpenAddressModal: PropTypes.func,
  handleOpenWalletModal: PropTypes.func,
  getAddressLink: PropTypes.func.isRequired,
  getWalletLink: PropTypes.func.isRequired
};

CollapsedInputAddressRow.defaultProps = {
  primaryTag: null,
  handleOpenAddressModal: null,
  handleOpenWalletModal: null,
  walletId: null
};
