import { useState } from "react";
import * as React from "react";

import { CustomCheckbox } from "../../Utils/CustomCheckbox";
import axios, { WATCHLIST_API } from "../../../api";

interface SMSCheckbox {
  value: boolean;
  setValue: (newVal: boolean) => void;
  style?: {
    marginLeft: string;
    marginRight: string;
    display: string;
    height: any;
    width: any;
    border: string;
    verticalAlign: string;
    textAlign: string;
    fontFamily: string;
    cursor: string;
  };
  currency: string;
  entityType: string;
  id: number;
}
export const SMSCheckbox: React.FC<SMSCheckbox> = ({
  value,
  setValue,
  style = {
    marginLeft: "25px",
    marginRight: "5px",
    display: "inline-block",
    height: "20px",
    width: "20px",
    border: "2px solid var(--base-color)",
    verticalAlign: "bottom",
    textAlign: "center",
    fontFamily: "Quicksand",
    cursor: "pointer"
  },
  currency,
  entityType,
  id
}) => {
  let [loading, setLoading] = useState(false);

  const setSMSValue = async (value: boolean) => {
    if (id == null) {
      setValue(value);
      return true; // ignore api call for watchlist item that doesn't exist yet
    }
    try {
      await axios.put(`${WATCHLIST_API(currency)}/sms/${entityType}/${id}`, {
        value: value
      });
      setValue(value);
      return true;
    } catch (e) {
      return false;
    }
  };

  const onClick = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const newValue = !value;
    await setSMSValue(newValue);
    setLoading(false);
  };

  return (
    <>
      <CustomCheckbox loading={loading} value={value} onClick={() => onClick()} style={style} />
      <span style={{ fontFamily: "Quicksand", fontWeight: "700", color: "#444" }}>
        SMS Messaging
      </span>
    </>
  );
};
