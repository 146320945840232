import React, { Component } from "react";
import { Button, Col, Grid, Row, Table } from "react-bootstrap";
import Chart from "react-google-charts";
import CustomMapComponent from "./CustomMapComponent";
import moment from "moment";
import Countries from "../countries";
import { connect } from "react-redux";
import { getAddressMoreInfo } from "../../selectors/address";
import PropTypes from "prop-types";
import { PieChart } from "react-minimal-pie-chart";

class IpAddressSummary extends Component {
  state = {
    expanded: false,
    current_ips: this.props.ip,
    current_ip_location: "Everywhere"
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.ip !== this.props.ip) {
      const { ip, risk, gambling_ok_risk, sanctions_risk } = this.props;
      this.props.sendBackData(
        ip.length,
        risk,
        gambling_ok_risk,
        sanctions_risk
      );
      this.setState({
        current_ips: ip,
        current_ip_location: "Everywhere"
      });
    }
  }

  setCurrentIps = (ips, withCities = true) => {
    let city = null;
    let country = null;

    ips.forEach(info => {
      if (
        city === null &&
        info.city !== null &&
        info.city !== "" &&
        withCities
      ) {
        city = info.city;
      }
      if (country === null && info.country !== null && info.country !== "") {
        country = info.country;
      }
    });

    this.setState({
      current_ips: ips,
      current_ip_location: `${city !== null ? `${city}, ` : ""}${
        country !== null ? Countries[country] : ""
      }`
    });
  };

  render() {
    const { ip, countries, cities, long_lat } = this.props;
    // Move the countries to an array for the chart
    let _countries = Object.keys(countries).map(country => [
      country,
      countries[country].length
    ]);
    _countries = [["country", "value"]].concat(_countries);

    // Move the cities to an array for the chart
    let _cities = Object.keys(cities).map(city => [city, cities[city].length]);
    _cities = [["city", "value"]].concat(_cities);

    // Make the markers for the maps
    const markers = [];

    long_lat &&
      Object.keys(long_lat).forEach(long => {
        Object.keys(long_lat[long]).forEach(lat => {
          const ips = [];
          Object.keys(long_lat[long][lat]).forEach(ip =>
            ips.push(long_lat[long][lat][ip])
          );
          markers.push([long, lat, ips]);
        });
      });

    const _setCurrentIps = this.setCurrentIps;
    return (
      <Grid style={{ marginLeft: "0", marginRight: "0", width: "100%" }}>
        <Row>
          <Col lg={7}>
            <Row>
              <Col lg={6}>
                {this.props.visible && (
                  <Chart
                    width="100%"
                    height="300px"
                    style={{ cursor: "pointer" }}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={_countries}
                    options={{
                      title: "Countries"
                    }}
                    rootProps={{ "data-testid": "1" }}
                    chartEvents={[
                      {
                        eventName: "select",
                        callback({ chartWrapper }) {
                          const data = chartWrapper.getDataTable();
                          const {
                            row
                          } = chartWrapper.getChart().getSelection()[0];
                          const ip = countries[data.getValue(row, 0)];

                          _setCurrentIps(ip, false);
                        }
                      }
                    ]}
                  />
                )}
              </Col>

              <Col lg={6}>
                {this.props.visible && (
                  <Chart
                    width="100%"
                    height="300px"
                    style={{ cursor: "pointer" }}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={_cities}
                    options={{
                      title: "Cities"
                    }}
                    rootProps={{ "data-testid": "1" }}
                    chartEvents={[
                      {
                        eventName: "select",
                        callback({ chartWrapper }) {
                          const data = chartWrapper.getDataTable();
                          const {
                            row
                          } = chartWrapper.getChart().getSelection()[0];
                          const ip = cities[data.getValue(row, 0)];

                          _setCurrentIps(ip);
                        }
                      }
                    ]}
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col lg={5}>
            {/* <ComposableMap style={{border: '2px solid #333'}}> */}
            {/*  <CustomZoomableGroup center={[0, 0]}> */}
            {/*    {position => ( */}
            {/*    <> */}
            {/*    <Geographies geography={Map}> */}
            {/*      {({ geographies }) => */}
            {/*        geographies.map(geo => <Geography key={geo.rsmKey} geography={geo} />) */}
            {/*      } */}
            {/*    </Geographies> */}
            {/*    {markers(8/position.k)} */}
            {/*    </> )} */}
            {/*  </CustomZoomableGroup> */}
            {/* </ComposableMap> */}
            <CustomMapComponent
              markers={markers}
              setCurrentIps={this.setCurrentIps}
            />
          </Col>
        </Row>
        <Row>
          {/*  Display the info about selected ips here */}
          <Col lg={12} style={{ marginTop: "70px" }}>
            <p className="entityType">
              {" "}
              Showing IPs from {this.state.current_ip_location}{" "}
            </p>
            <div>
              <p
                className="entityPrompt"
                style={{
                  marginTop: "5px",
                  marginBottom: "5px",
                  marginRight: "15px",
                  display: "inline-block"
                }}
              >
                {" "}
                Select from the Graphs or Map to Filter the IP Addresses Shown
              </p>
              {this.state.current_ip_location !== "Everywhere" && (
                <Button
                  className="blueButton"
                  style={{ display: "inline-block" }}
                  onClick={() => {
                    this.setState({
                      current_ips: ip,
                      current_ip_location: "Everywhere"
                    });
                  }}
                >
                  Clear Filter
                </Button>
              )}
            </div>
            <Table
              className="inviteTable"
              style={{
                maxWidth: "100%",
                tableLayout: "fixed",
                marginTop: "15px"
              }}
            >
              <thead>
                <th
                  style={{
                    width: "20%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    paddingLeft: "8px"
                  }}
                >
                  {" "}
                  IP Address
                </th>
                <th
                  style={{
                    width: "20%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    paddingLeft: "8px"
                  }}
                >
                  {" "}
                  Date Retrieved
                </th>
                <th
                  style={{
                    width: "20%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    paddingLeft: "8px"
                  }}
                >
                  {" "}
                  Location
                </th>
                <th
                  style={{
                    width: "20%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    paddingLeft: "8px"
                  }}
                >
                  {" "}
                  Latitude
                </th>
                <th
                  style={{
                    width: "20%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    paddingLeft: "8px"
                  }}
                >
                  {" "}
                  Longitude
                </th>
              </thead>
              <tbody>
                {this.state.current_ips.map(ip => {
                  return (
                    <tr className="inviteRow">
                      <td
                        style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      >
                        {" "}
                        {ip.ipAddress}{" "}
                      </td>
                      <td
                        style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      >
                        {moment
                          .unix(ip.date)
                          .utc()
                          .format("LLL")}
                      </td>
                      <td
                        style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      >
                        {" "}
                        {ip.city === null || ip.city === ""
                          ? Countries[ip.country] || "N/A"
                          : `${ip.city}, ${Countries[ip.country]}`}{" "}
                      </td>
                      <td
                        style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      >
                        {" "}
                        {ip.latitude || "N/A"}{" "}
                      </td>
                      <td
                        style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      >
                        {" "}
                        {ip.longitude || "N/A"}{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Grid>
    );
  }
}

IpAddressSummary.propTypes = {
  address: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired
};

IpAddressSummary.defaultProps = {
  countries: {},
  cities: {},
  long_lat: {},
  ip: []
};
const mapStateToProps = (state, { address }) => {
  return {
    ...getAddressMoreInfo(state, address)
  };
};

export default connect(
  mapStateToProps,
  null
)(IpAddressSummary);
