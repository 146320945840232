// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { List, Map, Record } from "immutable";

export const CompoundKey = Record({
  primary: 0,
  secondary: 0
});

export const Transactions = Record({
  transactionMap: Map(), // transactionId -> AddressTransaction
  newestFirst: List(),
  newestFirstNextKey: 0,
  oldestFirst: List(),
  oldestFirstNextKey: 0,
  largestFirst: List(),
  largestFirstNextKey: new CompoundKey(),
  order: 1, // newestFirst is default order
  startDate: null,
  endDate: null
});

export const MutualTransactions = Record({
  transactionMap: Map(), // transactionId -> AddressTransaction
  newestFirst: List(),
  newestFirstHasMore: true,
  oldestFirst: List(),
  oldestFirstHasMore: true,
  largestFirst: List(),
  largestFirstHasMore: true,
  order: "newestFirst", // newestFirst is default order
  startDate: null,
  endDate: null
});

export const AddressWalletMutualTransactions = Record({
  transactionMap: Map(), // transactionId -> AddressTransaction
  newestFirst: List(),
  newestFirstHasMore: true,
  oldestFirst: List(),
  oldestFirstHasMore: true,
  largestFirst: List(),
  largestFirstHasMore: true,
  order: "newestFirst", // newestFirst is default order
  startDate: null,
  endDate: null
});

export const WalletWalletMutualTransactions = Record({
  transactionMap: Map(), // transactionId -> AddressTransaction
  newestFirst: List(),
  newestFirstNextKey: 0, // 0 is just for initial. Will be null when no more keys
  oldestFirst: List(),
  oldestFirstNextKey: 0,
  largestFirst: List(),
  largestFirstNextKey: 0,
  order: "newestFirst", // newestFirst is default order
  startDate: null,
  endDate: null
});

export const WalletTransaction = Record({
  transactionHash: "",
  transactionId: 0,
  satoshi: 0,
  timestamp: 0,
  price: 0,
  priceAvailable: true
});

export const Transaction = Record({
  transactionHash: "",
  transactionId: 0,
  inputSatoshi: 0,
  outputSatoshi: 0,
  satoshi: 0,
  timestamp: 0,
  price: 0,
  priceAvailable: true
});

export const Mentions = Record({
  // the ordering for tags is just the order
  // they show up in, so there is no need to
  // store values in a map for normalization
  mentions: List(),
  nextKey: 0
});

export const AddressMention = Record({
  address: "",
  username: "",
  onlineAccountId: "",
  sourceName: "",
  count: 0
});

export const AddressMutualWallet = Record({
  category: "",
  walletId: "",
  tag: "",
  totalTrx: "",
  firstTrx: "",
  lastTrx: "",
  transactions: new AddressWalletMutualTransactions(),
  mostRecentCallSuccess: true,
  sentCount: 0,
  recvCount: 0
});

export const WalletMutualWallet = Record({
  category: "",
  walletId: "",
  tag: "",
  totalTrx: "",
  firstTrx: "",
  lastTrx: "",
  transactions: new WalletWalletMutualTransactions(),
  mostRecentCallSuccess: true,
  recv: 0,
  sent: 0,
  sentCount: 0,
  recvCount: 0
});

export const WalletMention = Record({
  username: "",
  onlineAccountId: "",
  sourceName: "",
  count: 0
});

// The default values for walletMentions if not found.
export const WalletMentions = Record({
  mentions: [],
  nextKey: 0
});

export const WalletSummary = Record({
  addressCount: 0,
  inputCount: 0,
  inputSatoshi: 0,
  outputCount: 0,
  outputSatoshi: 0,
  firstTransaction: 0,
  lastTransaction: 0,
  transactionCount: 0,
  balance: 0,
  anchorAddress: "",
  primaryTag: "",
  originalTag: "",
  userWalletTag: null,
  mentionsCount: 0,
  category: "",
  fetched: false,
  loading: true,
  sent_hist_usd: 0,
  recv_hist_usd: 0,
  sent_no_self: 0,
  recv_no_self: 0,
  sent_usd_no_self: 0,
  recv_usd_no_self: 0,
  attribution_source_name: null,
  peel: false,
  peel_chain_data: null,
  wisteria_attribution: null,
  wisteria_attribution_confidence_score: null,
  wisteria_attribution_probability_score: null,
  wisteria_category: null,
  wisteria_category_confidence_score: null,
  wisteria_category_probability_score: null,
  email: null,
  risk: null
});

export const WalletName = Record({
  name: null,
  address: "",
  tag: null,
  customTag: null,
  wisteria_attribution: null,
  wisteria_category: null,
  source_name: null,
  source_confidence: null
});

export const WalletAddress = Record({
  address: "",
  addressId: 0,
  transactionCount: 0,
  confidence_score: null,
  attribution_source: null
});

export const makeGetWalletRecord = WalletRecord => (state, wallet) =>
  state.get(wallet) || new WalletRecord();
