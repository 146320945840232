/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import {
  Button,
  Col,
  DropdownButton,
  Form,
  FormControl,
  FormGroup,
  OverlayTrigger
} from "react-bootstrap";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { debounce } from "lodash";
import tippy, { roundArrow } from "tippy.js";
import "tippy.js/themes/light.css";
import {
  faCaretDown,
  faCaretUp,
  faCheck,
  faRedo,
  faSearch,
  faSlidersH,
  faTrash,
  faUndo
} from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import moment from "moment";
import ReactCytoscape from "./ReactCytoscape";
import { reactRouterMatchShape } from "../../prop-types";
import CategoryColorsConfig from "./CategoryColorsConfig";
import {
  addNodeMovementToUndoStack,
  changeAttributedEdgeToggle,
  changeCaseNumber,
  changeDataTabsKey,
  changeDates,
  changeMainTabsKey,
  closeExplorerModal,
  deleteWalletsFromGraph,
  fetchGraph,
  graphAssociationSearch,
  graphSearch,
  graphShowNotesSet,
  makeNodeNote,
  openModal,
  redoGraphChange,
  saveGraph,
  setOutputWalletId,
  undoGraphChange,
  updateGraphInRedux
} from "../../actions/graph";
import { makeGetAllWalletSummaries } from "../../selectors/wallet";
import { fetchMutualTransactions, fetchWalletSummary } from "../../actions/wallet";
import ExplorerModals from "./ExplorerModals";
import Sidebar from "./Sidebar";
import cxtmenuSettings from "./cxtmenuSettings";
import { style } from "./GraphStyle";
import { onInputChange, satoshiToBitcoin } from "../../helpers";

import Search from "./Search/Search";
import axios, { RISK_SCORING_API } from "../../api";
import AssociatedSearch from "./Search/AssociatedSearch";
import { getCurrency } from "../../selectors/currency";
import ReportIssue from "../ReportIssue";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { getGraphSelectedDates, getLastSavedTimestamp } from "../../selectors/graph";
import { getFeature } from "../../selectors/features";
import { getClientMode } from "../../selectors/applicationConfig";
import ClientModeConfig from "../ClientModeConfig";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import Backdrop from "@material-ui/core/es/Backdrop/Backdrop";
import AddToNote from "../NoteEditor/AddToNote";
import { GraphDateFilter } from "./GraphDateFilter";
import { FullWidthPopover } from "../styled";
import { LimitedBackdrop } from "../Utils/LimitedBackdrop";
import { AddToAnotherGraph } from "./AddToAnotherGraph";
import { CustomLoading } from "../Utils/CustomLoading";
import { Box } from "@material-ui/core";

//cytoscape.use(popper);

class Graph extends Component {
  state = {
    walletKey: null, // subkey that determines what component to show in data tab
    modal: "",
    focusedWallet: null, // wallet as cytoscape node to show in data components
    selectedEdge: null,
    fields: {
      caseNumber: "",
      description: "",
      search: ""
    },
    showGraphData: true,
    activeMenuOption: null,
    graphStyle: {
      showLoops: false,
      showEdgeLabels: true,
      showAsUsd: false
    },
    fetchGraphComplete: false,
    showAddFromGraph: false,
    walletIdToPosition: new Map(),
    showBackdrop: false,
    loading: false,
    selectedWallets: []
  };

  saveDebouncer = debounce(() => {
    this.handleSave();
  }, 10000);

  componentWillUnmount() {
    this.props.updateGraphInRedux(this.cy);

    // Hide all tippys. Before this some tippys wouldn't go away when the component unmounted.
    this.hideTippys();
  }

  componentDidUpdate(prevProps, prevState) {
    this.handleUnlock();
    // Sort of a hack to get autosave every 3 seconds.
    // What I think should be done is have a loop that gets called on mount and
    // removed when unmounting.
    this.saveDebouncer();

    if (
      prevProps.graphInfo.attributedEdgeToggleState !==
      this.props.graphInfo.attributedEdgeToggleState
    ) {
      this.cy
        .style()
        .fromJson(
          style(
            this.state.graphStyle.showLoops ? "" : "hidden",
            this.state.graphStyle.showEdgeLabels ? "1" : "0",
            this.props.graphInfo.attributedEdgeToggleState ? "" : "hidden",
            this.state.graphStyle.showAsUsd
          )
        )
        .update();
    }
  }

  registerDirtyHandler = () => {
    this.cy.on("mousedown", () => {
      this.saveDebouncer();
    });
  };

  handleSave = (saveInDb = false) => {
    const { cy } = this;
    const { graphId } = this.props.match.params;
    this.props.updateGraphInRedux(this.cy);
    if (saveInDb) {
      this.props.saveGraph(graphId, cy);
    }
  };

  undo = async () => {
    this.props.undoGraphChange(this.cy);
    this.setState({
      focusedWallet: null,
      walletKey: null,
      showAddFromGraph: false,
      fields: {
        search: ""
      }
    });
  };

  redo = async () => {
    this.props.redoGraphChange(this.cy);
    this.setState({
      focusedWallet: null,
      walletKey: null,
      showAddFromGraph: false,
      fields: {
        search: ""
      }
    });
  };

  removeElements = () => {
    const wallets = this.cy.elements(":selected");
    this.props.removeWallets(this.cy, wallets);
  };

  cyRef = cy => {
    // Assign the reference to cy
    this.cy = cy;
    // eslint-disable-next-line
    cy.graphRef = this;
    cy.boxSelectionEnabled(true);
    cy.autounselectify(false);
    cy.cxtmenu(cxtmenuSettings);
    this.registerDirtyHandler();
    const { graphId } = this.props.match.params;

    cy.on("tap", "node[type='default']", async evt => {
      const node = evt.target;
      // This just makes it easier to not deal with fetching mutual transactions.
      // The user will need to actually select something first.
      this.props.setOutputWalletId(null);
      await this.handleSelectWalletStats(node);

      let query = this.state.fields.search;
      query = query.trim();

      if (query.length === 0) {
        this.props.associationSearch(this.state.focusedWallet.data("id"), "");
      } else {
        this.props.associationSearch(this.state.focusedWallet.data("id"), query);
      }

      if (!this.state.showGraphData) {
        this.setState({ showAddFromGraph: false, selectedEdge: null });
      }
    });

    cy.on("tap", "node[type='transaction']", async evt => {
      const node = evt.target;

      // This just makes it easier to not deal with fetching mutual transactions.
      // The user will need to actually select something first.
      this.props.setOutputWalletId(null);
      this.handleSelectTransactionNodeStats(node);
      this.setState({ selectedEdge: null });
    });

    cy.on("tap", "edge[type='default']", async evt => {
      const edge = evt.target;
      const { source: inputWalletId, target: outputWalletId } = edge.data();
      this.props.setOutputWalletId(null);
      const inputWallet = evt.cy.getElementById(inputWalletId);
      await this.handleSelectWalletStats(inputWallet);

      this.setState({
        walletKey: "WalletView",
        focusedWallet: inputWallet,
        activeMenuOption: "data",
        selectedEdge: edge
      });
      this.props.changeMainTabsKey("data");
      this.props.changeDataTabsKey("WalletMutualTransactions");
      this.props.setOutputWalletId(outputWalletId);
      this.props.fetchMutualTransactions(inputWalletId, outputWalletId);
    });

    cy.on("tap", "edge[type='transaction']", evt => {
      const edge = evt.target;
      const { source: inputWalletId, target: outputWalletId, satoshi } = edge.data();
      const inputWallet = evt.cy.getElementById(inputWalletId);
      this.setState({
        walletKey: "TransactionEdgeView",
        focusedWallet: inputWallet,
        activeMenuOption: "data",
        selectedEdge: edge
      });
      this.props.changeMainTabsKey("data");
      this.props.setOutputWalletId(outputWalletId);
    });

    // Client didn't want stats shown when nothing is selected in cytoscape.
    cy.on("tap", evt => {
      if (evt.target === cy) {
        this.setState({
          focusedWallet: null,
          walletKey: null,
          showAddFromGraph: false,
          fields: {
            search: ""
          }
        });
      }
    });

    let nodeHoverTimeout = null;
    cy.on("mouseover", "node[type='default']", async evt => {
      nodeHoverTimeout = setTimeout(async () => {
        this.hideTippys();

        // If the tippy hasn't been created yet, do so with makeNodePopper()
        if (!evt.target.tippy) {
          await this.props.fetchWalletSummary(evt.target.data("id"), graphId);
          this.makeNodePopper(evt.target);
        }

        // Show the tippy
        if (evt.target.tippy) {
          evt.target.tippy.show();
        }
      }, 250);
    });

    cy.on("mouseout", "node", evt => {
      clearTimeout(nodeHoverTimeout);
      if (evt.target.tippy) {
        evt.target.tippy.hide();
      } else {
        // If the tippy doesn't exist yet, wait 800ms and then you can hide it.
        setTimeout(() => {
          if (evt.target.tippy) {
            evt.target.tippy.hide();
            this.hideTippys();
          }
        }, 800);
      }
    });

    let edgeHoverTimeout = null;
    cy.on("mouseover", "edge[type='default']", async evt => {
      this.hideTippys();
      // If the tippy hasn't been created yet, do so with makeEdgePopper()
      // TODO if tippy loaded and time changed old tippy is still there not recalculaed
      edgeHoverTimeout = setTimeout(async () => {
        if (!evt.target.tippy) {
          const { startDate, endDate } = this.props;
          // Separate the source and destinations wallet ID's from the id string
          const targets = evt.target.data("id").split("+");
          // Make an api call to get the edge data
          let data;
          try {
            data = await axios.get(
              `/api/v2/${this.props.currency}/graph/edge/${targets[0]}/${targets[1]}`,
              {
                params: {
                  ...(startDate && { startDate: startDate.getTime() / 1000 }),
                  ...(endDate && { endDate: endDate.getTime() / 1000 })
                }
              }
            );
          } catch (e) {
            // N/A data to put if the call fails
            data = {
              data: {
                transactionCount: "N/A",
                firstTransaction: "N/A",
                lastTransaction: "N/A"
              }
            };
          }
          this.makeEdgePopper(evt.target, data.data, targets);
        }

        // Show the tippy
        if (evt.target.tippy) {
          evt.target.tippy.show();
        }
      }, 250);
    });

    cy.on("mouseout", "edge", evt => {
      clearTimeout(edgeHoverTimeout);
      // Hide the tippy
      if (evt.target.tippy) {
        evt.target.tippy.hide();
      } else {
        // If the tippy doesn't exist yet, wait 800ms and then you can hide it. Try again after another 800 to be safe
        setTimeout(() => {
          if (evt.target.tippy) {
            evt.target.tippy.hide();
            this.hideTippys();
          }
        }, 800);
      }
    });

    cy.on("grab", "node", evt => {
      // This is used to get previous positions for dragged nodes.
      const walletIdToPosition = new Map();

      evt.target
        .cy()
        .nodes(":selected")
        .union(evt.target)
        .forEach(wallet => {
          // Need to make copy or else cytoscape will update the reference!
          const position = {
            x: wallet.position("x"),
            y: wallet.position("y")
          };
          walletIdToPosition.set(wallet.id(), position);
        });

      this.setState({
        walletIdToPosition
      });
    });

    cy.on("dragfreeon", "node", evt => {
      // This only has the node directly clicked on for the evt. The user can select
      // multiple wallets and move them at once, so all selected nodes are grabbed
      // here.
      // dragfree doesn't provide what we want either.
      const walletIds = evt.target
        .cy()
        .nodes(":selected")
        .union(evt.target)
        .map(wallet => wallet.id());
      this.props.addNodeMovementToUndoStack(cy, walletIds, this.state.walletIdToPosition);
    });

    cy.on("select", "node", evt => {
      // This only has the node directly clicked on for the evt. The user can select
      // multiple wallets and move them at once, so all selected nodes are grabbed
      // here.
      // dragfree doesn't provide what we want either.
      const walletIds = evt.target
        .cy()
        .nodes(":selected")
        .map(wallet => ({
          ...wallet.data()
        }));
      console.log(walletIds);
      this.setState({ selectedWallets: walletIds });
    });

    cy.on("unselect", "node", evt => {
      // This only has the node directly clicked on for the evt. The user can select
      // multiple wallets and move them at once, so all selected nodes are grabbed
      // here.
      // dragfree doesn't provide what we want either.
      const walletIds = evt.target
        .cy()
        .nodes(":selected")
        .filter(wallet => wallet.data("type") === "default")
        .map(wallet => wallet.id());
      this.setState({ selectedWallets: walletIds });
    });

    cy.on("dragfreeon", "node", evt => {
      // This only has the node directly clicked on for the evt. The user can select
      // multiple wallets and move them at once, so all selected nodes are grabbed
      // here.
      // dragfree doesn't provide what we want either.
      const walletIds = evt.target
        .cy()
        .nodes(":selected")
        .union(evt.target)
        .map(wallet => wallet.id());
      this.props.addNodeMovementToUndoStack(cy, walletIds, this.state.walletIdToPosition);
    });

    this.forceUpdate();
  };

  selectElementFromSearchResult = async id => {
    this.handleUnlock();

    // Unselect all elements
    this.cy.elements().unselect();
    let node = this.cy.getElementById(id);
    this.cy.getElementById(id).select();

    this.cy.zoom(1.25);
    this.cy.center(node);

    this.props.setOutputWalletId(null);
    await this.handleSelectWalletStats(node);

    // Since an element is selected, do the association search in the backgroun
    let query = this.state.fields.search;
    query = query.trim();

    if (query.length === 0) {
      this.props.associationSearch(this.state.focusedWallet.data("id"), "");
    } else {
      this.props.associationSearch(this.state.focusedWallet.data("id"), query);
    }
  };

  handleSelectColorPicker = wallet => {
    this.setState({
      walletKey: "WalletColorPicker",
      focusedWallet: wallet,
      activeMenuOption: "data",
      modal: "WalletColorPicker"
    });
    this.props.changeMainTabsKey("data");
  };

  handleSelectTransactionNodeStats = wallet => {
    this.setState({
      walletKey: "TransactionNodeView",
      focusedWallet: wallet,
      activeMenuOption: "data",
      fields: {
        search: ""
      }
    });
    this.props.changeMainTabsKey("data");
    this.props.changeDataTabsKey("TransactionNodeStats");
  };

  handleSelectWalletStats = async wallet => {
    await this.setState({
      walletKey: "WalletView",
      focusedWallet: wallet,
      showAddFromGraph: false,
      selectedEdge: null,
      activeMenuOption: "data",
      fields: {
        search: ""
      }
    });
    this.props.changeMainTabsKey("data");
    this.props.changeDataTabsKey("WalletStats");
  };

  /*
   * handleSelectTagSetter() sets data tab to WalletTagSetter
   */
  handleSelectTagSetter = wallet => {
    this.setState({
      walletKey: "WalletTagSetter",
      focusedWallet: wallet,
      activeMenuOption: "data",
      modal: "WalletTagSetter"
    });
    this.props.changeMainTabsKey("data");
  };

  handleSelectNoteSetter = wallet => {
    this.setState({
      walletKey: "WalletNoteSetter",
      focusedWallet: wallet,
      activeMenuOption: "data",
      modal: "WalletNoteSetter"
    });
    this.props.changeMainTabsKey("data");
  };

  handleSearchFormSubmit = async e => {
    e.preventDefault();
    let query = this.state.fields.search;
    query = query.trim();

    if (
      this.state.focusedWallet === null ||
      this.state.walletKey === "TransactionNodeView" ||
      this.state.walletKey === "TransactionEdgeView"
    ) {
      if (query.length === 0) {
        return;
      }
      await this.props.search(query, this.cy);
    } else if (query.length === 0) {
      await this.props.associationSearch(
        this.state.focusedWallet.data("id"),
        "",
        this.state.focusedWallet.data("type") === "custom"
      );
    } else {
      await this.props.associationSearch(
        this.state.focusedWallet.data("id"),
        query,
        this.state.focusedWallet.data("type") === "custom"
      );
    }
    this.props.changeMainTabsKey("search");
    this.setState({ fields: { search: "" } });
  };

  handleOpenChangeTitleModal = () => this.setState({ modal: "changeTitle" });

  handleCloseModal = () => this.setState({ modal: null });

  handleOpenChangeCaseNumberModal = () => this.setState({ modal: "changeCaseNumber" });

  handleChangeCaseNumber = e => {
    e.preventDefault();
    // this should change the value in the redux store and mark the graph
    // as dirty
    const { caseNumber } = this.state.fields;
    this.setState({ modal: null });
    this.props.changeCaseNumber(caseNumber);
    this.handleSave();
  };

  handleChangeAttributedEdgeToggle = toggleState => {
    this.props.changeAttributedEdgeToggle(toggleState);
    this.handleSave(true);
  };

  // Dumb bug that happens every once in a while where the graph is completely
  // or partially locked up.
  handleUnlock = () => {
    this.cy.nodes().map(node => node.grabify());
    this.cy.zoomingEnabled(true);
    this.cy.userZoomingEnabled(true);
    this.cy.panningEnabled(true);
    this.cy.userPanningEnabled(true);
  };

  toggleVisible = () => {
    this.setState({ showGraphData: !this.state.showGraphData });
  };

  // Toggle which menu item should be visible
  toggleMenuOption = menuItem => {
    if (this.state.activeMenuOption === menuItem) {
      this.setState({ activeMenuOption: null });
    } else {
      this.setState({ activeMenuOption: menuItem });
    }
  };

  toggleLoops = e => {
    e.preventDefault();
    this.setState(
      {
        graphStyle: {
          showLoops: !this.state.graphStyle.showLoops,
          showEdgeLabels: this.state.graphStyle.showEdgeLabels,
          showAsUsd: this.state.graphStyle.showAsUsd
        }
      },
      () =>
        this.cy
          .style()
          .fromJson(
            style(
              this.state.graphStyle.showLoops ? "" : "hidden",
              this.state.graphStyle.showEdgeLabels ? "1" : "0",
              this.props.graphInfo.attributedEdgeToggleState ? "" : "hidden",
              this.state.graphStyle.showAsUsd
            )
          )
          .update()
    );
  };

  toggleAttributedEdges = toggleState => {
    this.handleChangeAttributedEdgeToggle(toggleState);

    this.setState(
      {
        graphStyle: {
          showLoops: this.state.graphStyle.showLoops,
          showEdgeLabels: this.state.graphStyle.showEdgeLabels,
          showAsUsd: this.state.graphStyle.showAsUsd
        }
      },
      () =>
        this.cy
          .style()
          .fromJson(
            style(
              this.state.graphStyle.showLoops ? "" : "hidden",
              this.state.graphStyle.showEdgeLabels ? "1" : "0",
              toggleState ? "" : "hidden",
              this.state.graphStyle.showAsUsd
            )
          )
          .update()
    );
  };

  toggleUsd = e => {
    this.setState(
      {
        graphStyle: {
          showLoops: this.state.graphStyle.showLoops,
          showEdgeLabels: this.state.graphStyle.showEdgeLabels,
          showAsUsd: !this.state.graphStyle.showAsUsd
        }
      },
      () =>
        this.cy
          .style()
          .fromJson(
            style(
              this.state.graphStyle.showLoops ? "" : "hidden",
              this.state.graphStyle.showEdgeLabels ? "1" : "0",
              this.props.graphInfo.attributedEdgeToggleState ? "" : "hidden",
              this.state.graphStyle.showAsUsd
            )
          )
          .update()
    );
  };

  hideTippys = () => {
    // Hide all non-note tippys
    this.cy.elements().forEach(node => {
      if (node.tippy) {
        node.tippy.hide();
      }
    });
  };

  colors = clientModeObj =>
    CategoryColorsConfig(clientModeObj).map(categoryConfig => {
      const { label, hex } = categoryConfig;
      return (
        <li className="dropdownKeyItem" style={{ textAlign: "center" }}>
          <p>{label}</p>
          {label === `Cold ${clientModeObj.cap_word_for_collection}` ? (
            <svg
              style={{ marginBottom: "25px", marginTop: "13px" }}
              height="50px"
              width="50px"
              aria-hidden="true"
              data-prefix="far"
              data-icon="snowflake"
              className="svg-inline--fa fa-snowflake fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="175 175 150 150"
            >
              <path
                stroke="black"
                strokeWidth="9"
                strokeOpacity="1.0"
                fill="white"
                d="M440.1 355.2l-39.2-23 34.1-9.3c8.4-2.3 13.4-11.1 11.1-19.6l-4.1-15.5c-2.2-8.5-10.9-13.6-19.3-11.3L343 298.2 271.2 256l71.9-42.2 79.7 21.7c8.4 2.3 17-2.8 19.3-11.3l4.1-15.5c2.2-8.5-2.7-17.3-11.1-19.6l-34.1-9.3 39.2-23c7.5-4.4 10.1-14.2 5.8-21.9l-7.9-13.9c-4.3-7.7-14-10.3-21.5-5.9l-39.2 23 9.1-34.7c2.2-8.5-2.7-17.3-11.1-19.6l-15.2-4.1c-8.4-2.3-17 2.8-19.3 11.3l-21.3 81-71.9 42.2v-84.5L306 70.4c6.1-6.2 6.1-16.4 0-22.6l-11.1-11.3c-6.1-6.2-16.1-6.2-22.2 0l-24.9 25.4V16c0-8.8-7-16-15.7-16h-15.7c-8.7 0-15.7 7.2-15.7 16v46.1l-24.9-25.4c-6.1-6.2-16.1-6.2-22.2 0L142.1 48c-6.1 6.2-6.1 16.4 0 22.6l58.3 59.3v84.5l-71.9-42.2-21.3-81c-2.2-8.5-10.9-13.6-19.3-11.3L72.7 84c-8.4 2.3-13.4 11.1-11.1 19.6l9.1 34.7-39.2-23c-7.5-4.4-17.1-1.8-21.5 5.9l-7.9 13.9c-4.3 7.7-1.8 17.4 5.8 21.9l39.2 23-34.1 9.1c-8.4 2.3-13.4 11.1-11.1 19.6L6 224.2c2.2 8.5 10.9 13.6 19.3 11.3l79.7-21.7 71.9 42.2-71.9 42.2-79.7-21.7c-8.4-2.3-17 2.8-19.3 11.3l-4.1 15.5c-2.2 8.5 2.7 17.3 11.1 19.6l34.1 9.3-39.2 23c-7.5 4.4-10.1 14.2-5.8 21.9L10 391c4.3 7.7 14 10.3 21.5 5.9l39.2-23-9.1 34.7c-2.2 8.5 2.7 17.3 11.1 19.6l15.2 4.1c8.4 2.3 17-2.8 19.3-11.3l21.3-81 71.9-42.2v84.5l-58.3 59.3c-6.1 6.2-6.1 16.4 0 22.6l11.1 11.3c6.1 6.2 16.1 6.2 22.2 0l24.9-25.4V496c0 8.8 7 16 15.7 16h15.7c8.7 0 15.7-7.2 15.7-16v-46.1l24.9 25.4c6.1 6.2 16.1 6.2 22.2 0l11.1-11.3c6.1-6.2 6.1-16.4 0-22.6l-58.3-59.3v-84.5l71.9 42.2 21.3 81c2.2 8.5 10.9 13.6 19.3 11.3L375 428c8.4-2.3 13.4-11.1 11.1-19.6l-9.1-34.7 39.2 23c7.5 4.4 17.1 1.8 21.5-5.9l7.9-13.9c4.6-7.5 2.1-17.3-5.5-21.7z"
              />
            </svg>
          ) : (
            <span
              className="dot-dropdown"
              style={{
                backgroundColor: hex
              }}
            />
          )}
        </li>
      );
    });

  makeNodePopper = ele => {
    const element = this.props.walletSummaries[ele.data("id")];
    ele.tippy = new tippy(document.createElement("div"), {
      getReferenceClientRect: ele.popperRef().getBoundingClientRect,
      content: () => {
        return `
          <Table style="width: 280px">
            <tr>
               <td class="text-left header">Address Count</td>
               <td class="text-left">${element.summary.addressCount.toLocaleString()}</td>
            </tr>
            <tr>
               <td class="text-left header">Transaction Count</td>
               <td class="text-left">${element.summary.transactionCount.toLocaleString()}</td>
            </tr>
            <tr>
               <td class="text-left header">Balance (BTC)</td>
               <td class="text-left">${satoshiToBitcoin(element.summary.balance)}</td>
            </tr>
            <tr>
               <td class="text-left header">First Trx</td>
               <td class="text-left">${moment
                 .unix(element.summary.firstTransaction)
                 .utc()
                 .format("LLL")}</td>
          </tr>
          <tr>
             <td class="text-left header">Latest Trx</td>
             <td class="text-left">${moment
               .unix(element.summary.lastTransaction)
               .utc()
               .format("LLL")}</td></tr>
          </Table>`;
      },
      theme: "light",
      trigger: "manual",
      // interactive: "false",
      arrow: roundArrow,
      allowHTML: "true"
    });
  };

  makeEdgePopper = (ele, data) => {
    const trxCount =
      data.transactionCount < 50000 ? data.transactionCount.toLocaleString() : ">50k";
    const sourceLabel = ele
      .cy()
      .nodes(node => node.data("id") === ele.data("source"))
      .data("label");
    const timeout = ele.data("timeout");
    const targetLabel = ele
      .cy()
      .nodes(node => node.data("id") === ele.data("target"))
      .data("label");
    ele.tippy = new tippy(document.createElement("div"), {
      getReferenceClientRect: ele.popperRef().getBoundingClientRect,
      content: () => {
        return `
          <div style="border-bottom: 1px solid #67cdef; padding-bottom: 5px; margin-bottom: 5px; text-align: left;">
            <span class="edgeTippyHeader">${sourceLabel}</span> 
            <span style="font-family: Quicksand, sans-serif; font-size: 14px; margin-left: 5px; margin-right: 4px; font-weight: 500;"> to </span> 
            <span class="edgeTippyHeader">${targetLabel}</span>
          </div>
          ${
            trxCount !== "NA"
              ? `<Table style="width: 300px"> 
            <tr>
              <td class="text-left header">Transaction Count</td>
              <td class="text-left">${trxCount}</td>
            </tr>
            <tr>
               <td class="text-left header">First Trx</td>
               <td class="text-left">${moment
                 .unix(data.firstTransaction)
                 .utc()
                 .format("LLL")}</td>
            </tr>
            <tr>
               <td class="text-left header">Latest Trx</td>
               <td class="text-left">${moment
                 .unix(data.lastTransaction)
                 .utc()
                 .format("LLL")}</td>
            </tr>
          </Table>`
              : ""
          }
          ${
            timeout
              ? '<div style="border-top: 1px solid #67cdef; padding-bottom: 5px; margin-bottom: 5px; text-align: left;"> \
            <span class="edgeTippyHeader">Timed out for the selected time range</span>  \
          </div>'
              : ""
          }`;
      },
      followCursor: true,
      theme: "light",
      trigger: "manual",
      // interactive: "true",
      arrow: roundArrow,
      allowHTML: "true" // probably want manual mode
    });
  };

  onInputChange = onInputChange.bind(this);

  showAddFromGraph = () => {
    this.setState({ showAddFromGraph: !this.state.showAddFromGraph });
  };

  showNotes = () => {
    this.props.setShowNotes(this.cy, !this.props.graphInfo.showNotes);
  };

  /**
   * Function when date range is changed
   * @param startDate
   * @param endDate
   * @returns {Promise<void>}
   */
  setDate = async (startDate, endDate) => {
    startDate = isNaN(startDate) ? null : startDate;
    endDate = isNaN(endDate) ? null : endDate;

    // make sure to only load new dates if submitted different dates
    if (startDate !== this.props.startDate || endDate !== this.props.endDate) {
      this.setState({ loading: true });
      try {
        await this.props.changeDates(this.cy, startDate, endDate);
        this.handleSave(true);
      } catch (e) {
        console.error(e);
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  render() {
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    const { modal, graphStyle } = this.state;

    const { cy } = this;

    const { graphId } = this.props.match.params;
    const { handleCloseExplorerModal, currentQuery, lastSaved } = this.props;
    const {
      caseNumber,
      description,
      currentSet,
      entity,
      entityType,
      graph,
      attributedEdgeToggleState
    } = this.state.fetchGraphComplete
      ? this.props.graphInfo
      : {
          caseNumber: "",
          description: "",
          editorContent: null,
          graph: null,
          undoCanGoBack: false,
          undoCanGoForward: false,
          showNotes: false,
          attributedEdgeToggleState: true
        };

    if (cy) {
      cy.elements().forEach(node => {
        // If no note exists but there is a non-empty "notes" string, make the note tippy
        if (
          ((node.note && node.note.state.isDestroyed) || !node.note) &&
          node.data("notes") !== "" &&
          node.data("notes") !== undefined &&
          node.data("notes") !== null &&
          this.props.graphInfo.showNotes
        ) {
          makeNodeNote(node, true);
        }
      });
    }

    return (
      <div
        style={{
          marginTop: "-10px",
          paddingTop: "0",
          height: "calc(100vh - 65px)"
          //zIndex: "10000",
        }}
      >
        <Helmet>
          <title>{`Graph ${caseNumber} ${description}`}</title>
        </Helmet>
        {graph === null && (
          <Backdrop style={{ color: "#fff", zIndex: "10001" }} open={true}>
            <CustomLoading
              content={
                clientModeConfigObject.icon && (
                  <img
                    style={{
                      paddingLeft: "0",
                      paddingRight: "0",
                      height: "100px",
                      width: "100px"
                    }}
                    src={clientModeConfigObject.icon}
                    alt=""
                  />
                )
              }
            />
          </Backdrop>
        )}
        <div className="container-fluid" style={{ height: "100%", overflowY: "hidden" }}>
          <div className="row" style={{ display: "flex", height: "100%" }}>
            <Sidebar
              activeMenuOption={this.state.activeMenuOption}
              showGraphData={this.state.showGraphData}
              caseNumber={caseNumber}
              description={description}
              graphId={graphId}
              toggleVisible={this.toggleVisible}
              toggleMenuOption={this.toggleMenuOption}
              cy={cy}
              focusedWallet={this.state.focusedWallet}
              walletKey={this.state.walletKey}
              currentQuery={currentQuery}
              modal={modal}
              handleOpenLoadGraphModal={this.handleOpenLoadGraphModal}
              handleChangeDescription={this.handleOpenChangeTitleModal}
              handleChangeCaseNumber={this.handleOpenChangeCaseNumberModal}
              handleSearchFormSubmit={this.handleSearchFormSubmit}
              handleCloseModal={this.handleCloseModal}
              lastSaved={lastSaved}
              selectedEdge={this.state.selectedEdge}
              selectElementFromSearchResult={this.selectElementFromSearchResult}
              fetchGraphComplete={this.state.fetchGraphComplete}
              disabled={this.state.loading}
            />
            <Col
              lg={this.state.showGraphData ? 7 : 11}
              style={{ backgroundColor: "white", padding: "0" }}
            >
              <div style={{ height: "100%" }}>
                <div
                  style={{
                    backgroundColor: "var(--graph-background-color)",
                    borderColor: "transparent",
                    height: "100%"
                  }}
                >
                  <LimitedBackdrop open={this.state.loading}>
                    <CustomLoading
                      content={
                        clientModeConfigObject.icon && (
                          <img
                            style={{
                              paddingLeft: "0",
                              paddingRight: "0",
                              height: "100px",
                              width: "100px"
                            }}
                            src={clientModeConfigObject.icon}
                            alt=""
                          />
                        )
                      }
                    />
                  </LimitedBackdrop>
                  <div
                    className="graphButtons"
                    style={{
                      position: "absolute",
                      zIndex: "999",
                      backgroundColor: "var(--graph-button-bar-color)",
                      padding: "10px"
                    }}
                  >
                    <Button
                      className="graphToggle"
                      style={{ display: "inline-block", position: "relative" }}
                      onClick={this.showAddFromGraph}
                    >
                      <FontAwesomeIcon icon={faSearch} style={{ marginRight: 7 }} />
                      Search
                      <FontAwesomeIcon
                        style={{ marginLeft: 7 }}
                        icon={this.state.showAddFromGraph ? faCaretUp : faCaretDown}
                      />
                    </Button>
                    <DropdownButton
                      className="graphToggle positiveLeft"
                      title={<FontAwesomeIcon icon={faSlidersH} />}
                      onClick={() => {
                        if (this.state.showAddFromGraph) {
                          this.setState({ showAddFromGraph: false });
                        }
                      }}
                    >
                      <ul
                        style={{
                          listStyleType: "none",
                          padding: "0",
                          margin: "0",
                          width: "350px"
                        }}
                      >
                        <li>
                          <div
                            onClick={e => this.toggleLoops(e)}
                            style={{
                              marginRight: "5px",
                              marginLeft: "10px",
                              display: "inline-block",
                              height: "20px",
                              width: "20px",
                              border: "2px solid var(--base-color)",
                              backgroundColor: "white",
                              verticalAlign: "middle",
                              textAlign: "center",
                              fontFamily: "Quicksand",
                              cursor: "pointer"
                            }}
                          >
                            {this.state.graphStyle.showLoops ? (
                              <FontAwesomeIcon
                                style={{
                                  color: "var(--base-color)",
                                  paddingBottom: "2px"
                                }}
                                icon={faCheck}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <p style={{ display: "inline", fontWeight: "600" }}>Show Loops</p>
                        </li>
                        <li>
                          <div
                            onClick={e => this.toggleAttributedEdges(!attributedEdgeToggleState)}
                            style={{
                              marginRight: "5px",
                              marginLeft: "10px",
                              display: "inline-block",
                              height: "20px",
                              width: "20px",
                              border: "2px solid var(--base-color)",
                              backgroundColor: "white",
                              verticalAlign: "middle",
                              textAlign: "center",
                              fontFamily: "Quicksand",
                              cursor: "pointer"
                            }}
                          >
                            {attributedEdgeToggleState ? (
                              <FontAwesomeIcon
                                style={{
                                  color: "var(--base-color)",
                                  paddingBottom: "2px"
                                }}
                                icon={faCheck}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <p style={{ display: "inline", fontWeight: "600" }}>
                            Show Edges Between Attributed Entities
                          </p>
                        </li>
                        <li>
                          <div
                            onClick={e => this.toggleUsd(e)}
                            style={{
                              marginRight: "5px",
                              marginLeft: "10px",
                              display: "inline-block",
                              height: "20px",
                              width: "20px",
                              border: "2px solid var(--base-color)",
                              backgroundColor: "white",
                              verticalAlign: "middle",
                              textAlign: "center",
                              fontFamily: "Quicksand",
                              cursor: "pointer"
                            }}
                          >
                            {graphStyle.showAsUsd ? (
                              <FontAwesomeIcon
                                style={{
                                  color: "var(--base-color)",
                                  paddingBottom: "2px"
                                }}
                                icon={faCheck}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <p style={{ display: "inline", fontWeight: "600" }}>
                            Show Edges as Historical USD
                          </p>
                        </li>
                        <li>
                          <div
                            onClick={e => this.showNotes(e)}
                            style={{
                              marginRight: "5px",
                              marginLeft: "10px",
                              display: "inline-block",
                              height: "20px",
                              width: "20px",
                              border: "2px solid var(--base-color)",
                              backgroundColor: "white",
                              verticalAlign: "middle",
                              textAlign: "center",
                              fontFamily: "Quicksand",
                              cursor: "pointer"
                            }}
                          >
                            {this.props.graphInfo.showNotes ? (
                              <FontAwesomeIcon
                                style={{
                                  color: "var(--base-color)",
                                  paddingBottom: "2px"
                                }}
                                icon={faCheck}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <p style={{ display: "inline", fontWeight: "600" }}>Show Notes</p>
                        </li>
                      </ul>
                    </DropdownButton>
                    <GraphDateFilter
                      startDate={this.props.startDate || null}
                      endDate={this.props.endDate || null}
                      setDate={this.setDate}
                      disabled={this.state.loading}
                    />
                    <AddToNote page={"graph"} itemId={graphId} />
                    {this.props.issuesEnabled && (
                      <ReportIssue
                        entityName={graphId}
                        entityType={"graph"}
                        currency={this.props.currency}
                        buttonClass="issueButtonGraph"
                      />
                    )}
                    <DropdownButton
                      className="graphToggle negativeLeft"
                      style={{
                        overflowY: "scroll !important",
                        maxHeight: 300
                      }}
                      title="Key"
                      onClick={() => {
                        if (this.state.showAddFromGraph) {
                          this.setState({ showAddFromGraph: false });
                        }
                      }}
                    >
                      {this.colors(clientModeConfigObject)}
                    </DropdownButton>
                    <Button
                      className="graphToggle"
                      onClick={this.undo}
                      disabled={!this.props.graphInfo.undoCanGoBack}
                    >
                      <FontAwesomeIcon icon={faUndo} />
                    </Button>
                    <Button
                      className="graphToggle"
                      onClick={this.redo}
                      disabled={!this.props.graphInfo.undoCanGoForward}
                    >
                      <FontAwesomeIcon icon={faRedo} />
                    </Button>
                    <OverlayTrigger
                      overlay={
                        <FullWidthPopover style={{ fontFamily: "Quicksand", fontWeight: "600" }}>
                          Remove Selected
                        </FullWidthPopover>
                      }
                      placement="bottom"
                      delayShow={300}
                      delayHide={150}
                    >
                      <Button
                        className="graphToggleRed"
                        onClick={this.removeElements}
                        disabled={this.state.loading}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={
                        <FullWidthPopover style={{ fontFamily: "Quicksand", fontWeight: "600" }}>
                          Save
                        </FullWidthPopover>
                      }
                      placement="bottom"
                      delayShow={300}
                      delayHide={150}
                    >
                      <Button
                        className="greenButtonGraphToolbar"
                        onClick={() => this.handleSave(true)}
                        disabled={this.state.loading}
                      >
                        <FontAwesomeIcon icon={faSave} />
                      </Button>
                    </OverlayTrigger>
                    {this.state.showAddFromGraph && (
                      <div
                        style={{
                          overflowY: "auto",
                          maxHeight: "400px",
                          width: "100%",
                          marginTop: "10px"
                        }}
                      >
                        <span
                          style={{
                            paddingLeft: "10px",
                            fontFamily: "Quicksand",
                            fontWeight: "700",
                            color: "var(--graph-button-bar-header-color)",
                            fontSize: "16px"
                          }}
                        >
                          {this.state.focusedWallet === null ||
                          this.state.walletKey === "TransactionNodeView" ||
                          this.state.walletKey === "TransactionEdgeView"
                            ? `Search All ${clientModeConfigObject.cap_plural_for_collection}`
                            : `Search ${
                                clientModeConfigObject.cap_plural_for_collection
                              } Interacting with ${this.state.focusedWallet.data("label")}`}
                        </span>
                        <Form style={{ minWidth: "310px" }}>
                          <FormGroup
                            controlId="search"
                            style={{
                              margin: "0",
                              padding: "0"
                            }}
                          >
                            <div
                              className="row"
                              style={{
                                paddingBottom: "10px",
                                paddingLeft: "10px",
                                margin: "0",
                                width: "100%"
                              }}
                            >
                              <div
                                className="col-lg-8 searchFilterPurple"
                                style={{
                                  margin: "0",
                                  paddingRight: "0",
                                  paddingLeft: "0",
                                  paddingBottom: "0"
                                }}
                              >
                                <FormControl
                                  type="text"
                                  placeholder={`Address/${clientModeConfigObject.cap_word_for_collection}/Transaction...`}
                                  value={this.state.fields.search}
                                  onChange={this.onInputChange}
                                  style={{
                                    fontSize: "14px",
                                    marginBottom: "0",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}
                                />
                              </div>
                              <div
                                className="col-lg-4"
                                style={{
                                  margin: "0",
                                  paddingLeft: "0"
                                }}
                              >
                                <Button
                                  className="allPurpleButton"
                                  style={{
                                    display: "inline-block",
                                    marginTop: "10px",
                                    marginBottom: "0",
                                    height: "34px"
                                  }}
                                  onClick={this.handleSearchFormSubmit}
                                  type="submit"
                                >
                                  <FontAwesomeIcon icon={faSearch} /> Search
                                </Button>
                              </div>
                            </div>
                          </FormGroup>
                        </Form>
                        <div
                          style={{
                            marginRight: "0",
                            marginLeft: "0",
                            marginTop: "5px",
                            marginBottom: "10px"
                          }}
                        >
                          {cy &&
                            (this.state.focusedWallet === null ||
                            this.state.walletKey === "TransactionNodeView" ||
                            this.state.walletKey === "TransactionEdgeView" ? (
                              <>
                                <Search
                                  cy={cy}
                                  fromSidebar={false}
                                  selectElementFromSearchResult={this.selectElementFromSearchResult}
                                  disabled={this.state.loading}
                                />
                              </>
                            ) : (
                              <AssociatedSearch
                                cy={cy}
                                fromSidebar={false}
                                focusedWallet={this.state.focusedWallet.data("label")}
                                selectElementFromSearchResult={this.selectElementFromSearchResult}
                                disabled={this.state.loading}
                              />
                            ))}
                        </div>
                      </div>
                    )}
                    {this.state.selectedWallets && this.state.selectedWallets.length > 1 && (
                      <div
                        style={{
                          width: "100%",
                          marginTop: "10px"
                        }}
                      >
                        <AddToAnotherGraph
                          selectedWallets={this.state.selectedWallets}
                          curGraph={graphId}
                        />
                      </div>
                    )}
                  </div>
                  <ReactCytoscape
                    graphId={graphId}
                    cyRef={cy_ => {
                      this.cyRef(cy_);
                    }}
                    cytoscapeOptions={{
                      wheelSensitivity: 0.1,
                      hideEdgesOnViewport: true
                    }}
                    layout={{ name: "dagre" }}
                    attributedEdgeToggleState={attributedEdgeToggleState}
                    blob={"hey"}
                    fetch={this.props.fetch}
                    loadStatus={() => {
                      this.setState({ fetchGraphComplete: true });
                    }}
                    open={this.state.loading}
                  ></ReactCytoscape>
                </div>
              </div>
            </Col>
          </div>
          <ExplorerModals
            currentSet={currentSet}
            entityType={entityType}
            entity={entity}
            handleCloseModal={handleCloseExplorerModal}
            cy={cy}
            graphId={graphId}
            disabled={this.state.loading}
          />
        </div>
      </div>
    );
  }
}

Graph.propTypes = {
  match: reactRouterMatchShape({
    graphId: PropTypes.string.isRequired
  }).isRequired,
  caseNumber: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  changeCaseNumber: PropTypes.func.isRequired,
  changeDescription: PropTypes.func.isRequired,
  changeMainTabsKey: PropTypes.func.isRequired,
  changeDataTabsKey: PropTypes.func.isRequired,
  setOutputWalletId: PropTypes.func.isRequired,
  currentQuery: PropTypes.string,
  search: PropTypes.func.isRequired,
  associationSearch: PropTypes.func.isRequired,
  handleCloseExplorerModal: PropTypes.func.isRequired,
  entity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/no-unused-prop-types
  deleteWalletsFromGraph: PropTypes.func.isRequired,
  updateGraphInRedux: PropTypes.func.isRequired,
  importGraph: PropTypes.func.isRequired,
  saveGraph: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  fetchMutualTransactions: PropTypes.func.isRequired,
  fetchWalletSummary: PropTypes.func.isRequired,
  addWalletToGraph: PropTypes.func.isRequired,
  undoCanGoBack: PropTypes.bool.isRequired,
  undoCanGoForward: PropTypes.bool.isRequired,
  removeWallets: PropTypes.func.isRequired
};

Graph.defaultProps = {
  entity: null,
  currentQuery: ""
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { graphId }
    }
  }
) => {
  return {
    ...makeGetAllWalletSummaries(state, false, false, graphId),
    currency: getCurrency(state),
    lastSaved: getLastSavedTimestamp(state),
    issuesEnabled: getFeature(state, "issues"),
    clientMode: getClientMode(state),
    ...getGraphSelectedDates(state, graphId)
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { graphId }
    }
  }
) => {
  return {
    changeAttributedEdgeToggle: toggleState =>
      dispatch(changeAttributedEdgeToggle(graphId, toggleState)),
    changeDates: (cy, startDate, endDate) => dispatch(changeDates(cy, graphId, startDate, endDate)),
    changeMainTabsKey: mainTabsKey => dispatch(changeMainTabsKey(mainTabsKey)),
    changeDataTabsKey: dataTabsKey => dispatch(changeDataTabsKey(dataTabsKey)),
    setOutputWalletId: outputWalletId => dispatch(setOutputWalletId(outputWalletId)),
    fetchMutualTransactions: (inputWalletId, outputWalletId) => {
      dispatch(fetchMutualTransactions(inputWalletId, outputWalletId, true));
    },
    search: (query, cy) => dispatch(graphSearch(query, cy)),
    associationSearch: (walletId, query, custom) =>
      dispatch(graphAssociationSearch(walletId, query, custom)),
    saveGraph: (graphIds, cy) => dispatch(saveGraph(graphIds, cy)),
    handleCloseExplorerModal: () => dispatch(closeExplorerModal()),
    deleteWalletsFromGraph: (cy, elements) =>
      dispatch(deleteWalletsFromGraph(graphId, cy, elements, true)),
    updateGraphInRedux: cy => dispatch(updateGraphInRedux(graphId, cy)),
    openModal: () => dispatch(openModal()),
    fetchWalletSummary: (walletId, graphId) => dispatch(fetchWalletSummary(walletId, graphId)),
    undoGraphChange: cy => dispatch(undoGraphChange(graphId, cy)),
    redoGraphChange: cy => dispatch(redoGraphChange(graphId, cy)),
    addNodeMovementToUndoStack: (cy, walletIds, walletIdToPosition) => {
      dispatch(addNodeMovementToUndoStack(cy, walletIds, walletIdToPosition));
    },
    setShowNotes: (cy, showNotes) => dispatch(graphShowNotesSet(cy, showNotes)),
    removeWallets: (cy, walletIds) =>
      dispatch(deleteWalletsFromGraph(graphId, cy, walletIds, true)),
    fetch: (graphId, cy, fetchGraphComplete) =>
      dispatch(fetchGraph(graphId, cy, fetchGraphComplete))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Graph);
