/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { reactRouterHistoryShape } from "../../prop-types";
import { getFeature } from "../../selectors/features";
import { getRole } from "../../selectors/authentication";
import { getCurrency } from "../../selectors/currency";
import ResearcherIssues from "./ResearcherIssues";
import UserIssues from "./UserIssues";
import AdminIssues from "./AdminIssues";

const throwError = () => {
  throw new Error("This is an error thrown for testing purposes.");
};

/**
 * List of notes associated with user with form modal
 * for creating new notes.
 */
class Issues extends Component {
  state = {
    notes: [],
    showModal: false,
    title: "",
  };

  handleChange = e => {
    this.setState({ title: e.target.value });
  };

  render() {
    return this.props.role === "researcher" ? (
      <ResearcherIssues currency={this.props.currency} sendTotal={this.props.sendTotal} />
    ) : this.props.role === "admin" ? (
      <>
        <UserIssues currency={this.props.currency} sendTotal={this.props.sendTotal} />
        <br />
        <AdminIssues currency={this.props.currency} />
      </>
    ) : (
      <UserIssues currency={this.props.currency} sendTotal={this.props.sendTotal} />
    );
  }
}

Issues.propTypes = {
  history: reactRouterHistoryShape.isRequired,
  throwExceptionButtonEnabled: PropTypes.bool.isRequired,
  isResearcher: PropTypes.bool.isRequired,
  sendTotal: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    throwExceptionButtonEnabled: getFeature(state, "throw_exception_test"),
    role: getRole(state),
    currency: getCurrency(state),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Issues)
);
