import { AddressSummary, Transactions } from "../../reducers/ethereum/address";

export const getName = addressSummary => {
  let names = [];

  addressSummary.token &&
    addressSummary.token.token_name &&
    names.push({
      source: "token",
      source_name: "Token",
      source_confidence: null,
      name: addressSummary.token.token_name
    });
  addressSummary.attribution &&
    names.push({
      source: "attribution",
      source_name: addressSummary.source_name,
      source_confidence: addressSummary.source_confidence,
      name: addressSummary.attribution
    });
  addressSummary.ens_names &&
    addressSummary.ens_names.length > 0 &&
    names.push(
      ...addressSummary.ens_names.map(elem => ({
        source: "ens",
        source_name: "ENS Name",
        source_confidence: null,
        ...elem
      }))
    );
  addressSummary.primaryTag &&
    !names.find(({ name }) => addressSummary.primaryTag === name) &&
    names.unshift({
      source: "Ethereum Custom Tag",
      name: addressSummary.primaryTag,
      source_name: "Custom",
      source_confidence: null
    });
  return names;
};

export const getAddress = (state, address) => {
  return state.getIn(["ethereum", "address", address]);
};

export const getAddressSummary = (state, address, noNull = false) => {
  const addressMap = getAddress(state, address);
  if (addressMap) {
    // In case the address is loaded but the summary wasn't
    return addressMap.get("summary").toJS();
  }
  if (noNull) {
    return new AddressSummary().toJS();
  }
  return null;
};

export const getTokenInfo = (state, address) => {
  const addressMap = getAddress(state, address);
  if (addressMap) {
    return addressMap
      .get("summary")
      .get("tokens")
      .toJS();
  }
  return null;
};

export const getAddressCoinSwap = (state, address, noNull = false) => {
  const addressMap = getAddress(state, address);
  if (addressMap) {
    return {
      coin_swap_data: addressMap.get("coin_swap_data").toJS(),
      coin_swap: addressMap.get("coin_swap")
    };
  }
  if (noNull) {
    return {
      coin_swap: false,
      coin_swap_data: []
    };
  }
  return null;
};

export const getAddressTokens = (state, address, noNull = false) => {
  const addressMap = getAddress(state, address);
  if (addressMap) {
    return addressMap.get("tokens").toJS();
  }
  if (noNull) {
    return [];
  }
  return null;
};

export const getAddressTransactions = (state, address, token, filter = null, noNull = false) => {
  const addressMap = getAddress(state, address);
  if (addressMap) {
    let transactions = addressMap.get("transactions");
    if (filter) {
      const filterString = JSON.stringify(filter);
      transactions = transactions.get(filterString);
    } else {
      transactions = transactions.get("base");
    }
    if (!transactions) {
      return new Transactions().toJS();
    }
    return transactions.get(token) ? transactions.get(token).toJS() : new Transactions().toJS();
  }
  if (noNull) {
    return new Transactions().toJS();
  }
  return null;
};

export const getInternalTransactions = (state, address, filter = null, noNull = false) => {
  const addressMap = getAddress(state, address);
  if (addressMap) {
    let transactions = addressMap.get("internalTransactions");
    if (filter) {
      const filterString = JSON.stringify(filter);
      transactions = transactions.get(filterString);
    } else {
      transactions = transactions.get("base");
    }
    if (!transactions) {
      return new Transactions().toJS();
    }
    return transactions.toJS();
  }
  if (noNull) {
    return new Transactions().toJS();
  }
  return null;
};

export const getAddressMutualTransactions = (state, source, target, token, noNull = false) => {
  const addressMap = getAddress(state, source);
  if (addressMap) {
    const transactions = addressMap.getIn(["mutualTransactions", target, "transactions"]);
    return transactions && transactions.get(token || "all")
      ? transactions.get(token || "all").toJS()
      : new Transactions().toJS();
  }
  if (noNull) {
    return new Transactions().toJS();
  }
  return null;
};

export const getAddressMutualTokens = (state, source, target, noNull = false) => {
  const addressMap = getAddress(state, source);
  if (addressMap) {
    const targetMutual = addressMap.getIn(["mutualTransactions", target, "tokens"]);
    return targetMutual ? targetMutual : [];
  }
  if (noNull) {
    return [];
  }
  return null;
};
