/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

/*
 * Handles the different ways an address cell can be displayed
 */
import PropTypes from "prop-types";
import React, { userState } from "react";
import { connect } from "react-redux";
import { Popover, Modal } from "react-bootstrap";
import { faStickyNote } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";

import { fetchAddressMentions } from "../../actions/address";
import { makeGetAddressMentions } from "../../selectors/address";
import CopyText from "../CopyText";

const AddressCell = ({
  address,
  walletId,
  handleOpenAddressModal,
  getAddressLink,
  mentions,
  fetchMentions,
  keyProp,
  isChange,
  currency,
  exists,
  isNote,
  onAddressNoteClick
}) => {
  // When address is actually a wallet
  // I have no idea how address could be 'null' as a string here
  // This might not be needed anymore with the transaction component refactor
  //   const [showAddAddressNoteModal, changeAddAddressNoteModal] = useState(false);
  if (/^\d+$/.test(address)) {
    return <td />;
  }

  if (address == null || (address.length > 0 && address[0] === "")) {
    return <td>Nonstandard Address</td>;
  }

  if (address[0] === "Coinbase") {
    return <td>Coinbase</td>;
  }

  // This is a mess and probably mostly unneeded, but the gist is-
  // Address can be null when the output is nonstandard or its part of a
  // coinbase transaction.
  if (address !== "null" && address[0] !== "Nonstandard Address") {
    // outputs with same wallet as input are marked as change
    const addressCells = address.map(subaddress => {
      if (walletId != null) {
        // ???
        if (isChange) {
          return `${subaddress} (change)`;
        }
      }
      return subaddress;
    });

    // Only show first 20 mentions to avoid huge popover.
    const mentionsList = mentions
      .slice(0, 20)
      .map(m => <li key={m.onlineAccountId}>{m.username}</li>);

    const popover = (
      <Popover id={`${keyProp}-popover`} title="Mentions" placement="right">
        {mentionsList.length > 0 ? mentionsList : "None"}
      </Popover>
    );

    return (
      <td
        style={{
          fontSize: "12px",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        <div>
          {addressCells.map((addressCell, index) => {
            return (
              <div
                key={addressCell}
                style={{
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap"
                }}
              >
                {isNote && isNote[index] === "transaction" && (
                  <FontAwesomeIcon
                    style={{
                      cursor: "pointer",
                      padding: "2px",
                      color: "orange",
                      fontSize: "15px"
                    }}
                    icon={faStickyNote}
                    onClick={() => onAddressNoteClick(address[index].split(":").pop())}
                  />
                )}

                <CopyText text={address[index].split(":").pop()} marginLeft="3px" />
                {exists ? (
                  getAddressLink(address[index], addressCell, handleOpenAddressModal, currency)
                ) : (
                  <span>{addressCell.split(":").pop()}</span>
                )}

                <br />
              </div>
            );
          })}
        </div>
      </td>
    );
  }

  // Pretty much here just in case.
  return <td>Nonstandard Address</td>;
};

AddressCell.propTypes = {
  address: PropTypes.arrayOf(PropTypes.string).isRequired,
  walletId: PropTypes.number,
  handleOpenAddressModal: PropTypes.func,
  getAddressLink: PropTypes.func.isRequired,
  mentions: PropTypes.arrayOf(
    PropTypes.shape({
      onlineAccountId: PropTypes.number.isRequired,
      sourceName: PropTypes.string.isRequired,
      hasContent: PropTypes.bool.isRequired
    })
  ),
  fetchMentions: PropTypes.func.isRequired,
  keyProp: PropTypes.string.isRequired,
  isChange: PropTypes.bool
};

AddressCell.defaultProps = {
  walletId: null,
  handleOpenAddressModal: null,
  mentions: [],
  address: ""
};

const makeMapStateToProps = () => {
  const getAddressMentions = makeGetAddressMentions();
  return (state, { address }) => getAddressMentions(state, address);
};

const mapDispatchToProps = dispatch => ({
  fetchMentions: address => dispatch(fetchAddressMentions(address, true))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(AddressCell);
