/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { hot } from "react-hot-loader";

import Graphs from "./Graphs";
import Notes from "./Notes";
import CustomWallets from "./CustomWallets";
import { reactRouterHistoryShape } from "../../prop-types";
import { getFeature } from "../../selectors/features";
import { getClientMode } from "../../selectors/applicationConfig";
import Issues from "./Issues";
import { CustomTags } from "./CustomTags/CustomTags";
import ClientModeConfig from "../ClientModeConfig";
import Watchlist from "./Watchlist/Watchlist";
import { setWalletTag } from "../../actions/customTags";
import { getCurrency } from "../../selectors/currency";
import { TransactionSearcher } from "../Transaction/TransactionSearcher";
import RiskScore from "./RiskScore";
import { Box, Container, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { TabContext } from "@material-ui/lab";
import { fetchCurrencyUsdValues } from "../../actions/currency";
import { CircularProgressWithContent, CustomLoading } from "../Utils/CustomLoading";
import WhitesailHeader from "../../../img/logo-small-2.png";

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <div hidden={value !== index}>{children}</div>
      </Box>
    </div>
  );
};

const Home = props => {
  const [issuesTotal, setIssuesTotal] = useState(0);
  const [watchlistTotal, setWatchlistTotal] = useState(0);
  const [usdLoading, setUsdLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchUsd = async () => {
      try {
        await dispatch(fetchCurrencyUsdValues());
        setUsdLoading(true);
      } catch (e) {
        if (e.response && e.response.status === 401) {
          setUsdLoading(false);
        } else {
          setUsdLoading(true);
        }
      }
    };
    fetchUsd();
  }, []);

  const getHelmet = clientModeConfigObject => {
    const { tab } = props;

    let title;
    if (tab === "case-management") {
      title = "Case Management";
    } else if (tab === "custom-wallet") {
      title = `Custom ${clientModeConfigObject.cap_plural_for_collection}`;
    } else if (tab === "updates") {
      title = "Updates";
    } else if (tab === "support") {
      title = "Support";
    } else if (tab === "custom-tags") {
      title = "Custom Tags";
    } else if (tab === "watchlist") {
      title = "Watchlist";
    } else if (tab === "transaction-searcher") {
      title = "Trx Searcher";
    } else if (tab === "risk-scoring") {
      title = "Risk Scoring";
    } else {
      title = "Graphs";
    }

    return (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    );
  };
  const {
    tab,
    customWalletsEnabled,
    issuesEnabled,
    watchlistEnabled,
    searcherEnabled,
    clientMode,
    currency,
    riskScoringEnabled
  } = props;
  const handleSelect = key => {
    const { history } = props;
    if (key === "case-management") {
      history.push("/case-management");
    } else if (key === "custom-wallet") {
      history.push("/custom-wallet");
    } else if (key === "custom-tags") {
      history.push("/custom-tags");
    } else if (key === "watchlist") {
      history.push("/watchlist");
    } else if (key === "graphs") {
      history.push("/graphs");
    } else if (key === "updates") {
      history.push("/updates");
    } else if (key === "support") {
      history.push("/support");
    } else if (key === "transaction-searcher") {
      history.push("/transaction-searcher");
    } else if (key === "risk-scoring") {
      history.push("/risk-scoring");
    }
  };

  const sendIssuesTotal = total => {
    setIssuesTotal(total);
  };

  const sendWatchlistTotal = total => {
    setWatchlistTotal(total);
  };

  const getIssuesBadge = (total, name) => {
    if (total === 0) {
      return (
        <div>
          <p style={{ display: "inline", paddingRight: "4px" }}>{name}</p>
        </div>
      );
    }
    return (
      <div>
        <p style={{ display: "inline", paddingRight: "10px" }}>{name}</p>
        <span className="badge progress-bar-success">{total}</span>
      </div>
    );
  };

  const customTheme = createTheme({
    palette: {
      primary: {
        light: "#42c2f5",
        main: "rgba(0,0,0,0.5)",
        dark: "#778899",
        contrastText: "#fff",
        fontFamily: "Quicksand"
      }
    }
  });

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    tabs: {
      "& .MuiButtonBase-root.MuiTab-root": {
        fontSize: 12,
        fontFamily: "Quicksand",
        borderRight: "2px solid #b8b6b6",
        width: "150px"
      },
      "& .MuiTabs-indicator": {
        display: "none",
        backgroundColor: "orange"
      },
      "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
        color: "var(--secondary-color-dark-hover)",
        fontSize: "14px !important",
        borderRight: "2px solid var(--secondary-color-dark-hover)"
      }
    },
    tab: {
      minWidth: "200px",
      width: "200px",
      fontSize: 13,
      fontFamily: "Quicksand"
    }
  }));

  const classes = useStyles();

  let clientModeConfigObject = {
    word_for_collection: "",
    cap_word_for_collection: "",
    plural_for_collection: "",
    cap_plural_for_collection: "",
    nav_logo: null,
    icon: null,
    login_logo: null,
    login_top_logo: null,
    login_top_icon: null
  };
  if (clientMode in ClientModeConfig) {
    clientModeConfigObject = ClientModeConfig[clientMode];
  }
  return (
    <ThemeProvider theme={customTheme}>
      <Container>
        {usdLoading ? (
          <TabContext value={tab}>
            {getHelmet(clientModeConfigObject)}

            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "flex" }}>
                {currency !== "ethereum" ? (
                  <Tabs
                    value={tab}
                    onChange={(e, nv) => handleSelect(nv)}
                    aria-label="basic tabs example"
                    className={classes.tabs}
                    orientation="vertical"
                    TabIndicatorProps={{ style: { background: "var(--secondary-color)" } }}
                    sx={{
                      borderRight: 1,
                      borderColor: "divider",
                      width: "150px"
                    }}
                  >
                    <Tab
                      label="Case Management"
                      value={"case-management"}
                      className={classes.tab}
                      id={"simple-tab-case-management"}
                      aria-controls={"simple-tabpanel-case-management"}
                    />
                    <Tab
                      label="Graphs"
                      value={"graphs"}
                      className={classes.tab}
                      id={"simple-tab-0"}
                      aria-controls={"simple-tabpanel-0"}
                    />
                    {customWalletsEnabled && (
                      <Tab
                        label={`Custom ${clientModeConfigObject.cap_plural_for_collection}`}
                        value={"custom-wallet"}
                        className={classes.tab}
                        id={"simple-tab-2"}
                        aria-controls={"simple-tabpanel-2"}
                      />
                    )}
                    <Tab
                      label="Custom Tags"
                      value={"custom-tags"}
                      className={classes.tab}
                      id={"simple-tab-case-management"}
                      aria-controls={"simple-tabpanel-case-management"}
                    />
                    {watchlistEnabled && (
                      <Tab
                        label={getIssuesBadge(watchlistTotal, "Watchlists")}
                        value={"watchlist"}
                        className={classes.tab}
                        id={"simple-tab-note"}
                        aria-controls={"simple-tabpanel-note"}
                      />
                    )}
                    {searcherEnabled && (
                      <Tab
                        label="Trx Searcher"
                        value={"transaction-searcher"}
                        className={classes.tab}
                        id={"simple-tab-trx-searcher"}
                        aria-controls={"simple-tabpanel-trx-searcher"}
                      />
                    )}
                    {issuesEnabled && (
                      <Tab
                        label={getIssuesBadge(issuesTotal, "Support")}
                        value={"support"}
                        className={classes.tab}
                        id={"simple-tab-2"}
                        aria-controls={"simple-tabpanel-2"}
                      />
                    )}
                    {currency !== "dash" && currency !== "doge" && riskScoringEnabled === true && (
                      <Tab
                        label={"Risk Scoring"}
                        value={"risk-scoring"}
                        className={classes.tab}
                        id={"simple-tab-risk"}
                        aria-controls={"simple-tabpanel-risk"}
                      />
                    )}
                  </Tabs>
                ) : (
                  <Tabs
                    value={tab}
                    onChange={(e, nv) => handleSelect(nv)}
                    aria-label="basic tabs example"
                    className={classes.tabs}
                    orientation="vertical"
                    sx={{
                      borderRight: 1,
                      borderColor: "divider",
                      width: "100px"
                    }}
                    TabIndicatorProps={{ style: { background: "var(--secondary-color)" } }}
                  >
                    <Tab
                      label="Case Management"
                      value={"case-management"}
                      className={classes.tab}
                      id={"simple-tab-note"}
                      aria-controls={"simple-tabpanel-note"}
                    />
                    <Tab
                      label="Graphs"
                      value={"graphs"}
                      className={classes.tab}
                      id={"simple-tab-0"}
                      aria-controls={"simple-tabpanel-0"}
                    />
                    <Tab
                      label="Custom Tags"
                      value={"custom-tags"}
                      className={classes.tab}
                      id={"simple-tab-note"}
                      aria-controls={"simple-tabpanel-note"}
                    />
                    <Tab
                      label="Risk Scoring"
                      value={"risk-scoring"}
                      className={classes.tab}
                      id={"simple-tab-case-management"}
                      aria-controls={"simple-tabpanel-case-management"}
                    />
                  </Tabs>
                )}
              </Box>
              <Box sx={{ display: "flex" }}>
                <TabPanel value={tab} index={"graphs"}>
                  <Graphs />
                </TabPanel>
                <TabPanel value={tab} index={"case-management"}>
                  <Notes />
                </TabPanel>
                <TabPanel value={tab} index={"support"}>
                  <Issues sendTotal={sendIssuesTotal} />
                </TabPanel>
                <TabPanel value={tab} index={"custom-wallet"}>
                  {currency !== "ethereum" && <CustomWallets />}
                </TabPanel>
                <TabPanel value={tab} index={"custom-tags"}>
                  <CustomTags />
                </TabPanel>
                <TabPanel value={tab} index={"watchlist"}>
                  {currency !== "ethereum" && <Watchlist sendTotal={sendWatchlistTotal} />}
                </TabPanel>
                <TabPanel value={tab} index={"transaction-searcher"}>
                  <TransactionSearcher />
                </TabPanel>
                <TabPanel value={tab} index={"risk-scoring"}>
                  <RiskScore />
                </TabPanel>
              </Box>
            </Box>
          </TabContext>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <CustomLoading
              content={
                clientModeConfigObject.icon && (
                  <img
                    style={{ paddingLeft: "0", paddingRight: "0", height: "100px", width: "100px" }}
                    src={clientModeConfigObject.icon}
                    alt=""
                  />
                )
              }
            />
          </Box>
        )}
      </Container>
    </ThemeProvider>
  );
};

Home.propTypes = {
  tab: PropTypes.string.isRequired,
  history: reactRouterHistoryShape.isRequired,
  customWalletsEnabled: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    customWalletsEnabled: getFeature(state, "custom_wallets"),
    issuesEnabled: getFeature(state, "issues"),
    clientMode: getClientMode(state),
    currency: getCurrency(state),
    watchlistEnabled: getFeature(state, "watchlist"),
    ethereumEnabled: getFeature(state, "ethereum"),
    searcherEnabled: getFeature(state, "trx_searcher"),
    riskScoringEnabled: getFeature(state, "risk_scoring")
  };
};

export default hot(module)(
  connect(
    mapStateToProps,
    null
  )(Home)
);
