/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Grid, Row, Tab, Tabs } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { hot } from "react-hot-loader";
import AddressNoteEditor from "../NoteEditor/AddressNoteEditor";
import { connect } from "react-redux";

import {
  getAddressCoinSwap,
  getAddressRisk,
  getScrollTop,
  retrieveBsaCount,
  retrieveMentionsCount
} from "../../selectors/address";
import AddressTags from "./AddressMentions";
import AddressSummary from "./AddressSummary";
import {
  fetchAddressCoinSwap,
  fetchAddressMentions,
  fetchAddressMoreInfo,
  fetchAddressMutualWallets,
  fetchAddressSummary,
  fetchAddressTransactions,
  fetchBsa,
  fetchAddressClusterHistory,
  fetchMoreAddressSummary
} from "../../actions/address";
import { createGraphInNewTabWithPreload } from "../../actions/graph";
import AddressBSA from "./AddressBSA";
import withAddPreloadModals from "../withAddPreloadModals";
import { getFeature } from "../../selectors/features";
import { getCurrency, getUsdForCoin } from "../../selectors/currency";
import { convertPriceToUsd } from "../../helpers";
import IpAddressSummary from "./IpAddressSummary";
import { getClientMode } from "../../selectors/applicationConfig";
import AddressTransactionsAll from "./AddressTransactionsAll";
import { RiskScoreBase } from "../Risk/RiskScoreBase";

export const getTabBadge = (title, count) => {
  if (count === 0) {
    return (
      <div>
        <p style={{ display: "inline", paddingRight: "4px" }}>{title}</p>
        <span className="badge progress-bar-danger">{count}</span>
      </div>
    );
  }
  return (
    <div>
      <p style={{ display: "inline", paddingRight: "4px" }}>{title}</p>
      <span className="badge progress-bar-info">{count}</span>
    </div>
  );
};

/**
 * Main address component that displays a summary, transactions, and tags/mentions
 */
class Address extends Component {
  state = {
    usd: false,
    change: true,
    histUsd: false,
    ipBadgeLength: 0,
    risk: 0,
    gambling_ok_risk: 0,
    sanctions_risk: 0
  };

  async componentWillMount() {
    const { address } = this.props;
    await this.props.fetchAddressSummary(address);
    this.props.fetchMoreAddressSummary(address);
    this.props.fetchAddressTags(address);
    this.props.fetchAddressMutualWallets(address);
    if (this.props.currency === "bitcoin" && this.props.moreInfoEnabled) {
      this.props.fetchAddressMoreInfo(address);
    }
    this.props.fetchAddressCoinSwap(address);
    this.props.fetchAddressClusterHistory(address);
    this.setTableScrollTop();
    if (this.props.bsaTagsEnabled) {
      this.props.fetchBsa(address);
    }
  }

  /**
   * Sets the tab key to match the url
   * @param {object} prevProps
   */
  async componentDidUpdate(prevProps) {
    // Make back button work when changing addresses
    const { address: oldAddress } = prevProps;
    const { address: newAddress } = this.props;
    if (oldAddress !== newAddress) {
      await this.props.fetchAddressSummary(newAddress);
      this.props.fetchMoreAddressSummary(newAddress);
      this.props.fetchAddressTags(newAddress);
      this.props.fetchAddressMutualWallets(newAddress);
      if (this.props.currency === "bitcoin" && this.props.moreInfoEnabled) {
        this.props.fetchAddressMoreInfo(newAddress);
      }
      this.props.fetchAddressCoinSwap(newAddress);
      this.props.fetchAddressClusterHistory(newAddress);
      this.setTableScrollTop();
      if (this.props.bsaTagsEnabled) {
        this.props.fetchBsa(newAddress);
      }
    }
  }

  setTableScrollTop = () => {
    // const container = document.getElementById("addressTransactionsTable");
    // if (this.props.scrollTop != null) {
    //   container.scrollTop = this.props.scrollTop;
    // }
  };

  setUsdDisplay = value => {
    this.setState({
      usd: value,
      histUsd: false
    });
  };

  setChange = value => {
    this.setState({
      change: value
    });
  };

  setHistUsd = value => {
    this.setState({
      histUsd: value,
      usd: false
    });
  };

  /**
   * Sets the title to <address> (Transactions | Tags)
   * @param {string} key tab key
   * @returns {React.Component}
   */
  getHelmet = key => {
    const { address } = this.props;
    let tab;
    if (key === "transactions") {
      tab = "Transactions";
    } else if (key === "ip-addresses") {
      tab = "IP Address";
    } else if (key === "notes") {
      tab = "Note";
    } else if (key === "risk") {
      tab = "risk";
    } else {
      tab = "Tags";
    }

    return (
      <Helmet>
        <title>{`Addr ${address} ${tab}`}</title>
      </Helmet>
    );
  };

  getMentionsBadge() {
    return getTabBadge("Mentions", this.props.retrieveMentionsCount);
  }

  sendBackData = (length, risk, gambling_ok_risk, sanctions_risk) => {
    this.setState({
      ipBadgeLength: length,
      risk,
      gambling_ok_risk,
      sanctions_risk
    });
  };
  getIpBadge() {
    return getTabBadge("IP Addresses", this.state.ipBadgeLength);
  }

  getBsaBadge() {
    return getTabBadge("Bsa Ids", this.props.retrieveBsaCount);
  }

  getRiskBadge() {
    return getTabBadge("Risk", this.props.risk.level);
  }

  disableMentions() {
    const x = this.props.retrieveMentionsCount;
    return x === 0;
  }

  disableIps() {
    const x = this.state.ipBadgeLength;
    return x === 0;
  }

  disableBsa() {
    const x = this.props.retrieveBsaCount;
    return x === 0;
  }

  render() {
    const {
      address,
      tabKey,
      getTransactionLink,
      onTabSelect,
      getWalletLink,
      getCreateGraphModal,
      bsaTagsEnabled,
      handleOpenWalletModal,
      currency,
      moreInfoEnabled,
      clientMode,
      risk
    } = this.props;

    return (
      <div>
        <Grid>
          <Row>
            <p
              style={{
                textAlign: "center",
                color: "red",
                fontFamily: "Quicksand",
                fontWeight: "700",
                fontSize: "16px"
              }}
            >
              {/*Application of the Attribution Filter is taking longer than usual. Please bear with us*/}
              {/*as we work to improve the response time.*/}
            </p>
          </Row>
          <Row>
            <AddressSummary
              address={address}
              getWalletLink={getWalletLink}
              addToGraph={() => getCreateGraphModal()}
              handleOpenWalletModal={handleOpenWalletModal}
              currency={currency}
              clientMode={clientMode}
              moreInfoEnabled={moreInfoEnabled}
              convertPriceToUsd={convertPriceToUsd}
              currentUsdPrice={this.props.usd}
              toggleUsdDisplay={this.setUsdDisplay}
              showAsUsd={this.state.usd}
              historicalToUsd={this.state.histUsd}
              toggleHistoricalUsd={this.setHistUsd}
              showChange={this.state.change}
              toggleChange={this.setChange}
              ip={this.state.ip}
              risk={this.state.risk}
              gambling_ok_risk={this.state.gambling_ok_risk}
              sanctions_risk={this.state.sanctions_risk}
              coinSwap={this.props.coinSwap}
              coinSwapData={this.props.coinSwap ? this.props.coinSwapData[0] : {}}
              customWalletEnabled={
                this.props.customWalletEnabled === undefined || !!this.props.customWalletEnabled
              }
            />
          </Row>
          <Row style={{ paddingRight: "0", paddingTop: "0" }}>
            <div
              className="container"
              style={{ paddingRight: "0", paddingTop: "0", marginTop: "10px" }}
            >
              {this.getHelmet(tabKey)}
              <Tabs
                className="inviteTabs"
                activeKey={tabKey}
                id="address-tab"
                onSelect={onTabSelect}
                animation={false}
              >
                <Tab eventKey="transactions" title="Address Transactions">
                  <AddressTransactionsAll
                    address={address}
                    maxHeight={700}
                    getTransactionLink={getTransactionLink}
                    currency={currency}
                    convertPriceToUsd={convertPriceToUsd}
                    currentUsdPrice={this.props.usd}
                    toggleUsdDisplay={this.setUsdDisplay}
                    showAsUsd={this.state.usd}
                    historicalToUsd={this.state.histUsd}
                    toggleHistoricalUsd={this.setHistUsd}
                    showChange={this.state.change}
                    toggleChange={this.setChange}
                  />
                </Tab>
                <Tab
                  eventKey="mentions"
                  title={this.getMentionsBadge()}
                  disabled={this.disableMentions()}
                  style={{
                    backgroundColor: "var(--secondary-color)",
                    paddingBottom: "15px",
                    marginTop: "10px"
                  }}
                >
                  <AddressTags address={address} maxHeight={700} currency={currency} />
                </Tab>
                <Tab
                  eventKey="ip-addresses"
                  title={this.getIpBadge()}
                  disabled={this.disableIps()}
                  style={{
                    paddingBottom: "15px",
                    paddingTop: "15px",
                    marginTop: "10px"
                  }}
                >
                  <IpAddressSummary
                    address={address}
                    visible={tabKey === "ip-addresses"}
                    sendBackData={this.sendBackData}
                  />
                </Tab>
                {bsaTagsEnabled && (
                  <Tab eventKey="bsa" title={this.getBsaBadge()} disabled={this.disableBsa()}>
                    <AddressBSA address={address} maxHeight={700} />
                  </Tab>
                )}
                <Tab eventKey="notes" title="Note">
                  <AddressNoteEditor
                    address={address}
                    mainLocation={"address"}
                    mainLocationName={address}
                  ></AddressNoteEditor>
                </Tab>
                {risk && (
                  <Tab eventKey="risk" title={this.getRiskBadge()}>
                    <RiskScoreBase type={"address"} results={risk} />
                  </Tab>
                )}
              </Tabs>
            </div>
          </Row>
        </Grid>
      </div>
    );
  }
}

Address.propTypes = {
  fetchAddressSummary: PropTypes.func.isRequired,
  fetchMoreAddressSummary: PropTypes.func.isRequired,
  fetchAddressTransactions: PropTypes.func.isRequired,
  fetchAddressTags: PropTypes.func.isRequired,
  fetchAddressMutualWallets: PropTypes.func.isRequired,
  fetchAddressMoreInfo: PropTypes.func.isRequired,
  fetchAddressCoinSwap: PropTypes.func.isRequired,
  fetchAddressClusterHistory: PropTypes.func.isRequired,
  getTransactionLink: PropTypes.func.isRequired,
  handleOpenWalletModal: PropTypes.func,
  address: PropTypes.string.isRequired,
  tabKey: PropTypes.string.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  getWalletLink: PropTypes.func.isRequired,
  fetchBsa: PropTypes.func.isRequired,
  retrieveBsaCount: PropTypes.number.isRequired,
  retrieveMentionsCount: PropTypes.number.isRequired,
  getCreateGraphModal: PropTypes.func.isRequired,
  bsaTagsEnabled: PropTypes.bool.isRequired,
  scrollTop: PropTypes.number
};

Address.defaultProps = {
  scrollTop: null,
  handleOpenWalletModal: null,
  ip: []
};

const mapStateToProps = (state, { address }) => {
  return {
    retrieveBsaCount: retrieveBsaCount(state, address),
    retrieveMentionsCount: retrieveMentionsCount(state, address),
    bsaTagsEnabled: getFeature(state, "bsa_tags"),
    scrollTop: getScrollTop(state, address),
    currency: getCurrency(state),
    clientMode: getClientMode(state),
    moreInfoEnabled: getFeature(state, "address_more_info"),
    usd: getUsdForCoin(state),
    ...getAddressCoinSwap(state, address),
    risk: getAddressRisk(state, address)
  };
};

const mapDispatchToProps = (dispatch, { address: addr }) => ({
  fetchAddressSummary: address => dispatch(fetchAddressSummary(address)),
  fetchMoreAddressSummary: address => dispatch(fetchMoreAddressSummary(address)),
  fetchAddressTags: address => dispatch(fetchAddressMentions(address, true)),
  fetchBsa: address => dispatch(fetchBsa(address, true)),
  fetchAddressMoreInfo: address => dispatch(fetchAddressMoreInfo(address)),
  fetchAddressCoinSwap: address => dispatch(fetchAddressCoinSwap(address)),
  fetchAddressClusterHistory: address => dispatch(fetchAddressClusterHistory(address)),
  fetchAddressTransactions: (address, order, startDate, endDate) => {
    dispatch(fetchAddressTransactions(address, order, startDate, endDate, true));
  },
  createGraph: (caseNumber, description, isPublic) => {
    dispatch(
      createGraphInNewTabWithPreload(caseNumber, description, isPublic, {
        preloadAddresses: [addr],
        preloadWallets: [],
        preloadTransactions: [],
        preloadUnclusteredTransactions: []
      })
    );
  },
  fetchAddressMutualWallets: address => dispatch(fetchAddressMutualWallets(address, true))
});

const getPreloadInfo = ({ address }) => ({
  type: "address",
  item: address,
  processed: true
});

export default hot(module)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withAddPreloadModals(Address, getPreloadInfo))
);
