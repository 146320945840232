// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { combineReducers } from "redux-immutable";

import authentication from "./authentication";
import transaction from "./transaction";
import makeAddressReducer from "./address";
import makeMentionReducer from "./mention";
import makeWalletReducer from "./wallet";
import makeNotificationReducer from "./notification";
import makeGraphReducer from "./graph";
import features from "./features";
import currency from "./currency";
import makeSearchReducer from "./search";
import makeResearchReducer from "./research";
import colorScheme from "./colorScheme";
import makeAddressReducerEthereum from "./ethereum/address";
import makeWalletReducerEthereum from "./ethereum/wallet";
import makeTransactionReducerEthereum from "./ethereum/transaction";
import makeSearchReducerEthereum from "./ethereum/search";
import makeFuzzySearchReducerEthereum from "./ethereum/fuzzySearch";
import coins from "./coins";
import messages from "./messages";

import makeGraphReducerEthereum from "./ethereum/graph";

const makeCombinedRedeucers = coin =>
  combineReducers({
    transaction: transaction(coin),
    address: makeAddressReducer(coin),
    mention: makeMentionReducer(coin),
    wallet: makeWalletReducer(coin),
    notification: makeNotificationReducer(coin),
    graph: makeGraphReducer(coin),
    search: makeSearchReducer(coin),
    research: makeResearchReducer(coin)
  });

const bitcoin = combineReducers({
  transaction: transaction("bitcoin"),
  address: makeAddressReducer("bitcoin"),
  mention: makeMentionReducer("bitcoin"),
  wallet: makeWalletReducer("bitcoin"),
  notification: makeNotificationReducer("bitcoin"),
  graph: makeGraphReducer("bitcoin"),
  search: makeSearchReducer("bitcoin"),
  research: makeResearchReducer("bitcoin")
});

const bitcoinCash = combineReducers({
  transaction: transaction("bitcoin-cash"),
  address: makeAddressReducer("bitcoin-cash"),
  mention: makeMentionReducer("bitcoin-cash"),
  wallet: makeWalletReducer("bitcoin-cash"),
  notification: makeNotificationReducer("bitcoin-cash"),
  graph: makeGraphReducer("bitcoin-cash"),
  search: makeSearchReducer("bitcoin-cash"),
  research: makeResearchReducer("bitcoin-cash")
});

const bitcoinSv = combineReducers({
  transaction: transaction("bitcoin-sv"),
  address: makeAddressReducer("bitcoin-sv"),
  mention: makeMentionReducer("bitcoin-sv"),
  wallet: makeWalletReducer("bitcoin-sv"),
  notification: makeNotificationReducer("bitcoin-sv"),
  graph: makeGraphReducer("bitcoin-sv"),
  search: makeSearchReducer("bitcoin-sv"),
  research: makeResearchReducer("bitcoin-sv")
});

const bitcoinGold = combineReducers({
  transaction: transaction("bitcoin-gold"),
  address: makeAddressReducer("bitcoin-gold"),
  mention: makeMentionReducer("bitcoin-gold"),
  wallet: makeWalletReducer("bitcoin-gold"),
  notification: makeNotificationReducer("bitcoin-gold"),
  graph: makeGraphReducer("bitcoin-gold"),
  search: makeSearchReducer("bitcoin-gold"),
  research: makeResearchReducer("bitcoin-gold")
});

const litecoin = combineReducers({
  transaction: transaction("litecoin"),
  address: makeAddressReducer("litecoin"),
  mention: makeMentionReducer("litecoin"),
  wallet: makeWalletReducer("litecoin"),
  notification: makeNotificationReducer("litecoin"),
  graph: makeGraphReducer("litecoin"),
  search: makeSearchReducer("litecoin"),
  research: makeResearchReducer("litecoin")
});

const ethereum = combineReducers({
  address: makeAddressReducerEthereum("ethereum"),
  wallet: makeWalletReducerEthereum("ethereum"),
  transaction: makeTransactionReducerEthereum("ethereum"),
  search: makeSearchReducer("ethereum"),
  notification: makeNotificationReducer("ethereum"),
  graph: makeGraphReducerEthereum("ethereum")
});

const dash = makeCombinedRedeucers("dash");

const doge = makeCombinedRedeucers("doge");

const app = combineReducers({
  authentication,
  features,
  coins,
  messages,
  currency,
  applicationCustomizations: colorScheme,
  bitcoin,
  "bitcoin-cash": bitcoinCash,
  "bitcoin-sv": bitcoinSv,
  "bitcoin-gold": bitcoinGold,
  litecoin,
  ethereum,
  dash,
  doge
});

export default app;
