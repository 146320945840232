// Copyright 2018-2020 DecisionQ Information Operations, Inc. All Rights Reserved.

import { Record } from "immutable";

import { LOGIN_SUCCESS } from "../actions/actionNames";

export const ApplicationCustomizations = Record({
  colorScheme: "default",
  clientMode: "vci",
});

const colorScheme = (state = ApplicationCustomizations(), action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return new ApplicationCustomizations({
        colorScheme: action.colorScheme,
        clientMode: action.clientMode,
      });
    default:
      return state;
  }
};

export default colorScheme;
