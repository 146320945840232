import { Backdrop, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

/**
 * Puts a backdrop over the parent component
 * @type {React.ComponentType<Omit<JSX.LibraryManagedAttributes<*, React.ComponentProps<*>>, keyof ({theme: Theme} & {classes: ClassNameMap<ClassKeyOfStyles<"root">>}) | {classes: ClassNameMap<ClassKeyOfStyles<"root">>}> & StyledComponentProps<"root">>}
 */
export const LimitedBackdrop = ({ open = false, ...props }) => {
  const [value, setValue] = useState(open);
  useEffect(() => {
    setValue(open);
  }, [open]);
  const useStyles = makeStyles(theme => ({
    root: {
      position: "absolute",
      zIndex: 11,
      backdropFilter: "blur(2px)",
      backgroundColor: "#0000003d"
    }
  }));
  const classes = useStyles();
  return (
    <Backdrop
      key={open}
      open={value}
      classes={{
        root: classes.root
      }}
    >
      {props.children}
    </Backdrop>
  );
};
