import { fromJS, List, Record } from "immutable";

import {
  LOGGING_OUT_AUTOMATICALLY,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  MESSAGE_DELETE,
  MESSAGE_IGNORE,
  MESSAGE_MARK_AS_READ,
  SET_MESSAGE_SHOW
} from "../actions/actionNames";

export const MessageRecord = Record({
  message: "",
  action_link: "",
  custom_entity: null,
  viewed: false,
  ignored: false,
  message_id: 0,
  title: "",
  date: null
});

export const Messages = Record({
  messages: List(),
  showMessages: false
});

export const makeMessageList = messages => {
  if (!messages) {
    return List();
  }
  const messageRecords = messages.map(message => new MessageRecord({ ...message }));
  return List(messageRecords);
};

const addMessages = (state, { messages }) => {
  let cur_messages = state.get("messages") || List();
  if (!messages) {
    return state;
  }
  let messageRecords = makeMessageList(messages);
  messageRecords = messageRecords.filter(
    ({ message_id }) => cur_messages.find(message_id) === -1
  );
  cur_messages = cur_messages.concat(messageRecords);
  return state.set("messages", cur_messages);
};

const markAsRead = (state, { message_id }) => {
  let cur_messages = state.get("messages");
  if (!cur_messages) {
    return state;
  }
  cur_messages = cur_messages.map(message =>
    message_id === message.get("message_id") ? message.set("viewed", true) : message
  );
  return state.set("messages", cur_messages);
};

const ignore = (state, { message_id }) => {
  let cur_messages = state.get("messages");
  if (!cur_messages) {
    return state;
  }
  cur_messages = cur_messages.map(message =>
    message_id === message.get("message_id") ? message.set("ignored", true) : message
  );
  return state.set("messages", cur_messages);
};

const deleteMessage = (state, { message_id }) => {
  let cur_messages = state.get("messages");
  if (!cur_messages) {
    return state;
  }
  cur_messages = cur_messages.filter(message => message_id !== message.get("message_id"));
  return state.set("messages", cur_messages);
};

const setShowMessages = (state, { view }) => {
  return state.set("showMessages", view);
};

const clearMessages = (state, action) => {
  return state.set("showMessages", false).set("messages", List());
};

const messages = (state = Messages(), action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return addMessages(state, action);
    case MESSAGE_MARK_AS_READ:
      return markAsRead(state, action);
    case MESSAGE_IGNORE:
      return ignore(state, action);
    case MESSAGE_DELETE:
      return deleteMessage(state, action);
    case SET_MESSAGE_SHOW:
      return setShowMessages(state, action);
    case LOGOUT_SUCCESS:
      return clearMessages(state, action);
    case LOGGING_OUT_AUTOMATICALLY:
      return clearMessages(state, action);
    default:
      return state;
  }
};

export default messages;
