/*
 * Copyright 2018-2021  DecisionQ Information Operations, Inc. All Rights Reserved. Pizza
 */

import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/es/styles/withStyles";
import { FullWidthPopover } from "../styled";
import { OverlayTrigger } from "react-bootstrap";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";

const tooltip = (
  <FullWidthPopover style={{ fontFamily: "Quicksand", fontWeight: "600" }}>
    Self-Change statistics not available for this entity, too many transactions.
  </FullWidthPopover>
);

const ChangeSwitch = ({ showChange, toggleChange, loading, noChange }) => {
  const changeSwitchEvent = event => {
    toggleChange(event.target.checked);
  };

  const CustomSwitch = withStyles({
    switchBase: {
      color: "var(--show-change-toggle)",
      "&$checked + $track": {
        backgroundColor: "var(--change-toggle-true)",
        opacity: 0.75,
      },
    },
    checked: {},
    track: {
      backgroundColor: "#333",
      opacity: 0.5,
    },
  })(Switch);

  return (
    <FormGroup style={{ display: "inline", paddingLeft: "35px" }}>
      <FormControlLabel
        control={
          <CustomSwitch
            colorPrimary={"black"}
            checked={showChange}
            onChange={changeSwitchEvent}
            color={"default"}
            disabled={noChange || loading}
          />
        }
        label={
          <p
            style={{
              fontSize: "12px",
              color: "#666",
              fontFamily: "Quicksand",
              fontWeight: "700",
              display: "inline",
            }}
          >
            {noChange ? (
              <OverlayTrigger overlay={tooltip} placement="top" delayShow={300} delayHide={150}>
                <span>
                  {noChange && <FontAwesomeIcon icon={faExclamationTriangle} />} Show Change
                </span>
              </OverlayTrigger>
            ) : (
              <span>
                {noChange && <FontAwesomeIcon icon={faExclamationTriangle} />} Show Change
              </span>
            )}
          </p>
        }
      />
    </FormGroup>
  );
};

ChangeSwitch.propTypes = {
  showChange: PropTypes.bool.isRequired,
  toggleChange: PropTypes.func.isRequired,
};

export default ChangeSwitch;
