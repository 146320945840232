import { createSelector } from "reselect";
import { getCurrency } from "../currency";
import history from "../../components/history";
import { getCurrentGraph, getUndoStatus } from "../graph";
import { getAddress } from "../address";
import { List } from "immutable";
import { clean_ethereum_address } from "../../components/Ethereum/helpers";

export const getGraph = (state, graphId) => {
  return state.getIn(["ethereum", "graph", "graphIdToGraphData", graphId.toString()]);
};

export const getGraphJson = (state, graphId) => getGraph(state, graphId).get("graph");

export const getGraphSearchResults = (state, graphId) => {
  const currentQuery = state.getIn([
    "ethereum",
    "graph",
    "graphIdToGraphData",
    graphId.toString(),
    "currentQuery"
  ]);
  const result = state.getIn([
    "ethereum",
    "graph",
    "graphIdToGraphData",
    graphId.toString(),
    "search",
    currentQuery
  ]);
  if (result) {
    return result.toJS();
  }
  return null;
};

export const getGraphViewInformation = createSelector(
  state => state.getIn([getCurrency(state), "graph", "view", "modals"]),
  state => getUndoStatus(state),
  (modals, undoStatus) => {
    try {
      const explorerModal = modals.get("explorer");
      const currentSet = modals.get("currentSet");
      if (explorerModal == null) {
        throw new Error();
      }
      const index = explorerModal.get("index");
      if (index == null) {
        throw new Error();
      }

      const historyRecord = index >= 0 ? explorerModal.getIn(["history", index]) : null;
      let entityType = null;
      let entity = null;
      if (historyRecord != null) {
        entityType = historyRecord.get("entityType");
        entity = historyRecord.get("entity");
      }

      const props = {
        currentSet,
        entityType,
        entity
      };
      Object.assign(props, undoStatus);
      return props;
    } catch (err) {
      history.replace("/graph");
      return null;
    }
  }
);

export const getGraphSearchLoading = (state, graphId) =>
  state.getIn(["ethereum", "graph", "graphIdToGraphData", graphId.toString(), "searchLoading"]);

export const getGraphSearchCurrentQuery = (state, graphId) =>
  state.getIn(["ethereum", "graph", "graphIdToGraphData", graphId.toString(), "currentQuery"]);

// this needs search results that are currently displayed
export const getMainTabsInfo = (state, graphId) => {
  const view = state.getIn(["ethereum", "graph", "view"]);
  const mainTabsKey = view.get("mainTabsKey");
  //const currentQuery = state.getIn(['ethereum', 'graph', 'graphIdToGraphData', graphId.toString(), "currentQuery");
  //const searchResults = getSearchResults(state);

  const editorState = state.getIn([
    "ethereum",
    "graph",
    "graphIdToGraphData",
    graphId.toString(),
    "editorState"
  ]);

  const caseNumber = state.getIn([
    "ethereum",
    "graph",
    "graphIdToGraphData",
    graphId.toString(),
    "caseNumber"
  ]);

  const description = state.getIn([
    "ethereum",
    "graph",
    "graphIdToGraphData",
    graphId.toString(),
    "description"
  ]);

  return {
    mainTabsKey,
    //searchResults,
    editorState,
    //currentQuery,
    caseNumber,
    description
  };
};

export const getGraphAddressTransactions = (state, address, token) => {
  // Get the address in the state
  const addressState = getAddress(state, address);
  if (addressState) {
    // Get the current direction
    const sent_filter = addressState.getIn(["graphTransactions", token, "sent", "currentOrder"]);
    const recv_filter = addressState.getIn([
      "graphTransactions",
      token,
      "received",
      "currentOrder"
    ]);
    const sent = addressState.getIn(["graphTransactions", token, "sent", sent_filter]).toJS();
    const received = addressState
      .getIn(["graphTransactions", token, "received", recv_filter])
      .toJS();

    return {
      sent,
      received
    };
  }

  return {
    sent: [],
    received: []
  };
};

// Used to get immutable version, which is then converted **once** to a JS object in
// getAddressSetMemo.
export const getAddressSet = (state, graphId) => {
  if (!graphId) {
    graphId = getCurrentGraph(state);
  }
  const graph = getGraph(state, graphId);
  if (graph == null) {
    return new Set();
  }

  const adds = graph.get("addresses", new List());
  return new Set(adds.toJS().map(addr => clean_ethereum_address(addr)));
};

/**
 * Gets category colors key for ethereum graph
 * @param state
 * @returns [
 *  <category>: {
 *   <category information>
 *  }
 * ]
 */
export const getCategoryColors = state => {
  return state.getIn(["ethereum", "graph", "colors"]).toJS();
};
