import {Button} from "@material-ui/core";
import React, { useState } from "react";
import { FullWidthPopover } from "../styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger } from "react-bootstrap";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/index";
import {MAX_VALUE, SEGMENT_COLORS} from "./RiskSpeedometer";
import StatCard from "../StatCard";

interface RiskButtonProps {
  getRisk: () => Promise<void>;
  risk: number | null | undefined;
  style?: any;
}

export const RiskButton: React.FC<RiskButtonProps> = ({ getRisk, risk, style={} }) => {
  const [loading, setLoading] = useState(false);
  const calculateRisk = async () => {
    setLoading(true);
    await getRisk();
    setLoading(false);
  };

  const riskFormatted = risk ?? 0;
  return (
    <>
      {risk == null && !loading ? (
        <OverlayTrigger
          overlay={
            <FullWidthPopover style={{ fontFamily: "Quicksand", fontWeight: "600" }}>
              Calculate Our Risk
            </FullWidthPopover>
          }
          placement="bottom"
          delayShow={300}
          delayHide={150}
        >
          <Button style={{ marginRight: "3px", display: 'inline', padding: 1, paddingTop: 0, ...style  }} className={`riskButton`} onClick={calculateRisk}>
            <span>
              <p style={{ fontSize: "14px", ...style}} className={"riskText"}>Risk </p>
              <FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize: "14px"}} />
            </span>
          </Button>
        </OverlayTrigger>) :
        (<div style={{paddingTop: "7px"}}>
          <StatCard stat={"Risk:"} value={
            <p
                style={{
                  fontSize: "14px", margin: 0,
                  ...style
                }}
                className={"riskText"}
            >
              {riskFormatted}
            </p>
          }
          loading={loading} pullRight={false}/>
        </div>)
      }
    </>
  );
};
