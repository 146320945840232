/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

/*
 * https://github.com/ybarukh/react-cytoscape
 */
import PropTypes from "prop-types";
import React, { Component, createRef } from "react";
import cytoscape from "cytoscape";
import cycola from "cytoscape-cola";
import dagre from "cytoscape-dagre";
import cxtmenu from "cytoscape-cxtmenu";
import { connect } from "react-redux";

import { style } from "../GraphStyle";
import { fetchGraph } from "../../../actions/graph";

cytoscape.use(cycola);
cytoscape.use(dagre);
cytoscape.use(cxtmenu);

/** React cytoscape component
 * props : style, elements, layout, cyRef,styleContainer, cytoscapeOptions
 */
class ReactCytoscape extends Component {
  componentDidMount() {
    this.build();
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    // I believe that no
    this.clean();
  }

  getCyID = () => this.props.containerId || "cy";

  getContainer = () => this.containerRef.current;

  containerRef = createRef();

  layout = () => this.props.layout || { name: "cola" };

  cytoscapeOptions = () => this.props.cytoscapeOptions || {};

  // This is only called during componentDidMount
  // There won't be an issue with updating as long as there isn't
  // a way for the user to go to a different graph without unmounting
  // cytoscape
  build = async () => {
    const { graphId, cyRef, fetch } = this.props;
    const opts = {
      container: this.getContainer(),
      boxSelectionEnabled: true,
      autounselectify: true,
      style: style(),
      elements: null,
      layout: this.layout(),
      ...this.cytoscapeOptions()
    };
    this.cy = cytoscape(opts);
    if (cyRef) {
      cyRef(this.cy);
    }
    // dispatch action to fetch cytoscape information
    // do it here in order to ensure that cytoscape exists
    // and graph can be made
    await fetch(graphId, this.cy, this.props.fetchGraphComplete);
    this.props.loadStatus();
    return this.cy;
  };

  clean = () => {
    if (this.cy) {
      this.cy.destroy();
    }
  };

  render() {
    const styleContainer = {
      height: "calc(100% - 1px)",
      maxHeight: "100%",
      width: "100%",
      display: "block",
      style
    };

    return (
      <div className="graph" id={this.getCyID()} ref={this.containerRef} style={styleContainer}>
        {this.props.children}
      </div>
    );
  }
}

// The following are mostly configuration objects passed to cytoscape and
// writing/changing the proptypes every time config values used change
// will get too annoying.
ReactCytoscape.propTypes = {
  containerId: PropTypes.string,
  cyRef: PropTypes.func.isRequired,
  cytoscapeOptions: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  layout: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  graphId: PropTypes.string.isRequired,
  fetch: PropTypes.func.isRequired
};

ReactCytoscape.defaultProps = {
  containerId: null,
  cytoscapeOptions: null,
  layout: null
};

const mapDispatchToProps = dispatch => ({});

export default connect(
  null,
  mapDispatchToProps
)(ReactCytoscape);
