/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";
import { Button, Grid, Modal, Row, Tab, Tabs, Form, FormControl, FormGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import AddressTables from "./AddressTables";
import CollapsedAddressTables from "./CollapsedAddressTables";
import WalletTables from "./WalletTables";
import TransactionSummary from "./TransactionSummary";
import BsaTransactionView from "./BsaTransactionView/BsaTransactionView";
import {
  transactionAddress,
  transactionAddressCollapsed,
  transactionWallet
} from "../../prop-types";
import {
  fetchTransaction,
  fetchAddressTransactionNote,
  fetchTransactionChains
} from "../../actions/transaction";
import { createGraphInNewTabWithPreload } from "../../actions/graph";
import { getTransactionInfo, getTransactionRisk } from "../../selectors/transaction";
import withAddPreloadModals from "../withAddPreloadModals";
import { getFeature } from "../../selectors/features";
import "../../../css/styles.css";
import { getCurrency } from "../../selectors/currency";
import ReportIssue from "../ReportIssue";
import { getClientMode } from "../../selectors/applicationConfig";
import ClientModeConfig from "../ClientModeConfig";
import { CoinSwap } from "../CoinSwap";
import TransactionNoteEditor from "../NoteEditor/TransactionNoteEditor";
import AddressNoteEditor from "../NoteEditor/AddressNoteEditor";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Typography } from "@material-ui/core";
import TransactionChainGraph from "./TransactionChainGraph";
import { createRiskFetch } from "../../actions/actionCreatorFactories";
import { getAddressRisk } from "../../selectors/address";
import { RiskScoreBase } from "../Risk/RiskScoreBase";

class Transaction extends Component {
  state = {
    key: "none",
    addressView: 0,
    showModal: false,
    usd: false,
    showAddAddressNoteModal: false,
    showAddressNoteModal: false,
    noteAddress: null,
    addAddressNote: null,
    showTransactionChainModal: false
  };

  componentDidMount = async () => {
    const { transaction } = this.props;
    await this.props.fetch(transaction);
    await this.props.fetchTransactionChains(transaction);
  };

  componentDidUpdate = async prevProps => {
    const { transaction: oldTransaction } = prevProps;
    const { transaction: newTransaction } = this.props;
    if (oldTransaction !== newTransaction) {
      await this.props.fetch(newTransaction);
      await this.props.fetchTransactionChains(newTransaction);
    }
  };

  addressNoteClick = address => {
    this.setState({
      showAddressNoteModal: !this.state.showAddressNoteModal,
      noteAddress: address
    });
  };

  addAddressIfExists = async (e, transaction) => {
    e.preventDefault();
    const address = this.state.addAddressNote.trim();

    if (address.length === 0) {
      return;
    }

    this.props.addNote(address, transaction);
    this.setState({ showAddAddressNoteModal: false });
  };

  whichAddressViewToShow = () => {
    const current = this.state.addressView;
    if (current === 0) {
      return (
        <AddressTables
          inputBitcoin={this.props.inputBitcoin}
          outputBitcoin={this.props.outputBitcoin}
          inputs={this.props.inputs}
          outputs={this.props.outputs}
          getAddressLink={this.props.getAddressLink}
          getTransactionLink={this.props.getTransactionLink}
          getWalletLink={this.props.getWalletLink}
          currency={this.props.currency}
          originatingTransaction={this.props.transaction}
          convertPriceToUsd={this.convertPriceToUsd}
          toggleUsdDisplay={this.toggleUsdDisplay}
          showAsUsd={this.state.usd}
          onAddressNoteClick={this.addressNoteClick}
        />
      );
    }

    if (current === 1) {
      return (
        <CollapsedAddressTables
          inputBitcoin={this.props.inputBitcoin}
          outputBitcoin={this.props.outputBitcoin}
          inputs={this.props.collapsedInputAddresses}
          outputs={this.props.collapsedOutputAddresses}
          getAddressLink={this.props.getAddressLink}
          getWalletLink={this.props.getWalletLink}
          currency={this.props.currency}
          originatingTransaction={this.props.transaction}
          convertPriceToUsd={this.convertPriceToUsd}
          toggleUsdDisplay={this.toggleUsdDisplay}
          showAsUsd={this.state.usd}
        />
      );
    }

    return (
      <WalletTables
        inputBitcoin={this.props.inputBitcoin}
        outputBitcoin={this.props.outputBitcoin}
        inputs={this.props.inputWallets}
        outputs={this.props.outputWallets}
        getWalletLink={this.props.getWalletLink}
        currency={this.props.currency}
        originatingTransaction={this.props.transaction}
        convertPriceToUsd={this.convertPriceToUsd}
        toggleUsdDisplay={this.toggleUsdDisplay}
        showAsUsd={this.state.usd}
      />
    );
  };

  handleSelect = key => this.setState({ key });

  convertDate = dateString => {
    const months = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12"
    };

    const dateArray = dateString.replace(",", "").split(" ");
    const month = months[dateArray[0]];
    const day = dateArray[1];
    const year = dateArray[2];

    return `${day}-${month}-${year}`;
  };

  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }

  replaceAll(str, match, replacement) {
    return str.replace(new RegExp(this.escapeRegExp(match), "g"), () => replacement);
  }

  convertPriceToUsd = coinValue => {
    return coinValue !== null
      ? (this.props.price * parseFloat(this.replaceAll(coinValue, ",", ""))).toFixed(2)
      : null;
  };

  toggleUsdDisplay = () => {
    this.setState({
      usd: !this.state.usd
    });
  };

  render() {
    const {
      transaction,
      block,
      timestamp,
      inputBitcoin,
      outputBitcoin,
      fee,
      bsaTagsEnabled,
      currency,
      unclustered_input,
      risk_score,
      price,
      priceAvailable,
      processed
    } = this.props;

    const addressOptionsNoteSelect = () => {
      let fullListOfAddresses = [];
      this.props.inputs.map(
        input => (fullListOfAddresses = fullListOfAddresses.concat(input["address"]))
      );
      this.props.outputs.map(
        output => (fullListOfAddresses = fullListOfAddresses.concat(output["address"]))
      );

      fullListOfAddresses = [...new Set(fullListOfAddresses)];
      return fullListOfAddresses;
    };

    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    return (
      <div>
        <Helmet>
          <title>{`Trx ${transaction}`}</title>
        </Helmet>
        <Grid>
          <Row>
            <TransactionSummary
              block={block}
              timestamp={timestamp}
              inputBitcoin={inputBitcoin}
              outputBitcoin={outputBitcoin}
              fee={fee}
              transaction={transaction}
              addToGraph={() => this.props.getCreateGraphModal()}
              unclustered_input={unclustered_input}
              risk_score={risk_score}
              currency={currency}
              convertPriceToUsd={this.convertPriceToUsd}
              toggleUsdDisplay={this.toggleUsdDisplay}
              showAsUsd={this.state.usd}
              priceAvailable={priceAvailable}
              clientMode={this.props.clientMode}
              coinSwapData={this.props.coinSwapData}
              coinSwap={this.props.coinSwap}
              isMempool={block === -1}
              risk={this.props.risk}
            />
          </Row>
          {unclustered_input === true && (
            <Row
              style={{
                textAlign: "center",
                margin: "auto",
                border: "1px solid green",
                width: "70%",
                padding: "5px",
                borderRadius: "5px",
                marginTop: "20px",
                textAlight: "center"
              }}
            >
              <a
                style={{
                  color: "green",
                  fontFamily: "Quicksand",
                  fontWeight: "700",
                  fontSize: "16px",
                  textAlign: "center"
                }}
                onClick={() => this.setState({ showModal: true })}
              >
                {`Note: Multiple ${clientModeConfigObject.plural_for_collection} appear on the input side of this
                transaction. Click here to find out why.`}
              </a>
            </Row>
          )}
          {!processed && (
            <Row
              style={{
                textAlign: "center",
                margin: "auto",
                border: "1px solid red",
                width: "70%",
                padding: "5px",
                borderRadius: "5px",
                marginTop: "20px",
                textAlight: "center"
              }}
            >
              <p
                style={{
                  color: "red",
                  fontFamily: "Quicksand",
                  fontWeight: "700",
                  fontSize: "16px",
                  textAlign: "center"
                }}
              >
                This transaction has not been analyzed yet either because its too new or is an
                unconfirmed transaction. Some features have been disabled.
              </p>
            </Row>
          )}
          <Row style={{ paddingRight: "0", paddingTop: "0" }}>
            <div
              className="container"
              style={{ paddingRight: "0", paddingTop: "0", marginTop: "10px" }}
            >
              <Tabs
                className="inviteTabs"
                activeKey={this.state.key}
                id="transaction-tab"
                onSelect={this.handleSelect}
                animation={false}
                mountOnEnter
              >
                <Tab eventKey="none" title="Standard">
                  <div
                    className="row"
                    style={{
                      marginTop: "15px",
                      marginBottom: "15px",
                      marginLeft: "0px"
                    }}
                  >
                    <Button
                      className="blueButton"
                      onClick={() => this.setState({ addressView: 0 })}
                      disabled={this.state.addressView === 0}
                    >
                      Show All {clientModeConfigObject.cap_plural_for_collection} and Addresses
                    </Button>
                    <div className="divider" />
                    <Button
                      className="blueButton"
                      onClick={() => this.setState({ addressView: 1 })}
                      disabled={this.state.addressView === 1}
                    >
                      Show Unique Addresses
                    </Button>
                    <div className="divider" />
                    <Button
                      className="blueButton"
                      onClick={() => this.setState({ addressView: 2 })}
                      disabled={this.state.addressView === 2}
                    >
                      Show Unique {clientModeConfigObject.cap_plural_for_collection}
                    </Button>
                  </div>

                  <div
                    className="row"
                    style={{
                      marginTop: "0px",
                      marginBottom: "15px",
                      marginLeft: "0px"
                    }}
                  >
                    <Button
                      className="greenButton"
                      onClick={() => this.setState({ showAddAddressNoteModal: true })}
                      //                       disabled={this.state.addressView === 2}
                    >
                      Add Note to an Address
                    </Button>
                    {/* TODO Add a button to specifically calculate it (when clicking first time)*/}
                    <Button
                      className="blueButton"
                      onClick={() => this.setState({ showTransactionChainModal: true })}
                      disabled={
                        !this.props.transactionChain ||
                        Object.keys(this.props.transactionChain).length === 0
                      }
                    >
                      {!this.props.transactionChain && (
                        <CircularProgress size={15} style={{ color: "white", marginRight: 5 }} />
                      )}
                      See Transaction Chain
                    </Button>
                  </div>

                  <p
                    style={{
                      fontFamily: "Quicksand, sans-serif",
                      fontSize: "20px",
                      color: "#666"
                    }}
                  >
                    Select a Column Header to Sort
                  </p>
                  {this.whichAddressViewToShow()}
                </Tab>
                {/*            <Tab eventKey="socialMentions" title="Address Social Mentions">
              <br />
              <MentionsTransactionView
                transaction={transaction}
                getAddressLink={this.props.getAddressLink}
              />
            </Tab>*/}
                {bsaTagsEnabled && (
                  <Tab eventKey="bsaMentions" title="Address BSA Mentions">
                    <br />
                    <BsaTransactionView
                      transaction={transaction}
                      getAddressLink={this.props.getAddressLink}
                      currency={currency}
                    />
                  </Tab>
                )}
                {processed && (
                  <Tab eventKey="notes" title="Notes">
                    <h3
                      className="entityHeader"
                      style={{
                        fontSize: "30px",
                        color: "var(--secondary-text-colors)"
                      }}
                    >
                      Transaction Note
                    </h3>
                    <TransactionNoteEditor
                      transaction={transaction}
                      mainLocation={"transaction"}
                      mainLocationName={transaction}
                    ></TransactionNoteEditor>
                  </Tab>
                )}
                {this.props.risk && (
                  <Tab eventKey="risk" title="Risks">
                    <RiskScoreBase type={"transaction"} results={this.props.risk} />
                  </Tab>
                )}
              </Tabs>
            </div>
          </Row>
        </Grid>
        <Modal
          show={this.state.showAddAddressNoteModal}
          onHide={() => this.setState({ showAddAddressNoteModal: false })}
          className="addressNotesModal"
        >
          <Modal.Header closeButton style={{ borderBottom: "2px solid #aaa" }}>
            <Modal.Title className="entityTitle">Select an Address to Write a Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={e => this.addAddressIfExists(e, transaction)}>
              <FormGroup>
                <Autocomplete
                  onChange={(event, value) => this.setState({ addAddressNote: value })}
                  disablePortal
                  id="combo-box-demo"
                  options={addressOptionsNoteSelect()}
                  sx={{ width: 300 }}
                  getOptionLabel={option => option.toString()}
                  renderInput={params => <TextField {...params} label="Address" />}
                />
              </FormGroup>
              <Button className="blueButton" type="submit">
                Add
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showAddressNoteModal}
          onHide={() => this.setState({ showAddressNoteModal: false })}
          className="addressNotesModal"
          dialogClassName="addressModalHeight"
        >
          <Modal.Header closeButton style={{ borderBottom: "2px solid #aaa" }}>
            <Modal.Title className="entityTitle">{this.state.noteAddress}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddressNoteEditor
              height={350}
              address={this.state.noteAddress}
              mainLocation={"transaction"}
              mainLocationName={transaction}
            ></AddressNoteEditor>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          className="issuesModal"
        >
          <Modal.Header closeButton style={{ borderBottom: "2px solid #aaa" }}>
            <Modal.Title className="entityTitle">Notice Regarding Unclustered Inputs</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="entityPrompt"> Notice - Shared Spend. </p>
            <br />
            <ul
              style={{
                fontFamily: "Quicksand",
                fontWeight: "600",
                fontSize: "15px",
                color: "#666"
              }}
            >
              <li style={{ marginBottom: "4px" }}>
                {" "}
                A Shared Spend is when more than one entity contribute bitcoin to a single
                transaction.
              </li>
              <li style={{ marginBottom: "4px" }}>
                {" "}
                This transaction has been flagged and is pending analysis.
              </li>
            </ul>{" "}
            <br />
            <p className="entityPrompt">
              If you would like to place this transaction as a priority, submit a “Investigation
              Support”.
            </p>
            <ReportIssue entityName={transaction} entityType={"transaction"} currency={currency} />{" "}
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showTransactionChainModal}
          onHide={() => this.setState({ showTransactionChainModal: false })}
          className="transactionChainModal"
          bsSize="large"
        >
          <Modal.Body>
            <TransactionChainGraph
              transactionChain={this.props.transactionChain}
              graphModal={this.props.graphModal ? this.props.graphModal : false}
              cy={this.props.cy}
              graphId={this.props.graphId}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

// TODO: Probably just have tables grab values they need instead of passing them down
Transaction.propTypes = {
  transaction: PropTypes.string.isRequired,
  fetch: PropTypes.func.isRequired,
  block: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  inputBitcoin: PropTypes.string.isRequired,
  outputBitcoin: PropTypes.string.isRequired,
  fee: PropTypes.string.isRequired,
  inputs: PropTypes.arrayOf(transactionAddress).isRequired,
  outputs: PropTypes.arrayOf(transactionAddress).isRequired,
  collapsedInputAddresses: PropTypes.arrayOf(transactionAddressCollapsed).isRequired,
  collapsedOutputAddresses: PropTypes.arrayOf(transactionAddressCollapsed).isRequired,
  inputWallets: PropTypes.arrayOf(transactionWallet).isRequired,
  outputWallets: PropTypes.arrayOf(transactionWallet).isRequired,
  getAddressLink: PropTypes.func.isRequired,
  getWalletLink: PropTypes.func.isRequired,
  getTransactionLink: PropTypes.func.isRequired,
  getCreateGraphModal: PropTypes.func.isRequired,
  bsaTagsEnabled: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  processed: PropTypes.bool
};

Transaction.defaultProps = {
  unclustered_input: null,
  processed: true
};

const mapStateToProps = (state, { transaction }) => {
  const transactionInfo = getTransactionInfo(state, transaction);
  const bsaTagsEnabled = {
    bsaTagsEnabled: getFeature(state, "bsa_tags")
  };

  return {
    ...transactionInfo,
    ...bsaTagsEnabled,
    currency: getCurrency(state),
    clientMode: getClientMode(state),
    risk: getTransactionRisk(state, transaction)
  };
};

// We moved everything from mapdispatchtoprops to mergeprops. The reason is that props acquired via mapstate are not
// reflected in the ownProps argument, so to access them we need to use the build in mergeprops instead
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { transaction: trx } = ownProps;
  const { unclustered_input } = stateProps;
  return {
    ...stateProps,
    ...ownProps,
    fetch: transaction => dispatch(fetchTransaction(transaction)),
    addNote: (address, transaction) => dispatch(fetchAddressTransactionNote(address, transaction)),
    fetchTransactionChains: transaction => dispatch(fetchTransactionChains(transaction)),
    createGraph: (caseNumber, description, isPublic) => {
      dispatch(
        createGraphInNewTabWithPreload(caseNumber, description, isPublic, {
          preloadAddresses: [],
          preloadWallets: [],
          preloadTransactions: unclustered_input === true ? [] : [trx],
          preloadUnclusteredTransactions: unclustered_input === true ? [trx] : []
        })
      );
    }
  };
};

const getPreloadInfo = ({ transaction, unclustered_input, processed }) => ({
  type: unclustered_input === true ? "unclustered_transaction" : "transaction",
  item: transaction,
  processed: processed
});

export default connect(
  mapStateToProps,
  null,
  mergeProps
)(withAddPreloadModals(Transaction, getPreloadInfo));
