// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { List, Map, Record } from "immutable";

import {
  LOGGING_OUT,
  TRANSACTION_FETCH_SUCCESS,
  TRANSACTION_ADDRESS_NOTE_ADD_SUCCESS,
  FETCH_TRANSACTION_CHAIN_SUCCESS,
  RISK_SUCCESS
} from "../actions/actionNames";
import { AddressRecord, CoinSwap, getAddressRecord } from "./address";
import { addRiskReducer } from "./reducerFactories";

/*
Transaction State
Transaction: Map<string, TransactionRecord>

TransactionRecord: {
  transactionStats: TransactionStats: {
    block: number,
    timestamp: number,
    inputCount: number,
    inputSatoshi: number,
    outputCount: number,
    outputSatoshi: number,
    fee: number,
  },
  inputs: List<TransactionOutputs>,
  outputs: List<TransactionOutputs>,
  mentions: List<TransactionAddressMentions>,
}

TransactionOutputs {
  key: number,
  address: List<string> | null,
  satoshi: number,
  walletId: number,
  transactionHash: string | null,
  primaryTag: string | null,
  firstBlock: number | null,
  addressCount: number,
  isNote: string | null,
}

TransactionAddressMentions {
  address: string,
  primaryTag: string | null,
  bsaMentionsCount: number,
  mentionsCount: number,
}
 */

export const TransactionStats = Record({
  block: 0,
  timestamp: 0,
  inputCount: 0,
  inputSatoshi: 0,
  outputCount: 0,
  outputSatoshi: 0,
  fee: 0,
  price: 0,
  priceAvailable: true
});

export const TransactionOutputs = Record({
  key: 0,
  address: "", // This is technically a list, but probably bugged if default isn't ''...
  satoshi: 0,
  walletId: 0,
  transactionHash: "",
  // These should theoretically be the same given some output
  primaryTag: null,
  firstBlock: null,
  addressCount: 0,
  isNote: null
});

export const TransactionAddressMentions = Record({
  address: "",
  primaryTag: null,
  bsaMentionsCount: 0,
  mentionsCount: 0
});

export const TransactionRecord = Record({
  transactionStats: new TransactionStats(),
  // inputs and outputs are lists of TransactionOutputs
  inputs: List(),
  outputs: List(),
  mentions: List(),
  unclustered_input: false,
  risk_score: 0,
  processed: true,
  coinSwap: false,
  coinSwapData: new CoinSwap(),
  transactionChain: null,
  risk: null
});

export const getTransactionRecord = (state, transaction) =>
  state.get(transaction) || new TransactionRecord();

const addTransaction = (
  state,
  {
    transactionHash,
    data: { stats, inputs, outputs, mentions, unclustered_input, risk_score, coin_swap, processed }
  }
) => {
  const inputRecords = inputs.map(input => new TransactionOutputs(input));
  const outputRecords = outputs.map(output => new TransactionOutputs(output));
  const mentionRecords = mentions.map(mention => new TransactionAddressMentions(mention));
  const statsRecord = new TransactionStats(stats);

  // If the coinswap object is null, we know its not a coinswap
  const transactionRecord = new TransactionRecord({
    transactionStats: statsRecord,
    inputs: inputRecords,
    outputs: outputRecords,
    mentions: mentionRecords,
    unclustered_input: unclustered_input,
    risk_score: risk_score,
    processed: processed !== false,
    coinSwap: coin_swap !== null,
    coinSwapData: coin_swap !== null ? new CoinSwap(coin_swap) : new CoinSwap(),
    transactionChain: null
  });

  return state.set(transactionHash, transactionRecord);
};

const addTransactionChain = (state, { transactionHash, data }) => {
  const originalState = state.get(transactionHash);
  let fullRecord = originalState.set("transactionChain", data);
  return state.set(transactionHash, fullRecord);
};

const addNote = (state, { transactionHash, address }) => {
  const inputs = state.get(transactionHash)["inputs"];
  const outputs = state.get(transactionHash)["outputs"];

  const inputsToChange = inputs.filter(input => input.address.includes(address));
  const inputChange = inputsToChange.map(inputToChange => {
    let newNote = [];
    let inputAddressLst = inputToChange.get("address");
    for (let i = 0; i < inputAddressLst.length; i++) {
      if (inputAddressLst[i] === address) {
        newNote.push("transaction");
      } else {
        newNote.push(inputToChange.get("isNote").at(i));
      }
    }
    return inputToChange.set("isNote", newNote);
  });
  const outputsToChange = outputs.filter(output => output.address.includes(address));
  const outputChange = outputsToChange.map(outputToChange => {
    let newNote = [];
    let outputAddressLst = outputToChange.get("address");
    for (let i = 0; i < outputAddressLst.length; i++) {
      if (outputAddressLst[i] === address) {
        newNote.push("transaction");
      } else {
        newNote.push(outputToChange.get("isNote").at(i));
      }
    }
    return outputToChange.set("isNote", newNote);
  });
  const inputSame = inputs.filter(input => !input.address.includes(address));
  const outputSame = outputs.filter(output => !output.address.includes(address));
  let newInput = inputs;
  let newOutput = outputs;
  if (inputsToChange) {
    newInput = inputSame.concat(inputChange);
  }

  if (outputsToChange) {
    newOutput = outputSame.concat(outputChange);
  }
  const originalState = state.get(transactionHash);
  let fullRecord = originalState.set("inputs", newInput);
  fullRecord = fullRecord.set("outputs", newOutput);

  return state.set(transactionHash, fullRecord);
};

const makeTransactionReducer = coin => {
  return (state = Map(), action) => {
    // If the coin doesn't match the reducer, just return the state.
    if (action === undefined || action.name !== coin) {
      return state;
    }

    if (action && action.entityType && action.entityType !== "transaction") {
      return state;
    }
    switch (action.type) {
      case TRANSACTION_FETCH_SUCCESS: {
        return addTransaction(state, action);
      }
      case TRANSACTION_ADDRESS_NOTE_ADD_SUCCESS: {
        return addNote(state, action);
      }
      case FETCH_TRANSACTION_CHAIN_SUCCESS: {
        return addTransactionChain(state, action);
      }
      case RISK_SUCCESS:
        return addRiskReducer(getTransactionRecord, action.entityType)(state, action);
      case LOGGING_OUT:
        return Map();
      default:
        return state;
    }
  };
};

export default makeTransactionReducer;
