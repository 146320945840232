import { createRiskFetch } from "../../../actions/actionCreatorFactories";

0; // Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { connect } from "react-redux";

import WalletSummaryBase from "../WalletSummaryBase";
import {
  getWalletName,
  getWalletRisk,
  getWalletSummary,
  getWisteriaData
} from "../../../selectors/wallet";
import {
  fetchMoreWalletSummary,
  fetchRiskScore,
  fetchWalletSummary
} from "../../../actions/wallet";

// Props are passed to WalletSummaryBase in this way which seems unnecessarily difficult to find for someone new
const mapStateToProps = (state, { walletId }) => ({
  ...getWalletSummary(state, walletId, true),
  ...getWisteriaData(state, walletId),
  name: getWalletName(state, walletId),
  risk: getWalletRisk(state, walletId)
});

const mapDispatchToProps = dispatch => ({
  fetch: walletId => dispatch(fetchWalletSummary(walletId)),
  fetchMore: walletId => dispatch(fetchMoreWalletSummary(walletId)),
  fetchRiskScore: async walletId =>
    await dispatch(createRiskFetch("wallet", getWalletRisk)(walletId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletSummaryBase);
