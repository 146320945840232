import { getCurrency } from "./currency";
import { WalletRecord } from "../reducers/wallet";

export const getProcessed = (state, walletId) => {
  const currency = getCurrency(state);
  let walletRecord = state.getIn([currency, "wallet", walletId]);
  if (walletRecord) {
    return walletRecord.get("processed");
  }
  walletRecord = new WalletRecord();
  state.setIn([currency, "wallet", walletId], walletRecord);
  return walletRecord.get("processed");
};
