// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { createSelector } from "reselect";
import { Map } from "immutable";
import moment from "moment";
import { satoshiToBitcoin } from "../helpers";
import { getCurrency } from "./currency";
import { getAddress } from "./address";

/**
 * All possible failures are included here as a enum for easy testing.
 * Note that things that some failures may not be applicable to all change tests.
 */
export const Result = {
  SUCCESS: "SUCCESS",
  NO_UNIQUE_INPUT_ADDRESS: "NO_UNIQUE_INPUT_ADDRESS",
  INPUT_ADDRESS_DOES_NO_SHOW_UP_AS_OUTPUT: "INPUT_ADDRESS_DOES_NO_SHOW_UP_AS_OUTPUT",
  INPUT_ADDRESS_SHOWS_UP_MULTIPLE_TIMES_AS_OUTPUT:
    "INPUT_ADDRESS_SHOWS_UP_MULTIPLE_TIMES_AS_OUTPUT",
  WALLET_SHOWS_UP_AS_OUTPUT: "WALLET_SHOWS_UP_AS_OUTPUT",
  WALLET_DOES_NOT_SHOW_UP_AS_OUTPUT: "WALLET_DOES_NOT_SHOW_UP_AS_OUTPUT",
  ALL_OUTPUT_ADDRESSES_FROM_PREVIOUS_BLOCKS: "ALL_OUTPUT_ADDRESSES_FROM_PREVIOUS_BLOCKS",
  MULTIPLE_OUTPUT_ADDRESSES_FROM_CURRENT_BLOCK: "MULTIPLE_OUTPUT_ADDRESSES_FROM_CURRENT_BLOCK",
  COINBASE: "COINBASE",
  SINGLE_OUTPUT_ADDRESS: "SINGLE_OUTPUT_ADDRESS"
};

const setAllIsChangeToFalse = outputs => {
  outputs.forEach(output => {
    output.isChange = false;
  });
};

// This will return the input address if it is unique.
// Otherwise it will return null.
const getUniqueAddress = inputs => {
  const inputAddresses = new Set();
  let inputAddress = null;
  inputs.forEach(input => {
    inputAddresses.add(input.address[0]);
    if (inputAddress === null) {
      // eslint-disable-next-line prefer-destructuring
      inputAddress = input.address[0];
    }
  });

  if (inputAddresses.size !== 1) {
    return null;
  }

  return inputAddress;
};

export const testInputToSameOutputAddress = (transactionBlock, inputs, outputs) => {
  if (outputs.length === 1) {
    return Result.SINGLE_OUTPUT_ADDRESS;
  }

  const inputAddress = getUniqueAddress(inputs);
  if (inputAddress === null) {
    return Result.NO_UNIQUE_INPUT_ADDRESS;
  }

  let timesChangeAddressShowsUp = 0;

  outputs.forEach(output => {
    if (output.address[0] === inputAddress) {
      timesChangeAddressShowsUp += 1;
    }
  });

  if (timesChangeAddressShowsUp > 1) {
    return Result.INPUT_ADDRESS_SHOWS_UP_MULTIPLE_TIMES_AS_OUTPUT;
  }

  if (timesChangeAddressShowsUp < 1) {
    return Result.INPUT_ADDRESS_DOES_NO_SHOW_UP_AS_OUTPUT;
  }

  outputs.forEach(output => {
    output.isChange = output.address[0] === inputAddress;
  });

  return Result.SUCCESS;
};

export const testInputToNewOutputAddress = (transactionBlock, inputs, outputs) => {
  if (outputs.length === 1) {
    return Result.SINGLE_OUTPUT_ADDRESS;
  }

  // Ensure that inputs walletId does not show up in outputs
  if (inputs.length === 0) {
    return Result.NO_UNIQUE_INPUT_ADDRESS;
  }

  if (inputs.length === 1 && inputs[0].address[0] === "Coinbase") {
    return Result.COINBASE;
  }

  const inputWallet = inputs[0].walletId;

  let found = false;
  outputs.forEach(output => {
    if (output.walletId === inputWallet) {
      found = true;
    }
  });

  if (found === false) {
    return Result.WALLET_DOES_NOT_SHOW_UP_AS_OUTPUT;
  }

  // get address that shows up for first time in this block and is in same wallet
  // as output
  let newAddress = null;
  let addressesInTransactionBlock = 0;
  outputs.forEach(output => {
    if (output.firstBlock === transactionBlock && output.walletId === inputWallet) {
      addressesInTransactionBlock += 1;
      // eslint-disable-next-line prefer-destructuring
      newAddress = output.address[0];
    }
  });

  if (addressesInTransactionBlock < 1) {
    return Result.ALL_OUTPUT_ADDRESSES_FROM_PREVIOUS_BLOCKS;
  }

  if (addressesInTransactionBlock > 1) {
    return Result.MULTIPLE_OUTPUT_ADDRESSES_FROM_CURRENT_BLOCK;
  }

  outputs.forEach(output => {
    output.isChange = output.address[0] === newAddress;
  });

  return Result.SUCCESS;
};

/**
 * Adds a change marker to the outputs of a transaction.
 *
 * Only one of the outputs should be marked as change. There must also be multiple
 * outputs in the transaction.
 *
 * 1. If the input address shows up exactly once as output, then that address output
 *    is marked as change.
 * 2. If one and only one of the outputs addresses both appears for the first time
 *    on the blockchain and belongs to the input's wallet then that new output address
 *    is marked as change.
 * @param block
 * @param inputs
 * @param outputs
 */
const markChange = (block, inputs, outputs) => {
  setAllIsChangeToFalse(inputs);
  const tests = [
    testInputToSameOutputAddress, // disabled for now
    testInputToNewOutputAddress
  ];

  let success = false;
  for (let i = 0; i < tests.length; i += 1) {
    const test_ = tests[i];
    const result = test_(block, inputs, outputs);
    if (result === Result.SUCCESS) {
      success = true;
      break;
    }
  }

  // ONLY DO THIS IF ALL RETURN FALSE
  if (success === false) {
    setAllIsChangeToFalse(outputs);
  }
};

export const getTransaction = (state, transactionHash) =>
  state.getIn([getCurrency(state), "transaction", transactionHash]);

// Returns a JS list.
const collapseAddresses = outputs =>
  outputs
    .reduce((map, output) => {
      const address = output.get("address");
      const satoshi = output.get("satoshi");
      const key = output.get("key");
      const walletId = output.get("walletId");
      const primaryTag = output.get("primaryTag");
      const firstBlock = output.get("firstBlock");
      const addressCount = output.get("addressCount");

      // Can't use arrays as keys so we gotta do this hacky nonsense.
      let addressKey = "";

      address.forEach(subaddress => {
        addressKey += `${subaddress}-`;
      });

      const addressSatoshi = map.has(addressKey) ? map.get(addressKey).get("satoshi") : 0;
      return map.set(
        addressKey,
        Map({
          address,
          satoshi: satoshi + addressSatoshi,
          key,
          walletId,
          primaryTag,
          firstBlock,
          addressCount
        })
      );
    }, Map())
    .toList();

const collapseWallets = outputs =>
  outputs
    .reduce((map, input) => {
      const satoshi = input.get("satoshi");
      const key = input.get("key");
      const walletId = input.get("walletId");
      const primaryTag = input.get("primaryTag");
      const firstBlock1 = input.get("firstBlock");
      const addressCount = input.get("addressCount");

      // The following are aggregates over all addresses in the wallet.
      const walletSatoshi = map.has(walletId) ? map.get(walletId).get("satoshi") : 0;
      const firstBlock2 = map.has(walletId) ? map.get(walletId).get("firstBlock") : 9999999;
      return map.set(
        walletId,
        Map({
          walletId,
          satoshi: satoshi + walletSatoshi,
          key,
          primaryTag,
          firstBlock: Math.min(firstBlock1, firstBlock2),
          addressCount
        })
      );
    }, Map())
    .toList();

export const getTransactionInfo = createSelector(
  [getTransaction],
  transactionInfo => {
    if (!transactionInfo) {
      // change all this to plain objects
      return {
        block: 0,
        price: 0.0,
        priceAvailable: true,
        inputBitcoin: "0.00000000",
        outputBitcoin: "0.00000000",
        fee: "0.00000000",
        inputs: [],
        outputs: [],
        collapsedInputAddresses: [],
        collapsedOutputAddresses: [],
        inputWallets: [],
        outputWallets: [],
        timestamp: moment.utc(0).format("LLL"),
        timestamp_raw: moment.utc(0),
        unclustered_input: null,
        risk_score: 0,
        processed: true,
        coinSwap: false,
        coinSwapData: {},
        transactionChain: null
      };
    }

    const transactionStats = transactionInfo.get("transactionStats");
    const block = transactionStats.get("block");
    const price = transactionStats.get("price");
    const priceAvailable = transactionStats.get("priceAvailable");
    const coinSwapData = transactionInfo.get("coinSwapData").toJS();
    const coinSwap = transactionInfo.get("coinSwap");
    const inputs = transactionInfo.get("inputs");
    const outputs = transactionInfo.get("outputs");
    let fee = transactionStats.get("fee");
    if (fee < 0) {
      fee = 0;
    }
    fee = satoshiToBitcoin(fee);
    const jsInputs = inputs.map(input => input.toJS());
    const jsOutputs = outputs.map(output => output.toJS());
    markChange(block, jsInputs, jsOutputs);

    return {
      block,
      price,
      priceAvailable,
      timestamp: moment
        .unix(parseInt(transactionStats.get("timestamp"), 10))
        .utc()
        .format("LLL"),
      timestamp_raw: parseInt(transactionStats.get("timestamp"), 10) * 1000,
      inputBitcoin: satoshiToBitcoin(transactionStats.get("inputSatoshi")),
      outputBitcoin: satoshiToBitcoin(transactionStats.get("outputSatoshi")),
      fee,
      inputs: jsInputs,
      outputs: jsOutputs,
      collapsedInputAddresses: collapseAddresses(inputs).toJS(),
      collapsedOutputAddresses: collapseAddresses(outputs).toJS(),
      inputWallets: collapseWallets(inputs).toJS(),
      outputWallets: collapseWallets(outputs).toJS(),
      unclustered_input: transactionInfo.get("unclustered_input"),
      risk_score: transactionInfo.get("risk_score"),
      coinSwapData,
      coinSwap,
      processed: transactionInfo.get("processed"),
      transactionChain: transactionInfo.get("transactionChain")
    };
  }
);

export const getTransactionRisk = (state, transactionHash) => {
  const trx = getTransaction(state, transactionHash);
  if (trx) {
    return trx.get("risk");
  }
  return null;
};

export const getTransactionMentions = (state, transactionHash) => {
  const transaction = getTransaction(state, transactionHash);
  if (transaction == null) {
    return [];
  }

  const mentions = transaction.get("mentions");
  return mentions.map(mention => mention.toJS());
};
