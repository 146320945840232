/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Button,
  ButtonToolbar,
  Form,
  FormControl,
  FormGroup,
  Modal
} from "react-bootstrap";
import cytoscape from "cytoscape";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import svg from "cytoscape-svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignCenter,
  faDotCircle,
  faDownload,
  faGripHorizontal,
  faListUl,
  faRandom,
  faSave,
  faSquare,
  faSuitcase,
  faTrash,
  faUpload
} from "@fortawesome/free-solid-svg-icons/index";
import axios, { WALLET_API } from "../../api";
import { cytoscapeShape } from "../../prop-types";
import {
  addNodeMovementToUndoStack,
  changeCaseNumber,
  changeDescription,
  deleteGraph,
  deleteWalletsFromGraph,
  importGraph
} from "../../actions/graph";
import { getFeature } from "../../selectors/features";
import { getCurrency } from "../../selectors/currency";
import { getGraphSaveInfo } from "../../selectors/graph";
import { onInputChange } from "../../helpers";

cytoscape.use(svg);

class Control extends Component {
  state = {
    modal: "",
    description: this.props.description,
    caseNumber: this.props.caseNumber
  };
  layout = config => {
    // Get positions of all nodes before.
    const positionsBefore = new Map();
    const walletIds = [];
    this.props.cy.nodes().forEach(wallet => {
      const position = { ...wallet.position() };
      positionsBefore.set(wallet.id(), position);
      walletIds.push(wallet.id());
    });

    this.props.cy.layout(config).run();

    this.props.addNodeMovementToUndoStack(
      this.props.cy,
      walletIds,
      positionsBefore
    );
  };

  handleLoadGraph = e => {
    e.preventDefault();
    const data = this.loadGraphForm.value;
    this.props.importGraph(data, this.props.cy);
    this.setState({ modal: null });
  };

  handleDeleteGraph = () => {
    // FIXME: Notify when deleting graph that isn't yours
    this.props.deleteGraph();
  };

  layoutRandom = () => this.layout({ name: "random" });

  layoutGrid = () => this.layout({ name: "grid" });

  layoutCircle = () => this.layout({ name: "circle" });

  downloadCy = file_type => {
    // Need to use this to pull the css style properties, for whatever reason cytoscape won't read from the css file
    const bodyStyles = window.getComputedStyle(document.body);
    const cssSecondaryColor = bodyStyles.getPropertyValue(
      "--graph-background-color"
    );
    // If the file type is png, save it as one. Otherwise, save it as jpg
    switch (file_type) {
      case "png":
        saveAs(this.props.cy.png({ bg: "#FFFFFF", full: true }), "graph.png");
        break;
      case "jpg":
        saveAs(this.props.cy.jpg({ bg: "#FFFFFF", full: true }), "graph.jpg");
        break;
      case "svg":
        // For some reason cytoscape's styles are not used with this method, so that needs to be fixed before releasing it
        const blob = new Blob(
          [this.props.cy.svg({ bg: "#FFFFFF", scale: 1, full: true })],
          {
            type: "image/svg+xml;charset=utf-8"
          }
        );
        saveAs(blob, "graph.svg");
        break;
    }
  };

  centerGraph = () => this.props.cy.center();

  fitGraph = () => this.props.cy.fit();

  render() {
    const {
      saveCallback,
      exportCallback,
      graphLayoutEnabled,
      graphImportEnabled
    } = this.props;
    const { modal } = this.state;

    return (
      <div
        style={{
          margin: "0",
          paddingLeft: "18px",
          borderLeft: "1px solid white",
          borderRadius: "0",
          paddingBottom: "10px"
        }}
      >
        <p
          style={{
            fontFamily: "Quicksand",
            fontWeight: "600",
            fontSize: "16px"
          }}
        >
          File
        </p>
        <ButtonToolbar style={{ margin: 0, paddingBottom: "10px" }}>
          {graphImportEnabled && (
            <>
              <Button
                className="control-whiteButton"
                onClick={() => this.setState({ modal: "loadGraph" })}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={faUpload}
                />
                Import
              </Button>
              <Button className="control-whiteButton" onClick={exportCallback}>
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={faDownload}
                />
                Export
              </Button>
            </>
          )}
          <Button className="control-whiteButton" onClick={saveCallback}>
            <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faSave} />
            Save
          </Button>
          <Button
            style={{
              fontFamily: "QuickSand",
              fontWeight: "700",
              backgroundColor: "#9f1d35",
              color: "white",
              border: "2px solid white",
              marginRight: "5px"
            }}
            onClick={() => this.setState({ modal: "deleteGraph" })}
          >
            <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faTrash} />
            Delete
          </Button>
        </ButtonToolbar>

        <p
          style={{
            fontFamily: "Quicksand",
            fontWeight: "600",
            fontSize: "16px"
          }}
        >
          Download Graph (as currently displayed)
        </p>
        <ButtonToolbar style={{ margin: 0, paddingBottom: "10px" }}>
          <Button
            className="control-whiteButton"
            onClick={() => this.downloadCy("png")}
          >
            <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faDownload} />
            Download PNG
          </Button>
          <Button
            className="control-whiteButton"
            onClick={() => this.downloadCy("jpg")}
          >
            <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faDownload} />
            Download JPEG
          </Button>
          {/*<Button*/}
          {/*  className="control-whiteButton"*/}
          {/*  onClick={() => this.downloadCy("svg")}*/}
          {/*>*/}
          {/*  <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faDownload} />*/}
          {/*  Download SVG*/}
          {/*</Button>*/}
        </ButtonToolbar>

        {graphLayoutEnabled && (
          <>
            <div
              style={{
                paddingLeft: "0",
                marginLeft: "0",
                paddingBottom: "10px"
              }}
            >
              <p
                style={{
                  fontFamily: "Quicksand",
                  fontWeight: "600",
                  fontSize: "16px"
                }}
              >
                Position
              </p>
              <ButtonToolbar style={{ margin: 0 }}>
                <Button
                  className="control-whiteButton"
                  onClick={this.centerGraph}
                >
                  <FontAwesomeIcon
                    style={{ marginRight: "5px" }}
                    icon={faAlignCenter}
                  />
                  Center Graph
                </Button>
                <Button className="control-whiteButton" onClick={this.fitGraph}>
                  <FontAwesomeIcon
                    style={{ marginRight: "5px" }}
                    icon={faSquare}
                  />
                  Fit Graph
                </Button>
              </ButtonToolbar>
            </div>
            <div
              style={{
                paddingLeft: "0",
                marginLeft: "0",
                paddingBottom: "10px"
              }}
            >
              <p
                style={{
                  fontFamily: "Quicksand",
                  fontWeight: "600",
                  fontSize: "16px"
                }}
              >
                Graph Layout
              </p>
              <ButtonToolbar style={{ margin: 0 }}>
                <Button
                  className="control-whiteButton"
                  onClick={this.layoutRandom}
                >
                  <FontAwesomeIcon
                    style={{ marginRight: "5px" }}
                    icon={faRandom}
                  />
                  Random
                </Button>
                <Button
                  className="control-whiteButton"
                  onClick={this.layoutGrid}
                >
                  <FontAwesomeIcon
                    style={{ marginRight: "5px" }}
                    icon={faGripHorizontal}
                  />
                  Grid
                </Button>
                <Button
                  className="control-whiteButton"
                  onClick={this.layoutCircle}
                >
                  <FontAwesomeIcon
                    style={{ marginRight: "5px" }}
                    icon={faDotCircle}
                  />
                  Circle
                </Button>
              </ButtonToolbar>
            </div>
          </>
        )}
        <div style={{ paddingLeft: "0", marginLeft: "0" }}>
          <p
            style={{
              fontFamily: "Quicksand",
              fontWeight: "600",
              fontSize: "16px"
            }}
          >
            Edit Names
          </p>
          <ButtonToolbar style={{ margin: 0 }}>
            <Button
              className="control-whiteButton"
              onClick={() => this.setState({ modal: "changeCaseNumber" })}
            >
              <FontAwesomeIcon
                style={{ marginRight: "5px" }}
                icon={faSuitcase}
              />
              Case Number
            </Button>
            <Button
              className="control-whiteButton"
              onClick={() => this.setState({ modal: "changeDescription" })}
            >
              <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faListUl} />
              Description
            </Button>
          </ButtonToolbar>
        </div>
        <Modal
          show={modal === "changeDescription"}
          onHide={() => this.setState({ modal: "" })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              onSubmit={e => {
                e.preventDefault();
                this.props.changeDescription(this.state.description);
              }}
            >
              <FormGroup>
                <FormControl
                  type="text"
                  componentClass="textarea"
                  maxLength={2000} // Max length for description
                  placeholder="Description"
                  id="description"
                  value={this.state.description}
                  onChange={e => this.setState({ description: e.target.value })}
                />
              </FormGroup>
              <Button type="submit">Change description</Button>
            </Form>
          </Modal.Body>
        </Modal>
        <Modal
          show={modal === "changeCaseNumber"}
          onHide={() => this.setState({ modal: "" })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Case Number</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              onSubmit={e => {
                e.preventDefault();
                this.props.changeCaseNumber(this.state.caseNumber);
              }}
            >
              <FormGroup>
                <FormControl
                  type="text"
                  placeholder="Title"
                  id="caseNumber"
                  maxLength={50} // Max length for case numbers
                  value={this.state.caseNumber}
                  onChange={e => this.setState({ caseNumber: e.target.value })}
                />
              </FormGroup>
              <Button type="submit">Change case number</Button>
            </Form>
          </Modal.Body>
        </Modal>
        <Modal
          show={modal === "loadGraph"}
          onHide={() => this.setState({ modal: "" })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Import Graph</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleLoadGraph}>
              <FormGroup>
                <FormControl
                  type="textarea"
                  componentClass="textarea"
                  placeholder="JSON export"
                  inputRef={ref => {
                    this.loadGraphForm = ref;
                  }}
                />
              </FormGroup>
              <Button type="submit">Import Graph</Button>
            </Form>
          </Modal.Body>
        </Modal>
        <Modal
          show={modal === "deleteGraph"}
          onHide={() => this.setState({ modal: "" })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button onClick={() => this.setState({ modal: "" })}>Keep</Button>{" "}
            <Button onClick={this.handleDeleteGraph} bsStyle="danger">
              Delete
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

Control.propTypes = {
  cy: cytoscapeShape,
  saveCallback: PropTypes.func.isRequired,
  exportCallback: PropTypes.func.isRequired,
  graphLayoutEnabled: PropTypes.bool.isRequired,
  graphImportEnabled: PropTypes.bool.isRequired,
  addNodeMovementToUndoStack: PropTypes.func.isRequired
};

Control.defaultProps = {
  cy: null,
  description: "",
  caseNumber: ""
};

const mapStateToProps = (state, { graphId }) => {
  return {
    graphLayoutEnabled: getFeature(state, "graph_layout"),
    graphImportEnabled: getFeature(state, "graph_import"),
    currency: getCurrency(state),
    ...getGraphSaveInfo(state, graphId)
  };
};

const mapDispatchToProps = (dispatch, { graphId }) => ({
  addNodeMovementToUndoStack: (cy, walletIds, walletIdToPosition) => {
    dispatch(
      addNodeMovementToUndoStack(cy, walletIds, walletIdToPosition, true)
    );
  },
  changeCaseNumber: caseNumber =>
    dispatch(changeCaseNumber(graphId, caseNumber)),
  changeDescription: description =>
    dispatch(changeDescription(graphId, description)),
  importGraph: (data, cy) => dispatch(importGraph(graphId, data, cy)),
  deleteGraph: () => dispatch(deleteGraph(graphId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Control);
