import { List, Map, Record } from "immutable";
import { LOGGING_OUT, WALLET_SUMMARY_FETCH_SUCCESS } from "../../actions/actionNames";

export const WalletSummary = Record({
  walletId: null,
  name: null,
  subpoenable: false,
  url: null,
  country: null,
  type: null,
  totalAddressCount: 0,
});

export const WalletRecord = Record({
  summary: new WalletSummary(),
  addresses: List(),
});

export const getWalletRecord = (state, wallet) => state.get(wallet) || new WalletRecord();

export const addWalletSummary = (state, action) => {
  // Pull the walletId and api data out of the action body
  const { walletId, data } = action;
  // Grab or create the address record and create the address summary
  let walletRecord = getWalletRecord(state, walletId);
  const walletSummary = new WalletSummary(data);
  // Set the summary in the record
  walletRecord = walletRecord.set("summary", walletSummary);
  walletRecord = walletRecord.set("addresses", List(data.addresses));

  // set the address record and return
  return state.set(walletId, walletRecord);
};

const makeWalletReducerEthereum = coin => {
  return (state = Map(), action) => {
    // If the coin doesn't match the reducer, just return the state.
    if (action === undefined || action.name !== coin) {
      return state;
    }

    switch (action.type) {
      case WALLET_SUMMARY_FETCH_SUCCESS:
        return addWalletSummary(state, action);
      case LOGGING_OUT:
        return Map();
      default:
        return state;
    }
  };
};

export default makeWalletReducerEthereum;
