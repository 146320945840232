import { Button, Modal } from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { withSnackbar } from "notistack";
import { getTransactionInfo } from "../../../selectors/transaction";
import { getFeature } from "../../../selectors/features";
import { getCurrency } from "../../../selectors/currency";
import { getClientMode } from "../../../selectors/applicationConfig";
import { connect } from "react-redux";
import ClientModeConfig from "../../ClientModeConfig";

/**
 * Component for button to represent deleting something with an 'are you sure' modal
 */
class DeleteEntity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
    };
  }

  onDeletePress = async () => {
    this.setState({ loading: true });
    const { entityId, name, enqueueSnackbar } = this.props;
    await this.props.onDelete(entityId, name, enqueueSnackbar);
    this.setState({ loading: false });
    this.setState({ show: false });
  };

  render() {
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null,
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }
    return (
      <>
        <Button onClick={() => this.setState({ show: true })} className="issueButton">
          <FontAwesomeIcon icon={faTrash} style={{ fontSize: "16px", marginRight: "7px" }} />
          {`Delete ${clientModeConfigObject.cap_word_for_collection}`}
        </Button>
        <Modal show={this.state.show} onHide={() => this.setState({ show: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loading ? (
              <CircularProgress />
            ) : (
              <div>
                <Button onClick={() => this.setState({ show: false })}>Keep</Button>
                <Button onClick={() => this.onDeletePress()} className="issueButton">
                  Delete
                </Button>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

DeleteEntity.propTypes = {
  onDelete: PropTypes.func.isRequired,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
};
DeleteEntity.defaultProps = {
  name: "",
};

const mapStateToProps = state => {
  return {
    clientMode: getClientMode(state),
  };
};

export default connect(
  mapStateToProps,
  null,
  null
)(withSnackbar(DeleteEntity));
