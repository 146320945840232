// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import PropTypes from "prop-types";

export const storeShape = PropTypes.shape({
  subscribe: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  getState: PropTypes.func.isRequired,
});

export const reactRouterMatchShape = params =>
  PropTypes.shape({
    match: PropTypes.shape({
      params,
    }),
    isExact: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  });

export const reactRouterHistoryShape = PropTypes.shape({
  length: PropTypes.number.isRequired,
  action: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    state: PropTypes.object,
  }),
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  go: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  block: PropTypes.func.isRequired,
});

export const cytoscapeShape = PropTypes.shape({
  layout: PropTypes.func.isRequired,
  elements: PropTypes.func.isRequired,
  center: PropTypes.func.isRequired,
  fit: PropTypes.func.isRequired,
  extent: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
});

export const cytoscapeNodeShape = PropTypes.shape({
  data: PropTypes.func.isRequired,
  id: PropTypes.func.isRequired,
  cy: PropTypes.func.isRequired,
});

export const transactionAddress = PropTypes.shape({
  address: PropTypes.arrayOf(PropTypes.string).isRequired,
  key: PropTypes.string.isRequired,
  primaryTag: PropTypes.string,
  satoshi: PropTypes.number,
  // will be null if output hasn't been spent yet.
  transactionHash: PropTypes.string,
  walletId: PropTypes.number,
  // will not be returned for addresses on input side
  firstBlock: PropTypes.number,
  isChange: PropTypes.bool.isRequired,
  isNote: PropTypes.string,
});

export const transactionAddressCollapsed = PropTypes.shape({
  address: PropTypes.arrayOf(PropTypes.string).isRequired,
  key: PropTypes.string.isRequired,
  satoshi: PropTypes.number.isRequired,
  walletId: PropTypes.number,
  primaryTag: PropTypes.string,
});

export const transactionWallet = PropTypes.shape({
  walletId: PropTypes.number,
  key: PropTypes.string.isRequired,
  satoshi: PropTypes.number,
  primaryTag: PropTypes.string,
});
