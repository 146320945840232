import React from "react";
import CopyText from "../CopyText";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger } from "react-bootstrap";
import { FullWidthPopover } from "../styled";

const CategoryName = ({
  category,
  categoryColor,
  categoryFontColor,
  categoryLabel,
  confidence_score = null
}) => {
  const tooltip = (
    <FullWidthPopover
      style={{ fontFamily: "Quicksand", fontWeight: "600", fontSize: "12px" }}
    >
      This category was predicted with {(confidence_score * 100).toFixed(0)}%
      certainty.
    </FullWidthPopover>
  );

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

  let isConfident = confidence_score > 0.3 || !confidence_score;
  return isConfident ? (
    <ConditionalWrapper
      condition={confidence_score}
      wrapper={children => (
        <OverlayTrigger
          overlay={tooltip}
          placement="bottom"
          delayShow={300}
          delayHide={150}
        >
          {children}
        </OverlayTrigger>
      )}
    >
      <div>
        <span
          style={{
            backgroundColor: categoryColor,
            borderRadius: "7px",
            border: `1px solid ${categoryColor}`,
            color: categoryFontColor,
            fontWeight: "700",
            fontSize: "14px",
            fontFamily: "Quicksand",
            padding: "2px 10px 2px 10px",
            marginLeft: "10px"
          }}
        >
          {categoryLabel}{" "}
          {confidence_score && `(${(confidence_score * 100).toFixed(0)}%)`}
        </span>
      </div>
    </ConditionalWrapper>
  ) : (
    <></>
  );
};

export default CategoryName;
