/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, ListGroupItem } from "react-bootstrap";
import { Set } from "immutable";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { FakeLinkSpan } from "../../styled";
import { cytoscapeShape, reactRouterMatchShape } from "../../../prop-types";
import {
  __addWalletToGraphInExplorer,
  addPeelChainToGraph,
  addWalletToGraph,
  addWalletToGraphInExplorer,
  fetchPeelChainStatus,
  isPeelChainInGraph,
  openWalletModal
} from "../../../actions/graph";
import { getWalletSetMemo } from "../../../selectors/graph";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faLocationArrow, faPlus } from "@fortawesome/free-solid-svg-icons/index";
import { faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@material-ui/core";

const SearchRow = ({
  primaryTag,
  walletId,
  cy,
  wallets,
  addWalletToGraphCallback,
  handleOpenWalletModal,
  match: {
    params: { graphId }
  },
  category,
  selectElementFromSearchResult,
  peel,
  addPeelChainToGraph,
  fetchWalletPeelChainStatus,
  disabled = false
}) => {
  const [peelLoading, setPeelLoading] = useState(false);
  const checkPeelChain = async () => {
    setPeelLoading(true);

    await fetchWalletPeelChainStatus(walletId);

    setPeelLoading(false);
  };

  const addPeelToGraph = async () => {
    setPeelLoading(true);

    await addPeelChainToGraph(walletId, cy, graphId);

    setPeelLoading(false);
  };

  const addWalletToGraph_ = () => {
    // The line below unselects all elements in the graph when a new one is added
    cy.elements().unselect();
    addWalletToGraphCallback(walletId, graphId, cy);
  };

  return (
    <ListGroupItem>
      <div
        className="row"
        style={{
          margin: "0",
          fontFamily: "Quicksand",
          fontWeight: "700",
          fontSize: "13px",
          paddingBottom: "5px"
        }}
      >
        <span style={{ borderBottom: "1px solid var(--graph-sidebar-base)", paddingBottom: "2px" }}>
          {walletId < 0 ? "Custom Wallet" : "Wallet"}
        </span>
      </div>
      <div
        className="row"
        style={{
          display: "flex",
          paddingBottom: "0",
          margin: "0"
        }}
      >
        <div
          className="col-lg-6"
          style={{
            overflow: "auto",
            wordWrap: "break-word",
            paddingLeft: "0",
            paddingRight: "0",
            paddingBottom: "0",
            marginLeft: "0",
            marginRight: "5px"
          }}
        >
          <FakeLinkSpan
            onClick={() => handleOpenWalletModal(walletId)}
            className="graphSearchTagName"
          >
            {primaryTag || walletId}
          </FakeLinkSpan>
        </div>

        <div
          className="col-lg-6"
          style={{
            paddingLeft: "0",
            paddingRight: "0",
            paddingBottom: "0",
            marginLeft: "5px",
            marginRight: "0"
          }}
        >
          {!wallets.has(walletId.toString()) ? (
            <>
              <Button
                className="pull-right allPurpleButton"
                bsSize="xsmall"
                onClick={addWalletToGraph_}
                style={{ display: "inline" }}
                disabled={disabled}
              >
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: "3px" }} />
                Add to Graph
              </Button>
              {peelLoading ? (
                <CircularProgress
                  className="pull-right"
                  size={20}
                  style={{ color: "var(--secondary-color)", marginRight: "10px" }}
                />
              ) : peel === null ? (
                <Button
                  className="pull-right allPurpleButton"
                  bsSize="xsmall"
                  onClick={() => checkPeelChain()}
                  style={{ display: "inline", marginRight: "5px" }}
                >
                  <FontAwesomeIcon icon={faNetworkWired} style={{ marginRight: "3px" }} />
                  Check Peel Chain
                </Button>
              ) : peel === true ? (
                <Button
                  className="pull-right allPurpleButton"
                  bsSize="xsmall"
                  onClick={() => addPeelToGraph()}
                  style={{ display: "inline", marginRight: "5px" }}
                  disabled={disabled}
                >
                  <FontAwesomeIcon icon={faNetworkWired} style={{ marginRight: "3px" }} />
                  Add Peel Chain
                </Button>
              ) : (
                <> </>
              )}
            </>
          ) : (
            <>
              <Button
                className="pull-right greenButtonGraph"
                bsSize="xsmall"
                style={{ marginLeft: "10px" }}
              >
                <FontAwesomeIcon icon={faCheck} style={{ marginRight: "3px" }} /> Added
              </Button>
              <Button
                className="pull-right locateButtonGraph"
                bsSize="xsmall"
                onClick={() => selectElementFromSearchResult(walletId)}
              >
                <FontAwesomeIcon icon={faLocationArrow} style={{ marginRight: "3px" }} /> Locate
              </Button>
              {peelLoading ? (
                <CircularProgress
                  className="pull-right"
                  size={20}
                  style={{ color: "var(--secondary-color)", marginRight: "10px" }}
                />
              ) : peel === null ? (
                <Button
                  className="pull-right allPurpleButton"
                  bsSize="xsmall"
                  onClick={() => checkPeelChain()}
                  style={{ display: "inline", marginRight: "5px" }}
                >
                  <FontAwesomeIcon icon={faNetworkWired} style={{ marginRight: "3px" }} />
                  Check Peel Chain
                </Button>
              ) : peel === true ? (
                <Button
                  className="pull-right allPurpleButton"
                  bsSize="xsmall"
                  onClick={() => addPeelToGraph()}
                  style={{ display: "inline", marginRight: "5px" }}
                  disabled={disabled}
                >
                  <FontAwesomeIcon icon={faNetworkWired} style={{ marginRight: "3px" }} />
                  Add Peel Chain
                </Button>
              ) : (
                <> </>
              )}
            </>
          )}
        </div>
      </div>
    </ListGroupItem>
  );
};

SearchRow.propTypes = {
  primaryTag: PropTypes.string.isRequired,
  walletId: PropTypes.number.isRequired,
  cy: cytoscapeShape.isRequired,
  wallets: PropTypes.instanceOf(Set).isRequired,
  addWalletToGraphCallback: PropTypes.func.isRequired,
  handleOpenWalletModal: PropTypes.func.isRequired,
  match: reactRouterMatchShape({ graphId: PropTypes.string.isRequired }).isRequired,
  category: PropTypes.string,
  selectElementFromSearchResult: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

SearchRow.defaultProps = {
  category: null
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { graphId }
    }
  }
) => {
  return {
    wallets: getWalletSetMemo(state, Number.parseInt(graphId, 10))
  };
};

const mapDispatchToProps = dispatch => ({
  handleOpenWalletModal: wallet => dispatch(openWalletModal(wallet)),
  // isPeelChainInGraph: (wallet_id, wallets) => dispatch(isPeelChainInGraph(wallet_id, wallets)),
  addPeelChainToGraph: (wallet_id, cy, graphId) =>
    dispatch(addPeelChainToGraph(wallet_id, cy, graphId)),
  addWalletToGraphCallback: (walletId, graphId, cy) =>
    dispatch(addWalletToGraphInExplorer(walletId, graphId, cy)),
  fetchWalletPeelChainStatus: walletId => dispatch(fetchPeelChainStatus(walletId))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SearchRow)
);
