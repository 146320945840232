/*
 * Copyright 2018-2021  DecisionQ Information Operations, Inc. All Rights Reserved. Pizza
 */
import React from "react";
import PropTypes from "prop-types";
import { DropdownButton } from "react-bootstrap";
import MenuItem from "react-bootstrap/lib/MenuItem";

class BtcToUsdChooser extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      showAsUsd,
      toggleUsdDisplay,
      historicalToUsd,
      toggleHistoricalUsd,
      currencyAbbreviation
    } = this.props;

    return (
      <>
        <p
          className={!showAsUsd && !historicalToUsd ? "usdToggle" : "usdToggleInactive"}
          style={{ marginRight: "10px" }}
          onClick={() => toggleUsdDisplay(false)}
        >
          {currencyAbbreviation}
        </p>
        <p className="usdToggle"> | </p>
        {historicalToUsd !== null ? (
          <DropdownButton
            bsStyle={"USD".toLowerCase()}
            className={showAsUsd || historicalToUsd ? "usdToggle" : "usdToggleInactive"}
            style={{
              marginLeft: "10px",
              fontFamily: "Quicksand",
              padding: "0px 0px 5px 0px",
              border: "none",
              background: "none"
            }}
            title="USD"
          >
            <MenuItem
              className={"usdToggleMenuItem"}
              eventKey="1"
              onClick={() => toggleUsdDisplay(true)}
            >
              Current Day Prices
            </MenuItem>
            <MenuItem
              className={"usdToggleMenuItem"}
              eventKey="2"
              onClick={() => toggleHistoricalUsd(true)}
            >
              Historical Prices
            </MenuItem>
          </DropdownButton>
        ) : (
          <p
            className={showAsUsd ? "usdToggle" : "usdToggleInactive"}
            style={{ marginLeft: "10px" }}
            onClick={() => toggleUsdDisplay(true)}
          >
            USD
          </p>
        )}
      </>
    );
  }
}

BtcToUsdChooser.propTypes = {
  showAsUsd: PropTypes.bool.isRequired,
  toggleUsdDisplay: PropTypes.func.isRequired,
  historicalToUsd: PropTypes.bool.isRequired,
  toggleHistoricalUsd: PropTypes.func.isRequired,
  currencyAbbreviation: PropTypes.string.isRequired
};

export default BtcToUsdChooser;
