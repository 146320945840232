/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import PropTypes from "prop-types";
import { EditorState } from "draft-js";

import StyleButton from "./StyleButton";

const BLOCK_TYPES = [
  {
    label: "H1",
    style: "header-one",
  },
  {
    label: "H2",
    style: "header-two",
  },
  {
    label: "H3",
    style: "header-three",
  },
  {
    label: "H4",
    style: "header-four",
  },
  {
    label: "H5",
    style: "header-five",
  },
  {
    label: "H6",
    style: "header-six",
  },
  {
    label: "Blockquote",
    style: "blockquote",
  },
  {
    label: "UL",
    style: "unordered-list-item",
  },
  {
    label: "OL",
    style: "ordered-list-item",
  },
];

const BlockStyleControls = ({ editorState, onToggle }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

export default BlockStyleControls;

BlockStyleControls.propTypes = {
  editorState: PropTypes.instanceOf(EditorState).isRequired,
  onToggle: PropTypes.func.isRequired,
};
