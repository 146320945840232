/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import moment from "moment";

import axios, { USER_API } from "../../api";

export default class UserLogins extends Component {
  state = {
    loginTimes: [],
  };

  componentDidMount() {
    // This works since the modal that contains this gets mounted/dismounted I guess?
    this.fetchLoginTimes();
  }

  fetchLoginTimes = async () => {
    if (this.props.userId == null) {
      return;
    }

    try {
      // We can change this to user-api i think.
      const { data } = await axios.get(`${USER_API}/${this.props.userId}`);
      this.setState({
        loginTimes: data.loginTimes,
      });
    } catch (err) {
      throw err;
    }
  };

  render() {
    const loginTimes = this.state.loginTimes.map(loginTime => {
      const date = moment.unix(parseInt(loginTime, 10)).format("LLL");
      return (
        <tr key={loginTime}>
          <td>{date}</td>
        </tr>
      );
    });

    return (
      <Table striped hover>
        <caption>Past 500 Logins</caption>
        <thead>
          <tr>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>{loginTimes}</tbody>
      </Table>
    );
  }
}

UserLogins.propTypes = {
  userId: PropTypes.number,
};

UserLogins.defaultProps = {
  userId: null,
};
