/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";
import { satoshiToBitcoin } from "../../helpers";

const DummyWalletsRow = ({ satoshi, sent }) => {
  const bitcoin = satoshiToBitcoin(satoshi * sent);
  return (
    <tr>
      <td style={{ width: "40%" }}>(Pending Analysis by Cryptovoyant)</td>
      <td style={{ width: "60%" }} className="text-left">
        {bitcoin}
      </td>
    </tr>
  );
};

DummyWalletsRow.propTypes = {
  satoshi: PropTypes.number.isRequired,
};

export default DummyWalletsRow;
