/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import { Glyphicon } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FakeLinkSpan } from "../styled";

export const getModalWalletLink = (walletId, primaryTag, handleOpenWalletModal) => (
  <FakeLinkSpan onClick={() => handleOpenWalletModal(walletId.toString())}>
    {primaryTag || walletId}
  </FakeLinkSpan>
);

// handleOpenAddressModal arg is just to maintain consistency with getModalWalletLink
export const getReactRouterWalletLink = (
  walletId,
  primaryTag,
  handleOpenTransactionModal,
  currency
) => <Link to={`/${currency}/wallet/${walletId}`}>{primaryTag || walletId}</Link>;

export const getModalTransactionLink = (transactionHash, direction, handleOpenTransactionModal) => {
  if (direction === "right") {
    return (
      <FakeLinkSpan onClick={() => handleOpenTransactionModal(transactionHash)}>
        <Glyphicon glyph="menu-right" />
      </FakeLinkSpan>
    );
  }
  return (
    <FakeLinkSpan onClick={() => handleOpenTransactionModal(transactionHash)}>
      <Glyphicon glyph="menu-left" />
    </FakeLinkSpan>
  );
};
// handleOpenAddressModal arg is just to maintain consistency with getModalTransactionLink

export const getReactRouterTransactionLink = (
  transactionHash,
  direction,
  handleOpenTransactionModal,
  currency
) => {
  if (direction === "right") {
    return (
      <Link to={`/${currency}/transaction/${transactionHash}`}>
        <Glyphicon glyph="menu-right" />
      </Link>
    );
  }
  return (
    <Link to={`/${currency}/transaction/${transactionHash}`}>
      <Glyphicon glyph="menu-left" />
    </Link>
  );
};

export const getModalAddressLink = (address, addressCell, handleOpenAddressModal) => (
  <FakeLinkSpan onClick={() => handleOpenAddressModal(address)}>
    {addressCell.split(":").pop()}
  </FakeLinkSpan>
);

// handleOpenAddressModal arg is just to maintain consistency with getModalAddressLink
export const getReactRouterAddressLink = (
  address,
  addressCell,
  handleOpenAddressModal,
  currency
) => (
  <Link to={`/${currency}/address/${address.split(":").pop()}`}>
    {addressCell.split(":").pop()}
  </Link>
);
