/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "../../css/styles.css";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import HelpDialogueChooser from "./HelpDialogueChooser";
import { getCurrency } from "../selectors/currency";
import { getClientMode } from "../selectors/applicationConfig";
import ClientModeConfig from "./ClientModeConfig";

export class HelpVideosPage extends Component {
  state = {
    chooser: {},
    current: null,
  };

  componentDidMount() {
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null,
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    this.setState(
      {
        chooser: HelpDialogueChooser(clientModeConfigObject),
      },
      () => this.setCurrentVideo(this.state.chooser[Object.keys(this.state.chooser)[0]].elements[0])
    );
  }

  setCurrentVideo = video => {
    this.setState({ current: video });
  };

  render() {
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null,
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }
    const helpElements = Object.keys(this.state.chooser).map(ele => {
      const data = this.state.chooser[ele];
      return (
        <div>
          <p className="videoPageCategoryTitle"> {data.title} </p>
          <ul>
            {data.elements.map(video => {
              return video.duplicate ? (
                ""
              ) : (
                <div className="row">
                  <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10">
                    <li
                      className="videoPageVideoTitle"
                      style={{ fontWeight: this.state.current === video ? "700" : "600" }}
                      onClick={() => this.setCurrentVideo(video)}
                    >
                      <span
                        style={{
                          color: this.state.current === video ? "var(--secondary-color)" : "black",
                          fontSize: this.state.current === video ? "16px" : "14px",
                        }}
                      >
                        {video.title}
                      </span>
                    </li>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    {this.state.current === video && (
                      <FontAwesomeIcon
                        style={{ color: "var(--secondary-color)", fontSize: "20px" }}
                        icon={faCaretRight}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </ul>
        </div>
      );
    });

    return (
      <div style={{ overflowX: "hidden" }}>
        <Helmet>
          <title>Tutorial</title>
        </Helmet>

        <div className="row">
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2" />
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8">
            <div className="row" style={{ marginBottom: "40px" }}>
              <h1 className="subpoenaHeader"> Training Videos </h1>
            </div>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 videoSidebar">
                {helpElements}
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8">
                {this.state.current && (
                  <div>
                    <ReactPlayer
                      controls
                      url={this.state.current.video}
                      width="100%"
                      height="100%"
                      style={{
                        borderTop: "2px solid var(--secondary-color)",
                        borderBottom: "none",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    />
                    <p className="videoPageMainTitle"> {this.state.current.title} </p>
                    <p className="videoPageMainDescription"> {this.state.current.description} </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currency: getCurrency(state),
    clientMode: getClientMode(state),
  };
};

export default connect(
  mapStateToProps,
  null
)(HelpVideosPage);
