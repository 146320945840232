import { PieChart } from "react-minimal-pie-chart";
import { Row } from "react-bootstrap";
import { significantDecimals } from "../../helpers";
import * as React from "react";
import { PropsWithDefaults } from "react-minimal-pie-chart/types/Chart/Chart";

export const colors = {
  "Low Risk": "green",
  "Medium Risk": "yellow",
  "High Risk": "red",
  "Unknown Risk": "gray",
  "OFAC Sanctioned": "blue"
};

type riskTypes = keyof typeof colors;
type riskObj = Partial<{ [key in riskTypes]: number }>;

export interface RiskPieChartProps extends Partial<PropsWithDefaults> {
  risks?: riskObj;
  direction: "Sent" | "Received";
}

export const RiskPieChart: React.FC<RiskPieChartProps> = ({ risks, direction, ...props }) => {
  if (risks == null) {
    // if no risks just put empty
    return <></>;
  }
  const filteredRisks: riskObj = Object.keys(risks)
    .filter(key => key !== "Unknown Risk")
    .reduce<riskObj>((obj, key) => {
      obj[key as riskTypes] = risks[key as riskTypes];
      return obj;
    }, {});

  const sum_risk: number = Object.values(filteredRisks).reduce(
    (accumulator: number, value: number) => accumulator + value,
    0
  );
  console.log(filteredRisks);
  return (
    <>
      {Object.keys(filteredRisks).length > 0 ? (
        <>
          <div
            style={{
              width: "50%",
              textAlign: "center",
              margin: "auto"
            }}
          >
            <PieChart
              lineWidth={100}
              data={Object.keys(filteredRisks).map(key => {
                return {
                  title: key,
                  value: filteredRisks[key as riskTypes],
                  color: colors[key as riskTypes]
                };
              })}
              {...props}
            />
          </div>
          <p
            style={{
              textAlign: "center",
              fontSize: "30px",
              width: "100%"
            }}
          >
            {direction === "Sent" ? "Sent To" : "Received From"}
          </p>
          <div style={{ textAlign: "center", display: "inlineBlock" }}>
            {Object.keys(filteredRisks).map(key => {
              return (
                <Row key={`risk-${key}`} style={{ display: "flex", marginBottom: "10px" }}>
                  <span
                    className="dot"
                    style={{ backgroundColor: `${colors[key as riskTypes]}` }}
                  />
                  <div style={{ height: "35px" }}>
                    <p
                      style={{
                        display: "inlineBlock",
                        margin: "0",
                        lineHeight: "35px",
                        verticalAlign: "middle",
                        marginLeft: "10px",
                        fontSize: "14px"
                      }}
                    >
                      {" "}
                      <b>
                        {significantDecimals(
                          Number(((filteredRisks[key as riskTypes] as number) / sum_risk) * 100),
                          2
                        )}
                        %
                      </b>{" "}
                      - {key} Address{" "}
                    </p>
                  </div>
                </Row>
              );
            })}
          </div>
        </>
      ) : (
        <p
          style={{
            textAlign: "center",
            fontSize: "30px",
            width: "100%",
            marginTop: "25%"
          }}
        >
          {`No Funds ${direction}`}
        </p>
      )}
    </>
  );
};
