import { Map } from "immutable";
import { LOGGING_OUT, SEARCH_SUCCESS } from "../../actions/actionNames";
import { SearchResult } from "../search";

export const addSearchResult = (state, { query, data }) => {
  return state.set(query, new SearchResult(data));
};

const makeSearchReducerEthereum = coin => {
  // This just returns the state, which is why each of the switch funcs returns an ImmutableJs set
  return (state = Map(), action) => {
    // If the coin doesn't match the reducer, just return the state.
    if (action === undefined || action.name !== coin) {
      return state;
    }

    switch (action.type) {
      case SEARCH_SUCCESS:
        return addSearchResult(state, action);
      case LOGGING_OUT:
        return Map();
      default:
        return state;
    }
  };
};

export default makeSearchReducerEthereum;
