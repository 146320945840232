/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

/* eslint-disable react/jsx-closing-tag-location */
import React from "react";
import { Search } from "./Search";
import "../../css/styles.css";

/**
 * Used as general 404 response for requested pages
 * @returns {React.Component}
 * @constructor
 */
const NoSearchResults = () => (
  <div className="emptySearchDiv-outer">
    <div className="emptySearchDiv-inner">
      {/*<img src={header} style={{ paddingBottom: "30px" }} alt="Whitesail Header" />*/}
      <Search wide="50%" groupWide="100%" />
      <h4 style={{ fontFamily: "Quicksand" }}>
        {"This search term didn't match anything. Please try a different query."}
      </h4>
    </div>
  </div>
);

export default NoSearchResults;
