import React, { useState } from "react";
import { connect } from "react-redux";
import { getFeature } from "../../selectors/features";
import { getCurrency } from "../../selectors/currency";
import { getRole, getTwoFASuccess } from "../../selectors/authentication";
import { getCoins } from "../../selectors/coins";
import { SWAP_CURRENCY } from "../../actions/actionNames";
import { Col, Grid, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ReactCodeInput from "react-code-input";
import { Button } from "@material-ui/core";
import { submitTwoFactor } from "../../actions/authentication";
import { Redirect } from "react-router-dom";

const TwoFAEnter = ({ submit, success }) => {
  const [code, setCode] = useState(null);
  const [error, setError] = useState(null);

  const submitCode = async () => {
    const data = await submit(code);
    if (!data) {
      setError(true);
    }
  };
  return (
    <>
      {success ? (
        <Redirect to="/" />
      ) : (
        <Grid>
          <Helmet>
            <title>2FA Enter</title>
          </Helmet>
          <Row style={{ paddingTop: "50px" }}>
            <Col lg={12}>
              <Row style={{ textAlign: "center", marginBottom: "20px" }}>
                <p style={{ fontSize: "20px" }}> Enter your Two Factor Authentication Code </p>
              </Row>
              <Row style={{ textAlign: "center", marginBottom: "20px" }}>
                <ReactCodeInput
                  type="text"
                  fields={6}
                  onChange={e => {
                    setCode(e);
                    setError(false);
                  }}
                />
              </Row>
              <Row style={{ textAlign: "center", marginBottom: "20px" }}>
                <Button
                  style={{
                    backgroundColor: "var(--base-color)",
                    color: "white",
                    fontFamily: "Quicksand",
                    fontSize: "12px",
                  }}
                  onClick={() => submitCode()}
                >
                  Submit
                </Button>
              </Row>
              {error && (
                <Row style={{ textAlign: "center" }}>
                  <p style={{ color: "red", fontSize: "14px" }}>
                    {" "}
                    There was an error, please try again.
                  </p>
                </Row>
              )}
            </Col>
          </Row>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    throwExceptionComponentEnabled: getFeature(state, "throw_exception_test"),
    issuesEnabled: getFeature(state, "issues"),
    currency: getCurrency(state),
    isResearcher: getRole(state) === "researcher",
    success: getTwoFASuccess(state),
  };
};

const mapDispatchToProps = dispatch => ({
  swapCurrency: currency => dispatch({ type: SWAP_CURRENCY, currency }),
  submit: code => dispatch(submitTwoFactor(code)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoFAEnter);
