/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, ControlLabel, FormControl, FormGroup, HelpBlock, Table } from "react-bootstrap";
import PropTypes from "prop-types";

import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import { faSave } from "@fortawesome/free-solid-svg-icons/faSave";
import {
  fetchWalletSummary,
  setOriginalWalletTag,
  setGraphWalletTag,
  setOriginalWalletTagFromPage
} from "../../actions/wallet";
import { getTags, getWalletName, getWalletNames } from "../../selectors/wallet";
import { cytoscapeNodeShape } from "../../prop-types";
import { fetchAddressSummaryEthereum } from "../../actions/ethereum/address";
import { CircularProgress } from "@material-ui/core";
import { setWalletTag } from "../../actions/customTags";
import { getEmail } from "../../selectors/authentication";
import { CustomTagRow } from "../Home/CustomTags/CustomTagRow";
import ClientModeConfig from "../ClientModeConfig";
import { getClientMode } from "../../selectors/applicationConfig";
import { getCurrency } from "../../selectors/currency";
import { getAddressSummary } from "../../selectors/ethereum/address";

class WalletTagSetter extends Component {
  state = {
    fields: {
      primaryTag: this.props.primaryTag || ""
    },
    notificationVisible: false,
    loading: true
  };

  componentDidMount = async () => {
    await this.props.fetch();
    this.setState({
      fields: {
        primaryTag: this.props.primaryTag || ""
      },
      loading: false
    });
  };

  // There is a bug here where the text field sometimes appears blank because of something with this.
  // Look into this in the future and consider using react hooks useEffect()
  componentDidUpdate = async prevProps => {
    const { wallet: oldWallet } = prevProps;
    const { wallet: newWallet } = this.props;
    if (oldWallet !== newWallet) {
      this.setState({ loading: true });
      await this.props.fetch();
      this.setState({
        fields: {
          primaryTag: this.props.primaryTag || ""
        },
        loading: false
      });
    }
  };

  onInputChange = e => {
    const { fields } = this.state;
    if (e.target.type !== "checkbox") {
      fields[e.target.id] = e.target.value;
    } else {
      fields[e.target.id] = e.target.checked;
    }
    this.setState({ fields, notificationVisible: false });
  };

  /**
   * Handles modifying a tag
   * @param tag new tag to use
   * @param permission user's permission on the tag
   * @param id the custom tag id
   * @returns {Promise<void>}
   */
  handleSetTag = async (tag, permission, custom_id) => {
    // dispatch the set wallet tag action
    const { walletName, cy, email } = this.props;
    const { tagType, name, id } = walletName;
    try {
      await this.props.setWalletTag(tag, custom_id, permission, email);
      console.log(tagType, id, name);
      if ((tagType === "customTag" || tagType === "Ethereum Custom Tag") && custom_id === id) {
        await this.props.setGraphWalletTag(walletName["name"], tag, cy);
      }
    } catch (err) {
      throw err;
    }
  };

  handleNewTag = async e => {
    e.preventDefault();
    const { primaryTag } = this.state.fields;
    const beforeName = `${this.props.walletName.name}`;
    await this.handleSetTag(primaryTag, 1, null);
    const { walletName, cy } = this.props;
    console.log(walletName, beforeName);
    if (walletName["name"] !== beforeName) {
      // see if main name changed because of new custom tag
      await this.props.setGraphWalletTag(beforeName, walletName["name"], cy);
    }
    this.setState({ notificationVisible: true });
  };

  /**
   * handles deleting a custom tag
   * @returns {Promise<void>}
   * @param custom_id
   */
  handleSetOriginalTag = async custom_id => {
    const { walletName, walletNames } = this.props;
    const { tagType, id, name } = walletName;
    try {
      await this.props.setOriginalWalletTagFromPage(id);
      if (tagType === "customTag" && custom_id === id) {
        await this.props.setOriginalWalletTag(name, walletNames[1]["name"], this.props.cy);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  render() {
    const {
      originalTag,
      primaryTag,
      wallet,
      walletName,
      walletNames,
      currency,
      clientMode
    } = this.props;

    // Compare the current tag to the original tag/id
    console.log(originalTag, primaryTag, walletName, wallet.data("label"));

    const matchingWalletName = walletNames.find(
      ({ name, tagType }) => tagType !== "Ethereum Custom Tag" && name === wallet.data("label")
    );
    return (
      <div>
        <form onSubmit={this.handleSetTag} autoComplete="off">
          <FormGroup controlId="primaryTag">
            {!this.state.loading && (
              <FormControl
                type="text"
                value={this.state.fields.primaryTag}
                onChange={this.onInputChange}
              />
            )}
          </FormGroup>
          {this.state.loading ? (
            <CircularProgress style={{ color: "white" }} />
          ) : (
            <>
              <HelpBlock
                style={{
                  color: this.props.fromModal ? "var(--graph-sidebar-base)" : "white",
                  fontFamily: "Quicksand",
                  marginTop: "0",
                  fontWeight: "600"
                }}
              >
                This will set the wallet tag globally for your personal use.
              </HelpBlock>
              <Button
                className={this.props.fromModal ? "allPurpleButton" : "control-whiteButton"}
                type="submit"
                onClick={this.handleNewTag}
                disabled={
                  this.state.fields.primaryTag.length === 0 ||
                  (walletNames &&
                    !!walletNames.find(({ name }) => name === this.state.fields.primaryTag))
                }
              >
                <FontAwesomeIcon icon={faSave} /> Save
              </Button>
              {this.props.currency === "ethereum" && (
                <Button
                  className={this.props.fromModal ? "allPurpleButton" : "control-whiteButton"}
                  style={{ marginLeft: "10px" }}
                  onClick={async e => {
                    e.preventDefault();
                    await this.props.setOriginalWalletTag(
                      walletName["name"],
                      walletNames.find(
                        ({ name, tagType }) =>
                          tagType !== "Ethereum Custom Tag" && name !== wallet.data("label")
                      ).name,
                      this.props.cy
                    );
                  }}
                  disabled={!!matchingWalletName}
                >
                  {!matchingWalletName &&
                  walletNames.find(
                    ({ name, tagType }) =>
                      tagType !== "Ethereum Custom Tag" && name !== wallet.data("label")
                  ) ? (
                    <>
                      Use original tag:{" "}
                      {
                        walletNames.find(
                          ({ name, tagType }) =>
                            tagType !== "Ethereum Custom Tag" && name !== wallet.data("label")
                        ).name
                      }
                    </>
                  ) : (
                    <>Original tag currently used.</>
                  )}
                </Button>
              )}
            </>
          )}
        </form>
        <br />
        <span
          style={{
            visibility: this.state.notificationVisible ? "visible" : "hidden"
          }}
          className={this.state.fromSidebar ? "sidebarNotification" : "modalNotification"}
        >
          <>
            {" "}
            <FontAwesomeIcon icon={faCheck} /> {" Tag Saved !"}{" "}
          </>
        </span>
        <Table className="inviteTable" style={{ marginBottom: "50px" }}>
          <thead>
            <tr>
              <th style={{ width: "50%" }}>Tags</th>
              <th style={{ width: "50%" }} />
            </tr>
          </thead>
          <tbody>
            {walletNames &&
              walletNames
                .filter(({ tagType }) => tagType !== "ID")
                .map(({ id, email, name, permission, tagType }) => (
                  <CustomTagRow
                    tag={name}
                    attributed_tag={originalTag}
                    email={email}
                    main_name={currency === "ethereum" ? null : walletName["name"] || ""}
                    id={id}
                    tagType={tagType}
                    currency={currency}
                    entity_id={wallet.id()}
                    permission={permission || 0}
                    showWalletName={false}
                    setMainNameBeforeCallback={async () => {
                      await this.props.setGraphWalletTag(walletName["name"], name, this.props.cy);
                    }}
                    handleSetOriginalTag={id => this.handleSetOriginalTag(id)}
                    handleSetTag={(tag, permission, id) => this.handleSetTag(tag, permission, id)}
                  />
                ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

WalletTagSetter.propTypes = {
  wallet: cytoscapeNodeShape.isRequired,
  fetch: PropTypes.func.isRequired,
  primaryTag: PropTypes.string,
  originalTag: PropTypes.string,
  setWalletTag: PropTypes.func.isRequired,
  setOriginalWalletTag: PropTypes.func.isRequired
};

WalletTagSetter.defaultProps = {
  primaryTag: null,
  originalTag: null,
  fromModal: false,
  cy: null
};

const mapStateToProps = (state, { wallet, currency }) => ({
  walletName: getWalletName(state, wallet.id()),
  walletNames: getWalletNames(state, wallet.id()),
  email: getEmail(state),
  clientMode: getClientMode(state),
  currency: getCurrency(state),
  summary: currency === "ethereum" ? getAddressSummary(state, wallet, true) : null
});

const mapDispatchToProps = (dispatch, { wallet, currency, email }) => {
  const id = wallet.id();
  return {
    fetch: () =>
      dispatch(currency === "ethereum" ? fetchAddressSummaryEthereum(id) : fetchWalletSummary(id)),
    setGraphWalletTag: (oldTag, newTag, cy) =>
      dispatch(setGraphWalletTag(wallet, oldTag, newTag, cy)),
    setWalletTag: (tag, custom_id, permission, email) =>
      dispatch(setWalletTag(id, tag, email, custom_id, permission)),
    setOriginalWalletTag: (currentTag, originalTag, cy) =>
      dispatch(setOriginalWalletTag(wallet, currentTag, originalTag, cy)),
    setOriginalWalletTagFromPage: custom_id => dispatch(setOriginalWalletTagFromPage(id, custom_id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletTagSetter);
