import React from "react";
import moment from "moment";

export const ChatBubble = ({ timestamp, text, outgoing_message, email }) => {
  return (
    <div className={outgoing_message ? "sent messages" : "received messages"}>
      <p style={{ color: "#333", fontWeight: "700", marginBottom: "0" }}>{email}</p>
      <div className="chatBubble">
        <p style={{ fontWeight: "600", marginBottom: "0" }}>{text}</p>
      </div>
      <p style={{ fontSize: "12px" }}>
        {moment(timestamp)
          .utc()
          .format("MM-DD-YYYY HH:MM")}
      </p>
    </div>
  );
};
