/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { useState } from "react";
import { Button, Form, FormControl, FormGroup, Modal, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faSave,
  faTimes,
  faTimesCircle,
  faWindowClose
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { setWalletTag } from "../actions/customTags";
import { setOriginalWalletTagFromPage } from "../actions/wallet";
import { getClientMode } from "../selectors/applicationConfig";
import ClientModeConfig from "./ClientModeConfig";
import { useSnackbar } from "notistack";
import { getCustomTagInfo, getWalletName } from "../selectors/wallet";
import { getCurrency } from "../selectors/currency";
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { getEmail } from "../selectors/authentication";
import { WALLET_REMOVE_CUSTOM_TAG } from "../actions/actionNames";
import { CustomTagRow } from "./Home/CustomTags/CustomTagRow";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";

/**
 * The create custom tag button for wallet.
 * @param wallet_id
 * @returns {JSX.Element}
 * @constructor
 */
export const CreateCustomTag = ({ wallet_id }) => {
  // Shows the custom tag modal
  const [showModal, setShowModal] = useState(false);
  const [newTag, setNewTag] = useState(null); // holds form data for if user decides to add new custom tag, null if not editing
  const { enqueueSnackbar, closeSnackbar } = useSnackbar(); // Notification snackbar

  // Selector to get the list of custom tags
  const customTag = useSelector(state => getCustomTagInfo(state, wallet_id));
  const clientMode = useSelector(state => getClientMode(state));
  const email = useSelector(state => getEmail(state));
  const currency = useSelector(state => getCurrency(state));

  /*
   * main_name represents the current showed/selected name
   * originalTag is the name of the wallet if we skip showing custom wallets to show as the name
   */
  const { name: main_name } = useSelector(state => getWalletName(state, wallet_id));
  const { name: originalTag } = useSelector(state => getWalletName(state, wallet_id, true));

  const dispatch = useDispatch();

  let clientModeConfigObject = {
    word_for_collection: "",
    cap_word_for_collection: "",
    plural_for_collection: "",
    cap_plural_for_collection: "",
    nav_logo: null,
    login_logo: null,
    login_top_logo: null,
    login_top_icon: null
  };
  if (clientMode in ClientModeConfig) {
    clientModeConfigObject = ClientModeConfig[clientMode];
  }

  /**
   * Handles modifying a tag
   * @param tag new tag to use
   * @param permission user's permission on the tag
   * @param id the custom tag id
   * @returns {Promise<void>}
   */
  const handleSetTag = async (tag, permission, id) => {
    // dispatch the set wallet tag action
    try {
      await dispatch(setWalletTag(wallet_id, tag, email, id, permission, enqueueSnackbar));
    } catch (err) {
      throw err;
    }
  };

  /**
   * Handle inserting a new tag
   * @returns {Promise<void>}
   */
  const handleNewTag = async () => {
    // dispatch the set wallet tag action with the id as null to moke a new one
    try {
      await dispatch(setWalletTag(wallet_id, newTag, email, null, 1, enqueueSnackbar));
      setNewTag(null);
    } catch (err) {
      throw err;
    }
  };

  /**
   * handles deleting a custom tag
   * @param id
   * @returns {Promise<void>}
   */
  const handleSetOriginalTag = async id => {
    try {
      await dispatch(setOriginalWalletTagFromPage(wallet_id, id));
    } catch (err) {
      throw err;
    }
  };

  const useStyles = makeStyles({
    newPosOfDialog: {
      // position: "absolute",
      top: "-25%",
      margin: "auto"
      // transform: "translate(-50%, -50%)"
    },
    dialogRoot: {
      zIndex: "10002 !important" // needed to show dialog over the navbar
    }
  });
  const classes = useStyles();

  return (
    <>
      <Button
        className="greenButton"
        onClick={() => setShowModal(true)}
        style={{ marginRight: "4px" }}
      >
        <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: "16px", marginRight: "7px" }} />
        Manage Custom Tag(s)
      </Button>
      <Dialog
        open={showModal}
        fullWidth
        maxWidth={"md"}
        onClose={() => setShowModal(false)}
        scroll="paper"
        classes={{
          paper: classes.newPosOfDialog,
          root: classes.dialogRoot
        }}
      >
        <DialogTitle closeButton style={{ borderBottom: "2px solid #aaa" }}>
          Custom Tags
        </DialogTitle>
        <DialogContent>
          <Table className="inviteTable" style={{ marginBottom: "50px" }}>
            <thead>
              <tr>
                <th style={{ width: "40%" }}>{clientModeConfigObject.cap_word_for_collection}</th>
                <th style={{ width: "30%" }}>Custom Tag</th>
                <th style={{ width: "30%" }} />
              </tr>
            </thead>
            <tbody>
              {customTag &&
                customTag.map(({ id, email, name, permission }) => (
                  <CustomTagRow
                    tag={name}
                    attributed_tag={originalTag}
                    email={email}
                    main_name={main_name}
                    id={id}
                    currency={currency}
                    entity_id={wallet_id}
                    permission={permission || 0}
                    handleSetOriginalTag={id => handleSetOriginalTag(id)}
                    handleSetTag={(tag, permission, id) => handleSetTag(tag, permission, id)}
                    tagType={"customTag"}
                  />
                ))}
            </tbody>
          </Table>
        </DialogContent>
        <DialogActions>
          {newTag === null ? (
            <Button onClick={() => setNewTag("")} className={"greenButton"}>
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: "3px" }} />
              Add New Custom Tag
            </Button>
          ) : (
            <>
              <Form
                autoComplete="off"
                onSubmit={e => {
                  e.preventDefault();
                  handleNewTag();
                }}
              >
                <FormGroup controlId="primaryTag" style={{ margin: "0" }}>
                  <FormControl
                    type="text"
                    maxLength={50} // Max custom length of 50
                    value={newTag}
                    style={{ display: "inline-block", verticalAlign: "middle" }}
                    onChange={e => setNewTag(e.target.value)}
                  />
                </FormGroup>
              </Form>
              <Button
                className="greenButton"
                style={{ marginLeft: "5px", fontSize: "12px" }}
                onClick={() => handleNewTag()}
                disabled={
                  newTag.length === 0 ||
                  newTag === originalTag ||
                  (customTag && customTag.find(({ name }) => name === newTag))
                }
              >
                <FontAwesomeIcon icon={faSave} style={{ marginRight: "3px" }} /> Save
              </Button>
              <Button
                className="redButton"
                style={{ marginLeft: "5px", fontSize: "12px" }}
                onClick={() => setNewTag(null)}
              >
                <FontAwesomeIcon icon={faWindowClose} style={{ marginRight: "3px" }} />
                Cancel
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
