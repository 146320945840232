/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { chooseBetweenConversions, numberWithCommas, satoshiToBitcoin } from "../../helpers";
import StatCard from "../StatCard";
import { getAddressRisk, getAddressSummary, getClusterHistory } from "../../selectors/address";
import { Link } from "react-router-dom";
import { FakeLinkSpan } from "../styled";
import CurrencyChooserConfig from "../CurrencyChooserConfig";
import ReportIssue from "../ReportIssue";
import MoreInfoAddressSummary from "./MoreInfoAddressSummary";
import AddToWatchlist from "../Home/Watchlist/AddToWatchlist";
import AddToCustomWallet from "../Wallet/CustomWallet/AddToCustomWallet";
import AddToNote from "../NoteEditor/AddToNote";
import CopyText from "../CopyText";
import BtcToUsdChooser from "../Utils/BtcToUsdChooser";
import ChangeSwitch from "../Utils/ChangeSwitch";
import { CoinSwap } from "../CoinSwap";
import { getWalletName } from "../../selectors/wallet";
import { RiskButton } from "../Risk/RiskButton";
import { createRiskFetch } from "../../actions/actionCreatorFactories";
import Col from "react-bootstrap/lib/Col";

/**
 * Panel that contains basic address statistics
 *
 * There are two main modes. There is the normal mode which functions as a normal
 * page with a links to other pages, and there is the Graph mode which is rendered
 * when certain callbacks are added. These callbacks are used to 'link' to modals
 * which house the Address, Wallet, and Transaction components.
 */
const AddressSummary = ({
  address,
  addToGraph,
  handleOpenWalletModal,
  currency,
  moreInfoEnabled,
  showChange,
  toggleChange,
  currentUsdPrice,
  showAsUsd,
  convertPriceToUsd,
  toggleUsdDisplay,
  historicalToUsd,
  toggleHistoricalUsd,
  clientMode,
  ip,
  risk,
  gambling_ok_risk,
  sanctions_risk,
  coinSwap,
  coinSwapData,
  customWalletEnabled = true
}) => {
  const { walletId, balance, primaryTag, ...summaryData } = useSelector(state =>
    getAddressSummary(state, address, true)
  );

  const { name, wisteria } = useSelector(state => walletId && getWalletName(state, walletId));
  const clusterHistory = useSelector(state => getClusterHistory(state, address, false));
  const riskData = useSelector(state => getAddressRisk(state, address));
  const dispatch = useDispatch();
  const inputCount = Number(summaryData.inputCount).toLocaleString();
  const outputCount = Number(summaryData.outputCount).toLocaleString();
  const inputCountSeparated = Number(summaryData.inputCountSeparated).toLocaleString();
  const outputCountSeparated = Number(summaryData.outputCountSeparated).toLocaleString();
  const transactionCount = Number(summaryData.transactionCount).toLocaleString();
  let balanceBitcoin = satoshiToBitcoin(balance);
  if (balanceBitcoin < 0.0) {
    balanceBitcoin = 0.0;
  }

  const showChangeWithDefault = showChange || summaryData.sent_hist_usd === -1;
  const outputBitcoin = satoshiToBitcoin(
    showChangeWithDefault ? summaryData.outputSatoshi : summaryData.recv_no_self
  );
  const inputBitcoin = satoshiToBitcoin(
    showChangeWithDefault ? summaryData.inputSatoshi : summaryData.sent_no_self
  );
  const inputHistUsd = showChangeWithDefault
    ? summaryData.sent_hist_usd
    : summaryData.sent_usd_no_self;
  const outputHistUsd = showChangeWithDefault
    ? summaryData.recv_hist_usd
    : summaryData.recv_usd_no_self;
  const showUsdText = showAsUsd || historicalToUsd;

  const firstTransaction = moment
    .unix(summaryData.firstTransaction)
    .utc()
    .format("LLL");
  const lastTransaction = moment
    .unix(summaryData.lastTransaction)
    .utc()
    .format("LLL");

  let currencyObject = CurrencyChooserConfig[currency];

  return (
    <div
      className="container"
      style={{
        borderWidth: "0 0 0 2px",
        borderColor: "var(--secondary-color)",
        borderStyle: "solid",
        paddingBottom: "0"
      }}
    >
      {" "}
      <div style={{ display: "flex", alignItems: "center" }}>
        <h4 className="entityHeader">Address</h4>
        <div style={{ marginLeft: "auto" }}>
          <AddToNote page="address" itemId={address} />
          {addToGraph()}
          {customWalletEnabled && <AddToCustomWallet entityId={address} />}
          <AddToWatchlist address={address} entityType="Address" />
          <ReportIssue entityName={address} entityType="address" currency={currency} />
        </div>
      </div>
      <div
        className="row"
        style={{
          marginBottom: "20px",
          display: "flex",
          flexDirection: "row",
          paddingLeft: "15px"
        }}
      >
        <div className="col addSummaryAddId" style={{ display: "flex", alignItems: "center" }}>
          <p
            className="addressInAddSummary"
            style={{ fontSize: address.length > 34 ? "20px" : "30px" }}
          >
            {address.split(":").pop()}
          </p>
          <CopyText text={address.split(":").pop()} marginLeft={"0"} fontSize={"18px"} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: "10px"
            }}
          >
            {handleOpenWalletModal ? (
              <FakeLinkSpan onClick={() => handleOpenWalletModal(walletId)}>
                <p
                  style={{
                    fontFamily: "Quicksand",
                    fontWeight: "600",
                    fontSize: "20px",
                    marginRight: "20px",
                    marginBottom: "0"
                  }}
                >
                  [{name || walletId}]
                </p>
              </FakeLinkSpan>
            ) : (
              <Link to={{ pathname: `/${currency}/wallet/${walletId}` }}>
                <p
                  style={{
                    fontFamily: "Quicksand",
                    fontWeight: "600",
                    fontSize: "20px",
                    marginRight: "20px",
                    marginBottom: "0"
                  }}
                >
                  [{name || walletId}]
                </p>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <p
            style={{
              color: "var(--secondary-color)",
              fontFamily: "Quicksand",
              fontWeight: "700",
              fontSize: "20px",
              display: "inline"
            }}
          >
            {" "}
            Summary{" "}
          </p>
          <ChangeSwitch
            showChange={showChangeWithDefault}
            toggleChange={toggleChange}
            noChange={summaryData.sent_hist_usd === -1}
          />
        </div>
        <Col sm={1} md={1} lg={1} style={{ display: "inline", paddingTop: "8px" }}>
          <RiskButton
            getRisk={async () => {
              return await dispatch(createRiskFetch("address", getAddressRisk)(address));
            }}
            risk={riskData && riskData.level}
          />
        </Col>
        <div className="pull-right" style={{ display: "inline", paddingTop: "8px" }}>
          <BtcToUsdChooser
            showAsUsd={showAsUsd}
            toggleUsdDisplay={toggleUsdDisplay}
            historicalToUsd={historicalToUsd}
            toggleHistoricalUsd={toggleHistoricalUsd}
            currencyAbbreviation={currencyObject["abb"]}
          />
        </div>
      </div>
      <div className="row" style={{ paddingBottom: "0" }}>
        <div className="col-md-4 col-lg-4 infoCol" style={{ paddingBottom: "0" }}>
          <div className="infoCards">
            <p
              style={{
                color: "var(--secondary-color)",
                fontFamily: "Quicksand",
                fontWeight: "700"
              }}
            >
              {" "}
              Volume{" "}
            </p>
            <StatCard
              stat={`Sent ${showUsdText ? "USD" : currencyObject.abb}`}
              value={chooseBetweenConversions(
                [inputBitcoin, !showAsUsd],
                [
                  "$" + numberWithCommas(convertPriceToUsd(inputBitcoin, currentUsdPrice)),
                  showAsUsd
                ],
                ["$" + numberWithCommas(inputHistUsd), historicalToUsd],
                true,
                summaryData.sent_hist_usd === -1
              )}
            />
            <StatCard
              stat={`Recv ${showUsdText ? "USD" : currencyObject.abb}`}
              value={chooseBetweenConversions(
                [outputBitcoin, !showAsUsd],
                [
                  "$" + numberWithCommas(convertPriceToUsd(outputBitcoin, currentUsdPrice)),
                  showAsUsd
                ],
                ["$" + numberWithCommas(outputHistUsd), historicalToUsd],
                true,
                summaryData.sent_hist_usd === -1
              )}
            />
            <StatCard
              stat="Balance"
              value={
                showUsdText
                  ? `$${numberWithCommas(convertPriceToUsd(balanceBitcoin, currentUsdPrice))}`
                  : balanceBitcoin
              }
            />
          </div>
        </div>
        <div className="col-md-4 col-lg-4 infoCol">
          <div className="infoCards">
            <p
              style={{
                color: "var(--secondary-color)",
                fontFamily: "Quicksand",
                fontWeight: "700"
              }}
            >
              {" "}
              Activity{" "}
            </p>
            <StatCard stat="First Trx" value={firstTransaction} />
            <StatCard stat="Latest Trx" value={lastTransaction} />
            <StatCard stat="Inputs" value={inputCountSeparated} />
            <StatCard stat="Outputs" value={outputCountSeparated} />
          </div>
        </div>
        <div className="col-md-4 col-lg-4 infoCol">
          <div className="infoCards">
            <p
              style={{
                color: "var(--secondary-color)",
                fontFamily: "Quicksand",
                fontWeight: "700"
              }}
            >
              {" "}
              Transactions{" "}
            </p>
            <StatCard stat="Sent Trxs" value={inputCount} />
            <StatCard stat="Recv Trxs" value={outputCount} />
            <StatCard stat="Total Trxs" value={transactionCount} />
          </div>
        </div>
        {clusterHistory && clusterHistory.wallet && (
          <div className="col-md-12 col-lg-12 infoCol">
            <div className="infoCards">
              <p
                style={{
                  color: "var(--secondary-color)",
                  fontFamily: "Quicksand",
                  fontWeight: "700"
                }}
              >
                Cluster Reason
              </p>
              <StatCard stat="Merged With" value={clusterHistory.name || clusterHistory.wallet} />
              <StatCard stat="Cluster Reason" value={clusterHistory.reason} />
              {clusterHistory.transaction && (
                <StatCard stat="Transaction" value={clusterHistory.transaction} />
              )}
              <StatCard stat="Description" value={clusterHistory.description} />
            </div>
          </div>
        )}
      </div>
      {currency === "bitcoin" && clientMode !== "vci" && moreInfoEnabled && (
        <div className="row" style={{ paddingBottom: "0" }}>
          <MoreInfoAddressSummary
            address={address}
            currency={currency}
            confidence_score={summaryData.confidence_score}
            attribution_source={summaryData.attribution_source}
            ip={ip}
            risk={risk}
            gambling_ok_risk={gambling_ok_risk}
            sanctions_risk={sanctions_risk}
          />
        </div>
      )}
      <div className="row" style={{ marginTop: "20px" }}>
        {coinSwap && (
          <div className="col-sm-12 col-md-12 col-lg-12 infoCol">
            <CoinSwap coinSwapData={coinSwapData} />
          </div>
        )}
      </div>
    </div>
  );
};

AddressSummary.propTypes = {
  transactionCount: PropTypes.number.isRequired,
  walletId: PropTypes.string.isRequired,
  // handles modal transitions in graph mode
  getWalletLink: PropTypes.func.isRequired,
  addToGraph: PropTypes.func.isRequired,
  address: PropTypes.string,
  moreInfoEnabled: PropTypes.bool.isRequired,
  showAsUsd: PropTypes.bool.isRequired
};

const mapStateToProps = (state, { address }) => getAddressSummary(state, address, true);

export default connect(
  mapStateToProps,
  null
)(AddressSummary);
