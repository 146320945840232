// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

/* eslint-disable import/prefer-default-export */

export const retrieveMutualTransactionInfo = state => {
  // Retrieve the current two wallets in focus
  const first = state.getIn(["wallet", "CurrentMutualWallets", "primary"]);
  const second = state.getIn(["wallet", "CurrentMutualWallets", "secondary"]);

  // Convert from immutable js List to plain js array for use in parsing by component
  let final = state.getIn(["wallet", first, "mutual", second, "transactions"]);
  if (final !== undefined) {
    final = final.toJS();
  }
  return final;
};

export const retrieveKeys = state => {
  // Retrieve the current two wallets in focus
  const first = state.getIn(["wallet", "CurrentMutualWallets", "primary"]);
  const second = state.getIn(["wallet", "CurrentMutualWallets", "secondary"]);
  const y = state.getIn(["wallet", first, "mutual", second, "keys"]);
  if (y === undefined) {
    return ["initial", "initial"];
  }
  return y.toJS();
};
