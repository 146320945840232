// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import axios, {
  ADDRESS_API,
  CUSTOM_WALLETS_API,
  GRAPH_API,
  TRANSACTION_API,
  WALLET_API
} from "../api";
import {
  getReceivedFiltered,
  getReceivedWallets,
  getSentFiltered,
  getSentWallets,
  getWalletAddresses,
  getWalletAssociations,
  getWalletMentions,
  getWalletMutualTransactions,
  getWalletMutualWallets,
  getWalletSummary,
  getWalletTransactions
} from "../selectors/wallet";
import {
  createFetchMentions,
  createFetchSummary,
  createFetchWalletAddresses,
  createWalletFetchMutualWalletTransactions
} from "./actionCreatorFactories";
import {
  FETCHING_WALLET_ADDRESSES,
  FETCHING_WALLET_ASSOCIATIONS,
  FETCHING_WALLET_MENTIONS,
  FETCHING_WALLET_MUTUAL_WALLETS,
  FETCHING_WALLET_PEEL_CHAIN,
  FETCHING_WALLET_SUMMARY,
  FETCHING_WALLET_TRANSACTIONS,
  FETCHING_WALLET_TRANSACTIONS_BY_SATOSHI,
  FETCHING_WALLET_WALLET_MUTUAL_TRANSACTIONS,
  GRAPH_UNDO_STACK_ADD,
  WALLET_ADDRESSES_FETCH_FAILURE,
  WALLET_ADDRESSES_FETCH_SUCCESS,
  WALLET_ADDRESSES_SCROLL_TOP,
  WALLET_ASSOCIATIONS_FETCH_FAILURE,
  WALLET_ASSOCIATIONS_FETCH_SUCCESS,
  WALLET_FETCHING_RECEIVED_WALLETS,
  WALLET_FETCHING_SENT_WALLETS,
  WALLET_MENTIONS_FETCH_FAILURE,
  WALLET_MENTIONS_FETCH_SUCCESS,
  WALLET_MENTIONS_PURGE,
  WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS,
  WALLET_MUTUAL_TRANSACTIONS_SWAP_ORDER,
  WALLET_MUTUAL_WALLETS_FETCH_FAILURE,
  WALLET_MUTUAL_WALLETS_FETCH_SUCCESS,
  WALLET_MUTUAL_WALLETS_TRANSACTIONS_SET_DATE_RANGE,
  WALLET_MUTUAL_WALLETS_TRANSACTIONS_SET_LARGEST_FIRST,
  WALLET_PEEL_CHAIN_FETCH_SUCCESS,
  WALLET_RECEIVED_WALLETS_FETCH_SUCCESS,
  WALLET_SENT_WALLETS_FETCH_SUCCESS,
  WALLET_SET_CUSTOM_TAG,
  WALLET_SUMMARY_FETCH_FAILURE,
  WALLET_SUMMARY_FETCH_SUCCESS,
  WALLET_TOGGLE_RECEIVED_FILTER,
  WALLET_TOGGLE_SENT_FILTER,
  WALLET_TRANSACTIONS_BY_SATOSHI_FETCH_FAILURE,
  WALLET_TRANSACTIONS_BY_SATOSHI_FETCH_SUCCESS,
  WALLET_TRANSACTIONS_FETCH_FAILURE,
  WALLET_TRANSACTIONS_FETCH_SUCCESS,
  WALLET_TRANSACTIONS_SCROLL_TOP,
  WALLET_TRANSACTIONS_SET_DATE_RANGE,
  WALLET_TRANSACTIONS_SWAP_ORDER,
  WALLET_UNCLUSTERED_FETCH_SUCCESS,
  WALLET_WALLET_MUTUAL_TRANSACTIONS_FETCH_FAILURE,
  WALLET_WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS,
  WALLET_TRANSACTIONS_PURGE,
  WALLET_SUMMARY_PURGE,
  WALLET_TRANSACTIONS_SET_ORDER,
  MORE_WALLET_SUMMARY_FETCH_FAILURE,
  MORE_WALLET_SUMMARY_FETCH_SUCCESS,
  FETCHING_MORE_WALLET_SUMMARY,
  WALLET_REMOVE_CUSTOM_TAG,
  SET_WALLET_MAIN_NAME,
  FETCHING_CUSTOM_WALLET_MENTIONS,
  CUSTOM_WALLET_MENTIONS_FETCH_SUCCESS,
  CUSTOM_WALLET_MENTIONS_FETCH_FAILURE,
  WALLET_SET_NAMES,
  WALLET_RISK_SCORE
} from "./actionNames";
import { MutualTransactions } from "../reducers/wallet";
import { getCurrency } from "../selectors/currency";
import { getCurrentGraph } from "../selectors/graph";
import { saveGraph } from "./graph";

export const fetchInitialWalletData = (walletId, graphId = 0) => async (dispatch, getState) => {
  const name = getCurrency(getState());

  const state = getState().getIn([name, "wallet", walletId.toString(), "transactions"]);
  if (state != null && state.get("transactionMap").size !== 0) {
    return;
  }

  dispatch({ type: FETCHING_WALLET_SUMMARY });

  const { data } =
    walletId > 0
      ? await axios.get(`${WALLET_API(name)}/${walletId}/all`)
      : await axios.get(
          `${CUSTOM_WALLETS_API(name)}/${walletId}/all${graphId > 0 ? `/${graphId}` : ""}`
        );
  dispatch({
    type: WALLET_SUMMARY_FETCH_SUCCESS,
    item: walletId,
    data: data.walletStats,
    name
  });
  // dispatch({
  //   type: WALLET_TRANSACTIONS_FETCH_SUCCESS,
  //   item: walletId,
  //   data: data.walletTransactions,
  //   order: 1,
  //   name,
  // });
  dispatch({
    type: WALLET_SENT_WALLETS_FETCH_SUCCESS,
    walletId,
    data: data.sent,
    name
  });
  dispatch({
    type: WALLET_RECEIVED_WALLETS_FETCH_SUCCESS,
    walletId,
    data: data.received,
    name
  });
  dispatch({
    type: WALLET_ADDRESSES_FETCH_SUCCESS,
    walletId,
    data: data.walletAddresses,
    name
  });
  dispatch({
    type: WALLET_MENTIONS_FETCH_SUCCESS,
    item: walletId,
    data: data.walletMentions,
    name
  });
  dispatch({
    type: WALLET_UNCLUSTERED_FETCH_SUCCESS,
    walletId,
    data: data.unclustered_amounts,
    name
  });

  dispatch({ type: FETCHING_WALLET_PEEL_CHAIN });
  const { data: data2 } =
    walletId > 0
      ? await axios.get(`${GRAPH_API(name)}/wallet/${walletId}/peel-chain`)
      : { data: { peel: false, peel_chain_data: [] } };
  dispatch({
    type: WALLET_PEEL_CHAIN_FETCH_SUCCESS,
    walletId,
    data: data2,
    name
  });
};

export const fetchSentWallets = (walletId, initial = false) => async (dispatch, getState) => {
  const state = getState();
  const sentWallets = getSentWallets(state, walletId.toString());
  const filtered = getSentFiltered(state, walletId.toString());
  let primaryKey = 0;
  let secondaryKey = 0;
  if (sentWallets) {
    if (initial) {
      if (sentWallets.get("nextPrimaryKey") !== 0) {
        return;
      }
    }
    primaryKey = sentWallets.get("nextPrimaryKey");
    secondaryKey = sentWallets.get("nextSecondaryKey");
    if (primaryKey === null) {
      return;
    }
  }

  dispatch({ type: WALLET_FETCHING_SENT_WALLETS });

  const name = getCurrency(getState());

  let api = `${GRAPH_API(name)}/wallet/${walletId}/sent`;
  if (filtered) {
    api = `${api}-filtered`;
  }
  api = primaryKey == null || primaryKey === 0 ? api : `${api}/${primaryKey}/${secondaryKey}`;
  try {
    const { data } = await axios.get(api);
    dispatch({
      type: WALLET_SENT_WALLETS_FETCH_SUCCESS,
      walletId: walletId.toString(),
      data,
      name
    });
  } catch (err) {
    // FIXME: Notify parent component that wallet doesn't exist anymore
    throw err;
  }
};

export const fetchReceivedWallets = (walletId, initial = false) => async (dispatch, getState) => {
  const state = getState();
  const receivedWallets = getReceivedWallets(state, walletId.toString());
  const filtered = getReceivedFiltered(state, walletId.toString());
  let primaryKey = 0;
  let secondaryKey = 0;
  if (receivedWallets) {
    if (initial) {
      if (receivedWallets.get("nextPrimaryKey") !== 0) {
        return;
      }
    }
    primaryKey = receivedWallets.get("nextPrimaryKey");
    secondaryKey = receivedWallets.get("nextSecondaryKey");
    if (primaryKey === null) {
      return;
    }
  }

  dispatch({ type: WALLET_FETCHING_RECEIVED_WALLETS });

  const name = getCurrency(state);
  let api = `${GRAPH_API(name)}/wallet/${walletId}/received`;
  if (filtered) {
    api = `${api}-filtered`;
  }
  api = primaryKey == null || primaryKey === 0 ? api : `${api}/${primaryKey}/${secondaryKey}`;
  try {
    const { data } = await axios.get(api);
    dispatch({
      type: WALLET_RECEIVED_WALLETS_FETCH_SUCCESS,
      walletId: walletId.toString(),
      data,
      name
    });
  } catch (err) {
    // FIXME: Notify parent component that wallet doesn't exist anymore
    throw err;
  }
};

export const toggleReceivedFilter = walletId => async (dispatch, getState) => {
  const name = getCurrency(getState());
  dispatch({
    type: WALLET_TOGGLE_RECEIVED_FILTER,
    walletId: walletId.toString(),
    name
  });

  dispatch(fetchReceivedWallets(walletId.toString(), true));
};

export const toggleSentFilter = walletId => async (dispatch, getState) => {
  const name = getCurrency(getState());
  dispatch({
    type: WALLET_TOGGLE_SENT_FILTER,
    walletId: walletId.toString(),
    name
  });

  dispatch(fetchSentWallets(walletId.toString(), true));
};

export const fetchWalletSummary = createFetchSummary(WALLET_API, getWalletSummary, {
  fetchAction: FETCHING_WALLET_SUMMARY,
  successAction: WALLET_SUMMARY_FETCH_SUCCESS,
  failureAction: WALLET_SUMMARY_FETCH_FAILURE
});

/**
 * Fetch extra wallet stats for change and historical data and dispatches for fetching, success, and failure
 * @param item walletId to fetch for
 * @returns {(function(*, *): (Promise<void>))|*}
 */
export const fetchMoreWalletSummary = (item, graphId = 0) => (dispatch, getState) => {
  const api = item < 0 ? CUSTOM_WALLETS_API : WALLET_API;

  const summary = getWalletSummary(getState(), item);
  if (!summary) {
    return;
  }
  // Get the current currency
  const name = getCurrency(getState());

  dispatch({ type: FETCHING_MORE_WALLET_SUMMARY });
  let api_call = `${api(name)}/${item}/summary-stats`;
  if (item < 0) {
    api_call = graphId > 0 ? `${api_call}/${graphId}` : api_call;
  }
  return axios.get(api_call).then(
    ({ data }) => {
      dispatch({
        type: MORE_WALLET_SUMMARY_FETCH_SUCCESS,
        item,
        data,
        name
      });
    },
    error => {
      dispatch({
        type: MORE_WALLET_SUMMARY_FETCH_FAILURE,
        item,
        name
      });
    }
  );
};

/**
 * Removes summary data from redux, so that we can reload the summary from scratch
 * Used for custom clusters if the addresses change.
 * @param walletId wallet_id to remove transactions
 * @returns {(function(*, *): Promise<void>)|*}
 */
export const purgeWalletSummary = walletId => async (dispatch, getState) => {
  const name = getCurrency(getState());

  dispatch({
    type: WALLET_SUMMARY_PURGE,
    walletId: walletId.toString(),
    name
  });
};

/**
 * Fetch wallet transactions within the date and dispatches for fetching, success, and failure
 * @param api API base for making requests to
 * @param item walletId to fetch for
 * @param order how to order it
 * @param startDate start date for transactions
 * @param endDate end date for transactions
 * @param initial Whether this is the first time fetching transactions
 * @returns {(function(*, *): (Promise<void>))|*}
 */
export const fetchWalletTransactions = (
  api,
  item,
  order,
  startDate,
  endDate,
  initial = false,
  graphId = 0
) => (dispatch, getState) => {
  // TODO modify to check wallet id instead of take api as a param.
  if (item < 0) {
    api = CUSTOM_WALLETS_API;
  }

  const transactions = getWalletTransactions(getState(), item);
  const keyName = order > 0 ? "newestFirstNextKey" : "oldestFirstNextKey";
  // Setting key null interferes with URL building, instead use -1 as default
  // for when address is not currently in store and will be created in the reducer.
  let key = -1;
  if (transactions) {
    if (initial) {
      if (transactions.get(keyName) !== 0) {
        // Initial fetches are done only when address is loaded for the first time
        // To load more transactions, initial should be set to false
        return Promise.resolve();
      }
    }
    key = transactions.get(keyName);
    if (key === null) {
      // There are no more transactions to fetch
      return Promise.resolve();
    }
  }

  // Get the current currency
  const name = getCurrency(getState());

  dispatch({ type: FETCHING_WALLET_TRANSACTIONS });
  let API = `${api(name)}/${item}/transactions`;
  API = order > 0 ? `${API}/newest` : `${API}/oldest`;
  API = key === -1 || key === 0 ? API : `${API}/${key}`;
  if (item < 0) {
    API = graphId > 0 ? `${API}/${graphId}` : API;
  }
  return axios
    .get(API, {
      params: {
        startDate,
        endDate
      }
    })
    .then(
      ({ data }) => {
        dispatch({
          type: WALLET_TRANSACTIONS_FETCH_SUCCESS,
          item,
          order,
          data,
          name
        });
      },
      error => {
        dispatch({
          type: WALLET_TRANSACTIONS_FETCH_FAILURE,
          item,
          order,
          startDate,
          endDate,
          initial,
          name
        });
      }
    );
};

/**
 * Removes transaction data from redux, so that we can reload the transactions from scratch
 * Used for custom clusters if the addresses change.
 * @param walletId wallet_id to remove transactions
 * @returns {(function(*, *): Promise<void>)|*}
 */
export const purgeWalletTransactions = walletId => async (dispatch, getState) => {
  const name = getCurrency(getState());

  dispatch({
    type: WALLET_TRANSACTIONS_PURGE,
    walletId: walletId.toString(),
    name
  });
};

/**
 * Returns dispatch function to get transactions ordered by satoshi.
 *
 * Dispatchs actions for fetching, success, and failure
 * @param api api base to use for api call
 * @param item walletId getting transactions for
 * @param startDate start date for search space
 * @param endDate end date for search space
 * @param initial Whether this is the first time this wallet is fetched
 * @returns {(function(*, *): (Promise<void>))|*}
 */
export const fetchWalletTransactionsBySatoshi = (
  api,
  item,
  startDate = null,
  endDate = null,
  initial = false,
  graphId = 0
) => {
  return function(dispatch, getState) {
    // Making dispatch function
    const transactions = getWalletTransactions(getState(), item);
    const keyName = "largestFirstNextKey";
    // Setting key null interferes with URL building, instead use -1 as default
    // for when address is not currently in store and will be created in the reducer.
    let key = -1;
    if (item < 0) {
      api = CUSTOM_WALLETS_API;
    }
    if (transactions) {
      if (initial) {
        if (transactions.get(keyName).get("primary") !== 0) {
          // Initial fetches are done only when wallet is loaded for the first time
          // To load more transactions, initial should be set to false
          return Promise.resolve();
        }
      }
      key = transactions.get(keyName);
      if (key === null) {
        // There are no more transactions to fetch
        return Promise.resolve();
      }
    }
    // Get the current currency
    const name = getCurrency(getState());

    dispatch({ type: FETCHING_WALLET_TRANSACTIONS_BY_SATOSHI });
    let API = `${api(name)}/${item}/transactions/largest`;
    API = key.primary === -1 || key.primary === 0 ? API : `${API}/${key.primary}/${key.secondary}`;
    if (item < 0) {
      API = graphId > 0 ? `${API}/${graphId}` : API;
    }
    return axios
      .get(API, {
        params: {
          startDate,
          endDate
        }
      })
      .then(
        ({ data }) => {
          dispatch({
            type: WALLET_TRANSACTIONS_BY_SATOSHI_FETCH_SUCCESS,
            item,
            data,
            name
          });
        },
        () => {
          dispatch({
            type: WALLET_TRANSACTIONS_BY_SATOSHI_FETCH_FAILURE,
            item,
            initial,
            name
          });
        }
      );
  };
};

export const walletTransactionsSetOrder = (walletId, order) => async (dispatch, getState) => {
  const name = getCurrency(getState());
  return dispatch({
    type: WALLET_TRANSACTIONS_SET_ORDER,
    walletId,
    order: order,
    name
  });
};

export const walletTransactionsSetDateRange = (
  api,
  walletId,
  startDate,
  endDate,
  graphId = 0
) => async (dispatch, getState) => {
  const name = getCurrency(getState());
  dispatch({
    type: WALLET_TRANSACTIONS_SET_DATE_RANGE,
    walletId: walletId.toString(),
    startDate,
    endDate,
    name
  });

  // get the state of the rest of the objects
  const order = getWalletTransactions(getState(), walletId.toString()).get("order");
  if (order === 2) {
    return dispatch(
      fetchWalletTransactionsBySatoshi(api, walletId.toString(), startDate, endDate, false, graphId)
    );
  }
  return dispatch(
    fetchWalletTransactions(api, walletId.toString(), order, startDate, endDate, false, graphId)
  );
};

export const walletTransactionsSetLargestFirst = (
  api,
  walletId,
  startDate = null,
  endDate = null,
  initial = false,
  graphId = 0
) => async (dispatch, getState) => {
  const name = getCurrency(getState());
  await dispatch({
    type: WALLET_TRANSACTIONS_SET_ORDER,
    walletId,
    order: 2,
    name
  });

  return dispatch(
    fetchWalletTransactionsBySatoshi(api, walletId, startDate, endDate, initial, graphId)
  );
};

// export const fetchWalletMentions = (api, item, initial = false) => {
//   return function(dispatch, getState) {
//     const tags = getWalletMentions(getState(), item);
//     // let seenTags;
//     let key = -1;
//     if (tags) {
//       // seenTags = tags.get('seenTags');
//       if (initial) {
//         if (tags.nextKey !== 0) {
//           return Promise.resolve();
//         }
//       }
//       key = tags.nextKey;
//       if (key === null) {
//         return Promise.resolve();
//       }
//     }
//     // Get the current currency
//     const name = getCurrency(getState());
//
//     dispatch({ type: FETCHING_WALLET_MENTIONS });
//     let API = `${api(name)}/${item}/mentions`;
//     API = key == null || key === -1 || key === 0 ? API : `${API}/${key}`;
//     return axios
//       .get(API)
//       .then(({ data }) => {
//         dispatch({
//           type: WALLET_MENTIONS_FETCH_SUCCESS,
//           item,
//           data,
//           name
//         });
//       })
//       .catch(err => {
//         dispatch({
//           type: WALLET_MENTIONS_FETCH_FAILURE,
//           item,
//           initial,
//           name
//         });
//         throw err;
//       });
//   };
// };

/**
 * Fetches mentions for wallet and dispatch action
 * cooresponding to if the api fetch fails or not.
 * @type {(function(*=, *=): function(*, *): Promise<void>)|*}
 */
export const fetchWalletMentions = createFetchMentions(
  WALLET_API,
  (state, walletId) => getWalletMentions(state, walletId.toString()),
  {
    fetchAction: FETCHING_WALLET_MENTIONS,
    successAction: WALLET_MENTIONS_FETCH_SUCCESS,
    failureAction: WALLET_MENTIONS_FETCH_FAILURE
  }
);

/**
 * Removes mentions data from redux, so that we can reload the mentions from scratch
 * Used for custom clusters if the addresses change.
 * @param walletId wallet_id to remove mentions
 * @returns {(function(*, *): Promise<void>)|*}
 */
export const purgeWalletMentions = walletId => async (dispatch, getState) => {
  const name = getCurrency(getState());

  dispatch({
    type: WALLET_MENTIONS_PURGE,
    walletId: walletId.toString(),
    name
  });
};

export const fetchWalletAddresses = createFetchWalletAddresses(WALLET_API, getWalletAddresses, {
  fetchAction: FETCHING_WALLET_ADDRESSES,
  successAction: WALLET_ADDRESSES_FETCH_SUCCESS,
  failureAction: WALLET_ADDRESSES_FETCH_FAILURE
});

export const fetchWalletAssociations = (walletId, initial = false) => async (
  dispatch,
  getState
) => {
  const name = getCurrency(getState());
  const walletAssociations = getWalletAssociations(getState(), walletId.toString());
  let key = 1;
  if (walletAssociations) {
    if (initial) {
      if (walletAssociations.get("nextKey") !== 1) {
        return;
      }
    }
    key = walletAssociations.get("nextKey");
    if (key === null) {
      return;
    }
  }
  dispatch({ type: FETCHING_WALLET_ASSOCIATIONS });
  const api = `${WALLET_API(name)}/${walletId}/associations/${key}`;
  try {
    const { data } = await axios.get(api);
    dispatch({
      type: WALLET_ASSOCIATIONS_FETCH_SUCCESS,
      wallet: walletId.toString(),
      data,
      name
    });
  } catch (err) {
    dispatch({
      type: WALLET_ASSOCIATIONS_FETCH_FAILURE,
      wallet: walletId.toString(),
      initial,
      name
    });
    throw err;
  }
};

// Wallet is the cytoscape node. This is needed to set the displayed value if the
// request to the server does not fail.
export const setGraphWalletTag = (cytoscapeWallet, oldTag, newTag, cy = null) => async (
  dispatch,
  getState
) => {
  await setWalletTagInner(cytoscapeWallet, newTag)(dispatch, getState);
  await dispatch(addSetWalletTagToUndoStack(cytoscapeWallet, oldTag, newTag, "change", cy));
};

// This should **not** modify the undo stack. The undo stack is handled by a separate
// action show we can reuse this easily.
export const setWalletTagInner = (cytoscapeWallet, tag) => async (dispatch, getState) => {
  // this sets the new primary key in the redux store if changing the tag on the
  // server is successful
  const walletId = cytoscapeWallet.id();
  const name = getCurrency(getState());

  if (tag === walletId) {
    // Kind of a hack. This will not work if user wants to lock a particular wallet
    // id as a tag.
    cytoscapeWallet.data("label", tag);
    return;
  }

  cytoscapeWallet.data("label", tag);
};

export const addSetWalletTagToUndoStack = (cytoscapeWallet, oldTag, newTag, opType, cy) => async (
  dispatch,
  getState
) => {
  const wallets = [
    {
      walletId: cytoscapeWallet.id(),
      tagOperation: {
        opType,
        oldTag,
        newTag
      }
    }
  ];

  const name = getCurrency(getState());
  dispatch({
    type: GRAPH_UNDO_STACK_ADD,
    opType: "setTag",
    wallets,
    name
  });

  // If this was changed from cy, save it
  if (cy) {
    const graphId = getCurrentGraph(getState());
    await dispatch(saveGraph(graphId, cy));
  }
};

// Wallet is also a cytoscape node here
export const setOriginalWalletTag = (cytoscapeWallet, currentTag, originalTag, cy = null) => async (
  dispatch,
  getState
) => {
  await setOriginalWalletTagInner(cytoscapeWallet, originalTag)(dispatch, getState);
  dispatch(addSetWalletTagToUndoStack(cytoscapeWallet, currentTag, originalTag, "delete", cy));
};

export const setOriginalWalletTagInner = (cytoscapeWallet, originalTag) => async (
  dispatch,
  getState
) => {
  cytoscapeWallet.data("label", originalTag);
};

/**
 * Calls api to delete custom tag for entity with custom tag id
 * @param entity_id
 * @param id custom tag id
 * @returns {(function(*, *): Promise<void>)|*}
 */
export const setOriginalWalletTagFromPage = (entity_id, id) => async (dispatch, getState) => {
  const name = getCurrency(getState());
  try {
    await axios.delete(`${WALLET_API(name)}/${entity_id}/primary-tag`, {
      data: {
        id
      }
    });
  } catch (err) {
    throw err;
  }

  //dispatch action to remove custom tag from redux
  dispatch({
    type: WALLET_REMOVE_CUSTOM_TAG,
    walletId: entity_id.toString(),
    id,
    name
  });
};

/**
 * Calls api to set the mainName for a wallet and change main name in the redux store
 * @param mainName
 * @param data_type
 * @param entity_id
 * @param walletId
 * @returns {(function(*, *): Promise<void>)|*}
 */
export const setMainName = (mainName, data_type, entity_id, walletId) => async (
  dispatch,
  getState
) => {
  const name = getCurrency(getState());

  // calling api to set the main name
  try {
    await axios.put(`${WALLET_API(name)}/${walletId}/main-name`, {
      data_type: data_type,
      entity_id: entity_id
    });
    dispatch({
      // changing redux store
      type: SET_WALLET_MAIN_NAME,
      mainName,
      walletId,
      name
    });
  } catch (err) {
    throw err;
  }
};

const fetchMutualTransactionsCompoundKey = async (
  inputWalletId,
  outputWalletId,
  order,
  initial,
  dispatch,
  getState
) => {
  const state = getState();
  const name = getCurrency(getState());
  const mutualTransactions =
    state.getIn([name, "wallet", inputWalletId, "mutualTransactions", outputWalletId]) ||
    new MutualTransactions();
  const compoundKey = mutualTransactions.get(`${order}NextKey`);
  const nextPrimaryKey = compoundKey.get("primary");
  const nextSecondaryKey = compoundKey.get("secondary");

  // Handle special cases where there are already mutual transactions fetched
  if (mutualTransactions.get(order).size !== 0) {
    if (initial) {
      if (nextPrimaryKey !== 0 && nextSecondaryKey !== 0) {
        // Initial items have already been fetched.
        return;
      }
    } else if (nextPrimaryKey == null && nextSecondaryKey == null) {
      // There are no more mutual transactions to fetch
      return;
    }
  }

  // We want "largest" from "largestFirst"
  const orderUrl = order.substring(0, order.indexOf("F"));

  const API =
    nextPrimaryKey === 0 && nextSecondaryKey === 0
      ? `${TRANSACTION_API(name)}/pair/${orderUrl}/${inputWalletId}/${outputWalletId}`
      : `${TRANSACTION_API(
          name
        )}/pair/${orderUrl}/${inputWalletId}/${outputWalletId}/${nextPrimaryKey}/${nextSecondaryKey}`;

  try {
    const { data } = await axios.get(API);
    dispatch({
      type: WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS,
      inputWalletId,
      outputWalletId,
      order,
      data,
      name
    });
  } catch (err) {
    throw err;
  }
};

const fetchMutualTransactionsSingleKey = async (
  inputWalletId,
  outputWalletId,
  order,
  initial,
  dispatch,
  getState
) => {
  const state = getState();
  const name = getCurrency(getState());
  const mutualTransactions =
    state.getIn([name, "wallet", inputWalletId, "mutualTransactions", outputWalletId]) ||
    new MutualTransactions();
  const key = mutualTransactions.get(`${order}NextKey`);

  // Handle special cases where there are already mutual transactions fetched
  if (mutualTransactions.get(order).size !== 0) {
    if (initial) {
      if (key !== 0) {
        // Initial items have already been fetched.
        return;
      }
    } else if (key == null) {
      // There are no more mutual transactions to fetch
      return;
    }
  }

  // We want "newest" from "newestFirst"
  const orderUrl = order.substring(0, order.indexOf("F"));

  const API =
    key === 0
      ? `${TRANSACTION_API(name)}/pair/${orderUrl}/${inputWalletId}/${outputWalletId}`
      : `${TRANSACTION_API(name)}/pair/${orderUrl}/${inputWalletId}/${outputWalletId}/${key}`;

  try {
    const { data } = await axios.get(API);
    dispatch({
      type: WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS,
      inputWalletId,
      outputWalletId,
      order,
      data,
      name
    });
  } catch (err) {
    throw err;
  }
};

export const fetchMutualTransactions = (inputWalletId, outputWalletId, initial = false) => async (
  dispatch,
  getState
) => {
  inputWalletId = inputWalletId.toString();
  outputWalletId = outputWalletId.toString();
  const name = getCurrency(getState());
  const order = getState().getIn([name, "graph", "view", "mutualTransactionsOrder"]);

  if (order === "largestFirst" || order === "smallestFirst") {
    await fetchMutualTransactionsCompoundKey(
      inputWalletId,
      outputWalletId,
      order,
      initial,
      dispatch,
      getState
    );
    return;
  }
  if (order === "newestFirst" || order === "oldestFirst") {
    await fetchMutualTransactionsSingleKey(
      inputWalletId,
      outputWalletId,
      order,
      initial,
      dispatch,
      getState
    );
    return;
  }

  throw new Error("Invalid order.");
};

export const setTransactionsScrollTop = (walletId, scrollTop) => (dispatch, getState) => {
  const name = getCurrency(getState());
  dispatch({
    type: WALLET_TRANSACTIONS_SCROLL_TOP,
    walletId,
    scrollTop,
    name
  });
};

export const setAddressesScrollTop = (walletId, scrollTop) => (dispatch, getState) => {
  const name = getCurrency(getState());
  dispatch({
    type: WALLET_ADDRESSES_SCROLL_TOP,
    walletId,
    scrollTop,
    name
  });
};

export const fetchWalletMutualWallets = (api, item, graphId = 0) => async (dispatch, getState) => {
  const mutualWallets = getWalletMutualWallets(getState(), item);

  // Get the current currency
  const name = getCurrency(getState());

  // If the address record exists, but the mutual wallets haven't been fetched, fetch them.
  if (!mutualWallets || !mutualWallets.walletsFetched) {
    dispatch({ type: FETCHING_WALLET_MUTUAL_WALLETS });
    if (item < 0) {
      api = CUSTOM_WALLETS_API;
    }
    const API = `${api(name)}/${item}/tagged-transactions-summary${
      graphId > 0 ? `/${graphId}` : ""
    }`;
    try {
      const { data } = await axios.get(API);
      dispatch({
        type: WALLET_MUTUAL_WALLETS_FETCH_SUCCESS,
        item,
        data,
        name
      });
    } catch (err) {
      dispatch({
        type: WALLET_MUTUAL_WALLETS_FETCH_FAILURE,
        item,
        name
      });
      throw err;
    }
  }
};

export const fetchWalletMutualWalletTransactions = createWalletFetchMutualWalletTransactions(
  TRANSACTION_API,
  getWalletMutualTransactions,
  {
    fetchAction: FETCHING_WALLET_WALLET_MUTUAL_TRANSACTIONS,
    successAction: WALLET_WALLET_MUTUAL_TRANSACTIONS_FETCH_SUCCESS,
    failureAction: WALLET_WALLET_MUTUAL_TRANSACTIONS_FETCH_FAILURE
  }
);

export const walletMutualTransactionsSwapOrder = (address, walletId) => (dispatch, getState) => {
  const name = getCurrency(getState());
  dispatch({
    type: WALLET_MUTUAL_TRANSACTIONS_SWAP_ORDER,
    address,
    walletId,
    name
  });
};

export const walletMutualTransactionsSetLargestFirst = (
  address,
  walletId,
  tag,
  order,
  startDate,
  endDate,
  initial
) => async (dispatch, getState) => {
  const name = getCurrency(getState());
  // This is just used to clear everything
  dispatch({
    type: WALLET_MUTUAL_WALLETS_TRANSACTIONS_SET_LARGEST_FIRST,
    address,
    walletId,
    tag,
    name
  });

  await dispatch(
    fetchWalletMutualWalletTransactions(address, walletId, tag, order, startDate, endDate, initial)
  );
};

export const walletMutualWalletTransactionsSetDateRange = (
  address,
  walletId,
  tag,
  order,
  startDate,
  endDate
) => async (dispatch, getState) => {
  const name = getCurrency(getState());
  dispatch({
    type: WALLET_MUTUAL_WALLETS_TRANSACTIONS_SET_DATE_RANGE,
    address,
    walletId,
    startDate,
    endDate,
    name
  });

  // get the state of the rest of the objects
  await dispatch(
    fetchWalletMutualWalletTransactions(
      address,
      walletId,
      tag,
      "newestFirst",
      startDate,
      endDate,
      false
    )
  );
};
