/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Papa from "papaparse/papaparse.min";
import {
  getBsaKey,
  retrieveBsa,
  retrieveBsaCount,
  retrieveBsaCountRemaining,
  retrieveNumberToShow,
} from "../../../selectors/address";
import { fetchAllBsa, fetchBsa } from "../../../actions/address";
import "../../../../css/styles.css";

class BsaMentionsTransactionView extends Component {
  componentDidMount() {
    // This should just not be mounted if nothing is being displayed.
    if (this.props.address) {
      this.props.fetchBsa(this.props.address, false);
    }
  }

  getDownloadCSVLink() {
    const data = this.props.bsa;
    if (data === null) {
      return null;
    }
    const finalData = [];
    data.map(item => finalData.push({ bsaAddress: item }));
    let csv = Papa.unparse(finalData);
    // The encodeURIComponent wrapper around the csv object was necessary for firefox compatibility
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    }
    return csv;
  }

  disableBsa() {
    const x = this.props.bsa.length;
    return x >= this.props.bsaCount;
  }

  loadMore() {
    this.props.fetchBsa(this.props.address, false);
  }

  loadData() {
    const csvButton =
      this.props.bsaKey === null ? (
        <a
          href={this.getDownloadCSVLink()}
          download={`${this.props.address}bsa-output.csv`}
          className="btn btn-success"
        >
          Download CSV
        </a>
      ) : (
        <Button
          className="blueButton"
          onClick={() => this.props.fetchAllBsa(this.props.address, false)}
        >
          Get Download Link
        </Button>
      );
    return this.props.address !== null ? (
      <div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th> BSA Mention Id </th>
              <th>
                <Button
                  className="blueButton"
                  onClick={() => this.loadMore()}
                  disabled={this.disableBsa()}
                >
                  Load More
                </Button>
              </th>
              <th>{csvButton}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.bsa.map(id => (
              <tr key={id}>
                <td
                  className="bsaHoverable"
                  style={{
                    fontFamily: "Monospace",
                    fontSize: "11px",
                  }}
                >
                  {id}
                </td>
                <td className="bsaHoverable" />
                <td className="bsaHoverable" />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <div
        className="center-block"
        style={{
          padding: "20px",
          width: "85%",
          display: "inlike-block",
        }}
      >
        <h4 style={{ textAlign: "center", color: "var(--secondary-color)" }}>
          Select an Address on the Left to Load the Corresponding BSA Mentions
        </h4>
      </div>
    );
  }

  render() {
    return <div>{this.loadData()}</div>;
  }
}

BsaMentionsTransactionView.propTypes = {
  address: PropTypes.string,
  bsa: PropTypes.arrayOf(PropTypes.number).isRequired,
  fetchBsa: PropTypes.func.isRequired,
  fetchAllBsa: PropTypes.func.isRequired,
  bsaCount: PropTypes.number.isRequired,
  bsaKey: PropTypes.number,
};

BsaMentionsTransactionView.defaultProps = {
  address: null,
  bsaKey: 0,
};
const mapStateToProps = (state, props) => {
  return {
    bsa: retrieveBsa(state, props.address),
    retrieveBsaCountRemaining: retrieveBsaCountRemaining(state, props.address),
    numberToShow: retrieveNumberToShow(state, props.address),
    bsaCount: retrieveBsaCount(state, props.address),
    bsaKey: getBsaKey(state, props.address),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchBsa: (address, initial) => {
    dispatch(fetchBsa(address, initial));
  },
  fetchAllBsa: address => {
    dispatch(fetchAllBsa(address, false));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BsaMentionsTransactionView);
