/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import { Button, ButtonToolbar, ControlLabel, Form, FormControl, FormGroup } from "react-bootstrap";
import AssociatedSearch from "./Search/AssociatedSearch";
import PropTypes from "prop-types";
import { EditorState } from "draft-js";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faCog,
  faPlus,
  faSearch,
  faStickyNote,
  faWallet
} from "@fortawesome/free-solid-svg-icons/index";
import { onInputChange, satoshiToBitcoin3 } from "../../helpers";
import { getMainTabsInfo } from "../../selectors/graph";
import NoteEditor from "../NoteEditor";
import {
  changeMainTabsKey,
  graphAssociationSearch,
  graphSearch,
  openTransactionModal,
  openWalletModal,
  saveGraph,
  updateEditorState
} from "../../actions/graph";
import Search from "./Search";
import Control from "./Control";
import { cytoscapeNodeShape } from "../../prop-types";
import WalletView from "./WalletView";
import WalletTagSetter from "./WalletTagSetter";
import WalletNoteSetter from "./WalletNoteSetter";
import ColorPicker from "./ColorPicker";
import Modal from "react-bootstrap/es/Modal";
import TransactionNodeView from "./TransactionNodeVIew";
import { FakeLinkSpan } from "../styled";
import { getClientMode } from "../../selectors/applicationConfig";
import ClientModeConfig from "../ClientModeConfig";
import { getCurrency } from "../../selectors/currency";
import { GraphChangeTitle } from "./GraphChangeTitle";
import axios, { RISK_SCORING_API } from "../../api";

class Sidebar extends Component {
  state = {
    fields: {
      caseNumber: "",
      description: "",
      search: ""
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.focusedWallet !== prevProps.focusedWallet) {
      this.setState({
        fields: {
          search: ""
        }
      });
    }
  }

  getWalletTab = wallet_or_entity => {
    const { walletKey, focusedWallet, cy, selectedEdge } = this.props;

    if (walletKey === "WalletView") {
      return <WalletView wallet={focusedWallet} cy={cy} graphId={this.props.graphId} />;
    }

    if (walletKey === "TransactionNodeView") {
      return <TransactionNodeView trx={focusedWallet} cy={cy} graphId={this.props.graphId} />;
    }

    if (walletKey === "TransactionEdgeView") {
      if (focusedWallet.data("type") === "transaction") {
        return (
          <div className="activeOptionMainDiv">
            <p style={{ fontSize: "24px", marginLeft: "10px" }}>Transaction</p>
            <p
              style={{
                fontSize: "15px",
                marginLeft: "10px",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              <FakeLinkSpan
                style={{ color: "white", fontSize: "18px", fontWeight: "500" }}
                onClick={() => this.props.handleOpenTransactionModal(selectedEdge.data("source"))}
              >
                {selectedEdge.data("source")}{" "}
              </FakeLinkSpan>
            </p>
            <div
              style={{
                borderLeft: "1px solid white",
                borderRadius: "0",
                marginLeft: "0",
                paddingLeft: "10px"
              }}
            >
              <p style={{ color: "white", fontSize: "14px", fontWeight: "500" }}>
                Wallet
                <FakeLinkSpan
                  style={{
                    color: "white",
                    fontSize: "18px",
                    fontWeight: "500"
                  }}
                  onClick={() => this.props.handleOpenWalletModal(selectedEdge.data("target"))}
                >
                  {" "}
                  {selectedEdge.data("target")}{" "}
                </FakeLinkSpan>
                received {satoshiToBitcoin3(selectedEdge.data("satoshi"))} BTC in this Transaction.
              </p>
            </div>
          </div>
        );
      } else {
        return (
          <div className="activeOptionMainDiv">
            <p style={{ fontSize: "24px", marginLeft: "10px" }}>Transaction</p>
            <p
              style={{
                fontSize: "15px",
                marginLeft: "10px",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              <FakeLinkSpan
                style={{ color: "white", fontSize: "18px", fontWeight: "500" }}
                onClick={() => this.props.handleOpenTransactionModal(selectedEdge.data("target"))}
              >
                {selectedEdge.data("target")}{" "}
              </FakeLinkSpan>
            </p>
            <div
              style={{
                borderLeft: "1px solid white",
                borderRadius: "0",
                marginLeft: "0",
                paddingLeft: "10px"
              }}
            >
              <p style={{ color: "white", fontSize: "14px", fontWeight: "500" }}>
                Wallet
                <FakeLinkSpan
                  style={{
                    color: "white",
                    fontSize: "18px",
                    fontWeight: "500"
                  }}
                  onClick={() => this.props.handleOpenWalletModal(selectedEdge.data("source"))}
                >
                  {" "}
                  {selectedEdge.data("source")}{" "}
                </FakeLinkSpan>
                sent {satoshiToBitcoin3(selectedEdge.data("satoshi"))} BTC in this Transaction.
              </p>
            </div>
          </div>
        );
      }
    }

    if (walletKey === "WalletTagSetter") {
      return (
        <>
          <GraphChangeTitle selectedEntity={focusedWallet} menuName={walletKey} cy={cy} />
          <WalletTagSetter wallet={focusedWallet} cy={cy} />
        </>
      );
    }

    if (walletKey === "WalletNoteSetter") {
      return (
        <>
          <GraphChangeTitle selectedEntity={focusedWallet} menuName={walletKey} cy={cy} />
          <WalletNoteSetter wallet={focusedWallet} cy={cy} graphId={this.props.graphId} />
        </>
      );
    }
    if (walletKey === "WalletColorPicker") {
      const colorPickerStyle = focusedWallet.isNode() ? "background-color" : "line-color";
      return (
        <>
          <GraphChangeTitle selectedEntity={focusedWallet} menuName={walletKey} cy={cy} />
          <ColorPicker item={focusedWallet} style={colorPickerStyle} />
        </>
      );
    }

    return (
      <p style={{ fontSize: "12px", paddingLeft: "25px", fontWeight: "600" }}>
        Please Select an {wallet_or_entity} in the Graph for a Detailed View.
      </p>
    );
  };

  handleEval = () => {
    // Protip: in the console, type `var myCy;`. Then evaluate `myCy = cy;` in this
    // component to use cy within the console.
    const { cy } = this.props; // eslint-disable-line no-unused-vars
    const str = this.text.value;
    eval(str); // eslint-disable-line no-eval
  };

  handleSearchFormSubmit = async e => {
    e.preventDefault();
    let query = this.state.fields.search;
    query = query.trim();

    if (
      this.props.focusedWallet === null ||
      this.state.walletKey === "TransactionNodeView" ||
      this.state.walletKey === "TransactionEdgeView"
    ) {
      if (query.length === 0) {
        return;
      }
      await this.props.search(query, this.cy);
    } else if (query.length === 0) {
      await this.props.associationSearch(this.props.focusedWallet.data("id"), "");
    } else {
      await this.props.associationSearch(this.props.focusedWallet.data("id"), query);
    }
    this.setState({ fields: { search: "" } });
  };

  onInputChange = onInputChange.bind(this);

  render() {
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null
    };
    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    const {
      handleOpenLoadGraphModal,
      handleOpenDeleteGraphModal,
      showGraphData,
      toggleVisible,
      toggleMenuOption,
      editorState,
      caseNumber,
      description,
      cy,
      graphId,
      activeMenuOption,
      modal,
      disabled
    } = this.props;

    const maxHeight = window.innerHeight > 400 ? window.innerHeight - 350 : 100;

    if (showGraphData) {
      return (
        <div
          className="col-lg-5 sidebar"
          style={{
            //background: "linear-gradient(#684ce6 45%, #543db8)",
            background: "var(--graph-sidebar-base)",
            overflowY: "auto",
            overflowX: "hidden",
            paddingLeft: "0",
            paddingRight: "0",
            height: "100%",
            zIndex: "10000"
          }}
        >
          <div
            className="row topRow"
            style={{
              display: "flex",
              margin: "0",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingLeft: "10px",
              width: "100%",
              borderBottom: "1px solid white"
            }}
          >
            <div className="col-lg-9">
              <div
                style={{
                  width: "100%",
                  marginBottom: "15px"
                }}
              >
                <p
                  className="graphDataHeader"
                  style={{ cursor: "pointer", color: "white" }}
                  onDoubleClick={this.handleOpenChangeCaseNumberModal}
                >
                  {caseNumber || "Add case number"}
                </p>
                <p
                  className="graphDataSubheader"
                  style={{ cursor: "pointer", color: "white" }}
                  onDoubleClick={this.handleOpenChangeTitleModal}
                >
                  {description || "Add description"}
                </p>
              </div>
            </div>
            <div
              className="col-lg-3"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Button className="collapseButton" onClick={toggleVisible}>
                <FontAwesomeIcon icon={faArrowLeft} /> Collapse
              </Button>
            </div>
          </div>
          <div
            className={activeMenuOption === "add" ? "sidebarOption active" : "sidebarOption"}
            style={{ paddingRight: "25px", paddingLeft: "25px" }}
          >
            <p className="optionHeader" onClick={() => toggleMenuOption("add")}>
              {" "}
              <FontAwesomeIcon icon={faPlus} style={{ paddingRight: "5px" }} /> Add New{" "}
              {clientModeConfigObject.cap_word_for_collection}{" "}
            </p>
            <div className="activeOptionMainDiv" style={{ margin: "0" }}>
              <span
                style={{
                  paddingLeft: "10px",
                  fontFamily: "Quicksand",
                  fontWeight: "700",
                  color: "white",
                  fontSize: "16px"
                }}
              >
                {this.props.focusedWallet === null ||
                this.state.walletKey === "TransactionNodeView" ||
                this.state.walletKey === "TransactionEdgeView"
                  ? `Search All ${clientModeConfigObject.cap_plural_for_collection}`
                  : ` Search ${
                      clientModeConfigObject.cap_plural_for_collection
                    } Interacting with ${this.props.focusedWallet.data("label")}`}
              </span>
              <Form style={{ width: "100%" }}>
                <FormGroup controlId="search" style={{ margin: "0", padding: "0" }}>
                  <div
                    className="row"
                    style={{
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      margin: "0"
                    }}
                  >
                    <div
                      className="col-lg-6 searchFilterPurple"
                      style={{
                        margin: "0",
                        paddingRight: "0",
                        paddingLeft: "0",
                        paddingBottom: "0"
                      }}
                    >
                      <FormControl
                        type="text"
                        placeholder={`Address/${clientModeConfigObject.cap_word_for_collection}/Transaction...`}
                        value={this.state.fields.search}
                        onChange={this.onInputChange}
                        style={{
                          fontSize: "14px",
                          marginBottom: "0",
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}
                      />
                    </div>
                    <div className="col-lg-6" style={{ margin: "0", paddingLeft: "0" }}>
                      <Button
                        className="control-whiteButton"
                        style={{
                          display: "inline-block",
                          marginTop: "10px",
                          marginBottom: "0",
                          height: "34px"
                        }}
                        onClick={this.handleSearchFormSubmit}
                        type="submit"
                      >
                        <FontAwesomeIcon icon={faSearch} /> Search
                      </Button>
                    </div>
                  </div>
                </FormGroup>
              </Form>
              <div style={{ marginRight: "0", marginLeft: "0" }}>
                {cy &&
                  (this.props.focusedWallet === null ||
                  this.state.walletKey === "TransactionNodeView" ||
                  this.state.walletKey === "TransactionEdgeView" ? (
                    <Search
                      cy={cy}
                      fromSidebar={true}
                      selectElementFromSearchResult={this.props.selectElementFromSearchResult}
                      disabled={disabled}
                    />
                  ) : (
                    <AssociatedSearch
                      cy={cy}
                      fromSidebar={true}
                      focusedWallet={this.props.focusedWallet.data("label")}
                      selectElementFromSearchResult={this.props.selectElementFromSearchResult}
                      disabled={disabled}
                    />
                  ))}
              </div>
            </div>
          </div>
          {/* <Button */}
          {/* onClick={this.props.openModal} */}
          {/* disabled={!this.props.entityType} */}
          {/* > */}
          {/* Review History */}
          {/* </Button> */}
          <div
            className={activeMenuOption === "data" ? "sidebarOption active" : "sidebarOption"}
            style={{ paddingRight: "25px", paddingLeft: "25px" }}
          >
            <p className="optionHeader" onClick={() => toggleMenuOption("data")}>
              {" "}
              <FontAwesomeIcon icon={faWallet} style={{ paddingRight: "5px" }} />{" "}
              {clientModeConfigObject.cap_word_for_collection} Data{" "}
            </p>
            {activeMenuOption === "data" &&
              this.getWalletTab(clientModeConfigObject.cap_word_for_collection)}
          </div>
          <div
            className={activeMenuOption === "notes" ? "sidebarOption active" : "sidebarOption"}
            style={{ paddingRight: "25px", paddingLeft: "25px" }}
          >
            <p className="optionHeader" onClick={() => toggleMenuOption("notes")}>
              {" "}
              <FontAwesomeIcon icon={faStickyNote} style={{ paddingRight: "5px" }} /> Notes{" "}
            </p>
            <div
              style={{
                backgroundColor: "white",
                paddingBottom: "0",
                marginTop: "20px"
              }}
              className="activeOptionMainDiv"
            >
              <NoteEditor
                height={maxHeight}
                editorState={editorState}
                saveGraph={async () => {
                  this.props.saveGraph(graphId, cy);
                }}
                fetchGraphComplete={this.props.fetchGraphComplete}
                onEdit={state => this.props.handleUpdateEditorState(graphId, state)}
              />
            </div>
          </div>
          <div
            className={activeMenuOption === "settings" ? "sidebarOption active" : "sidebarOption"}
            style={{ paddingRight: "25px", paddingLeft: "25px" }}
          >
            <p className="optionHeader" onClick={() => toggleMenuOption("settings")}>
              {" "}
              <FontAwesomeIcon icon={faCog} style={{ paddingRight: "5px" }} /> <span>Settings</span>
            </p>
            <div style={{ paddingBottom: "0", marginTop: "20px" }} className="activeOptionMainDiv">
              <Control
                cy={cy}
                graphId={graphId}
                saveCallback={async () => {
                  this.props.saveGraph(graphId, cy);
                }}
                importCallback={handleOpenLoadGraphModal}
                exportCallback={() =>
                  window.open(`/api/v2/${this.props.currency}/graph/${graphId}`)
                }
                deleteCallback={handleOpenDeleteGraphModal}
              />
            </div>
          </div>
          {["127.0.0.1", "localhost"].indexOf(window.location.hostname) > -1 && (
            <div
              className={activeMenuOption === "console" ? "sidebarOption active" : "sidebarOption"}
              style={{ paddingRight: "25px", paddingLeft: "25px" }}
            >
              <p className="optionHeader" onClick={() => toggleMenuOption("console")}>
                {" "}
                Console (dev only){" "}
              </p>
              <div style={{ paddingBottom: "0" }} className="activeOptionMainDiv">
                <FormGroup controlId="formControlsTextarea">
                  <ControlLabel>Console (Access graph using Javascript)</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    cols="100"
                    rows="5"
                    inputRef={ref => {
                      this.text = ref;
                    }}
                  />
                </FormGroup>
                <ButtonToolbar>
                  <Button bsSize="small" onClick={this.handleEval}>
                    Eval
                  </Button>
                </ButtonToolbar>
              </div>
            </div>
          )}
          <p
            style={{
              color: "rgba(255,255,255,0.5)",
              marginLeft: "25px",
              marginTop: "10px"
            }}
          >
            {" "}
            {this.props.lastSaved !== "" && this.props.lastSaved !== undefined
              ? `Last Saved ${this.props.lastSaved}`
              : ""}
          </p>
        </div>
      );
    } else {
      let edgeWallets;
      let fromWallet;
      let toWallet;
      if (this.props.focusedWallet && !this.props.focusedWallet.isNode()) {
        edgeWallets = this.props.focusedWallet.id().split("+");
        fromWallet = cy.getElementById(edgeWallets[0]).data("label");
        toWallet = cy.getElementById(edgeWallets[1]).data("label");
      }

      return (
        <>
          <div
            className="col-lg-1 sidebar collapsedSideBar"
            style={{ paddingTop: "10px", zIndex: "10000" }}
          >
            <Button className="expandButton" onClick={toggleVisible}>
              Expand <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
          {this.props.focusedWallet && (
            <>
              <Modal show={modal === "WalletTagSetter"} onHide={this.props.handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title style={{ fontFamily: "Quicksand", fontWeight: "600" }}>
                    <GraphChangeTitle
                      menuName={modal}
                      cy={this.props.cy}
                      selectedEntity={this.props.focusedWallet}
                      color={"black"}
                    />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <WalletTagSetter wallet={this.props.focusedWallet} fromModal={true} cy={cy} />
                </Modal.Body>
              </Modal>
              <Modal show={modal === "WalletNoteSetter"} onHide={this.props.handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title style={{ fontFamily: "Quicksand", fontWeight: "600" }}>
                    <GraphChangeTitle
                      menuName={modal}
                      cy={this.props.cy}
                      selectedEntity={this.props.focusedWallet}
                      color={"black"}
                    />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <WalletNoteSetter
                    wallet={this.props.focusedWallet}
                    fromModal={true}
                    cy={this.props.cy}
                    graphId={graphId}
                  />
                </Modal.Body>
              </Modal>
              <Modal show={modal === "WalletColorPicker"} onHide={this.props.handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title style={{ fontFamily: "Quicksand", fontWeight: "600" }}>
                    <GraphChangeTitle
                      menuName={modal}
                      cy={this.props.cy}
                      selectedEntity={this.props.focusedWallet}
                      color={"black"}
                    />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ColorPicker
                    item={this.props.focusedWallet}
                    style={
                      this.props.focusedWallet && this.props.focusedWallet.isNode()
                        ? "background-color"
                        : "line-color"
                    }
                    fromModal={true}
                  />
                </Modal.Body>
              </Modal>{" "}
            </>
          )}
        </>
      );
    }
  }
}

Sidebar.propTypes = {
  showGraphData: PropTypes.bool.isRequired,
  toggleVisible: PropTypes.func.isRequired,
  toggleMenuOption: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  associationSearch: PropTypes.func.isRequired,
  walletKey: PropTypes.string,
  activeMenuOption: PropTypes.string.isRequired,
  caseNumber: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  focusedWallet: cytoscapeNodeShape,
  cy: PropTypes.object, // eslint-disable-line
  editorState: PropTypes.instanceOf(EditorState),
  currentQuery: PropTypes.string,
  modal: PropTypes.string,
  selectedEdge: PropTypes.object,
  disabled: PropTypes.bool
};

Sidebar.defaultProps = {
  focusedWallet: null,
  walletKey: null,
  editorState: null,
  currentQuery: null,
  modal: "",
  lastSaved: "",
  selectedEdge: null,
  disabled: false
};

const mapStateToProps = (state, { graphId }) => {
  return {
    ...getMainTabsInfo(state, graphId),
    clientMode: getClientMode(state),
    currency: getCurrency(state)
  };
};

const mapDispatchToProps = dispatch => ({
  changeMainTabsKey: mainTabsKey => dispatch(changeMainTabsKey(mainTabsKey)),
  saveGraph: (graphId, cy) => dispatch(saveGraph(graphId, cy)),
  handleUpdateEditorState: (graphId, editorState) =>
    dispatch(updateEditorState(graphId, editorState)),
  search: (query, cy) => dispatch(graphSearch(query, cy)),
  associationSearch: (walletId, query) => dispatch(graphAssociationSearch(walletId, query)),
  handleOpenTransactionModal: transaction => dispatch(openTransactionModal(transaction)),
  handleOpenWalletModal: walletId => dispatch(openWalletModal(walletId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
