// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { List, Record } from "immutable";
import { ADD_NOTIFICATION, DISMISS_NOTIFICATION, LOGGING_OUT } from "../actions/actionNames";

/*
Notification State
Notification: List<Notification>

Notification: {
  text: string,
  style: string,
}
 */

const Notification = Record({
  text: "",
  style: "", // success, warning, danger, info
});

const dismissNotification = state => (state.size === 0 ? state : state.rest());

const addNotification = (state, action) =>
  state.push(
    new Notification({
      text: action.text,
      style: action.style,
    })
  );

/**
 * Holds a queue of notifications
 */

const makeNotificationReducer = coin => {
  return (state = List(), action) => {
    // If the coin doesn't match the reducer, just return the state.
    if (action === undefined || action.name !== coin) {
      return state;
    }

    switch (action.type) {
      case ADD_NOTIFICATION:
        return addNotification(state, action);
      case DISMISS_NOTIFICATION:
        return dismissNotification(state, action);
      case LOGGING_OUT:
        return List();
      default:
        return state;
    }
  };
};

export default makeNotificationReducer;
