import { getCurrency } from "../selectors/currency";
import axios, { MESSAGE_API } from "../api";
import {
  MESSAGE_MARK_AS_READ_FAILURE,
  MESSAGE_MARK_AS_READ,
  MESSAGE_MARKING_AS_READ,
  MESSAGE_IGNORE,
  MESSAGE_DELETE
} from "./actionNames";

export const markAsRead = message_id => async (dispatch, getState) => {
  // this sets the new primary key in the redux store if changing the tag on the
  // server is successful

  // action if successfully changes
  dispatch({
    type: MESSAGE_MARKING_AS_READ,
    message_id: message_id
  });
  const name = getCurrency(getState());
  try {
    await axios.put(`${MESSAGE_API}/${message_id}`);
  } catch (err) {
    dispatch({
      type: MESSAGE_MARK_AS_READ_FAILURE
    });
    throw err; //throw error for caller to handle
  }

  // action if successfully changes
  dispatch({
    type: MESSAGE_MARK_AS_READ,
    message_id: message_id,
    name
  });
};

export const deleteMessage = message_id => async (dispatch, getState) => {
  // this sets the new primary key in the redux store if changing the tag on the
  // server is successful

  // action if successfully changes
  dispatch({
    type: MESSAGE_DELETE,
    message_id: message_id,
    name
  });
};

export const ignoreMessage = message_id => async (dispatch, getState) => {
  // this sets the new primary key in the redux store if changing the tag on the
  // server is successful

  // action if successfully changes
  dispatch({
    type: MESSAGE_IGNORE,
    message_id: message_id,
    name
  });
};
