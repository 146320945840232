/*
 * Copyright 2018-2022  DecisionQ Information Operations, Inc. All Rights Reserved.
 */

const MakeCxtMenuSettings = ({
  deleteWalletsFromGraph,
  handleSelectColorPicker,
  handleSelectNoteSetter,
  handleSelectTagSetter,
}) => ({
  menuRadius: 100, // the radius of the circular menu in pixels
  selector: "node, edge", // elements matching this Cytoscape.js selector will trigger cxtmenus
  commands: ele => {
    let currentCommands = [];
    if (ele.data("type") !== "default" || ele.data("category") !== "custom") {
      handleSelectTagSetter &&
        currentCommands.push({
          fillColor: "rgba(57, 189, 63, 0.75)",
          content: "Set tag",
          select: ele => {
            if (ele.isEdge()) {
              return;
            }
            handleSelectTagSetter(ele);
          },
          enabled: true,
        });
    }

    //delete button
    deleteWalletsFromGraph &&
      currentCommands.push({
        // example command
        fillColor: "rgba(246, 0, 0, 0.75)",
        content: "Delete",
        select: ele => {
          // Remove all selected edges
          const selected = [].slice.call(ele.cy().elements(":selected"));

          // Need this check because the right-clicked element wont necessarily be selected
          const selectedIds = ele
            .cy()
            .elements(":selected")
            .map(wallet => wallet.data("id"));
          if (!selectedIds.includes(ele.data("id"))) {
            selected.push(ele);
          }

          // hide the summary
          if (ele.tippy) {
            ele.tippy.hide();
          }

          deleteWalletsFromGraph(ele.cy(), selected);
        },
        enabled: true, // Did this work by accident before???
      });

    //Notes
    handleSelectNoteSetter &&
      currentCommands.push({
        fillColor: "rgba(104, 76, 230, 0.75)",
        content: "Set Note",
        select: ele => {
          // add node edge check
          handleSelectNoteSetter(ele);
        },
        enabled: true,
      });

    handleSelectColorPicker &&
      currentCommands.push({
        fillColor: "rgba(100, 400, 200, 0.75)",
        content: "Color",
        select: ele => {
          handleSelectColorPicker(ele);
        },
        enabled: true,
      });
    return currentCommands;
  }, // function( ele ){ return [ /*...*/ ] }, // example function for commands
  fillColor: "rgba(0, 0, 0, 0.75)", // the background colour of the menu
  activeFillColor: "rgba(92, 194, 237, 0.75)", // the colour used to indicate the selected command
  activePadding: 20, // additional size in pixels for the active command
  indicatorSize: 24, // the size in pixels of the pointer to the active command
  separatorWidth: 3, // the empty spacing in pixels between successive commands
  spotlightPadding: 4, // extra spacing in pixels between the element and the spotlight
  minSpotlightRadius: 24, // the minimum radius in pixels of the spotlight
  maxSpotlightRadius: 38, // the maximum radius in pixels of the spotlight
  openMenuEvents: "cxttapstart", // space-separated cytoscape events that will open the menu; only `cxttapstart` and/or `taphold` work here
  itemColor: "white", // the colour of text in the command's content
  itemTextShadowColor: "black", // the text shadow colour of the command's content
  zIndex: 10000, // the z-index of the ui div
  atMouse: false, // draw menu at mouse position
});

export default MakeCxtMenuSettings;
