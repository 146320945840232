// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import axios, { TAG_API } from "../api";
import { getAddressPosts, getWalletPosts } from "../selectors/mention";
import { getCurrency } from "../selectors/currency";

export const FETCHING_MENTION_ADDRESS_POSTS = "FETCHING_MENTION_ADDRESS_POSTS";
export const MENTION_ADDRESS_POSTS_FETCH_SUCCESS = "MENTION_ADDRESS_POSTS_FETCH_SUCCESS";
export const MENTION_ADDRESS_POSTS_FETCH_FAILURE = "MENTION_ADDRESS_POSTS_FETCH_FAILURE";
export const fetchMentionAddressPosts = (onlineAccountId, address) => async (
  dispatch,
  getState
) => {
  const posts = getAddressPosts(getState(), onlineAccountId, address);
  if (!posts.isEmpty()) {
    return;
  }

  const name = getCurrency(getState());
  dispatch({ type: FETCHING_MENTION_ADDRESS_POSTS });
  try {
    const api = `${TAG_API(name)}/${onlineAccountId}/address/${address}`;
    const { data } = await axios.get(api);
    dispatch({
      type: MENTION_ADDRESS_POSTS_FETCH_SUCCESS,
      address,
      onlineAccountId,
      data,
      name,
    });
  } catch (err) {
    dispatch({
      type: MENTION_ADDRESS_POSTS_FETCH_FAILURE,
      onlineAccountId,
      address,
      name,
    });
  }
};

export const FETCHING_MENTION_WALLET_POSTS = "FETCHING_MENTION_WALLET_POSTS";
export const MENTION_WALLET_POSTS_FETCH_SUCCESS = "MENTION_WALLET_POSTS_FETCH_SUCCESS";
export const MENTION_WALLET_POSTS_FETCH_FAILURE = "MENTION_WALLET_POSTS_FETCH_FAILURE";
export const fetchMentionWalletPosts = (onlineAccountId, wallet) => async (dispatch, getState) => {
  const posts = getWalletPosts(getState(), onlineAccountId, wallet);
  if (!posts.isEmpty()) {
    return;
  }

  const name = getCurrency(getState());
  dispatch({ type: FETCHING_MENTION_WALLET_POSTS });
  try {
    const api = `${TAG_API(name)}/${onlineAccountId}/wallet/${wallet}`;
    const { data } = await axios.get(api);
    dispatch({
      type: MENTION_WALLET_POSTS_FETCH_SUCCESS,
      wallet,
      onlineAccountId,
      data,
      name,
    });
  } catch (err) {
    dispatch({
      type: MENTION_WALLET_POSTS_FETCH_FAILURE,
      onlineAccountId,
      wallet,
      name,
    });
  }
};
