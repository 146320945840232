import * as React from "react";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import {
  CircularProgress,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
// @ts-ignore
import debounce from "lodash/debounce";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { getCurrency } from "../selectors/currency";
import { AutocompleteProps } from "@material-ui/lab/Autocomplete/Autocomplete";

const filter = (currency: string) => {
  return createFilterOptions({
    // matchFrom: 'start',
    stringify: (option: QueryResults) => {
      let ethereumHexLabel = "";

      if (currency === "ethereum") {
        if (
          !option.label.toLowerCase().startsWith("0x") &&
          (option.type === "Address" || option.type === "Transaction")
        ) {
          ethereumHexLabel = "0x" + option.label;
        }
      }
      return (
        option.label.toString() +
        ethereumHexLabel +
        (option.custom ? option.custom.toString() : "") +
        (option.contract ? option.contract.toString() : "")
      );
    }
  });
};

interface QueryResults {
  type: string;
  label: string;
  custom?: string[];
  contract?: string;
  url: string;
  id?: string | number;
}

type CustomAutocompleteProps = AutocompleteProps<
  QueryResults,
  boolean | undefined,
  boolean | undefined,
  boolean | undefined
>;

export type classType = {
  popper: any,
  inputField: any,
  adornment: any,
  textField: any,
  noBorder: any
}

interface SearchBarProps {
  label: string;
  query: (query: string, currency: string) => Promise<QueryResults[]>;
  handleSelect: (option: QueryResults) => void;
  manualValue?: string;
  type?: string;
  manualSearch?: (query: string) => any;
  autoCompleteProps?: CustomAutocompleteProps;
  adornmentProps?: Partial<FontAwesomeIconProps>;
  customClasses?: classType
}

const OPTIONS_LIMIT = 100;

export const Searchbar = ({
  label,
  query,
  handleSelect,
  manualValue,
  type = "",
  manualSearch,
  autoCompleteProps,
  adornmentProps,
  customClasses
}: SearchBarProps) => {
  const [open, setOpen] = React.useState(false);
  const [optionsCoin, setOptionsCoins] = React.useState<{
    [key: string]: QueryResults[];
  }>({});
  const [searchedTermsCoins, setSearchedTermsCoins] = React.useState<{
    [key: string]: string[];
  }>({});
  const [value, setValue] = React.useState<any>("");
  const [inputValue, setInputValue] = React.useState("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const currency = useSelector(state => getCurrency(state));

  const options = optionsCoin[currency];
  const searchedTerms = searchedTermsCoins[currency] || [];
  const getOptionsDelayed = useCallback(
    debounce((text: string, callback: (results: QueryResults[]) => void) => {
      setLoading(true);
      query(text, currency).then(callback);
    }, 400),
    [currency, query]
  );

  useEffect(() => {
    if (manualValue === "") {
      setInputValue(manualValue);
      setValue("");
      setOpen(false);
    }
  }, [manualValue]);

  const useStyles = makeStyles(theme => ({
    popper: {
      zIndex: 10101
    },
    inputField: {
      fontSize: 16
      // backgroundColor: "white"
    },
    adornment: {
      // backgroundColor: "gray",
      padding: "12px 10px",
      fontSize: 16,
      backgroundColor: theme.palette.divider,
      borderTopLeftRadius: theme.shape.borderRadius + "px",
      borderBottomLeftRadius: theme.shape.borderRadius + "px",
      color: "var(--secondary-color-dark-hover) !important",
      cursor: "pointer"
    },
    textField: {
      "& .MuiOutlinedInput-root": {
        paddingLeft: 0,
        backgroundColor: "white",
        height: 40,
        borderRadius: 10
      }
    },
    noBorder: {
      border: "none"
    }
  }));
  const classes = customClasses ?? useStyles();

  React.useEffect(() => {
    if (!inputValue) {
      return undefined;
    }
    if (typeof inputValue === "string" && inputValue.length < 3) {
      return undefined;
    }

    if (!searchedTerms.find(term => type + inputValue === type + term) && open) {
      setOpen(true); // TODO might not be needed
      getOptionsDelayed(inputValue, (results: QueryResults[]) => {
        let newOptions: QueryResults[] = [];
        if (!options) {
          newOptions = results;
        } else {
          newOptions = options;
          results.forEach(result => {
            const idx = newOptions.findIndex(
              option => option.label === result.label && option.type === result.type
            );
            if (idx >= 0) {
              if (result.custom && newOptions[idx].custom) {
                let curOptions = newOptions[idx].custom;
                result.custom.forEach(currResult => {
                  curOptions.indexOf(currResult) === -1 && curOptions.push(currResult);
                });
                newOptions[idx].custom = curOptions;
              } else {
                newOptions[idx].custom = result.custom;
              }
            } else {
              newOptions.push(result);
            }
          });
        }
        setOptionsCoins({
          ...optionsCoin,
          [currency]: newOptions
        });
        setSearchedTermsCoins({
          ...searchedTermsCoins,
          [currency]: [...searchedTerms, type + inputValue]
        });
        setLoading(false);
      });
    }
  }, [inputValue, getOptionsDelayed]);

  const handleManualSearch = (value: string) => {
    if (!value || value === "") {
      return;
    }
    let existing_options: number[] = [];
    options &&
      options.forEach(({ label }, idx) => {
        if (label === value) {
          existing_options.push(idx);
        }
      });

    if (existing_options.length == 1) {
      handleSelect(options[existing_options[0]]);
    } else {
      manualSearch(value);
    }
  };
  return (
    <Autocomplete
      disableClearable
      classes={{ popper: classes.popper }}
      id="search_bar"
      size="medium"
      open={!!(open && inputValue && inputValue.length >= 3)}
      freeSolo
      onOpen={() => {
        setOpen(true);
      }}
      fullWidth
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      inputValue={inputValue}
      onChange={(event, newValue, reason) => {
        if (typeof newValue === "string") {
          setValue({
            label: newValue
          });
        } else {
          setValue(newValue);
          if (newValue && newValue.type) {
            handleSelect(newValue);
            setInputValue(newValue.label);
            setValue("");
            setOpen(false);
          }
        }
      }}
      onInputChange={(e, value, reason) => {
        if (reason !== "reset") {
          setInputValue(value);
        }
      }}
      filterOptions={(options, params) => {
        params.inputValue = inputValue
        // Suggest the creation of a new value when not empty and not an existing name
        let filtered = filter(currency)(options, params).slice(0, OPTIONS_LIMIT);
        const formattedType = type && type.charAt(0).toUpperCase() + type.slice(1)
        filtered = filtered.filter(({type: optionType}) => !type || optionType === formattedType)
        if (
          manualSearch && params.inputValue.trim() !== "" &&
          !filtered.some(values => values.label === params.inputValue)
        ) {
          filtered.unshift({
            type: "Search",
            url: `/search/${params.inputValue.trim()}`,
            label: `${params.inputValue}`
          });
        }
        if (!manualSearch && !loading && filtered.length === 0
        && searchedTerms.find(val => val === type + inputValue)) {
          filtered.unshift({
            type: "",
            url: "",
            label: "No Results Found"
          })
        }
        return filtered;
      }}
      getOptionLabel={option => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        const formattedType = type && type.charAt(0).toUpperCase() + type.slice(1)
        if (type && option.type && option.type !== formattedType) {
          return ""
        }
        // Regular option
        return option.label.toString();
      }}
      renderOption={option => (
        <Typography>
          {option.type && <span> {option.type}: </span>}
          {option.label}
          {option.custom && option.custom.length > 0 && ` (custom tag: ${option.custom})`}
        </Typography>
      )}
      options={options || []}
      loading={loading}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderInput={params => (
        <TextField
          {...params}
          placeholder={label}
          onKeyPress={event => {
            if (!manualSearch) {
              return;
            }
            if (event.key === "Enter") {
              handleManualSearch(inputValue);
            }
          }}
          variant="outlined"
          classes={{ root: classes.textField }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  className={classes.adornment}
                  icon={faSearch}
                  {...(manualSearch && {onClick: () => handleManualSearch(inputValue)})}
                  {...adornmentProps}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress
                    // className={classes.adornment}
                    color="inherit"
                    size={20}
                  />
                ) : (
                  params.InputProps.endAdornment
                )}
              </React.Fragment>
            ),
            type: "search",
            classes: {
              input: classes.inputField,
              notchedOutline: classes.noBorder
            }
          }}
        />
      )}
      {...autoCompleteProps}
    />
  );
};
