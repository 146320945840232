import axios, { NOTES_API } from "../../api";
import { Button, Form, FormGroup, Modal, OverlayTrigger } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrency } from "../../selectors/currency";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { faConciergeBell, faNotesMedical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FullWidthPopover } from "../styled";

const AddToNote = ({ currency, page, itemId }) => {
  const [show, setShow] = useState(false);
  const [notesList, setNotesList] = useState([]);
  const [value, setValue] = useState(""); // Value selected by user to use for note
  const [pageName, setPageName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [add, setAdd] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar(); // Notification snackbar

  useEffect(() => {
    (async () => {
      if (show) {
        setLoading(true);
        const p = page.toLowerCase();
        setPageName(p.charAt(0).toUpperCase() + p.slice(1));
        const { data: noteData } = await axios.get(NOTES_API(currency));
        setNotesList(noteData.notes);
        setLoading(false);
      }
    })();
    if (!show) {
      setValue(null);
    }
  }, [show]);

  const allNoteOptions = () => notesList;

  useEffect(() => {
    (async () => {
      if (value && add) {
        const noteId = value.noteId;
        const location = page.toLowerCase();
        try {
          await axios.put(`${NOTES_API(currency)}/addtonote/${noteId}/${location}`, {
            item: itemId
          });
          if (enqueueSnackbar) {
            enqueueSnackbar(`Successfully added to case file`, { variant: "success" });
          }
        } catch (err) {
          if (enqueueSnackbar) {
            if (err && err.response && err.response.status === 403) {
              enqueueSnackbar(`This ${location} is already in that case file`, {
                variant: "error"
              });
            } else if (err && err.response && err.response.status === 404) {
              enqueueSnackbar(`This ${location} does not exist or has not been processed`, {
                variant: "error"
              });
            }
          }
        }
        setAdd(false);
        setShow(false);
      }
    })();
  }, [add]);

  return (
    <>
      <OverlayTrigger
        overlay={
          <FullWidthPopover style={{ fontFamily: "Quicksand", fontWeight: "600" }}>
            Add to Case File
          </FullWidthPopover>
        }
        placement="bottom"
        delayShow={300}
        delayHide={150}
      >
        <Button
          style={{ marginRight: "3px" }}
          className="greenButton"
          onClick={() => setShow(true)}
        >
          <FontAwesomeIcon icon={faNotesMedical} style={{ fontSize: "16px" }} />
        </Button>
      </OverlayTrigger>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add {pageName} to Case File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup>
              <Autocomplete
                onChange={(event, value) => setValue(value)}
                disablePortal
                options={allNoteOptions()}
                loading={loading}
                sx={{ width: 300 }}
                getOptionLabel={option => option.title.toString()}
                renderOption={option => (
                  <p>
                    <span>{option.title}</span>
                    <span style={{ fontSize: "11px", color: "gray" }}>
                      {" "}
                      Last Modified: {moment.unix(parseInt(option.lastModified_, 10)).format("LLL")}
                    </span>
                  </p>
                )}
                renderInput={params => <TextField {...params} label="Case Management File" />}
              />
            </FormGroup>
            <Button style={{ borderColor: "#ccc" }} variant="outlined" onClick={() => setAdd(true)}>
              Add
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    currency: getCurrency(state)
  };
}

export default withRouter(connect(mapStateToProps)(AddToNote));
