// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { Map, Record } from "immutable";

import { FETCH_CURRENCY_SUCCESS, LOGGING_OUT, SWAP_CURRENCY } from "../actions/actionNames";

export const Currency = Record({
  currency: "bitcoin",
  prices: Map(),
});

export const PriceRecord = Record({
  bitcoin: 0.0,
  "bitcoin-cash": 0.0,
  "bitcoin-sv": 0.0,
  "bitcoin-gold": 0.0,
  litecoin: 0.0,
  ethereum: 0.0,
});

const swapCurrency = (state, action) => {
  const { currency } = action;
  localStorage.setItem("currency", currency);
  return state.set("currency", currency);
};

const setCurrencyUsdValues = (state, action) => {
  const prices = action.data;
  return state.set("prices", prices);
};

const currency = (state = new Currency(), action) => {
  switch (action.type) {
    case SWAP_CURRENCY:
      return swapCurrency(state, action);
    case FETCH_CURRENCY_SUCCESS:
      return setCurrencyUsdValues(state, action);
    case LOGGING_OUT:
      localStorage.setItem("currency", "bitcoin");
      return new Currency();
    default:
      return state;
  }
};

export default currency;
