/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";

import { WALLET_API } from "../../api";
import WalletStats from "./WalletStats";
import ReceivedWallets from "./ReceivedWallets";
import SentWallets from "./SentWallets";
import WalletMutualTransactions from "./WalletMutualTransactions";
import WalletMentions from "./WalletTags";
import { WalletAddresses, WalletTransactions } from "../Wallet";
import { cytoscapeNodeShape } from "../../prop-types";
import {
  getModalAddressLink,
  getModalTransactionLinkUnshortened,
  getShortTransactionDateCell
} from "../Wallet/helpers";
import {
  fetchInitialWalletData,
  fetchWalletAddresses,
  fetchWalletMentions,
  fetchWalletTransactions
} from "../../actions/wallet";
import { getGraphSelectedDates, getWalletViewData } from "../../selectors/graph";
import {
  changeDataTabsKey,
  openAddressModal,
  openTransactionModal,
  openWalletModal
} from "../../actions/graph";
import { FakeLinkSpan } from "../styled";
import { convertPriceToUsd } from "../../helpers";
import WalletPeelChain from "./WalletPeelChain";
import { getWalletName, getWalletRisk } from "../../selectors/wallet";
import { RiskScoreBase } from "../Risk/RiskScoreBase";

class WalletView extends Component {
  componentDidMount() {
    // FIXME Make WalletStats take care of its own data fetching
    const { wallet, graphId } = this.props;
    const walletId = wallet.id();
    this.props.fetchAll(walletId, graphId);
  }

  componentDidUpdate(prevProps) {
    const { wallet: oldWallet } = prevProps;
    const { wallet: newWallet, graphId } = this.props;
    if (oldWallet !== newWallet) {
      const walletId = newWallet.id();
      this.props.fetchAll(walletId, graphId);
    }
  }

  handleSelect = key => {
    this.props.handleChangeDataTabsKey(key);
  };

  getTransactionLink = transaction => {
    const { handleOpenTransactionModal } = this.props;
    return getModalTransactionLinkUnshortened(transaction, handleOpenTransactionModal);
  };

  getAddressLink = address => {
    const { handleOpenAddressModal } = this.props;
    return getModalAddressLink(address, handleOpenAddressModal);
  };

  render() {
    console.log(this.props);
    const { wallet, handleOpenWalletModal, cy, dataTabsKey, graphId } = this.props;
    const maxHeight = window.innerHeight > 400 ? window.innerHeight - 400 : 100;
    return (
      <div className="activeOptionMainDiv">
        <p style={{ fontSize: "24px", marginLeft: "10px" }}>{wallet.id() < 0 && "Custom Entity"}</p>
        <p style={{ fontSize: "15px", marginLeft: "10px" }}>
          <FakeLinkSpan
            style={{ color: "white", fontSize: "18px", fontWeight: "500" }}
            onClick={() => handleOpenWalletModal(wallet.data("id"))}
          >
            {wallet.data("label")}
          </FakeLinkSpan>
        </p>
        <div
          className="tabWrapper walletGraphData"
          style={{
            borderLeft: "1px solid white",
            borderRadius: "0",
            marginLeft: "0"
          }}
        >
          <Tabs
            style={{ width: "100%" }}
            activeKey={dataTabsKey}
            id="transaction-tab"
            onSelect={this.handleSelect}
            animation={false}
            mountOnEnter
          >
            <Tab
              eventKey="WalletStats"
              title="Stats"
              style={{ marginLeft: "0", paddingBottom: "0" }}
            >
              <br />
              <WalletStats wallet={wallet} graphId={graphId} />
            </Tab>
            <Tab
              eventKey="WalletAddresses"
              title="Addresses"
              style={{ marginLeft: "0", paddingBottom: "0" }}
            >
              <br />
              <WalletAddresses
                walletId={wallet.id()}
                maxHeight={maxHeight}
                getAddressLink={this.getAddressLink}
                caption={wallet.data("label")}
                fromModal
                fromSidebar
              />
            </Tab>
            <Tab
              eventKey="ReceivedWallets"
              title="Received"
              style={{ marginLeft: "0", paddingBottom: "0" }}
            >
              <br />
              <ReceivedWallets wallet={wallet} maxHeight={maxHeight} cy={cy} />
            </Tab>
            <Tab
              eventKey="SentWallets"
              title="Sent"
              style={{ marginLeft: "0", paddingBottom: "0" }}
            >
              <br />
              <SentWallets wallet={wallet} maxHeight={maxHeight} cy={cy} />
            </Tab>
            <Tab
              eventKey="WalletMentions"
              title="Mentions"
              style={{ marginLeft: "0", paddingBottom: "0" }}
            >
              <br />
              <WalletMentions walletId={wallet.id()} maxHeight={maxHeight} />
            </Tab>
            <Tab
              eventKey="WalletTransactions"
              title="Entity Trx"
              style={{ marginLeft: "0", paddingBottom: "0" }}
            >
              <br />
              <WalletTransactions
                walletId={wallet.id()}
                apiBase={WALLET_API}
                graphId={graphId}
                maxHeight={maxHeight}
                getTransactionLink={this.getTransactionLink}
                getDateCell={getShortTransactionDateCell}
                caption={wallet.data("label")}
                convertPriceToUsd={convertPriceToUsd}
                graphView
                startDate={this.props.startDate}
                endDate={this.props.endDate}
              />
            </Tab>
            <Tab
              eventKey="WalletMutualTransactions"
              title="Edge Trx"
              style={{ marginLeft: "0", paddingBottom: "0", marginRight: "0" }}
            >
              <br />
              <WalletMutualTransactions
                inputWallet={wallet}
                graphId={graphId}
                cy={cy}
                maxHeight={maxHeight}
              />
            </Tab>
            <Tab
              eventKey="WalletPeelChain"
              title="Peel Chain"
              style={{ marginLeft: "0", paddingBottom: "0", marginRight: "0" }}
            >
              <WalletPeelChain walletId={wallet.id()} cy={cy} graphId={graphId} />
            </Tab>
            {this.props.risk && (
              <Tab
                eventKey="Risk"
                title="Risk"
                style={{ marginLeft: "0", paddingBottom: "0", marginRight: "0" }}
              >
                <RiskScoreBase type={"wallet"} results={this.props.risk} />
              </Tab>
            )}
          </Tabs>
        </div>
      </div>
    );
  }
}

WalletView.propTypes = {
  wallet: cytoscapeNodeShape.isRequired,
  dataTabsKey: PropTypes.string.isRequired,
  handleOpenAddressModal: PropTypes.func.isRequired,
  handleOpenTransactionModal: PropTypes.func.isRequired,
  handleChangeDataTabsKey: PropTypes.func.isRequired,
  handleOpenWalletModal: PropTypes.func.isRequired,
  fetchWalletTransactions: PropTypes.func.isRequired,
  fetchWalletAddresses: PropTypes.func.isRequired,
  fetchWalletMentions: PropTypes.func.isRequired,
  fetchAll: PropTypes.func.isRequired,
  cy: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  graphId: PropTypes.number.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    dataTabsKey: getWalletViewData(state),
    ...getGraphSelectedDates(state, ownProps.graphId),
    risk: getWalletRisk(state, ownProps.wallet.id())
  };
};

const mapDispatchToProps = dispatch => ({
  fetchAll: (walletId, graphID) => {
    dispatch(fetchInitialWalletData(walletId, graphID));
  },
  fetchWalletTransactions: (walletId, order, startDate, endDate) => {
    dispatch(fetchWalletTransactions(walletId, order, startDate, endDate, true));
  },
  fetchWalletAddresses: walletId => dispatch(fetchWalletAddresses(walletId, true)),
  fetchWalletMentions: walletId => dispatch(fetchWalletMentions(WALLET_API, walletId, true)),
  handleOpenAddressModal: address => dispatch(openAddressModal(address)),
  handleOpenTransactionModal: transaction => dispatch(openTransactionModal(transaction)),
  handleChangeDataTabsKey: key => dispatch(changeDataTabsKey(key)),
  handleOpenWalletModal: walletId => dispatch(openWalletModal(walletId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletView);
