/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types/index";
import React, { Component } from "react";
import { Col, Grid, Row, Tab, Tabs } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import CustomWalletAddresses from "./CustomWalletAddresses";
import CustomWalletSummary from "./CustomWalletSummary";
import CustomWalletTransactions from "./CustomWalletTransactions";
import { CUSTOM_WALLETS_API } from "../../../api";
import withRouteBasedTabs from "../withRouteBasedTabs";
import {
  fetchCustomWalletMentions,
  fetchCustomWalletSummary,
  setProcessing
} from "../../../actions/customWallet";
import { getCurrency, getUsdForCoin } from "../../../selectors/currency";
import ClientModeConfig from "../../ClientModeConfig";
import { getClientMode } from "../../../selectors/applicationConfig";
import { getProcessed } from "../../../selectors/customWallet";
import {
  getMentionsCount,
  getTags,
  getWalletSummary
} from "../../../selectors/wallet";
import WalletMentions from "../../Graph/WalletTags/WalletMentions";
import { fetchWalletMentions } from "../../../actions/wallet";
import { convertPriceToUsd } from "../../../helpers";
import withAddPreloadModals from "../../withAddPreloadModals";
import { getEmail } from "../../../selectors/authentication";
import WalletNoteEditor from "../../NoteEditor/WalletNoteEditor";

/**
 * Component to represent custom wallets
 */
class CustomWallet extends Component {
  state = {
    usd: false,
    change: false,
    histUsd: false
  };

  /*
   * Getting wallet name
   */
  componentDidMount() {
    const { walletId } = this.props;
    this.props.fetchWalletTags(walletId);
  }

  /*
   * refetch wallet name if left page
   */
  componentDidUpdate(prevProps) {
    const { walletId: oldWalletId } = prevProps;
    const { walletId: newWalletId } = this.props;
    if (oldWalletId !== newWalletId) {
      // this.fetchPriceData(this.props.currency);
    }
  }

  //TODO a lot of these functions are copied from Wallet.jsx there has to be a cleaner way
  getMentionsBadge = mentionsCount => {
    if (mentionsCount === 0) {
      return (
        <div>
          <p style={{ display: "inline", paddingRight: "4px" }}>Mentions</p>
          <span className="badge progress-bar-danger">{mentionsCount}</span>
        </div>
      );
    }
    return (
      <div>
        <p style={{ display: "inline", paddingRight: "4px" }}>Mentions</p>
        <span className="badge progress-bar-info">{mentionsCount}</span>
      </div>
    );
  };

  disableMentions() {
    return this.props.mentionsCount === 0;
  }

  setUsdDisplay = value => {
    this.setState({
      usd: value,
      histUsd: false
    });
  };

  setChange = value => {
    this.setState({
      change: value
    });
  };

  setHistUsd = value => {
    this.setState({
      histUsd: value,
      usd: false
    });
  };

  // Updates tab text
  getHelmet = key => {
    let tab;
    if (key === "transactions") {
      tab = "Transactions";
    } else if (key === "mentions") {
      tab = "Tags";
    } else {
      tab = "Addresses";
    }

    return (
      <Helmet>
        <title>{`Wal ${this.props.tags.primaryTag} ${tab}`}</title>
      </Helmet>
    );
  };

  render() {
    let clientModeConfigObject = {
      word_for_collection: "",
      cap_word_for_collection: "",
      plural_for_collection: "",
      cap_plural_for_collection: "",
      nav_logo: null,
      login_logo: null,
      login_top_logo: null,
      login_top_icon: null
    };

    if (this.props.clientMode in ClientModeConfig) {
      clientModeConfigObject = ClientModeConfig[this.props.clientMode];
    }

    const {
      walletId,
      tabKey,
      onTabSelect,
      getTransactionLink,
      getDateCell
    } = this.props;
    return (
      <div>
        {this.getHelmet(tabKey)}
        <Grid>
          <CustomWalletSummary
            walletId={walletId}
            convertPriceToUsd={convertPriceToUsd}
            currentUsdPrice={this.props.usd}
            toggleUsdDisplay={this.setUsdDisplay}
            showAsUsd={this.state.usd}
            historicalToUsd={this.state.histUsd}
            graphId={this.props.graphId}
            toggleHistoricalUsd={this.setHistUsd}
            showChange={this.state.change}
            toggleChange={this.setChange}
          />
          <Row>
            <Col lg={12}>
              <Tabs
                className="inviteTabs"
                activeKey={tabKey}
                id="custom-wallet-tab"
                onSelect={onTabSelect}
                animation={false}
                style={{ marginTop: "10px" }}
              >
                <Tab
                  eventKey="transactions"
                  title={`${clientModeConfigObject.cap_word_for_collection} Transactions`}
                >
                  <br />
                  <CustomWalletTransactions
                    threshold={-100}
                    walletId={walletId}
                    apiBase={CUSTOM_WALLETS_API}
                    graphId={this.props.graphId}
                    maxHeight={700}
                    getTransactionLink={getTransactionLink}
                    getDateCell={getDateCell}
                    currency={this.props.currency}
                    convertPriceToUsd={convertPriceToUsd}
                    currentUsdPrice={this.props.usd}
                    showAsUsd={this.state.usd}
                    historicalToUsd={this.state.histUsd}
                  />
                </Tab>
                <Tab eventKey="addresses" title="Addresses">
                  <CustomWalletAddresses
                    apiBase={CUSTOM_WALLETS_API}
                    graphId={this.props.graphId}
                    walletId={walletId}
                    maxHeight={700}
                    currency={this.props.currency}
                    processed={this.props.processed}
                    name={this.props.tags.primaryTag}
                    getAddressLink={this.props.getAddressLink}
                    owner={this.props.user_email === this.props.email}
                  />
                </Tab>
                <Tab
                  eventKey="mentions"
                  title={this.getMentionsBadge(this.props.mentionsCount)}
                  disabled={this.disableMentions()}
                  style={{
                    backgroundColor: "var(--secondary-color)",
                    paddingBottom: "15px",
                    marginTop: "10px"
                  }}
                >
                  <WalletMentions
                    walletId={walletId}
                    graphId={this.props.graphId}
                    apiBase={CUSTOM_WALLETS_API}
                    maxHeight={700}
                  />
                </Tab>
                <Tab eventKey="notes" title="Notes">
                <WalletNoteEditor
                  wallet={this.props.tags.primaryTag}
                  walletId={walletId}
                  mainLocationId={walletId}
                  mainLocation={"wallet"}
                ></WalletNoteEditor>
              </Tab>
              </Tabs>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

CustomWallet.propTypes = {
  currency: PropTypes.string.isRequired,
  walletId: PropTypes.string.isRequired,
  tabKey: PropTypes.string.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  setProcessing: PropTypes.func.isRequired,
  fetchCustomWalletSummary: PropTypes.func.isRequired,
  fetchCustomWalletMentions: PropTypes.func.isRequired,
  getTransactionLink: PropTypes.func.isRequired,
  getAddressLink: PropTypes.func.isRequired,
  getDateCell: PropTypes.func.isRequired,
  processed: PropTypes.bool,
  mentionsCount: PropTypes.number.isRequired,
  tags: PropTypes.shape({
    primaryTag: PropTypes.string.isRequired,
    originalTag: PropTypes.string.isRequired
  }),
  graphId: PropTypes.number
};

CustomWallet.defaultProps = {
  processed: true
};

//TODO Either wallet changes or this for props. I prefer this way because it involves less props.
// Move MentionsCount to another component?
const mapStateToProps = (state, { walletId }) => {
  return {
    currency: getCurrency(state),
    clientMode: getClientMode(state),
    processed: getProcessed(state, walletId),
    mentionsCount: getMentionsCount(state, walletId),
    tags: getTags(state, walletId),
    usd: getUsdForCoin(state),
    user_email: getEmail(state),
    ...getWalletSummary(state, walletId, true)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCustomWalletSummary: walletId =>
    dispatch(fetchCustomWalletSummary(walletId)),
  fetchCustomWalletMentions: walletId =>
    dispatch(fetchCustomWalletMentions(walletId, true)),
  setProcessing: (walletId, processed) =>
    dispatch(setProcessing(walletId, processed)),
  fetchWalletTags: walletId => dispatch(fetchWalletMentions(walletId, true))
});

const getPreloadInfo = ({ walletId, processed }) => ({
  type: "custom-wallet",
  item: walletId,
  processed: processed
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAddPreloadModals(CustomWallet, getPreloadInfo));
