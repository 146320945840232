import WrappedLink from "../Utils/WrappedLink";
import { useSelector } from "react-redux";
import { getAddressSummary, getName } from "../../selectors/ethereum/address";
import * as React from "react";
import { DropdownButton, MenuItem, OverlayTrigger } from "react-bootstrap";
import { Divider } from "@material-ui/core";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-bootstrap";
import CopyText from "../CopyText";
import { formattedTagTypes } from "../Wallet/CustomWallet/WalletName";

interface AddressNameProps {
  address: string;
  stateBased?: boolean;
  copyFontSize?: string;
}

export const AddressName = ({
  address,
  stateBased = false,
  copyFontSize = "18px"
}: AddressNameProps) => {
  const addressSummary = useSelector(state => getAddressSummary(state, address, true));
  const wallet = addressSummary.walletName || addressSummary.walletId;

  let names = getName(addressSummary);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <CopyText
          text={names.length > 0 ? names[0].name : address}
          fontSize={copyFontSize}
          marginLeft={5}
          marginRight={5}
        />
        <p
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            marginRight: names.length > 1 ? 0 : 15
          }}
        >
          {names.length > 0 ? names[0].name : address}
        </p>
        {names.length > 1 && (
          <DropdownButton
            style={{
              height: 7,
              width: 7,
              marginRight: 15
            }}
            title={""}
            className={"customDropdown"}
            id="name-dropdown"
          >
            <MenuItem
              header
              style={{
                color: "black",
                fontSize: "12px",
                fontWeight: "700",
                fontFamily: "Quicksand"
              }}
            >
              Also known as:
            </MenuItem>
            <Divider />
            <div style={{ maxHeight: "140px", overflowY: "auto" }}>
              {names.slice(1).map(elem => (
                <MenuItem
                  header
                  style={{
                    color: "black",
                    fontSize: "12px",
                    fontWeight: "700",
                    fontFamily: "Quicksand"
                  }}
                >
                  {elem.source_name ? (
                    <>
                      <OverlayTrigger
                        placement="bottom"
                        style={{ position: "fixed" }}
                        overlay={
                          <Tooltip
                            id="button-tooltip-2"
                            style={{ position: "fixed", zIndex: 30000000 }}
                          >
                            <>
                              <div>Source: {elem.source_name}</div>{" "}
                              {elem.source_confidence && (
                                <div>Source Confidence: {elem.source_confidence}</div>
                              )}
                            </>
                          </Tooltip>
                        }
                      >
                        <div style={{ cursor: "pointer" }}>{elem.name}</div>
                      </OverlayTrigger>
                    </>
                  ) : (
                    <div style={{ cursor: "default" }}>{elem.name}</div>
                  )}
                  {/*{elem.name}*/}
                </MenuItem>
              ))}
            </div>
          </DropdownButton>
        )}
        {names.length > 0 && names[0].source_name ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip-2" style={{ zIndex: 30000000 }}>
                <>
                  <div>Source: {names[0].source_name}</div>{" "}
                  {names[0].source_confidence && (
                    <div>Source Confidence: {names[0].source_confidence}</div>
                  )}
                </>
              </Tooltip>
            }
          >
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ display: "inline", fontSize: "20px", cursor: "pointer" }}
            />
          </OverlayTrigger>
        ) : (
          <></>
        )}
      </div>
      {addressSummary.walletId && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: "10px"
          }}
        >
          <WrappedLink
            entity={addressSummary.walletId}
            entityType={"wallet"}
            stateBased={stateBased}
          >
            <p
              style={{
                fontSize: "14px",
                color: "var(--secondary-color)"
              }}
            >
              [{wallet}]
            </p>
          </WrappedLink>
        </div>
      )}
    </>
  );
};
