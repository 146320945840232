import {
  Alert,
  Button,
  ControlLabel,
  DropdownButton,
  Form,
  FormControl,
  FormGroup
} from "react-bootstrap";
import React, { useReducer, useState } from "react";
import InputGroup from "react-bootstrap/lib/InputGroup";
import MenuItem from "react-bootstrap/lib/MenuItem";
import CurrencyChooserConfig from "../CurrencyChooserConfig";
import { useSelector } from "react-redux";
import { getCurrency } from "../../selectors/currency";
import Table from "react-bootstrap/lib/Table";
import { TRANSACTION_API } from "../../api";
import axios from "axios";
import { getUnixTimestampFromDate } from "../../helpers";
import moment from "moment";
import { Link } from "react-router-dom";
import CopyText from "../CopyText";
import { Skeleton } from "@material-ui/lab";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import DataDownload from "../DataDownload";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { ScrollDiv } from "../styled";

export const TransactionSearcher = ({ loadingRows = 5, maxHeight = 700 }) => {
  const currency = useSelector(state => getCurrency(state));
  let currencyObject = CurrencyChooserConfig[currency];

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [entity_ids, setEntityIds] = useState("");
  const [errormsg, setErrorMsg] = useState("");

  const entityTypeDict = {
    address: {
      plural: "Address(es)",
      entityCol: "address_id",
      singular: "Address"
    },
    wallet: {
      plural: "Wallet(s)",
      entityCol: "wallet_id",
      singular: "Wallet"
    },
    custom_wallet: {
      plural: "Custom Wallet(s)",
      entityCol: "cluster_id",
      singular: "Custom Wallet"
    }
  };
  const [entityType, setEntityType] = useState("address");

  const currencyDict = {
    satoshi: "Satoshi",
    usd: "USD",
    coin: currencyObject.abb
  };
  const [currencyType, setCurrencyType] = useState("coin");

  const sideDict = {
    sent: "Sent",
    received: "Received"
  };
  const [side, setSide] = useState("sent");
  const [value, setValue] = useState();
  const [transactions, setTransactions] = useState([]);

  // The reducer used to access and manipulate local state
  function reducer(state, action) {
    switch (action.type) {
      case "set":
        return action.payload;
      default:
        throw new Error();
    }
  }

  const [tableSettings, dispatchTable] = useReducer(reducer, {
    type: entityType,
    side: side,
    currency: currencyType,
    hasMore: false
  });
  const numTableColumns = 5;
  // status = 0, nothing loaded on first time so empty
  // status = 1, form was submitted waiting for request to finish (loading)
  // status = 2, request completed show table with data.
  const [status, setStatus] = useState(0);

  const getValue = (
    side,
    currency,
    input_usd_price,
    output_usd_price,
    input_satoshi,
    output_satoshi
  ) => {
    if (currency.toUpperCase() === "USD") {
      return `$${Number(
        side === "sent" ? input_usd_price : output_usd_price.toFixed(8)
      ).toLocaleString("en")}`;
    }
    return `${Number(
      ((side === "sent" ? input_satoshi : output_satoshi) / 100000000).toFixed(
        2
      )
    ).toLocaleString("en")}
      ${currencyObject.abb}`;
  };

  const fetchTransactions = async (
    start,
    end,
    entityType,
    side,
    currencyType,
    value,
    entity_ids,
    offset = 0,
    append = false
  ) => {
    setErrorMsg("");

    setStatus(1); //Set loading
    const {
      data: { trxs, hasMore }
    } = await axios.put(`${TRANSACTION_API(currency)}/transaction-narrower`, {
      starttime: start,
      endtime: end,
      type: entityType,
      ...(offset !== 0 && {
        offset: offset
      }),
      ...(value && {
        value: {
          value: side === "sent" ? -value : value,
          currency_type: currencyType
        }
      }),
      entity_ids: entity_ids ? entity_ids.trim().split(/[\s,\n]+/) : []
    });
    await dispatchTable({
      type: "set",
      payload: {
        type: entityType,
        side: side,
        currency: currencyType,
        hasMore,
        start,
        end,
        entity_ids,
        value
      }
    });

    const newTransactions = trxs.map(
      ({
        transaction_hash,
        time,
        input_usd_price,
        output_usd_price,
        input_satoshi,
        output_satoshi,
        interval,
        ...entity
      }) => {
        const entityColumnName = entityTypeDict[entityType]["entityCol"];
        const entityObject = entity[entityColumnName];
        return {
          name: entityObject.name,
          id: entityObject.id,
          transaction_hash,
          time: moment
            .unix(time)
            .utc()
            .format("MM/DD/YY HH:mm:ss z"),
          interval,
          entityType,
          value: getValue(
            side,
            currencyType,
            input_usd_price,
            output_usd_price,
            input_satoshi,
            output_satoshi
          )
        };
      }
    );
    setTransactions(
      append ? transactions.concat(newTransactions) : newTransactions
    );
    setStatus(2);
  };

  const getTransactions = async e => {
    e.preventDefault();
    setTransactions([]);
    setErrorMsg("");
    const start = getUnixTimestampFromDate(startDate);
    const end = getUnixTimestampFromDate(endDate);
    if (start >= end) {
      throw new Error("Start date can't be after the end date");
    }
    await fetchTransactions(
      start,
      end,
      entityType,
      side,
      currencyType,
      value,
      entity_ids
    );
  };

  const loadMoreTransactions = async e => {
    e.preventDefault();

    setErrorMsg("");
    await fetchTransactions(
      tableSettings.start,
      tableSettings.end,
      tableSettings.type,
      tableSettings.side,
      tableSettings.currency,
      tableSettings.value,
      tableSettings.entity_ids,
      transactions.length,
      true
    );
  };

  return (
    <div>
      <br />
      <p style={{ fontSize: "14px", fontWeight: "700", color: "#666" }}>
        This tool is to help narrow down to transactions when you know some
        specifics about the transaction but don't know the actual transaction on
        the blockchain.
      </p>
      <Form
        onSubmit={e =>
          getTransactions(e)
            .then(_ => {
              setStatus(2);
            })
            .catch(e => {
              console.error(e);
              setErrorMsg(
                (e.response && e.response.data.message) || e.toString()
              );
              setStatus(0);
            })
        }
      >
        <ControlLabel className="trxSearcherLabel">Start Date</ControlLabel>
        <Datetime input onChange={e => setStartDate(e)} />
        <ControlLabel className="trxSearcherLabel">End Date</ControlLabel>
        <Datetime input onChange={e => setEndDate(e)} />
        <ControlLabel className="trxSearcherLabel">
          Entities (optional to specify entities to filter for)
        </ControlLabel>
        <FormGroup>
          <InputGroup>
            <FormControl
              componentClass="textarea"
              type="text"
              rows={1}
              value={entity_ids}
              min={0}
              placeholder=""
              onChange={e => setEntityIds(e.target.value)}
            />
            <DropdownButton
              componentClass={InputGroup.Button}
              id="input-dropdown-addon"
              title={entityTypeDict[entityType]["plural"]}
              style={{
                fontFamily: "Quicksand",
                fontWeight: "700",
                color: "#666"
              }}
            >
              {Object.keys(entityTypeDict).map((curr, idx) => (
                <MenuItem
                  key={`entityType ${idx}`}
                  onClick={() => setEntityType(curr)}
                  style={{ fontFamily: "Quicksand" }}
                  // className={menuItemButtonClass}
                >
                  {entityTypeDict[curr]["plural"]}
                </MenuItem>
              ))}
            </DropdownButton>
          </InputGroup>
        </FormGroup>
        <ControlLabel className="trxSearcherLabel">
          Transaction Value
        </ControlLabel>
        <FormGroup>
          <InputGroup>
            <input
              type="number"
              step={0.00000001}
              min={0}
              className="form-control"
              value={value}
              onChange={e => setValue(e.target.value)}
            />
            <DropdownButton
              componentClass={InputGroup.Button}
              id="input-dropdown-addon"
              title={sideDict[side] || "un"}
              style={{
                fontFamily: "Quicksand",
                fontWeight: "700",
                color: "#666"
              }}
            >
              {Object.keys(sideDict).map((curr, idx) => (
                <MenuItem
                  key={`side ${idx}`}
                  onClick={() => setSide(curr)}
                  style={{ fontFamily: "Quicksand" }}
                  // className={menuItemButtonClass}
                >
                  {sideDict[curr]}
                </MenuItem>
              ))}
            </DropdownButton>
            <DropdownButton
              componentClass={InputGroup.Button}
              id="input-dropdown-addon"
              title={currencyDict[currencyType]}
              style={{
                fontFamily: "Quicksand",
                fontWeight: "700",
                color: "#666"
              }}
            >
              {Object.keys(currencyDict).map((curr, idx) => (
                <MenuItem
                  key={`currency ${idx}`}
                  onClick={() => setCurrencyType(curr)}
                  style={{ fontFamily: "Quicksand" }}
                >
                  {currencyDict[curr]}
                </MenuItem>
              ))}
            </DropdownButton>
          </InputGroup>
        </FormGroup>
        <Button type="submit" className="greenButton">
          Submit
        </Button>
        {errormsg && <p style={{ color: "red", fontSize: 14 }}> {errormsg} </p>}
      </Form>
      {status > 0 && (
        <>
          <Grid style={{ marginTop: 10 }}>
            <div className="blueButtonFilter">
              <Row>
                <Col md={9} sm={9} lg={9}>
                  <h3 style={{ color: "var(--transaction-searcher-results-text)", marginBottom: 0, marginTop: 5 }}>
                    Results
                  </h3>
                </Col>
                <Col className={"align-self-end"} md={3} sm={3} lg={3}>
                  <Row>
                    <Col lg={6}>
                      {transactions.length !== 0 && (
                        <DataDownload
                          data={transactions.map(
                            ({
                              name,
                              id,
                              transaction_hash,
                              time,
                              interval,
                              value,
                              entityType
                            }) => ({
                              "Transaction Hash": transaction_hash,
                              [entityTypeDict[tableSettings.type]["singular"]]:
                                entityType === "wallet"
                                  ? name
                                    ? `${name} (${id})`
                                    : id
                                  : name,
                              Timestamp: time,
                              "Time Since Start Date": interval,
                              [sideDict[tableSettings.side]]: value
                            })
                          )}
                          entity_type="transaction"
                        />
                      )}
                    </Col>
                    <Col lg={6}>
                      <Button
                        className="whiteButton"
                        disabled={
                          status === 1 ||
                          !tableSettings ||
                          !tableSettings.hasMore
                        }
                        onClick={e =>
                          loadMoreTransactions(e)
                            .then(_ => {
                              setStatus(2);
                            })
                            .catch(e => {
                              console.error(e);
                              setErrorMsg(
                                e.response.data.message || e.toString()
                              );
                              setStatus(0);
                            })
                        }
                        style={{ float: "right" }}
                      >
                        {!tableSettings || !tableSettings.hasMore
                          ? "No More"
                          : "Load More"}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Grid>
          <ScrollDiv maxHeight={maxHeight} id="transactionsTable">
            <Table
              className="inviteTable"
              style={{
                width: "100%",
                tableLayout: "fixed",
                overflow: "auto",
                wordWrap: "break-word"
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>
                    {entityTypeDict[tableSettings.type]["singular"]}
                  </th>
                  <th style={{ width: "35%" }}>Transaction</th>
                  <th style={{ width: "20%" }}>Timestamp</th>
                  <th style={{ width: "15%" }}>Time Since Start</th>
                  <th style={{ width: "10%" }}>
                    {sideDict[tableSettings.side]}
                  </th>
                </tr>
              </thead>
              <tbody style={{ position: "relative" }}>
                {transactions &&
                  transactions.length > 0 &&
                  transactions.map(
                    ({
                      name,
                      id,
                      transaction_hash,
                      time,
                      interval,
                      value,
                      entityType
                    }) => (
                      <tr>
                        <td
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap"
                          }}
                        >
                          <CopyText
                            text={name || id}
                            marginLeft={"0"}
                            fontSize={"18px"}
                          />
                          <Link
                            style={{color: "var(--link-colors)"}}
                            to={`/${currency}/${entityType}/${
                              entityType === "wallet" ? id : name
                            }`}
                          >
                            {name || id}
                          </Link>
                        </td>
                        <td
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap"
                          }}
                        >
                          <CopyText
                            text={transaction_hash}
                            marginLeft={"0"}
                            fontSize={"18px"}
                          />
                          <Link
                            style={{color: "var(--link-colors)"}}
                            to={`/${currency}/transaction/${transaction_hash}`}
                          >
                            {transaction_hash}
                          </Link>
                        </td>
                        <td>
                          <div>{time}</div>
                        </td>
                        <td>{interval}</td>
                        <td>{value}</td>
                      </tr>
                    )
                  )}
                {status === 1 &&
                  [...Array(loadingRows)].map(_ => (
                    <tr className="inviteRowThin staticTableRow">
                      {[...Array(numTableColumns)].map(_ => (
                        <td>
                          <Skeleton />
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </ScrollDiv>
          {status === 2 && transactions && transactions.length === 0 && (
            <Alert
              style={{ boxShadow: "0px 2px 2px 0px #666" }}
              bsStyle="warning"
            >
              No transactions found
            </Alert>
          )}
        </>
      )}
    </div>
  );
};
