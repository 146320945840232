import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { fetchTokenTransfersEthereum } from "../../actions/ethereum/transaction";
import { getTransactionTokenTransfers } from "../../selectors/ethereum/transaction";
import { TokenTransferItem } from "./TokenTransfer";

export const TransactionTokenTransfers = ({
  transaction,
  stateBased = false
}) => {
  const { erc20, erc721 } = useSelector(state =>
    getTransactionTokenTransfers(state, transaction, true)
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // TODO for future use for loading symbols
  const fetchTokenTransfers = async () => {
    setLoading(true);
    await dispatch(fetchTokenTransfersEthereum(transaction));
    setLoading(false);
  };
  useEffect(() => {
    fetchTokenTransfers().catch(console.error); // TODO add error message
  }, [transaction]);
  return (
    <div className="col-sm-12 col-md-12 col-lg-12">
      {erc20 && erc20.length > 0 && (
        <div>
          <p
            style={{
              color: "var(--secondary-color)",
              fontFamily: "Quicksand",
              fontWeight: "700"
            }}
          >
            ERC20 Token Transfers
          </p>
          {erc20.map((curr, idx) => (
            <TokenTransferItem
              key={"id" + idx.toString()}
              {...curr}
              label={curr.symbol || curr.name || curr.token_account_id}
              stateBased={stateBased || false}
            />
          ))}
        </div>
      )}
      {erc721 && erc721.length > 0 && (
        <div className="infoCards">
          <p
            style={{
              color: "var(--secondary-color)",
              fontFamily: "Quicksand",
              fontWeight: "700"
            }}
          >
            ERC721 Token Transfers
          </p>
          {erc721.map((curr, idx) => (
            <TokenTransferItem
              key={"id" + idx.toString()}
              {...curr}
              label={curr.symbol || curr.name || curr.token_account_id}
              stateBased={stateBased || false}
            />
          ))}
        </div>
      )}
    </div>
  );
};
