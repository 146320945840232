/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

export const sortByAddressAsc = outputs =>
  outputs.sort((a, b) => {
    // We always want empty addresses to show up last, since they aren't
    // important.
    if (a.address[0] === "") {
      return 1;
    }
    if (b.address[0] === "") {
      return -1;
    }
    return a.address[0].localeCompare(b.address[0]);
  });

export const sortByAddressDesc = outputs =>
  outputs.sort((a, b) => {
    // See above
    if (a.address[0] === "") {
      return 1;
    }
    if (b.address[0] === "") {
      return -1;
    }
    return b.address[0].localeCompare(a.address[0]);
  });

export const sortByWalletAsc = outputs =>
  outputs.sort((a, b) => {
    // See above
    if (a.walletId === null) {
      return 1;
    }
    if (b.walletId === null) {
      return -1;
    }
    return a.walletId - b.walletId;
  });

export const sortByWalletDesc = outputs =>
  outputs.sort((a, b) => {
    // See above
    if (a.walletId === null) {
      return 1;
    }
    if (b.walletId === null) {
      return -1;
    }
    return b.walletId - a.walletId;
  });

export const sortBySatoshiAsc = outputs =>
  outputs.sort((a, b) => {
    // See above
    if (a.satoshi === 0) {
      return 1;
    }
    if (b.satoshi === 0) {
      return -1;
    }
    return a.satoshi - b.satoshi;
  });

export const sortBySatoshiDesc = outputs =>
  outputs.sort((a, b) => {
    // See above
    if (a.satoshi === 0) {
      return 1;
    }
    if (b.satoshi === 0) {
      return -1;
    }
    return b.satoshi - a.satoshi;
  });
