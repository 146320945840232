/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

// This component is shared between Address, Wallet, and Transaction and is
// used to preload an existing Graph with the item in the view.

import moment from "moment";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, Grid, Modal, Row, Table } from "react-bootstrap";
import styled from "styled-components";
import { connect } from "react-redux";

import axios, { GRAPH_API } from "../api";
import { FakeLinkSpan } from "./styled";
import { addPreloadToGraph } from "../actions/graph";
import { getCurrency } from "../selectors/currency";

/**
 * Modal for existing graph modal for adding to graph
 */
class PreloadGraphModal extends Component {
  state = {
    graphs: [],
    selectedGraphIndex: null
  };

  componentDidMount() {
    this.fetchGraphs();
  }

  setGraphIndex = i => {
    this.setState({
      selectedGraphIndex: i
    });
  };

  getButtonText = () => {
    const { selectedGraphIndex } = this.state;
    if (selectedGraphIndex != null) {
      return `Add to ${this.state.graphs[selectedGraphIndex].caseNumber}`;
    }
    return "Please select a graph from above.";
  };

  fetchGraphs = async () => {
    try {
      const { data } = await axios.get(GRAPH_API(this.props.currency));
      this.setState({
        graphs: data.graphs
      });
    } catch (err) {
      throw err;
    }
  };

  addToGraph = async () => {
    const { graphs, selectedGraphIndex } = this.state;
    const { graphId } = graphs[selectedGraphIndex];
    await this.props.addPreloadToGraph(graphId);
    this.props.close();
  };

  render() {
    const { curGraph } = this.props;
    const graphList = this.state.graphs.filter(({ graphId }) =>
      curGraph ? graphId !== curGraph : true
    );
    const graphs = graphList.map((graph, i) => {
      const { graphId, description, isPublic, lastModified_, caseNumber } = graph;
      const lastModified = moment.unix(parseInt(lastModified_, 10)).format("LLL");

      return (
        <tr key={graphId}>
          <td>
            <FakeLinkSpan onClick={() => this.setGraphIndex(i)}>
              {caseNumber || "(No case number)"}
            </FakeLinkSpan>
          </td>
          <td>{description}</td>
          <td>{isPublic}</td>
          <td>{lastModified}</td>
        </tr>
      );
    });

    const { selectedGraphIndex } = this.state;

    const buttonText = this.getButtonText();

    const { show, close, className } = this.props;

    return (
      <Modal dialogClassName={className} show={show} onHide={close}>
        <br />
        <Grid>
          <Row>
            <Col lg={12}>
              <Table striped hover>
                <caption>Add to existing graph</caption>
                <thead>
                  <tr>
                    <th>Case #</th>
                    <th>Description</th>
                    <th>Public</th>
                    <th>Last Modified</th>
                  </tr>
                </thead>
                <tbody>{graphs}</tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Button disabled={selectedGraphIndex == null} onClick={this.addToGraph}>
              {buttonText}
            </Button>
          </Row>
          <br />
        </Grid>
      </Modal>
    );
  }
}

PreloadGraphModal.propTypes = {
  // The following are needed for `mapDispatchToProps`, but not used directly in
  // PreloadGraphForm.
  // eslint-disable-next-line
  type: PropTypes.string.isRequired, // type can be address, wallet, or transaction
  // eslint-disable-next-line
  item: PropTypes.string.isRequired,
  addPreloadToGraph: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  curGraph: PropTypes.number
};

const StyledPreloadGraphModal = styled(PreloadGraphModal)`
  width: 90%;
`;

const mapStateToProps = state => {
  return {
    currency: getCurrency(state)
  };
};

/**
 * Puts entity in preload list for graph
 * @param dispatch
 * @param type type of entity added to graph
 * @param item individual item or arrays for adding to preload list
 * @returns {{addPreloadToGraph: addPreloadToGraph}}
 */
const mapDispatchToProps = (dispatch, { type, item }) => {
  const preloadAddresses = [];
  const preloadWallets = [];
  const preloadTransactions = [];
  const preloadUnclusteredTransactions = [];

  const isArray = Array.isArray(item);
  if (type === "address") {
    isArray ? preloadAddresses.push(...item) : preloadAddresses.push(item);
  } else if (type === "wallet") {
    isArray ? preloadWallets.push(...item) : preloadWallets.push(item);
  } else if (type === "transaction") {
    isArray ? preloadTransactions.push(...item) : preloadTransactions.push(item);
  } else if (type === "unclustered_transaction") {
    isArray
      ? preloadUnclusteredTransactions.push(...item)
      : preloadUnclusteredTransactions.push(item);
  }

  return {
    addPreloadToGraph: graphId => {
      dispatch(
        addPreloadToGraph(graphId, {
          preloadAddresses,
          preloadWallets,
          preloadTransactions,
          preloadUnclusteredTransactions
        })
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledPreloadGraphModal);
