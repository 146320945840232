/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";

import { reactRouterHistoryShape, reactRouterMatchShape } from "../../prop-types";

const withRouteBasedTabs = Component => {
  const WrappedComponent = props => {
    const handleRouteChange = key => {
      const { url } = props.match;
      switch (key) {
        case "transactions":
          props.history.push(`${url}/transactions`);
          break;
        case "mentions":
          props.history.push(`${url}/mentions`);
          break;
        case "bsa":
          props.history.push(`${url}/bsa`);
          break;
        case "ip-addresses":
          props.history.push(`${url}/ip-addresses`);
          break;
        case "notes":
          props.history.push(`${url}/notes`);
          break;
        case "risk":
          props.history.push(`${url}/risk`);
          break;
        default:
          break;
      }
    };

    const {
      match: {
        params: { address },
        path,
        url
      },
      history,
      ...passThroughProps
    } = props;
    return (
      <Switch>
        <Route
          path={`${path}/:tab?`}
          render={({ match }) => (
            <Component
              address={address}
              tabKey={match.params.tab}
              onTabSelect={handleRouteChange}
              enableAddToGraph
              stateBased={false}
              {...passThroughProps}
            />
          )}
        />
        <Redirect to={`${url}/transactions`} />
      </Switch>
    );
  };

  WrappedComponent.propTypes = {
    match: reactRouterMatchShape({
      address: PropTypes.string.isRequired
    }).isRequired,
    history: reactRouterHistoryShape.isRequired
  };

  WrappedComponent.displayName = `withRouteBasedTabs(${Component.displayName || Component.name})`;

  return WrappedComponent;
};

withRouteBasedTabs.propTypes = {
  match: reactRouterMatchShape({
    address: PropTypes.string.isRequired
  }).isRequired,
  history: reactRouterHistoryShape.isRequired
};

export default withRouteBasedTabs;
