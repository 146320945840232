/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";

import CollapsedInputAddressRow from "./CollapsedInputAddressRow";
import withTableSorter from "../withTableSorter";
import { PointerTableHeader } from "../styled";
import { transactionAddressCollapsed } from "../../../prop-types";
import { Table } from "react-bootstrap";
import CurrencyChooserConfig from "../../CurrencyChooserConfig";
import { numberWithCommas } from "../../../helpers";
import ClientModeConfig from "../../ClientModeConfig";
import { useSelector } from "react-redux";
import { getClientMode } from "../../../selectors/applicationConfig";

const CollapsedInputAddressTable = ({
  totalBitcoin,
  outputs,
  handleSortByAddress,
  handleSortBySatoshi,
  handleSortByWallet,
  handleOpenAddressModal,
  handleOpenWalletModal,
  getAddressLink,
  getWalletLink,
  currentCaret,
  currency,
  convertPriceToUsd,
  toggleUsdDisplay,
  showAsUsd,
}) => {
  // map addresses to table rows
  const inputs = outputs.map(input => {
    const { key, satoshi, walletId, address, primaryTag } = input;

    return (
      <CollapsedInputAddressRow
        key={key}
        keyProp={key}
        address={address}
        walletId={walletId}
        satoshi={satoshi}
        primaryTag={primaryTag}
        handleOpenAddressModal={handleOpenAddressModal}
        handleOpenWalletModal={handleOpenWalletModal}
        getAddressLink={getAddressLink}
        getWalletLink={getWalletLink}
        currency={currency}
        convertPriceToUsd={convertPriceToUsd}
        toggleUsdDisplay={toggleUsdDisplay}
        showAsUsd={showAsUsd}
      />
    );
  });

  let count = outputs.length;
  if (outputs.length > 0 && outputs[0].walletId == null) {
    count = 0;
  }
  let currencyObject = CurrencyChooserConfig[currency];

  const clientMode = useSelector(state => getClientMode(state));
  let clientModeConfigObject = {
    word_for_collection: "",
    cap_word_for_collection: "",
    plural_for_collection: "",
    cap_plural_for_collection: "",
    nav_logo: null,
    login_logo: null,
    login_top_logo: null,
    login_top_icon: null,
  };
  if (clientMode in ClientModeConfig) {
    clientModeConfigObject = ClientModeConfig[clientMode];
  }

  return (
    <div>
      <p style={{ fontFamily: "Quicksand", fontSize: "15px" }}>
        <b style={{ color: "#666" }}>Input</b> ({count}){" "}
        {showAsUsd ? "$" + numberWithCommas(convertPriceToUsd(totalBitcoin)) : totalBitcoin}
      </p>
      <Table
        className="inviteTable"
        style={{
          width: "100%",
          tableLayout: "fixed",
          overflow: "auto",
          wordWrap: "break-word",
        }}
      >
        <thead>
          <tr>
            <PointerTableHeader style={{ width: "55%" }} onClick={handleSortByAddress}>
              Address
              <div className="divider" />
              {currentCaret.address}
            </PointerTableHeader>
            <PointerTableHeader style={{ width: "25%" }} onClick={handleSortByWallet}>
              {clientModeConfigObject.cap_word_for_collection}
              <div className="divider" />
              {currentCaret.wallet}
            </PointerTableHeader>
            <PointerTableHeader onClick={handleSortBySatoshi} className="text-right">
              {showAsUsd ? "$" : currencyObject["abb"]}
              <div className="divider" />
              {currentCaret.satoshi}
            </PointerTableHeader>
          </tr>
        </thead>
        <tbody>{inputs}</tbody>
      </Table>
    </div>
  );
};

CollapsedInputAddressTable.propTypes = {
  totalBitcoin: PropTypes.string.isRequired,
  handleSortByAddress: PropTypes.func.isRequired,
  handleSortBySatoshi: PropTypes.func.isRequired,
  outputs: PropTypes.arrayOf(transactionAddressCollapsed).isRequired,
  handleOpenAddressModal: PropTypes.func,
  handleOpenWalletModal: PropTypes.func,
  handleSortByWallet: PropTypes.func,
  getAddressLink: PropTypes.func.isRequired,
  getWalletLink: PropTypes.func.isRequired,
  currentCaret: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

CollapsedInputAddressTable.defaultProps = {
  handleOpenAddressModal: null,
  handleOpenWalletModal: null,
  handleSortByWallet: null,
};

export default withTableSorter(CollapsedInputAddressTable, "address");
