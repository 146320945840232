/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Form, FormControl, FormGroup, Modal } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default class CreateGraphModal extends Component {
  state = {
    title: "",
  };

  handleCreateForm = async e => {
    e.preventDefault();
    const caseNumber = this.caseNumberForm.value;
    const description = this.descriptionForm.value;
    const isPublic = this.isPublicForm.checked;
    this.props.close();
    this.props.createGraph(caseNumber, description, isPublic);
  };

  handleChange = e => {
    this.setState({ title: e.target.value });
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.close}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Graph</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleCreateForm}>
            <FormGroup>
              <FormControl
                type="text"
                placeholder="Case Number"
                maxLength={50} // max case number size
                onChange={this.handleChange}
                inputRef={ref => {
                  this.caseNumberForm = ref;
                }}
              />
            </FormGroup>
            <FormGroup>
              <FormControl
                componentClass="textarea"
                type="text"
                rows={10}
                maxLength={2000} // max description size
                placeholder="Description"
                inputRef={ref => {
                  this.descriptionForm = ref;
                }}
              />
            </FormGroup>
            <FormGroup>
              <Checkbox
                inputRef={ref => {
                  this.isPublicForm = ref;
                }}
              >
                Public
              </Checkbox>
            </FormGroup>
            <Button className="blueButton" type="submit" disabled={this.state.title.length <= 0}>
              <span>
                <FontAwesomeIcon icon={faPlus} />
                {"  "}
                Create Graph
              </span>
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

CreateGraphModal.propTypes = {
  createGraph: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};
