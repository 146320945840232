import { List, Map, Record } from "immutable";
import {
  INTERNAL_TRANSACTIONS_FETCH_SUCCESS,
  LOGGING_OUT,
  TOKEN_TRANSFERS_FETCH_SUCCESS,
  TRANSACTION_SUMMARY_FETCH_SUCCESS
} from "../../actions/actionNames";
import { CoinSwap } from "../address";
import { InternalTransaction, Transactions } from "./address";

export const TokenTransaction = Record({
  sender_id: "",
  receiver_id: "",
  name: "",
  symbol: "",
  token_account_id: "",
  value: 0,
  decimals: 0,
  log_index: 0
});
export const TokenTransfer = Record({
  erc20: [],
  erc721: []
});
export const TransactionSummary = Record({
  transactionHash: null,
  nonce: 0,
  toAddress: "",
  toAddressBalance: 0,
  fromAddress: "",
  fromAddressBalance: 0,
  gasPrice: 0,
  date: 0,
  blockHeight: 0,
  gasLimit: 0,
  value: 0,
  gasUsed: 0,
  addresses: [],
  price: 0.0,
  coin_swap: false,
  coin_swap_data: new CoinSwap()
});

export const TransactionRecord = Record({
  summary: new TransactionSummary(),
  tokens: new TokenTransfer(),
  internalTransactions: new Transactions()
});

export const getTransactionRecord = (state, transaction) =>
  state.get(transaction) || new TransactionRecord();

export const addTokenTransfers = (state, action) => {
  // Pull the walletId and api data out of the action body
  const { transaction, data } = action;

  // Grab or create the address record and create the address summary
  let transactionRecord = getTransactionRecord(state, transaction);
  let erc20_transfers = [];
  data["erc20"].forEach(curr => {
    erc20_transfers.push(new TokenTransaction(curr));
  });
  let erc721_transfers = [];
  data["erc721"].forEach(curr => {
    erc721_transfers.push(new TokenTransaction(curr));
  });
  const transactionTransfers = new TokenTransfer({
    erc20: erc20_transfers,
    erc721: erc721_transfers
  });

  // Set the summary in the record
  transactionRecord = transactionRecord.set("tokens", transactionTransfers);

  // set the address record and return
  return state.set(transaction, transactionRecord);
};

export const addTransactionSummary = (state, action) => {
  // Pull the walletId and api data out of the action body
  const { transaction, data } = action;

  // Grab or create the address record and create the address summary
  let transactionRecord = getTransactionRecord(state, transaction);
  const transactionSummary = new TransactionSummary({
    transactionHash: data.transactionHash,
    nonce: data.nonce,
    toAddress: data.toAddress,
    toAddressBalance: data.toAddressBalance,
    fromAddress: data.fromAddress,
    fromAddressBalance: data.fromAddressBalance,
    gasPrice: data.gasPrice,
    date: data.date,
    blockHeight: data.blockHeight,
    gasLimit: data.gasLimit,
    value: data.value,
    gasUsed: data.gasUsed,
    addresses: data.addresses,
    price: data.price,
    coin_swap: data.coin_swap !== null,
    coin_swap_data: data.coin_swap === null ? new CoinSwap() : new CoinSwap(data.coin_swap)
  });

  // Set the summary in the record
  transactionRecord = transactionRecord.set("summary", transactionSummary);

  // set the address record and return
  return state.set(transaction, transactionRecord);
};

export const addInternalTransactions = (state, { transaction, data: { transactions, offset } }) => {
  // Grab or create the address record and create the address summary
  let transactionRecord = getTransactionRecord(state, transaction);
  let transactions_data = [];
  transactions.forEach(
    ({
      transaction,
      sender_id,
      receiver_id,
      value,
      trx_fee,
      block_id,
      call_index,
      function_signature,
      timestamp
    }) => {
      transactions_data.push(
        new InternalTransaction({
          transaction,
          sender_id,
          receiver_id,
          value,
          trx_fee,
          block_id,
          call_index,
          function_signature,
          timestamp
        })
      );
    }
  );

  let cur_data = transactionRecord.getIn(["internalTransactions", "transactions"]) || List();
  cur_data = cur_data.push(...transactions_data);

  const internalTransactions = new Transactions({
    transactions: cur_data,
    offset: offset
  });

  // Set the summary in the record
  transactionRecord = transactionRecord.set("internalTransactions", internalTransactions);

  // set the address record and return
  return state.set(transaction, transactionRecord);
};

const makeTransactionReducerEthereum = coin => {
  return (state = Map(), action) => {
    // If the coin doesn't match the reducer, just return the state.
    if (action === undefined || action.name !== coin) {
      return state;
    }

    switch (action.type) {
      case TRANSACTION_SUMMARY_FETCH_SUCCESS:
        return addTransactionSummary(state, action);
      case TOKEN_TRANSFERS_FETCH_SUCCESS:
        return addTokenTransfers(state, action);
      case INTERNAL_TRANSACTIONS_FETCH_SUCCESS:
        return addInternalTransactions(state, action);
      case LOGGING_OUT:
        return Map();
      default:
        return state;
    }
  };
};

export default makeTransactionReducerEthereum;
