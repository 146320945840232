/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import moment from "moment";
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Col,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Modal,
  Row,
  Table
} from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

import axios, { deleteSessionCookie, NOTES_API } from "../../api";
import { reactRouterHistoryShape } from "../../prop-types";
import { getFeature } from "../../selectors/features";
import { getRole } from "../../selectors/authentication";
import { getCurrency } from "../../selectors/currency";
import { getClientMode } from "../../selectors/applicationConfig";
import ClientModeConfig from "../ClientModeConfig";
import { LOGGING_OUT_AUTOMATICALLY } from "../../actions/actionNames";
import instance from "../../api";
import { dismissFailureNotification, login } from "../../actions/authentication";

const throwError = () => {
  throw new Error("This is an error thrown for testing purposes.");
};

/**
 * List of notes associated with user with form modal
 * for creating new notes.
 */
class Notes extends Component {
  state = {
    notes: [],
    showModal: false,
    title: ""
  };

  componentDidMount = async () => {
    this.fetchNotes();
  };

  fetchNotes = async () => {
    try {
      const { data } = await axios.get(NOTES_API(this.props.currency));
      this.setState({
        notes: data.notes
      });
    } catch (err) {
      throw err;
    }
  };

  showCreateNote = () => this.setState({ showModal: true });

  closeCreateNote = () => this.setState({ showModal: false });

  handleChange = e => {
    this.setState({ title: e.target.value });
  };

  handleCreateForm = async e => {
    e.preventDefault();
    const title = this.titleForm.value;
    const isPublic = this.isPublicForm.checked;

    try {
      const {
        data: { noteId }
      } = await axios.post(NOTES_API(this.props.currency), {
        title,
        isPublic
      });

      if (noteId) {
        this.props.history.push(`/${this.props.currency}/case-management/${noteId}`);
      }
    } catch (err) {
      throw err;
    }
  };

  render() {
    const notes = this.state.notes.map(note => {
      const { noteId, title, publicNote, lastModified_, addressCount, walletCount } = note;

      const lastModified = moment.unix(parseInt(lastModified_, 10)).format("LLL");

      return (
        <tr key={noteId} className="inviteRow staticTableRow">
          <td>
            <Link
              to={`/${this.props.currency}/case-management/${noteId}`}
              style={{ color: "var(--link-colors)" }}
            >
              {title}
            </Link>
          </td>
          <td>{publicNote}</td>
          <td>{lastModified}</td>
        </tr>
      );
    });

    return (
      <Grid>
        <Row>
          <Col lg={12}>
            <Button onClick={this.showCreateNote} className="blueButton">
              <span>
                <FontAwesomeIcon icon={faPlus} />
                {"  "}
                Create Case File
              </span>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Table className="inviteTable">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Public</th>
                  <th>Last Modified</th>
                </tr>
              </thead>
              <tbody>{notes}</tbody>
            </Table>
          </Col>
        </Row>
        <Row></Row>
        <Modal show={this.state.showModal} onHide={this.closeCreateNote}>
          <Modal.Header closeButton>
            <Modal.Title>Create New Case File</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleCreateForm}>
              <FormGroup>
                <FormControl
                  type="text"
                  placeholder="Note title"
                  onChange={this.handleChange}
                  inputRef={ref => {
                    this.titleForm = ref;
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Checkbox
                  inputRef={ref => {
                    this.isPublicForm = ref;
                  }}
                >
                  Public
                </Checkbox>
              </FormGroup>
              <Button disabled={this.state.title.length <= 0} className="blueButton" type="submit">
                <span>
                  <FontAwesomeIcon icon={faPlus} />
                  {"  "}
                  Create Case File
                </span>
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        <br />
        {this.props.throwExceptionButtonEnabled && (
          <Button onClick={() => throwError()}> Throw error </Button>
        )}
        <br />
      </Grid>
    );
  }
}

Notes.propTypes = {
  history: reactRouterHistoryShape.isRequired,
  throwExceptionButtonEnabled: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    throwExceptionButtonEnabled: getFeature(state, "throw_exception_test"),
    isAdmin: ["admin", "researcher"].includes(getRole(state)),
    currency: getCurrency(state),
    clientMode: getClientMode(state)
  };
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch({ type: LOGGING_OUT_AUTOMATICALLY })
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Notes)
);
