/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Button, Col, Form, FormControl, FormGroup, Grid, Row } from "react-bootstrap";
import validator from "validator";
import { Helmet } from "react-helmet";

import axios, { CHANGE_EMAIL_API } from "../../api";
import { reactRouterMatchShape } from "../../prop-types";

/**
 * Form used to change email.
 */
export default class ChangeEmail extends Component {
  state = {
    notification: null,
  };

  componentDidMount() {
    const { token } = this.props.match.params;

    if (token) {
      this.sendEmailChangeToken(token);
    }
  }

  /**
   * Attempts to change email using token received from new email.
   * @param {string} token
   * @returns {Promise<void>}
   */
  sendEmailChangeToken = async token => {
    try {
      await axios.get(`${CHANGE_EMAIL_API}/${token}`);
      this.setState({
        notification: "Email successfully changed",
      });
    } catch (err) {
      this.setState({
        notification: "Invalid token",
      });
    }
  };

  handleAlertDismiss = () => this.setState({ notification: null });

  /**
   * Check if the new email is valid and sends request to change it.
   * @param {event} e
   * @returns {Promise<void>}
   */
  handleChangeEmail = async e => {
    e.preventDefault();

    const newEmail = this.email.value;
    const password = this.password.value;
    if (validator.isEmail(newEmail)) {
      try {
        await axios.post(CHANGE_EMAIL_API, {
          newEmail,
          password,
        });

        this.setState({
          notification: "Email confirmation sent",
        });
      } catch (err) {
        if (err.response.status === 409) {
          this.setState({
            notification: "Email already exists or incorrect password",
          });
        } else {
          throw err;
        }
      }
    } else {
      this.setState({
        notification: "Invalid email",
      });
    }
  };

  showUpdateFailedAlert = () => {
    const { notification } = this.state;
    switch (notification) {
      case "Invalid email":
        return (
          <Row>
            <Col lg={6} lgOffset={3}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p className="alertText">Invalid email</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Email confirmation sent":
        return (
          <Row>
            <Col lg={6} lgOffset={3}>
              <Alert bsStyle="success" onDismiss={this.handleAlertDismiss}>
                <p className="alertText">Email confirmation sent</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Email already exists or incorrect password":
        return (
          <Row>
            <Col lg={6} lgOffset={3}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p className="alertText">Email already exists or incorrect password</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Email successfully changed":
        return (
          <Row>
            <Col lg={6} lgOffset={3}>
              <Alert bsStyle="success" onDismiss={this.handleAlertDismiss}>
                <p className="alertText">Email successfully changed</p>
              </Alert>
            </Col>
          </Row>
        );
      case "Invalid token":
        return (
          <Row>
            <Col lg={6} lgOffset={3}>
              <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                <p className="alertText">Invalid token</p>
              </Alert>
            </Col>
          </Row>
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>Change Email</title>
        </Helmet>
        <Grid>
          {this.showUpdateFailedAlert()}

          <Row>
            <Col lg={6} lgOffset={3}>
              <div className="changeUsernamePassword">
                <h1> Change Email </h1>
                <Form onSubmit={this.handleChangeEmail}>
                  <FormGroup>
                    <h3>New email</h3>
                    <FormControl
                      type="email"
                      inputRef={ref => {
                        this.email = ref;
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <h3>Password</h3>
                    <FormControl
                      type="password"
                      inputRef={ref => {
                        this.password = ref;
                      }}
                    />
                  </FormGroup>
                  <Button className="blueButton" type="submit">
                    Submit
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

ChangeEmail.propTypes = {
  match: reactRouterMatchShape({
    token: PropTypes.string.isRequired,
  }).isRequired,
};
