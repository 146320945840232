// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { Popover, Table } from "react-bootstrap";
import styled from "styled-components";

// Makes font within a table monospace
export const MonospacedTable = styled(Table)`
  font-family: Monospace;
  width: 100%;
  font-size: 11px;
`;

// Ensures that Popovers don't cutoff text
export const FullWidthPopover = styled(Popover)`
  max-width: 100%;
`;

// Sets a max-height for large tables
export const ScrollDiv = styled.div`
  max-height: ${props => props.maxHeight}px;
  overflow: auto;
`;

// Emulates the bootstrap style for links
// A callback for onClick is passed to emulate links/buttons
export const FakeLinkSpan = styled.span`
  cursor: pointer;
  color: #337ab7;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  &:hover {
    color: #23527c;
    text-decoration: underline;
  }
`;
