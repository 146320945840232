/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";

import { FakeLinkSpan } from "../styled";
import { cytoscapeNodeShape } from "../../prop-types";
import { getWalletRisk, getWalletSummary } from "../../selectors/wallet";
import { satoshiToBitcoin } from "../../helpers";
import { openAddressModal, openWalletModal } from "../../actions/graph";
import CopyText from "../CopyText";
import { getCurrency } from "../../selectors/currency";
import CurrencyChooserConfig from "../CurrencyChooserConfig";
import { RiskButton } from "../Risk/RiskButton";
import { createRiskFetch } from "../../actions/actionCreatorFactories";
import { MAX_VALUE, SEGMENT_COLORS } from "../Risk/RiskSpeedometer";

const WalletStats = ({
  outputSatoshi,
  inputSatoshi,
  anchorAddress,
  handleOpenAddressModal,
  handleOpenWalletModal,
  wallet,
  currency,
  addressCount: addressCount_,
  outputCount: outputCount_,
  inputCount: inputCount_,
  balance: balance_,
  transactionCount: transactionCount_,
  firstTransaction: firstTransaction_,
  lastTransaction: lastTransaction_,
  ...props
}) => {
  const addressCount = addressCount_.toLocaleString();
  const outputCount = outputCount_.toLocaleString();
  const inputCount = inputCount_.toLocaleString();
  const transactionCount = transactionCount_.toLocaleString();
  const outputBitcoin = satoshiToBitcoin(outputSatoshi);
  const inputBitcoin = satoshiToBitcoin(inputSatoshi);
  const balance = satoshiToBitcoin(balance_);
  const firstTransaction = moment
    .unix(parseInt(firstTransaction_, 10))
    .utc()
    .format("LLL");
  const lastTransaction = moment
    .unix(parseInt(lastTransaction_, 10))
    .utc()
    .format("LLL");
  const add = anchorAddress.split(":").pop();
  const walletId = wallet && wallet.id();
  let currencyObject = CurrencyChooserConfig[currency];

  return (
    <div style={{ width: "100%", marginLeft: "0", paddingBottom: "0" }}>
      <Table className="walletStatsTable purple">
        <tbody>
          <tr key="anchorAddress">
            <td className="header" style={{ paddingBottom: "10px" }}>
              First Address
            </td>
            <td
              className="text-left content"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                paddingBottom: "0"
              }}
            >
              <FakeLinkSpan onClick={() => handleOpenAddressModal(anchorAddress)} style={{}}>
                {add}
              </FakeLinkSpan>
              <CopyText text={add.split(":").pop()} marginRight="0" />
            </td>
          </tr>
          {walletId > 0 && (
            <tr>
              {!props.risk ? (
                <td>
                  <RiskButton
                    getRisk={() => props.fetchRiskScore(walletId)}
                    risk={props.risk && props.risk.level}
                    style={{
                      color: "var(--base-color); !important",
                      border: "none !important",
                      fontWeight: 700,
                      fontSize: 14
                    }}
                  />
                </td>
              ) : (
                <>
                  <td className="header">Risk</td>
                  <td className="text-left">
                    <p
                      style={{
                        color:
                          SEGMENT_COLORS[
                            Math.floor((props.risk.level / MAX_VALUE) * SEGMENT_COLORS.length)
                          ],
                        fontSize: "14px",
                        margin: 0
                      }}
                      className={"riskText"}
                    >
                      {props.risk.level}
                    </p>
                  </td>
                </>
              )}
            </tr>
          )}
          <tr key="addressCount">
            <td className="header">Address Count</td>
            <td className="text-left">{addressCount}</td>
          </tr>
          <tr key="transactionCount">
            <td className="header">Transaction Count</td>
            <td className="text-left">{transactionCount}</td>
          </tr>
          <tr key="outputCount">
            <td className="header">Received Transactions</td>
            <td className="text-left">{outputCount}</td>
          </tr>
          <tr key="outputSum">
            <td className="header">Received {currencyObject.abb}</td>
            <td className="text-left">{outputBitcoin}</td>
          </tr>
          <tr key="balance">
            <td className="header">Balance</td>
            <td className="text-left">{balance}</td>
          </tr>
          <tr key="inputCount">
            <td className="header">Sent Transactions</td>
            <td className="text-left">{inputCount}</td>
          </tr>
          <tr key="inputSum">
            <td className="header">Sent {currencyObject.abb}</td>
            <td className="text-left">{inputBitcoin}</td>
          </tr>
          <tr key="firstTransaction">
            <td className="header">First Transaction</td>
            <td className="text-left">{firstTransaction}</td>
          </tr>
          <tr key="lastTransaction">
            <td className="header">Last Transaction</td>
            <td className="text-left">{lastTransaction}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

WalletStats.propTypes = {
  wallet: cytoscapeNodeShape.isRequired,
  addressCount: PropTypes.number.isRequired,
  transactionCount: PropTypes.number.isRequired,
  inputCount: PropTypes.number.isRequired,
  inputSatoshi: PropTypes.number.isRequired,
  outputCount: PropTypes.number.isRequired,
  outputSatoshi: PropTypes.number.isRequired,
  balance: PropTypes.number.isRequired,
  anchorAddress: PropTypes.string.isRequired,
  handleOpenAddressModal: PropTypes.func.isRequired,
  handleOpenWalletModal: PropTypes.func.isRequired,
  firstTransaction: PropTypes.number.isRequired,
  lastTransaction: PropTypes.number.isRequired
};

const mapStateToProps = () => {
  return (state, { wallet }) => {
    return {
      ...getWalletSummary(state, wallet.id(), true),
      currency: getCurrency(state),
      risk: getWalletRisk(state, wallet.id())
    };
  };
};

const mapDispatchToProps = dispatch => ({
  handleOpenAddressModal: address => dispatch(openAddressModal(address)),
  handleOpenWalletModal: walletId => dispatch(openWalletModal(walletId)),
  fetchRiskScore: async walletId =>
    await dispatch(createRiskFetch("wallet", getWalletRisk)(walletId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletStats);
