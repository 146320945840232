import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { IconButton, Tooltip } from "@material-ui/core";

interface AddButtonProps {
  callback: () => void;
  color?: string;
  fontSize?: string;
  marginLeft?: string;
  marginRight?: string;
  hoverText?: string;
}
export const AddButton = ({
  callback,
  color = "var(--secondary-color)",
  fontSize = "14px",
  marginLeft = "10px",
  marginRight = "10px"
}: AddButtonProps) => {
  return (
    <FontAwesomeIcon
      className="copyText"
      style={{ fontSize, color, marginLeft, marginRight }}
      icon={faPlusSquare}
      onClick={callback}
    />
  );
};
