/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";
import { ListGroup } from "react-bootstrap";
import { Set } from "immutable";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import SearchRow from "./SearchRow";
import TransactionSearchRow from "./TransactionSearchRow";
import AddressSearchRow from "./AddressSearchRow";
import { cytoscapeShape } from "../../../prop-types";
import { openAddressModal, openTransactionModal, openWalletModal } from "../../../actions/graph";
import { getSearchInfo } from "../../../selectors/graph";
import CopyText from "../../CopyText";
import { getSearchDisplayName } from "../../SearchResults/SearchResults";

const Search = ({
  cy,
  searchResults,
  handleOpenAddressModal,
  currentQuery,
  selectElementFromSearchResult,
  fromSidebar,
  disabled = false
}) => {
  const graphWallets = Set.of(
    ...cy
      .nodes()
      .toArray()
      .map(node => node.id())
  );

  if (currentQuery === null) {
    return null;
  }

  if (searchResults.length === 0) {
    return (
      <div
        className="row"
        style={{
          margin: "0",
          fontFamily: "Quicksand",
          fontWeight: "500",
          fontSize: "15px",
          paddingBottom: "5px",
          paddingTop: "10px",
          paddingLeft: "15px",
          paddingRight: "15px",
          color: fromSidebar ? "white" : "#333"
        }}
      >
        {" "}
        {`Sorry, no results for "${currentQuery}"`}{" "}
      </div>
    );
  }
  const addresses =
    searchResults && searchResults.addresses
      ? searchResults.addresses.map(({ address, walletId }) => {
          const addHash = address.split(":").pop();
          return (
            <AddressSearchRow
              key={`AddressRow/${addHash}`}
              address={addHash}
              cy={cy}
              walletId={walletId}
              wallets={graphWallets}
              handleOpenAddressModal={handleOpenAddressModal}
              selectElementFromSearchResult={selectElementFromSearchResult}
              disabled={disabled}
            />
          );
        })
      : [];

  const transactions =
    searchResults && searchResults.transactions
      ? searchResults.transactions.map(({ transaction_hash, wallets, input_count }) => (
          <TransactionSearchRow
            key={transaction_hash}
            transactionHash={transaction_hash}
            transactionWallets={wallets}
            wallets={graphWallets}
            unclustered_inputs={input_count > 1}
            cy={cy}
            disabled={disabled}
          />
        ))
      : [];

  const wallets =
    searchResults && searchResults.wallets
      ? searchResults.wallets.map(({ walletId, name }) => {
          return (
            <SearchRow
              key={`SearchRow/${walletId}`}
              primaryTag={name}
              walletId={walletId}
              cy={cy}
              selectElementFromSearchResult={selectElementFromSearchResult}
              disabled={disabled}
            />
          );
        })
      : [];

  const customWallets =
    searchResults && searchResults.custom_wallets
      ? searchResults.custom_wallets.map(({ walletId, name }) => (
          <SearchRow
            key={`SearchRow/${walletId}`}
            primaryTag={name}
            walletId={walletId}
            cy={cy}
            selectElementFromSearchResult={selectElementFromSearchResult}
          />
        ))
      : [];

  return (
    <>
      {
        <p
          style={{
            paddingLeft: "15px",
            fontSize: "15px",
            color: fromSidebar ? "white" : "var(--graph-key-labels)",
            fontFamily: "Quicksand",
            fontWeight: "500"
          }}
        >
          {" "}
          Showing Results for {"  "}
          <span style={{ fontWeight: "700" }}> "{currentQuery}"</span>{" "}
        </p>
      }
      <ListGroup style={{ marginBottom: "0" }}>
        {[...customWallets, ...wallets, ...addresses, ...transactions]}
      </ListGroup>
    </>
  );
};

Search.propTypes = {
  searchResults: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  cy: cytoscapeShape.isRequired,
  // callback used to indicate trying to add a node that already exists
  handleOpenAddressModal: PropTypes.func.isRequired,
  selectElementFromSearchResult: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { graphId }
    }
  }
) => getSearchInfo(state, Number.parseInt(graphId, 10));

const mapDispatchToProps = dispatch => ({
  handleOpenAddressModal: address => dispatch(openAddressModal(address)),
  handleOpenWalletModal: wallet => dispatch(openWalletModal(wallet)),
  handleOpenTransactionModal: transaction => dispatch(openTransactionModal(transaction))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Search)
);
