/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";
import { ListGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { cytoscapeShape } from "../../../prop-types";
import { openAddressModal, openTransactionModal, openWalletModal } from "../../../actions/graph";
import { getAssociationSearchInfoMemo } from "../../../selectors/graph";

import AssociatedSearchRow from "./AssociatedSearchRow";

const AssociatedSearch = ({
  cy,
  result,
  fromSidebar,
  focusedWallet,
  query,
  selectElementFromSearchResult
}) => {
  if (!result) {
    return null;
  }

  if (result.length === 0) {
    return (
      <div
        className="row"
        style={{
          margin: "0",
          fontFamily: "Quicksand",
          fontWeight: "500",
          fontSize: "15px",
          paddingBottom: "5px",
          paddingTop: "5px",
          paddingLeft: "15px",
          paddingRight: "15px",
          color: fromSidebar ? "white" : "var(--graph-key-labels)"
        }}
      >
        {" "}
        Sorry, no results for {focusedWallet} {query === "" ? "" : `on search "${query}"`}{" "}
      </div>
    );
  }
  const results = result.map(wallet => {
    const { walletId, inputSatoshi, outputSatoshi, tag, category, anchorAddress } = wallet;
    return (
      <AssociatedSearchRow
        key={`SearchRow/${walletId}`}
        primaryTag={tag}
        walletId={walletId}
        anchorAddress={anchorAddress}
        cy={cy}
        category={category}
        inputSatoshi={inputSatoshi}
        outputSatoshi={outputSatoshi}
        selectElementFromSearchResult={selectElementFromSearchResult}
      />
    );
  });

  return (
    <>
      {query ? (
        <p
          style={{
            paddingLeft: "15px",
            fontSize: "15px",
            color: fromSidebar ? "white" : "#333",
            fontFamily: "Quicksand",
            fontWeight: "500"
          }}
        >
          {" "}
          Showing Results for {"  "}
          <span style={{ fontWeight: "700" }}> "{query}"</span>{" "}
        </p>
      ) : (
        ""
      )}

      <div
        className="row entitySearchGraphDropdown"
        style={{
          margin: "0",
          fontFamily: "Quicksand",
          fontWeight: "700",
          fontSize: "13px",
          paddingBottom: "5px",
          paddingTop: "10px",
          paddingLeft: "15px",
          paddingRight: "15px"
        }}
      >
        <div
          className="col-lg-3"
          style={{
            paddingLeft: "0",
            paddingRight: "0",
            paddingBottom: "0",
            marginLeft: "0",
            marginRight: "0"
          }}
        >
          <span
            style={{
              borderBottom: "1px solid var(--graph-sidebar-base)",
              paddingBottom: "2px",
              color: fromSidebar ? "white" : ""
            }}
          >
            {" "}
            Entity
          </span>
        </div>
        <div
          className="col-lg-3"
          style={{
            paddingLeft: "0",
            paddingRight: "0",
            paddingBottom: "0",
            marginLeft: "0",
            marginRight: "0"
          }}
        >
          <span
            style={{
              float: "left",
              borderBottom: "1px solid var(--graph-sidebar-base)",
              paddingBottom: "2px",
              color: fromSidebar ? "white" : ""
            }}
          >
            {" "}
            Sent
          </span>
        </div>
        <div
          className="col-lg-3"
          style={{
            paddingLeft: "0",
            paddingRight: "0",
            paddingBottom: "0",
            marginLeft: "0",
            marginRight: "0"
          }}
        >
          <span
            style={{
              float: "left",
              borderBottom: "1px solid var(--graph-sidebar-base)",
              paddingBottom: "2px",
              color: fromSidebar ? "white" : ""
            }}
          >
            Received
          </span>
        </div>
      </div>
      <ListGroup style={{ marginBottom: "0" }}>{results}</ListGroup>
    </>
  );
};

AssociatedSearch.propTypes = {
  // reference to cytoscape object
  cy: cytoscapeShape.isRequired,
  // callback used to indicate trying to add a node that already exists
  handleOpenAddressModal: PropTypes.func.isRequired,
  fromSidebar: PropTypes.bool
};

const mapStateToProps = state => getAssociationSearchInfoMemo(state);

const mapDispatchToProps = dispatch => ({
  handleOpenAddressModal: address => dispatch(openAddressModal(address)),
  handleOpenWalletModal: wallet => dispatch(openWalletModal(wallet)),
  handleOpenTransactionModal: transaction => dispatch(openTransactionModal(transaction))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AssociatedSearch)
);
