/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import { connect } from "react-redux";

import {
  fetchWalletMutualWallets,
  fetchWalletTransactions,
  walletTransactionsSetDateRange,
  fetchWalletTransactionsBySatoshi,
  setTransactionsScrollTop,
  walletTransactionsSetOrder,
  walletTransactionsSetLargestFirst
} from "../../../actions/wallet";
import { makeGetWalletTransactions } from "../../../selectors/wallet";
import WalletTransactionsBase from "../WalletTransactionsBase";
import { WALLET_API } from "../../../api";
import { getUnixTimestampFromDate } from "../../../helpers";

const makeMapStateToProps = (state, initialOwnProps) => {
  console.log(initialOwnProps.startDate, initialOwnProps.endDate);
  const getWalletTransactions = makeGetWalletTransactions();
  return (state, { walletId }) => {
    let walletTransaction = getWalletTransactions(state, walletId, false);
    if (!walletTransaction.startDate && initialOwnProps.startDate) {
      walletTransaction.startDate = getUnixTimestampFromDate(initialOwnProps.startDate);
    }
    if (!walletTransaction.endDate && initialOwnProps.endDate) {
      walletTransaction.endDate = getUnixTimestampFromDate(initialOwnProps.endDate);
    }
    return walletTransaction;
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  __fetchByDate: (walletId, order, startDate = null, endDate = null, initial = false) =>
    dispatch(
      fetchWalletTransactions(
        WALLET_API,
        walletId,
        order,
        startDate,
        endDate,
        initial,
        ownProps.graphId || 0
      )
    ),
  __fetchBySatoshi: (walletId, startDate = null, endDate = null) =>
    dispatch(
      fetchWalletTransactionsBySatoshi(
        WALLET_API,
        walletId,
        startDate,
        endDate,
        false,
        ownProps.graphId || 0
      )
    ),
  onSetLargestFirst: (walletId, startDate = null, endDate = null, initial) =>
    dispatch(
      walletTransactionsSetLargestFirst(
        WALLET_API,
        walletId,
        startDate,
        endDate,
        initial,
        ownProps.graphId || 0
      )
    ),
  onSetDate: (walletId, startDate, endDate) =>
    dispatch(
      walletTransactionsSetDateRange(
        WALLET_API,
        walletId,
        startDate,
        endDate,
        ownProps.graphId || 0
      )
    ),
  setTransactionsScrollTop: (wallet, scrollTop) =>
    dispatch(setTransactionsScrollTop(wallet, scrollTop)),
  fetchMutualWallets: walletId =>
    dispatch(fetchWalletMutualWallets(WALLET_API, walletId, ownProps.graphId || 0)),
  onSetOrder: (walletId, order) => dispatch(walletTransactionsSetOrder(walletId, order))
});

// TODO this is confusing still don't understand what is happening here
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { order } = stateProps;
  const { __fetchByDate, __fetchBySatoshi } = dispatchProps;

  const fetch = order === 2 ? __fetchBySatoshi : __fetchByDate;

  return {
    fetch,
    ...ownProps,
    ...stateProps,
    ...dispatchProps
  };
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps,
  mergeProps
)(WalletTransactionsBase);
