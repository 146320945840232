// Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved.

import { createSelector } from "reselect";
import { EditorState } from "draft-js";
import history from "../components/history";
import { getWallet, getWalletName } from "./wallet";
import { getCurrency } from "./currency";

export const getGraph = (state, graphId) => {
  return state.getIn([getCurrency(state), "graph", "graphIdToGraphData", graphId.toString()]);
};

export const getCurrentGraph = state =>
  state.getIn([getCurrency(state), "graph", "view", "modals", "currentGraph"]);

export const getLastSavedTimestamp = (state, graphId) =>
  state.getIn([
    getCurrency(state),
    "graph",
    "graphIdToGraphData",
    getCurrentGraph(state),
    "lastSaved"
  ]);

export const getLastSavedTimestampForGraphId = (state, graphId) =>
  state.getIn([getCurrency(state), "graph", "graphIdToGraphData", graphId, "lastSaved"]);

export const getGraphJson = (state, graphId) => getGraph(state, graphId).get("graph");

export const getGraphWallets = (state, graphId) => getGraph(state, graphId).get("wallets");

/**
 * Gets the selected dates for graph nulls if no dates selected
 * @param state
 * @param graphId
 * @returns {{endDate: *, startDate: *}|{endDate: null, startDate: null}}
 */
export const getGraphSelectedDates = (state, graphId) => {
  const graph = getGraph(state, graphId);
  if (!graph) {
    // if no graph return nulls
    return {
      startDate: null,
      endDate: null
    };
  }
  const startDate = graph.get("startDate");
  const endDate = graph.get("endDate");
  return {
    startDate: startDate,
    endDate: endDate
  };
};

// Used to get immutable version, which is then converted **once** to a JS object in
// getWalletSetMemo.
const getWalletSet = (state, graphId) => {
  if (!graphId) {
    graphId = getCurrentGraph(state);
  }
  const graph = getGraph(state, graphId);
  if (graph == null) {
    return null;
  }

  const wallets = graph.get("wallets", null);
  if (wallets == null) {
    return null;
  }

  return wallets;
};

// (state, graphId) => state
export const getWalletSetMemo = createSelector(
  [getWalletSet],
  walletSet => {
    if (walletSet == null) {
      return new Set();
    }
    return new Set(walletSet.toJS());
  }
);

export const getGraphSearchCurrentQuery = state => {
  return state.getIn([getCurrency(state), "graph", "view", "currentQuery"]);
};

// gets both search results for current query and list of wallets
// to prevent adding ones that already exist
export const getSearchInfo = (state, graphId) => {
  const view = state.getIn([getCurrency(state), "graph", "view"]);
  const graph = getGraph(state, graphId);
  if (!graph) {
    return {
      currentQuery: null,
      searchResults: [],
      wallets: []
    };
  }

  const currentQuery = view.get("currentQuery");
  const searchResults_ = currentQuery != null ? view.getIn(["queries", currentQuery]).toJS() : {};
  if (searchResults_ && searchResults_) {
    searchResults_.wallets &&
      searchResults_.wallets.forEach(({ walletId }, idx) => {
        const { name, custom } = getWalletName(state, walletId);
        searchResults_.wallets[idx]["name"] = name;
        searchResults_.wallets[idx]["custom"] = !!custom;
      });
    searchResults_.custom_wallets &&
      searchResults_.custom_wallets.forEach(
        ({ walletId }, idx) =>
          (searchResults_.custom_wallets[idx]["name"] = getWalletName(state, walletId)["name"])
      );
  }

  return {
    currentQuery,
    searchResults: searchResults_
  };
};

export const getAssociationSearchInfoMemo = createSelector(
  // Get current query
  state => {
    const view = state.getIn([getCurrency(state), "graph", "view"]);
    if (view.get("currentAssociationQuery") == null) {
      return null;
    }
    const currentQuery = view.getIn(["currentAssociationQuery", "query"]);
    const walletId = view.getIn(["currentAssociationQuery", "walletId"]);
    const searchResults = view.getIn(["associationQueries", walletId, currentQuery]).toJS();
    return Object.keys(searchResults).map(result => searchResults[result]);
  },
  state => {
    const currentQuery = state.getIn([
      getCurrency(state),
      "graph",
      "view",
      "currentAssociationQuery"
    ]);
    if (currentQuery == null) {
      return null;
    }
    return currentQuery.get("query");
  },
  (queryResults, currentQuery) => {
    if (queryResults == null || currentQuery == null) {
      return {
        currentAssociationQuery: null,
        associationQueries: []
      };
    }
    return { result: queryResults, query: currentQuery };
  }
);

export const getGraphSaveInfo = (state, graphId) => {
  const graph = getGraph(state, graphId);
  if (!graph) {
    return {
      caseNumber: "",
      description: "",
      editorState: null,
      attributedEdgeToggleState: true
    };
  }

  return {
    caseNumber: graph.get("caseNumber"),
    description: graph.get("description"),
    editorState: graph.get("editorState"),
    attributedEdgeToggleState: graph.get("attributedEdgeToggleState")
  };
};

export const getGraphInfoMemo = createSelector(
  (state, graphId) => {
    return getGraph(state, graphId);
  },
  state => state.getIn([getCurrency(state), "graph", "view", "modals"]),
  state => getShowNotesFlag(state),
  state => getUndoStatus(state),
  (graph, modals, showNotes, undoStatus) => {
    if (graph == null) {
      return {
        caseNumber: "",
        description: "",
        editorContent: null,
        graph: null,
        undoCanGoBack: false,
        undoCanGoForward: false,
        showNotes: false,
        attributedEdgeToggleState: true
      };
    }
    // For some reason this inconsistently bugs out when a graph is deleted.
    // Values are null, but this selector shouldn't be getting called since there
    // is a redirect to /graph right after the delete succeeds.
    // For now just make this return send the user back to /graph if it bugs out
    try {
      const caseNumber = graph.get("caseNumber");
      const attributedEdgeToggleState = graph.get("attributedEdgeToggleState");
      const description = graph.get("description");
      const editorState = graph.get("editorState");
      const graph_ = graph.get("graph");
      const explorerModal = modals.get("explorer");
      const currentSet = modals.get("currentSet");
      if (explorerModal == null) {
        throw new Error();
      }
      const index = explorerModal.get("index");
      if (index == null) {
        throw new Error();
      }

      const historyRecord = index >= 0 ? explorerModal.getIn(["history", index]) : null;
      let entityType = null;
      let entity = null;
      if (historyRecord != null) {
        entityType = historyRecord.get("entityType");
        entity = historyRecord.get("entity");
      }

      const props = {
        caseNumber,
        attributedEdgeToggleState,
        description,
        editorState,
        graph_,
        currentSet,
        entityType,
        entity,
        showNotes
      };
      Object.assign(props, undoStatus);
      return props;
    } catch (err) {
      history.replace("/graph");
      return null;
    }
  }
);

export const getModalEntities = createSelector(
  state => state.getIn([getCurrency(state), "graph", "view", "modals"]),
  modals => {
    try {
      const explorerModal = modals.get("explorer");
      const currentSet = modals.get("currentSet");
      if (explorerModal == null) {
        throw new Error();
      }
      const index = explorerModal.get("index");
      if (index == null) {
        throw new Error();
      }

      const historyRecord = index >= 0 ? explorerModal.getIn(["history", index]) : null;
      let entityType = null;
      let entity = null;
      if (historyRecord != null) {
        entityType = historyRecord.get("entityType");
        entity = historyRecord.get("entity");
      }

      const props = {
        currentSet,
        entityType,
        entity
      };
      return props;
    } catch (err) {
      history.replace("/graph");
      return null;
    }
  }
);

const getSearchResults = createSelector(
  state => {
    const view = state.getIn([getCurrency(state), "graph", "view"]);
    const currentQuery = view.get("currentQuery");
    return currentQuery != null ? view.getIn(["queries", currentQuery]) : null;
  },
  searchResults => {
    if (searchResults != null) {
      return searchResults.toJS();
    }
    return [];
  }
);

// this needs search results that are currently displayed
export const getMainTabsInfo = (state, graphId) => {
  const currency = getCurrency(state);
  const view = state.getIn([currency, "graph", "view"]);
  const mainTabsKey = view.get("mainTabsKey");
  const currentQuery = view.get("currentQuery");
  const searchResults = getSearchResults(state);

  const editorState = state.getIn(
    [currency, "graph", "graphIdToGraphData", graphId.toString(), "editorState"],
    EditorState.createEmpty()
  );

  return {
    mainTabsKey,
    searchResults,
    editorState,
    currentQuery
  };
};

// This needs to get the active tab key
export const getWalletViewData = state => {
  return state.getIn([getCurrency(state), "graph", "view", "dataTabsKey"]);
};

export const getShowNotesFlag = state => {
  return state.getIn([getCurrency(state), "graph", "view", "showNotes"]);
};

export const getWalletMutualTransactionsData = (state, inputWalletId) => {
  inputWalletId = inputWalletId.toString();
  const currency = getCurrency(state);
  const outputWalletId = state.getIn([currency, "graph", "view", "outputWalletId"]);
  const mutualTransactionsOrder = state.getIn([
    currency,
    "graph",
    "view",
    "mutualTransactionsOrder"
  ]);
  const walletMap = getWallet(state, inputWalletId);

  let transactions;
  let hasNext;
  if (walletMap != null) {
    const mutualTransactions = walletMap.getIn(["mutualTransactions", outputWalletId]);
    if (mutualTransactions == null) {
      transactions = [];
      hasNext = true;
    } else {
      const transactionMap = mutualTransactions.get("transactionMap");
      const transactionIdList = mutualTransactions.get(mutualTransactionsOrder);
      transactions = transactionIdList
        .map(txid => {
          return transactionMap.get(txid);
        })
        .toJS();

      if (
        mutualTransactionsOrder === "largestFirst" ||
        mutualTransactionsOrder === "smallestFirst"
      ) {
        const compoundKey = mutualTransactions.get(`${mutualTransactionsOrder}NextKey`);
        hasNext = compoundKey.get("primary") != null;
      } else {
        hasNext = mutualTransactions.get(`${mutualTransactionsOrder}NextKey`) != null;
      }
    }
  } else {
    transactions = [];
    hasNext = false;
  }

  return {
    outputWalletId,
    transactions,
    hasNext,
    mutualTransactionsOrder
  };
};

export const getButtonHistory = state => {
  const currency = getCurrency(state);
  return {
    index: state.getIn([currency, "graph", "view", "modals", "explorer", "index"]),
    historyLength: state.getIn([currency, "graph", "view", "modals", "explorer", "history"]).count()
  };
};

export const getModalShow = state => {
  const currency = getCurrency(state);
  return {
    currentSet: state.getIn([currency, "graph", "view", "modals", "currentSet"])
  };
};

export const getUndoStatus = state => {
  const record = state.getIn([getCurrency(state), "graph", "view", "modals", "graphUndoStack"]);
  const { size } = record.get("stack");
  const index = record.get("index");
  return {
    undoCanGoBack: index !== -1,
    undoCanGoForward: size > 0 && size - 1 !== index
  };
};
