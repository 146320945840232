import React, { useEffect } from "react";

const CurrencyChooser = ({ setCoins, fetchCurrencyUsdValues }) => {
  const makeCall = async () => {
    await fetchCurrencyUsdValues();
    setCoins();
  };
  let interval;
  useEffect(() => {
      const call = async () => {
        // Make the call once before the interval so that we don't have to wait 30 secs
        makeCall();
        //await fetchCurrencyUsdValues();

        // Set an interval to refresh the coin prices once every 5 mins
        interval = setInterval(async () => {
          // Get the api nickname for the url
          await makeCall();
        }, 1000 * 60 * 5);
      };
      call()
      return () => clearInterval(interval);
  }, []);

  return <></>;
};

export default CurrencyChooser;
