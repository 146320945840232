/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedditAlien, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";

class Mention extends Component {
  // has the tag as a prop
  // also has api
  state = {
    open: false,
    currentPost: 0,
  };

  open = async () => {
    const { id, onlineAccountId } = this.props;

    await this.props.onClick(onlineAccountId, id);

    this.setState(prevState => ({
      open: !prevState.open,
    }));
  };

  render() {
    const { sourceName, username, count } = this.props;

    const posts = this.props.posts.map((mention, index) => {
      const { content, firstSeenAt } = mention;
      const firstSeenAtDate = moment
        .unix(firstSeenAt)
        .utc()
        .format("LLL");

      let cont;
      if (content.includes("\\n\\n")) {
        cont = content.split("\\n\\n")[1];
      } else if (content.includes("Comment on:")) {
        cont = content.split("Comment on:")[1];
      } else {
        cont = content;
      }

      return (
        <>
          {firstSeenAt !== null && (
            <p
              style={{
                color: "#333",
                fontSize: "12px",
                fontWeight: "700",
                display: "inline-block",
              }}
            >
              {" "}
              {firstSeenAtDate}{" "}
            </p>
          )}{" "}
          <p
            style={{
              color: "#333",
              fontSize: "12px",
              fontWeight: "700",
              display: "inline-block",
              float: "right",
            }}
          >
            {" "}
            {index + 1}/{this.props.posts.length}{" "}
          </p>
          <br />
          <p
            style={{
              color: "black",
              margin: "0",
              width: "100%",
              fontSize: "12px",
              overflowWrap: "break-word",
            }}
          >
            {cont}
          </p>
        </>
      );
    });

    if (count !== 0) {
      return (
        <div
          className="mention"
          style={{
            backgroundColor: "white",
            border: "2px solid white",
            // minWidth: "300px",
            // maxWidth: "500px",
            // width: "95%",
            margin: "0 auto",
            marginTop: "10px",
            marginBottom: "10px",
            // float: "none",
            padding: "0",
            overflow: "hidden",
            borderRadius: "10px",
          }}
        >
          {sourceName === "twitter" ? (
            <div
              className="row mentionHeader"
              onClick={() => this.open()}
              style={{
                fontFamily: "Quicksand",
                width: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                height: "60px",
                backgroundColor: "#08a0e9",
                color: "white",
                margin: "0",
                borderBottomLeftRadius: !this.state.open ? "10px" : "0px",
                borderBottomRightRadius: !this.state.open ? "10px" : "0px",
                padding: "5px 5px 5px 10px",
              }}
            >
              <FontAwesomeIcon
                style={{ width: "10%", marginRight: "10px" }}
                icon={faTwitter}
                size="2x"
              />{" "}
              <div style={{ width: "70%" }}>
                {" "}
                <span style={{ fontSize: "14px", float: "left" }}> Twitter </span> <br />{" "}
                <span style={{ fontSize: "12px", fontWeight: "700", float: "left" }}>
                  @{username}
                </span>
              </div>{" "}
              <span className="postBadge" style={{ color: "#08a0e9" }}>
                {count}
              </span>
            </div>
          ) : sourceName === "reddit" ? (
            <div
              className="row mentionHeader"
              onClick={() => this.open()}
              style={{
                fontFamily: "Quicksand",
                width: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                height: "60px",
                backgroundColor: "#ff4500",
                color: "white",
                margin: "0",
                borderBottomLeftRadius: !this.state.open ? "10px" : "0px",
                borderBottomRightRadius: !this.state.open ? "10px" : "0px",
                padding: "5px 5px 5px 10px",
              }}
            >
              <FontAwesomeIcon
                style={{ width: "10%", marginRight: "10px" }}
                icon={faRedditAlien}
                size="2x"
              />{" "}
              <div style={{ width: "70%" }}>
                {" "}
                <span style={{ fontSize: "14px", float: "left" }}> Reddit </span> <br />{" "}
                <span style={{ fontSize: "12px", fontWeight: "700", float: "left" }}>
                  @{username}
                </span>
              </div>
              <span className="postBadge" style={{ color: "#ff4500" }}>
                {count}
              </span>
            </div>
          ) : (
            <div
              className="row mentionHeader"
              onClick={() => this.open()}
              style={{
                fontFamily: "Quicksand",
                width: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                height: "60px",
                backgroundColor: "#FF0000",
                color: "white",
                margin: "0",
                borderBottomLeftRadius: !this.state.open ? "10px" : "0px",
                borderBottomRightRadius: !this.state.open ? "10px" : "0px",
                padding: "5px 5px 5px 10px",
              }}
            >
              <FontAwesomeIcon
                style={{ width: "10%", marginRight: "10px" }}
                icon={faYoutube}
                size="2x"
              />{" "}
              <div style={{ width: "70%" }}>
                {" "}
                <span style={{ fontSize: "14px", float: "left" }}> YouTube </span> <br />{" "}
                <span style={{ fontSize: "12px", fontWeight: "700", float: "left" }}>
                  @{username}
                </span>
              </div>
              <span className="postBadge" style={{ color: "#FF0000" }}>
                {count}
              </span>
            </div>
          )}
          <div
            className="row mentionContent"
            style={{
              height: !this.state.open ? "0" : "160px",
              padding: !this.state.open ? "0" : "5px",
              overflowY: "auto",
              overflowX: "hidden",
              display: "flex",
              alignItems: "center",
              marginLeft: "0",
              marginRight: "4px",
            }}
          >
            {this.state.open && (
              <>
                <div className="col-lg-1" style={{ margin: "0", padding: "0" }}>
                  <button
                    onClick={() => this.setState({ currentPost: this.state.currentPost - 1 })}
                    disabled={this.state.currentPost === 0}
                    style={{
                      color: sourceName === "twitter" ? "#08a0e9" : "#ff4500",
                    }}
                  >
                    {" "}
                    {"<"}
                  </button>
                </div>
                <div
                  className="col-lg-10"
                  style={{
                    overflow: "auto",
                    height: "100%",
                    justifyContent: "left",
                    alignItems: "left",
                    textAlign: "left",
                  }}
                >
                  {posts[this.state.currentPost]}
                </div>
                <div className="col-lg-1" style={{ margin: "0", padding: "0" }}>
                  <button
                    onClick={() => this.setState({ currentPost: this.state.currentPost + 1 })}
                    disabled={this.state.currentPost + 1 === posts.length}
                    style={{
                      color: sourceName === "twitter" ? "#08a0e9" : "#ff4500",
                    }}
                  >
                    {" "}
                    {">"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      );
    }

    // Fallback that should never happen since only mentions with comments are fetched.
    return <div />;
  }
}

Mention.propTypes = {
  id: PropTypes.string.isRequired,
  onlineAccountId: PropTypes.number.isRequired,
  count: PropTypes.number,
  username: PropTypes.string.isRequired,
  sourceName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      originalUrl: PropTypes.string,
      firstSeenAt: PropTypes.number,
    })
  ).isRequired,
};

Mention.defaultProps = {
  hasContent: false,
};

const mapStateToProps = (state, { id, onlineAccountId, getPostConverted }) => ({
  posts: getPostConverted(state, onlineAccountId, id),
});

const mapDispatchToProps = (dispatch, { fetchMentionPosts }) => ({
  onClick: (tagId, id) => dispatch(fetchMentionPosts(tagId, id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Mention);
