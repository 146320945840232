/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

// The following is borrowed from:
// https://github.com/facebook/draft-js/tree/master/examples/draft-0-10-0/rich
import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { convertFromRaw, convertToRaw, Editor, EditorState, RichUtils } from "draft-js";
import { debounce } from "lodash";
import "draft-js/dist/Draft.css";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCurrency } from "../../selectors/currency";

import BlockStyleControls from "./BlockStyleControls";
import InlineStyleControls from "./InlineStyleControls";
import "../../../css/RichEditor.css";
import axios, { GRAPH_API, NOTES_API, ADDRESS_API, TRANSACTION_API } from "../../api";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { Button, Col, Row, Table, Tab, Tabs } from "react-bootstrap";
import NoteEditor from "./index";

class AddressNoteEditor extends Component {
  state = {
    addressData: { location: null, locationId: null },
    addressMentionData: { location: null, locationId: null },
    allAddressData: { note: {}, address: {}, transaction: {} },
    addressNoteEditorState: null,
    addressMentionsEditorState: null,
    addressLoading: false,
    addressKey: "addressNote",
    addressDescription: ""
  };

  componentDidMount() {
    this.fetchAddressNote(this.props.address);
  }

  componentDidUpdate(prevProps) {
    if (this.props.address !== prevProps.address) {
      this.setState({ addressLoading: false });
      this.fetchAddressNote(this.props.address);
    }
  }

  getNoteId = async () => {
    let noteId = null;
    if (!this.props.mainLocationId) {
      if (this.props.mainLocation === "address") {
        const addressData = await axios.get(
          `${ADDRESS_API(this.props.currency)}/${this.props.mainLocationName}/exists`
        );
        noteId = await addressData.data.addressId;
      } else if (this.props.mainLocation === "transaction") {
        const transactionData = await axios.get(
          `${TRANSACTION_API(this.props.currency)}/${this.props.mainLocationName}/exists`
        );
        noteId = await transactionData.data.transactionId;
      }
    } else {
      noteId = parseInt(this.props.mainLocationId, 10);
    }
    return noteId;
  };

  fetchAddressNote = async address => {
    try {
      const { data } = await axios.get(`${NOTES_API(this.props.currency)}/address/${address}`);
      //          const { noteId } = this.props.match.params
      const noteId = await this.getNoteId();
      console.log("NOTE ID: ", noteId);
      if (!data[this.props.mainLocation][noteId]) {
        await axios.post(`${NOTES_API(this.props.currency)}/address/${address}`, {
          noteId: noteId,
          mainLocation: this.props.mainLocation
        });
      }
      this.setState({ allAddressData: data }, () => {
        //             const noteId = parseInt(this.props.mainLocationId, 10);
        if (this.state.allAddressData[this.props.mainLocation][noteId]) {
          if (this.state.allAddressData[this.props.mainLocation][noteId].editorContent) {
            this.setState({
              addressNoteEditorState: EditorState.createWithContent(
                convertFromRaw(
                  this.state.allAddressData[this.props.mainLocation][noteId].editorContent
                )
              )
            });
          } else {
            this.setState({
              addressNoteEditorState: EditorState.createEmpty()
            });
          }
        } else {
          this.setState({ addressNoteEditorState: EditorState.createEmpty() });
        }
        this.setState(
          {
            addressData: {
              ...this.state.addressData,
              location: this.props.mainLocation
            }
          },
          () => {
            this.setState({
              addressData: { ...this.state.addressData, locationId: noteId }
            });
          }
        );
        this.setState({ addressLoading: true });
      });
    } catch (err) {
      // this.props.history.replace("/case-management");
      throw err;
    }
  };

  //TODO Add an error message if save failed
  handleSave = async () => {
    const address = this.props.address;
    const addressLocation = this.state.addressData.location;
    const addressLocationId = this.state.addressData.locationId;
    const addressNote = JSON.stringify(
      convertToRaw(this.state.addressNoteEditorState.getCurrentContent())
    );
    if ("changeCreateAddressNoteButton" in this.props) {
      if (
        !(
          convertToRaw(this.state.addressNoteEditorState.getCurrentContent())["blocks"].length ===
            1 &&
          convertToRaw(this.state.addressNoteEditorState.getCurrentContent())["blocks"][0][
            "text"
          ] === ""
        )
      ) {
        this.props.changeCreateAddressNoteButton(address, true);
      } else {
        this.props.changeCreateAddressNoteButton(address, false);
      }
    }
    try {
      await axios.put(`${NOTES_API(this.props.currency)}/address/${address}`, {
        addressLocation,
        addressLocationId,
        addressNote
      });
    } catch (err) {
      window.scrollTo(0, 0);
      throw err;
    }
  };

  handleSaveMentions = async (message = false) => {
    const address = this.props.address;
    const addressLocation = this.state.addressMentionData.location;
    const addressLocationId = this.state.addressMentionData.locationId;
    const addressNote = JSON.stringify(
      convertToRaw(this.state.addressMentionsEditorState.getCurrentContent())
    );

    try {
      const updatedEditor = {
        editorContent: convertToRaw(this.state.addressMentionsEditorState.getCurrentContent()),
        locationName: this.state.allAddressData[addressLocation][addressLocationId].locationName
      };
      let copyState = { ...this.state.allAddressData };
      copyState[addressLocation][addressLocationId] = updatedEditor;
      this.setState({ allAddressData: copyState });
      await axios.put(`${NOTES_API(this.props.currency)}/address/${address}`, {
        addressLocation,
        addressLocationId,
        addressNote
      });
    } catch (err) {
      window.scrollTo(0, 0);
      throw err;
    }
  };

  handleAddressSelect = addressKey => this.setState({ addressKey });
  onAddressEdit = addressNoteEditorState => this.setState({ addressNoteEditorState });
  onAddressMentionEdit = addressMentionsEditorState =>
    this.setState({ addressMentionsEditorState });

  updateAddressMentionEditorState = (locationId, location) => {
    this.setState({
      addressDescription:
        location.charAt(0).toUpperCase() +
        location.slice(1).toLowerCase() +
        " - " +
        this.state.allAddressData[location][locationId].locationName
    });
    if (this.state.allAddressData[location][locationId].editorContent) {
      this.setState({
        addressMentionsEditorState: EditorState.createWithContent(
          convertFromRaw(this.state.allAddressData[location][locationId].editorContent)
        )
      });
    } else {
      this.setState({ addressMentionsEditorState: EditorState.createEmpty() });
    }
    this.setState(
      {
        addressMentionData: {
          // ...this.state.addressMentionData,
          location: location,
          locationId: locationId
        }
      }
      // () => {
      //   this.setState({
      //     addressMentionData: {
      //       ...this.state.addressMentionData,
      //       locationId: locationId
      //     }
      //   });
      // }
    );
  };

  render() {
    const addressMentionsNotes = Object.keys(this.state.allAddressData.note).map(locationId => {
      const locationName = this.state.allAddressData.note[locationId].locationName;
      if (
        (this.props.mainLocation === "note" && this.props.mainLocationId !== locationId) ||
        this.props.mainLocation !== "note"
      ) {
        return (
          <tr key={locationName}>
            <td>
              <Link
                to={`/${this.props.currency}/case-management/${locationId}`}
                target="_blank"
                style={{ color: "var(--link-colors)" }}
              >
                {locationName}
              </Link>{" "}
              <Button
                className="pull-right greenButtonGraph"
                bsSize="xsmall"
                onClick={() => this.updateAddressMentionEditorState(locationId, "note")}
              >
                See Notes
              </Button>
            </td>
          </tr>
        );
      } else if (
        this.props.mainLocation === "note" &&
        this.props.mainLocationId === locationId &&
        Object.keys(this.state.allAddressData.note).length === 1
      ) {
        return (
          <tr>
            <td>None Found</td>
          </tr>
        );
      }
    });

    const addressMentionsTransactions = Object.keys(this.state.allAddressData.transaction).map(
      locationId => {
        const locationName = this.state.allAddressData.transaction[locationId].locationName;
        const shorterTransactionName = locationName.substring(0, 55) + "...";
        if (
          (this.props.mainLocation === "transaction" && this.props.mainLocationId !== locationId) ||
          this.props.mainLocation !== "transaction"
        ) {
          return (
            <tr key={locationName}>
              <td>
                <Link
                  to={`/${this.props.currency}/transaction/${locationName}`}
                  target="_blank"
                  style={{ color: "var(--link-colors)" }}
                >
                  {shorterTransactionName}
                </Link>{" "}
                <Button
                  className="pull-right greenButtonGraph"
                  bsSize="xsmall"
                  onClick={() => this.updateAddressMentionEditorState(locationId, "transaction")}
                >
                  See Notes
                </Button>
              </td>
            </tr>
          );
        } else if (
          this.props.mainLocation === "transaction" &&
          this.props.mainLocationId === locationId &&
          Object.keys(this.state.allAddressData.transaction).length === 1
        ) {
          return (
            <tr>
              <td>None Found</td>
            </tr>
          );
        }
      }
    );

    const addressMentionsAddresses = Object.keys(this.state.allAddressData.address).map(
      locationId => {
        const locationName = this.state.allAddressData.address[locationId].locationName;
        const shorterAddressName = locationName.substring(0, 55) + "...";
        if (
          (this.props.mainLocation === "address" && this.props.mainLocationId !== locationId) ||
          this.props.mainLocation !== "address"
        ) {
          return (
            <tr key={locationName}>
              <td>
                <Link
                  to={`/${this.props.currency}/address/${locationName}`}
                  target="_blank"
                  style={{ color: "var(--link-colors)" }}
                >
                  {shorterAddressName}
                </Link>{" "}
                <Button
                  className="pull-right greenButtonGraph"
                  bsSize="xsmall"
                  onClick={() => this.updateAddressMentionEditorState(locationId, "address")}
                >
                  See Notes
                </Button>
              </td>
            </tr>
          );
        } else if (
          this.props.mainLocation === "address" &&
          this.props.mainLocationId === locationId &&
          Object.keys(this.state.allAddressData.address).length === 1
        ) {
          return (
            <tr>
              <td>None Found</td>
            </tr>
          );
        }
      }
    );

    return (
      <>
        {this.state.addressLoading && (
          <Tabs
            className="inviteTabs"
            activeKey={this.state.addressKey}
            id="address-tab"
            onSelect={this.handleAddressSelect}
            animation={false}
            mountOnEnter
          >
            <Tab eventKey="addressNote" title="Address Note">
              <>
                <Button
                  style={{
                    position: "relative",
                    float: "right",
                    top: "10px",
                    right: "10px"
                  }}
                  className="greenButton"
                  onClick={() => this.handleSave()}
                >
                  Save
                </Button>{" "}
                <NoteEditor
                  height={this.props.height ? this.props.height : 700}
                  editorState={this.state.addressNoteEditorState}
                  onEdit={this.onAddressEdit}
                  saveGraph={this.handleSave}
                />
              </>
            </Tab>
            <Tab eventKey="otherAddressMentions" title="Other Mentions">
              <Row>
                <Col lg={6}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Note Mentions</th>
                      </tr>
                    </thead>
                    {Object.keys(this.state.allAddressData.note).length !== 0 ? (
                      <tbody>{addressMentionsNotes}</tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td>None Found</td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </Col>
                <Col lg={6}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Transaction Mentions</th>
                      </tr>
                    </thead>
                    {Object.keys(this.state.allAddressData.transaction).length !== 0 ? (
                      <tbody>{addressMentionsTransactions}</tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td>None Found</td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </Col>
                {this.props.mainLocation !== "address" && (
                  <Col lg={12}>
                    <Table>
                      <thead>
                        <tr>
                          <th>Address Mentions</th>
                        </tr>
                      </thead>
                      {Object.keys(this.state.allAddressData.address).length !== 0 ? (
                        <tbody>{addressMentionsAddresses}</tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td>None Found</td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </Col>
                )}
              </Row>
              {this.state.addressMentionData.location ? (
                <>
                  <div>
                    <p
                      style={{
                        fontFamily: "Quicksand",
                        fontWeight: "600",
                        color: "#666",
                        marginRight: "10px",
                        marginBottom: "0",
                        fontSize: "30px"
                      }}
                    >
                      {this.state.addressDescription}
                    </p>
                    <Button
                      style={{
                        position: "relative",
                        float: "right",
                        top: "10px",
                        right: "10px"
                      }}
                      className="greenButton"
                      onClick={() => this.handleSaveMentions()}
                    >
                      Save
                    </Button>{" "}
                  </div>
                  <NoteEditor
                    height={this.props.height ? this.props.height : 700}
                    editorState={this.state.addressMentionsEditorState}
                    onEdit={this.onAddressMentionEdit}
                    saveGraph={this.handleSaveMentions}
                  />
                </>
              ) : (
                <></>
              )}
            </Tab>
          </Tabs>
        )}
      </>
    );
  }
}

AddressNoteEditor.defaultProps = {
  saveGraph: null
};

const mapStateToProps = state => {
  return {
    currency: getCurrency(state)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(AddressNoteEditor)
);
