import { useEffect, useState } from "react";
import { Alert, Button, DropdownButton, MenuItem, Table } from "react-bootstrap";
import DropDownTitle from "./DropDownTitle";
import { ScrollDiv } from "../styled";
import CopyText from "../CopyText";
import WrappedLink from "../Utils/WrappedLink";
import { Skeleton } from "@material-ui/lab";

import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Filters, FilterType, Token } from "./interfaces";
import { SideFilterButton } from "./SideFilterButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddButton } from "../Utils/AddButton";
import { faCheck } from "@fortawesome/free-solid-svg-icons/index";
import { getUsdForCoin } from "../../selectors/currency";
import {
  chooseBetweenConversions,
  convertPriceToUsd,
  numberWithCommas,
  significantDecimals
} from "../../helpers";

interface TransactionListProps {
  graphView: boolean;
  fetchTransactions: (token_account_id: string, filter: FilterType) => any;
  fetchTokens: () => any;
  getTransactions: (state: any, token_account_id: string, filter: FilterType) => any;
  getTokens: (state: any) => any;
  loadingRows?: number;
  stateBased: boolean;
  bold?: string;
  searchKey: string;
  enabledFilters?: Set<Filters>;
  addToGraph?: (addressId: string) => void;
  addresses?: Set<string>;
  showUsd?: boolean;
  showHist?: boolean;
}

export const TransactionList = ({
  graphView = false,
  getTransactions,
  getTokens,
  fetchTransactions,
  fetchTokens,
  loadingRows = 5,
  stateBased = false,
  bold,
  searchKey,
  enabledFilters = new Set<Filters>(),
  addToGraph,
  addresses,
  showUsd = false,
  showHist = false
}: TransactionListProps) => {
  const [token, setToken] = useState(null); // null means the token list has not been loaded
  const [loading, setLoading] = useState(true);
  const [tokenLoading, setTokenLoading] = useState(true); // TODO future loading variable for loading more tokens
  const [filter, setFilter] = useState<FilterType>({
    side: "everything"
  });

  // select transactions only when token is set
  const { transactions } = useSelector((state: any) =>
    token ? getTransactions(state, token.token_account_id, filter) : { transactions: [] }
  );

  const { tokens } = useSelector(state => getTokens(state));
  const usdPrice = useSelector(state => getUsdForCoin(state));
  const dispatch = useDispatch();

  const loadTransactions = async () => {
    setLoading(true);
    await dispatch(fetchTransactions(token.token_account_id, filter));
    setLoading(false);
  };

  const loadTokens = async () => {
    setLoading(true);
    await dispatch(fetchTokens());
    setLoading(false);
  };
  useEffect(() => {
    if (tokens && tokens.length > 0) {
      setTokenLoading(false);
      setToken(tokens[0]);
    } else {
      setToken(null);
      loadTokens().catch(console.error);
    }
  }, [dispatch, searchKey]);

  useEffect(() => {
    if (!token && tokens && tokens.length > 0) {
      setToken(tokens[0]);
    }
  }, [tokens]);

  useEffect(() => {
    if (!token) {
      setLoading(!tokens); // null token means tokens not loaded yet so can't load transactions
    } else if (transactions.length > 0 || !tokens) {
      setLoading(!tokens);
    } else {
      loadTransactions().catch(console.error);
    }
  }, [token, searchKey, filter]);

  const maxHeight = window.innerHeight > 400 ? window.innerHeight - 400 : 100;
  const buttonDisabled = true; // TODO finish implementing load more transactions capabilities
  const menuItemButtonClass = graphView ? "purpleFilterItem" : "coinDropdown";

  const tokenHasPrice = token && token.token_account_id === "0";
  const showUsdText = showHist || showUsd;
  const getValue = (value: number, decimals: number, price: number, priceAvailable: boolean) => {
    const tokenAmount = value / Math.pow(10, decimals);

    return chooseBetweenConversions(
      [
        numberWithCommas(significantDecimals(tokenAmount, 5)),
        (!showUsd && !showHist) || !tokenHasPrice
      ],
      ["$" + numberWithCommas(convertPriceToUsd(tokenAmount, usdPrice)), showUsd && tokenHasPrice],
      ["$" + numberWithCommas(convertPriceToUsd(tokenAmount, price)), showHist && tokenHasPrice],
      priceAvailable,
      false
    );
  };
  // Number of columns in table changes depending on if on the graph sidebar
  const numTableColumns = graphView ? 5 : 7;
  return (
    <div>
      <div className="row" style={{ marginLeft: "0", marginBottom: "15px", marginTop: "15px" }}>
        <div className={graphView ? "purpleButtonFilter" : "blueButtonFilter"}>
          {/*<Button*/}
          {/*  className="whiteButton"*/}
          {/*  onClick={_ => {*/}
          {/*    getTransactions(token.token_account_id).catch(console.error);*/}
          {/*  }}*/}
          {/*  disabled={buttonDisabled || loading}*/}
          {/*  style={{ float: "right" }}*/}
          {/*>*/}
          {/*  {buttonText}*/}
          {/*</Button>*/}
          <div style={{ display: "flex", fontSize: "15px" }}>
            <DropdownButton
              style={{
                fontFamily: "Quicksand",
                display: "inline-block",
                zIndex: 10001,
                height: "40px",
                paddingBottom: "3px",
                borderRadius: "20px",
                marginRight: "20px",
                color: "#555",
                backgroundColor: "white",
                border: "2px solid white"
              }}
              title={
                token ? (
                  <DropDownTitle token={token} address={bold} />
                ) : (
                  <Skeleton style={{ display: "inline-block" }} width={50} />
                )
              }
              id="currency-dropdown"
            >
              {tokens &&
                tokens.map((tok: Token) => (
                  <MenuItem
                    id={(tok.symbol || tok.name || tok.token_account_id).substring(0, 10)}
                    key={tok.token_account_id}
                    onClick={() => setToken(tok)}
                    className={menuItemButtonClass}
                    style={{ width: "300px" }}
                  >
                    <DropDownTitle token={tok} address={bold} />
                  </MenuItem>
                ))}
            </DropdownButton>
            {enabledFilters.has("side") && (
              <SideFilterButton
                side={filter.side}
                setSide={side => setFilter({ side })}
                graphView={graphView}
              />
            )}
          </div>
        </div>
      </div>
      {loading || transactions.length > 0 ? (
        <ScrollDiv
          maxHeight={maxHeight}
          id="walletTransactionsTable"
          // onScroll={this.setTableScrollTop}
        >
          <Table
            className="inviteTable"
            style={{
              width: "100%",
              tableLayout: "fixed",
              overflow: "auto",
              wordWrap: "break-word"
            }}
          >
            <thead>
              <tr>
                {/*Not showing block and trx fee on graph sidebar to save space TODO recalculate width based off of graphview*/}
                <th style={{ width: "17.5%" }}>Time (UTC)</th>
                <th style={{ width: "20%" }}>Trx Hash</th>
                {!graphView && <th style={{ width: "15%" }}>Block</th>}
                <th style={{ width: "17.5%" }}>Sender</th>
                <th style={{ width: "17.5%" }}>Receiver</th>
                {!graphView ? (
                  <th style={{ width: "15%" }}>{`Value (${
                    showUsdText && tokenHasPrice
                      ? "USD"
                      : token
                      ? token.symbol || token.name || token.token_account_id
                      : ""
                  })`}</th>
                ) : (
                  <th style={{ width: "15%" }}>{`${
                    showUsdText && tokenHasPrice
                      ? "USD"
                      : token
                      ? token.symbol || token.name || token.token_account_id
                      : ""
                  }`}</th>
                )}
                {!graphView && (
                  <th style={{ width: "15%" }}>{`Trx Fee (${showUsdText ? "USD" : "ETH"})`}</th>
                )}
              </tr>
            </thead>
            <tbody style={{ position: "relative" }}>
              {/*Not showing block and trx fee on graph sidebar to save space, so if*/}
              {/*graphView is true*/}
              {transactions &&
                transactions.map(
                  ({
                    transaction,
                    sender_id,
                    receiver_id,
                    block_id,
                    value,
                    trx_fee,
                    price,
                    priceAvailable,
                    timestamp
                  }: any) => (
                    <tr key={transaction} className="inviteRowThin staticTableRow">
                      <td>{timestamp}</td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap"
                        }}
                      >
                        <CopyText text={transaction} marginRight="4px" marginLeft="0" />
                        <WrappedLink
                          entity={transaction}
                          entityType={"transaction"}
                          stateBased={stateBased || false}
                          style={{ fontWeight: "500" }}
                        >
                          {transaction}
                        </WrappedLink>
                      </td>
                      {!graphView && <td> {block_id} </td>}
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {addToGraph &&
                          (addresses.has(sender_id.account_id) ? (
                            <FontAwesomeIcon
                              className="copyText"
                              // style={{ fontSize, color, marginLeft, marginRight }}
                              style={{
                                color: "green",
                                fontSize: "14px",
                                marginLeft: "0px",
                                marginRight: "10px"
                              }}
                              icon={faCheck}
                            />
                          ) : (
                            <AddButton
                              callback={() => addToGraph(sender_id.account_id)}
                              marginRight="10px"
                              marginLeft="0"
                              color={"green"}
                            />
                          ))}
                        <CopyText text={sender_id.account_id} marginRight="4px" marginLeft="0" />
                        <WrappedLink
                          entity={sender_id.account_id}
                          entityType={"address"}
                          stateBased={stateBased || false}
                          style={{ fontWeight: "500" }}
                        >
                          {sender_id.account_id === bold ? (
                            <strong>
                              {sender_id["name_attribution"] ||
                                sender_id["name_token"] ||
                                (sender_id["ens_names"] && sender_id["ens_names"][0]["name"]) ||
                                sender_id.account_id}
                            </strong>
                          ) : (
                            sender_id["name_attribution"] ||
                            sender_id["name_token"] ||
                            (sender_id["ens_names"] && sender_id["ens_names"][0]["name"]) ||
                            sender_id.account_id
                          )}
                        </WrappedLink>
                      </td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {addToGraph &&
                          (addresses.has(receiver_id.account_id) ? (
                            <FontAwesomeIcon
                              className="copyText"
                              // style={{ fontSize, color, marginLeft, marginRight }}
                              style={{
                                color: "green",
                                fontSize: "14px",
                                marginLeft: "0px",
                                marginRight: "10px"
                              }}
                              icon={faCheck}
                            />
                          ) : (
                            <AddButton
                              callback={() => addToGraph(receiver_id.account_id)}
                              marginRight="10px"
                              marginLeft="0"
                              color={"green"}
                            />
                          ))}
                        <CopyText text={receiver_id.account_id} marginRight="4px" marginLeft="0" />
                        <WrappedLink
                          entity={receiver_id.account_id}
                          entityType={"address"}
                          stateBased={stateBased || false}
                          style={{ fontWeight: "500" }}
                        >
                          {receiver_id.account_id === bold ? (
                            <strong>
                              {receiver_id["name_attribution"] ||
                                receiver_id["name_token"] ||
                                (receiver_id["ens_names"] && receiver_id["ens_names"][0]["name"]) ||
                                receiver_id.account_id}
                            </strong>
                          ) : (
                            receiver_id["name_attribution"] ||
                            receiver_id["name_token"] ||
                            (receiver_id["ens_names"] && receiver_id["ens_names"][0]["name"]) ||
                            receiver_id.account_id
                          )}
                        </WrappedLink>
                      </td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {getValue(value, token.decimals, price, priceAvailable)}
                      </td>
                      {!graphView && (
                        <td
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap"
                          }}
                        >
                          {getValue(trx_fee, 18, price, priceAvailable)}
                        </td>
                      )}
                    </tr>
                  )
                )}
              {loading &&
                [...Array(loadingRows)].map(_ => (
                  <tr className="inviteRowThin staticTableRow">
                    {[...Array(numTableColumns)].map(_ => (
                      <td>
                        <Skeleton />
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </Table>
        </ScrollDiv>
      ) : (
        <Alert style={{ boxShadow: "0px 2px 2px 0px #666" }} bsStyle="warning">
          No transactions found
        </Alert>
      )}
    </div>
  );
};
