/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Table, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Skeleton } from "@material-ui/lab";
import {
  chooseBetweenConversions,
  getUnixTimestampFromDate,
  numberWithCommas,
  onInputChange,
  satoshiToBitcoin,
  satoshiToBitcoinNoCommas
} from "../../helpers";
import { ScrollDiv } from "../styled";
import "../../../css/styles.css";
import moment from "moment/moment";
import { CUSTOM_WALLETS_API, WALLET_API } from "../../api";
import MutualWallets from "../Address/MutualWallets";
import {
  fetchWalletMutualWalletTransactions,
  walletMutualTransactionsSetLargestFirst,
  walletMutualTransactionsSwapOrder,
  walletMutualWalletTransactionsSetDateRange
} from "../../actions/wallet";
import { getWalletMutualWallets2, makeGetWalletMutualTransactions } from "../../selectors/wallet";
import CopyText from "../CopyText";
import DataDownload from "../DataDownload";
import TransactionSorter from "../Utils/TransactionSorter";

// TODO Document this file
export default class WalletTransactions extends Component {
  state = {
    currentView: "transactions",
    loading: true // Whether the transactions are waiting to be loaded
  };

  handleInputChange = onInputChange.bind(this);

  componentDidMount() {
    this.getOrFetchWalletTransactions(); // Fetch wallet transactions if not in the redux store
  }

  async componentDidUpdate(prevProps) {
    const { walletId: oldWalletId, processed: lastProcessed } = prevProps;
    const { walletId: newWalletId, processed: curProcessed, apiBase } = this.props;
    if (newWalletId !== oldWalletId) {
      this.fetchWalletTransactions(); // fetch transactions for in wallet
    }

    // if lastProcessed is false and curProcessed is true that means the wallet has changed
    // Need to purge old transaction data and refetch from beginning
    if (!lastProcessed && curProcessed) {
      // Addresses changed need to refetch all the transactions.
      await this.props.purge(newWalletId);
      this.fetchWalletTransactions();
    }
  }

  /**
    get if it already exists in state if not fetch
   */
  getOrFetchWalletTransactions = () => {
    if (!this.props.transactions || this.props.transactions.length === 0) {
      this.fetchWalletTransactions();
    } else {
      this.setState({ loading: false });
    }
  };

  /**
   * Fetches next set of transaction for wallet
   */
  fetchWalletTransactions = () => {
    const { walletId, order, startDate, endDate } = this.props;
    this.setState({ loading: true }); // display loading skeleton
    const fetchPromise =
      order === 2
        ? this.props.fetch(walletId, startDate, endDate)
        : this.props.fetch(walletId, order, startDate, endDate);
    fetchPromise.then(() => {
      // on success turn off loading
      this.setState({ loading: false });
      this.props.fetchMutualWallets(walletId);
    });
  };

  setLoading = value => {
    this.setState({ loading: value });
  };

  handleSetTransactionOrder = async (newOrder, startDateUnix, endDateUnix) => {
    const newOrderNumber = parseInt(newOrder); // make sure newOrder is an int
    this.setState({
      loading: true
    });

    const {
      walletId,
      newestFirstNextKey,
      order,
      oldestFirstNextKey,
      largestFirstNextKey
    } = this.props;

    await this.props.onSetOrder(walletId, newOrderNumber);
    if (newOrderNumber === -1 && oldestFirstNextKey === 0) {
      this.props
        .fetch(walletId, -1, startDateUnix, endDateUnix, true)
        .then(() => this.setState({ loading: false }));
    } else if (newOrderNumber === 1 && newestFirstNextKey === 0) {
      this.props
        .fetch(walletId, 1, startDateUnix, endDateUnix, true)
        .then(() => this.setState({ loading: false }));
    } else if (newOrderNumber === 2 && largestFirstNextKey === 0) {
      this.props
        .fetch(walletId, startDateUnix, endDateUnix, true)
        .then(() => this.setState({ loading: false }));
    } else {
      this.setState({ loading: false }); // fits none of the cases so we set loading to false
    }
  };

  handleSetWalletFilter = () => {
    if (this.state.currentView === "transactions") {
      this.setState({
        currentView: "mutualWallets"
      });
    } else {
      this.setState({
        currentView: "transactions"
      });
    }
  };

  render() {
    const {
      maxHeight,
      transactions,
      order,
      oldestFirstNextKey,
      newestFirstNextKey,
      largestFirstNextKey,
      getTransactionLink,
      getDateCell,
      custom,
      hasNextLargest,
      graphView,
      processed,
      loadingRows,
      showAsUsd,
      historicalToUsd,
      convertPriceToUsd,
      currentUsdPrice,
      walletId
    } = this.props;
    const { loading } = this.state;

    let buttonDisabled = !(
      (order > 0 && newestFirstNextKey !== null) ||
      (order < 0 && oldestFirstNextKey !== null)
    );
    if (largestFirstNextKey !== 0 && !hasNextLargest) {
      buttonDisabled = true;
    }
    const buttonText = !buttonDisabled ? "Load More ..." : "No More   ";

    let transactionComponents = transactions.map(transaction => {
      const {
        transactionHash,
        transactionId,
        satoshi,
        timestamp,
        price,
        priceAvailable
      } = transaction;

      const bitcoin = satoshiToBitcoin(satoshi);

      return (
        <tr key={transactionId} className="inviteRowThin staticTableRow">
          <td>{getDateCell(parseInt(timestamp, 10), transactionHash)}</td>
          <td
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap"
            }}
          >
            <CopyText text={transactionHash} marginLeft="0" />
            {getTransactionLink(transactionHash, this.props.currency)}
          </td>
          <td style={{ textAlign: "right" }}>
            {chooseBetweenConversions(
              [bitcoin, !showAsUsd],
              ["$" + numberWithCommas(convertPriceToUsd(bitcoin, currentUsdPrice)), showAsUsd],
              [
                "$" + numberWithCommas((bitcoin.replace(",", "") * price).toFixed(2)),
                historicalToUsd
              ],
              priceAvailable
            )}
          </td>
        </tr>
      );
    });

    // LoadingSkeleton is for loading bars for entire table
    // currently only useful for custom wallets when processing deletion/addition of addresses
    let loadingSkeleton = [];
    if (loading) {
      // react list of skeleton rows for showing loading bars on table

      loadingSkeleton = [...Array(loadingRows)].map(_ => {
        return (
          <tr className="inviteRowThin staticTableRow">
            {[...Array(3)].map(_ => (
              <td>
                <Skeleton />
              </td>
            ))}
          </tr>
        );
      });

      transactionComponents = [...transactionComponents, ...loadingSkeleton];
    }

    const menu = (
      <div className="row" style={{ marginLeft: "0", marginBottom: "15px", marginTop: "15px" }}>
        <div className={graphView ? "purpleButtonFilter" : "blueButtonFilter"}>
          {!graphView && (
            <Button
              className="greenButton"
              style={{ marginRight: "25px" }}
              onClick={this.handleSetWalletFilter}
            >
              <FontAwesomeIcon icon={faFilter} /> Add Attribution Category Filter
            </Button>
          )}
          <TransactionSorter
            entity_id={walletId}
            order={order}
            graphView={graphView}
            handleOnSetDate={this.props.onSetDate}
            handleSetTransactionOrder={this.handleSetTransactionOrder}
            setLoading={loading => this.setState({ loading: loading })}
            initialStartDate={this.props.startDate}
            initialEndDate={this.props.endDate}
          />
          <Button
            className="whiteButton"
            onClick={this.fetchWalletTransactions}
            disabled={buttonDisabled || loading}
            style={{ float: "right" }}
          >
            {buttonText}
          </Button>
          {transactions.length !== 0 && (
            <div style={{ float: "right" }}>
              <DataDownload
                data={transactions.map(item => {
                  const { transactionHash, satoshi, timestamp, price, priceAvailable } = item;
                  return {
                    "Transaction Hash": transactionHash,
                    Timestamp: moment
                      .unix(timestamp)
                      .utc()
                      .format("LLL"),
                    "Transaction Balance (Bitcoin)": satoshiToBitcoin(satoshi),
                    "Transaction Balance (USD)": priceAvailable
                      ? "$" + (satoshiToBitcoinNoCommas(satoshi) * price).toFixed(2)
                      : "N/A",
                    "USD Price": priceAvailable ? "$" + price.toFixed(2) : "N/A"
                  };
                })}
                entity_type="transaction"
                style={{ float: "right", margin: "10px" }}
              />
            </div>
          )}
        </div>
      </div>
    );

    if (this.state.currentView === "transactions" || this.state.currentView === "graphView") {
      return (
        <div>
          {menu}
          {// If the length is zero or not processed display regular table
          !processed || transactionComponents.length > 0 ? (
            <ScrollDiv
              maxHeight={maxHeight}
              id="walletTransactionsTable"
              onScroll={this.setTableScrollTop}
            >
              <Table
                className="inviteTable"
                style={{
                  width: "100%",
                  tableLayout: "fixed",
                  overflow: "auto",
                  wordWrap: "break-word"
                }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "20%" }}>Timestamp</th>
                    <th style={{ width: "60%" }}>Transaction</th>
                    <th style={{ textAlign: "right", width: "20%" }}>Trx Balance</th>
                  </tr>
                </thead>
                <tbody style={{ position: "relative" }}>{transactionComponents}</tbody>
              </Table>
            </ScrollDiv>
          ) : (
            // if transactionComponents has 0 length and is processed means no transactions
            <Alert style={{ boxShadow: "0px 2px 2px 0px #666" }} bsStyle="warning">
              No transactions found
            </Alert>
          )}
        </div>
      );
    } else {
      return (
        <MutualWallets
          sourceId={this.props.walletId}
          entityType={"wallet"}
          sourceTag={this.props.tag}
          graphId={this.props.graphId}
          apiBase={custom ? CUSTOM_WALLETS_API : WALLET_API}
          maxHeight={700}
          getTransactionLink={this.props.getTransactionLink}
          handleOpenDateSelectModal={this.handleOpenDateSelectModal}
          handleCloseDateSelectModal={this.handleCloseDateSelectModal}
          handleSetWalletFilter={this.handleSetWalletFilter}
          currentView={this.state.currentView}
          fetchMoreWallets={this.props.fetchMutualWallets}
          fetchTransactions={fetchWalletMutualWalletTransactions}
          getWallets={getWalletMutualWallets2}
          getMutualTransactions={makeGetWalletMutualTransactions}
          setDateRange={walletMutualWalletTransactionsSetDateRange}
          setLargest={walletMutualTransactionsSetLargestFirst}
          swapOrder={walletMutualTransactionsSwapOrder}
          fetchMoreTransactions={fetchWalletMutualWalletTransactions}
          fromAddressView={false}
          currency={this.props.currency}
          convertPriceToUsd={convertPriceToUsd}
          currentUsdPrice={currentUsdPrice}
          showAsUsd={this.props.showAsUsd}
          historicalToUsd={this.props.historicalToUsd}
        />
      );
    }
  }
}

// Purge is for custom wallets to reset transactions if the addresses change
WalletTransactions.propTypes = {
  walletId: PropTypes.string.isRequired,
  fetch: PropTypes.func.isRequired,
  purge: PropTypes.func,
  apiBase: PropTypes.func,
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      transactionHash: PropTypes.string.isRequired,
      transactionId: PropTypes.number.isRequired,
      satoshi: PropTypes.number.isRequired,
      timestamp: PropTypes.number.isRequired
    })
  ).isRequired,
  newestFirstNextKey: PropTypes.number,
  oldestFirstNextKey: PropTypes.number,
  largestFirstNextKey: PropTypes.number,
  onSetOrder: PropTypes.func.isRequired,
  onSetDate: PropTypes.func.isRequired,
  onSetLargestFirst: PropTypes.func,
  order: PropTypes.number.isRequired,
  maxHeight: PropTypes.number.isRequired,
  getTransactionLink: PropTypes.func.isRequired,
  getDateCell: PropTypes.func.isRequired,
  custom: PropTypes.bool,
  hasNextLargest: PropTypes.bool,
  setTransactionsScrollTop: PropTypes.func,
  scrollTop: PropTypes.number,
  graphView: PropTypes.bool,
  processed: PropTypes.bool,
  loadingRows: PropTypes.number,
  fetchMutualWallets: PropTypes.func.isRequired,
  showAsUsd: PropTypes.bool.isRequired,
  historicalToUsd: PropTypes.bool.isRequired,
  convertPriceToUsd: PropTypes.func.isRequired,
  currentUsdPrice: PropTypes.number,
  startDate: PropTypes.number.isRequired,
  endDate: PropTypes.number.isRequired
};

WalletTransactions.defaultProps = {
  purge: _ => {},
  newestFirstNextKey: null,
  onSetLargestFirst: null,
  hasNextLargest: false,
  custom: false,
  oldestFirstNextKey: null,
  setTransactionsScrollTop: null,
  scrollTop: null,
  graphView: null,
  processed: true,
  apiBase: WALLET_API,
  loadingRows: 5,
  showAsUsd: false,
  historicalToUsd: false,
  currentUsdPrice: 0
};
