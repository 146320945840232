import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Filters, filterStyles, FilterType } from "./interfaces";
import { DropdownButton, MenuItem } from "react-bootstrap";
import * as React from "react";
import { makeid } from "../../helpers";

interface SideFilterButtonProps {
  side: FilterType["side"];
  setSide: (side: FilterType["side"]) => void;
  graphView?: boolean;
}

export const SideFilterButton = ({
  side,
  setSide,
  graphView = false
}: SideFilterButtonProps) => {
  const menuItemButtonClass = graphView ? "purpleFilterItem" : "coinDropdown";

  return (
    <DropdownButton
      className="whiteButton"
      title={
        <>
          {" "}
          <FontAwesomeIcon
            style={{ marginRight: 10 }}
            icon={filterStyles[side].icon}
          />
          {`${side && filterStyles[side].displayName}`}
        </>
      }
      id={makeid(10)}
    >
      <MenuItem
        eventKey="2"
        className={menuItemButtonClass}
        onClick={() => setSide("everything")}
      >
        <>
          {" "}
          <FontAwesomeIcon
            style={{ marginRight: 10 }}
            icon={filterStyles["everything"].icon}
          />
          {filterStyles["everything"].displayName}
        </>
      </MenuItem>
      <MenuItem
        eventKey="2"
        className={menuItemButtonClass}
        onClick={() => setSide("sent")}
      >
        <>
          {" "}
          <FontAwesomeIcon
            style={{ marginRight: 10 }}
            icon={filterStyles["sent"].icon}
          />
          {filterStyles["sent"].displayName}
        </>
      </MenuItem>
      <MenuItem
        eventKey="2"
        className={menuItemButtonClass}
        onClick={() => setSide("received")}
      >
        <>
          {" "}
          <FontAwesomeIcon
            style={{ marginRight: 10 }}
            icon={filterStyles["received"].icon}
          />
          {filterStyles["received"].displayName}
        </>
      </MenuItem>
    </DropdownButton>
  );
};
