import React from "react";
import { Alert, Fade } from "react-bootstrap";
import PropTypes from "prop-types";

/**
 * Wraps boostrap alert to make a simple dismissible alerts
 */
export default class DismissableAlert extends React.Component {
  constructor(props) {
    super(props);

    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);

    this.state = {
      show: true,
    };
  }

  handleDismiss() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  render() {
    return (
      <Fade in={this.state.show} onExited={this.props.onDismissed}>
        <Alert
          bsStyle={this.props.bsStyle}
          onDismiss={this.props.dismissable && this.handleDismiss}
        >
          <p>{this.props.children}</p>
        </Alert>
      </Fade>
    );
  }
}

DismissableAlert.propTypes = {
  dismissable: PropTypes.bool,
  onDismissed: PropTypes.func,
  bsStyle: PropTypes.string,
};

DismissableAlert.defaultProps = {
  dismissable: true,
  bsStyle: "warning",
};
