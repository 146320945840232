/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { CustomWallet, WalletWithRouteBasedTabs } from "../Wallet";
import SearchResults from "../SearchResults";
import GenericNotFound from "../GenericNotFound";
import NoSearchResults from "../NoSearchResults";
import SubpoenaSupport from "../SubpoenaSupport";
import { ChangeEmail, ChangePassword, UserManagement } from "../Administration";
import {
  getLongTransactionDateCell,
  getReactRouterAddressLink,
  getReactRouterTransactionLink,
  getReactRouterWalletLink
} from "../Wallet/helpers";

import {
  getReactRouterAddressLink as getTrxAddressLink,
  getReactRouterTransactionLink as getTrxTransactionLink,
  getReactRouterWalletLink as getTrxWalletLink
} from "../Transaction/linkHelpers";
import { getFeature } from "../../selectors/features";
import Note from "../Note";
import { AddressWithRouteBasedTabs } from "../Address";
import { TransactionWithRouteBasedProps } from "../Transaction";
import Graph from "../Graph";
import Home from "../Home";
import LogoutRequest from "../LogoutRequest";
import { getCurrency } from "../../selectors/currency";
import {
  MESSAGE_DELETE,
  SET_MESSAGE_SHOW,
  SWAP_CURRENCY
} from "../../actions/actionNames";
import Researcher from "../Researcher";
import { getRole } from "../../selectors/authentication";
import HelpVideosPage from "../HelpVideosPage";
import { EtherAddressWithRouteBasedTabs } from "../Ethereum/Address";
import WalletEthereum from "../Ethereum/Wallet";
import TransactionEthereum, {
  EtherTransactionWithRouteBasedTabs
} from "../Ethereum/Transaction";
import EthereumGraph from "../Ethereum/Graph/Graph";
import { getCoins } from "../../selectors/coins";
import { connect, useDispatch } from "react-redux";
import ModalWrapper from "../Graph/ModalWrapper";
import { Share } from "../Messages/Share";
import { CustomWalletWithRouteBasedTabs } from "../Wallet/CustomWallet";

// This broke while making NoteEditor state controlled through props
// const Note = Loadable({
//   loader: () => import(/* webpackChunkName: "note" */ '../Note'),
//   loading: () => null,
// });

// const Graph = Loadable({
//   loader: () => import(/* webpackChunkName: "graph" */ '../Graph'),
//   loading: () => null,
// });
// const Transaction = Loadable({
//   loader: () => import(/* webpackChunkName: "transaction" */ '../Transaction'),
//   loading: () => null,
// });
// const Home = Loadable({
//   loader: () => import(/* webpackChunkName: "home" */ '../Home'),
//   loading: () => null,
// });
// const Address = Loadable({
//   loader: () => import(/* webpackChunkName: "address" */ '../AddressWithRouteBasedTabs'),
//   loading: () => null,
// });

const homeWithHistory = tab => ({ history }) => (
  <Home tab={tab} history={history} />
);

/* eslint-disable */
class ThrowsException extends React.Component {
  render() {
    throw new Error("This error was thrown within a component.");
  }
}

/* eslint-enable */

const AuthenticatedRoutes = props => {
  const location = useLocation(); // TODO expand on this for modal navigation
  let background = location.state && location.state.background;
  const dispatch = useDispatch();
  const makeAltCoinRoutes = (coin, researcher) => {
    const routes = [
      <Route
        exact
        path={`/${coin}/transaction/:transaction`}
        render={_props => {
          if (props.currency !== coin) {
            props.swapCurrency(coin);
          }
          return (
            <TransactionWithRouteBasedProps
              getAddressLink={getTrxAddressLink}
              getTransactionLink={getTrxTransactionLink}
              getWalletLink={getTrxWalletLink}
              {..._props}
            />
          );
        }}
      />,
      <Route
        path={`/${coin}/address/:address`}
        render={_props => {
          if (props.currency !== coin) {
            props.swapCurrency(coin);
          }
          return (
            <AddressWithRouteBasedTabs
              getTransactionLink={getReactRouterTransactionLink}
              getWalletLink={getReactRouterWalletLink}
              {..._props}
            />
          );
        }}
      />,
      <Route
        path={`/${coin}/wallet/:walletId`}
        render={_props => {
          if (props.currency !== coin) {
            props.swapCurrency(coin);
          }
          return (
            <WalletWithRouteBasedTabs
              getTransactionLink={getReactRouterTransactionLink}
              getDateCell={getLongTransactionDateCell}
              getAddressLink={getReactRouterAddressLink}
              {..._props}
            />
          );
        }}
      />,
      <Route
        exact
        path={`/${coin}/graph/:graphId`}
        render={_props => {
          if (props.currency !== coin) {
            props.swapCurrency(coin);
          }
          return <Graph {..._props} />;
        }}
      />,
      <Route
        exact
        path={`/${coin}/case-management/:noteId`}
        render={_props => {
          if (props.currency !== coin) {
            props.swapCurrency(coin);
          }
          return <Note {..._props} />;
        }}
      />,
      <Route
        exact
        path={`/${coin}/share/token/:token`}
        render={_props => {
          if (props.currency !== coin) {
            props.swapCurrency(coin);
          }
          dispatch({
            type: SET_MESSAGE_SHOW,
            view: false
          });
          return (
            <Share
              token={_props.match.params.token}
              currency={props.currency}
            />
          );
        }}
      />,
      //routes for custom wallets
      <Route
        path={`/${coin}/custom-wallet/:walletId`}
        render={_props => {
          if (props.currency !== coin) {
            props.swapCurrency(coin);
          }
          return (
            <CustomWalletWithRouteBasedTabs
              getTransactionLink={getReactRouterTransactionLink}
              getDateCell={getLongTransactionDateCell}
              getAddressLink={getReactRouterAddressLink}
              {..._props}
            />
          );
        }}
      />
    ];
    if (researcher) {
      routes.push(
        <Route
          exact
          path={`/${coin}/researcher`}
          render={_props => {
            if (props.currency !== coin) {
              props.swapCurrency(coin);
            }
            return <Researcher {..._props} />;
          }}
        />
      );
    }
    return routes;
  };

  dispatch({
    type: SET_MESSAGE_SHOW,
    view: true
  });
  let routeslist = []; // Enabling all the routes for every enabled coin
  props.coinsEnabled.forEach((coin, _) => {
    if (coin === "ethereum") {
      routeslist.push(
        ...[
          <Route
            exact
            path="/ethereum/address/:address"
            render={_props => {
              if (props.currency !== "ethereum") {
                props.swapCurrency("ethereum");
              }
              return <EtherAddressWithRouteBasedTabs {..._props} />;
            }}
          />,
          <Route
            exact
            path="/ethereum/wallet/:walletId"
            render={_props => {
              if (props.currency !== "ethereum") {
                props.swapCurrency("ethereum");
              }
              return <WalletEthereum {..._props} />;
            }}
          />,
          <Route
            exact
            path="/ethereum/transaction/:transaction"
            render={_props => {
              if (props.currency !== "ethereum") {
                props.swapCurrency("ethereum");
              }
              return <EtherTransactionWithRouteBasedTabs {..._props} />;
            }}
          />,
          <Route
            exact
            path="/ethereum/graph/:graphId"
            render={_props => {
              if (props.currency !== "ethereum") {
                props.swapCurrency("ethereum");
              }
              return <EthereumGraph {..._props} />;
            }}
          />,
          <Route
            exact
            path={`/ethereum/case-management/:noteId`}
            render={_props => {
              if (props.currency !== coin) {
                props.swapCurrency(coin);
              }
              return <Note {..._props} />;
            }}
          />
        ]
      );
    } else {
      routeslist.push(...makeAltCoinRoutes(coin, props.isResearcher));
    }
  });
  return (
    <div>
      <Switch location={background || location}>
        <Route
          exact
          path="/"
          render={() => (
            <Redirect
              // TODO TEMP while ethereum being built
              to={"/case-management"}
            />
          )}
        />
        <Route
          exact
          path="/2fa-enter-sms"
          render={() => (
            <Redirect
              // TODO TEMP while ethereum being built
              to={"/case-management"}
            />
          )}
        />
        <Route
          exact
          path="/login"
          render={({ history }) => (
            <Redirect
              // TODO TEMP while ethereum being built
              to={"/case-management"}
              history={history}
            />
          )}
        />
        {/* homeWithHistory is used to only render home on specific routes */}
        <Route
          exact
          path="/case-management"
          render={homeWithHistory("case-management")}
        />
        <Route
          exact
          path="/risk-scoring"
          render={homeWithHistory("risk-scoring")}
        />
        <Route
          exact
          path={"/transaction-searcher"}
          render={homeWithHistory("transaction-searcher")}
        />
        <Route
          exact
          path="/custom-wallet"
          render={homeWithHistory("custom-wallet")}
        />
        <Route
          exact
          path="/custom-tags"
          render={homeWithHistory("custom-tags")}
        />
        <Route exact path="/watchlist" render={homeWithHistory("watchlist")} />
        <Route exact path="/graphs" render={homeWithHistory("graphs")} />
        {props.issuesEnabled && (
          <Route exact path="/support" render={homeWithHistory("support")} />
        )}
        <Route exact path="/updates" render={homeWithHistory("updates")} />
        <Route exact path="/researcher" component={Researcher} />
        {routeslist}

        <Route exact path="/search/:query" component={SearchResults} />
        {/* other routes */}
        <Route exact path="/change-password" component={ChangePassword} />
        <Route exact path="/change-email" component={ChangeEmail} />
        <Route
          exact
          path="/change-email/token/:token"
          component={ChangeEmail}
        />
        <Route exact path="/user-management" component={UserManagement} />
        <Route exact path="/no-search-results" component={NoSearchResults} />
        <Route
          exact
          path="/register/token/:token"
          component={LogoutRequest}
          render={_props => {
            dispatch({
              type: SET_MESSAGE_SHOW,
              view: false
            });
            return <LogoutRequest />;
          }}
        />
        <Route exact path="/reset-request" component={LogoutRequest} />
        <Route
          exact
          path="/reset/token/:token"
          component={LogoutRequest}
          render={_props => {
            dispatch({
              type: SET_MESSAGE_SHOW,
              view: false
            });
            return <LogoutRequest />;
          }}
        />
        <Route exact path="/subpoena-support" component={SubpoenaSupport} />
        <Route exact path="/tutorial-videos" component={HelpVideosPage} />
        <Route exact path="/research" component={Researcher} />
        <Route exact path="/404" component={GenericNotFound} />
        {props.throwExceptionComponentEnabled && (
          <Route exact path="/exception" component={ThrowsException} />
        )}
        <Redirect to="/404" />
      </Switch>
      {background && (
        <Route path="/ethereum/address/:address" children={<ModalWrapper />} />
      )}
    </div>
  );
};

AuthenticatedRoutes.propTypes = {
  throwExceptionComponentEnabled: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    throwExceptionComponentEnabled: getFeature(state, "throw_exception_test"),
    issuesEnabled: getFeature(state, "issues"),
    currency: getCurrency(state),
    isResearcher: getRole(state) === "researcher",
    coinsEnabled: getCoins(state)
  };
};

const mapDispatchToProps = dispatch => ({
  swapCurrency: currency => dispatch({ type: SWAP_CURRENCY, currency })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticatedRoutes);
