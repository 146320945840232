/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";

import InputAddressTable from "./InputAddressTable";
import OutputAddressTable from "./OutputAddressTable";
import { transactionAddress } from "../../../prop-types";

const AddressTables = ({
  inputBitcoin,
  outputBitcoin,
  inputs,
  outputs,
  handleOpenWalletModal,
  handleOpenTransactionModal,
  handleOpenAddressModal,
  getAddressLink,
  getWalletLink,
  getTransactionLink,
  currency,
  originatingTransaction,
  convertPriceToUsd,
  toggleUsdDisplay,
  showAsUsd,
  onAddressNoteClick,
}) => (
  <div className="col-lg-12" style={{ paddingLeft: "0", paddingRight: "0", paddingTop: "0" }}>
    <Row>
      <Col lg={6}>
        <InputAddressTable
          totalBitcoin={inputBitcoin}
          outputs={inputs}
          handleOpenWalletModal={handleOpenWalletModal}
          handleOpenTransactionModal={handleOpenTransactionModal}
          handleOpenAddressModal={handleOpenAddressModal}
          getAddressLink={getAddressLink}
          getWalletLink={getWalletLink}
          getTransactionLink={getTransactionLink}
          currency={currency}
          convertPriceToUsd={convertPriceToUsd}
          toggleUsdDisplay={toggleUsdDisplay}
          showAsUsd={showAsUsd}
          onAddressNoteClick={onAddressNoteClick}
        />
      </Col>
      <Col lg={6}>
        <OutputAddressTable
          totalBitcoin={outputBitcoin}
          outputs={outputs}
          handleOpenWalletModal={handleOpenWalletModal}
          handleOpenTransactionModal={handleOpenTransactionModal}
          handleOpenAddressModal={handleOpenAddressModal}
          getAddressLink={getAddressLink}
          getWalletLink={getWalletLink}
          getTransactionLink={getTransactionLink}
          currency={currency}
          originatingTransaction={originatingTransaction}
          convertPriceToUsd={convertPriceToUsd}
          toggleUsdDisplay={toggleUsdDisplay}
          showAsUsd={showAsUsd}
          onAddressNoteClick={onAddressNoteClick}
        />
      </Col>
    </Row>
  </div>
);

export default AddressTables;

AddressTables.propTypes = {
  inputBitcoin: PropTypes.string.isRequired,
  outputBitcoin: PropTypes.string.isRequired,
  inputs: PropTypes.arrayOf(transactionAddress).isRequired,
  outputs: PropTypes.arrayOf(transactionAddress).isRequired,
  handleOpenAddressModal: PropTypes.func,
  handleOpenTransactionModal: PropTypes.func,
  handleOpenWalletModal: PropTypes.func,
  getAddressLink: PropTypes.func.isRequired,
  getWalletLink: PropTypes.func.isRequired,
  getTransactionLink: PropTypes.func.isRequired,
  currency: PropTypes.func.isRequired,
};

AddressTables.defaultProps = {
  handleOpenAddressModal: null,
  handleOpenTransactionModal: null,
  handleOpenWalletModal: null,
};
