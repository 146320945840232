/* Copyright 2018-2021 DecisionQ Information Operations, Inc. All Rights Reserved. */

import React from "react";

const LogoutRequest = () => (
  <div>
    <h1 className="text-center">Please logout before using this link.</h1>
  </div>
);

export default LogoutRequest;
