import React, { Component } from "react";
import { Button, FormControl, FormGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";

class EditableTitle extends Component {
  state = {
    editTag: false,
    fields: {
      primaryTag: "",
    },
  };

  onInputChange = e => {
    const { fields } = this.state;
    if (e.target.type !== "checkbox") {
      fields[e.target.id] = e.target.value;
    } else {
      fields[e.target.id] = e.target.checked;
    }
    this.setState({ fields });
  };

  componentDidMount() {
    this.setState({ fields: { primaryTag: this.props.title } });
  }

  submit = async (id, title, type) => {
    await this.props.updateTitle(id, title, type);

    this.setState({
      editTag: false,
    });
  };

  render() {
    const { title, id, type } = this.props;
    return this.state.editTag ? (
      <>
        <form autoComplete="off">
          <FormGroup controlId="primaryTag">
            <FormControl
              type="text"
              value={this.state.fields.primaryTag}
              style={{ display: "inline-block", verticalAlign: "middle" }}
              onChange={this.onInputChange}
            />
          </FormGroup>
        </form>
        <Button
          className="greenButton"
          onClick={() => this.submit(id, this.state.fields.primaryTag, type)}
        >
          <FontAwesomeIcon icon={faSave} style={{ marginRight: "5px" }} /> Save
        </Button>
        <Button
          style={{ marginLeft: "5px" }}
          className="issueButton"
          onClick={() => {
            this.setState({ editTag: false });
          }}
        >
          <FontAwesomeIcon icon={faTimes} style={{ marginRight: "5px" }} /> Cancel
        </Button>
      </>
    ) : (
      <>
        <p
          style={{
            fontFamily: "Quicksand",
            fontWeight: "600",
            fontSize: "22px",
            display: "inline",
          }}
        >
          {title || "Add a Title"}
        </p>
        <Button
          className="greenButton"
          style={{ marginLeft: "15px" }}
          onClick={() => this.setState({ editTag: true })}
        >
          <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: "5px" }} /> Edit Title
        </Button>
      </>
    );
  }
}

export default EditableTitle;
